import React from 'react';
import Preloader from '../../../elements/Preloader/Preloader';
import {useCallRequest} from './hooks';

import {Wrapper, Title, Main, Secondary, List} from './styles';
import Sidebar from './components/sidebar/component';
import Card from './components/card/component';

export default function CallRequestsItemScreen() {
  const {data, loading, refetch} = useCallRequest();

  return (
    <Wrapper>
      <Main>
        <Title>Список учреждений на прозвон</Title>
        {loading && <Preloader />}

        <List>
          {data.institutions.map((i) => (
            <Card
              onAddSuccess={refetch}
              key={i.id}
              id={i.id}
              authorFullName={data.author?.fullName}
              number={i.number}
              name={i.name}
              type={i.type}
              region={i.region}
              address={i.address}
              contacts={{
                phone: i.phone,
                email: i.email,
                website: i.webSite,
              }}
              caller={i.caller}
              note={i.note}
              result={i.result}
            />
          ))}
        </List>
      </Main>
      <Secondary>
        <Sidebar
          number={data.id}
          urgent={data.urgency}
          missing={data.issue.missing}
          createDate={data.createDate}
          lossDate={data.issue.lossDate}
          applicationId={data.issue.id}
          author={data.author}
          regionFields={data.issue.regionFields}
        />
      </Secondary>
    </Wrapper>
  );
}
