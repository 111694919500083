import styled from 'styled-components';

export const Form = styled.form``;

export const Wrapper = styled.div`
  padding-right: 128px;
  padding-bottom: 20px;
`;

export const Url = styled.div`
  margin-bottom: 24px;
`;

export const Tooltip = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const Buttons = styled.div`
  margin: 24px 0 0;
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
