import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {APPLICATION_EDIT_VERIFICATION_MUTATION} from '../../../../../../gql';
import {EditVerification} from '../../../../../../gql/application/mutations/__generated__/EditVerification';
import {Id} from '../../../../../../types/auxiliary.types';
import {toast} from 'react-toastify';
import {FormFields} from '../../../modals/Verification/types';

export function useVerificationHandler(
  verificationId: Id,
  onEditSuccess: () => void
) {
  const [loading, setLoading] = useState(false);

  const [editVerificationMutation] = useMutation<EditVerification>(
    APPLICATION_EDIT_VERIFICATION_MUTATION
  );

  const submit = useCallback(
    async (data: FormFields, setServerError: UseFormSetError<FormFields>) => {
      await editVerificationMutation({
        variables: {
          input: {
            id: Number(verificationId),
            comment: data.description,
          },
        },
      })
        .then(() => {
          toast('Верификация успешно изменена!');
          setLoading(true);
          onEditSuccess();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [editVerificationMutation, onEditSuccess, verificationId]
  );

  return {submit, loading};
}
