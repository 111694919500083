import React, {useMemo} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {Id} from '../../../../../types/auxiliary.types';
import DateField from '../../../../elements/DateField/DateField';
import {Label} from '../../../../elements/Label/Label';
import {ListFilter} from '../../../../elements/ListFilter/ListFilter';
import {Option} from '../../../../elements/Select/Select.types';
import SelectField from '../../../../elements/SelectField/SelectField';
import SwitchField from '../../../../elements/SwitchField/SwitchField';
import {TextField} from '../../../../elements/TextField/TextField';
import {useAction, useDict} from './Filter.hooks';
import {
  FieldWrapper,
  Field,
  Fields,
  Form,
  PrimaryColumn,
  SecondaryField,
  Wrapper,
} from './Filter.styles';
import {FormFields} from './Filter.types';

const InitState = {
  search: '',
  region: '',
  area: '',
  location: '',
  dateFrom: null,
  dateTo: null,
  statuses: [],
  subStatuses: [],
  missingName: '',
  gender: null,
  ageFrom: null,
  ageTo: null,
  missingNumber: '',
  applicantNumber: '',
  searchParticipantName: '',
  isUnworked: false,
  isUrgent: false,
  isEducational: false,
  isForestInTouch: false,
  isReverseLookup: false,
};

interface Props {
  applicationCount: number;
}

export function Filter(props: Props) {
  const [searchParams] = useSearchParams();

  const currentDate = useMemo(() => new Date(), []);

  const {regions, locations, statuses, subStatuses, areas} = useDict();

  const {submit, handleReset} = useAction();

  function getInitValue<T>(name: keyof FormFields, map?: (d: any) => T): T {
    const value = searchParams.get(name) as T | null;

    return value == null
      ? (InitState[name] as unknown as T)
      : map == null
      ? value
      : map(value);
  }

  const {control, register, reset, handleSubmit, formState, setValue} =
    useForm<FormFields>({
      defaultValues: {
        search: getInitValue<string>('search'),
        region: getInitValue<Id>('region', (d) => Number(d)),
        area: getInitValue<Id>('area', (d) => Number(d)),
        location: getInitValue<Id>('location'),
        dateFrom: getInitValue<Date | null>('dateFrom', (d) => new Date(d)),
        dateTo: getInitValue<Date | null>('dateTo', (d) => new Date(d)),
        statuses: searchParams.getAll('statuses'),
        subStatuses: searchParams.getAll('subStatuses'),
        missingName: getInitValue<string>('missingName'),
        gender: getInitValue<'m' | 'w' | null>('gender'),
        ageFrom: getInitValue<number | null>('ageFrom', (d) => Number(d)),
        ageTo: getInitValue<number | null>('ageTo', (d) => Number(d)),
        missingNumber: getInitValue<string>('missingNumber'),
        applicantNumber: getInitValue<string>('applicantNumber'),
        searchParticipantName: getInitValue<string>('searchParticipantName'),
        isUnworked: getInitValue<boolean>('isUnworked', (d) => Boolean(d)),
        isUrgent: getInitValue<boolean>('isUrgent', (d) => Boolean(d)),
        isEducational: getInitValue<boolean>('isEducational', (d) =>
          Boolean(d)
        ),
        isForestInTouch: getInitValue<boolean>('isForestInTouch', (d) =>
          Boolean(d)
        ),
        isReverseLookup: getInitValue<boolean>('isReverseLookup', (d) =>
          Boolean(d)
        ),
      },
      reValidateMode: 'onSubmit',
      mode: 'all',
    });

  const fields = useWatch<FormFields>({
    control: control,
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset={Object.values(fields).some(
          (f) => f !== '' && f != null && f !== false
        )}
        submitLabelButton="Показать заявки"
        count={{
          amount: props.applicationCount,
          words: ['заявка', 'заявки', 'заявок'],
        }}>
        <Wrapper>
          <PrimaryColumn>
            <SelectField
              name="region"
              hasFilter
              placeholder="Выберите регион"
              options={regions}
              label={'Выберите регион'}
              control={control}
            />
            <SelectField
              name="area"
              placeholder="Выберите"
              hasFilter
              options={
                fields.region === '' ||
                fields.region == null ||
                areas[fields.region] == null
                  ? []
                  : areas[fields.region]
              }
              label="Район"
              control={control}
              disabled={fields.region == null || fields.region === ''}
            />

            <SelectField
              name="location"
              placeholder="Выберите"
              hasFilter
              options={locations}
              label="Локация"
              control={control}
            />
            <Field>
              <Label label="Дата пропажи от и до" />
              <Fields>
                <DateField
                  name="dateFrom"
                  maxDate={fields.dateTo != null ? fields.dateTo : currentDate}
                  control={control}
                  format="dd.MM.yyyy"
                />
                <DateField
                  name="dateTo"
                  format="dd.MM.yyyy"
                  maxDate={currentDate}
                  minDate={
                    fields.dateFrom == null ? undefined : fields.dateFrom
                  }
                  control={control}
                />
              </Fields>
            </Field>
            <SelectField
              name="statuses"
              placeholder="Выберите"
              multiple
              hasFilter
              options={statuses}
              label="Статус"
              control={control}
              onCallbackChange={(newValue: Id[]) => {
                const result = statuses
                  .reduce((acc: Id[], v) => {
                    return [...acc, ...v.values.map((v1) => v1.value)];
                  }, [])
                  .reduce((acc: Id[], v) => {
                    if (newValue?.some((a) => a.toString() === v.toString())) {
                      if (Object.keys(subStatuses).length === 0) {
                        return acc;
                      }

                      return [
                        ...acc,
                        ...(
                          ((
                            subStatuses as Record<
                              Id,
                              {value: string | number; label: string | number}[]
                            >
                          )[v] || []) as {
                            value: string | number;
                            label: string | number;
                          }[]
                        ).map((v1) => v1.value),
                      ];
                    }
                    return acc;
                  }, [])
                  .filter((v) => {
                    return fields.subStatuses?.some(
                      (a) => a.toString() === v.toString()
                    );
                  });
                setValue('subStatuses', result);
              }}
            />
            <SelectField
              name="subStatuses"
              placeholder="Выберите"
              multiple
              hasFilter
              options={
                Object.entries(subStatuses).reduce(
                  (acc: Option[], [key, curr]) => {
                    if (
                      fields.statuses?.some(
                        (a) => a.toString() === key.toString()
                      )
                    ) {
                      return [...acc, ...(curr as Option[])];
                    }
                    return acc;
                  },
                  []
                ) as Option[]
              }
              disabled={fields.statuses?.length === 0}
              label="Подстатус"
              control={control}
            />
            <TextField
              register={register('missingName')}
              label="ФИО пропавшего"
              error={formState.errors.missingName}
              placeholder="Введите"
            />
            <SelectField
              name="gender"
              placeholder="Выберите"
              options={[
                {
                  label: 'Мужской',
                  value: 'm',
                },
                {
                  label: 'Женский',
                  value: 'w',
                },
              ]}
              label="Пол"
              control={control}
            />
            <Field>
              <Label label="Возраст пропавшего и от и до">
                <FieldWrapper>
                  <TextField
                    register={register('ageFrom', {
                      pattern: {
                        value: RegExpConstants.integerNumber,
                        message: 'Некорректный формат',
                      },
                      max: {
                        value:
                          fields.ageTo != null && fields.ageTo !== ''
                            ? fields.ageTo
                            : Infinity,
                        message: 'Текущее значение больше допустимого',
                      },
                    })}
                    error={formState.errors.ageFrom}
                    placeholder="Введите"
                  />
                  <TextField
                    register={register('ageTo', {
                      pattern: {
                        value: RegExpConstants.integerNumber,
                        message: 'Некорректный формат',
                      },
                      min: {
                        value:
                          fields.ageFrom != null && fields.ageFrom !== ''
                            ? fields.ageFrom
                            : -Infinity,
                        message: 'Текущее значение меньше допустимого',
                      },
                    })}
                    error={formState.errors.ageTo}
                    placeholder="Введите"
                  />
                </FieldWrapper>
              </Label>
            </Field>
            <TextField
              register={register('missingNumber', {
                pattern: {
                  value: RegExpConstants.phone,
                  message: 'Некорректный формат',
                },
              })}
              mask="+7(999) 999 99 99"
              error={formState.errors.missingNumber}
              label="Номер телефона пропавшего"
              placeholder="+ _ ___ ___ __ __"
            />
            <TextField
              register={register('applicantNumber', {
                pattern: {
                  value: RegExpConstants.phone,
                  message: 'Некорректный формат',
                },
              })}
              mask="+7(999) 999 99 99"
              error={formState.errors.applicantNumber}
              label="Номер телефона заявителя"
              placeholder="+ _ ___ ___ __ __"
            />
          </PrimaryColumn>
          <SecondaryField>
            <SwitchField
              name="isUnworked"
              control={control}
              label="Только неотработанные"
            />
            <SwitchField name="isUrgent" control={control} label="Срочная" />
            <SwitchField
              name="isEducational"
              control={control}
              label="Учебная"
            />
            <SwitchField
              name="isForestInTouch"
              control={control}
              label="Лес на связи"
            />
            <SwitchField
              name="isReverseLookup"
              control={control}
              label="Обратный поиск"
            />
          </SecondaryField>
        </Wrapper>
      </ListFilter>
    </Form>
  );
}
