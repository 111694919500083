import {GraphQLErrors} from '@apollo/client/errors';
import {Path, UseFormSetError} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types/fields';

export function useServerErrors<T extends FieldValues>(
  struct: Record<string, Path<T>>,
  setServerError?: UseFormSetError<T>
) {
  function addServerError(
    error: GraphQLErrors,
    setServerErrorLocal?: UseFormSetError<T>
  ) {
    if (
      error == null ||
      (setServerError == null && setServerErrorLocal == null)
    )
      return null;

    error.forEach((e: GraphQLErrors & any) => {
      const findErrorName = Object.keys(struct).find(
        (error_name_dto) => e[error_name_dto] != null
      );

      if (findErrorName != null) {
        const setError = (setServerError ||
          setServerErrorLocal) as UseFormSetError<T>;

        setError(struct[findErrorName], {
          type: 'server',
          message: e[findErrorName],
        });
        return;
      }

      Object.entries(e.extensions.validation).forEach(
        ([error_name_dto, messages]) => {
          const name = struct[error_name_dto];

          if (name != null && Array.isArray(messages) && messages.length > 0) {
            const setError = (setServerError ||
              setServerErrorLocal) as UseFormSetError<T>;

            setError(name, {
              type: 'server',
              message: messages[0],
            });
          }
        }
      );
    });
  }

  return {addServerError};
}
