import React from 'react';
import {useForm} from 'react-hook-form';
import {useEnterForm} from '../../../form.hooks';
import {useHandler} from './Step1.hooks';

import {Fields, Footer, Form, Title, ContentWrapper} from './Step1.styles';

import {Step1FormFields as FormFields} from '../../../types';
import {GeneralInfo} from '../../../components/GeneralInfo/GeneralInfo';
import {Button} from '../../../../../elements/Button/Button';
import {Id} from '../../../../../../types/auxiliary.types';
import {useNavigate} from 'react-router-dom';

interface Props {
  defaultValues?: FormFields;
  onSuccess?(id: Id): void;
}

export const Step1 = (props: Props) => {
  const {
    control,
    register,
    formState,
    formState: {errors},
    handleSubmit,
    setValue,
    reset,
    setError,
  } = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      region: '',
      additionalRegions: [],
      area: [],
      location: '',
      source: '',
      date: null,
      circumstances: '',
      lastSeen: '',
      educational: false,
      forestInTouch: false,
      urgently: false,
      reverseLookup: false,
    },
    reValidateMode: 'onChange',
    mode: 'onBlur',
  });

  const {handleSubmit1Step} = useHandler(setError, props.onSuccess);

  const navigate = useNavigate();

  useEnterForm(handleSubmit(handleSubmit1Step));

  return (
    <>
      <Title>Новая заявка</Title>
      <ContentWrapper>
        <Form
          onSubmit={handleSubmit(handleSubmit1Step)}
          onReset={() => reset({}, {keepDefaultValues: true})}>
          <Fields>
            <GeneralInfo
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Fields>

          <Footer>
            <Button
              title="Отменить"
              type="reset"
              variant="outlined"
              onClick={() => navigate('/applications')}
              disabled={formState.isSubmitting}
            />
            <Button
              title="Создать заявку"
              type="submit"
              disabled={formState.isSubmitting}
            />
          </Footer>
        </Form>
      </ContentWrapper>
    </>
  );
};
