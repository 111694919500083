import React from 'react';
import {SvgStyled} from './Icons.styles';

export const WomenIcon = () => {
  return (
    <SvgStyled
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0826 11.6688C14.7225 9.02973 14.7225 4.73561 12.0826 2.09653C9.44267 -0.542593 5.14724 -0.542593 2.50728 2.09653C-0.132636 4.73561 -0.132636 9.02973 2.50728 11.6688C3.62473 12.7859 5.0388 13.43 6.49833 13.6014V15.8675H4.90524C4.46531 15.8675 4.10869 16.224 4.10869 16.6638C4.10869 17.1035 4.46531 17.4601 4.90524 17.4601H6.49833V19.7033C6.49837 20.143 6.85498 20.4996 7.29496 20.4996C7.73489 20.4996 8.0915 20.143 8.0915 19.7033V17.4601H9.68463C10.1246 17.4601 10.4812 17.1035 10.4812 16.6638C10.4812 16.224 10.1246 15.8675 9.68463 15.8675H8.0915V13.6014C9.55107 13.43 10.9651 12.7859 12.0826 11.6688ZM3.63381 10.5426C1.61504 8.52451 1.61504 5.24079 3.63381 3.22266C5.65254 1.2046 8.93725 1.20448 10.9561 3.22266C12.9749 5.24079 12.9749 8.52451 10.9561 10.5426C8.93733 12.5607 5.65258 12.5607 3.63381 10.5426Z"
        fill="#EE8C8C"
      />
    </SvgStyled>
  );
};
