import styled, {css} from 'styled-components';

export const Input = styled.input`
  display: none;
`;

function button() {
  return css`
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px dashed var(--primary-color);
    border-radius: 8px;
    background-color: transparent;

    font-weight: 400;
    font-size: 16px;
    line-height: 1.38;

    color: var(--primary-color);

    transition: background-color 500ms linear;

    cursor: pointer;

    &:not(:disabled):hover {
      //not found in design

      svg path {
        //empty
      }
    }

    &:disabled {
      //not found in design

      cursor: not-allowed;
    }
  `;
}

export const Button = styled.button`
  ${button()};
`;

export const FakeInput = styled.label`
  ${button()};
`;

export const PlusIconWrapper = styled.div`
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: var(--primary-color);

  margin-right: 12px;

  svg {
    width: 12px;
    height: 12px;

    path {
      stroke: #fff;
    }
  }
`;
