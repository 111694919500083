import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {CalendarIcon} from '../../icons/CalendarIcon';
import {CalendarIconWrapper, Wrapper} from './DateComponent.styles';

interface Props<T extends FieldValues> {
  ariaInvalid?: boolean;
  maxDate?: Date;
  minDate?: Date;
  startDate?: Date;
  disabled?: boolean;
  name: Path<T>;
  control: Control<T>;
  rules?: UseControllerProps['rules'];
  format?: string;
}

export function DateComponent<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const {field, formState} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper>
      <CalendarIconWrapper>
        <CalendarIcon />
      </CalendarIconWrapper>
      <DateTimePicker
        className={'date_time_picker'}
        dayPlaceholder={'ДД'}
        monthPlaceholder={'ММ'}
        yearPlaceholder={'ГГГГ'}
        calendarIcon={null}
        onChange={(date: any) => field.onChange(date)}
        value={field.value}
        locale="ru"
        disableClock
        format={props.format || 'dd.MM.yyyy'}
        hourPlaceholder={'12'}
        minutePlaceholder={'00'}
        disabled={props.disabled || formState.isSubmitting}
        minDate={props.minDate}
        maxDate={props.maxDate}
        name={props.name}
        clearIcon={null}
      />
    </Wrapper>
  );
}
