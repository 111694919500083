import React from 'react';
import {Photo as SharedAddPhoto} from '../../../../../modals/Photo/Photo';
import {useAddPhotosHandler} from './hooks';

interface Props {
  file: File;
  fetch(): void;
  onModalClose(): void;
}

export function AddPhoto(props: Props) {
  const {handleAddPhoto, requestSending} = useAddPhotosHandler(() => {
    props.fetch();
    props.onModalClose();
  });

  return (
    <SharedAddPhoto
      title="Добавить фото"
      submitLabel="Добавить"
      file={props.file}
      onSubmit={handleAddPhoto}
      requestSending={requestSending}
      onModalClose={props.onModalClose}
    />
  );
}
