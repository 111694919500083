import {Children} from 'react';
import React, {ReactNode} from 'react';
import {
  MainInfo,
  SecondaryInfo,
  Wrapper,
} from './styles';

interface Props {
  onClickCard?(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
  children: [ReactNode, ReactNode] | ReactNode
}

export default function WrapperWithComment(props: Props) {

  const childrenArray = Children.toArray(props.children)

  const showComments = childrenArray.length === 2 &&childrenArray[1] != null && childrenArray[1] !== ""

  return (
    <Wrapper onClick={props.onClickCard}>
      <MainInfo>
        {childrenArray.length === 2 ? childrenArray[0] : props.children}
      </MainInfo>
      {showComments && (
        <SecondaryInfo>
          {childrenArray[1]}
        </SecondaryInfo>
      )}
    </Wrapper>
  )
}
