import React, {CSSProperties} from 'react';
import {BadgeStyled, Counter} from './Badge.style';

export type BadgeProps = {
  title: string;
  counter: number;
  className: string;
  color:
    | 'primary'
    | 'secondary'
    | 'additional'
    | 'yellow'
    | 'optional'
    | 'dark_blue'
    | CSSProperties['backgroundColor'];
};

export const Badge = (props: Partial<BadgeProps>) => {
  const {title, counter, color = 'primary', ...rest} = props;
  return (
    <BadgeStyled {...{...rest, color, counter}}>
      {title}
      {counter !== undefined && <Counter>{counter}</Counter>}
    </BadgeStyled>
  );
};
