import styled from 'styled-components';

export const Card = styled.article`
  max-width: 480px;
  width: 100%;
  height: fit-content;
`;

export const Video = styled.iframe`
  width: 100%;
  height: 268px;
  border-radius: 4px;

  display: block;
  object-fit: cover;
  
  @media screen and (max-width: 500px) {
    height: auto;
  }
`;

export const Title = styled.p`
  margin: 0 0 16px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: var(--additional-color);
`;
