import {DUTY_SHIFTS_QUERY} from './../../../gql/index';
import {useQuery} from '@apollo/client';
import {DutyShifts} from '../../../gql/queries/__generated__/DutyShifts';

export function useHeader() {
  const {data, loading} = useQuery<DutyShifts>(DUTY_SHIFTS_QUERY);
  return {
    loading,
    data:
      data?.me.team?.activeShifts == null
        ? []
        : data.me.team.activeShifts.map((shift) => ({
            id: Number(shift.group.id),
            name: shift.group.short_name,
            active: true,
            person: {
              fullName: shift.user.profile.full_name || '',
              nickName: shift.user.profile.nickname || '',
              telegram: shift.user.profile.account_telegram || '',
              phones: shift.user.profile.phone_number || '',
              twitter: shift.user.profile.account_twitter || '',
              facebook: shift.user.profile.account_facebook || '',
              vk: shift.user.profile.account_vkontakte || '',
              email: shift.user.email || '',
            },
          })),
  };
}
