import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {CheckBoxLabelStyled, Input, Wrapper} from './Switch.style';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  rules?: UseControllerProps['rules'];
  disabled?: boolean;
  onCallbackChange?(): void;
}

export function Switch<FormField extends FieldValues>(props: Props<FormField>) {
  const {field} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper tabIndex={0}>
      <Input
        id={props.name}
        name={props.name}
        type="checkbox"
        checked={Boolean(field.value)}
        disabled={props.disabled}
        onChange={() => {
          field.onChange(!Boolean(field.value));
          props.onCallbackChange?.();
        }}
        onBlur={field.onBlur}
      />
      <CheckBoxLabelStyled htmlFor={props.name} />
    </Wrapper>
  );
}
