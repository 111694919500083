import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  
  > *:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    > *:not(:last-child) {
      margin-right: 0;
    }
    
    > *:not(:first-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Form = styled.form`
  flex: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-top: 27px;
`;

export const Main = styled.div`
 
  flex: auto;

  > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const List = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

interface ITitle {
  hasMargin?: boolean
}

export const Title = styled.h2<ITitle>`
  margin: 0 0 0 ${p => p.hasMargin ? "24" : 0}px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: var(--text-color);
`;

export const Circumstances = styled.section`
 
`;

export const CircumstancesText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;

export const Result = styled.section`
  drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.06))
`;


export const ResultTitle = styled.h3`
  margin: 0;
  
  padding: 20px 24px;
  width: 100%;

  background: #E7E7E7;
  border-radius: 8px 8px 0 0;

  box-sizing: border-box;
`;

export const ResultBlock = styled.div`
  padding: 24px;
  
  background-color: #fff;
`;

export const ResultTooltip = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
 
  color: #000000;
  
  margin: 0 0 24px;
`;
