import React from 'react';
import {useForm} from 'react-hook-form';
import Preloader from '../../elements/Preloader/Preloader';
import {Url} from './styles';
import {Buttons, Form, Tooltip, Wrapper} from './styles';
import {FormFields} from './types';
import {useMapHandler} from './hooks';
import { ModalWrapper } from '../../elements/ModalWrapper/ModalWrapper';
import { TextField } from '../../elements/TextField/TextField';
import { Button } from '../../elements/Button/Button';

interface Props {
  onAdd(data: FormFields): Promise<void>;
  onAddSuccess?(): void;
  onModalClose(): void;
}

export default function AddMapModal(props: Props) {
  const {loading, handleAddMap} = useMapHandler({
    onAddSuccess: props.onAddSuccess,
    onAdd: props.onAdd
  })

  const {handleSubmit, register, formState} = useForm<FormFields>({
    defaultValues: {
      url: '',
      name: '',
    },
    mode: 'onSubmit',
  });

  return (
    <ModalWrapper title="Добавить карту" onModalClose={props.onModalClose}>
        {loading ? (
          <Preloader />
        ) : (
          <Form onSubmit={handleSubmit(handleAddMap)} onReset={props.onModalClose}>
            <Wrapper>
              <Url>
                <TextField
                  register={register('url', {
                    required: 'Поле обязательно для заполнения.',
                    maxLength: {
                      value: 20000,
                      message: 'Превышено количество символов',
                    },
                  })}
                  error={formState.errors.url}
                  label="Ссылка на файл"
                  placeholder="http://"
                />
                <Tooltip>
                  Вы можете указать ссылку на файл на таких хостингах, как
                  Яндекс.Диск, Google Drive, Dropbox и др.
                </Tooltip>
              </Url>

              <TextField
                register={register('name', {
                  required: 'Поле обязательно для заполнения.',
                  maxLength: {
                    value: 300,
                    message: 'Превышено количество символов',
                  },
                })}
                error={formState.errors.name}
                label="Название карты"
                placeholder="Введите ваше сообщение"
              />
            </Wrapper>
            <Buttons>
              <Button
                title="Отмена"
                type="reset"
                variant={'outlined'}
                color={'primary'}
                fullWidth
              />
              <Button title="Добавить" type="submit" fullWidth />
            </Buttons>
          </Form>
        )}
    </ModalWrapper>
  );
}
