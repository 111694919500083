import React, {CSSProperties} from 'react';
import {Wrapper} from './styles';

interface Props {
  value?: string;
  color?: CSSProperties["backgroundColor"];
  hideSmallSize?: boolean;
  hideFullSize?: boolean;
}

export default function Status(props: Props) {
  if (props.value == null || props.value === "") return null;


  return (
    <Wrapper
      color={props.color}
      hideSmallSize={props.hideSmallSize}
      hideFullSize={props.hideFullSize}
    >
      {props.value}
    </Wrapper>
  )
}
