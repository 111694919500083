import {CSSProperties} from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  width: fit-content;
`;

interface ILabel {
  bColor: CSSProperties['backgroundColor'];
}

export const Label = styled.div<ILabel>`
  margin: 0;
  width: fit-content;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: ${(p) => p.bColor};
  color: #333;

  cursor: pointer;

  svg {
    flex-shrink: 0;
    margin-left: 10px;
  }

  svg path {
    fill: #333;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

interface IOptionContainer {
  open: boolean;
}

export const OptionsContainer = styled.div<IOptionContainer>`
  min-width: 116px;

  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 30px;
  padding: 8px;
  display: ${(p) => (p.open ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  z-index: 3;

  filter: ${(p) =>
    p.open ? 'drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.06))' : 'none'};
  border-radius: 4px;
`;

export const Options = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > *:not(:last-child) {
    margin-bottom: 2px;
  }
`;

interface IOption {
  bColor: CSSProperties['backgroundColor'];
}

export const Option = styled.li<IOption>`
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 14px;
  width: 300px;
  line-height: 16px;

  color: #333;
  border-radius: 4px;

  &:hover {
    background-color: #f3f3f3;
  }

  &:not([aria-disabled='true']):hover {
    //not found in design
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

export const StatusColor = styled.div<IOption>`
  background: ${(p) => p.bColor};
  clip-path: circle(50%);
  height: 1em;
  margin-left: 1rem;
  min-width: 1em;
`;

export const Group = styled.li`
  padding: 0 8px;

  &:not(:first-child) {
    margin-top: 6px;
  }

  ul {
    padding-left: 8px;
  }
`;

export const TitleGroup = styled.p`
  margin: 0 0 6px 0;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #333;
`;
