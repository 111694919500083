import {toast} from 'react-toastify';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {APPLICATION_CREATE_MISSING_MUTATION} from '../../../../../../gql';

import {
  CreateMissing,
  CreateMissingVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateMissing';
import {useServerErrors} from '../../../../../../hooks/useErrors.hooks';
import {Id} from '../../../../../../types/auxiliary.types';
import {createDate_DTO} from '../../../../../../utils/Date.utils';
import {Step3FormFields as FormFields3} from '../../../types';
import {FormFields} from './Step3.types';

export function useHandler(
  id: Id,
  setServerError: UseFormSetError<FormFields>
) {
  const [createMissing] = useMutation<CreateMissing, CreateMissingVariables>(
    APPLICATION_CREATE_MISSING_MUTATION
  );

  const {addServerError} = useServerErrors(
    {
      'input.fullName': 'lastName',
      'input.lastName': 'firstName',
      'input.secondName': 'middleName',
      'input.sex': 'gender',
      'input.birthdayString': 'birthdayString',
      'input.havingPhoneStatus': 'hasPhone',
      'input.availabilityPhoneStatus': 'availablePhone',
      'input.phoneNumber': 'phone',
      'input.appearance': 'appearance',
      'input.diseases': 'diseases',
      'input.clothes': 'cloth',
      'input.things': 'withSelf',
      'input.additionalInformation': 'additionalInformation',
    },
    setServerError
  );

  const handleSubmit3Step = useCallback(
    async (applicantId: Id, data: FormFields3, callback?: () => void) => {
      await createMissing({
        variables: {
          input: {
            issueId: Number(id),
            bearerId: Number(applicantId),
            lastName: data.lastName,
            firstName: data.firstName,
            ...(data.middleName != null &&
              data.middleName !== '' && {
                secondName: data.middleName,
              }),
            sex: data.gender === 'm' ? 'male' : 'female',
            birthdayString:
              data.birthdayString == null ? '' : data.birthdayString,
            havingPhoneStatus: data.hasPhone.toString(),
            availabilityPhoneStatus: data.availablePhone.toString(),
            ...(data.phone != null &&
              data.phone !== '' && {
                phoneNumber: data.phone,
              }),
            ...(data.appearance != null &&
              data.appearance !== '' && {
                appearance: data.appearance,
              }),
            ...(data.cloth != null &&
              data.cloth !== '' && {
                clothes: data.cloth,
              }),
            ...(data.withSelf != null &&
              data.withSelf !== '' && {
                things: data.withSelf,
              }),
            ...(data.additionalInformation != null &&
              data.additionalInformation !== '' && {
                additionalInformation: data.additionalInformation,
              }),
            ...(data.diseases != null && {
              diseases: data.diseases,
            }),
          },
        },
      })
        .then(() => {
          callback?.();
          window.location.reload();
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
        });
    },
    [createMissing, id]
  );

  return {handleSubmit3Step};
}
