import React from 'react';
import {LogoImg} from './Logo.styles';
import logoSvg from '../../../assets/svg/logo.svg';
import smallLogo from '../../../assets/svg/small-logo.svg';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  variant?: 'small' | 'medium' | 'big';
}

export const Logo = ({variant = 'big', style, className}: Props) => {
  const svg = variant === 'small' ? smallLogo : logoSvg;
  return (
    <LogoImg style={style} className={className} variant={variant} src={svg} />
  );
};
