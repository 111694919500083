import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  
  > *:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(216, 226, 237, 0.5);
    margin-bottom: 24px;
  }
`;

export const MainInfo = styled.div``

export const StatusWrapper = styled.div`
  margin-top: 16px
`;

export const FieldsWrapper = styled.div`
  margin-top: 16px
`;

export const ResponsibleWrapper = styled.div`

`;

export const Title = styled.h2`
  margin: 0 0 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
 
  color: #333;
`;

export const UserWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Responsible = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;


export const Field = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);

  &::after {
    content: ":";
  }
`;

export const Badge = styled.p`
  width: fit-content;
  margin: 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #333;
  padding: 4px 8px;
  background: var(--primary-border-color);
  border-radius: 4px;
`;

export const ControlGroupWrapper = styled.div``;
