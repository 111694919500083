import React, {useState} from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {ChangeButtons} from '../../shared/changeButtons/component';
import {AddReport} from '../../modals/AddReport/AddReport';
import {EditReport} from '../../modals/EditReport/EditReport';
import {DateField} from '../../shared/date/component';
import {HeadingInfo} from '../../shared/headingInfo/component';
import {useActions, useReports} from './Reports.hooks';
import {
  Wrapper,
  Number,
  Card,
  List,
  Header,
  Description,
  SettingsBlock,
  MiddleBlock,
} from './Reports.styles';
import {DeleteModal} from '../../modals/Delete/component';

export function Reports() {
  const [modal, setModal] = useState<
    | {
        id: Id;
        description: string;
      }
    | {}
    | null
  >(null);
  const [show, setShow] = useState<{id: Id} | null>(null);

  const {loading, data, fetch} = useReports();
  const {loading: actionLoading, handleDeleteReport} = useActions(fetch);

  const deleteReport = (id: Id) => {
    handleDeleteReport(id);
    setShow(null);
  };

  if (loading) return <Preloader />;
  return (
    <Wrapper>
      {modal != null &&
        ('id' in modal ? (
          <EditReport
            description={'description' in modal ? modal.description : ''}
            reportId={modal.id}
            onEditSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ) : (
          <AddReport
            onAddSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ))}
      {show != null &&
        ('id' in show ? (
          <DeleteModal
            confirm={() => deleteReport(show.id)}
            close={() => setShow(null)}
          />
        ) : null)}
      <AddButton
        disabled={actionLoading || loading}
        buttonLabel="Добавить отчет"
        typeButton="button"
        addItem={() => setModal({})}
      />
      <List>
        {data.map((card) => (
          <Card key={card.id}>
            <Header>
              <Number>#{card.number}</Number>
              <SettingsBlock>
                <ChangeButtons
                  edit={{
                    disabled: actionLoading || loading,
                    onClick: () =>
                      setModal({id: card.id, description: card.description}),
                  }}
                  delete={{
                    disabled: actionLoading || loading,
                    // onClick: () => handleDeleteReport(card.id),
                    onClick: () => setShow({id: card.id}),
                  }}
                />
                <DateField
                  displayShown="small"
                  label="Добавлено"
                  date={card.createAt}
                />
              </SettingsBlock>
            </Header>
            <MiddleBlock>
              <HeadingInfo
                author={card.author}
                dates={[
                  {
                    label: 'Добавлено',
                    date: card.createAt,
                  },
                  {
                    label: 'Отредактировано',
                    date: card.editAt,
                  },
                ]}
              />
            </MiddleBlock>
            <Description>
              {card.description.split('\n').map((item, key) => {
                return (
                  <Description key={key}>
                    {item} <br />
                  </Description>
                );
              })}
            </Description>
          </Card>
        ))}
      </List>
    </Wrapper>
  );
}
