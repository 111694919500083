import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: auto;
  overflow-y: hidden;

  height: 100%;
`;

export const Title = styled.h2`
  margin: 0 0 28px;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--text-color);
`;

export const FilterWrapper = styled.div`
  margin-top: 16px;
`;

export const List = styled.div`
  margin-top: 16px;
  flex: auto;

  overflow-y: auto;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
`;
