import React from 'react';
import {useForm} from 'react-hook-form';
import {useAuthenticatedUser} from '../../../../../hooks/useAuthenticatedUser';
import PaperPlaneIcon from '../../../../icons/PaperPlaneIcon';
import {Avatar} from '../../../Avatar/Avatar';
import {
  AvatarContainer,
  Container,
  Form,
  MainInput,
  SendButton,
  Wrapper,
} from './styles';
import {FormFields} from './types';

interface Props {
  onAddMessage: (data: FormFields) => Promise<any>;
  isLoading: boolean;
}

export function SendingComment(props: Props) {
  const {user} = useAuthenticatedUser();
  const {register, handleSubmit, reset} = useForm<FormFields>({
    defaultValues: {
      message: '',
    },
  });

  if (user == null) return null;

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        props.onAddMessage(data).then(() => reset())
      )}>
      <Container>
        <Wrapper>
          <AvatarContainer>
            <Avatar size="small" fullName={user.fullName} />
          </AvatarContainer>
          <MainInput
            placeholder="Ваш комментарий..."
            {...register('message', {
              required: 'Поле обязательно к заполнению',
            })}
            autoComplete="off"
          />
          <SendButton disabled={props.isLoading}>
            <PaperPlaneIcon />
          </SendButton>
        </Wrapper>
      </Container>
    </Form>
  );
}
