import React from 'react';
import {useAddGroupFile} from './hooks';
import {AddFile as AddGroupFile} from '../../../../../modals/AddFile/AddFile';

interface Props {
  file: File;
  fetch(): void;
  onModalClose(): void;
  groupId: number;
}
const AddFile = (props: Props) => {
  const {handleAddFile, requestSending} = useAddGroupFile(() => {
    props.fetch();
    props.onModalClose();
  }, props.groupId);

  return (
    <AddGroupFile
      file={props.file}
      onModalClose={props.onModalClose}
      onSubmit={handleAddFile}
      requestSending={requestSending}
    />
  );
};

export default AddFile;
