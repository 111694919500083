import {useQuery, useMutation} from '@apollo/client';

import {useParams, useNavigate} from 'react-router-dom';
import {CALL_DELETE_MUTATION} from './../../../../../../gql/index';
import {CALL_DESCRIPTION_QUERY} from '../../../../../../gql';
import {
  Description,
  DescriptionVariables,
} from '../../../../../../gql/call/queries/__generated__/Description';
import {
  DeleteCallVariables,
  DeleteCall,
} from '../../../../../../gql/call/mutations/__generated__/DeleteCall';
import {Id} from '../../../../../../types/auxiliary.types';
import {useState} from 'react';
import {toast} from 'react-toastify';

export function useCall() {
  const params = useParams<{callId?: string}>();

  const {data, loading} = useQuery<Description, DescriptionVariables>(
    CALL_DESCRIPTION_QUERY,
    {
      variables: {
        id: Number(params.callId),
      },
      skip: params.callId == null || params.callId === '',
    }
  );

  return {
    data:
      data?.call == null
        ? null
        : {
            id: data.call.id || '',
            description: data.call.description || '',
          },
    loading,
  };
}

export function useDeleteCall(onChangeSuccess?: () => void) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [deleteCall] = useMutation<DeleteCall, DeleteCallVariables>(
    CALL_DELETE_MUTATION
  );
  function handleDeleteCall(id: Id) {
    setLoading(true);

    deleteCall({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        toast('Звонок успешно удален');
        if (onChangeSuccess != null) {
          new Promise(onChangeSuccess).then(() => setLoading(false));
        }
        navigate('/calls');
      })
      .catch((e) => {
       toast(e.message, {type: 'error'});
      })
      .finally(() => {
        if (onChangeSuccess == null) {
          setLoading(false);
        }
      });
  }
  return {
    loading,
    handleDeleteCall,
  };
}
