import React from 'react';
import {Video} from '../../../../modals/Video/component';
import {useAddVideoHandler} from './AddVideo.hooks';

interface Props {
  onAddSuccess(): void;
  onModalClose(): void;
}

export function AddVideo(props: Props) {
  const {handleAddVideo, requestSending} = useAddVideoHandler(props.onAddSuccess);

  return (
    <Video
      title="Добавить видео"
      requestSending={requestSending}
      submitLabel="Добавить"
      onSubmit={handleAddVideo}
      onModalClose={props.onModalClose}
    />
  )
}
