import React from 'react';
import {SvgStyled} from './Icons.styles';

export const UrgentlyIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58166 0 0 3.58178 0 8C0 12.4185 3.58166 16 8 16C12.4183 16 16 12.4185 16 8C16 3.58178 12.4183 0 8 0ZM8 12C7.44769 12 7 11.5525 7 11C7 10.4478 7.44769 10 8 10C8.55231 10 9 10.4478 9 11C9 11.5525 8.55231 12 8 12ZM9 8.5H7L6.5 4.5C6.5 4.22388 6.72388 4 7 4H9C9.27612 4 9.5 4.22388 9.5 4.5L9 8.5Z"
        fill="#EE8C8C"
      />
    </SvgStyled>
  );
};
