import React from 'react';
import {DoubleIcon} from '../../icons/DoubleIcon';
import {PencilIcon} from '../../icons/PencilIcon';
import {ReverseArrowIcon} from '../../icons/ReverseArrowIcon';
import {TrashIcon} from '../../icons/TrashIcon';
import {useProfile} from '../../screens/Profile/edit/hooks';
import {BlockSidebarWrapper} from '../BlockSidebarWrapper/BlockSidebarWrapper';
import LetterModal from '../LetterModal/SendLetter';
import TelegramModal from '../TelegramModal/TelegramModal';
import {Wrapper, Action, IconWrapper, Title} from './ActionBlock.styles';

type Callback = () => void;

interface Props {
  title: string;
  sendData?: {
    label: string;
    onClick?: Callback;
  };
  onDouble?: Callback;
  onDelete?: {
    label: string;
    onClick?: Callback;
  };
  onEdit?: Callback;
}

export function ActionBlock(props: Props) {
  const {data} = useProfile();

  return (
    <BlockSidebarWrapper title={props.title} shouldHideTitleBigSize type="full">
      <Wrapper>
        {props.sendData != null && (
          <Action
            type="button"
            color="blue"
            disabled={props.sendData.onClick == null}
            onClick={props.sendData.onClick}>
            <IconWrapper data-icon>
              <ReverseArrowIcon />
            </IconWrapper>
            <Title data-name>{props.sendData.label}</Title>
          </Action>
        )}
        {props.onDouble != null && (
          <Action type="button" color="blue" onClick={props.onDouble}>
            <IconWrapper data-icon>
              <DoubleIcon />
            </IconWrapper>
            <Title data-name>Отметить как дубль</Title>
          </Action>
        )}
        {props.onDelete != null && (
          <Action type="button" color="pink" onClick={props.onDelete.onClick}>
            <IconWrapper data-icon>
              <TrashIcon />
            </IconWrapper>
            <Title data-name>Удалить</Title>
          </Action>
        )}
        {props.onEdit != null && (
          <Action type="button" color="orange" onClick={props.onEdit}>
            <IconWrapper data-icon>
              <PencilIcon />
            </IconWrapper>
            <Title data-name>Редактировать</Title>
          </Action>
        )}
        <LetterModal title={'Письмо'} labelSubmit={'Выберите'} />
        {data.canSendTelegram ? (
          <TelegramModal title={'Выберите'} labelSubmit={'Выберите'} />
        ) : null}
      </Wrapper>
    </BlockSidebarWrapper>
  );
}
