import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {GROUP_ADD_IMAGE_MUTATION} from '../../../../../../gql';
import {
  CreateGroupPhoto,
  CreateGroupPhotoVariables,
} from '../../../../../../gql/mutations/__generated__/CreateGroupPhoto';

export const useAddPhoto = (fetch: () => void) => {
  const [requestSending, setRequestSending] = useState(false);
  const params = useParams<{groupId?: string}>();
  const [addPhotoMutation] = useMutation<
    CreateGroupPhoto,
    CreateGroupPhotoVariables
  >(GROUP_ADD_IMAGE_MUTATION);

  const handleAddPhoto = useCallback(
    async (data: {file: File; description: string}) => {
      setRequestSending(true);

      await addPhotoMutation({
        variables: {
          input: {
            groupId: Number(params.groupId),
            file: data.file,
            name: data.description,
          },
        },
      })
        .then(() => {
          fetch();
          toast('Фотография успешно добавлена!');
        })
        .catch((e) => toast(e.message))
        .finally(() => setRequestSending(false));
    },
    [addPhotoMutation, fetch]
  );
  return {handleAddPhoto, requestSending};
};
