import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ActionBlock as ActionBlockBase} from '../../../../../elements/ActionBlock/ActionBlock';
import {AddDoubleModal} from '../../modals/AddDouble/component';
import {DeleteModal} from '../../modals/Delete/component';
import Transfer from '../../modals/transfer/component';
import {useDeleteIssue} from './hooks';

export function ActionBlock() {
  const [modalName, setModalName] = useState<
    'sendData' | 'deleteIssue' | 'addDouble' | null
  >(null);

  const params = useParams<{applicationId?: string}>();
  const navigate = useNavigate();

  const {handleDeleteIssue} = useDeleteIssue();

  function renderModal() {
    switch (modalName) {
      case 'sendData':
        return (
          <Transfer
            onChangeSuccess={() => setModalName(null)}
            onClose={() => setModalName(null)}
          />
        );
      case 'deleteIssue':
        return (
          <DeleteModal
            confirm={() => handleDeleteIssue(Number(params.applicationId))}
            close={() => setModalName(null)}
          />
        );
      case 'addDouble':
        return (
          <AddDoubleModal
            onSubmit={() => {
              setModalName(null);
              setTimeout(() =>
                toast('Родительская заявка успешо установлена!')
              );
            }}
            close={() => setModalName(null)}
          />
        );
      default: {
        return null;
      }
    }
  }

  return (
    <>
      {renderModal()}
      <ActionBlockBase
        title="Действия с заявкой"
        onDouble={() => setModalName('addDouble')}
        sendData={{
          label: 'Передать заявку',
          onClick: () => setModalName('sendData'),
        }}
        onEdit={() => navigate(`/applications/edit/${params.applicationId}`)}
        onDelete={{
          label: 'Удалить заявку',
          onClick: () => setModalName('deleteIssue'),
        }}
      />
    </>
  );
}
