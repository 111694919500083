import styled from 'styled-components';

export const Wrapper = styled.article`
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  margin-bottom: 1rem;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 16px 24px 16px 16px;
  box-sizing: border-box;

  > :not(:last-child) {
    margin-right: 10px;
  }
  @media screen and (max-width: 900px) {
    align-items: start;
    flex-direction: column;

    > :not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const ContactBlock = styled.div`
  color: #7d7d7d;
`;

export const ContactBlockTitle = styled.p`
  margin: 0;
`;

export const ContactBlockItem = styled.a`
  margin: 0;
  display: block;
  color: #376092;
  text-decoration: none;
`;

export const Description = styled.p`
  font-size: 1rem;
  color: #333;
  margin-top: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: around-center;
`;

export const Title = styled.p`
  margin: 0;
  display: flex;
  justify-content: around-center;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  margin-right: 10px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const FlexBox = styled.div`
  flex: 1;
`;

export const Subtitle = styled.div`
  color: #7d7d7d;
  display: flex;
  font-weight: 400;
  font-size: 14px;
`;

export const AddressBlock = styled.p`
  color: #333;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

export const SubtitleItem = styled.p`
  margin: 10px 0 10px 0;
`;
