import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;

  margin-bottom: 48px;
`;

export const Button = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  background-color: #fff;

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 16px;
    height: 17px;

    path {
      stroke: #376092;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  text-align: center;

  color: #000000;

  margin: 0 0 0 32px;
`;

export const RegistrationFormWrapper = styled.div`
  padding-left: 84px;

  @media screen and (max-width: 980px) {
    padding-left: 0;
  }
`;

export const FakePageWrapper = styled.div`
  position: absolute;
  
  background-color: var(--primary-background-color);
  top: 0;
  left: 0;
  
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageWrapper = styled.div`
  max-width: 445px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Message = styled.p`
  margin: 45px 0 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
 
  text-align: center;

  color: #333;
`;
