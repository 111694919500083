import React, {useState} from 'react';
import {useOutside} from '../../../../../hooks/htmlElement.hooks';
import {NotificationsIcon} from '../../../../icons/NotificationsIcon';
import {IconWrapper} from '../User/User.styles';
import * as S from './styles';

const Notifications = ({notifications}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  const {contentRef} = useOutside<HTMLDivElement>(handleIconClick);

  return (
    <S.Wrapper>
      <IconWrapper
        onClick={handleIconClick}
        showBadge={notifications.length !== 0}>
        <NotificationsIcon />
      </IconWrapper>
      {isOpen && (
        <S.NotificationsDropdown ref={contentRef}>
          <S.Arrow />
          {notifications.length === 0 ? (
            <S.EmptyNotification>Уведомлений нет</S.EmptyNotification>
          ) : (
            <S.NotificationList>
              {notifications.map((item: any, index: number) => (
                <S.NotificationItem key={index}>
                  <a target="_blank" rel="noreferrer" href={item.link}>
                    {item.type} #{item.id}
                  </a>
                </S.NotificationItem>
              ))}
            </S.NotificationList>
          )}
        </S.NotificationsDropdown>
      )}
    </S.Wrapper>
  );
};

export default Notifications;
