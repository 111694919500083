import React from 'react';
import {ErrorLayout} from '../../layouts/ErrorLayout/ErrorLayout';

export function Screen404() {
  return (
    <ErrorLayout
      title="404"
      message="Данной страницы не существует"
    />
  );
}

