import styled from 'styled-components';

export const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

type ColorType = 'blue' | 'orange' | 'pink';

const config: Record<ColorType, string> = {
  blue: 'var(--additional-color)',
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IAction {
  color: ColorType;
}

export const Action = styled.button<IAction>`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  [data-icon='true'] {
    background-color: ${(p) => config[p.color]};
  }
  [data-name='true'] {
    color: ${(p) => config[p.color]};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;
  margin-right: 10px;

  svg {
    fill: #fff;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;
