import React, {useMemo} from 'react';
import {Control, UseFormRegister, useFormState} from 'react-hook-form';
import {UseFormSetValue} from 'react-hook-form/dist/types/form';
import {FieldPath} from 'react-hook-form/dist/types/path';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {
  capitalized,
  lowercase,
  StringPipe,
  stringPipe,
  trim,
} from '../../../../../utils/pipes.utils';
import DateField from '../../../../elements/DateField/DateField';
import {Switch} from '../../../../elements/SwitchButton/Switch';
import {TextField} from '../../../../elements/TextField/TextField';
import {FormFields} from '../../RegistrationFrom.types';
import {BlockWrapper} from '../BlockWrapper/BlockWrapper';
import LabelCheckbox from '../LabelCheckbox/LabelCheckbox';
import {useDict} from './PersonalInfo.hooks';
import {Wrapper, CheckboxWrapper, CheckboxLabel} from './PersonalInfo.styles';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  setValue: UseFormSetValue<FormFields>;
}

const nickPipe = stringPipe(trim);
const namePipe = stringPipe(trim, lowercase, capitalized);

export const PersonalInfo = (props: Props) => {
  const currentDate = new Date();
  const lastDate = new Date(
    `${currentDate.getFullYear() - 15}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`
  );
  const {driverCategoriesDict} = useDict();

  const {errors} = useFormState({
    control: props.control,
  });
  const {setValue} = props;

  const onChangeFn = useMemo(
    () => (name: FieldPath<FormFields>, pipe: StringPipe) => (e: any) => {
      setValue(name, pipe(e.target.value ?? ''));
    },
    [setValue]
  );

  return (
    <BlockWrapper title="Личные данные">
      <CheckboxWrapper>
        <Switch
          name={'additionalInfo.playNotificationSound'}
          control={props.control}
        />
        <CheckboxLabel>Звуковые уведомления о событиях</CheckboxLabel>
      </CheckboxWrapper>
      <CheckboxWrapper>
        <Switch
          name={'additionalInfo.sendNotificationEmail'}
          control={props.control}
        />
        <CheckboxLabel>Уведомления о событиях на почту</CheckboxLabel>
      </CheckboxWrapper>

      <Wrapper>
        <TextField
          register={props.register('personalInfo.lastName', {
            required: 'Поле обязательно для заполнения.',
            pattern: {
              value: RegExpConstants.name,
              message: 'Некорректный формат',
            },
            onChange: onChangeFn('personalInfo.lastName', namePipe),
          })}
          error={errors.personalInfo?.lastName}
          label="Фамилия *"
          placeholder="Игоревский"
        />
        <TextField
          register={props.register('personalInfo.firstName', {
            required: 'Поле обязательно для заполнения.',
            pattern: {
              value: RegExpConstants.name,
              message: 'Некорректный формат',
            },
            onChange: onChangeFn('personalInfo.firstName', namePipe),
          })}
          error={errors.personalInfo?.firstName}
          label="Имя *"
          placeholder="Игорь"
        />
        <TextField
          register={props.register('personalInfo.middleName', {
            pattern: {
              value: RegExpConstants.name,
              message: 'Некорректный формат',
            },
            onChange: onChangeFn('personalInfo.middleName', namePipe),
          })}
          error={errors.personalInfo?.middleName}
          label="Отчество"
          placeholder="Давыдович"
        />
        <TextField
          register={props.register('personalInfo.nick', {
            onChange: onChangeFn('personalInfo.nick', nickPipe),
          })}
          error={errors.personalInfo?.nick}
          label="Ник"
          placeholder="Floral"
        />
        <DateField
          label="Дата рождения *"
          name="personalInfo.birthday"
          format="dd.MM.yyyy"
          maxDate={lastDate}
          startDate={lastDate}
          control={props.control}
          rules={{
            required: 'Поле обязательно для заполнения.',
          }}
        />
      </Wrapper>
      <LabelCheckbox
        label="Категория ВУ"
        control={props.control}
        name="personalInfo.category"
        options={driverCategoriesDict}
      />
    </BlockWrapper>
  );
};
