import React, {Fragment, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  useMissings,
  useTelegram,
} from '../../screens/Application/ApplicationView/components/ActionBlock/telegram.hook';
import {Button} from '../Button/Button';
import {Label} from '../Label/Label';
import SelectField from '../SelectField/SelectField';
import * as S from '../LetterModal/SendLetter.styles';
import {TelegramIcon} from '../../icons/TelegramIcon';

interface FormFields {
  dispatchId: any;
  missingIds?: any;
  note: any;
}

interface Props {
  title: string;
  loading?: boolean;
  labelSubmit: string;
  defaultValues?: FormFields;
  onAddSuccess?(): void;
}

export default function TelegramModal(props: Props) {
  const [open, setOpen] = useState(false);

  const {missingList, dispatch} = useMissings();

  const {submit: telegramSubmit} = useTelegram(() => setOpen(false));

  const {control, handleSubmit, register} = useForm({
    defaultValues: props.defaultValues || {
      dispatchId: '',
      missingIds: '',
      note: '',
    },
    mode: 'all',
  });

  const styles = {
    border: '1px solid var(--primary-border-color)',
    color: 'var(--primary-text-color)',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '14px 16px',
    lineHeight: '1.3571428571',
    outline: 'none',
    marginTop: '1rem',
    width: '400px',
  };

  return (
    <S.Wrapper>
      <S.Action type="button" color="blue" onClick={() => setOpen(true)}>
        <S.IconWrapper data-icon>
          <TelegramIcon />
        </S.IconWrapper>
        <span data-name style={{fontWeight: 700}}>
          Отправить в телеграм
        </span>
      </S.Action>
      {open && (
        <S.ModalWraper
          title={'Отправка письма'}
          onModalClose={() => setOpen(false)}>
          <S.Form onSubmit={handleSubmit(telegramSubmit)}>
            <Fragment>
              <S.SampleBlock>
                <SelectField
                  name="missingIds"
                  options={Object.values(missingList).map((c) => ({
                    value: c?.id == null ? '' : c.id,
                    label:
                      c?.first_name == null
                        ? ''
                        : c.first_name + ' ' + c.last_name,
                  }))}
                  placeholder="Выберите"
                  label="Выберите пропавших"
                  control={control}
                  rules={{
                    required: 'Поле обязательно для заполнения',
                  }}
                />
              </S.SampleBlock>
              <S.ThemeBlock>
                <SelectField
                  label={'Выберите рассылку'}
                  placeholder={'Выберите'}
                  control={control}
                  name={'dispatchId'}
                  //@ts-ignore
                  options={Object.values(dispatch).map((c) => ({
                    value: c?.id == null ? '' : c.id,
                    label: c?.name == null ? '' : c.name,
                  }))}
                />
              </S.ThemeBlock>
              <S.ContentBlock>
                <Label label={'Примечание'}>
                  <textarea
                    placeholder={'Введите примечание'}
                    autoFocus={false}
                    style={{...styles, resize: 'vertical'}}
                    {...register('note', {
                      required: 'Поле обязательно для заполнения',
                      maxLength: {
                        value: 1000,
                        message: 'Количество символов не может превышать 1000',
                      },
                    })}
                  />
                </Label>
              </S.ContentBlock>
              <S.ButtonsWrapper>
                <Button
                  title="Отмена"
                  variant="outlined"
                  type="reset"
                  onClick={() => setOpen(false)}
                />
                <Button type="submit" title="Отправить" />
              </S.ButtonsWrapper>
            </Fragment>
          </S.Form>
        </S.ModalWraper>
      )}
    </S.Wrapper>
  );
}
