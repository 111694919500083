import React from 'react';
import {SvgStyled} from './Icons.styles';

export const TrashIcon = () => {
  return (
    <SvgStyled
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 2.50061H1.5C1.36739 2.50061 1.24021 2.55329 1.14645 2.64706C1.05268 2.74083 1 2.868 1 3.00061C1 3.13322 1.05268 3.2604 1.14645 3.35416C1.24021 3.44793 1.36739 3.50061 1.5 3.50061H2.5V9.50061C2.50012 9.8984 2.65819 10.2799 2.93947 10.5611C3.22075 10.8424 3.60221 11.0005 4 11.0006H8C8.39779 11.0005 8.77926 10.8424 9.06054 10.5612C9.34183 10.2799 9.4999 9.8984 9.5 9.50061V3.50061H10.5C10.6326 3.50061 10.7598 3.44793 10.8536 3.35416C10.9473 3.2604 11 3.13322 11 3.00061C11 2.868 10.9473 2.74083 10.8536 2.64706C10.7598 2.55329 10.6326 2.50061 10.5 2.50061ZM5.5 8.00061C5.5 8.13322 5.44732 8.2604 5.35355 8.35416C5.25979 8.44793 5.13261 8.50061 5 8.50061C4.86739 8.50061 4.74022 8.44793 4.64645 8.35416C4.55268 8.2604 4.5 8.13322 4.5 8.00061V5.50061C4.5 5.368 4.55268 5.24083 4.64645 5.14706C4.74022 5.05329 4.86739 5.00061 5 5.00061C5.13261 5.00061 5.25979 5.05329 5.35355 5.14706C5.44732 5.24083 5.5 5.368 5.5 5.50061V8.00061ZM7.5 8.00061C7.5 8.13322 7.44732 8.2604 7.35355 8.35416C7.25979 8.44793 7.13261 8.50061 7 8.50061C6.86739 8.50061 6.74022 8.44793 6.64645 8.35416C6.55268 8.2604 6.5 8.13322 6.5 8.00061V5.50061C6.5 5.368 6.55268 5.24083 6.64645 5.14706C6.74022 5.05329 6.86739 5.00061 7 5.00061C7.13261 5.00061 7.25979 5.05329 7.35355 5.14706C7.44732 5.24083 7.5 5.368 7.5 5.50061V8.00061Z"
        fill="white"
      />
      <path
        d="M5 2.00061H7C7.13261 2.00061 7.25979 1.94793 7.35355 1.85416C7.44732 1.76039 7.5 1.63322 7.5 1.50061C7.5 1.368 7.44732 1.24082 7.35355 1.14706C7.25979 1.05329 7.13261 1.00061 7 1.00061H5C4.86739 1.00061 4.74021 1.05329 4.64645 1.14706C4.55268 1.24082 4.5 1.368 4.5 1.50061C4.5 1.63322 4.55268 1.76039 4.64645 1.85416C4.74021 1.94793 4.86739 2.00061 5 2.00061Z"
        fill="white"
      />
    </SvgStyled>
  );
};
