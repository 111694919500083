// https://realadmin.ru/coding/sklonenie-na-javascript.html

export function declOfNum(
  number: number,
  [word_1, word_2, word_5]: [string, string, string]
): string {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) return `${number} ${word_5}`;
  if (n1 > 1 && n1 < 5) return `${number} ${word_2}`;
  if (n1 === 1) return `${number} ${word_1}`;

  return `${number} ${word_5}`;
}

export function getFullName_dto(user: {
  last_name?: string,
  first_name?: string,
  second_name?: string | null
}) {
  return [user?.last_name, user?.first_name, user?.second_name].filter(Boolean).join(" ")
}

export function getFullName(user: {
  lastName?: string,
  firstName?: string,
  secondName?: string | null
}) {
  return [user?.lastName, user?.firstName, user?.secondName].filter(Boolean).join(" ")
}

export function getShortNames_dto(users: ({
  last_name: string,
  first_name: string,
  second_name: string | null
  //dto kaka
} | null)[] | null | undefined) {
  if (users == null) return ""

  return users.map(u => {
    if (u == null) return null;

    return `${u.last_name} 
            ${u.first_name[0].toUpperCase()}.
            ${u.second_name == null || u.second_name === "" ? "" : u.second_name[0].toUpperCase() + "."}`
  }).filter(Boolean).join(", ")
}

export function getShortNames(users: ({
  lastName: string,
  firstName: string,
  secondName?: string
})[]) {
  return users.map(u => {
     return `${u.lastName} 
            ${u.firstName[0].toUpperCase()}.
            ${u.secondName == null || u.secondName === "" ? "" : u.secondName[0].toUpperCase() + "."}`
  }).filter(Boolean).join(", ")
}
