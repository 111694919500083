import {loader} from 'graphql.macro';

export const RESET_PASSWORD_MUTATION = loader(
  './mutations/reset-password.mutation.gql'
);
export const WEBSOCKET_AUTH_TOKEN = loader(
  './mutations/webSocketToken.mutation.gql'
);
export const SIGN_IN_MUTATION = loader('./mutations/sign-in.mutation.gql');
export const SIGN_UP_MUTATION = loader('./mutations/sign-up.mutation.gql');
export const CREATE_TEAM_MAP_MUTATION = loader(
  './mutations/create-team-map.mutation.gql'
);
export const CREATE_TEAM_FILE_MUTATION = loader(
  './mutations/create-team-file.mutation.gql'
);
export const CREATE_TEAM_IMAGE_MUTATION = loader(
  './mutations/create-team-image.mutation.gql'
);
export const CREATE_TEAM_VIDEO_MUTATION = loader(
  './mutations/create-team-video.mutation.gql'
);

export const DUTY_SHIFTS_QUERY = loader('./queries/duty-shifts.query.gql');
/* start application */
export const APPLICATION_CREATE_MUTATION = loader(
  './application/mutations/create.mutation.gql'
);
export const APPLICATION_EDIT_MUTATION = loader(
  './application/mutations/edit.mutation.gql'
);
export const APPLICATION_CREATE_APPLICANT_MUTATION = loader(
  './application/mutations/create-applicant.mutation.gql'
);
export const APPLICATION_EDIT_APPLICANT_MUTATION = loader(
  './application/mutations/edit-applicant.mutation.gql'
);
export const APPLICATION_DELETE_APPLICANT_MUTATION = loader(
  './application/mutations/delete-applicant.mutation.gql'
);
export const APPLICATION_CREATE_MISSING_MUTATION = loader(
  './application/mutations/create-missing.mutation.gql'
);
export const APPLICATION_EDIT_MISSING_MUTATION = loader(
  './application/mutations/edit-missing.mutation.gql'
);
export const APPLICATION_DELETE_MISSING_MUTATION = loader(
  './application/mutations/delete-missing.mutation.gql'
);
export const APPLICATION_ADD_SEARCH_MEMBER_QUERY = loader(
  './application/mutations/add-search-member.mutation.gql'
);
export const APPLICATION_DELETE_SEARCH_MEMBER_QUERY = loader(
  './application/mutations/delete-search-member.mutation.gql'
);
export const APPLICATION_CREATE_REPORT_MUTATION = loader(
  './application/mutations/create-report.mutation.gql'
);
export const APPLICATION_EDIT_REPORT_MUTATION = loader(
  './application/mutations/edit-report.mutation.gql'
);
export const APPLICATION_DELETE_REPORT_MUTATION = loader(
  './application/mutations/delete-report.mutation.gql'
);
export const APPLICATION_CREATE_MISSING_FILE_MUTATION = loader(
  './application/mutations/create-missing-file.mutation.gql'
);
export const APPLICATION_CREATE_MISSING_TELEGRAM = loader(
  './application/mutations/telegram.mutation.gql'
);
export const APPLICATION_MISSINGS_LIST = loader(
  './application/queries/missings-list.query.gql'
);
export const APPLICATION_DISPATCH_LIST = loader(
  './application/queries/dispatch-list.query.gql'
);
export const APPLICATION_CREATE_MISSING_PHOTO_MUTATION = loader(
  './application/mutations/create-missing-photo.mutation.gql'
);
export const APPLICATION_CREATE_MISSING_VIDEO_MUTATION = loader(
  './application/mutations/create-missing-video.mutation.gql'
);
export const APPLICATION_EDIT_STATUS_MUTATION = loader(
  './application/mutations/change-status.mutation.gql'
);
export const APPLICATION_CREATE_VERIFICATION_MUTATION = loader(
  './application/mutations/create-verification.mutation.gql'
);
export const APPLICATION_EDIT_VERIFICATION_MUTATION = loader(
  './application/mutations/edit-verification.mutation.gql'
);
export const APPLICATION_DELETE_VERIFICATION_MUTATION = loader(
  './application/mutations/delete-verification.mutation.gql'
);

export const APPLICATION_GENERAL_DICTIONARIES_QUERY = loader(
  './application/queries/ApplicationGeneralDictionaries.queries.gql'
);
export const APPLICATION_MISSING_DICT_QUERY = loader(
  './application/queries/missing-dict.queries.gql'
);
export const APPLICATION_QUERY = loader(
  './application/queries/Application.queries.gql'
);
export const APPLICATION_MISSING_LIST_QUERY = loader(
  './application/queries/missing-list.queries.gql'
);

export const APPLICATION_LIST_QUERY = loader(
  './application/queries/list.queries.gql'
);
export const APPLICATION_FILTERS_DICTIONARIES_QUERY = loader(
  './application/queries/ApplicationFiltersDictionaries.queries.gql'
);
export const APPLICATION_GENERAL_QUERY = loader(
  './application/queries/ApplicationGeneral.queries.gql'
);
export const APPLICATION_SEARCH_MEMBERS_QUERY = loader(
  './application/queries/search-members.queries.gql'
);
export const APPLICATION_ADD_SEARCH_MEMBERS_DICT_QUERY = loader(
  './application/queries/add-search-members-dict.queries.gql'
);
export const APPLICATION_REPORTS_QUERY = loader(
  './application/queries/reports.queries.gql'
);
export const APPLICATION_CALL_REQUEST_QUERY = loader(
  './application/queries/application-call-request.queries.gql'
);
export const APPLICATION_CALLS_QUERY = loader(
  './application/queries/calls.queries.gql'
);
export const APPLICATION_TASKS_QUERY = loader(
  './application/queries/tasks.queries.gql'
);
export const APPLICATION_VERIFICATIONS_QUERY = loader(
  './application/queries/verifications.queries.gql'
);
export const APPLICATION_DETAILS_QUERY = loader(
  './application/queries/details.queries.gql'
);
export const APPLICATION_FILES_QUERY = loader(
  './application/queries/files.queries.gql'
);
export const APPLICATION_PHOTOS_QUERY = loader(
  './application/queries/photos.queries.gql'
);
export const APPLICATION_VIDEOS_QUERY = loader(
  './application/queries/videos.queries.gql'
);
export const APPLICATION_CIRCUMSTANCES_OF_LOSS_QUERY = loader(
  './application/queries/circumstances-of-loss.queries.gql'
);
export const APPLICATION_STATUSES_QUERY = loader(
  './application/queries/statuses.queries.gql'
);
export const APPLICATION_CHAT_QUERY = loader(
  './application/queries/chat.queries.gql'
);
export const APPLICATION_ADD_COMMENT_MUTATION = loader(
  './application/mutations/add-comment.mutation.gql'
);
export const APPLICATION_MAPS_QUERY = loader(
  './application/queries/maps.queries.gql'
);
export const APPLICATION_CREATE_MAP_MUTATION = loader(
  './application/mutations/create-map.mutation.gql'
);
export const APPLICATION_DELETE_MAP_MUTATION = loader(
  './application/mutations/delete-map.mutation.gql'
);

export const APPLICATION_DELETE_COMMENT_MUTATION = loader(
  './application/mutations/delete.comment.mutation.gql'
);

export const APPLICATION_TRANSFER_MUTATION = loader(
  './application/mutations/transfer.mutation.gql'
);
export const DELETE_ISSUE_MUTATION = loader(
  './application/mutations/delete-issue.mutation.gql'
);

/* end application */

export const TEAMS_PAGINATION_QUERY = loader(
  './queries/teamsPagination.queries.gql'
);
export const ME_QUERY = loader('./queries/me.query.gql');
export const SHORT_TEAMS_QUERY = loader('./queries/shortTeams.queries.gql');
export const TEAM_QUERY = loader('./queries/team.queries.gql');
export const SHORT_REGIONS_QUERY = loader('./queries/shortRegions.queries.gql');
export const DRIVER_LICENSE_CATEGORIES_QUERY = loader(
  './queries/driverLicenseCategory.queries.gql'
);
export const INVITE_QUERY = loader('./queries/invite.queries.gql');

/*group*/

export const GROUP_IMAGE_QUERY = loader('./queries/group/photo.queries.gql');
export const GROUP_TASKS_QUERY = loader('./queries/group/tasks.queries.gql');
export const GROUP_ADD_IMAGE_MUTATION = loader(
  './mutations/add-photo.mutation.gql'
);
export const GROUP_FILE_QUERY = loader('./queries/group/file.queries.gql');
export const GROUP_ADD_FILE_MUTATION = loader(
  './mutations/create-group-file.mutation.gql'
);
export const GROUP_NAME_QUERY = loader('./queries/group/name.queries.gql');
export const GROUP_DESCRIPTION_QUERY = loader(
  './queries/group/description.queries.gql'
);
export const CREATE_GROUP_VIDEO = loader(
  './mutations/create-group-video.mutation.gql'
);
export const GROUP_VIDEO_QUERY = loader('./queries/group/video.queries.gql');
export const GROUP_PARTICIPANTS_QUERY = loader(
  './queries/group/participants.queries.gql'
);

/* team*/
export const TEAM_NAME_QUERY = loader('./queries/team/name.queries.gql');
export const TEAM_DESCRIPTION_QUERY = loader(
  './queries/team/description.queries.gql'
);
export const TEAM_PARTICIPANTS_QUERY = loader(
  './queries/team/participants.queries.gql'
);
export const TEAM_DUTY_QUERY = loader('./queries/team/duty.queries.gql');
export const TEAM_GROUPS_QUERY = loader('./queries/team/groups.queries.gql');
export const TEAM_MAPS_QUERY = loader('./queries/team/maps.queries.gql');
export const TEAM_EVENTS_QUERY = loader('./queries/team/events.queries.gql');
export const TEAM_PHOTOS_QUERY = loader('./queries/team/photos.queries.gql');
export const TEAM_VIDEOS_QUERY = loader('./queries/team/videos.queries.gql');
export const TEAM_FILES_QUERY = loader('./queries/team/files.queries.gql');
export const TEAM_WIDGET_QUERY = loader('./queries/teamWidgets.queries.gql');
export const TEAM_EQUIPMENTS_QUERY = loader(
  './queries/team/equipments.queries.gql'
);
export const TEAM_TECHNICS_QUERY = loader(
  './queries/team/technics.queries.gql'
);

/* start call */

export const CALL_CHANGE_STATUS_MUTATION = loader(
  './call/mutations/changeStatus.mutation.gql'
);
export const CALL_STATUSES_QUERY = loader(
  './call/queries/callStatuses.query.gql'
);
export const CALL_NEW_MUTATION = loader('./call/mutations/create.mutation.gql');
export const CALL_EDIT_MUTATION = loader('./call/mutations/edit.mutation.gql');
export const CALLS_QUERY = loader('./call/queries/list.queries.gql');
export const CALL_LIST_DICT_QUERY = loader(
  './call/queries/list-dict.queries.gql'
);
export const CALL_QUERY = loader('./call/queries/item.queries.gql');
export const CALL_DETAIL_QUERY = loader('./call/queries/detail.queries.gql');
export const CALL_DESCRIPTION_QUERY = loader(
  './call/queries/description.queries.gql'
);
export const CALL_CHANGE_FORM_DICT_QUERY = loader(
  './call/queries/change-form-dict.queries.gql'
);

export const CALL_TRANSFER_MUTATION = loader(
  './call/mutations/transfer.mutation.gql'
);
export const CALL_TASKS_QUERY = loader('./call/queries/tasks.queries.gql');
export const CALL_PHOTOS_QUERY = loader('./call/queries/photos.queries.gql');

export const CREATE_CALL_PHOTO_MUTATION = loader(
  './call/mutations/photos.mutation.gql'
);

export const CALL_DELETE_MUTATION = loader(
  './call/mutations/delete.mutation.gql'
);

export const CALL_ADD_COMMENT_MUTATION = loader(
  './call/mutations/add.comment.mutation.gql'
);

export const CALL_CHAT_QUERY = loader('./call/queries/chat.queries.gql');

export const CALL_TAKE_TO_WORK = loader(
  './call/mutations/takeToWork.mutation.gql'
);
export const CALL_DELETE_CALLING = loader(
  './call/mutations/delete.calling.mutation.gql'
);
export const CALL_EDIT_CALLING = loader(
  './call/mutations/edit.calling.mutation.gql'
);
/* end call */

export const USE_INSTITUTIONS_DICT_QUERY = loader(
  './helpfulInfo/queries/institutions-dict.queries.gql'
);

export const USEFUL_RESOURCES_QUERY = loader(
  './helpfulInfo/queries/usefulResources.query.gql'
);

export const USEFUL_INSTITUTIONS_QUERY = loader(
  './helpfulInfo/queries/institutions.query.gql'
);

export const USEFUL_ALGORITHM_QUERY = loader('./queries/algorithm.query.gql');

export const USEFUL_TYPOGRAPHY_QUERY = loader('./queries/typography.query.gql');

export const USEFUL_REGIONAL_AGREE_QUERY = loader(
  './helpfulInfo/queries/regionalAgree.query.gql'
);

export const USEFUL_QUESTIONNAIRE_QUERY = loader(
  './helpfulInfo/queries/questionnaire.query.gql'
);

/* start task */
export const TASK_CREATE_MUTATION = loader(
  './task/mutations/create.mutation.gql'
);

export const TASK_CHANGE_FORM_DICT_QUERY = loader(
  './task/queries/change-form-dict.queries.gql'
);
export const TASK_LIST_DICT_QUERY = loader(
  './task/queries/list-dict.queries.gql'
);
export const TASK_LIST_QUERY = loader('./task/queries/list.queries.gql');
export const TASK_QUERY = loader('./task/queries/item.queries.gql');
export const TASK_DELETE_MUTATION = loader(
  './task/mutations/delete.mutation.gql'
);
export const TASK_EDIT_MUTATION = loader('./task/mutations/edit.mutation.gql');
export const TASK_STATUSES_QUERY = loader(
  './task/queries/statuses.queries.gql'
);
export const TASK_CHANGE_STATUS_MUTATION = loader(
  './task/mutations/changeStatus.mutation.gql'
);

export const TASK_COMMENTS_QUERY = loader('./task/queries/chat.queries.gql');

export const TASK_ADD_COMMENT_MUTATION = loader(
  './task/mutations/add.comment.mutation.gql'
);

/* end task */

export const CREATE_LETTER_MUTATION = loader(
  './mutations/create-letter.mutation.gql'
);

export const LETTER_TEMPLATES_QUERY = loader(
  './queries/letterTemplates.query.gql'
);

/* start call request */

export const ADD_CALL_REQUEST_DICT_QUERY = loader(
  './callRequest/queries/add-dict.queries.gql'
);
export const ADD_CALL_REQUEST_MUTATION = loader(
  './callRequest/mutation/create.mutation.gql'
);
export const CALL_REQUEST_LIST_QUERY = loader(
  './callRequest/queries/list.queries.gql'
);
export const CALL_REQUEST_ITEM_QUERY = loader(
  './callRequest/queries/item.queries.gql'
);
export const CALL_REQUEST_INSTITUTION_QUERY = loader(
  './callRequest/queries/institution.queries.gql'
);
export const CALL_REQUEST_SET_RESULT_MUTATION = loader(
  './callRequest/mutation/setResult.mutation.gql'
);

export const CALL_REQUEST_SET_CALLER_MUTATION = loader(
  './callRequest/mutation/setCaller.mutation.gql'
);

/* end call request */

/* start users */
export const USER_LIST_QUERY = loader('./users/queries/list.queries.gql');
export const USER_LIST_DICT_QUERY = loader(
  './users/queries/list-dict.queries.gql'
);
/* end users */

/* start user profile */

export const ADD_DUTY_MUTATION = loader(
  './profile/mutations/add-duty.mutation.gql'
);
export const END_DUTY_MUTATION = loader(
  './profile/mutations/end-duty.mutation.gql'
);
export const EDIT_DUTY_MUTATION = loader(
  './profile/mutations/edit-duty.mutation.gql'
);
export const DELETE_DUTY_MUTATION = loader(
  './profile/mutations/delete-duty.mutation.gql'
);
export const DUTY_LIST_QUERY = loader(
  './profile/queries/duty-list.queries.gql'
);
export const PROFILE_QUERY = loader('./profile/queries/profile.queries.gql');
export const ANNOUNCEMENT_LIST_QUERY = loader(
  './profile/queries/announcement-list.queries.gql'
);
export const TOGGLE_READ_ANNOUNCEMENT_MUTATION = loader(
  './profile/mutations/toggle-read-announcement.mutation.gql'
);
export const PROFILE_FEEDBACK_QUERY = loader(
  './profile/mutations/add-feedback.mutation.gql'
);
export const EDIT_PROFILE_MUTATION = loader(
  './profile/mutations/edit-profile.mutation.gql'
);
export const PROFILE_STATISTICS_QUERY = loader(
  './profile/queries/statistics.queries.gql'
);
export const PRIVATE_INFO_QUERY = loader(
  './profile/queries/profile-info.query.gql'
);
export const PROFILE_SEND_ITEM_DICT_QUERY = loader(
  './profile/queries/send-item-dict.queries.gql'
);
export const PROFILE_SEND_ITEM_MUTATION = loader(
  './profile/mutations/send-item.mutation.gql'
);
export const PROFILE_ISSUES_QUERY = loader(
  './profile/queries/issues.queries.gql'
);
export const PROFILE_TASKS_QUERY = loader(
  './profile/queries/tasks.queries.gql'
);
export const PROFILE_CALLS_QUERY = loader(
  './profile/queries/calls.queries.gql'
);
export const PROFILE_CREATE_TECHNIQUE = loader(
  './profile/mutations/add-technique.mutation.gql'
);
export const PROFILE_CREATE_EQUIPMENT = loader(
  './profile/mutations/add-equipment.mutation.gql'
);
export const ALL_EQUIPMENT_CATEGORY = loader(
  './profile/queries/equipmentCategory.query.gql'
);
export const ALL_EQUIPMENT_STATUS = loader(
  './profile/queries/equipmentStatus.query.gql'
);
export const ALL_TECHNIC_CATEGORY = loader(
  './profile/queries/technicCategories.query.gql'
);
export const DELETE_EQUIPMENT_ITEM = loader(
  './profile/mutations/delete-equipment.mutation.gql'
);
export const DELETE_TECHNIC_ITEM = loader(
  './profile/mutations/delete-technic.mutation.gql'
);

/* end user profile */

/* start create and get callings */

export const CREATE_RINGING_MUTATION = loader(
  './mutations/create-ringing.mutation.gql'
);

export const GET_CALLINGS_QUERY = loader('./queries/calling.query.gql');

export const CALL_RECIPIENT_QUERY = loader(
  './queries/call-recipient.query.gql'
);

/* end create and get callings */

export const LOGOUT_MUTATION = loader('./mutations/logout.mutation.gql');
