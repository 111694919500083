import React from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {MissingInfo} from '../../../components/MissingInfo/MissingInfo';
import {useEnterForm} from '../../../form.hooks';
import {useInit} from '../hooks';
import {useHandler} from './Step3.hooks';

import {Applicant, Fields, Footer, Form} from './Step3.styles';

import {Button} from '../../../../../elements/Button/Button';
import {Id} from '../../../../../../types/auxiliary.types';
import {FormFields} from './Step3.types';

interface Props {
  applicationId: Id;
  applicantId: Id;
  defaultValues?: FormFields;
}

export const Step3 = (props: Props) => {
  const {loading, data} = useInit(props.applicationId);

  const navigate = useNavigate();

  const {control, register, formState, handleSubmit, setError} =
    useForm<FormFields>({
      defaultValues: props.defaultValues || {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: null,
        birthdayString: '',
        hasPhone: 'unknown',
        availablePhone: 'unknown',
        phone: '',
        additionalPhones: [],
        health: '',
        diseases: [],
        appearance: '',
        cloth: '',
        withSelf: '',
        additionalInformation: '',
      },
      reValidateMode: 'onBlur',
      mode: 'onBlur',
    });

  const {handleSubmit3Step} = useHandler(props.applicationId, setError);

  useEnterForm(
    handleSubmit((d) => {
      handleSubmit3Step(props.applicationId, d, () =>
        navigate('/applications')
      );
    })
  );

  if (loading) {
    return <Preloader />;
  }

  if (!loading && data == null) {
    return <p>Ошибка</p>;
  }

  return (
    <Form
      onSubmit={handleSubmit((d) =>
        handleSubmit3Step(props.applicantId, d, () => navigate('/applications'))
      )}>
      <Applicant>
        Заявитель - {data?.issue.bearers?.[0]?.full_name || ''}
      </Applicant>
      <Fields>
        <MissingInfo register={register} control={control} />
      </Fields>

      <Footer>
        <Button
          title="Завершить"
          type="submit"
          disabled={formState.isSubmitting}
        />
        <Button
          title="Завершить и редактировать"
          type="submit"
          disabled={formState.isSubmitting}
          onClick={handleSubmit((d) =>
            handleSubmit3Step(props.applicantId, d, () =>
              navigate(`/applications/edit/${props.applicationId}`)
            )
          )}
        />
      </Footer>
    </Form>
  );
};
