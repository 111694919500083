import styled from 'styled-components';
import {ModalWrapper} from '../ModalWrapper/ModalWrapper';

export const Wrapper = styled.div`
  box-sizing: border-box;
`;

export const ModalWraper = styled(ModalWrapper)`
  width: 600px;
  height: 600px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const ThemeBlock = styled.div``;

export const SampleBlock = styled.div``;

export const AdresseeBlock = styled.div``;

export const ContentBlock = styled.div``;

export const Form = styled.form``;

type ColorType = 'blue' | 'orange' | 'pink';

const config: Record<ColorType, string> = {
  blue: 'var(--additional-color)',
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IAction {
  color: ColorType;
}

export const Action = styled.button<IAction>`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  [data-icon='true'] {
    background-color: ${(p) => config[p.color]};
  }
  [data-name='true'] {
    color: ${(p) => config[p.color]};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;
  margin-right: 10px;

  svg {
    fill: #fff;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

interface ITextarea {
  isInvalid?: boolean;
}

export const Input = styled.input<ITextarea>`
  border: 1px solid
    ${(p) =>
      p.isInvalid ? 'var(--warning-color);' : 'var(--primary-border-color);'};
  color: ${(props) =>
    props.isInvalid ? 'var(--warning-color);' : 'var(--primary-text-color);'};
  border-radius: 8px;
  font-size: 14px;
  padding: 14px 16px;
  line-height: 1.3571428571;
  resize: vertical;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;
