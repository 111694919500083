import './css/normalize.css';
import './css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {AppLayout} from './components/layouts/AppLayout/AppLayout';
import {DetailLayout} from './components/layouts/DetailLayout/DetailLayout';
import {ProtectedLayout} from './components/layouts/ProtectedLayout/ProtectedLayout';
import {SignInLayout} from './components/layouts/SignInLayout/SignInLayout';
import {ApplicationCreateScreen} from './components/screens/Application/ApplicationCreate/ApplicationCreate';
import {ApplicationEditScreen} from './components/screens/Application/Edit/Edit';
import ApplicationsScreen from './components/screens/ApplicationsScreen/ApplicationsScreen';
import {EditCallScreen} from './components/screens/Call/edit/component';
import CallListScreen from './components/screens/Call/list/component';
import {NewCallScreen} from './components/screens/Call/new/component';
import {ViewCallScreen} from './components/screens/Call/view/component';
import InstitutionScreen from './components/screens/CallRequest/institution/component';
import {DepartmentScreen} from './components/screens/DepartmentScreen/DepartmentScreen';
import {DetachmentScreen} from './components/screens/DetachmentScreen/DetachmentScreen';
import {GroupScreen} from './components/screens/GroupScreen/GroupScreen';
import HelpfulInfo from './components/screens/HelpfulInfoScreen/HelpfulInfo';
import IndexPage from './components/screens/IndexPage/component';
import PasswordRecoveryScreen from './components/screens/PasswordRecoveryScreen/PasswordRecoveryScreen';
import {ProfileEdit} from './components/screens/Profile/edit/component';
import {SignInScreen} from './components/screens/SignInScreen/SignInScreen';
import {SignUpLayout} from './components/layouts/SignUpLayout/SignUpLayout';
import {SignUpScreen} from './components/screens/SignUpScreen/SignUpScreen';
import {NewTaskScreen} from './components/screens/Task/new/component';
import ViewTaskScreen from './components/screens/Task/view/component';
import TaskListScreen from './components/screens/Task/list/component';
import {TeamsScreen} from './components/screens/TeamsScreen/TeamsScreen';
import {Screen404} from './components/screens/Screen404/Screen404';
import {ApplicationViewScreen} from './components/screens/Application/ApplicationView/ApplicationView';
import UsefulResources from './components/screens/HelpfulInfoScreen/components/UsefulResources/UsefulResources';
import Algorithm from './components/screens/HelpfulInfoScreen/components/Algorithms/Algorithm';
import Algorithms from './components/screens/HelpfulInfoScreen/components/Algorithms/Algorithms';
import Typography from './components/screens/HelpfulInfoScreen/components/Typography/Typography';
import Institutions from './components/screens/HelpfulInfoScreen/components/Institutions/Institutions';
import RegionalAgree from './components/screens/HelpfulInfoScreen/components/RegionalAgree/RegionalAgree';
import RegionalAgreement from './components/screens/HelpfulInfoScreen/components/RegionalAgree/RegionalAgreement';
import Questionnaires from './components/screens/HelpfulInfoScreen/components/Questionnaires/Questionnaires';
import Questionnaire from './components/screens/HelpfulInfoScreen/components/Questionnaires/Questionnaire';
import UsersList from './components/screens/Users/list/component';
import CallRequestsListScreen from './components/screens/CallRequest/list/component';
import CallRequestsItemScreen from './components/screens/CallRequest/item/component';
import ProfileInfo from './components/screens/Profile/main/ProfileInfo';
import EditTaskScreen from './components/screens/Task/edit/component';

const App = () => {
  console.log('Версия: 1.05');
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SignUpLayout />}>
          <Route path="/sign-up" element={<SignUpScreen />} />
        </Route>
        <Route element={<SignInLayout />}>
          <Route path="/sign-in" element={<SignInScreen />} />
        </Route>
        <Route element={<SignInLayout />}>
          <Route path="/" element={<SignInScreen />} />
        </Route>
        <Route element={<PasswordRecoveryScreen />}>
          <Route
            path="/password-recovery"
            element={<PasswordRecoveryScreen />}
          />
        </Route>

        <Route element={<ProtectedLayout />}>
          <Route element={<DetailLayout />}>
            <Route
              path="/applications/new"
              element={<ApplicationCreateScreen />}
            />
            <Route
              path="/applications/edit/:applicationId"
              element={<ApplicationEditScreen />}
            />
            <Route path="/calls/new" element={<NewCallScreen />} />
            <Route path="/calls/edit/:callId" element={<EditCallScreen />} />
            <Route path="/calls/:callId" element={<ViewCallScreen />} />
            <Route path="/tasks/new" element={<NewTaskScreen />} />
            <Route path="/tasks/:taskId" element={<ViewTaskScreen />} />
            <Route path="/tasks/edit/:taskId" element={<EditTaskScreen />} />
            <Route
              path="/callRequests/:callRequestId"
              element={<CallRequestsItemScreen />}
            />
            <Route
              path="/callRequests/:callRequestId/:institutionId"
              element={<InstitutionScreen />}
            />
            <Route path="/profile/edit" element={<ProfileEdit />} />
          </Route>
          <Route element={<AppLayout />}>
            <Route path="/main" element={<IndexPage />} />
            <Route path="/teams" element={<TeamsScreen />} />
            <Route path="/teams/:teamId" element={<DetachmentScreen />} />
            <Route
              path="/departments"
              element={<Navigate to="/teams" replace />}
            />
            <Route
              path="/departments/:departmentId"
              element={<DepartmentScreen />}
            />
            <Route path="/applications" element={<ApplicationsScreen />} />
            <Route
              path="/applications/:applicationId"
              element={<ApplicationViewScreen />}
            />
            <Route path="/groups/:groupId" element={<GroupScreen />} />
            <Route path="/calls" element={<CallListScreen />} />
            <Route path="/helpfulInfo" element={<HelpfulInfo />} />
            <Route path="/usefulResources" element={<UsefulResources />} />
            <Route path="/algorithms" element={<Algorithms />} />
            <Route path="/algorithms/:algorithmId" element={<Algorithm />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/institutions" element={<Institutions />} />
            <Route path="/regionalAgree" element={<RegionalAgree />} />
            <Route
              path="/regionalAgree/:regionalId"
              element={<RegionalAgreement />}
            />
            <Route path="/callRequests" element={<CallRequestsListScreen />} />
            <Route path="/questionnaires" element={<Questionnaires />} />
            <Route
              path="/questionnaires/:questionnaireId"
              element={<Questionnaire />}
            />
            <Route path="/tasks" element={<TaskListScreen />} />
            <Route path="/users" element={<UsersList />} />
            <Route path="/profile" element={<ProfileInfo />} />
            <Route path="/edit/profile" element={<ProfileEdit />} />
            <Route path="*" element={<Screen404 />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
