import styled from 'styled-components';

export const Wrapper = styled.div``;

interface ILabel {
  hasLabelPostfix?: boolean;
}

export const Label = styled.p<ILabel>`
  margin: 0 0 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);

  &::after {
    content: ':';
    display: ${(p) => (p.hasLabelPostfix ? 'inline' : 'none')};
  }
`;

export const DateValue = styled.div`
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;
