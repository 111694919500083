import {useQuery} from '@apollo/client';

import {useSearchParams} from 'react-router-dom';
import {INVITE_QUERY} from '../../../gql';
import {
  Invite,
  InviteVariables,
} from '../../../gql/queries/__generated__/Invite';

export function useInvite() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token') || '';

  const {data, loading} = useQuery<Invite, InviteVariables>(INVITE_QUERY, {
    variables: {
      token: token,
    },
    skip: token === '',
  });

  return {
    data,
    loading,
  };
}
