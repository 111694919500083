import React from 'react';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import Preloader from '../../../../elements/Preloader/Preloader';
import ResourcesItem from '../../../../elements/ResourcesItem/ResourcesItem';
import {useUsefulResources} from './UsefulResources.hooks';
import {Wrapper} from './UsefulResources.styles';

export default function UsefulResources() {
  useDocumentTitle('Полезные ресурсы');

  const {resources, loading} = useUsefulResources();

  return (
    <Wrapper>
      <h1>Полезные ресурсы</h1>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {resources.map((item) => {
            return (
              <ResourcesItem
                key={item.id}
                id={item?.id}
                name={item.name}
                link={item.link}
                comment={item.comment}
              />
            );
          })}
        </>
      )}
    </Wrapper>
  );
}
