import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {UserContext} from '../../../context/UserContext';
import {useAuthenticatedUser} from '../../../hooks/useAuthenticatedUser';
import {useLogoutHandler} from '../../../hooks/useLogoutHandler';
import Preloader from '../../elements/Preloader/Preloader';
import {Center} from '../../elements/Preloader/Preloader.styles';

export const ProtectedLayout = () => {
  const logOut = useLogoutHandler();
  const navigate = useNavigate();

  const {user: currentUser, loading} = useAuthenticatedUser();

  useEffect(() => {
    if (!loading && currentUser == null) {
      // window.location.reload();
    }
  }, [currentUser, loading, logOut, navigate]);

  if (loading) {
    return (
      <Center>
        <Preloader />
      </Center>
    );
  }

  if (currentUser == null) {
    return null;
  }

  return (
    <UserContext.Provider value={currentUser}>
      <Outlet />
    </UserContext.Provider>
  );
};
