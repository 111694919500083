import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {ActionBlock} from '../../../elements/ActionBlock/ActionBlock';
import {Button} from '../../../elements/Button/Button';
import {Chat} from '../../../elements/chat/component';
import {ModalWrapper} from '../../../elements/ModalWrapper/ModalWrapper';
import {Tabs} from '../../../elements/Tabs/Tabs';
import {
  ButtonWrapper,
  Wrapper as ButtonsWrapper,
} from '../../../layouts/Header/components/User/User.styles';
import {useChat} from '../hooks/chat.hooks';
import {useDeleteCall} from './components/description/hooks';
import {GeneralInfo} from './components/generalInfo/component';
import {useCall, useChangeStatus} from './components/generalInfo/hooks';
import {TabConfig} from './config';
import {useTab} from './hooks';
import TransferCall from './modals/transferCall/component';
import {Wrapper, MainColumn, SecondaryColumn} from './styles';

export function ViewCallScreen() {
  const [modalName, setModalName] = useState<'sendData' | 'deleteCall' | null>(
    null
  );

  useDocumentTitle('Просмотр звонка');

  const {activeTab, handleSwitchTab} = useTab();
  const params = useParams<{callId?: string}>();
  const navigate = useNavigate();

  const config = TabConfig.find((tab) => tab.id === activeTab);

  const {handleDeleteCall} = useDeleteCall();

  const {refetch} = useCall();

  const {handleChangeStatus} = useChangeStatus(refetch);

  const {comments, loading, handleAddMessage, sendMessage} = useChat();

  function renderModal() {
    switch (modalName) {
      case 'sendData': {
        return (
          <TransferCall
            onChangeSuccess={() => setModalName(null)}
            onClose={() => setModalName(null)}
          />
        );
      }
      case 'deleteCall': {
        return (
          <ModalWrapper
            title={'Подтвердите удаление'}
            onModalClose={() => setModalName(null)}>
            Вы уверены что хотите удалить звонок?
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  title="Да"
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => handleDeleteCall(Number(params.callId))}
                />
              </ButtonWrapper>
              <Button
                title="Отмена"
                variant={'outlined'}
                color={'primary'}
                onClick={() => setModalName(null)}
              />
            </ButtonsWrapper>
          </ModalWrapper>
        );
      }
      default:
        return null;
    }
  }

  return (
    <Wrapper>
      {renderModal()}
      <MainColumn>
        <Tabs
          activeTab={activeTab || ''}
          items={TabConfig.map((t) => ({
            id: t.id,
            label: t.label,
          }))}
          onSwitchTab={handleSwitchTab}
        />
        {config?.renderComponent?.()}
      </MainColumn>
      <SecondaryColumn>
        <ActionBlock
          title="Действие со звонком"
          sendData={{
            label: 'Передать',
            onClick: () => setModalName('sendData'),
          }}
          onEdit={() => navigate(`/calls/edit/${params.callId}`)}
          onDelete={{
            label: 'Удалить звонок',
            onClick: () => setModalName('deleteCall'),
          }}
        />
        <GeneralInfo onChangeStatus={handleChangeStatus} onSuccess={refetch} />
      </SecondaryColumn>
      <Chat
        comments={comments}
        sendingMessage={sendMessage}
        onMessageAdd={handleAddMessage}
        loadingComments={loading}
      />
    </Wrapper>
  );
}
