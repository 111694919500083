import styled from 'styled-components';

export const Wrapper = styled.article`
  background: #fff;
  border-radius: 8px;

  width: 100%;
  padding: 16px 24px 16px 16px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.06);

  > :not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 900px) {
    align-items: start;
    flex-direction: column;

    > :not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const LeftSide = styled.div`
  width: 50%;
`;

export const RightSide = styled.div`
  width: 50%;
  display: flex;
`;

export const Footer = styled.footer`
  font-size: 1rem;
  color: #333;
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 16px;
  color: #333;
`;

export const Subtitle = styled.p`
  color: #7d7d7d;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;

export const Address = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #333;
`;

export const TimeBlock = styled.div`
  flex: 1;
`;

export const CountBlock = styled.div`
  flex: 2;
  margin: 0 43px 0 43px;
`;

export const Time = styled.time`
  margin: 0;
  font-size: 12px;
  color: #333;
`;

export const MaxInColor = styled.p`
  margin: 0;
  font-size: 12px;
  color: #333;
`;
