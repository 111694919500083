import React from 'react';
import {Id} from '../../../../../../../types/auxiliary.types';
import {Form} from '../form/form';
import {FormFields} from '../form/types';
import {useEditDuty} from './hooks';

interface Props {
  id: Id;
  defaultValues: FormFields;
  onEditSuccess: () => void;
  onClose: () => void;
}

export function EditForm(props: Props) {
  const {handleSubmit, loading} = useEditDuty(props.id, props.onEditSuccess);

  return (
    <Form
      title="Редактировать дежурство"
      submitLabel="Сохранить"
      defaultValues={props.defaultValues}
      submitLoading={loading}
      isDisabledGroup
      onClose={props.onClose}
      onSubmit={handleSubmit}
    />
  );
}
