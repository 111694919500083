import styled, {css} from 'styled-components';

export const Card = styled.article`
  background: #FFF;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  padding: 16px 26px 16px 16px;

  display: flex;
  align-items: center;
`;

export const WrapperInfo = styled.div`
  display: flex;
  align-items: start;
`;

function icon_wrapper() {
  return css`
    width: 32px;
    height: 40px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;
      }
    }
  `;
}

export const WordWrapper = styled.div`
  ${icon_wrapper()};

  svg {
    path[data-at='wrapper'] {
      fill: #749AC8;
    }
    path[data-at="text"] {
      fill: #FFF;
    }
    rect {
      fill: #E8E8E8;
    }
  }
`;

export const ExelWrapper = styled.div`
  ${icon_wrapper()};

  svg {
    path[data-at="wrapper"] {
      fill: #74C890;
    }
    path[data-at="text"] {
      fill: #FFF;
    }
    rect {
      fill: #E8E8E8;
    }
  }
`;

export const PDFWrapper = styled.div`
  ${icon_wrapper()};

  svg {
    path[data-at="wrapper"] {
      fill: #C87474;
    }
    path[data-at="text"] {
      fill: #FFF;
    }
    rect {
      fill: #E8E8E8;
    }
  }
`;

export const Info = styled.div`
  margin-left: 16px;

  flex: auto;
`;

export const Link = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;

  color: #000;
`;

export const Size = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;
