import React from 'react';
import {Id} from '../../../../../types/auxiliary.types';
import User from '../../../../elements/user/component';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useTeam} from './EventsList.hooks';
import {
  Wrapper,
  Main,
  DateS,
  MainColumn,
  Title,
  Info,
  Status,
  Region,
  SecondaryColumn,
  Phone,
  MainInfo,
  SecondaryInfo,
  Url,
  Secondary,
  LabelWeight,
} from './Events.styles';
import WrapperWithComment from '../../../../cards/elements/wrapperWithComment/component';

interface Event {
  id: Id;
  name: string;
  type?: string;
  colorType?: string;
  contactPerson: string;
  contactPhone: string;
  link?: string;
  dateFrom: string;
  dateTo?: string;
  comment?: string;
  region: string;
}

export function Events() {
  const {loading, list} = useTeam();

  function getFormattedDate(date: string) {
    return new Intl.DateTimeFormat('ru', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }).format(new Date(date));
  }

  function renderCard(card: Event) {
    return (
      <WrapperWithComment>
        <Main>
          <MainInfo>
            <MainColumn>
              <Title>{card.name}</Title>
              <Info>
                {card.type != null && (
                  <Status color={card.colorType}>{card.type}</Status>
                )}
                <Region>{card.region}</Region>
                <DateS>
                  {getFormattedDate(card.dateFrom)}
                  {card.dateTo != null && ` — ${getFormattedDate(card.dateTo)}`}
                </DateS>
              </Info>
            </MainColumn>
            <SecondaryColumn>
              <User
                avatar={undefined}
                fullName={card.contactPerson}
                label="Ответственный"
                hasLabelPostfix
              >
                <Phone>{card.contactPhone}</Phone>
              </User>
            </SecondaryColumn>
          </MainInfo>
          {card.link != null && (
            <SecondaryInfo>
              Ссылка на мероприятие:{' '}
              <Url target="_blank" href={card.link}>
                {card.link}
              </Url>
            </SecondaryInfo>
          )}
        </Main>
        {card.comment != null && card.comment !== "" && (
          <Secondary>
            <LabelWeight>Комментарий</LabelWeight>
            {card.comment}
          </Secondary>
        )}
      </WrapperWithComment>
    );
  }

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : list.length === 0 ? (
        'Данные отсутствуют'
      ) : (
        list.map(renderCard)
      )}
    </Wrapper>
  );
}
