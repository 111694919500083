import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {Video} from '../../../../../modals/Video/component';
import {useAddVideo} from './hooks';

interface Props {
  missingId: Id;
  onAddSuccess?: () => void;
  onModalClose(): void;
}

export function AddVideo(props: Props) {
  const {handleAddVideo, requestSending} = useAddVideo(props.missingId, () => {
    props.onAddSuccess?.();
    props.onModalClose();
  });

  return (
    <Video
      title="Добавить видео"
      requestSending={requestSending}
      submitLabel="Добавить"
      onSubmit={handleAddVideo}
      onModalClose={props.onModalClose}
    />
  );
}
