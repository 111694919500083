import React from 'react';
import {useProfileInfo} from './ProfileInfo.hooks';
import {Tabs} from '../../../elements/Tabs/Tabs';
import * as S from './ProfileInfo.styles';
import AnnouncementList from '../../Profile/announcementList/component';
import Sidebar from '../../Profile/sidebar/component';
import Equipments from '../equipmentsList/Equipments';
import Technics from '../TechnicsList/Technics';
import Statistics from '../statistics/component';

export default function ProfileInfo() {
  const {activeTab, handleSwitchTab} = useProfileInfo();

  const config = [
    {
      id: 'equipments',
      name: 'Оборудование',
      component: <Equipments />,
    },
    {
      id: 'technique',
      name: 'Техника',
      component: <Technics />,
    },
    {
      id: 'announcement',
      name: 'Объявления',
      component: <AnnouncementList />,
    },
  ];

  const activeItem = config.find((c) => c.id === activeTab);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Title>Личный кабинет</S.Title>
        <Tabs
          activeTab={activeTab || ''}
          items={config.map((c) => ({
            id: c.id,
            label: c.name,
          }))}
          onSwitchTab={handleSwitchTab}
        />
        <S.ContentItem>
          {activeTab == null ? null : activeItem?.component}
        </S.ContentItem>
      </S.ContentWrapper>

      <S.SidebarWrapper>
        <Statistics />
        <Sidebar />
      </S.SidebarWrapper>
    </S.Wrapper>
  );
}
