import { useMutation} from '@apollo/client';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {END_DUTY_MUTATION} from '../../../gql';
import {EndDuty} from '../../../gql/profile/mutations/__generated__/EndDuty';
import {useUrlParamInitialization} from '../../../hooks/useUrl.hooks';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

export function useTab() {
  useUrlParamInitialization({
    tab: 'applications',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {
    handleSwitchTab,
    activeTab,
  };
}

export function useActions() {
  const [commonError, setCommonError] = useState('');
  const navigate = useNavigate();
  const [endDuty, {loading}] = useMutation<EndDuty>(END_DUTY_MUTATION);

  function handleEndDuty() {
    endDuty()
      .then(() => {
        setCommonError('');
        toast('Текущие смены завершены');
        navigate('/?tab=duty');
      })

      .catch((e) => {
        toast(e.message, {type: 'error'});
      });
  }

  return {handleEndDuty, commonError, loading};
}
