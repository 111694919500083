import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {APPLICATION_CREATE_MISSING_PHOTO_MUTATION} from '../../../../../../gql';
import {
  CreateMissingPhoto,
  CreateMissingPhotoVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateMissingPhoto';

export const useAddPhotosHandler = (fetch: () => void) => {
  const [requestSending, setRequestSending] = useState(false);
  const [searchParams] = useSearchParams();

  const [addPhotoMutation] = useMutation<
    CreateMissingPhoto,
    CreateMissingPhotoVariables
  >(APPLICATION_CREATE_MISSING_PHOTO_MUTATION);

  const handleAddPhoto = useCallback(
    (data: {file: File; description: string}) => {
      if (!searchParams.has('photos')) return null;
      setRequestSending(true);

      addPhotoMutation({
        variables: {
          input: {
            missingId: Number(searchParams.get('photos')),
            file: data.file,
            name: data.description,
          },
        },
      })
        .then(() => fetch())
        .finally(() => setRequestSending(false));
    },
    [addPhotoMutation, fetch, searchParams]
  );

  return {handleAddPhoto, requestSending};
};
