import React from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import MIMEConstants from '../../../../../constants/mime.constants';
import {FileCard} from '../../../../cards/FileCard/FileCard';
import Preloader from '../../../../elements/Preloader/Preloader';
import {MediaList} from '../../../DetachmentScreen/components/MediaList/MediaList';
import {useGroup} from './hooks';
import AddFile from './modal/component';
import {FileTypes} from './types';

const File = () => {
  const {loading, list, fetch} = useGroup();
  const params = useParams<{groupId?: string}>();

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  function renderModal() {
    if (file == null) return null;

    return (
      <AddFile
        groupId={Number(params.groupId)}
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  function renderCard(file: FileTypes) {
    return (
      <FileCard
        key={file.url}
        name={file.name}
        mime={file.mime}
        size={file.size}
        url={file.url}
      />
    );
  }

  if (loading) return <Preloader />;

  const accept = Object.entries(MIMEConstants).reduce(
    (acc: string[], [k, v]) =>
      ['word', 'exel', 'pdf'].includes(v) ? [...acc, k] : acc,
    []
  );

  return (
    <MediaList<FileTypes>
      list={list}
      typeButton="fileInput"
      buttonLabel="Добавить файл"
      renderCard={renderCard}
      renderModal={renderModal}
      formRegister={formRegisterResult}
      accept={accept.join(',')}
      countInRow={1}
    />
  );
};

export default File;
