import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {Pagination} from '../../elements/Pagination/Pagination';
import Preloader from '../../elements/Preloader/Preloader';
import {PaginationWrapper} from '../../layouts/StructureList/componets/Form/Form.styles';
import {useApplicationList, usePagination} from './ApplicationsScreen.hooks';
import {Filter} from './components/Filter/Filter';
import {List} from './components/List/List';
import {
  Wrapper,
  Title,
  Content,
  ListWrapper,
  MainContent,
} from './ApplicationsScreen.styles';

const ApplicationsScreen = () => {
  const navigate = useNavigate();
  useDocumentTitle('Заявки');
  const {page, handleSwitchPage} = usePagination();
  const {list, loading, paginatorInfo} = useApplicationList();

  return (
    <Wrapper>
      <Content>
        <Title>Заявки</Title>
        <MainContent>
          {/*<Sidebar isAdaptive>*/}
          {/*  <StatisticPanel />*/}
          {/*</Sidebar>*/}
          <ListWrapper>
            <Filter applicationCount={paginatorInfo?.total || 0} />
            {loading && <Preloader />}
            <List
              items={list}
              onCardClick={(id) => navigate(`/applications/${id}`)}
            />
          </ListWrapper>
          <PaginationWrapper>
            <Pagination
              page={page || 1}
              total={paginatorInfo?.lastPage || 1}
              onChange={handleSwitchPage}
            />
          </PaginationWrapper>
        </MainContent>
      </Content>
      {/*<Sidebar>*/}
      {/*  <StatisticPanel />*/}
      {/*</Sidebar>*/}
    </Wrapper>
  );
};

export default ApplicationsScreen;
