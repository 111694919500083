import {useQuery} from '@apollo/client';

import {useParams, useSearchParams} from 'react-router-dom';
import {TEAM_TECHNICS_QUERY} from '../../../../../gql';
import {
  TeamTechnics,
  TeamTechnicsVariables,
} from '../../../../../gql/queries/team/__generated__/TeamTechnics';
import {createDict} from '../../../../../utils/Dict.utils';

export function useTechnics() {
  const [searchParams] = useSearchParams();

  const params = useParams<{teamId?: string}>();

  const {data, loading} = useQuery<TeamTechnics, TeamTechnicsVariables>(
    TEAM_TECHNICS_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip: params.teamId == null || params.teamId === '',
    }
  );

  return {
    categoryDict:
      data?.allTechnicCategories == null
        ? []
        : createDict(data.allTechnicCategories),
    list:
      data?.team.technics == null
        ? []
        : data?.team.technics
            .filter((t) => {
              if (searchParams.has('search')) {
                const search = (
                  searchParams.get('search') as string
                ).toLowerCase();

                if (
                  !t?.number?.toString().toLowerCase().includes(search) &&
                  !t?.name.toLowerCase().includes(search) &&
                  !t?.user?.profile.full_name.toLowerCase().includes(search)
                ) {
                  return false;
                }
              }

              if (searchParams.has('categoryIds')) {
                const categoryIds = searchParams.getAll(
                  'categoryIds'
                ) as string[];

                if (
                  !categoryIds.some(
                    (c) => c.toString() === t?.category.id.toString()
                  )
                ) {
                  return false;
                }
              }

              return true;
            })
            .map((e) => ({
              id: e?.id || '',
              number: e?.number || '',
              name: e?.name || '',
              category: e?.category.name || undefined,
              description: e?.note || undefined,
              user: {
                avatar: undefined,
                fullName: e?.user?.profile.full_name,
              },
            })),
    loading,
  };
}
