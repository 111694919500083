import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

interface ILabelStyled {
  active: boolean;
}

export const LabelStyled = styled.div<ILabelStyled>`
  padding: 4px 8px;

  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: var(${(p) => (p.active ? '--secondary-color' : '--warning-color')});
  background-color: ${(p) => (p.active ? '#fff' : 'rgba(238, 140, 140, 0.35)')};
  border: 1px solid
    var(${(p) => (p.active ? '--secondary-color' : '--warning-color')});

  box-shadow: inset 2px 4px 14px rgba(0, 0, 0, 0.09);
  border-radius: 8px;

  cursor: pointer;
`;

export const CardWrapper = styled.div`
  display: block;

  position: absolute;
  top: 42px;

  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    left: 8px;
    top: -18px;
    border: 9px solid transparent;
    border-bottom-color: #ffffff;
  }
`;
