import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Card = styled.article`
  width: 100%;
  padding: 16px 32px 16px 15px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ffffff;
  border-radius: 8px;

  > :not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 900px) {
    display: block;

    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  max-width: calc(264px + 153px);

  flex: auto;
  align-items: center;
`;

export const InfoBlock = styled.div`
  margin-left: 16px;
`;

export const Name = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #000;

  margin: 0;
`;

function thinText() {
  return css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: var(--secondary-text-color);
  `;
}

export const NickName = styled.p`
  margin: 8px 0 0;
  ${thinText()};
`;

export const AdditionalText = styled.p`
  margin: 4px 0 0;
  ${thinText()};
`;

export const Address = styled.div`
  width: 123px;
`;

export const Town = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #000;
`;

export const Phones = styled.div`
  width: 170px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

function link() {
  return css`
    display: block;
    font-size: 16px;
    line-height: 1;

    color: #000000;

    text-decoration: none;
  `;
}

export const Phone = styled.a`
  ${link()}
`;

export const Email = styled.a`
  ${link()}
`;

export const SocialNetworkInfo = styled.div`
  width: 100px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  > *:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 900px) {
    justify-content: start;
  }
`;

export const LinkIcon = styled.a`
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #010002;
    }
  }

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;
