import styled, {keyframes} from 'styled-components';

export const Wrapper = styled.section``;

export const Header = styled.header`
  display: flex;
  align-items: center;

  cursor: pointer;

  svg[data-at='triangle-icon'] {
    width: 8px;
    height: 5px;
    margin-right: 20px;
    flex-shrink: 1;

    transform: rotate(180deg);

    path {
      fill: var(--primary-color);

      transition: fill 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  flex: auto;

  color: var(--text-color);
`;

interface IContent {
  show: boolean;
  height: number;
}

const standardOverflow = keyframes`
  0%, 100% {
    overflow: hidden;
  }
`;

const openOverflow = keyframes`
  0%, 99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
`;

export const Content = styled.div<IContent>`
  height: 100%;

  max-height: ${(p) => (p.show ? p.height + 32 + 32 : 0)}px;

  transition: max-height 250ms linear;
  animation-name: ${(p) => (p.show ? openOverflow : standardOverflow)};
  animation-duration: 250ms;
  animation-fill-mode: forwards;
`;

export const Container = styled.div`
  margin-top: 32px;
`;
