import React, {ReactNode} from 'react';
import {Id, Url} from '../../../types/auxiliary.types';
import {FacebookIcon} from '../../icons/FacebookIcon';
import {TrashIcon} from '../../icons/TrashIcon';
import {TwitterIcon} from '../../icons/TwitterIcon';
import {VKIcon} from '../../icons/VKIcon';
import {Avatar} from '../Avatar/Avatar';
import {Button} from '../Button/Button';

import {
  Card,
  Wrapper,
  InfoBlock,
  IconsWrapper,
  LinkIcon,
  Main,
  NickName,
  Name,
  Address,
  Phones,
  Town,
  Phone,
  SocialNetworkInfo,
  AdditionalText,
  Email,
} from './ParticipantList.styles';

interface Props {
  onDelete?: any;
  users: {
    idParticipant?: Id;
    id: Id;
    avatar?: Url;
    name: string;
    nickname: string;
    address: string;
    region?: string;
    team?: string;
    phones: string[];
    emails?: string[];
    socialNetworkInfo: {
      vk?: Url;
      facebook?: Url;
      twitter?: Url;
      telegram?: string;
    };
    renderAdditionalFirstColumn?: () => ReactNode;
  }[];
}

export function ParticipantList(props: Props) {
  return (
    <Wrapper>
      {props.users.length === 0 ? (
        <div>Данные отсутствуют</div>
      ) : (
        props.users.map((u) => (
          <Card key={u.id}>
            <Main>
              <Avatar fullName={u.name} src={u.avatar} size="small" />
              <InfoBlock>
                <Name>{u.name}</Name>
                {u.nickname != null && u.nickname !== '' && (
                  <NickName>{u.nickname}</NickName>
                )}
                {u.renderAdditionalFirstColumn?.()}
              </InfoBlock>
            </Main>
            <Address>
              <Town>{u.address}</Town>
              {u.region != null && u.region !== '' && (
                <AdditionalText>{u.region}</AdditionalText>
              )}
              {u.team != null && u.team !== '' && (
                <AdditionalText>{u.team}</AdditionalText>
              )}
            </Address>
            <Phones>
              {u.phones.map((p) => (
                <Phone key={p} href={`tel:${p}`}>
                  {p}
                </Phone>
              ))}
              {u.emails != null &&
                u.emails.map((e) => (
                  <Email key={e} href={`mailto:${e}`}>
                    {e}
                  </Email>
                ))}
            </Phones>
            <SocialNetworkInfo>
              <IconsWrapper>
                {u.socialNetworkInfo.vk != null &&
                  u.socialNetworkInfo.vk !== '' && (
                    <LinkIcon href={u.socialNetworkInfo.vk}>
                      <VKIcon />
                    </LinkIcon>
                  )}
                {u.socialNetworkInfo.facebook != null &&
                  u.socialNetworkInfo.facebook !== '' && (
                    <LinkIcon href={u.socialNetworkInfo.facebook}>
                      <FacebookIcon />
                    </LinkIcon>
                  )}
                {u.socialNetworkInfo.twitter != null &&
                  u.socialNetworkInfo.twitter !== '' && (
                    <LinkIcon href={u.socialNetworkInfo.twitter}>
                      <TwitterIcon />
                    </LinkIcon>
                  )}
              </IconsWrapper>
            </SocialNetworkInfo>
            {props.onDelete && (
              <div>
                <Button onClick={() => props.onDelete(u.idParticipant)}>
                  <TrashIcon />
                </Button>
              </div>
            )}
          </Card>
        ))
      )}
    </Wrapper>
  );
}
