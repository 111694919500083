import {useQuery} from '@apollo/client';

import {useParams, useSearchParams} from 'react-router-dom';
import {GROUP_NAME_QUERY} from '../../../gql';
import {GroupName,GroupNameVariables} from '../../../gql/queries/group/__generated__/GroupName';

import {useUrlParamInitialization} from '../../../hooks/useUrl.hooks';

export function useGroup() {
  const params = useParams<{groupId?: string}>();

  useUrlParamInitialization({
    tab: 'about',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  const {data, loading} = useQuery<GroupName, GroupNameVariables>(GROUP_NAME_QUERY, {
    variables: {
      id: (params.groupId || '').toString(),
    },
    skip:
      activeTab == null ||
      activeTab === '' ||
      params.groupId == null ||
      params.groupId === '',
  });

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {
    data,
    loading,
    handleSwitchTab,
    activeTab,
  };
}
