import {useQuery} from '@apollo/client';
import {APPLICATION_MISSING_DICT_QUERY} from '../../../../../gql';
import {MissingDict} from '../../../../../gql/application/queries/__generated__/MissingDict';
import {createDict} from '../../../../../utils/Dict.utils';

export function useDict() {
  const {data} = useQuery<MissingDict>(APPLICATION_MISSING_DICT_QUERY);

  return {
    phoneStatuses:
      data == null
        ? []
        : createDict(data.allIssueMissingAvailablePhoneStatuses),
    hasPhone:
      data == null ? [] : createDict(data.allIssueMissingHavePhoneStatuses),
    diseases: data == null ? [] : createDict(data.allDiseases),
  };
}
