import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Button = styled.button`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed var(--primary-color);
  border-radius: 8px;
  background-color: transparent;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;

  color: var(--primary-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const PlusIconWrapper = styled.div`
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: var(--primary-color);

  margin-right: 12px;

  svg {
    width: 12px;
    height: 12px;

    path {
      stroke: #fff;
    }
  }
`;

export const Group = styled.section``;

export const GroupName = styled.h2`
  margin: 0 0 16px;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333;
`;

export const TimeWrapper = styled.div`
  margin: 10px 0 0;

  time {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: var(--secondary-text-color);
  }
`;
