import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 295px;
  height: 100%;
  padding: 28px 30px;

  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0 0 42px rgba(0, 0, 0, 0.25);
`;

export const LogoWrapper = styled.div``;

export const MenuContainer = styled.ul`
  margin: 18px 0 0;
  padding: 0;
  list-style: none;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface IMenuItem {
  active: boolean;
}

export const MenuItem = styled.li<IMenuItem>`
  padding: 14px 0;

  cursor: pointer;

  a,
  div {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;

    color: ${(p) => (p.active ? 'var(--additional-color)' : '#000')};

    display: flex;
    align-items: center;
    text-decoration: none;
  }

  svg {
    margin-right: 30px;

    path {
      fill: ${(p) => (p.active ? 'var(--additional-color)' : '#A1B2C8')};
    }
  }

  &:hover {
    a {
      color: var(--additional-color);
    }

    svg path {
      fill: var(--additional-color);
    }
  }
`;
