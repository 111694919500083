import React, {ReactNode, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import {Gallery} from '../../../../../elements/Gallery/Gallery';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {AddPhoto} from '../../modals/AddPhoto/AddPhoto';
import {usePhotos} from './Photos.hooks';
import {Photo as PhotoType} from './Photos.types';

import {
  Wrapper,
  Photo,
  PhotoGalleryWrapper,
  PhotoGallery,
  TitleGallery,
  Container,
  BreadCrumbs,
  Home,
  BreadCrumb,
} from './Photos.styles';

interface Props {
  setTitle?: (value: ReactNode) => void;
}

export function Photos(props: Props) {
  const {setTitle} = props;

  const params = useParams<{applicationId?: string}>();
  const [numberSlide, setNumberSlide] = useState<number | null>(null);
  const {list, loading, missingName, fetch} = usePhotos();

  useEffect(() => {
    if (setTitle != null && missingName != null && missingName !== '') {
      setTitle(
        <BreadCrumbs>
          <Home
            to={{
              pathname: `/applications/${params.applicationId}`,
              search: 'tab=missing',
            }}>
            Пропавшие
          </Home>
          <BreadCrumb>
            {' > '}Фото - {missingName}
          </BreadCrumb>
        </BreadCrumbs>
      );
    }

    return () => {
      if (setTitle != null) {
        setTitle(null);
      }
    };
  }, [missingName, params.applicationId, setTitle]);

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  if (loading) return <Preloader />;

  function renderModal() {
    if (file == null) return null;

    return (
      <AddPhoto
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  function renderCard({url: photoSrc}: PhotoType, i: number) {
    return (
      <Photo
        key={photoSrc}
        height={312}
        width={312}
        alt="Фото"
        src={photoSrc}
        onClick={() => setNumberSlide(i)}
      />
    );
  }

  function renderGalleryCard(photo: {name: string; url: string}) {
    return (
      <PhotoGalleryWrapper>
        <PhotoGallery alt="Фото" src={photo.url} />
        <TitleGallery>{photo.name}</TitleGallery>
      </PhotoGalleryWrapper>
    );
  }

  return (
    <Wrapper>
      {file != null && renderModal()}
      {numberSlide != null && (
        <Gallery
          list={list}
          renderCard={renderGalleryCard}
          firstSlide={numberSlide}
          onModalClose={() => setNumberSlide(null)}
        />
      )}
      <AddButton
        typeButton="fileInput"
        buttonLabel="Добавить фото"
        accept=".jpg,.jpeg,.png"
        formRegister={formRegisterResult}
      />
      <Container>
        {list.length === 0 ? 'Данные отсутствуют' : list.map(renderCard)}
      </Container>
    </Wrapper>
  );
}
