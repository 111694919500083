import React, {useCallback, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {UserContext} from '../../../../../context/UserContext';
import {useAuthenticatedUser} from '../../../../../hooks/useAuthenticatedUser';
import {useLogoutHandler} from '../../../../../hooks/useLogoutHandler';
import {Avatar} from '../../../../elements/Avatar/Avatar';
import {Button} from '../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import {ExitIcon} from '../../../../icons/ExitIcon';
import {SettingsIcon} from '../../../../icons/SettingsIcon';
import {connectCentrifuge} from '../../webSocket.hooks';
import Notifications from '../NotificationLabel/NotificationLabel';
import sound from '../../../../../assets/audio/notificationSound.mp3';

import {
  ButtonWrapper,
  IconsWrapper,
  IconWrapper,
  Name,
  UserWrapper,
  Wrapper,
  StyledNavLink,
} from './User.styles';

export function User() {
  const {user: data} = useAuthenticatedUser();

  const user = useContext(UserContext);

  const logoutHandler = useLogoutHandler();

  const [open, setOpen] = useState(false);

  const [webSocketData, setWebsocketData] = useState<any>([]);

  const handlePublication = useCallback(
    (ctx: any) => {
      if (data?.notificationSound) {
        new Audio(sound).play();
      }
      setWebsocketData((prevData: any) => {
        const newData =
          prevData.length >= 10 ? prevData.slice(1) : [...prevData];
        return [...newData, ctx.data];
      });
    },
    [data?.notificationSound]
  );

  useEffect(() => {
    connectCentrifuge(handlePublication).catch((err) => {
      console.error('Ошибка при инициализации подключения:', err);
    });
  }, [handlePublication]);

  const reversedData = webSocketData.slice().reverse();

  if (!user) return null;

  const [firstName = ' ', lastName = ' '] = user?.fullName.split(' ');

  return (
    <Wrapper>
      <UserWrapper>
        <StyledNavLink to={'/profile'}>
          <Avatar firstName={firstName} lastName={lastName} size="small" />
        </StyledNavLink>
        <Name>
          {firstName} {lastName}
        </Name>
      </UserWrapper>
      <IconsWrapper>
        <IconWrapper showBadge={webSocketData.length !== 0}>
          <Notifications notifications={reversedData} />
        </IconWrapper>
        {data?.link !== null && (
          <a href={data?.link}>
            <IconWrapper>
              <SettingsIcon />
            </IconWrapper>
          </a>
        )}
        <IconWrapper light onClick={() => setOpen(true)}>
          <ExitIcon />
        </IconWrapper>
        {open && (
          <ModalWrapper
            onModalClose={() => setOpen(false)}
            title={'Вы уверены что хотите выйти?'}>
            <Wrapper>
              <ButtonWrapper>
                <Button
                  title="Выйти"
                  variant={'outlined'}
                  color={'primary'}
                  onClick={logoutHandler}
                />
              </ButtonWrapper>

              <Button
                title="Отмена"
                variant={'outlined'}
                color={'primary'}
                onClick={() => setOpen(false)}
              />
            </Wrapper>
          </ModalWrapper>
        )}
      </IconsWrapper>
    </Wrapper>
  );
}
