import React, {ReactNode, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import MIMEConstants from '../../../../../../constants/mime.constants';
import {FileCard} from '../../../../../cards/FileCard/FileCard';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {Wrapper, Container} from './Files.styles';
import {AddFile} from '../../modals/AddFile/AddFile';
import {useFiles} from './Files.hooks';

import {BreadCrumbs} from '../BreadCrumbs/BreadCrumbs';

interface Props {
  setTitle?: (value: ReactNode) => void;
}

export function Files(props: Props) {
  const {setTitle} = props;

  const params = useParams<{applicationId?: string}>();
  const {list, loading, missingName, fetch} = useFiles();

  useEffect(() => {
    if (setTitle != null && missingName != null && missingName !== '') {
      setTitle(<BreadCrumbs missingName={missingName} subtitle={'Файлы'} />);
    }

    return () => {
      if (setTitle != null) {
        setTitle(null);
      }
    };
  }, [missingName, params.applicationId, setTitle]);

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  if (loading) return <Preloader />;

  function renderModal() {
    if (file == null) return null;

    return (
      <AddFile
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  const accept = Object.entries(MIMEConstants).reduce(
    (acc: string[], [k, v]) =>
      ['word', 'exel', 'pdf'].includes(v) ? [...acc, k] : acc,
    []
  );

  return (
    <Wrapper>
      {file != null && renderModal()}
      <AddButton
        typeButton="fileInput"
        buttonLabel="Добавить файл"
        accept={accept.join(',')}
        formRegister={formRegisterResult}
      />
      <Container>
        {list.length === 0
          ? 'Данные отсутствуют'
          : list.map((card) => (
              <FileCard
                key={card.url}
                name={card.name}
                mime={card.mime}
                size={card.size}
                url={card.url}
              />
            ))}
      </Container>
    </Wrapper>
  );
}
