import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {toast} from 'react-toastify';
import {ADD_DUTY_MUTATION, PRIVATE_INFO_QUERY} from '../../../../../../../gql';
import {
  AddDuty,
  AddDutyVariables,
} from '../../../../../../../gql/profile/mutations/__generated__/AddDuty';
import {PrivateInfo} from '../../../../../../../gql/profile/queries/__generated__/PrivateInfo';
import {createDateTime_DTO} from '../../../../../../../utils/Date.utils';
import {FormFields} from '../form/types';

export function useAddDuty(onAddSuccess: () => void) {
  const [addDuty, {loading}] = useMutation<AddDuty, AddDutyVariables>(
    ADD_DUTY_MUTATION
  );

  const handleSubmit = useCallback(
    (data: FormFields, setError?: (error: string) => void) => {
      if (data.startAt == null || data.endAt == null) return;

      addDuty({
        variables: {
          input: {
            groupId: Number(data.groupId),
            dateStart: createDateTime_DTO(data.startAt),
            dateEnd: createDateTime_DTO(data.endAt),
          },
        },
      })
        .then(() => {
          toast('Дежурство успешно добавлено!');
          onAddSuccess();
        })
        .catch((e) => {
          const errorNode = new ApolloError(e)
            .graphQLErrors?.[0] as unknown as {debugMessage: string};

          setError?.(errorNode?.debugMessage || '');
        });
    },
    [addDuty, onAddSuccess]
  );

  return {handleSubmit, loading};
}

export function useVigialGroups() {
  const {data, loading} = useQuery<PrivateInfo>(PRIVATE_INFO_QUERY);
  return {
    loading,
    groups: data == null ? [] : data.me.vigilGroups,
  };
}
