import styled from 'styled-components';

export const StatisticsWrapper = styled.div`
  margin-bottom: 16px;

  > :not(:last-child) {
    margin-bottom: 16px;

    @media screen and (max-width: 900px) {
      margin-bottom: 8px;
    }
  }
`;
