import {toast} from 'react-toastify';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {EDIT_PROFILE_MUTATION, PROFILE_QUERY} from '../../../../gql';
import {
  EditProfile,
  EditProfileVariables,
} from '../../../../gql/profile/mutations/__generated__/EditProfile';
import {Profile} from '../../../../gql/profile/queries/__generated__/Profile';
import {useServerErrors} from '../../../../hooks/useErrors.hooks';
import {createDate_DTO} from '../../../../utils/Date.utils';
import {FormFields} from '../../../forms/RegistrationFrom/RegistrationFrom.types';

export function useProfile() {
  const {data, loading} = useQuery<Profile>(PROFILE_QUERY);

  const name = data?.me?.profile?.full_name || '';

  const [lastName, firstName, middleName] = name.split(' ');

  // const normalize = (s: string) => {
  //   return 'https://' + s.replace(/https:\/\/|http:\/\//gi, '');
  // };

  return {
    loading,
    data: {
      canSendTelegram: data?.me.profile.can_send_telegram,
      fullName: data?.me.profile.full_name,
      personalInfo: {
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        nick: data?.me.profile.nickname || '',
        birthday:
          data?.me.profile.birthday == null
            ? null
            : new Date(data.me.profile.birthday),
        category:
          data?.me?.profile?.driver_license_categories?.map((e) =>
            e?.key.toString()
          ) || [], // not id
      },
      contactInfo: {
        primaryEmail: data?.me.email || '',
        secondaryEmails:
          data?.me.additionalEmails?.map((e) => String(e?.name)) || [],
        primaryPhone: data?.me.profile.phone_number || '',
        secondaryPhones:
          data?.me.additionalPhones?.map((p) => String(p?.number)) || [],
        address: data?.me.profile.address || '',
      },
      squadInfo: {
        squad: data?.me.team?.id || '',
        roles: data?.me.groups?.map((g) => g.id) || [],
        regions: data?.me.regions?.map((r) => r.id) || [],
      },
      socialNetworkInfo: {
        vk: data?.me.profile.account_vkontakte
          ? data?.me.profile.account_vkontakte
          : '',
        facebook: data?.me.profile.account_facebook
          ? data?.me.profile.account_facebook
          : '',
        twitter: data?.me.profile.account_twitter
          ? data?.me.profile.account_twitter
          : '',
        telegram: data?.me.profile.account_telegram
          ? data?.me.profile.account_telegram
          : '',
      },
      additionalInfo: {
        qualification: data?.me.profile.qualification || '',
        message: '',
        agreeWork: true,
        agreePersonalData: true,
        sendNotificationEmail:
          data?.me.profile.send_notification_email || false,
        playNotificationSound:
          data?.me.profile.play_notification_sound || false,
      },
    },
  };
}

export const useActions = () => {
  const navigate = useNavigate();

  const {addServerError} = useServerErrors<FormFields>({
    'input.fullName': 'personalInfo.firstName',
    'input.nickname': 'personalInfo.nick',
    'input.birthday': 'personalInfo.birthday',
    'input.driverLicenseCategories': 'personalInfo.category',
    'input.additionalEmails': 'contactInfo.secondaryEmails',
    'input.additionalPhones': 'contactInfo.secondaryPhones',
    'input.address': 'contactInfo.address',
    'input.accountVkontakte': 'socialNetworkInfo.vk',
    'input.accountFacebook': 'socialNetworkInfo.facebook',
    'input.accountTwitter': 'socialNetworkInfo.twitter',
    'input.accountTelegram': 'socialNetworkInfo.telegram',
    'input.qualification': 'additionalInfo.qualification',
  });

  const [editProfile, {loading}] = useMutation<
    EditProfile,
    EditProfileVariables
  >(EDIT_PROFILE_MUTATION);

  const handleEditProfile = useCallback(
    (data: FormFields, setError?: UseFormSetError<FormFields>) => {
      if (data.personalInfo.birthday == null) return null;
      editProfile({
        variables: {
          input: {
            fullName: [
              data.personalInfo.lastName,
              data.personalInfo.firstName,
              data.personalInfo.middleName,
            ]
              .filter(Boolean)
              .join(' '),
            playNotificationSound: data.additionalInfo.playNotificationSound,
            sendNotificationEmail: data.additionalInfo.sendNotificationEmail,
            birthday: createDate_DTO(data.personalInfo.birthday),
            address: data.contactInfo.address,
            qualification: data.additionalInfo.qualification,
            nickname: data.personalInfo.nick,
            accountVkontakte: data.socialNetworkInfo.vk,
            accountFacebook: data.socialNetworkInfo.facebook,
            accountTelegram: data.socialNetworkInfo.telegram,
            accountTwitter: data.socialNetworkInfo.twitter,
            additionalEmails: data.contactInfo.secondaryEmails,
            additionalPhones: data.contactInfo.secondaryPhones,
            driverLicenseCategories:
              data?.personalInfo?.category?.map((s) => s.toString()) || [],
          },
        },
      })
        .then(() => {
          navigate('/profile');
          toast('Ваши изменения успешно внесены!', {type: 'success'});
        })
        .catch((e) =>
          addServerError(new ApolloError(e).graphQLErrors, setError)
        );
    },
    [addServerError, editProfile, navigate]
  );

  return {loading, handleEditProfile};
};
