import React, {ReactNode, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {VideoCard} from '../../../../../cards/Video/component';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {AddVideo} from '../../modals/AddVideo/component';
import {useVideos} from './hooks';
import {Video as VideoType} from './types';

import {Wrapper, Container} from './styles';
import {BreadCrumbs} from '../BreadCrumbs/BreadCrumbs';

interface Props {
  setTitle?: (value: ReactNode) => void;
}

export function Videos(props: Props) {
  const [shownModal, setShownModal] = useState(false);
  const {setTitle} = props;

  const params = useParams<{applicationId?: string}>();
  const {list, loading, missingName, fetch, missingId} = useVideos();

  useEffect(() => {
    if (setTitle != null && missingName != null && missingName !== '') {
      setTitle(<BreadCrumbs subtitle="Видео" missingName={missingName} />);
    }

    return () => {
      if (setTitle != null) {
        setTitle(null);
      }
    };
  }, [missingName, params.applicationId, setTitle]);

  if (loading) return <Preloader />;

  function renderCard(card: VideoType, i: number) {
    return <VideoCard key={i} name={card.name} url={card.url} />;
  }

  return (
    <Wrapper>
      {shownModal && (
        <AddVideo
          missingId={missingId}
          onAddSuccess={fetch}
          onModalClose={() => setShownModal(false)}
        />
      )}
      <AddButton
        typeButton="button"
        buttonLabel="Добавить видео"
        addItem={() => setShownModal(true)}
      />
      <Container>
        {list.length === 0 ? 'Данные отсутствуют' : list.map(renderCard)}
      </Container>
    </Wrapper>
  );
}
