import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Button} from '../../../elements/Button/Button';
import {Pagination} from '../../../elements/Pagination/Pagination';
import Preloader from '../../../elements/Preloader/Preloader';
import {StatisticSimple} from '../../../elements/StatisticSimple/StatisticSimple';
import Card from '../../../cards/task/component';
import {Filter} from './components/filter/component';
import {useTaskList, usePagination, useStatistic} from './hooks';
import {
  List,
  PaginationWrapper,
  Wrapper,
  Title,
  Main,
  Secondary,
} from './styles';

export default function TaskListScreen() {
  useDocumentTitle('Задачи');

  const navigate = useNavigate();

  const {data, pagination, loading} = useTaskList();
  const {page, handleSwitchPage} = usePagination();
  const {loading: loadingStatistic, statistic} = useStatistic();

  return (
    <Wrapper>
      <Main>
        <Title>Задачи</Title>
        <Filter tasksCount={pagination?.total || 0} />
        {loading ? (
          <Preloader />
        ) : (
          <List>
            {data.map((task) => {
              return (
                <Card
                  key={task.id}
                  number={Number(task.id)}
                  issue={task.issue}
                  call={task.call}
                  isUrgent={task.isUrgent}
                  description={task.description}
                  region={task.region}
                  createAt={task.createDate}
                  avatarUrl={task.avatarResponsible}
                  fullNameResponsible={task.responsible}
                  status={task.status}
                  statusColor={task.statusColor}
                  result={task.result}
                />
              );
            })}
          </List>
        )}
        <PaginationWrapper>
          <Pagination
            page={page}
            total={pagination.lasPage}
            onChange={handleSwitchPage}
          />
        </PaginationWrapper>
      </Main>
      <Secondary>
        <Button title="Создать задачу" onClick={() => navigate('/tasks/new')} />
        {loadingStatistic ? (
          <Preloader />
        ) : (
          <StatisticSimple title="По статусу" items={statistic} />
        )}
      </Secondary>
    </Wrapper>
  );
}
