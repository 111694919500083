import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {Wrapper, Title, CommentBlock, LinkBlock} from './ResourcesItem.styles';

interface Props {
  id: Id;
  name?: string;
  link: string;
  comment?: string;
}

export default function ResourcesItem(props: Props) {
  return (
    <Wrapper>
      <Title>{props.name}</Title>
      <CommentBlock>{props.comment}</CommentBlock>
      <LinkBlock href={props.link}>{props.link}</LinkBlock>
    </Wrapper>
  );
}
