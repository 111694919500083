import React from 'react';
import {SvgStyled} from '../Icons.styles';

export const TownIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        data-element="town"
        d="M14.8094 15.0625V10.0616H9.42325V15.0625H8.48575V9.12412H11.6476V3.77744H10.5937V1.68272H9.40106V0H8.46356V1.68272H7.27094V3.77744H6.217V15.0625H5.2795V5.87219H1.19059V15.0625H0V16H16V15.0625H14.8094ZM9.51091 4.93469H10.4484V5.87219H9.51091V4.93469ZM9.51091 7.02941H10.4484V7.96691H9.51091V7.02941ZM3.68309 14.6948H2.74559V13.7573H3.68309V14.6948ZM3.68309 12.4522H2.74559V11.5147H3.68309V12.4522ZM3.68309 10.2096H2.74559V9.27206H3.68309V10.2096ZM3.68309 7.96691H2.74559V7.02941H3.68309V7.96691ZM8.35369 7.96691H7.41619V7.02941H8.35369V7.96691ZM8.35369 5.87219H7.41619V4.93469H8.35369V5.87219ZM8.46353 2.83994H9.40103V3.77744H8.46353V2.83994ZM11.5312 15.0625H10.5937V11.3H11.5312V15.0625ZM13.639 15.0625H12.7015V11.3H13.639V15.0625Z"
      />
    </SvgStyled>
  );
};
