import React from 'react';
import {SvgStyled} from './Icons.styles';

export const InfoIcon = () => {
  return (
    <SvgStyled
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48093 0.00909377 0.00909377 4.48093 0 10V17C0.00482813 18.6548 1.34517 19.9952 3 20H10C15.5229 20 20 15.5228 20 10C20 4.47718 15.5229 0 10 0ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9.00001 15.5523 9.00001 15V10H8.00003C7.44775 10 7.00004 9.5523 7.00004 9.00001C7.00004 8.44773 7.44775 8.00003 8.00003 8.00003H9.00001C10.1041 8.00115 10.9989 8.8959 11 10V15ZM11 6.00001C11 6.55229 10.5523 7 10 7C9.44772 7 9.00001 6.55229 9.00001 6.00001V5.00002C9.00001 4.44774 9.44772 4.00004 10 4.00004C10.5523 4.00004 11 4.44774 11 5.00002V6.00001H11Z" />
    </SvgStyled>
  );
};
