import React from 'react';
import {useForm, UseFormSetError} from 'react-hook-form';
import {Button} from '../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../elements/Preloader/Preloader';
import {TextField} from '../../../../elements/TextField/TextField';

import {Buttons, Form} from './style';
import {FormFields} from './types';

interface Props {
  title: string;
  defaultValues?: FormFields;
  loading?: boolean;
  labelSubmitButton: string;
  submit(data: FormFields, setError: UseFormSetError<FormFields>): void;
  onModalClose(): void;
}

export function Verification(props: Props) {
  const {handleSubmit, register, formState, setError} = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      description: '',
    },
    mode: 'onSubmit',
  });

  return (
    <ModalWrapper title={props.title} onModalClose={props.onModalClose}>
      {props.loading ? (
        <Preloader />
      ) : (
        <Form
          onSubmit={handleSubmit((d) => props.submit(d, setError))}
          onReset={props.onModalClose}>
          <TextField
            register={register('description', {
              maxLength: {
                value: 3000,
                message: 'Количество символов в поле не может превышать 3000',
              },
            })}
            error={formState.errors.description}
            label="Содержание"
            placeholder="Введите"
            isTextarea
            rows={2}
          />
          <Buttons>
            <Button
              title="Отмена"
              type="reset"
              variant={'outlined'}
              color={'primary'}
              fullWidth
              disabled={formState.isSubmitting}
            />
            <Button
              title={props.labelSubmitButton}
              type="submit"
              fullWidth
              disabled={formState.isSubmitting}
            />
          </Buttons>
        </Form>
      )}
    </ModalWrapper>
  );
}
