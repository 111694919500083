import {useQuery} from '@apollo/client';
import {USEFUL_ALGORITHM_QUERY} from '../../../../../gql';
import {
  Algorithm_algorithms_data,
  Algorithm,
} from '../../../../../gql/queries/__generated__/Algorithm';

export function useAlgorithm() {
  const {data, loading} = useQuery<Algorithm, Algorithm_algorithms_data>(
    USEFUL_ALGORITHM_QUERY
  );

  return {
    loading,
    algorithm:
      data?.algorithms?.data == null
        ? []
        : data.algorithms?.data.map((item) => ({
            id: item.id || '',
            name: item.name || '',
            text: item.text || '',
          })),
  };
}
