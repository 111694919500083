import {useQuery} from '@apollo/client';
import {APPLICATION_GENERAL_DICTIONARIES_QUERY} from '../../../../../gql';
import {ApplicationGeneralDictionaries} from '../../../../../gql/application/queries/__generated__/ApplicationGeneralDictionaries';
import {createDict} from '../../../../../utils/Dict.utils';
import {Option} from '../../../../elements/Select/Select.types';

export function useDict() {
  const {data, loading} = useQuery<ApplicationGeneralDictionaries>(
    APPLICATION_GENERAL_DICTIONARIES_QUERY
  );

  const areaDict =
    data == null || data.allRegions == null
      ? {}
      : data.allRegions.reduce((acc: Record<string, Option[]>, region) => {
          if (region == null) return acc;

          return {
            ...acc,
            [region.id]:
              region.districts == null
                ? []
                : region.districts.map((district) => ({
                    label: district?.name || '',
                    value: district?.id || '',
                  })),
          };
        }, {});

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.allRegions),
    areas: areaDict,
    locations: data == null ? [] : createDict(data.searchLocations),
    sources: data == null ? [] : createDict(data.allIssueSources),
  };
}
