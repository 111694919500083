import React from 'react';
import {SvgStyled} from './Icons.styles';

export const RingingIcon = () => {
  return (
    <SvgStyled
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M21.4755 16.678L18.6786 13.8869C17.6797 12.8901 15.9816 13.2889 15.582 14.5847C15.2823 15.4818 14.2834 15.9802 13.3844 15.7808C11.3866 15.2824 8.68957 12.6907 8.19012 10.5975C7.89045 9.70029 8.48979 8.70348 9.3888 8.40448C10.6874 8.00576 11.0869 6.31119 10.088 5.31438L7.2911 2.52332C6.49198 1.82556 5.29329 1.82556 4.59406 2.52332L2.69614 4.41725C0.798225 6.41087 2.89592 11.6939 7.59077 16.3789C12.2856 21.0639 17.5798 23.2569 19.5776 21.2633L21.4755 19.3693C22.1748 18.5719 22.1748 17.3757 21.4755 16.678Z" />
      <path d="M22 5H14M22 5L18 1M22 5L18 9" stroke="#A1B2C8" strokeWidth="2" />
    </SvgStyled>
  );
};
