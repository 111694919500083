import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Description = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;
