import React from 'react';
import {SvgStyled} from './Icons.styles';

export const WiFiIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96842 13.335C10.1001 12.5226 9.54829 11.7573 8.73588 11.6256C7.92347 11.4939 7.15812 12.0457 7.02642 12.8581C6.89473 13.6705 7.44655 14.4359 8.25896 14.5676C9.07137 14.6993 9.83672 14.1474 9.96842 13.335Z"
        fill="#376092"
      />
      <path
        d="M16.0551 5.54177C13.9074 3.39402 11.1453 2.41211 8.49998 2.41211C5.85467 2.41211 3.0926 3.39402 0.944821 5.54177C0.351259 6.13602 0.353978 7.0562 0.93929 7.64398C1.67023 8.38305 2.62723 8.08886 3.05576 7.64598C4.51057 6.19311 6.44385 5.39298 8.49998 5.39298C10.5561 5.39298 12.4894 6.19311 13.9442 7.64598C14.3727 8.08886 15.3297 8.38305 16.0607 7.64398C16.646 7.0562 16.6487 6.13602 16.0551 5.54177Z"
        fill="#376092"
      />
      <path
        d="M8.49996 7.00977C7.01806 7.00977 5.42796 7.5607 4.19581 8.79258C3.59465 9.3937 3.60662 10.3181 4.20181 10.907C4.79278 11.494 5.71224 11.4915 6.30306 10.9007C6.88987 10.3139 7.67006 9.99077 8.49993 9.99077C9.32981 9.99077 10.11 10.3139 10.6968 10.9007C11.2876 11.4915 12.2071 11.494 12.7981 10.907C13.3932 10.3181 13.4052 9.3937 12.8041 8.79258C11.572 7.56073 9.98187 7.00977 8.49996 7.00977Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
