import {useQuery} from '@apollo/client';
import {SHORT_REGIONS_QUERY, SHORT_TEAMS_QUERY} from '../../../../../gql';
import {ShortRegions} from '../../../../../gql/queries/__generated__/ShortRegions';
import {ShortTeams} from '../../../../../gql/queries/__generated__/ShortTeams';
import {Option} from '../../../../elements/Select/Select.types';

export function useDict() {
  const {data: squads} = useQuery<ShortTeams>(SHORT_TEAMS_QUERY);
  const {data: regions} = useQuery<ShortRegions>(SHORT_REGIONS_QUERY);

  const squadsDict =
    squads == null || squads.allTeams == null
      ? []
      : (squads.allTeams
          .map((team) => {
            if (team == null) return null;

            return {
              label: team.name,
              value: team.id,
            };
          })
          .filter(Boolean) as Option[]);

  const rolesDict =
    squads == null || squads.allTeams == null
      ? []
      : squads.allTeams.reduce((acc: Record<string, Option[]>, team) => {
          if (team == null) return acc;

          return {
            ...acc,
            [team.id]:
              team.groups == null
                ? []
                : team.groups.map((group) => ({
                    label: group?.full_name,
                    value: group?.id,
                  })),
          };
        }, {});

  const regionsDict =
    regions == null || regions.allRegions == null
      ? []
      : (regions.allRegions
          .map((team) => {
            if (team == null) return null;

            return {
              label: team.name,
              value: team.id,
            };
          })
          .filter(Boolean) as Option[]);

  return {
    squads: squadsDict as {label: string; value: string}[],
    roles: rolesDict as Record<string, Option[]>,
    regions: regionsDict as {label: string; value: string}[],
  };
}
