import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {APPLICATION_CREATE_MUTATION} from '../../../../../../gql';
import {toast} from 'react-toastify';

import {
  Create,
  CreateVariables,
} from '../../../../../../gql/application/mutations/__generated__/Create';
import {useServerErrors} from '../../../../../../hooks/useErrors.hooks';
import {Id} from '../../../../../../types/auxiliary.types';
import {createDateTime_DTO} from '../../../../../../utils/Date.utils';
import {FormFields} from './Step1.types';

export function useHandler(
  setServerError: UseFormSetError<FormFields>,
  onSuccess?: (id: Id) => void
) {
  const [createApplication] = useMutation<Create, CreateVariables>(
    APPLICATION_CREATE_MUTATION
  );

  const {addServerError} = useServerErrors(
    {
      'input.regionId': 'region',
      // 'input.': 'additionalRegions',
      'input.districts': 'area',
      'input.searchLocation': 'location',
      'input.sourceId': 'source',
      'input.dateOfLoss': 'date',
      'input.reasonOfLoss': 'circumstances',
      'input.lastSeenLocation': 'lastSeen',
      'input.isTraining': 'educational',
      'input.isForestOnline': 'forestInTouch',
      'input.isEmergency': 'urgently',
      'input.isReverseLookup': 'reverseLookup',
    },
    setServerError
  );

  const handleSubmit1Step = useCallback(
    async (data: FormFields) => {
      await createApplication({
        variables: {
          input: {
            ...(data.date != null && {
              dateOfLoss: `${createDateTime_DTO(data.date)}`,
            }),
            ...(data.area.length !== 0 && {
              districts: data.area.map((a) => Number(a)),
            }),
            isEmergency: data.urgently,
            isReverseLookup: data.reverseLookup,
            isTraining: data.educational,
            isForestOnline: data.forestInTouch,
            lastSeenLocation: data.lastSeen,
            searchLocation: data.location.toString(),
            reasonOfLoss: data.circumstances,
            regionId: Number(data.region),
            sourceId: Number(data.source),
          },
        },
      })
        .then((response) => {
          const applicationId = response.data?.createIssue?.id;

          if (applicationId == null) return toast('Ошибка!', {type: 'error'});

          onSuccess?.(applicationId);
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [createApplication, onSuccess]
  );

  return {handleSubmit1Step};
}
