import {useQuery} from '@apollo/client';

import {USEFUL_TYPOGRAPHY_QUERY} from '../../../../../gql';
import {
  Typography_printings_data,
  Typography,
} from '../../../../../gql/queries/__generated__/Typography';

export const useTypography = () => {
  const {data, loading} = useQuery<Typography, Typography_printings_data>(
    USEFUL_TYPOGRAPHY_QUERY
  );
  return {
    loading,
    typography:
      data?.printings == null
        ? []
        : data.printings.data.map((i) => ({
            id: i.id,
            name: i.name,
            address: i.address,
            note: i.note,
            startTime: i.start_time,
            endTime: i.end_time,
            region: i.region,
            printMaps: i.print_maps,
            maxInColor: i.max_in_color,
          })),
  };
};
