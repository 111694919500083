import React from 'react';
import {Applicant} from '../Applicant/Applicant';
import {useHandler} from './NewApplicant.hooks';

interface Props {
  onClose: () => void;
  onSuccess?: () => void;
}

export function NewApplicant(props: Props) {
  const {handleSubmit} = useHandler();

  return (
    <Applicant
      title="Добавление заявителя"
      onClose={props.onClose}
      onSave={(d, error) =>
        handleSubmit(d, error, () => {
          props.onSuccess?.();
          props.onClose();
        })
      }
    />
  );
}
