import styled from 'styled-components';
import {Input} from '../Input/Input';

export const SearchFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 0;
  position: relative;
`;

export const SearchIconStyled = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;

  svg path {
    fill: var(--additional-color);
  }
`;

export const SearchInputStyled = styled(Input)`
  padding-left: 2.5rem;
`;
