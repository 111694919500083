import React from 'react';
import {CopyrightBox, CopyrightText} from './Copyright.styles';

const COPYRIGHT_TEXT = 'Центр поиска пропавших людей';
const COPYRIGHT_DATE = '© 2022';

export const Copyright = () => {
  return (
    <CopyrightBox>
      <CopyrightText>{COPYRIGHT_DATE}</CopyrightText>
      <CopyrightText>{COPYRIGHT_TEXT}</CopyrightText>
    </CopyrightBox>
  );
};
