import {ApolloError, useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {
  AddFeedback,
  AddFeedbackVariables,
} from '../../../../../gql/profile/mutations/__generated__/AddFeedback';
import {PROFILE_FEEDBACK_QUERY} from '../../../../../gql';
import {useServerErrors} from '../../../../../hooks/useErrors.hooks';
import {FormFields} from './types';

export function useFeedback(
  onAddSuccess: () => void,
  setServerError: UseFormSetError<FormFields>
) {
  const [addFeedback, {loading}] = useMutation<
    AddFeedback,
    AddFeedbackVariables
  >(PROFILE_FEEDBACK_QUERY);

  const {addServerError} = useServerErrors(
    {
      'input.text': 'message',
    },
    setServerError
  );

  const handleSend = useCallback(
    (data: FormFields) => {
      addFeedback({
        variables: {
          input: {
            text: data.message,
          },
        },
      })
        .then(() => onAddSuccess())
        .catch((e) => {
          addServerError(new ApolloError(e).graphQLErrors, setServerError);
        });
    },
    [addFeedback, addServerError, onAddSuccess, setServerError]
  );

  return {handleSend, loading};
}
