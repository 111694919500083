import React from 'react';
import {Url} from '../../../types/auxiliary.types';
import {FacebookIcon} from '../../icons/FacebookIcon';
import {TwitterIcon} from '../../icons/TwitterIcon';
import {VKIcon} from '../../icons/VKIcon';

import {Wrapper, LinkIcon} from './styles';

interface Props {
  vk?: Url;
  facebook?: Url;
  twitter?: Url;
}

export function SocialNetworks(props: Props) {
  return (
    <Wrapper>
      {props.vk != null && props.vk !== '' && (
        <LinkIcon href={props.vk}>
          <VKIcon />
        </LinkIcon>
      )}
      {props.facebook != null && props.facebook !== '' && (
        <LinkIcon href={props.facebook}>
          <FacebookIcon />
        </LinkIcon>
      )}
      {props.twitter != null && props.twitter !== '' && (
        <LinkIcon href={props.twitter}>
          <TwitterIcon />
        </LinkIcon>
      )}
    </Wrapper>
  );
}
