import React, {useState} from 'react';
import {Id} from '../../../../../types/auxiliary.types';
import {DateField} from '../../../../cards/elements/dateField/component';
import {AddButton} from '../../../../elements/AddButton/AddButton';
import {Button} from '../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../elements/Preloader/Preloader';
import {ButtonWrapper} from '../../../../elements/ProfileItem/ProfileItem.styles';
import {ArrowIcon} from '../../../../icons/ArrowIcon';
import {PencilIcon} from '../../../../icons/PencilIcon';
import {TrashIcon} from '../../../../icons/TrashIcon';
import {ButtonsWrapper} from '../../../../layouts/Header/Header.styles';
import {useActions, useDuty} from './hooks';
import {AddForm} from './modals/add/component';
import {EditForm} from './modals/edit/component';
import {
  List,
  Wrapper,
  DateValue,
  Header,
  ButtonIcon,
  Section,
  DateValueItem,
  Card,
  Group,
  IconWrapper,
  EmptyMessage,
  IconsWrapper,
} from './styles';

export default function DutyList() {
  const [modal, setModal] = useState<
    | {
        type: 'new';
      }
    | {
        type: 'edit';
        meta: {
          id: Id;
          groupId: Id;
          startAt: Date;
          endAt: Date | null;
        };
      }
    | {
        type: 'delete';
        meta: {
          id: Id;
        };
      }
    | null
  >();

  const {
    loading,
    list,
    firstDate,
    lastDate,
    refetch,
    handleTodayClick,
    handlePrevClick,
    handleNextClick,
  } = useDuty();
  const {handleDelete} = useActions(refetch);

  function handleDeleteDuty(e: Id) {
    handleDelete(e);
    setModal(null);
  }

  function renderModal() {
    if (modal == null) return null;

    switch (modal.type) {
      case 'new': {
        return (
          <AddForm
            onAddSuccess={() => {
              refetch().then(() => {
                setModal(null);
              });
            }}
            onClose={() => setModal(null)}
          />
        );
      }
      case 'delete': {
        return (
          <ModalWrapper
            title={'Подтвердите удаление'}
            onModalClose={() => setModal(null)}>
            Вы уверены что хотите удалить дежурство?
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  title="Да"
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => handleDeleteDuty(modal.meta.id)}
                />
              </ButtonWrapper>
              <Button
                title="Отмена"
                variant={'outlined'}
                color={'primary'}
                onClick={() => setModal(null)}
              />
            </ButtonsWrapper>
          </ModalWrapper>
        );
      }
      case 'edit': {
        return (
          <EditForm
            id={modal.meta.id}
            defaultValues={{
              groupId: modal.meta.groupId,
              startAt: modal.meta.startAt,
              endAt: modal.meta.endAt,
            }}
            onEditSuccess={() => {
              refetch().then(() => {
                setModal(null);
              });
            }}
            onClose={() => setModal(null)}
          />
        );
      }
    }
  }

  return (
    <Wrapper>
      {renderModal()}
      <AddButton
        buttonLabel="Добавить дежурство"
        typeButton="button"
        addItem={() => setModal({type: 'new'})}
      />
      {loading ? (
        <Preloader />
      ) : (
        <List>
          <Header>
            <DateValue dateTime={firstDate as string}>
              {new Date(firstDate as string).toLocaleDateString('ru', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}{' '}
              -{' '}
              {new Date(lastDate as string).toLocaleDateString('ru', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}
            </DateValue>
            <Button
              title="Сегодня"
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleTodayClick}
            />
            <ButtonIcon
              title="Предыдущая неделя"
              type="button"
              onClick={handlePrevClick}>
              <ArrowIcon />
            </ButtonIcon>
            <ButtonIcon
              title="Следующая неделя"
              type="button"
              onClick={handleNextClick}
              style={{transform: 'rotate(180deg)'}}>
              <ArrowIcon />
            </ButtonIcon>
          </Header>
          {list.map((l) => (
            <Section key={l.date}>
              <DateValueItem dateTime={l.date}>
                {new Date(l.date).toLocaleDateString('ru', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })}
              </DateValueItem>
              {l.list.length === 0 && <EmptyMessage>Нет дежурств</EmptyMessage>}
              {l.list.map((card) => (
                <Card key={card.id}>
                  <Group>{card.group.name}</Group>
                  <DateField date={card.dateStart} label="Начало смены" />
                  <DateField date={card.dateEnd} label="Окончание смены" />
                  <IconsWrapper>
                    <IconWrapper
                      type="button"
                      color="pink"
                      title="Редактировать"
                      onClick={() =>
                        setModal({
                          type: 'edit',
                          meta: {
                            id: card.id,
                            groupId: card.group.id,
                            startAt: card.dateStart,
                            endAt: card.dateEnd,
                          },
                        })
                      }>
                      <PencilIcon />
                    </IconWrapper>
                    <IconWrapper
                      type="button"
                      color="orange"
                      title="Удалить"
                      onClick={() =>
                        setModal({type: 'delete', meta: {id: card.id}})
                      }>
                      <TrashIcon />
                    </IconWrapper>
                  </IconsWrapper>
                </Card>
              ))}
            </Section>
          ))}
        </List>
      )}
    </Wrapper>
  );
}
