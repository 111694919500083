import React from 'react';
import {useSearchParams} from 'react-router-dom';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {BlockWrapper} from '../BlockWrapper/BlockWrapper';
import {Wrapper} from './ContactInfo.styles';
import {TextField} from '../../../../elements/TextField/TextField';
import {Control, UseFormRegister, useFormState} from 'react-hook-form';
import {FormFields} from '../../RegistrationFrom.types';
import {TextArrayFields} from '../../../../elements/TextArrayFields/TextArrayFields';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  disabledPrimaryFields?: boolean;
}

export const ContactInfo = (props: Props) => {
  const [searchParams] = useSearchParams();
  const hasToken = searchParams.has('token');

  const {errors} = useFormState({
    control: props.control,
  });

  return (
    <BlockWrapper title="Контактные данные">
      <Wrapper>
        <TextField
          register={props.register('contactInfo.primaryEmail', {
            required: 'Поле обязательно для заполнения.',
            pattern: {
              value: RegExpConstants.email,
              message: 'Некорректный формат',
            },
          })}
          disabled={hasToken || props.disabledPrimaryFields}
          error={errors.contactInfo?.primaryEmail}
          label="E-mail основной *"
          placeholder="sample@sample.com"
        />
        <TextArrayFields
          label="Дополнительные e-mails"
          name="contactInfo.secondaryEmails"
          placeholder="sample@sample.com"
          rules={{
            pattern: {
              value: RegExpConstants.email,
              message: 'Некорректный формат',
            },
          }}
          control={props.control}
        />
        <TextField
          register={props.register('contactInfo.primaryPhone', {
            required: 'Поле обязательно для заполнения.',
            pattern: {
              value: RegExpConstants.phone,
              message: 'Некорректный формат',
            },
          })}
          disabled={props.disabledPrimaryFields}
          mask="+7(999) 999 99 99"
          error={errors.contactInfo?.primaryPhone}
          label="Номер телефона (основной) *"
          placeholder="+ _ ___ ___ __ __"
        />
        <TextArrayFields
          label="Дополнительные номера телефонов"
          rules={{
            pattern: {
              value: RegExpConstants.phone,
              message: 'Некорректный формат',
            },
          }}
          name="contactInfo.secondaryPhones"
          placeholder="+ _ ___ ___ __ __"
          mask="+7(999) 999 99 99"
          control={props.control}
        />
      </Wrapper>

      <TextField
        register={props.register('contactInfo.address')}
        error={errors.contactInfo?.address}
        label="Домашний адрес"
        placeholder="г. Соленогорск, ул. Векторова д. 16"
      />
    </BlockWrapper>
  );
};
