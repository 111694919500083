import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 24px;
  align-items: center;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DateWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    margin-top: 24px;
  }
`;
