import React from 'react';
import {useController, useForm} from 'react-hook-form';
import {Button} from '../../../../../elements/Button/Button';
import DateField from '../../../../../elements/DateField/DateField';
import {ModalWrapper} from '../../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../../elements/Preloader/Preloader';
import SelectField from '../../../../../elements/SelectField/SelectField';
import {useDictionary, useSearchMemberHandler} from './AddSearchMember.hooks';
import {
  Buttons,
  DateWrapper,
  Form,
  Tooltip,
  Wrapper,
} from './AddSearchMember.style';
import {FormFields} from './AddSearchMember.types';

interface Props {
  fetchSearchMember(): void;
  onModalClose(): void;
}

export function AddSearchMember(props: Props) {
  const submit = useSearchMemberHandler(props.fetchSearchMember);
  const {loading: loadingDict, users, groups} = useDictionary();

  const {handleSubmit, control, formState, watch} = useForm<FormFields>({
    defaultValues: {
      group: '',
      user: '',
      starWorkDate: new Date(),
    },
    mode: 'onSubmit',
  });

  const {field: userField} = useController({
    name: 'user',
    control: control,
  });

  const group = watch('group');

  return (
    <ModalWrapper title="Добавить участника" onModalClose={props.onModalClose}>
      {loadingDict ? (
        <Preloader />
      ) : (
        <Form onSubmit={handleSubmit(submit)} onReset={props.onModalClose}>
          <Tooltip>Выберите статус, пользователя и время дежурства</Tooltip>
          <Wrapper>
            <SelectField
              name="group"
              placeholder="Выберите"
              disabled={formState.isSubmitting}
              options={groups}
              label="Роль или группа"
              hasFilter
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
              onCallbackChange={() => userField.onChange('')}
            />
            <SelectField
              name="user"
              placeholder="Выберите"
              disabled={formState.isSubmitting}
              options={group === '' || group == null ? [] : users[group]}
              label="Пользователь"
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
            />
            <DateWrapper>
              <DateField
                label="Начало смены"
                name="starWorkDate"
                disabled={formState.isSubmitting}
                control={control}
                maxDate={new Date()}
                rules={{
                  required: 'Поле обязательно для заполнения.',
                }}
                format="dd.MM.yyyy HH:mm"
              />
            </DateWrapper>
          </Wrapper>
          <Buttons>
            <Button
              title="Отмена"
              type="reset"
              variant={'outlined'}
              color={'primary'}
              fullWidth
              disabled={formState.isSubmitting}
            />
            <Button
              title="Добавить"
              type="submit"
              fullWidth
              disabled={formState.isSubmitting}
            />
          </Buttons>
        </Form>
      )}
    </ModalWrapper>
  );
}
