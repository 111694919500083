import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 80px;
  height: 100%;
  padding: 16px 15px;
  box-sizing: border-box;
`;

export const MenuContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface IMenuItem {
  active: boolean;
}

export const MenuItem = styled.li<IMenuItem>`
  background-color: ${(p) => (p.active ? '#FFF' : 'transparent')};
  box-shadow: ${(p) => (p.active ? '0 0 28px rgba(0, 0, 0, 0.06)' : 'none')};

  cursor: pointer;

  a {
    padding: 14px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg path {
    fill: ${(p) => (p.active ? 'var(--additional-color)' : '#A1B2C8')};
  }

  &:hover {
    background: #ffffff;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);

    svg path {
      fill: var(--additional-color);
    }
  }
`;

export const Button = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  background-color: #fff;

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 16px;
    height: 17px;

    path {
      stroke: #376092;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const BackButtonWrapper = styled.div`
  height: auto;
  padding: 1rem 0 1rem;
  border-right: 1px none #d8e2ed;
`;
