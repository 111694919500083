import {useParams} from 'react-router-dom';
import {
  LetterTemplates,
  LetterTemplates_allLetterTemplates,
} from './../../../../../../gql/queries/__generated__/LetterTemplates';
import {useQuery} from '@apollo/client';
import {
  CREATE_LETTER_MUTATION,
  LETTER_TEMPLATES_QUERY,
} from './../../../../../../gql/index';
import {
  CreateLetter,
  CreateLetterVariables,
} from './../../../../../../gql/mutations/__generated__/CreateLetter';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {createDict} from '../../../../../../utils/Dict.utils';
import {FormFields} from './letter.types';
import {toast} from 'react-toastify';

export function useCreateLetter(onModalClose?: () => void) {
  const [create] = useMutation<CreateLetter, CreateLetterVariables>(
    CREATE_LETTER_MUTATION
  );

  const params = useParams<{applicationId: string}>();
  const callId = useParams<{callId: string}>();

  const submit = useCallback(
    async (data: FormFields) => {
      return await create({
        variables: {
          input: {
            subject: data.subject,
            text: data.text,
            recipients: data.recipients,
            type: params.applicationId === undefined ? 'call' : 'issue',
            relativeId:
              params.applicationId === undefined
                ? Number(callId.callId)
                : Number(params.applicationId),
          },
        },
      })
        .then(() => {
          toast('Письмо успешно отправлено');
          onModalClose?.();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [callId.callId, create, onModalClose, params.applicationId]
  );
  return {submit};
}

export function useLetterTemplates() {
  const {data, loading} = useQuery<
    LetterTemplates,
    LetterTemplates_allLetterTemplates
  >(LETTER_TEMPLATES_QUERY);

  return {
    loading,
    templates: data == null ? [] : createDict(data.allLetterTemplates),
    fields:
      data?.allLetterTemplates == null
        ? []
        : data.allLetterTemplates.map((item) => ({
            id: item?.id || '',
            subject: item?.subject || '',
            text: item?.text || '',
            recipients: item?.recipients || '',
            name: item?.name || '',
          })),
  };
}
