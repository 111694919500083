import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {PlusIcon} from '../../icons/PlusIcon';

import {Button, Input, PlusIconWrapper, FakeInput} from './AddButton.styles';

type Props = {
  buttonLabel: string;
  disabled?: boolean;
} & (
  | {
      typeButton: 'button';
      addItem(): void;
    }
  | {
      typeButton: 'fileInput';
      accept?: string;
      formRegister: UseFormRegisterReturn;
    }
);

export function AddButton(props: Props) {
  switch (props.typeButton) {
    case 'button':
      return (
        <Button disabled={props.disabled} type="button" onClick={props.addItem}>
          <PlusIconWrapper>
            <PlusIcon />
          </PlusIconWrapper>
          {props.buttonLabel}
        </Button>
      );
    case 'fileInput':
      return (
        <>
          <Input
            disabled={props.disabled}
            type="file"
            id={props.formRegister.name}
            accept={props.accept || '*'}
            {...props.formRegister}
          />
          <FakeInput htmlFor={props.formRegister.name}>
            <PlusIconWrapper>
              <PlusIcon />
            </PlusIconWrapper>
            {props.buttonLabel}
          </FakeInput>
        </>
      );
    default:
      return null;
  }
}
