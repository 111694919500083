import styled from 'styled-components';

export const Wrapper = styled.div`
  > * {
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #d8e2ed;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const Action = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: var(--primary-color);

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  border-radius: 4px;
  margin-right: 10px;

  background-color: var(--primary-color);

  svg {
    fill: #fff;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 8px;

    &[data-element='phone'] {
      margin-top: 16px;
    }
  }
`;

export const Name = styled.p`
  margin: 18px 0 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
`;

interface ISmallText {
  showSeparator?: boolean;
}

export const SmallText = styled.p<ISmallText>`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: var(--secondary-text-color);

  time {
    &::before {
      content: ' | ';
      display: ${(p) => (p.showSeparator ? 'inline' : 'none')};
    }
  }

  b {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #333333;
  }
`;

export const Region = styled.p`
  margin: 16px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333333;
`;

export const PhoneLink = styled.a`
  font-size: 14px;
  line-height: 1.36;

  color: #333;
  text-decoration: none;

  cursor: text;
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 1.36;

  color: #376092;
  text-decoration: none;

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;

export const SocialNetworksWrapper = styled.div`
  margin-top: 16px;
`;

export const Groups = styled.div``;

interface ILabel {
  isGray?: boolean;
}

export const Label = styled.p<ILabel>`
  margin: 0 0 8px;

  display: inline;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  padding: 4px 8px;

  background-color: var(
    ${(p) =>
      p.isGray ? '--primary-border-color' : '--scecondary-additional-color'}
  );
  border-radius: 4px;

  color: #333;
`;

export const GroupLabels = styled.div`
  margin-top: 8px;

  display: flex;

  > :not(:last-child) {
    margin-right: 8px;
  }

  > {
    margin-bottom: 8px;
  }
`;

export const Qualification = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;
