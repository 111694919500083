import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px;

  flex: auto;
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
`;

export const AlgorithmItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  background: #ffffff;
  border-radius: 8px;
`;

export const AlgorithmName = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;
  margin: 0;
`;

export const AlgorithmSubtitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  color: #000;
  margin: 0;
`;

export const AlgorithmDescription = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 16px;
`;

export const ItemWrapper = styled.div`
  margin-bottom: 1rem;
`;
