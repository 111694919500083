import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px;
  justify-content: space-between;
  svg {
    path {
      fill: black;
    }
  }
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 24px;
`;
