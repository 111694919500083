import {allEquipmentStatuses} from './../../../../../../gql/profile/queries/__generated__/allEquipmentStatuses';
import {allEquipmentCategories} from './../../../../../../gql/profile/queries/__generated__/allEquipmentCategories';
import {Profile} from './../../../../../../gql/profile/queries/__generated__/Profile';
import {useCallback} from 'react';
import {
  CreateEquipment,
  CreateEquipmentVariables,
} from './../../../../../../gql/profile/mutations/__generated__/CreateEquipment';
import {
  ALL_EQUIPMENT_CATEGORY,
  ALL_EQUIPMENT_STATUS,
  PROFILE_CREATE_EQUIPMENT,
  PROFILE_QUERY,
} from './../../../../../../gql/index';
import {useMutation, useQuery} from '@apollo/client';
import {FormFields} from './types';
import {createDict} from '../../../../../../utils/Dict.utils';
import {toast} from 'react-toastify';

export function useAddEquipment(onAddSuccess: () => void) {
  const [addEquimpent, {loading}] = useMutation<
    CreateEquipment,
    CreateEquipmentVariables
  >(PROFILE_CREATE_EQUIPMENT);

  const handleSubmit = useCallback(
    (data: FormFields, setError?: (error: string) => void) => {
      addEquimpent({
        variables: {
          input: {
            regionId: Number(data.regionId),
            statusId: Number(data.statusId),
            categoryId: Number(data.categoryId),
            name: data.name,
            number: data.number,
            note: data.note,
          },
        },
      })
        .then(() => {
          toast('Оборудование успешно добавлено');
          onAddSuccess();
        })
        .catch(() => {
          toast('Ошибка добавления оборудования', {type: 'error'});
        });
    },
    [addEquimpent, onAddSuccess]
  );
  return {handleSubmit, loading};
}

export function useDict() {
  const {data, loading} = useQuery<Profile>(PROFILE_QUERY);
  const {data: category} = useQuery<allEquipmentCategories>(
    ALL_EQUIPMENT_CATEGORY
  );
  const {data: status} = useQuery<allEquipmentStatuses>(ALL_EQUIPMENT_STATUS);

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.me.regions),
    category:
      category == null ? [] : createDict(category.allEquipmentCategories),
    status: status == null ? [] : createDict(status.allEquipmentStatuses),
  };
}
