import React from 'react';
import {SvgStyled} from './Icons.styles';

export const UsersIcon = () => {
  return (
    <SvgStyled
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3468 13.0843C9.93588 12.2915 9.71866 11.4119 9.71866 10.5C9.71866 8.95152 10.3452 7.49568 11.4826 6.40072C11.9349 5.96536 12.4467 5.60791 13 5.33524C12.7922 4.92934 12.515 4.55413 12.1728 4.22474C11.3524 3.43496 10.2617 3 9.10139 3C7.94128 3 6.85036 3.43496 6.03013 4.22474C5.20969 5.01452 4.75785 6.06447 4.75785 7.18142C4.75785 8.29818 5.20969 9.34833 6.03013 10.1379C6.08465 10.1906 6.14084 10.2414 6.19787 10.2909C5.85988 10.4225 5.53091 10.5765 5.21452 10.7539C4.07475 11.3927 3.11676 12.3078 2.44393 13.4002C1.86042 14.3478 1.85161 15.4876 2.42066 16.4489C2.99536 17.4201 4.03051 18 5.18936 18H6.96631C7.0095 17.2732 7.23364 16.5538 7.63956 15.8947C8.32372 14.7838 9.25088 13.8234 10.3468 13.0843Z" />
      <path d="M19.0579 13.7537C18.7683 13.5765 18.4672 13.4225 18.1578 13.2909C18.21 13.2414 18.2612 13.1906 18.3113 13.1379C19.0623 12.3481 19.4759 11.2982 19.4759 10.1812C19.4759 9.06447 19.0623 8.01432 18.3113 7.22474C17.5606 6.43496 16.562 6 15.5002 6C14.4381 6 13.4396 6.43496 12.6888 7.22474C11.9379 8.01452 11.5243 9.06447 11.5243 10.1812C11.5243 11.2982 11.9379 12.3483 12.6888 13.1379C12.7387 13.1906 12.7902 13.2414 12.8424 13.2909C12.533 13.4225 12.2319 13.5765 11.9423 13.7539C10.899 14.3927 10.0222 15.3078 9.40633 16.4002C8.87224 17.3478 8.86418 18.4876 9.38503 19.4489C9.91107 20.4201 10.8585 21 11.9193 21H19.0809C20.1416 21 21.0889 20.4201 21.6151 19.4489C22.1358 18.4876 22.1277 17.3478 21.5936 16.4002C20.978 15.3078 20.1011 14.3927 19.0579 13.7537Z" />
    </SvgStyled>
  );
};
