import React from 'react';
import {SvgStyled} from './Icons.styles';

export const UserIcon = () => {
  return (
    <SvgStyled
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3789 12.3044C16.0225 12.0918 15.6519 11.907 15.2711 11.7491C15.3354 11.6897 15.3985 11.6287 15.4601 11.5655C16.3844 10.6178 16.8934 9.35781 16.8934 8.01747C16.8934 6.67736 16.3844 5.41718 15.4601 4.46968C14.5361 3.52195 13.3071 3 12.0002 3C10.6931 3 9.46412 3.52195 8.5401 4.46968C7.61584 5.41742 7.10683 6.67736 7.10683 8.01747C7.10683 9.35781 7.61584 10.618 8.5401 11.5655C8.60151 11.6287 8.66481 11.6897 8.72906 11.7491C8.3483 11.907 7.9777 12.0918 7.62127 12.3047C6.33728 13.0713 5.25808 14.1694 4.5001 15.4802C3.84275 16.6173 3.83283 17.9851 4.47389 19.1387C5.12131 20.3042 6.28744 21 7.59293 21H16.4073C17.7128 21 18.8787 20.3042 19.5263 19.1387C20.1671 17.9851 20.1572 16.6173 19.4999 15.4802C18.7421 14.1694 17.6629 13.0713 16.3789 12.3044Z" />
    </SvgStyled>
  );
};
