import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const Description = styled.div`
  width: 336px;
  background-color: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 0 8px 8px 0;

  p {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    margin: 1rem 0 0 1rem;
  }
`;

export const ResourcesIconWrapper = styled.div`
  background-color: #ebe89c;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 17px;
  }
`;

export const TypographyIconWrapper = styled.div`
  background-color: #f4afaf;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 17px;
  }
`;

export const InstitutionsIconWrapper = styled.div`
  background-color: #eac76e;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 17px;
  }
`;

export const AlgorithmIconWrapper = styled.div`
  background-color: #c8eb9c;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 20px;
  }
`;

export const QuestionnairesIconWrapper = styled.div`
  background-color: #9cebca;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 17px;
  }
`;

export const RegionalAgreeIconWrapper = styled.div`
  background-color: #eb9cc2;
  border-radius: 8px 0 0 8px;
  text-align: center;
  width: 88px;
  height: 88px;
  svg {
    padding-top: 17px;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  margin-right: 1rem;
  cursor: pointer;
`;

export const BlockWithLink = styled(NavLink)`
  text-decoration: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  text-decoration: none;
  margin-bottom: 1rem;
  @media screen and (max-width: 800px) {
    flex-direction: column;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;
