import React from 'react';
import {SvgStyled} from './Icons.styles';

export const FileIcon = () => {
  return (
    <SvgStyled
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 3.25V10C14.6666 10.3978 14.5086 10.7794 14.2273 11.0607C13.946 11.342 13.5645 11.5 13.1666 11.5H2.16663C1.7688 11.5 1.38727 11.342 1.10597 11.0607C0.824661 10.7794 0.666626 10.3978 0.666626 10V2C0.666626 1.60218 0.824661 1.22064 1.10597 0.93934C1.38727 0.658035 1.7688 0.5 2.16663 0.5H4.66663C5.05456 0.500249 5.42731 0.650783 5.70663 0.92L6.56663 1.75H13.1666C13.5645 1.75 13.946 1.90804 14.2273 2.18934C14.5086 2.47064 14.6666 2.85218 14.6666 3.25Z"
        fill="#E5F1FF"
      />
    </SvgStyled>
  );
};
