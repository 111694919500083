import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  padding-bottom: 3.75rem;
  text-align: center;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 8.875rem;
`;

export const Title = styled.h1`
  margin: 1.1rem 0 0;
  color: #2b3230;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
`;

export const Subtitle = styled.p`
  margin: 0.75rem 0 0;
  color: #2b3230;
  font-size: 1rem;
  line-height: 1.5;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
`;
