import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {GROUP_TASKS_QUERY} from '../../../../../gql';
import {
  TaskGroup,
  TaskGroupVariables,
} from '../../../../../gql/queries/group/__generated__/TaskGroup';

export function useGroupTask() {
  const params = useParams<{groupId?: string}>();
  const {data, loading} = useQuery<TaskGroup, TaskGroupVariables>(
    GROUP_TASKS_QUERY,
    {
      variables: {
        id: (params.groupId || '').toString(),
      },
      skip: params.groupId == null || params.groupId === '',
    }
  );

  console.log(data);

  return {
    loading,
    task:
      data?.group.tasks == null
        ? []
        : data.group.tasks.map((task) => ({
            id: task?.id || '',
            isUrgent: task?.is_urgent || false,
            issue: task?.issue?.id,
            call: task?.call?.id,
            description: task?.description || '',
            region: task?.region?.name || '',
            createDate:
              task?.created_at == null ? null : new Date(task?.created_at),
            avatarResponsible: undefined,
            responsible: task?.responsible?.profile.full_name || '',
            status: task?.status?.name || '',
            statusColor: task?.status?.color || 'transparent',
            result: task?.result || undefined,
          })),
  };
}
