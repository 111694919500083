import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;

  flex: auto;
  overflow-y: hidden;
`;

export const BackButtonWrapper = styled.div`
  height: auto;
  padding: 16px 15px 16px;
  border-right: 1px solid #d8e2ed;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Button = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  background-color: #fff;

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 16px;
    height: 17px;

    path {
      stroke: #376092;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const OutletWrapper = styled.div`
  max-width: 1448px;
  margin: 0 auto;
  
  flex: auto;
  overflow: auto;

  display: flex;
  flex-direction: column;
`;
