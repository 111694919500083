import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import {Id, Url} from '../../../../../../types/auxiliary.types';
import MissingColumn from '../../../../../cards/elements/missingColumn/component';
import User from '../../../../../elements/user/component';
import MainInfoCard from '../../../../../cards/elements/mainInfo/component';
import {Wrapper} from './styles';

interface Props {
  id: Id;
  number: number;
  urgent?: 1 | 2 | 3 | 4;
  region: string;
  author: {
    avatar?: Url;
    fullName: string;
  };
  area?: string;
  missing: {
    id: Id;
    status?: {
      name: string;
      color: CSSProperties['backgroundColor'];
    };
    fullName: string;
    gender: 'w' | 'm';
    birthday?: string;
  }[];
}

export default function Card(props: Props) {
  const navigate = useNavigate();

  return (
    <Wrapper onClick={() => navigate(`/callRequests/${props.id}`)}>
      <MainInfoCard number={props.number} urgent={props.urgent} />

      <MissingColumn missing={props.missing} area={props.area} />
      <User
        avatar={props.author.avatar}
        fullName={props.author.fullName}
        label="Автор"
      />
    </Wrapper>
  );
}
