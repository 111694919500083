import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.div`
  padding: 21px 24px;

  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const DateValue = styled.time`
  margin: 0 16px 0 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #000;
`;

export const ButtonIcon = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 8px;
  background-color: #a1b2c8;

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 12px;
    height: 13px;

    path {
      stroke: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const DateValueItem = styled.time`
  margin: 0;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: #333;
`;

export const Card = styled.article`
  margin: 16px 0 0;

  padding: 16px;
  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > :not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 1000px) {
    align-items: start;
    flex-direction: column;

    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const EmptyMessage = styled.p`
  margin: 16px 0 0;

  font-size: 14px;
  line-height: 19px;

  color: #7d7d7d;
`;

export const Group = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;

type ColorType = 'orange' | 'pink';

const config: Record<ColorType, string> = {
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IIconWrapper {
  color: ColorType;
}

export const IconWrapper = styled.button<IIconWrapper>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;

  border: none;
  cursor: pointer;
  background-color: ${(p) => config[p.color]};

  svg {
    width: 12px;
    height: 12px;

    fill: #fff;
  }

  &:not(&:disabled):hover {
    // not found in design
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;
