import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {APPLICATION_CREATE_REPORT_MUTATION} from '../../../../../../gql';
import {toast} from 'react-toastify';

import {
  CreateReport,
  CreateReportVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateReport';

import {FormFields} from '../../../modals/Report/Report.types';

export const useReportsHandler = (onAddSuccess?: () => void) => {
  const [loading, setLoading] = useState(false);
  const params = useParams<{applicationId?: string}>();

  const [createReportMutation] = useMutation<
    CreateReport,
    CreateReportVariables
  >(APPLICATION_CREATE_REPORT_MUTATION);

  const submit = useCallback(
    async (data: FormFields) => {
      await createReportMutation({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            description: data.description,
          },
        },
      })
        .then(() => {
          onAddSuccess?.();
          toast('Отчет успешно добавлен!');
        })
        .catch((e) => toast(e, {type: 'error'}));
    },
    [createReportMutation, onAddSuccess, params.applicationId]
  );

  return {submit, loading};
};
