import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
`;

export const ContentWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;
