import {useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {LocationType} from '../../../constants/location.constants';
import {APPLICATION_LIST_QUERY} from '../../../gql';
import {
  ListPagination,
  ListPaginationVariables,
} from '../../../gql/application/queries/__generated__/ListPagination';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../hooks/useUrl.hooks';
import {createDate_DTO} from '../../../utils/Date.utils';
import {getFullName_dto} from '../../../utils/String.utils';
import {Gender} from './components/Card/Card.types';
import {FormFields} from './components/Filter/Filter.types';

export function useApplicationList() {
  const currSearchParams = useCurrSearch<
    FormFields & {page: string | null}
  >() as FormFields & {page: string | null};
  const [searchParams] = useSearchParams();

  const {data, loading} = useQuery<ListPagination, ListPaginationVariables>(
    APPLICATION_LIST_QUERY,
    {
      variables: {
        ...(currSearchParams.search != null && {
          text: currSearchParams.search,
        }),
        ...(searchParams.has('statuses') && {
          status: searchParams
            .getAll('statuses')
            .map((s) => Number(s.split('.')[1])),
        }),
        ...(searchParams.has('subStatuses') && {
          subStatus: searchParams
            .getAll('subStatuses')
            .map((s) => Number(s.split('.')[2])),
        }),
        ...(currSearchParams.region != null && {
          region: Number(currSearchParams.region),
        }),
        ...(currSearchParams.location != null && {
          location: currSearchParams.location as string,
        }),
        ...(currSearchParams.dateFrom != null && {
          dateFrom: createDate_DTO(currSearchParams.dateFrom),
        }),
        ...(currSearchParams.dateTo != null && {
          dateTo: createDate_DTO(currSearchParams.dateTo),
        }),
        ...(currSearchParams.missingName != null && {
          missingName: currSearchParams.missingName,
        }),
        ...(currSearchParams.gender != null && {
          gender: currSearchParams.gender === 'm' ? 'male' : 'female',
        }),
        ...(currSearchParams.ageFrom != null && {
          ageFrom: Number(currSearchParams.ageFrom),
        }),
        ...(currSearchParams.ageTo != null && {
          ageTo: Number(currSearchParams.ageTo),
        }),
        ...(currSearchParams.missingNumber != null && {
          missingNumber: currSearchParams.missingNumber,
        }),
        ...(currSearchParams.applicantNumber != null && {
          applicantNumber: currSearchParams.applicantNumber,
        }),
        ...(currSearchParams.isUrgent && {isUrgent: true}),
        ...(currSearchParams.isEducational && {isEducational: true}),
        ...(currSearchParams.isReverseLookup && {isReverseLookup: true}),
        ...(currSearchParams.isForestInTouch && {isForestInTouch: true}),
        page: Number(currSearchParams.page),
      },
      skip: currSearchParams.page == null || currSearchParams.page === '',
    }
  );

  return {
    list:
      data?.issues == null
        ? []
        : data.issues.data.map((i) => ({
            number: i.id,
            isUrgently: i.is_emergency ?? undefined,
            location: (i.searchLocation?.key ?? undefined) as
              | LocationType
              | undefined,
            area: i.region?.name || '',
            isTraining: i.is_training ?? undefined,
            parentId: i.parentIssue?.id,
            lossDate: i.date_of_loss == null ? null : new Date(i.date_of_loss),
            creationDate: i.created_at == null ? null : new Date(i.created_at),
            missing:
              i.missing == null
                ? []
                : i.missing.map((m, ind) => ({
                    id: `fake_id_${ind}`,
                    status: {
                      name: m?.status?.name || '',
                      color: m?.status?.color || '',
                    },
                    fullName:
                      m == null
                        ? ''
                        : getFullName_dto({
                            first_name: m.first_name,
                            last_name: m.last_name,
                            second_name: m.second_name,
                          }),
                    gender: m?.sex === 'male' ? 'm' : ('w' as Gender),
                    birthday:
                      m?.birthday_string == null
                        ? undefined
                        : m.birthday_string,
                  })),
            forestInTouch: Boolean(i.is_forest_online),
            // ringingNumber: {
            //   count: 0,
            // },
            // tasksNumber: {
            //   count: 100,
            // },
            // institutionCallsNumber: {
            //   count: 20,
            //   new: 1,
            // },
            lettersServicesNumber:
              i.reports == null
                ? undefined
                : {
                    count: i.reports.length,
                  },
            // commentNumber: 100,
          })),
    paginatorInfo: data?.issues?.paginatorInfo,
    loading,
  };
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    page: Number(searchParams.get('page')),
    handleSwitchPage,
  };
}
