import styled, {css} from 'styled-components';
import {BadgeProps} from './Badge';

export const Counter = styled.div`
  margin-left: 0.25rem;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0.25rem;
  padding: 0 0.125rem 0 0.125rem;
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
`;

export const BadgeStyled = styled.div<Partial<BadgeProps>>`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: 700;
  line-height: 1.125rem;
  padding: 0.25rem 0.5rem;
  border: 0.125rem solid;
  width: fit-content;

  ${(props) =>
    props.counter !== undefined &&
    css`
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    `}

  ${(props) => css`
    color: #fff;
    background-color: ${props.color};
    border-color: ${props.color};

    &:hover {
      color: #fff;
      background-color: ${props.color};
      border: 0.125rem solid ${props.color};
    }
  `}

  ${(props) =>
    props.color === 'dark_blue' &&
    css`
      color: #fff;
      background-color: #2c507c;
      border-color: #2c507c;

      cursor: default;

      &:hover {
        color: #fff;
        background-color: #2c507c;
        border-color: #2c507c;
      }
    `}
  
  ${(props) =>
    props.color === 'secondary' &&
    css`
      color: var(--secondary-color-contrast);
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      &:hover {
        color: var(--secondary-color-contrast);
        background-color: var(--secondary-color-accent);
        border-color: var(--secondary-color-accent);
      }
    `}
  
  ${(props) =>
    props.color === 'optional' &&
    css`
      color: #376092;
      background-color: #e5f1ff;
      border-radius: 4px;
      font-size: 12px;
      margin: 0 8px 8px 0;
      padding: 4px 8px;
      font-weight: 600;
      border: 0.125rem solid #e5f1ff;

      &:hover {
        color: #376092;
        background-color: #e5f1ff;
        border: 0.125rem solid #e5f1ff;
      }
    `}

  ${(props) =>
    props.color === 'additional' &&
    css`
      color: var(--additional-color-contrast);
      background-color: var(--additional-color);
      border-color: var(--additional-color);

      &:hover {
        color: var(--secondary-color-contrast);
        background-color: var(--additional-color-accent);
        border-color: var(--additional-color-accent);
      }
    `}

  ${(props) =>
    props.color === 'yellow' &&
    css`
      color: var(--additional-color-contrast);
      background-color: var(--yellow-color);
      border-color: var(--yellow-color);

      &:hover {
        color: var(--secondary-color-contrast);
        background-color: var(--yellow-color-accent);
        border: 0.125rem solid var(--yellow-color-accent);
      }
    `}
`;
