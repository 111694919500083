import React from 'react';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import Preloader from '../../elements/Preloader/Preloader';
import {Tabs} from '../../elements/Tabs/Tabs';
import {Main} from './components/Main/Main';
import {ParticipantList} from './components/ParticipantList/ParticipantList';
import Photo from './components/Photo/components';
import Tasks from './components/Tasks/component';
import Videos from './components/Videos/component';
import File from './components/Files/component';

import {useGroup} from './GroupScreen.hooks';
import {Container, Primary, Title, Wrapper} from './GroupScreen.styles';

export function GroupScreen() {
  useDocumentTitle('Группа');

  const {loading, data, activeTab, handleSwitchTab} = useGroup();

  function renderForm() {
    if (!data?.group) return null;

    switch (activeTab) {
      case 'about': {
        return <Main />;
      }
      case 'users': {
        return <ParticipantList />;
      }
      case 'videos': {
        return <Videos />;
      }
      case 'photos': {
        return <Photo />;
      }
      case 'files': {
        return <File />;
      }
      case 'tasks': {
        return <Tasks />;
      }
      default:
        return <i>Раздел находится в разработке</i>;
    }
  }

  if (loading) {
    return <Preloader />;
  }

  if (!data?.group) {
    return <div>'Not found'</div>;
  }

  return (
    <Wrapper>
      <Title>{data.group.full_name}</Title>
      <Tabs
        activeTab={activeTab || ''}
        items={[
          {
            id: 'about',
            label: 'О группе',
          },
          {
            id: 'users',
            label: 'Участники',
          },
          {
            id: 'tasks',
            label: 'Задачи',
          },
          {
            id: 'photos',
            label: 'Фото',
          },
          {
            id: 'videos',
            label: 'Видео',
          },
          {
            id: 'files',
            label: 'Файлы',
          },
        ]}
        onSwitchTab={handleSwitchTab}
      />
      <Container>
        <Primary>{renderForm()}</Primary>
      </Container>
    </Wrapper>
  );
}
