import {useCallback, useEffect, useRef, useState} from 'react';

export function useSizes() {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (ref.current == null) return;

    setHeight(ref.current.scrollHeight);
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {ref, height};
}
