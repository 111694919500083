import {
  DeleteTechnic,
  DeleteTechnicVariables,
} from './../../../../../../gql/profile/mutations/__generated__/DeleteTechnic';
import {DELETE_TECHNIC_ITEM} from './../../../../../../gql/index';
import {useMutation} from '@apollo/client';
import {Id} from '../../../../../../types/auxiliary.types';
import {toast} from 'react-toastify';

export function useActions(onDeleteSuccess?: () => void) {
  const [deleteTechnic, {loading}] = useMutation<
    DeleteTechnic,
    DeleteTechnicVariables
  >(DELETE_TECHNIC_ITEM);

  function handleDelete(id: Id) {
    deleteTechnic({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        onDeleteSuccess?.();
        toast('Техника успешно удалена!');
      })
      .catch(() => toast('Ошибка удаления техники', {type: 'error'}));
  }
  return {
    loading,
    handleDelete,
  };
}
