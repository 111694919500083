import {client} from '../../../../../../gql-client';

import {
  ListPagination,
  ListPaginationVariables,
} from '../../../../../../gql/application/queries/__generated__/ListPagination';
import {
  APPLICATION_LIST_QUERY,
  USEFUL_INSTITUTIONS_QUERY,
} from '../../../../../../gql';

import {
  List,
  ListVariables,
} from '../../../../../../gql/call/queries/__generated__/List';
import {CALLS_QUERY} from '../../../../../../gql';
import {
  InstitutionList,
  InstitutionListVariables,
} from '../../../../../../gql/helpfulInfo/queries/__generated__/InstitutionList';

type OptionType = {id: any};
type OptionsType = Array<OptionType>;

export const useIssueOptions = (
  term: string,
  callback: (options: OptionsType) => void,
  applicationId?: any
): void => {
  client
    .query<ListPagination, ListPaginationVariables>({
      query: APPLICATION_LIST_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        text: term,
      },
    })
    .then((resp) => {
      if (applicationId === null) {
        const data = resp.data.issues?.data.map((a) => ({
          id: a.id,
          value: a.id,
          label: `Заявка #${a.id}: ${a.missing?.map(
            (c) => c?.first_name
          )} ${a.missing?.map((c) => c?.last_name)}`,
        }));
        callback(data || []);
      } else {
        const data = resp.data.issues?.data.map((c) => ({
          id: applicationId,
          value: applicationId,
          label: `Заявка #${applicationId}`,
        }));
        callback(data || []);
      }
    });
};

export const useCallOptions = (
  term: string,
  callback: (options: OptionsType) => void,
  callId?: any
): void => {
  client
    .query<List, ListVariables>({
      query: CALLS_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        text: term,
      },
    })
    .then((resp) => {
      if (callId === null) {
        const data = resp.data.calls?.data.map((a) => ({
          id: a.id,
          value: a.id,
          label: `Звонок #${a.id} - ${a.contact}`,
        }));
        callback(data || []);
      } else {
        const data = resp.data.calls?.data.map((a) => ({
          id: callId,
          value: callId,
          label: `Звонок #${callId}`,
        }));
        callback(data || []);
      }
    });
};

export const useInstitutionsOptions = (
  term: string,
  callback: (options: OptionsType) => void
): void => {
  client
    .query<InstitutionList, InstitutionListVariables>({
      query: USEFUL_INSTITUTIONS_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        nameAddress: term,
      },
    })
    .then((resp) => {
      const data = resp.data.institutions?.data.map((a) => ({
        id: a.id,
        value: a.id,
        label: a.name,
      }));
      callback(data || []);
    });
};
