import {LocationType} from '../../../constants/location.constants';
import {ForestIcon} from '../../icons/locations/ForestIcon';
import {TownForestIcon} from '../../icons/locations/TownForestIcon';
import {TownIcon} from '../../icons/locations/TownIcon';
import {WaterForestIcon} from '../../icons/locations/WaterForestIcon';
import {WaterIcon} from '../../icons/locations/WaterIcon';
import {IconWrapper} from './LocationIcon.styles';

export interface Props {
  location?: LocationType;
}

export function LocationIcon(props: Props) {
  switch (props.location) {
    case 'forest': {
      return (
        <IconWrapper title="Лес">
          <ForestIcon />
        </IconWrapper>
      );
    }
    case 'forest_city': {
      return (
        <IconWrapper title="Лес + Город">
          <TownForestIcon />
        </IconWrapper>
      );
    }
    case 'forest_water': {
      return (
        <IconWrapper title="Вода + Лес">
          <WaterForestIcon />
        </IconWrapper>
      );
    }
    case 'city': {
      return (
        <IconWrapper title="Город">
          <TownIcon />
        </IconWrapper>
      );
    }
    case 'water': {
      return (
        <IconWrapper title="Вода">
          <WaterIcon />
        </IconWrapper>
      );
    }
    default:
      return null;
  }
}
