import {format} from 'date-fns';

// return format yyyy-MM-dd
export function createDate_DTO(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

// return format yyyy

export function createDateYear_DTO(date: Date) {
  return format(date, 'yyyy');
}

// return format yyyy-MM-dd HH:mm
export function createDateTime_DTO(dateTime: Date) {
  return format(dateTime, 'yyyy-MM-dd HH:mm');
}

//https://ru.stackoverflow.com/questions/576478/javascript-%D0%92%D1%8B%D1%87%D0%B8%D1%81%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B2%D0%BE%D0%B7%D1%80%D0%B0%D1%81%D1%82%D0%B0-%D0%BF%D0%BE-%D0%B4%D0%B0%D1%82%D0%B5-%D1%80%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F
export function getAgeFromDate(birthday: Date) {
  const now = new Date(); //Текущя дата
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); //Текущя дата без времени
  const dobnow = new Date(
    today.getFullYear(),
    birthday.getMonth(),
    birthday.getDate()
  ); //ДР в текущем году

  //Возраст = текущий год - год рождения
  let age = today.getFullYear() - birthday.getFullYear();
  //Если ДР в этом году ещё предстоит, то вычитаем из age один год
  if (today < dobnow) {
    age = age - 1;
  }

  return age;
}
