import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  margin-bottom: 34px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const CheckboxLabel = styled.span`
  margin-left: 1rem;
`;
