import React from 'react';
import {Id, Url} from '../../../../../../types/auxiliary.types';
import {BlockSidebarWrapper} from '../../../../../elements/BlockSidebarWrapper/BlockSidebarWrapper';
import Fields, {
  Props as FieldsProps,
} from '../../../../../elements/sidebarInfo/generalInfo/component';
import Header from '../../../../../elements/sidebarInfo/header/component';
import User from '../../../../../elements/user/component';
import {FieldsBlock} from '../../../../../elements/sidebarInfo/FieldsBlock/FieldsBlock';
import {HeaderWrapper, Section, Wrapper} from './styles';

interface Props {
  number: Id;
  urgent?: 1 | 2 | 3 | 4;
  missing?: string;
  createDate?: Date;
  lossDate?: Date;
  applicationId: Id;
  author?: {
    avatar?: Url;
    fullName: string;
  };
  regionFields: any;
}

export default function Sidebar(props: Props) {
  // const {
  //   handleDeleteCallRequest,
  //   actionLoading,
  // } = useActions()

  if (!props.number) {
    return null;
  }

  return (
    <Wrapper>
      {/*FIXME: no backend*/}
      {/*<ActionBlock*/}
      {/*  title="Действия с запросом на прозвон"*/}
      {/*  onDelete={() => actionLoading ? undefined : handleDeleteCallRequest}*/}
      {/*/>*/}
      <BlockSidebarWrapper shouldHideTitleBigSize title="Подробная информация">
        <Section>
          <HeaderWrapper>
            <Header number={Number(props.number)} urgent={props.urgent} />
          </HeaderWrapper>
          <Fields
            list={
              [
                {
                  type: 'default',
                  label: 'Пропавшие',
                  value: props.missing,
                },
                {
                  type: 'datetime',
                  label: 'Запрос создан',
                  value: props.createDate,
                },
                {
                  type: 'datetime',
                  label: 'Время пропажи',
                  value: props.lossDate,
                },
                {
                  type: 'links',
                  label: 'Заявка',
                  values: [
                    {
                      href: `/applications/${props.applicationId}`,
                      text: props.applicationId || 'Нет привязанного звонка',
                    },
                  ],
                },
              ].filter(
                (f) => (f.value != null && f.value !== '') || f.values != null
              ) as FieldsProps['list']
            }
          />
        </Section>
        {props.author != null && (
          <Section>
            <User
              avatar={props.author.avatar}
              fullName={props.author.fullName}
              label="Автор"
              hasLabelPostfix
            />
          </Section>
        )}
        {props.regionFields.length !== 0 && (
          <Section>
            <FieldsBlock
              title="Регионы и адрес пропажи"
              fields={props.regionFields}
            />
          </Section>
        )}
      </BlockSidebarWrapper>
    </Wrapper>
  );
}
