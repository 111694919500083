import React from 'react';
import {SvgStyled} from './Icons.styles';

export const MapMarkerIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C4.64 0 1.90625 2.73375 1.90625 6.09375C1.90625 7.42344 2.32625 8.68687 3.12188 9.74687L7.62437 15.811C7.63562 15.8257 7.65281 15.8313 7.66531 15.8444C7.89031 16.0853 8.215 16.0247 8.37531 15.811C9.67188 14.081 12.0484 10.85 12.9622 9.63125C12.9622 9.63125 12.9625 9.63031 12.9628 9.62969L12.9684 9.62219C13.7047 8.58781 14.0938 7.36781 14.0938 6.09375C14.0938 2.73375 11.36 0 8 0ZM8 9.38125C6.19094 9.38125 4.7125 7.90281 4.7125 6.09375C4.7125 4.28469 6.19094 2.80625 8 2.80625C9.80906 2.80625 11.2875 4.28469 11.2875 6.09375C11.2875 7.90281 9.80906 9.38125 8 9.38125Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
