import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  margin: 0;
  
  font-weight: 400;
  font-size: 257px;
  line-height: 1.36;

  color: transparent;
  -webkit-text-stroke: 2px #FF6600;

  text-shadow: rgba(255, 102, 0, 0.2) 4px 8px;
`;

export const Message = styled.h2`
  margin: 0;
  
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333;
`;
