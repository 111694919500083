import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {
  Wrapper,
  HeaderWrapper,
  Footer,
  LeftSide,
  RightSide,
  Title,
  Subtitle,
  Address,
  TimeBlock,
  CountBlock,
  Time,
  MaxInColor,
} from './TypographyItem.styles';

interface Props {
  id: Id;
  region?: string | null;
  name?: string | null;
  address?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  maxInColor?: number | null;
  printMaps?: boolean | null;
  note?: string | null;
}

const TypographyItem = (props: Props) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <LeftSide>
          <Title>{props.name}</Title>
          <Subtitle> {props.region}</Subtitle>
          <Address>{props.address}</Address>
        </LeftSide>
        <RightSide>
          <TimeBlock>
            <Subtitle>Время работы:</Subtitle>
            <Time>
              {props.startTime} - {props.endTime}
            </Time>
          </TimeBlock>
          <CountBlock>
            <Subtitle>Максимальное кол-во в цвете:</Subtitle>
            <MaxInColor>{props.maxInColor}</MaxInColor>
          </CountBlock>
          <TimeBlock>
            <Subtitle>Печать карт:</Subtitle>
            <Time>{props.printMaps ? 'Да' : 'Нет'}</Time>
          </TimeBlock>
        </RightSide>
      </HeaderWrapper>
      <Footer></Footer>
    </Wrapper>
  );
};

export default TypographyItem;
