import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {GROUP_DESCRIPTION_QUERY} from '../../../../../gql';
import {
  GroupDescription,
  GroupDescriptionVariables,
} from '../../../../../gql/queries/group/__generated__/GroupDescription';

export function useGroup() {
  const params = useParams<{groupId?: string}>();

  const {data, loading} = useQuery<GroupDescription, GroupDescriptionVariables>(
    GROUP_DESCRIPTION_QUERY,
    {
      variables: {
        id: (params.groupId || '').toString(),
      },
      skip: params.groupId == null || params.groupId === '',
    }
  );

  return {
    data,
    loading,
  };
}
