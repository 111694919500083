import React from 'react';
import {FieldError} from 'react-hook-form/dist/types/errors';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import {Input} from '../Input/Input';
import {Label} from '../Label/Label';
import {TextFieldContainer} from './TextField.styles';
import {Textarea} from '../Textarea/Textarea';

type TextFieldProps = {
  label?: string;
  error?: FieldError | string;
  register?: UseFormRegisterReturn;
  placeholder?: string;
  value?: string;
  rows?: number;
  isTextarea?: boolean;
  type?: 'password' | 'text';
  disabled?: boolean;
  mask?: string | Array<string | RegExp>;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
};

export function TextField(props: TextFieldProps) {
  const {label, error, ...rest} = props;
  const isInvalid = !!error;
  return (
    <TextFieldContainer data-element="text-field">
      <Label label={label} isInvalid={isInvalid}>
        {props.isTextarea ? <Textarea {...rest} /> : <Input {...rest} />}
        {error != null && (
          <ErrorMessage
            message={typeof error === 'string' ? error : error?.message}
          />
        )}
      </Label>
    </TextFieldContainer>
  );
}
