import {useEffect} from 'react';

export function useEnterForm(callback: () => void) {
  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (event.ctrlKey && event.keyCode === 13) {
        callback();
      }
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [callback]);
}
