import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  padding: 16px;
  box-sizing: border-box;

  background: #ffffff;
  border-radius: 4px;
`;

export const PersonInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Data = styled.div`
  margin-left: 16px;
`;

interface IName {
  thin?: boolean;
}

export const Name = styled.p<IName>`
  margin: 0;

  font-size: 16px;
  line-height: 1.37;

  color: var(${(p) => (p.thin ? '--secondary-text-color' : '--text-color')});
`;

export const ContactsWrapper = styled.div`
  display: flex;
   flex-direction: column; 

  margin-top: 24px;
  margin-bottom: 24px;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 1.36;

  color: #376092;
  text-decoration: none;

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;

export const PhoneLink = styled.a`
  font-size: 14px;
  line-height: 1.36;

  color: #333;
  text-decoration: none;
  display: block;

  cursor: text;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

export const LinkIcon = styled.a`
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #010002;
    }
  }

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;
