import React from 'react';
import {Outlet} from 'react-router-dom';
import {Copyright} from '../../elements/Copyright/Copyright';
import {Slogan} from '../../elements/Slogan/Slogan';
import {
  Container,
  Main,
  Content,
  Aside,
  LogoStyled,
  Image,
} from './SignInLayout.style';
import logo from '../../../assets/img/croc-logo.png';

export const SignInLayout = () => {
  return (
    <Container>
      <Main>
        <LogoStyled variant={'big'} />
        <Content>
          <Outlet />
          <Copyright />
          <Image src={logo} alt="" />
        </Content>
      </Main>
      <Aside>
        <Slogan />
      </Aside>
    </Container>
  );
};
