import {useMutation} from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {CALL_TRANSFER_MUTATION} from '../../../../../../gql';
import {TransferCall, TransferCallVariables} from '../../../../../../gql/call/mutations/__generated__/TransferCall';
import {toast} from 'react-toastify';
import {FormFields} from './types';

export function useActions() {
  const params = useParams<{callId: string}>();

  const [transferCall] = useMutation<
    TransferCall,
    TransferCallVariables
    >(CALL_TRANSFER_MUTATION)

  const navigate = useNavigate()

  function handleTransferCall(data: FormFields) {
    return transferCall({
      variables: {
        input: {
          callId: Number(params.callId),
          teamId: Number(data.groupId),
          description: data.description,
        }
      }
    }).then(() => {
      toast('Звонок успешно передан');
      navigate('/calls')
    }).catch((e) => {
      toast(e.message, {type: 'error'});
      navigate('/calls')
    })
  }

  return  {
    handleTransferCall
  }
}
