import React from 'react';

import CreateRinging from '../../../../../../elements/CreateRinging/CreateRinging';
import { useCreateRinging } from '../../../ringing.hook';


interface Props {
  onAddSuccess?(): void;
  onModalClose(): void;
}

export default function AddRinging(props: Props) {
  const {submit, loading} = useCreateRinging(
    props.onAddSuccess,
    props.onModalClose
  );
  return (
    <CreateRinging
      title={'Добавить прозвон'}
      labelSubmit={'Добавить'}
      submit={submit}
      loading={loading}
      onAddSuccess={props.onAddSuccess}
      onModalClose={props.onModalClose}
    />
  );
}
