import React from 'react';
import {LocationType} from '../../../../constants/location.constants';
import {DoubleUrgentlyIcon} from '../../../icons/DoubleUrgentlyIcon';
import {EmailIcon} from '../../../icons/EmailIcon';
import {ForestInTouchIcon} from '../../../icons/ForestInTouchIcon';
import {RejectIcon} from '../../../icons/RejectIcon';
import {SMSIcon} from '../../../icons/SMSIcon';
import {SuccessIcon} from '../../../icons/SuccessIcon';
import {UrgentlyIcon} from '../../../icons/UrgentlyIcon';
import {LocationIcon} from '../../LocationIcon/LocationIcon';

import {
  IconsWrapper,
  NumberField,
  ForestInTouchIconWrapper,
  UrgentlyIconWrapper,
  SuccessIconWrapper,
  RejectIconWrapper,
  MessageIconWrapper,
  Wrapper,
} from './styles';

interface Props {
  number: number | string;
  isUrgently?: boolean;
  urgent?: 1 | 2 | 3 | 4 | number;
  isForestInTouch?: boolean;
  location?: LocationType;
  isSmsStatus?: boolean;
  isEmailSend?: boolean;
}

export default function Header(props: Props) {
  return (
    <Wrapper>
      <NumberField>{props.number}</NumberField>
      <IconsWrapper isMain>
        {props.isUrgently && (
          <UrgentlyIconWrapper title="Срочно">
            <UrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        {props.urgent != null && [2, 3].includes(props.urgent) && (
          <UrgentlyIconWrapper
            important={props.urgent === 2}
            title={props.urgent === 2 ? 'Важно' : 'Срочно'}>
            <UrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        {props.urgent === 4 && (
          <UrgentlyIconWrapper title="Очень срочно">
            <DoubleUrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        {props.isForestInTouch && (
          <ForestInTouchIconWrapper title="Лес на связи">
            <ForestInTouchIcon />
          </ForestInTouchIconWrapper>
        )}
        {props.location != null && <LocationIcon location={props.location} />}
      </IconsWrapper>
      <IconsWrapper>
        {props.isSmsStatus != undefined && (
          <MessageIconWrapper
            title={
              props.isSmsStatus
                ? 'Сообщение отправлено'
                : 'Сообщение не отправлено'
            }>
            <SMSIcon />
            {props.isSmsStatus ? (
              <SuccessIconWrapper>
                <SuccessIcon />
              </SuccessIconWrapper>
            ) : (
              <RejectIconWrapper>
                <RejectIcon />
              </RejectIconWrapper>
            )}
          </MessageIconWrapper>
        )}
        {
          <MessageIconWrapper
            title={
              props.isEmailSend ? 'Почту отправили' : 'Почту не отправили'
            }>
            <EmailIcon />
            {props.isEmailSend ? (
              <SuccessIconWrapper>
                <SuccessIcon />
              </SuccessIconWrapper>
            ) : (
              <RejectIconWrapper>
                <RejectIcon />
              </RejectIconWrapper>
            )}
          </MessageIconWrapper>
        }
      </IconsWrapper>
    </Wrapper>
  );
}
