import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const List = styled.div`
  flex: auto;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Card = styled.article`
  padding: 25px 24px 24px;
  background: #ffffff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const Header = styled.header`
  margin-bottom: 17px;

  width: 100%;

  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  & > *:first-child {
    max-width: 216px;
  }

  & > *:nth-child(2) {
    flex: auto;
  }

  @media screen and (max-width: 800px) {
    display: block;

    > {
      margin-right: 0;
      max-width: 100%;
    }

    > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-color);

  padding: 0;
  border: none;
  background-color: transparent;

  &::before {
    content: '✔';
    color: #ff6600;
    font-size: 70%;

    margin-right: 10px;
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

interface IText {
  isBold?: boolean;
}

export const Text = styled.p<IText>`
  margin: 0;
  font-weight: ${(p) => (p.isBold ? 700 : 400)};
  font-size: 16px;
  line-height: 22px;

  color: #333333;
`;
