import {useEffect} from 'react';

export function useDocumentTitle(
  title: string,
  postfix: string | null = "ЦППЛ"
) {
  useEffect(() => {
    document.title = title + (postfix == null ? "" : ` | ${postfix}`);
  }, [title, postfix]);
}
