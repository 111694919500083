import React from 'react';
import {useActions} from './hooks';
import TransferBase from '../../../../../modals/transfer/component';

interface Props {
  onChangeSuccess?: () => void;
  onClose: () => void;
}

export default function Transfer(props: Props) {
  const {handleTransfer} = useActions();

  return (
    <TransferBase
      onTransfer={handleTransfer as any}
      onChangeSuccess={props.onChangeSuccess}
      onClose={props.onClose}
    />
  );
}
