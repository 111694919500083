import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Form} from '../components/form/component';
import {useCall} from './hooks';
import {Wrapper, Title} from './styles';

export function NewCallScreen() {
  useDocumentTitle('Создать звонок');

  const {createCall, loading} = useCall();

  return (
    <Wrapper>
      <Title>Новый звонок</Title>
      <Form
        submitLabel="Создать звонок"
        fetching={loading}
        onSubmit={createCall}
      />
    </Wrapper>
  );
}
