import styled from 'styled-components';

export const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: #333;

  margin: 0;
`;

export const Field = styled.div``;

export const TitleLink = styled.p`
  margin: 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const Link = styled.a`
  margin: 0;
  text-decoration: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--additional-color);
`;
