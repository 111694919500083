import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {CREATE_GROUP_VIDEO} from '../../../../../../gql';
import {
  CreateGroupVideo,
  CreateGroupVideoVariables,
} from '../../../../../../gql/mutations/__generated__/CreateGroupVideo';
import {Id} from '../../../../../../types/auxiliary.types';
import {VideoTypes} from '../types';

export function useAddVideo(
  groupId: Id,
  onAddSuccess: () => void,
  onModalClose?: () => void
) {
  const [requestSending, setRequestSending] = useState(false);
  const [addVideoMutation] = useMutation<
    CreateGroupVideo,
    CreateGroupVideoVariables
  >(CREATE_GROUP_VIDEO);

  const handleAddVideo = useCallback(
    async (data: VideoTypes) => {
      setRequestSending(true);
      await addVideoMutation({
        variables: {
          input: {
            groupId: Number(groupId),
            url: data.url,
            name: data.name,
          },
        },
      })
        .then(() => {
          toast('Видео успешно создано!');
          onModalClose?.();
          onAddSuccess();
        })
        .catch((e) => toast(e.message, {type: 'error'}))
        .finally(() => setRequestSending(false));
    },
    [addVideoMutation, groupId, onAddSuccess, onModalClose]
  );
  return {handleAddVideo, requestSending};
}
