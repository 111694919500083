import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
`;

export const TitleCount = styled.span`
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: var(--secondary-text-color);
  margin-left: 12px;
`;

export const Label = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  background: var(--primary-border-color);
  border-radius: 8px;
`;

export const Text = styled.p`
  margin: 8px 10px 8px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--text-color);
`;

export const Count = styled.p`
  margin: 0;
  padding: 6px 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #fff;
  background: var(--primary-color);
  box-shadow: inset 2px 4px 14px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
`;
