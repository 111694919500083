import React, {ReactNode, useMemo} from 'react';
import Select from 'react-select';
import DateField from '../../../../elements/DateField/DateField';
import {Label} from '../../../../elements/Label/Label';
import {TextField} from '../../../../elements/TextField/TextField';
import {BlockWrapper} from '../../../../elements/BlockWrapper/BlockWrapper';
import {
  Control,
  Controller,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {Step1FormFields as FormFields} from '../../types';
import {useDict} from './GeneralInfo.hooks';
import {
  ChildrenWrapper,
  GridFields,
  PrimaryColumn,
  SecondaryColumn,
  Wrapper,
} from './GeneralInfo.styles';
import SwitchField from '../../../../elements/SwitchField/SwitchField';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  setValue: UseFormSetValue<FormFields>;
  children?: ReactNode;
  errors?: any;
}

export const GeneralInfo = (props: Props) => {
  const {setValue} = props;
  const currentDate = new Date();

  const {regions, locations, sources, areas, loading} = useDict();

  const fields = useWatch({
    control: props.control,
  });

  const area =
    fields.region == null || fields.region === '' ? [] : areas[fields.region];

  const forestLocation = useMemo(
    () => locations?.find((l) => l.label === 'Лес'),
    [locations]
  );

  return (
    <BlockWrapper title="Общая информация" shouldOpen>
      <Wrapper>
        <PrimaryColumn>
          <GridFields>
            <Label label={'Выберите регион*'}>
              <Controller
                name={'region'}
                control={props.control}
                rules={{required: true}}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                      }}
                      placeholder={'Выберите регион'}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={regions}
                      value={regions?.find((c) => c.value === value)}
                      required
                      isLoading={loading}
                      isClearable
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
              {props.errors?.region &&
                props.errors?.region?.type === 'required' && (
                  <p style={{color: 'red'}}>Поле обязательно для заполнения</p>
                )}
            </Label>

            <Label label={'Дополнительные регионы'}>
              <Controller
                name={'additionalRegions'}
                control={props.control}
                render={({field: {onChange, onBlur}}) => {
                  return (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                      }}
                      placeholder="Выберите регионы"
                      isMulti
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={regions}
                      isLoading={loading}
                      isClearable
                      onChange={(options) =>
                        onChange(options?.map((option) => option.value))
                      }
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </Label>

            <Label label={'Районы'}>
              <Controller
                name={'area'}
                control={props.control}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      placeholder="Выберите"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                      }}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={
                        fields.region == null || fields.region === ''
                          ? []
                          : areas[fields.region]
                      }
                      isMulti
                      value={
                        fields.region == null || fields.region === ''
                          ? []
                          : areas[fields.region]?.find((c) => c.value === value)
                      }
                      onBlur={onBlur}
                      onChange={(options) =>
                        onChange(options?.map((option) => option.value))
                      }
                      isDisabled={
                        fields.region == null || fields.region === '' || loading
                      }
                    />
                  );
                }}
              />
            </Label>

            <Label label={'Локация*'}>
              <Controller
                name={'location'}
                rules={{required: true}}
                control={props.control}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      placeholder="Выберите"
                      options={locations}
                      isLoading={loading}
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                      }}
                      value={locations?.find((c) => c.value === value)}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      isDisabled={Boolean(fields.forestInTouch)}
                    />
                  );
                }}
              />
              {props.errors?.location &&
                props.errors?.location.type === 'required' && (
                  <p style={{color: 'red'}}>Поле обязательно для заполнения</p>
                )}
            </Label>

            <Label label={'Источник*'}>
              <Controller
                name={'source'}
                control={props.control}
                rules={{required: true}}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      placeholder={'Выберите'}
                      options={sources}
                      value={sources?.find((c) => c.value === value)}
                      isLoading={loading}
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                      }}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                    />
                  );
                }}
              />
              {props.errors?.source &&
                props.errors?.source.type === 'required' && (
                  <p style={{color: 'red'}}>Поле обязательно для заполнения</p>
                )}
            </Label>
          </GridFields>
          <GridFields>
            <DateField
              label="Дата и время пропажи"
              name="date"
              format="dd.MM.yyyy HH:mm"
              maxDate={currentDate}
              control={props.control}
            />
          </GridFields>
          <TextField
            register={props.register('circumstances')}
            label="Обстоятельства пропажи"
            placeholder="Введите"
            isTextarea
            rows={2}
          />
          <TextField
            register={props.register('lastSeen')}
            label="Где видели в последний раз"
            placeholder="Введите"
            isTextarea
            rows={2}
          />
        </PrimaryColumn>
        <SecondaryColumn>
          <SwitchField
            name="educational"
            control={props.control}
            label="Учебная"
          />
          <SwitchField
            name="forestInTouch"
            control={props.control}
            label="Лес на связи"
            onCallbackChange={() => {
              if (forestLocation != null)
                setValue('location', forestLocation.value);
              else
                console.error('В справочнике локаций отсутсвует локация "Лес"');

              setValue('urgently', true);
            }}
          />
          <SwitchField
            name="urgently"
            control={props.control}
            label="Срочная"
            disabled={fields.forestInTouch}
          />
          <SwitchField
            name="reverseLookup"
            control={props.control}
            label="Обратный поиск"
          />
        </SecondaryColumn>
      </Wrapper>
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
    </BlockWrapper>
  );
};
