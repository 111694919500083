import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {CREATE_TEAM_FILE_MUTATION} from '../../../../../gql';
import {CreateTeamFile, CreateTeamFileVariables} from '../../../../../gql/mutations/__generated__/CreateTeamFile';

export const useAddFilesHandler = (fetch: () => void) => {
  const [requestSending, setRequestSending] = useState(false)

  const [addFileMutation] = useMutation<CreateTeamFile, CreateTeamFileVariables>(
    CREATE_TEAM_FILE_MUTATION
  );

  const handleAddFile = useCallback(
    async (data: {
      file: File,
      name: string
    }) => {
      setRequestSending(true)

      await addFileMutation({
        variables: {
          input: data,
        }
      }).then(() => fetch())
        .finally(() => setRequestSending(false))
    },
    [addFileMutation, fetch]
  );
  return {handleAddFile, requestSending}
};
