import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  margin-top: 2%;
  margin-bottom: 8%;

  max-width: 62%;
  max-height: 86%;
  background-color: transparent;

  width: inherit;
  height: fit-content;

  border-radius: 4px;

  position: relative;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  width: 21px;
  height: 21px;
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    transform: rotate(45deg);

    path {
      stroke: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Content = styled.div`
  padding-top: 36px;
  box-sizing: border-box;
`;

interface IChevronButton {
  rotate?: boolean;
}

export const ChevronButton = styled.button<IChevronButton>`
  width: 42px;
  height: 20px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: ${(p) => (p.rotate ? 'auto' : '38px')};
  right: ${(p) => (p.rotate ? '38px' : 'auto')};
  top: 0;
  bottom: 0;
  margin: auto;

  background-color: transparent;
  border: none;
  transform: rotate(${(p) => (p.rotate ? -90 : 90)}deg);

  cursor: pointer;

  svg {
    width: 42px;
    height: 20px;

    path {
      stroke: #fff;
      fill: transparent;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    svg path {
      stroke: #333;
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;
