import React from 'react';
import {
  AvatarContainer,
  Header,
  Content,
  Time,
  UserInfoWrapper,
  UserName,
  Wrapper,
  DeleteButton,
} from './styles';

import {Avatar} from '../../../Avatar/Avatar';
import {Id} from '../../../../../types/auxiliary.types';
import {TrashIcon} from '../../../../icons/TrashIcon';

interface Props {
  fullName: string;
  date?: Date;
  description: string;
  deleteComment?: (id: Id) => void;
  id: Id;
}

export default function CommentCard(props: Props) {
  return (
    <Wrapper>
      <Header>
        <AvatarContainer>
          <Avatar fullName={props.fullName} />
        </AvatarContainer>
        <UserInfoWrapper>
          <UserName>{props.fullName}</UserName>
          {props.date != null && <Time>{props.date}</Time>}
        </UserInfoWrapper>
      </Header>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Content>{props.description}</Content>
        <DeleteButton
          onClick={() =>
            props.deleteComment === undefined
              ? null
              : props.deleteComment(props.id)
          }>
          <TrashIcon />
        </DeleteButton>
      </div>
    </Wrapper>
  );
}
