import React from 'react';
import {useParams} from 'react-router-dom';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useAlgorithm} from './Algorithms.hooks';
import {
  AlgorithmDescription,
  AlgorithmName,
  Wrapper,
} from './Algorithms.styles';

export default function Algorithm() {
  const {algorithm, loading} = useAlgorithm();

  const params = useParams<{algorithmId: string}>();

  const data = algorithm.find((p) => p.id == params.algorithmId);

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <AlgorithmName>{data?.name}</AlgorithmName>
          {data?.text != null && (
            <AlgorithmDescription
              dangerouslySetInnerHTML={{__html: data?.text}}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}
