import React from 'react';
import {useParams} from 'react-router-dom';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useRegionalAgree} from './RegionalAgree.hooks';
import {
  RegionalDescription,
  RegionalName,
  Wrapper,
} from './RegionalAgree.styles';

export default function RegionalAgreement() {
  const {regionalAgree, loading} = useRegionalAgree();

  const params = useParams<{regionalId: string}>();

  const data = regionalAgree.find((p) => p.id == params.regionalId);

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <RegionalName>{data?.name}</RegionalName>
          {data?.text != null && (
            <RegionalDescription
              dangerouslySetInnerHTML={{__html: data.text}}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}
