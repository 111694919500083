import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {FormFields} from './types';
import {Wrapper, Buttons} from './styles';
import {ModalWrapper} from '../../../../../elements/ModalWrapper/ModalWrapper';
import {TextField} from '../../../../../elements/TextField/TextField';
import {Button} from '../../../../../elements/Button/Button';
import {useDict} from './hooks';
import SelectField from '../../../../../elements/SelectField/SelectField';

interface Props {
  title: string;
  submitLabel: string;
  isDisabledGroup?: boolean;
  defaultValues?: FormFields;
  submitLoading?: boolean;
  onSubmit: (data: FormFields, setError?: (error: string) => void) => void;
  onClose: () => void;
}

export function Form(props: Props) {
  const {handleSubmit, register, control} = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      regionId: '',
      categoryId: '',
      name: '',
      number: '',
      note: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const {regions, category} = useDict();

  const [commonError, setCommonError] = useState('');

  return (
    <ModalWrapper title={props.title} onModalClose={props.onClose}>
      <Wrapper
        onSubmit={handleSubmit((d) => props.onSubmit(d, setCommonError))}>
        <TextField
          label="Название техники"
          register={register('name')}
          placeholder="Введите"
          isTextarea
        />
        <TextField
          label="Номер"
          register={register('number')}
          placeholder="Введите"
          isTextarea
        />
        <SelectField
          placeholder="Выберите"
          label="Регион"
          control={control}
          options={regions}
          name="regionId"
        />
        <SelectField
          placeholder="Выберите"
          label="Категория"
          control={control}
          name={'categoryId'}
          options={category}
        />
        <TextField
          label="Примечание"
          register={register('note')}
          placeholder="Введите"
          isTextarea
        />
        {commonError !== '' && <p>{commonError}</p>}
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            onClick={props.onClose}
            fullWidth
            disabled={props.submitLoading}
          />
          <Button
            title={props.submitLabel}
            type="submit"
            fullWidth
            disabled={props.submitLoading}
          />
        </Buttons>
      </Wrapper>
    </ModalWrapper>
  );
}
