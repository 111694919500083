import styled from 'styled-components';
import {Button} from '../Button/Button';

export const Span = styled.span`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const IconText = styled.div`
  stroke: #ff6600;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

export const ListFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .shown-enter {
    opacity: 0.01;
  }

  .shown-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
  }

  .shown-exit {
    opacity: 1;
    height: 0;
  }

  .shown-exit-active {
    opacity: 0.01;
    height: auto;
    transition: all 500ms ease-in;
  }
`;

export const ListFilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ListFilterContent = styled.div<{isShow: boolean}>`
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid #a1b2c8;
  background-color: transparent;
  padding: 1.5rem 2rem;
`;

export const ListFilterButton = styled(Button)`
  margin-left: 1rem;
`;

export const Container = styled.div`
  flex: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--additional-color);

  transition: color 500ms linear;

  cursor: pointer;

  svg {
    width: 8px;
    height: 5px;
    margin: 0 8px 0 4px;

    path {
      fill: var(--additional-color);

      transition: fill 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

export const CountWrapper = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;

export const Count = styled.span`
  font-weight: 700;

  color: var(--additional-color);
`;
