import {useNavigate} from 'react-router-dom';
import {
  ButtonsWrapper,
  LabelsWrapper,
  LogoWrapper,
  MainSection,
  UserWrapper,
  Wrapper,
} from './Header.styles';
import {Logo} from '../../elements/Logo/Logo';
import {useHeader} from './Header.hooks';
import {Label, User} from './components';
import {Button} from '../../elements/Button/Button';
import {PlusCircleIcon} from '../../icons/PlusCircleIcon';

interface Props {
  onClickLogo?: () => void;
}

export function Header(props: Props) {
  const navigate = useNavigate();

  const {data, loading} = useHeader();

  return (
    <Wrapper>
      <LogoWrapper onClick={props.onClickLogo}>
        <Logo variant="small" />
      </LogoWrapper>

      <MainSection>
        <ButtonsWrapper>
          <Button
            type="button"
            color="secondary"
            onClick={() => navigate('/applications/new')}>
            <PlusCircleIcon />
            <span>Заявка</span>
          </Button>
          <Button onClick={() => navigate('/calls/new')}>
            <PlusCircleIcon />
            <span>Звонок</span>
          </Button>
        </ButtonsWrapper>
        <LabelsWrapper>
          {loading
            ? null
            : data.map((item) => {
                return (
                  <LabelsWrapper key={item.id}>
                    <Label
                      key={item.id}
                      name={item.name}
                      active={item.active}
                      person={item.person}
                    />
                  </LabelsWrapper>
                );
              })}
        </LabelsWrapper>
      </MainSection>

      <UserWrapper>
        <User />
      </UserWrapper>
    </Wrapper>
  );
}
