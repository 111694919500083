import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.06);
  margin: 0;
  width: 100%;
  padding: 16px 24px 16px 16px;
  box-sizing: border-box;

  > :not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 900px) {
    align-items: start;
    flex-direction: column;

    > :not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  color: var(--black-color);
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 900px) {
    margin-bottom: 16px;
  }
`;

export const CommentBlock = styled.p`
  font-weight: 400;
  font-size: 16px;

  color: #333;
`;

export const LinkBlock = styled.a`
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: #376092;
`;
