import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import Preloader from '../../elements/Preloader/Preloader';
import {DateField} from '../elements/dateField/component';
import User from '../../elements/user/component';
import {Badge} from '../../elements/Badge/Badge';
import MainInfoCard from '../elements/mainInfo/component';
import {
  Status,
  Wrapper,
  MainInfo,
  PersonInfo,
  ResponseName,
  SecondaryInfo,
  TypeWrapper,
  ValueText,
  DateWrapper,
  RowWrapper,
  Description,
  DescriptionWrapper,
  SecondaryInfoBlock,
  Badges,
  Comment,
} from './styles';
import {Button} from '../../elements/Button/Button';

interface Props {
  number: number;
  description: string;
  isUrgent: boolean;
  isTraining: boolean;
  status: string;
  statusColor: CSSProperties['backgroundColor'];
  avatarResponsible?: string;
  fullNameResponsible?: string;
  phoneResponsible?: string;
  region: string;
  type: string;
  dateTime: Date | null;
  ringingCount?: number;
  taskCount?: number;
  commentCount?: number;
  takeToWork?: () => void;
  responsible?: number | undefined;
  contact?: string;
  takeWork?: boolean;
}

export default function Card(props: Props) {
  const navigate = useNavigate();
  const showRingingCount =
    props.ringingCount != null && props.ringingCount !== 0;
  const showTaskCount = props.taskCount != null && props.taskCount !== 0;
  const showCommentCount =
    props.commentCount != null && props.commentCount !== 0;

  function handleStopPropogation(event: any) {
    event.stopPropagation();
  }

  return (
    <Wrapper onClick={() => navigate(`/calls/${props.number}`)}>
      <MainInfo>
        <MainInfoCard
          number={props.number}
          isUrgent={props.isUrgent}
          isTraining={props.isTraining}
          status={props.status}
          statusColor={props.statusColor}
        />
        <PersonInfo>
          <ResponseName>{props.contact}</ResponseName>
          <SecondaryInfo>
            {props.phoneResponsible == '' ? '112' : props.phoneResponsible}
          </SecondaryInfo>
          <SecondaryInfo>{props.region}</SecondaryInfo>
        </PersonInfo>

        <RowWrapper>
          <TypeWrapper>
            {props.type !== '' && (
              <>
                <SecondaryInfo>Тип:</SecondaryInfo>
                <ValueText>{props.type}</ValueText>
              </>
            )}
          </TypeWrapper>
          <DateWrapper>
            <DateField
              date={props.dateTime}
              label="Дата создания"
              hasLabelPostfix
            />
          </DateWrapper>
          <TypeWrapper onClick={(e) => handleStopPropogation(e)}></TypeWrapper>
        </RowWrapper>
        {props.takeToWork !== null && !props.fullNameResponsible && (
          <div onClick={(e) => handleStopPropogation(e)}>
            <Button
              onClick={props.takeToWork}
              disabled={!!props.responsible}
              color="primary"
              title={props.takeWork ? 'Идет загрузка...' : 'Взять в работу'}
            />
          </div>
        )}
        {props.fullNameResponsible != null &&
          props.fullNameResponsible !== '' && (
            <User
              avatar={props.avatarResponsible}
              fullName={props.fullNameResponsible}
              label="Ответственный"
            />
          )}

        {props.status !== '' && (
          <Status hideSmallSize color={props.statusColor}>
            {props.status}
          </Status>
        )}
      </MainInfo>
      {props.description !== '' && (
        <DescriptionWrapper>
          <Description>
            {props.description.substring(0, 250)}
            {props.description.length > 250 ? '...' : ''}
          </Description>
        </DescriptionWrapper>
      )}
      {(showRingingCount || showTaskCount || showCommentCount) && (
        <SecondaryInfoBlock>
          <Badges>
            {showRingingCount && (
              <Badge
                color="dark_blue"
                title={`Прозвоны: ${props.ringingCount}`}
              />
            )}
            {showTaskCount && (
              <Badge color="dark_blue" title={`Задачи: ${props.taskCount}`} />
            )}
          </Badges>
          {showCommentCount && (
            <Comment>Комментарии: {props.commentCount}</Comment>
          )}
        </SecondaryInfoBlock>
      )}
    </Wrapper>
  );
}
