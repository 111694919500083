import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;

  svg path {
    &[data-element='town'] {
      fill: var(--yellow-color-accent);
    }

    &[data-element='forest'] {
      fill: var(--secondary-color);
    }

    &[data-element='water'] {
      fill: var(--additional-color);
    }
  }
`;
