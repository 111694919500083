import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import { DateField } from '../../../../../../cards/elements/dateField/component';
import MainInfo from '../../../../../../cards/elements/mainInfo/component';
import Status from '../../../../../../cards/elements/status/component';
import {Button} from '../../../../../../elements/Button/Button';

import {
  DateWrapper,
  SecondaryInfo,
  Name,
  Region,
  StatusWrapper,
  Wrapper,
} from './styles';

interface Props {
  number: number;
  isUrgent: boolean;
  description: string;
  region: string
  createAt: Date | null;
  status: string;
  statusColor: CSSProperties["backgroundColor"];
  loading?: boolean
  onSendData: () => void
}

export default function Card(props: Props) {
  const navigate = useNavigate();

  return (
    <Wrapper onClick={() => navigate(`/tasks/${props.number}`)}>
      <MainInfo
        number={props.number}
        isUrgent={props.isUrgent}
        status={props.status}
        statusColor={props.statusColor}
      />

      <SecondaryInfo>
        <Name>{props.description}</Name>
        <Region>{props.region}</Region>
      </SecondaryInfo>

      <DateWrapper>
        <DateField
          label="Добавлено"
          date={props.createAt}
        />
      </DateWrapper>
      <StatusWrapper>
        <Status
          color={props.statusColor}
          value={props.status}
          hideSmallSize
        />
      </StatusWrapper>
      <Button
        disabled={props.loading}
        type="button"
        title="Передать"
        onClick={(e) => {
          e.stopPropagation();

          props.onSendData()
        }}
      />
    </Wrapper>
  )
}
