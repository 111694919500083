import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface ICheckboxWrapper {
  disabled?: boolean;
}

export const CheckboxWrapper = styled.p<ICheckboxWrapper>`
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color);

  opacity: ${(p) => (p.disabled ? '0.5' : '1')};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

export const FakeCheck = styled.span`
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;

  svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    border-color: var(--additional-color);
  }
`;

export const UnCheck = styled.span`
  display: block;
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;
  box-sizing: border-box;

  border: 1px solid var(--secondary-text-color);
  border-radius: 4px;

  &:hover {
    border-color: var(--additional-color);
  }
`;


export const Form = styled.form`
  flex: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: auto;
  
  padding: 0 25px 25px;

  > *:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 2px solid #fff;
  }
  
  > * {
    padding-top: 32px;
  }
`;

export const GeneralInfo = styled.div`
  display: flex;
`;

export const PrimaryColumn = styled.div`
  flex: auto;
  margin-right: 92px;
  max-width: 648px;
  
  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1110px) {
    flex: none;
  }
  
  @media screen and (max-width: 680px) {
    margin-right: 0;
  }
`;

export const GridFields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 24px;
  grid-row-gap: 32px;
  
  *[data-element='time-field'] {
    max-width: 145px;
  }

  @media screen and (max-width: 1110px) {
    grid-template-columns: repeat(1, 312px);
  }
  
  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const SecondaryColumn = styled.div`
  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 680px) {
    margin-top: 32px;
  }
`;

export const BigGridFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 312px);

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 312px);
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const Footer = styled.div`
  width: 100%;
  box-sizing: border-box;
  
  display: flex;
  align-items: center;
  justify-content: end;

  background-color: #fff;
  
  > :not(:last-child) {
    margin-right: 16px;
  }
`;
