import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;

  flex: auto;
  overflow-y: hidden;
`;

export const MenuWrapper = styled.div`
  height: auto;
  border-right: 1px solid #d8e2ed;
`;

export const OutletWrapper = styled.div`
  max-width: 1448px;
  margin: 0 auto;
  flex: auto;
  overflow: auto;

  display: flex;
  flex-direction: column;
`;
