import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 304px;
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

interface IButton {
  isRight?: boolean;
}

export const Button = styled.button<IButton>`
  width: 32px;
  height: 32px;
  margin-right: ${(p) => (p.isRight ? 0 : 16)}px;
  margin-left: ${(p) => (p.isRight ? 16 : 0)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: var(--primary-color);

  transform: rotate(${(p) => (p.isRight ? 180 : 0)}deg);

  svg path {
    fill: var(--primary-color-contrast);
  }

  &:hover {
    background-color: #ff8c40;
  }

  &:disabled {
    background-color: var(--primary-color);
    opacity: 0.2;
  }
`;

export const PagesWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

interface IPage {
  //not found in design
  active: boolean;
}

export const Page = styled.button<IPage>`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);

  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: var(--black-color);
  transition: color, background-color 500ms linear;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }

  &:disabled {
    //not found in design
  }
`;
