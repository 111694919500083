import React from 'react';
import Card from '../../../../cards/task/component';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useGroupTask} from './hoooks';
import {List, Main, Wrapper} from './styles';

const Tasks = () => {
  const {task: data, loading} = useGroupTask();

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      <Main>
        <List>
          {data?.map((task) => {
            return (
              <Card
                key={task.id}
                number={Number(task.id)}
                issue={task.issue}
                call={task.call}
                isUrgent={task.isUrgent}
                description={task.description}
                region={task.region}
                createAt={task.createDate}
                avatarUrl={task.avatarResponsible}
                fullNameResponsible={task.responsible}
                status={task.status}
                statusColor={task.statusColor}
                result={task.result}
              />
            );
          })}
        </List>
      </Main>
    </Wrapper>
  );
};

export default Tasks;
