import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {APPLICATION_CIRCUMSTANCES_OF_LOSS_QUERY} from '../../../../../../gql';
import {
  CircumstancesOfLoss,
  CircumstancesOfLossVariables,
} from '../../../../../../gql/application/queries/__generated__/CircumstancesOfLoss';

export function useCircumstancesOfLoss() {
  const params = useParams<{applicationId?: string}>();
  const {data, loading} = useQuery<
    CircumstancesOfLoss,
    CircumstancesOfLossVariables
  >(APPLICATION_CIRCUMSTANCES_OF_LOSS_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null || params.applicationId === '',
  });

  return {
    text: data?.issue?.reason_of_loss || '',
    loading,
  };
}
