import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {
  CALL_REQUEST_INSTITUTION_QUERY,
  CALL_REQUEST_SET_RESULT_MUTATION,
} from '../../../../gql';
import {
  InstitutionItem,
  InstitutionItemVariables,
} from '../../../../gql/callRequest/queries/__generated__/InstitutionItem';
import {
  SetCallRequestResult,
  SetCallRequestResultVariables,
} from '../../../../gql/callRequest/mutation/__generated__/SetCallRequestResult';
import {getAgeFromDate} from '../../../../utils/Date.utils';
import {MapMarkerIcon} from '../../../icons/MapMarkerIcon';
import {Props as MissingCardProps} from '../../Application/ApplicationView/components/MissingCard/MissingCard';
import {toast} from 'react-toastify';

export function useInstitution() {
  const params = useParams<{callRequestId: string; institutionId: string}>();

  const {data, loading, refetch} = useQuery<
    InstitutionItem,
    InstitutionItemVariables
  >(CALL_REQUEST_INSTITUTION_QUERY, {
    variables: {
      id: Number(params.callRequestId),
    },
    skip: params.callRequestId == null || params.callRequestId === '',
  });

  const institution = data?.callRequest?.institutions?.find(
    (i) => i?.id === Number(params.institutionId)
  );

  return {
    loading,
    refetch,
    data: {
      id: data?.callRequest?.id || '',
      urgency: data?.callRequest?.urgency as undefined | 1 | 2 | 3 | 4,
      createDate:
        data?.callRequest?.created_at == null
          ? undefined
          : new Date(data.callRequest.created_at),
      circumstances: data?.callRequest?.issue?.reason_of_loss || '',
      issue: {
        id: data?.callRequest?.issue?.id || '',
        lossDate:
          data?.callRequest?.issue?.date_of_loss == null
            ? undefined
            : new Date(data.callRequest.issue.date_of_loss),
        missing:
          data?.callRequest?.issue?.missing == null
            ? []
            : data.callRequest.issue.missing.map((m) => ({
                id: m?.id || '',
                gender: m?.sex === 'male' ? 'm' : ('w' as 'm' | 'w'),
                fullName: {
                  lastName: m?.last_name || '',
                  firstName: m?.first_name || '',
                  secondName: m?.second_name || undefined,
                },
                age:
                  m?.birthday_string == null
                    ? 0
                    : getAgeFromDate(new Date(m.birthday_string)),
                // labels: {
                //   fire: true,
                //   water: false,
                //   phoneCharging: 12,
                //   wifiNetwork: 4,
                //   generalState: 8,
                // },
                labels: undefined,
                statuses: {
                  status: m?.status?.id,
                  subStatus: m?.subStatus?.id,
                },
                fields: [
                  {
                    id: 'phone',
                    name: 'Телефоны',
                    value: m?.phone_number,
                    labels: [
                      {
                        id: 1,
                        value: m?.havingPhoneStatus?.name,
                      },
                      {
                        id: 2,
                        value: m?.availabilityPhoneStatus?.name,
                      },
                    ].filter((l) => l.value != null),
                  },
                  {
                    id: 'coordinates',
                    name: 'Координаты обнаружения',
                    value: m?.detection_coordinates,
                    preIcon: MapMarkerIcon,
                  },
                  {
                    id: 'health',
                    name: 'Здоровье',
                    value: m?.health,
                  },
                  {
                    id: 'appearance',
                    name: 'Приметы, внешность',
                    value: m?.appearance,
                  },
                  {
                    id: 'clothes',
                    name: 'Одежда',
                    value: m?.clothes,
                  },
                  {
                    id: 'withSelf',
                    name: 'С собой',
                    value: m?.things,
                  },
                  {
                    id: 'additionally',
                    name: 'Дополнительно',
                    value: m?.additional_information,
                  },
                  {
                    id: 'informationForPilot',
                    name: 'Информация для пилота',
                    value: m?.pilot_information,
                  },
                ].filter((f) => f.value != null) as MissingCardProps['fields'],
              })),
        regionFields: [
          {
            title: 'Регионы',
            value: data?.callRequest?.issue?.region?.name || '',
          },
          {
            title: 'Районы',
            value:
              data?.callRequest?.issue?.districts == null
                ? ''
                : data.callRequest.issue.districts
                    .map((i) => i?.name)
                    .join(', '),
          },
          {
            title: 'Город и адрес',
            value: data?.callRequest?.issue?.last_seen_location || '',
          },
        ].filter((f) => f.value != null && f.value !== ''),
      },
      institution:
        institution == null
          ? null
          : {
              id: institution.id || '',
              number: institution.call_order || 0,
              name: institution.institution.name || '',
              type: institution.institution.institution_type?.name || '',
              region: institution.institution.region?.name || '',
              address: institution.institution.address || '',
              phone: institution.institution.phone_number || '',
              email: institution.institution.email || '',
              webSite: institution.institution.website_link || '',
              note: institution.institution.note || '',
              caller:
                institution.calledBy == null
                  ? undefined
                  : {
                      avatar: undefined,
                      fullName: institution.calledBy.profile.full_name || '',
                    },
              result: institution.result || '',
            },
    },
  };
}

export function useActions(onAddSuccess?: () => void) {
  const params = useParams<{callRequestId: string; institutionId: string}>();
  const navigate = useNavigate();

  const [createResult, {loading}] = useMutation<
    SetCallRequestResult,
    SetCallRequestResultVariables
  >(CALL_REQUEST_SET_RESULT_MUTATION);

  function handleCreateResult(data: {result: string}) {
    createResult({
      variables: {
        input: {
          institutionId: Number(params.institutionId),
          result: data.result,
        },
      },
    })
      .then(() => {
        toast('Результат успешно изменен');
        navigate(`/callRequests/${params.callRequestId}`);
        onAddSuccess?.();
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      });
  }

  return {handleCreateResult, loading};
}
