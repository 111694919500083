import React from 'react';
import {SvgStyled} from './Icons.styles';

export const ChargingIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4038 0.192383C14.7437 0.192383 15.0192 0.467901 15.0192 0.807786V2.65395H15.9423C16.1122 2.65395 16.25 2.79171 16.25 2.96163V6.03856C16.25 6.20848 16.1122 6.34624 15.9423 6.34624H15.0192V8.1924C15.0192 8.53229 14.7437 8.80781 14.4038 8.80781H0.865403C0.525518 8.80777 0.25 8.53225 0.25 8.1924V0.807786C0.25 0.467901 0.525518 0.192383 0.865403 0.192383H14.4038ZM4.55769 2.03855H2.09617V6.96164H4.55769V2.03855Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
