import {useQuery} from '@apollo/client';
import {useState} from 'react';
import {toast} from 'react-toastify';
import {SHORT_TEAMS_QUERY} from '../../../gql';
import {ShortTeams} from '../../../gql/queries/__generated__/ShortTeams';
import {createDict} from '../../../utils/Dict.utils';

import {FormFields} from './types';

export function useDict() {
  const {data: squads, loading} = useQuery<ShortTeams>(SHORT_TEAMS_QUERY);

  return {
    loading,
    groups: squads?.allTeams == null ? [] : createDict(squads.allTeams),
  };
}

interface UseActions {
  onChangeSuccess?: () => void;
  onTransfer: (data: FormFields) => Promise<void>;
}

export function useActions(params: UseActions) {
  const [loading, setLoading] = useState(false);
  const [commonMessage, setCommonMessage] = useState('');

  function handleTransfer(data: FormFields) {
    setLoading(true);

    params
      .onTransfer(data)
      .then(() => {
        setCommonMessage('');
        params.onChangeSuccess?.();
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      })
      .finally(() => setLoading(false));
  }

  return {
    loading,
    commonMessage,
    handleTransfer,
  };
}
