import React from 'react';
import Preloader from '../../../elements/Preloader/Preloader';
import {StatisticSimple} from '../../../elements/StatisticSimple/StatisticSimple';

import {useStatistics} from './hooks';
import {StatisticsWrapper} from './styles';

export default function Statistics() {
  const {data, loading} = useStatistics();

  return (
    <StatisticsWrapper>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <StatisticSimple title="Мои заявки" showCount items={data.issues} />
          <StatisticSimple title="Задачи" showCount items={data.tasks} />
          <StatisticSimple title="Смены" items={data.duty} />
        </>
      )}
    </StatisticsWrapper>
  );
}
