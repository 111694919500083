import styled from "styled-components";
import {CSSProperties} from 'react';

export const Wrapper = styled.article`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  
  cursor: pointer;
`

export const MainInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  
  > *:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`

interface IStatus {
  hideSmallSize?: boolean;
  hideFullSize?: boolean;
  color: CSSProperties["backgroundColor"]
}

export const Status = styled.p<IStatus>`
  height: min-content;
  display: ${p => p.hideFullSize ? "none" : "block"};
  margin: 0;
  background-color: ${p => p.color};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333;
  border-radius: 4px;
  padding: 4px 8px;

  @media screen and (max-width: 800px) {
    display: ${p => p.hideSmallSize ? "none" : "block"};
  }
`

export const PersonInfo = styled.div`
  width: 22%;
  
  > *:not(:last-child) {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    margin-top: 16px;
  }
`

export const ResponseName = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #333333;
`

export const SecondaryInfo = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);
`

export const RowWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const TypeWrapper = styled.div`
  flex: 2;
  width: 50;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const ValueText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`

export const DateWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

export const DescriptionWrapper = styled.section`
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: var(--scecondary-additional-color);


  @media screen and (max-width: 800px) {
    display: block;
  }
`

export const Description = styled.p`
  margin: 0;;
  font-size: 14px;
  line-height: 19px;

  color: #333333;
`

export const SecondaryInfoBlock = styled.div`
  display: flex;
  align-items: center;
  
  background-color: var(--additional-color);
  
  padding: 8px 16px;
`;

export const Badges = styled.div`
  display: flex;
  align-items: center;
  flex: auto;

  > * {
    margin-right: 8px;
  }
`;

export const Comment = styled.p`
  margin: 0;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;
