import {toast} from 'react-toastify';
import {CALL_REQUEST_SET_CALLER_MUTATION} from './../../../../gql/index';
import {
  setCaller,
  setCallerVariables,
} from './../../../../gql/callRequest/mutation/__generated__/setCaller';
import {useQuery, useMutation} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {CALL_REQUEST_ITEM_QUERY} from '../../../../gql';
import {
  CallRequestItem,
  CallRequestItemVariables,
} from '../../../../gql/callRequest/queries/__generated__/CallRequestItem';
import {getShortNames_dto} from '../../../../utils/String.utils';
import {useState} from 'react';

export function useCallRequest() {
  const params = useParams<{callRequestId: string}>();

  const {data, loading, refetch} = useQuery<
    CallRequestItem,
    CallRequestItemVariables
  >(CALL_REQUEST_ITEM_QUERY, {
    variables: {
      id: Number(params.callRequestId),
    },
    skip: params.callRequestId == null || params.callRequestId === '',
  });

  return {
    loading,
    refetch,
    data: {
      id: data?.callRequest?.id || '',
      urgency: data?.callRequest?.urgency as undefined | 1 | 2 | 3 | 4,
      createDate:
        data?.callRequest?.created_at == null
          ? undefined
          : new Date(data.callRequest.created_at),
      issue: {
        id: data?.callRequest?.issue?.id || '',
        lossDate:
          data?.callRequest?.issue?.date_of_loss == null
            ? undefined
            : new Date(data.callRequest.issue.date_of_loss),
        missing: getShortNames_dto(data?.callRequest?.issue?.missing),
        regionFields: [
          {
            title: 'Регионы',
            value: data?.callRequest?.issue?.region?.name || '',
          },
          {
            title: 'Районы',
            value:
              data?.callRequest?.issue?.districts == null
                ? ''
                : data.callRequest.issue.districts
                    .map((d) => d?.name)
                    .join(', '),
          },
          {
            title: 'Город и адрес',
            value: data?.callRequest?.issue?.last_seen_location || '',
          },
        ].filter((f) => f.value != null && f.value !== ''),
      },
      author:
        data?.callRequest?.createdBy?.profile.full_name == null
          ? undefined
          : {
              avatar: undefined,
              fullName: data?.callRequest?.createdBy?.profile.full_name || '',
            },

      institutions:
        data?.callRequest?.institutions == null
          ? []
          : data.callRequest.institutions.map((item) => ({
              id: item?.id || '',
              number: item?.call_order || 0,
              name: item?.institution.name || '',
              type: item?.institution.institution_type?.name || '',
              region: item?.institution.region?.name || '',
              address: item?.institution.address || '',
              phone: item?.institution.phone_number || '',
              email: item?.institution.email || '',
              webSite: item?.institution.website_link || '',
              note: item?.institution.note || '',
              caller:
                item?.calledBy == null
                  ? undefined
                  : {
                      avatar: undefined,
                      fullName: item?.calledBy.profile.full_name || '',
                    },
              result: item?.result || '',
            })),
    },
  };
}

export function useSetCaller(
  onAddSuccess?: () => void,
  onWebsocketSuccess?: any
) {
  const [setCaller] = useMutation<setCaller, setCallerVariables>(
    CALL_REQUEST_SET_CALLER_MUTATION
  );

  const [loading, setLoading] = useState(false);

  function handleSetCaller(id: number) {
    setLoading(true);
    setCaller({
      variables: {
        callRequestInstitutionId: id,
      },
    })
      .then(() => {
        setLoading(false);
        toast('Вы стали ответственным за прозвон учреждения!', {
          type: 'success',
        });
        onAddSuccess?.();
        onWebsocketSuccess?.();
      })
      .catch((e) => toast(e.message, {type: 'error'}));
  }
  return {handleSetCaller, loading};
}
