import React from 'react';
import {MapList} from '../../../../lists/maps/component';
import {useActions, useTeam} from './hooks';

export function Maps() {
  const {loading, list, fetch} = useTeam();
  const {handleAddMap} = useActions();

  return (
    <MapList
      loading={loading}
      list={list}
      // complex type with Promise and Apollo
      onAdd={handleAddMap as any}
      // complex type with Promise and Apollo
      fetch={fetch as any}
    />
  )
}
