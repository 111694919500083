import {
  TaskCreateComment,
  TaskCreateCommentVariables,
} from './../../../../gql/task/mutations/__generated__/TaskCreateComment';
import {
  TASK_COMMENTS_QUERY,
  TASK_ADD_COMMENT_MUTATION,
} from './../../../../gql/index';
import {
  TaskChat,
  TaskChatVariables,
} from './../../../../gql/task/queries/__generated__/TaskChat';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {
  TASK_CHANGE_STATUS_MUTATION,
  TASK_DELETE_MUTATION,
  TASK_QUERY,
} from '../../../../gql';
import {
  Task,
  TaskVariables,
} from '../../../../gql/task/queries/__generated__/Task';
import {
  DeleteTask,
  DeleteTaskVariables,
} from '../../../../gql/task/mutations/__generated__/DeleteTask';
import {Id} from '../../../../types/auxiliary.types';
import {IGroupStatus, IStatus} from '../../../elements/ChangedStatus/type';
import {
  ChangeStatusTask,
  ChangeStatusTaskVariables,
} from '../../../../gql/task/mutations/__generated__/ChangeStatusTask';
import {toast} from 'react-toastify';

interface FormFields {
  message: string;
}

export function useTask() {
  const params = useParams<{taskId?: string}>();

  const {data, loading, refetch} = useQuery<Task, TaskVariables>(TASK_QUERY, {
    variables: {
      id: Number(params.taskId),
    },
    skip: params.taskId == null || params.taskId === '',
  });

  return {
    refetch,
    data:
      data?.task == null
        ? null
        : {
            id: data.task.id || '',
            description: data.task.description || '',
            result: data.task.result || undefined,
            isUrgent: data.task.is_urgent || false,
            statusId: data.task.status?.id || null,
            createDate:
              data.task.created_at == null
                ? null
                : new Date(data.task.created_at),
            applications:
              data.task.issue?.id == null ? [] : [data.task.issue.id],
            calls: data.task.call?.id == null ? [] : [data.task.call.id],
            region: data.task.region?.name || '',
            author: {
              avatar: undefined,
              fullName: data.task.user?.profile.full_name || null,
            },
            responsible: {
              team: data.task.team?.name || null,
              group: data.task.group?.full_name || null,
              avatar: undefined,
              fullName: data.task.responsible?.profile.full_name || null,
            },
            controlGroup: data.task.controlGroup?.full_name || null,
          },
    loading,
  };
}

export function useActions(
  onSuccess?: () => void,
  curStatusId?: Id,
  statuses?: IGroupStatus[]
) {
  const navigate = useNavigate();

  const [deleteTask, {loading: deleteLoading}] = useMutation<
    DeleteTask,
    DeleteTaskVariables
  >(TASK_DELETE_MUTATION);

  function handleDeleteTask(id: Id) {
    deleteTask({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        toast('Задача успешно удалена');
        navigate('/tasks');
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      });
  }
  const params = useParams<{taskId?: string}>();

  const [changeStatus] = useMutation<
    ChangeStatusTask,
    ChangeStatusTaskVariables
  >(TASK_CHANGE_STATUS_MUTATION);

  const currentStatus =
    curStatusId != null
      ? statuses?.reduce((acc: IStatus | undefined, curr) => {
          if (acc != null) return acc;
          return curr.statuses.find(
            (s) => s.id.toString() === curStatusId.toString()
          );
        }, undefined)
      : undefined;

  function handleChangeStatus(id: Id) {
    changeStatus({
      variables: {
        input: {
          taskId: Number(params.taskId),
          statusId: Number(id),
        },
      },
    }).then(() => onSuccess?.());
  }

  return {
    handleDeleteTask,
    handleChangeStatus,
    currentStatus,
    actionLoading: deleteLoading,
  };
}

export function useChat() {
  const {taskId} = useParams<{taskId: string}>();

  const {data, loading, refetch} = useQuery<TaskChat, TaskChatVariables>(
    TASK_COMMENTS_QUERY,
    {
      variables: {
        id: Number(taskId),
      },
      skip: false,
    }
  );

  const [addMessageMutation, {loading: sendMessage}] = useMutation<
    TaskCreateComment,
    TaskCreateCommentVariables
  >(TASK_ADD_COMMENT_MUTATION);

  async function handleAddMessage(data: FormFields) {
    await addMessageMutation({
      variables: {
        input: {
          description: data.message,
          taskId: Number(taskId),
        },
      },
    }).then(() => {
      toast('Ваш комментарий успешно добавлен!', {type: 'success'});
    }).catch((e) => toast(e.message))
    return await refetch();
  }
  return {
    comments:
      data?.task?.comments == null
        ? []
        : data.task.comments.map((item) => {
            return {
              id: item?.id || '',
              fullName: item?.user.profile.full_name || '',
              description: item?.description || '',
              date: item?.created_at,
            };
          }),

    loading,
    handleAddMessage,
    sendMessage,
  };
}
