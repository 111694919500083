import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {GROUP_ADD_FILE_MUTATION} from '../../../../../../gql';
import {
  CreateGroupFile,
  CreateGroupFileVariables,
} from '../../../../../../gql/mutations/__generated__/CreateGroupFile';

export const useAddGroupFile = (fetch: () => void, groupId: number) => {
  const [requestSending, setRequestSending] = useState(false);

  const [addGroupFile] = useMutation<CreateGroupFile, CreateGroupFileVariables>(
    GROUP_ADD_FILE_MUTATION
  );

  const handleAddFile = useCallback(
    async (data: {file: File; name: string; groupId: number}) => {
      setRequestSending(true);
      await addGroupFile({
        variables: {
          input: {
            name: data.name,
            groupId: Number(groupId),
            file: data.file,
          },
        },
      })
        .then(() => {
          fetch();
          toast('Файл успешно добавлен!', {type: 'success'});
        })
        .catch((e) => toast(e.message, {type: 'error'}))
        .finally(() => setRequestSending(false));
    },
    [addGroupFile, fetch]
  );
  return {handleAddFile, requestSending};
};
