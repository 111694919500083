import React, {ReactNode} from 'react';
import {CorrectMark} from '../../icons/CorrectMark';
import {InCorrectMark} from '../../icons/InCorrectMark';
import {Content, ValidateMessage, Footer} from './styles';

interface Props {
  hasErrors?: boolean;
  children: ReactNode;
  isValid?: boolean;
}

export default function FormFooter(props: Props) {
  const {hasErrors, children, isValid} = props;

  return (
    <Footer>
      <ValidateMessage>
        {isValid ? (
          <>
            <CorrectMark />
            <span>Все поля заполнены корректно</span>
          </>
        ) : null}
      </ValidateMessage>

      <ValidateMessage>
        {hasErrors ? (
          <>
            <InCorrectMark />
            <span>Поля заполнены некорректно</span>
          </>
        ) : null}
      </ValidateMessage>

      <Content>{children}</Content>
    </Footer>
  );
}
