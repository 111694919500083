import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 16px;
  background: #fff;
  margin-top: 10px;
  border-radius: 0 0 8px 8px;
`;

export const Content = styled.div``;

export const ValidateMessage = styled.p`
  flex: auto;
  margin-left: 24px;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 16px;
  }

  span {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
`;
