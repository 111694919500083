import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../../../../elements/Button/Button';
import DateField from '../../../../../../elements/DateField/DateField';
import {ModalWrapper} from '../../../../../../elements/ModalWrapper/ModalWrapper';
import SelectField from '../../../../../../elements/SelectField/SelectField';
import {FormFields} from './types';
import {Wrapper, Tooltip, Buttons} from './styles';
import {useVigialGroups} from '../add/hooks';

interface Props {
  title: string;
  submitLabel: string;
  isDisabledGroup?: boolean;
  defaultValues?: FormFields;
  submitLoading?: boolean;
  onSubmit: (data: FormFields, setError?: (error: string) => void) => void;
  onClose: () => void;
}

export function Form(props: Props) {
  const [commonError, setCommonError] = useState('');

  const {control, formState, handleSubmit, watch} = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      groupId: '',
      startAt: null,
      endAt: null,
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const startAt = watch('startAt');

  const {groups} = useVigialGroups();

  const vigialGroups =
    groups == null
      ? []
      : groups.map((_) => ({
          label: _.full_name,
          value: _.id,
        }));

  return (
    <ModalWrapper title={props.title} onModalClose={props.onClose}>
      <Wrapper
        onSubmit={handleSubmit((d) => props.onSubmit(d, setCommonError))}>
        <Tooltip>Выберите статус, пользователя и время дежурства</Tooltip>
        <SelectField
          name="groupId"
          placeholder="Выберите"
          disabled={formState.isSubmitting || props.isDisabledGroup}
          options={vigialGroups}
          label="Роль или группа"
          control={control}
          rules={{
            required: 'Поле обязательно для заполнения.',
          }}
        />
        <DateField
          label="Начало смены"
          name="startAt"
          format="dd.MM.yyyy HH:mm"
          control={control}
          rules={{
            required: 'Поле обязательно для заполнения.',
          }}
        />
        <DateField
          label="Окончание смены"
          name="endAt"
          format="dd.MM.yyyy HH:mm"
          control={control}
          rules={{
            required: 'Поле обязательно для заполнения.',
            validate: (value) => {
              if (startAt != null && value < startAt)
                return 'Значение меньше допустимого';
            },
          }}
        />
        {commonError !== '' && <p>{commonError}</p>}
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={props.submitLoading}
          />
          <Button
            title={props.submitLabel}
            type="submit"
            fullWidth
            disabled={props.submitLoading}
          />
        </Buttons>
      </Wrapper>
    </ModalWrapper>
  );
}
