import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.header`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const Value = styled.p`
  display: flex;
  align-items: center;

  margin: 16px 0 0;
  font-weight: 600;

  color: #333;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;

    path {
      fill: #333;
    }
  }
`;
