import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Field = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  margin-left: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #000;

  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;
