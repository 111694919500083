import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {Id} from '../../../types/auxiliary.types';
import {StructureList} from '../../layouts/StructureList/StructureList';
import {Filter} from './components/Filter/Filter';

import {useTeams} from './TeamsScreen.hooks';

export function TeamsScreen() {
  useDocumentTitle('Отряды');

  const {
    loading,
    data,
    activeTab,
    handleSwitchTab,
    handleSwitchPage,
  } = useTeams();
  const navigate = useNavigate();

  function renderFilter() {
    return <Filter  />;
  }

  function handleCardClick(teamId: Id) {
    if (activeTab === 'squads') {
      navigate(`/teams/${teamId}`);
    }
    if (activeTab === 'departments') {
      navigate(`/departments/${teamId}`);
    }
  }

  const list = data != null && data.teams != null ? data.teams.data : [];
  const paginatorInfo =
    data != null && data.teams != null
      ? data.teams.paginatorInfo
      : {
          currentPage: 0,
          lastPage: 0,
        };

  return (
    <StructureList
      activeTab={activeTab ?? ''}
      tabs={[
        {
          id: 'squads',
          label: 'Отряды',
        },
        {
          id: 'departments',
          label: 'Ведомства',
        },
      ]}
      items={list.map((item) => ({
        id: item.id,
        title: item.name,
        labels: [
          {
            id: 'groups',
            name: 'Группы',
            count: item.groupCount,
          },
          {
            id: 'members',
            name: 'Участники',
            count: item.userCount,
          },
        ],
      }))}
      pagination={{
        page: paginatorInfo.currentPage,
        total: paginatorInfo.lastPage,
        onChange: handleSwitchPage,
      }}
      loading={loading}
      renderFilterComponent={renderFilter}
      onCardClick={handleCardClick}
      onSwitchTab={handleSwitchTab}
    />
  );
}
