import React, {useMemo} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import RegExpConstants from '../../../../../../constants/regExp.constants';
import DateField from '../../../../../elements/DateField/DateField';
import {Label} from '../../../../../elements/Label/Label';
import {ListFilter} from '../../../../../elements/ListFilter/ListFilter';
import SelectField from '../../../../../elements/SelectField/SelectField';
import SwitchField from '../../../../../elements/SwitchField/SwitchField';
import {TextField} from '../../../../../elements/TextField/TextField';
import {useAction, useDict} from './hooks';
import {
  FieldWrapper,
  Fields,
  Form,
  PrimaryColumn,
  SecondaryField,
  Wrapper,
} from './styles';
import {FormFields} from './types';

const InitState = {
  search: '',
  region: '',
  type: '',
  isUrgently: false,
  status: '',
  dateFrom: undefined,
  dateTo: undefined,
  phone: '',
  application: '',
};

interface Props {
  callsCount: number;
}

export function Filter(props: Props) {
  const currentDate = useMemo(() => new Date(), []);
  const [search] = useSearchParams();

  const {loading, regions, types, statuses} = useDict();

  const {submit, handleReset} = useAction();

  const {control, register, reset, handleSubmit, formState} =
    useForm<FormFields>({
      defaultValues: {
        search: search.get('search') || InitState.search,
        region: search.get('region') || InitState.region,
        type: search.get('type') || InitState.type,
        isUrgently: (search.get('isUrgently') ||
          InitState.isUrgently) as boolean,
        status: search.get('status') || InitState.status,
        dateFrom:
          search.get('dateFrom') == null
            ? InitState.dateFrom
            : new Date(
                (search.get('dateFrom') as string)
                  .split('.')
                  .reverse()
                  .join('.')
              ),
        dateTo:
          search.get('dateTo') == null
            ? InitState.dateTo
            : new Date(
                (search.get('dateTo') as string).split('.').reverse().join('.')
              ),
        phone: search.get('phone') || InitState.phone,
        application: search.get('application') || InitState.application,
      },
      reValidateMode: 'onSubmit',
      mode: 'onSubmit',
    });

  const fields = useWatch<FormFields>({
    control: control,
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset={Object.values(fields).some(
          (f) => f !== '' && f != null && f !== false
        )}
        submitLabelButton="Показать звонки"
        count={{
          amount: props.callsCount,
          words: ['звонок', 'звонка', 'звонков'],
        }}>
        <Wrapper>
          <PrimaryColumn>
            <SelectField
              name="region"
              placeholder="Выберите регион"
              options={regions}
              hasFilter
              label="Регион"
              control={control}
              disabled={loading}
            />
            <SelectField
              name="type"
              placeholder="Выберите"
              hasFilter
              options={types}
              label="Тип"
              control={control}
              disabled={loading}
            />
            <SelectField
              name="status"
              hasFilter
              placeholder="Выберите"
              options={statuses}
              label="Статус"
              control={control}
              disabled={loading}
            />
            <FieldWrapper>
              <Label label="Даты создания от и до" />
              <Fields>
                <DateField
                  name="dateFrom"
                  maxDate={
                    fields.dateTo != null
                      ? new Date(fields.dateTo)
                      : currentDate
                  }
                  control={control}
                  format="dd.MM.yyyy"
                />
                <DateField
                  name="dateTo"
                  format="dd.MM.yyyy"
                  maxDate={currentDate}
                  minDate={
                    fields.dateFrom == null
                      ? undefined
                      : new Date(fields.dateFrom)
                  }
                  control={control}
                />
              </Fields>
            </FieldWrapper>
            <TextField
              register={register('phone', {
                pattern: {
                  value: RegExpConstants.phone,
                  message: 'Некорректный формат',
                },
              })}
              mask="+7(999) 999 99 99"
              error={formState.errors.phone}
              label="Номер телефона"
              placeholder="+ _ ___ ___ __ __"
            />
            <TextField
              register={register('application')}
              label="Номер заявки"
              placeholder="Введите"
            />
          </PrimaryColumn>
          <SecondaryField>
            <SwitchField name="isUrgently" control={control} label="Срочные" />
          </SecondaryField>
        </Wrapper>
      </ListFilter>
    </Form>
  );
}
