import styled from "styled-components";

export const Wrapper = styled.article`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const SecondaryInfo = styled.div`
  flex: auto;
`;

export const Name = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;

export const Region = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);
`;

export const DateWrapper = styled.div`
  width: 140px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const StatusWrapper = styled.div`
  width: 120px;

  > * {
    width: fit-content;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
