import {useQuery} from '@apollo/client';
import {useState} from 'react';
import {PROFILE_TASKS_QUERY} from '../../../../../gql';
import { ProfileTask } from '../../../../../gql/profile/queries/__generated__/ProfileTask';
import {Id} from '../../../../../types/auxiliary.types';

export function useTasks() {
  const [sendId, setSendId] = useState<Id | null>(null)

  const {data, loading, refetch} = useQuery<ProfileTask>(PROFILE_TASKS_QUERY, {
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    modalMeta: sendId == null ? undefined : {
      id: sendId,
      type: "task" as "task",
      onSendSuccess: refetch,
      onResetMeta: () => setSendId(null)
    },
    data: data?.me.tasks == null ? [] : data.me.tasks.map((t) => ({
      number: t?.id || 0,
      isUrgent: Boolean(t?.is_urgent),
      description: t?.description || "",
      region: t?.region?.name || "",
      createAt: t?.created_at == null ? null : new Date(t.created_at),
      statusColor: t?.status?.color || "transparent",
      status: t?.status?.name || "",
      loading: loading,
      onSendData: () => setSendId(t?.id || 0)
    }))
  }
}
