import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  flex: auto;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
