import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {Text} from '../../elements/Text/Text';
import {useSignInHandler} from './hooks/useSignInHandler';
import {AuthFormStyled, ButtonStyled} from './SignInScreen.styles';

export const SignInScreen = () => {
  useDocumentTitle('Авторизация');

  const submitHandler = useSignInHandler();
  const navigate = useNavigate();

  return (
    <>
      <AuthFormStyled onSubmit={submitHandler} />
      <Text tag={'p'} variant={'p1'}>
        Еще нет аккаунта?
      </Text>
      <ButtonStyled
        title={'Зарегистрироваться'}
        variant={'outlined'}
        fullWidth
        contrast
        onClick={() => navigate('/sign-up')}
      />
    </>
  );
};
