import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useState} from 'react';

import {useParams} from 'react-router-dom';
import {
  APPLICATION_DELETE_VERIFICATION_MUTATION,
  APPLICATION_VERIFICATIONS_QUERY,
} from '../../../../../../gql';
import {
  DeleteVerification,
  DeleteVerificationVariables,
} from '../../../../../../gql/application/mutations/__generated__/DeleteVerification';
import {
  Verifications,
  VerificationsVariables,
} from '../../../../../../gql/application/queries/__generated__/Verifications';
import {Id} from '../../../../../../types/auxiliary.types';

import {Card} from './types';

export function useVerifications() {
  const params = useParams<{applicationId?: string}>();
  const {data, loading, fetchMore} = useQuery<
    Verifications,
    VerificationsVariables
  >(APPLICATION_VERIFICATIONS_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null || params.applicationId === '',
  });

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.applicationId || '').toString(),
      },
    });
  }, [fetchMore, params.applicationId]);

  return {
    data:
      data?.issue?.verifications == null
        ? []
        : (data?.issue?.verifications.map((v) => ({
            id: v?.id,
            team: v?.team?.name,
            author: {
              avatar: undefined,
              fullName: v?.user.profile.full_name,
            },
            createAt: new Date(v?.created_at || ''),
            editAt: new Date(v?.updated_at || ''),
            description: v?.comment || '',
          })) as Card[]),
    loading,
    fetch,
  };
}

export function useActions(onChangeSuccess?: () => void) {
  const [loading, setLoading] = useState(false);

  const [deleteVerification] = useMutation<
    DeleteVerification,
    DeleteVerificationVariables
  >(APPLICATION_DELETE_VERIFICATION_MUTATION);

  function handleDeleteVerification(id: Id) {
    setLoading(true);

    deleteVerification({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        if (onChangeSuccess != null) {
          new Promise(onChangeSuccess).then(() => setLoading(false));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    loading,
    handleDeleteVerification,
  };
}
