import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px 0;

  flex: auto;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  color: #333;

  margin: 20px 0 34px;
`;

export const ContentWrapper = styled.div`
  flex: auto;

  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (max-width: 800px) {
    flex-direction: row-reverse;
  }
`;

export const Primary = styled.section`
  width: 100%;
`;

interface ISecondary {
  shouldHideBigSize?: boolean;
}

export const Secondary = styled.section<ISecondary>`
  max-width: 336px;
  margin-left: 24px;
  width: 100%;
  display: ${(p) => (p.shouldHideBigSize ? 'none' : 'block')};
  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-left: 0px;
    display: ${(p) => (p.shouldHideBigSize ? 'block' : 'none')};
    margin-bottom: 20px;
    margin-top: 1rem;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
