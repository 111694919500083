import {useQuery} from '@apollo/client';
import {useState} from 'react';
import {LocationType} from '../../../../../constants/location.constants';
import {PROFILE_ISSUES_QUERY} from '../../../../../gql';
import {ProfileIssue} from '../../../../../gql/profile/queries/__generated__/ProfileIssue';
import {Id} from '../../../../../types/auxiliary.types';
import {getFullName_dto} from '../../../../../utils/String.utils';

export function useIssue() {
  const [sendId, setSendId] = useState<Id | null>(null);

  const {data, loading, refetch} = useQuery<ProfileIssue>(
    PROFILE_ISSUES_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  return {
    loading,
    modalMeta:
      sendId == null
        ? undefined
        : {
            id: sendId,
            type: 'issue' as 'issue',
            onSendSuccess: refetch,
            onResetMeta: () => setSendId(null),
          },
    data:
      data?.me.issues == null
        ? []
        : data.me.issues.map((i) => ({
            number: i?.id || 0,
            isUrgently: Boolean(i?.is_emergency),
            forestInTouch: Boolean(i?.is_forest_online),
            location: (i?.searchLocation?.key ?? undefined) as
              | LocationType
              | undefined,
            area: i?.region?.name || '',
            missing:
              i?.missing == null
                ? []
                : i.missing.map((m) => ({
                    id: m?.id || '',
                    status: {
                      name: m?.status?.name || '',
                      color: m?.status?.color || '',
                    },
                    fullName: getFullName_dto({
                      first_name: m?.first_name,
                      last_name: m?.last_name,
                      second_name: m?.second_name,
                    }),
                    gender: m?.sex === 'male' ? 'm' : ('w' as 'm' | 'w'),
                    birthday:
                      m?.birthday_string == null
                        ? undefined
                        : m.birthday_string,
                  })),
            lossDate: i?.date_of_loss == null ? null : new Date(i.date_of_loss),
            ringingNumber:
              i?.callingCount == null
                ? undefined
                : {
                    count: i?.callingCount,
                  },
            tasksNumber:
              i?.taskCount == null
                ? undefined
                : {
                    count: i?.taskCount,
                  },
            callsNumber:
              i?.callCount == null
                ? undefined
                : {
                    count: i?.callCount,
                  },
            lettersServicesNumber:
              i?.letterCount == null
                ? undefined
                : {
                    count: i?.letterCount,
                  },
            commentNumber: i?.commentCount ?? undefined,
            onSendData: () => setSendId(i?.id || 0),
          })),
  };
}
