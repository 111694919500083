import {SvgStyled} from './Icons.styles';

export const ArrowIcon = () => {
  return (
    <SvgStyled
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 10H3M3 10L11.5 1.5M3 10L11.5 18.5"
        stroke="#376092"
        strokeWidth="3"
      />
    </SvgStyled>
  );
};
