import React from 'react';
import {Id} from '../../../../../../../types/auxiliary.types';
import {Missing} from '../Missing/Missing';
import {useHandler} from './NewMissing.hooks';

interface Props {
  applicantId: Id;
  onClose: () => void;
  onSuccess?: () => void;
}

export function NewMissing(props: Props) {
  const {handleSubmit} = useHandler();

  return (
    <Missing
      title="Добавление пропавшего"
      onClose={props.onClose}
      onSave={(d, error) =>
        handleSubmit(props.applicantId, d, error, () => {
          props.onSuccess?.();
          props.onClose();
        })
      }
    />
  );
}
