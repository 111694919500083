import { useMutation, useQuery} from '@apollo/client';
import {useState} from 'react';
import {
  PROFILE_SEND_ITEM_DICT_QUERY,
  PROFILE_SEND_ITEM_MUTATION,
} from '../../../../../../gql';
import {
  ProfileSendItem,
  ProfileSendItemVariables,
} from '../../../../../../gql/profile/mutations/__generated__/ProfileSendItem';
import {Id} from '../../../../../../types/auxiliary.types';
import {Option} from '../../../../../elements/Select/Select.types';
import {ProfileSendItemDict} from '../../../../../../gql/profile/queries/__generated__/ProfileSendItemDict';

import {FormFields} from './types';
import { toast } from 'react-toastify';

export function useDict() {
  const {data, loading} = useQuery<ProfileSendItemDict>(
    PROFILE_SEND_ITEM_DICT_QUERY
  );

  return {
    loading,
    groups:
      data?.me?.groups == null
        ? {}
        : data.me.groups.reduce(
            (acc1: Record<Id, Option & {users: Option[]}>, group) => ({
              ...acc1,
              [group?.id || '']: {
                value: group?.id || '',
                label: group?.full_name || '',
                users:
                  group?.users?.map((user) => ({
                    value: user?.id || '',
                    label: user?.profile.full_name || '',
                  })) || [],
              },
            }),
            {}
          ) || {},
  };
}

interface UseActions {
  meta: {
    id: Id;
    type: 'issue' | 'call' | 'task';
  };
  onSendSuccess?: () => void;
}

export function useActions(params: UseActions) {
  const [loading, setLoading] = useState(false);
  const [commonMessage, setCommonMessage] = useState('');

  const [send] = useMutation<ProfileSendItem, ProfileSendItemVariables>(
    PROFILE_SEND_ITEM_MUTATION
  );

  function handleSend(data: FormFields) {
    setLoading(true);

    send({
      variables: {
        input: {
          id: Number(params.meta.id),
          type: params.meta.type,
          groupId: Number(data.groupId),
          userId: Number(data.userId),
          comment: data.description,
        },
      },
    })
      .then(() => {
        setCommonMessage('');
        params.onSendSuccess?.();
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      })
      .finally(() => setLoading(false));
  }

  return {
    loading,
    commonMessage,
    handleSend,
  };
}
