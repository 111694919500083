import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Card = styled.article`
  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  padding: 24px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h3`
  margin: 0 0 16px;
  flex: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333;

  @media screen and (max-width: 800px) {
    margin: 0;
  }
`;

export const Description = styled.p`
  margin: 16px 0 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;
