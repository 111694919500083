import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;

  z-index: 999;
`;

interface IContainer {
  size?: 'default' | 'big';
}

export const Container = styled.div<IContainer>`
  margin-top: 8%;
  margin-bottom: 8%;

  max-width: ${(p) => (p.size === 'big' ? 1062 : 472)}px;
  background-color: #fff;

  width: inherit;
  height: fit-content;
  max-height: calc(100vh - 16%);
  display: flex;
  flex-direction: column;

  filter: drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.06));

  border-radius: 8px;

  overflow: hidden;
`;

export const Header = styled.header`
  background: #e7e7e7;
  padding: 20px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Heading = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.33;

  color: #333;
`;

export const CloseButton = styled.button`
  width: 14px;
  height: 14px;
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);

    path {
      stroke: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Content = styled.div`
  padding: 24px;

  flex: auto;

  display: flex;
  flex-direction: column;

  height: auto;

  overflow-y: auto;
`;
