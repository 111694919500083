import {useQuery} from '@apollo/client';

import {useNavigate, useParams} from 'react-router-dom';
import {APPLICATION_TASKS_QUERY} from '../../../../../../gql';
import {
  ApplicationTask,
  ApplicationTaskVariables,
} from '../../../../../../gql/application/queries/__generated__/ApplicationTask';

export function useTasks() {
  const params = useParams<{applicationId?: string}>();
  const {data, loading} = useQuery<ApplicationTask, ApplicationTaskVariables>(
    APPLICATION_TASKS_QUERY,
    {
      variables: {
        id: (params.applicationId || '').toString(),
      },
      skip: params.applicationId == null || params.applicationId === '',
    }
  );

  return {
    data:
      data?.issue?.tasks == null
        ? []
        : data.issue.tasks.map((t) => ({
            number: t?.id || '',
            isUrgent: Boolean(t?.is_urgent),
            description: t?.description || '',
            region: t?.region?.name || '',
            createAt: t?.created_at == null ? null : new Date(t.created_at),
            avatarUrl: undefined,
            fullNameResponsible: t?.responsible?.profile.full_name || '',
            status: t?.status?.name || '',
            statusColor: t?.status?.color || 'transparent',
            result: t?.result || undefined,
          })),
    loading,
  };
}

export function useActions() {
  const params = useParams<{applicationId: string}>();

  const navigate = useNavigate();

  function handleAdd() {
    navigate(`/tasks/new?applicationId=${params.applicationId}`);
  }

  return {
    handleAdd,
  };
}
