import React from 'react';
import {LabelContainer, LabelText} from './Label.styles';

export type LabelProps = {
  label?: string;
  className?: string;
  isInvalid?: boolean;
  children?: any;
};

export function Label({isInvalid, label, className, children}: LabelProps) {
  return (
    <LabelContainer className={className}>
      {!!label && <LabelText isInvalid={isInvalid}>{label}</LabelText>}
      {children}
    </LabelContainer>
  );
}
