import React from 'react';
import {SvgStyled} from '../Icons.styles';

const QuestionnairesIcon = () => {
  return (
    <SvgStyled
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M32.4375 29.9062C31.9198 29.9062 31.5 30.326 31.5 30.8438V37.3125C31.5 39.8972 29.3972 42 26.8125 42H16.6905C16.1729 42 15.7534 42.4193 15.753 42.9368L15.7517 44.8008L13.5141 42.5632C13.3692 42.2307 13.0377 42 12.6543 42H6.5625C3.97781 42 1.875 39.8972 1.875 37.3125V22.875C1.875 20.2903 3.97781 18.1875 6.5625 18.1875H16.0312C16.5489 18.1875 16.9688 17.7678 16.9688 17.25C16.9688 16.7322 16.5489 16.3125 16.0312 16.3125H6.5625C2.94394 16.3125 0 19.2564 0 22.875V37.3125C0 40.9311 2.94394 43.875 6.5625 43.875H12.1742L16.0246 47.7254C16.2039 47.9048 16.4437 48 16.6877 48C16.8083 48 16.9301 47.9767 17.0459 47.9287C17.3962 47.7838 17.6247 47.4422 17.625 47.0632L17.6273 43.875H26.8125C30.4311 43.875 33.375 40.9311 33.375 37.3125V30.8438C33.375 30.326 32.9552 29.9062 32.4375 29.9062Z" />
      <path d="M34.3739 0H32.0012C24.4877 0 18.375 6.11269 18.375 13.6262C18.375 21.1397 24.4877 27.2523 32.0011 27.2523H34.3738C35.5465 27.2523 36.7073 27.1033 37.8323 26.8089L41.3373 30.312C41.5166 30.4912 41.7563 30.5864 42.0002 30.5864C42.121 30.5864 42.2429 30.5631 42.3589 30.515C42.7091 30.3698 42.9375 30.028 42.9375 29.6489V24.2249C44.4215 23.0231 45.6542 21.505 46.5193 19.8091C47.5019 17.8829 48 15.8027 48 13.6262C48 6.11269 41.8873 0 34.3739 0ZM41.4359 23.0189C41.2009 23.196 41.0625 23.4733 41.0625 23.7678V27.3865L38.7683 25.0936C38.5898 24.9153 38.3504 24.8192 38.1055 24.8192C38.0137 24.8192 37.9208 24.8328 37.8303 24.8606C36.7144 25.2036 35.5515 25.3774 34.3739 25.3774H32.0012C25.5215 25.3774 20.25 20.1058 20.25 13.6262C20.25 7.14656 25.5216 1.875 32.0012 1.875H34.3739C40.8535 1.875 46.125 7.14656 46.125 13.6262C46.125 17.351 44.4159 20.7745 41.4359 23.0189Z" />
      <path d="M37.4094 10.2582C37.2741 8.2955 35.692 6.71338 33.7292 6.57809C32.6166 6.50169 31.5548 6.87697 30.7421 7.63569C29.9405 8.38391 29.4808 9.4416 29.4808 10.5376C29.4808 11.0554 29.9006 11.4751 30.4183 11.4751C30.936 11.4751 31.3558 11.0554 31.3558 10.5376C31.3558 9.95085 31.5923 9.407 32.0215 9.00641C32.4503 8.60619 33.0107 8.40809 33.6004 8.44878C34.6343 8.52003 35.4676 9.35338 35.5389 10.3872C35.6109 11.4313 34.9258 12.3539 33.9101 12.5812C33.0872 12.7653 32.5125 13.4804 32.5125 14.3202V16.5725C32.5125 17.0903 32.9323 17.51 33.45 17.51C33.9678 17.51 34.3875 17.0903 34.3874 16.5725V14.3951C36.2765 13.9387 37.5439 12.2101 37.4094 10.2582Z" />
      <path d="M34.1128 19.6184C33.9384 19.4441 33.6965 19.3438 33.45 19.3438C33.2034 19.3438 32.9615 19.4441 32.7871 19.6184C32.6128 19.7928 32.5125 20.0347 32.5125 20.2812C32.5125 20.5288 32.6129 20.7706 32.7871 20.945C32.9615 21.1194 33.2034 21.2188 33.45 21.2188C33.6965 21.2188 33.9384 21.1194 34.1128 20.945C34.2871 20.7697 34.3875 20.5288 34.3875 20.2812C34.3875 20.0347 34.287 19.7928 34.1128 19.6184Z" />
      <path d="M25.7812 29.0625H6C5.48231 29.0625 5.0625 29.4822 5.0625 30C5.0625 30.5178 5.48231 30.9375 6 30.9375H25.7812C26.299 30.9375 26.7188 30.5178 26.7188 30C26.7188 29.4822 26.2989 29.0625 25.7812 29.0625Z" />
      <path d="M26.444 34.5872C26.2697 34.4128 26.0278 34.3125 25.7812 34.3125C25.5347 34.3125 25.2928 34.4128 25.1184 34.5872C24.944 34.7616 24.8438 35.0034 24.8438 35.25C24.8438 35.4966 24.9441 35.7384 25.1184 35.9128C25.2928 36.0872 25.5347 36.1875 25.7812 36.1875C26.0278 36.1875 26.2697 36.0872 26.444 35.9128C26.6184 35.7384 26.7188 35.4975 26.7188 35.25C26.7188 35.0034 26.6183 34.7616 26.444 34.5872Z" />
      <path d="M22.0938 34.3125H6C5.48231 34.3125 5.0625 34.7322 5.0625 35.25C5.0625 35.7678 5.48231 36.1875 6 36.1875H22.0938C22.6116 36.1875 23.0313 35.7678 23.0313 35.25C23.0313 34.7322 22.6115 34.3125 22.0938 34.3125Z" />
      <path d="M19.6875 23.8125H6C5.48231 23.8125 5.0625 24.2322 5.0625 24.75C5.0625 25.2678 5.48231 25.6875 6 25.6875H19.6875C20.2053 25.6875 20.625 25.2678 20.625 24.75C20.625 24.2322 20.2052 23.8125 19.6875 23.8125Z" />
    </SvgStyled>
  );
};

export default QuestionnairesIcon;
