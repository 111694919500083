import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  APPLICATION_CREATE_MISSING_TELEGRAM,
  APPLICATION_DISPATCH_LIST,
  APPLICATION_MISSINGS_LIST,
} from '../../../../../../gql';
import {
  Telegram,
  TelegramVariables,
} from '../../../../../../gql/application/mutations/__generated__/Telegram';
import {
  DispatchList,
  DispatchListVariables,
} from '../../../../../../gql/application/queries/__generated__/DispatchList';
import {
  MissingsList,
  MissingsListVariables,
} from '../../../../../../gql/application/queries/__generated__/MissingsList';
import {TelegramTypes} from './telegram.types';

export function useTelegram(onModalClose?: () => void) {
  const [create] = useMutation<Telegram, TelegramVariables>(
    APPLICATION_CREATE_MISSING_TELEGRAM
  );

  const submit = useCallback(
    async (data: TelegramTypes) =>
      await create({
        variables: {
          input: {
            missingIds: data.missingIds,
            dispatchId: data.dispatchId,
            note: data.note,
          },
        },
      })
        .then(() => {
          toast('Письмо успешно отправлено');
          onModalClose?.();
        })
        .catch((e) => toast(e.message, {type: 'error'})),
    [create, onModalClose]
  );
  return {submit};
}

export function useMissings() {
  const params = useParams<{applicationId: string}>();

  const {data: missingList, loading} = useQuery<
    MissingsList,
    MissingsListVariables
  >(APPLICATION_MISSINGS_LIST, {
    variables: {
      id: params.applicationId as string,
    },
  });

  const {data: dispatchList} = useQuery<DispatchList, DispatchListVariables>(
    APPLICATION_DISPATCH_LIST,
    {
      variables: {
        id: params.applicationId as string,
      },
    }
  );

  return {
    loading,
    dispatch:
      dispatchList?.issue.teams == null
        ? []
        : dispatchList.issue.teams[0]?.telegramDispatches,
    missingList:
      missingList?.issue.missing == null ? [] : missingList.issue.missing,
  };
}
