import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 312px;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ForgotPasswordLink = styled.a`
  display: block;
  width: 100%;
  margin: 0 10px 0 10px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-color);
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
`;
