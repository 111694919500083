import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
`;

interface IMenuItem {
  visible: boolean;
}

export const FullMenuWrapper = styled.div<IMenuItem>`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transform: translateX(${(p) => (p.visible ? 0 : -350)}px);
  transition: all 500ms linear;
`;

interface IMenuItem {
  visible: boolean;
}

export const ShortMenuWrapper = styled.div<IMenuItem>`
  transform: translateX(${(p) => (p.visible ? 0 : -350)}px);
  transition: all 500ms linear;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
