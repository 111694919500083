import {SvgStyled} from './Icons.styles';

export const CorrectMark = () => {
  return (
    <SvgStyled
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.18945 5.92734L5.40118 8.95446L11.3105 3.04492"
        stroke="#79C077"
        strokeWidth="2"
      />
    </SvgStyled>
  );
};
