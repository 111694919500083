import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: block;
  height: 2rem;
`;

export const ErrorMessageText = styled.span`
  color: var(--warning-color);
  font-size: 0.75rem;
`;
