import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ArrowIcon} from '../../../../icons/ArrowIcon';
import {MenuConfig} from '../../Menu.types';
import {
  BackButtonWrapper,
  Button,
  MenuContainer,
  MenuItem,
  Wrapper,
} from './ShortMenu.styles';

interface Props extends MenuConfig {
  activeTab: string;
}

export function ShortMenu(props: Props) {
  const [nested, setNested] = useState(false);

  const navigate = useNavigate();

  const {pathname} = useLocation();

  useEffect(() => {
    if (pathname.match(/\/\w+\/\w+/)) {
      setNested(true);
    } else {
      setNested(false);
    }
  }, [pathname, nested]);

  const mainPathname: RegExpMatchArray | null = pathname.match(/\/\w+/);

  const $pathname: string | undefined = mainPathname?.[0];

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate($pathname ? $pathname : '/');
    } else {
      navigate('/', {replace: true});
    }
  };

  return (
    <Wrapper>
      {nested && (
        <BackButtonWrapper>
          <Button title={'Назад'} onClick={handleBack}>
            <ArrowIcon />
          </Button>
        </BackButtonWrapper>
      )}
      <MenuContainer>
        {props.items.map((item) => {
          const Icon = item.icon;

          return (
            <MenuItem
              key={item.id}
              title={item.name}
              active={item.id === props.activeTab}>
              <Link to={item.linkTo}>
                <Icon />
              </Link>
            </MenuItem>
          );
        })}
      </MenuContainer>
    </Wrapper>
  );
}
