import React from 'react';
import {useForm} from 'react-hook-form';
import { Button } from '../../elements/Button/Button';
import { ModalWrapper } from '../../elements/ModalWrapper/ModalWrapper';
import { TextField } from '../../elements/TextField/TextField';
import {
  Buttons,
  Form,
  Wrapper,
} from './AddFile.styles';
import {FormFields} from './AddFile.types';

interface Props {
  file: File;
  requestSending: boolean;
  onSubmit(data: FormFields): void;
  onModalClose(): void;
}

export function AddFile(props: Props) {
 const {onSubmit} = props;

  const {handleSubmit, register, formState} = useForm({
    defaultValues: {
      file: props.file,
      name: '',
    },
    mode: 'onSubmit',
  });

  return (
    <ModalWrapper title="Добавить файл" onModalClose={props.onModalClose}>
      <Form onSubmit={handleSubmit(onSubmit)} onReset={props.onModalClose}>
        <Wrapper>
          <TextField
            label="Введите название файла"
            register={register('name', {
              required: 'Поле обязательно для заполнения.',
              maxLength: {
                value: 300,
                message: 'Превышено количество символов',
              },
            })}
            error={formState.errors.name}
            placeholder="Введите ваше сообщение"
          />
        </Wrapper>
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={props.requestSending}
          />
          <Button
            title="Добавить"
            type="submit"
            fullWidth
            disabled={props.requestSending}
          />
        </Buttons>
      </Form>
    </ModalWrapper>
  );
}
