import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {CREATE_TEAM_IMAGE_MUTATION} from '../../../../../gql';
import {CreateTeamImage, CreateTeamImageVariables} from '../../../../../gql/mutations/__generated__/CreateTeamImage';

export const useAddPhotosHandler = (fetch: () => void) => {
  const [requestSending, setRequestSending] = useState(false)

  const [addPhotoMutation] = useMutation<CreateTeamImage, CreateTeamImageVariables>(
    CREATE_TEAM_IMAGE_MUTATION
  );

  const handleAddPhoto = useCallback(
    async (data: {
      file: File,
      description: string
    }) => {
      setRequestSending(true)

      await addPhotoMutation({
        variables: {
          input: {
            file: data.file,
            name: data.description
          },
        }
      }).then(() => fetch())
        .finally(() => setRequestSending(false))
    },
    [addPhotoMutation, fetch]
  );

  return {handleAddPhoto, requestSending}
};
