import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {LOGOUT_MUTATION} from '../gql';
import {Logout, Logout_logout} from '../gql/mutations/__generated__/Logout';

export const useLogoutHandler = () => {
  const navigate = useNavigate();
  const [logOut] = useMutation<Logout, Logout_logout>(LOGOUT_MUTATION);
  const onExit = async () => {
    const result = await logOut();
    try {
      if (result.data?.logout?.success) {
        await navigate('/sign-in');
      }
    } catch (e) {
      // console.log(e.message);
    }
  };
  return useCallback(onExit, [logOut, navigate]);
};
