import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px;

  flex: auto;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-right: 24px;
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 16px;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const ContentItem = styled.div`
  margin-top: 1rem;
`;
