import React from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {ListFilter} from '../../../../../elements/ListFilter/ListFilter';
import SelectField from '../../../../../elements/SelectField/SelectField';
import {useAction, useDict} from './hooks';
import {Form, Wrapper} from './styles';
import {FormFields} from './types';

const InitState = {
  search: '',
  regionId: '',
  teamId: '',
  groupId: '',
};

interface Props {
  usersCount: number;
}

export default function Filter(props: Props) {
  const [search] = useSearchParams();

  const {loading, regions, teams} = useDict();

  const {submit, handleReset} = useAction();

  const {control, register, reset, handleSubmit, setValue} =
    useForm<FormFields>({
      defaultValues: {
        search: search.get('search') || InitState.search,
        regionId: search.get('regionId') || InitState.regionId,
        teamId: search.get('teamId') || InitState.teamId,
        groupId: search.get('groupId') || InitState.groupId,
      },
      reValidateMode: 'onSubmit',
      mode: 'onSubmit',
    });

  const fields = useWatch<FormFields>({
    control: control,
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset={Object.values(fields).some((f) => f !== '' && f != null)}
        submitLabelButton="Показать"
        count={{
          amount: props.usersCount,
          words: ['пользователь', 'пользователя', 'пользователей'],
        }}
        placeholder="Поиск по ФИО или телефоному номеру">
        <Wrapper>
          <SelectField
            name="regionId"
            placeholder="Выберите"
            options={regions}
            label="Регион"
            hasFilter
            control={control}
            disabled={loading}
          />
          <SelectField
            name="teamId"
            placeholder="Выберите"
            options={Object.values(teams)}
            label="Отряд"
            hasFilter
            control={control}
            disabled={loading}
            onCallbackChange={() => setValue('groupId', '')}
          />
          <SelectField
            name="groupId"
            placeholder="Выберите"
            options={
              fields.teamId == null ||
              fields.teamId === '' ||
              teams[fields.teamId] == null
                ? []
                : Object.values(teams[fields.teamId].groups)
            }
            label="Группа"
            control={control}
            hasFilter
            disabled={loading || fields.teamId === '' || fields.teamId == null}
          />
        </Wrapper>
      </ListFilter>
    </Form>
  );
}
