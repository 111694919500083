import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {TEAM_PARTICIPANTS_QUERY} from '../../../../../gql';
import {
  TeamParticipants,
  TeamParticipantsVariables,
} from '../../../../../gql/queries/team/__generated__/TeamParticipants';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading} = useQuery<
    TeamParticipants,
    TeamParticipantsVariables
  >(TEAM_PARTICIPANTS_QUERY, {
    variables: {
      id: (params.teamId || '').toString(),
    },
    skip: params.teamId == null || params.teamId === '',
  });

  return {
    data,
    loading,
  };
}
