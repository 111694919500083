import React, {useState} from 'react';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {TextField} from '../../../../elements/TextField/TextField';
import {BlockWrapper} from '../../../../elements/BlockWrapper/BlockWrapper';
import {Control, UseFormRegister, useFormState} from 'react-hook-form';
import {Step2FormFields as FormFields} from '../../types';
import {GridFields, Wrapper} from './ApplicantInfo.styles';
import Checkbox from '../../../RegistrationFrom/components/Checkbox/Checkbox';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
}

export function ApplicantInfo(props: Props) {
  const {errors} = useFormState({
    control: props.control,
  });

  const [value, setValue] = useState(true);

  return (
    <BlockWrapper title="Заявитель" shouldOpen>
      <Wrapper>
        <GridFields>
          <TextField
            register={props.register('lastName', {
              required: 'Поле обязательно для заполнения.',
              pattern: {
                value: RegExpConstants.fio,
                message: 'Некорректный формат',
              },
            })}
            error={errors.lastName}
            label="ФИО*"
            placeholder="Введите"
          />
          <TextField
            register={props.register('relationDegree', {
              required: 'Поле обязательно для заполнения.',
            })}
            error={errors.relationDegree}
            label="Степень родства*"
            placeholder="Введите"
          />
          <TextField
            register={props.register('phone', {
              required: value ? 'Поле обязательно для заполнения!' : false,
              pattern: {
                value: RegExpConstants.phone,
                message: 'Некорректный формат',
              },
            })}
            mask="+7(999) 999 99 99"
            error={errors.phone}
            label="Телефон заявителя*"
            placeholder="Введите"
          />
          <div onClick={() => setValue(!value)}>
            <Checkbox name={'is112'} control={props.control} label={'112'} />
          </div>
        </GridFields>
        <TextField
          register={props.register('additionalContact')}
          label="Дополнительные контакты"
          placeholder="Email, telegram, whatsapp"
          error={errors.additionalContact}
        />
        <TextField
          register={props.register('police')}
          label="Заявление в полицию"
          placeholder="Октябрьский УВД, 4857366"
          error={errors.police}
        />
        <TextField
          register={props.register('MES')}
          label="МЧС, ПСО, 112"
          placeholder="Октябрьский УВД, 4857366"
          error={errors.MES}
        />
      </Wrapper>
    </BlockWrapper>
  );
}
