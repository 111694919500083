import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 336px;
  @media screen and (max-width: 950px) {
    max-width:100%;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 25px;
`;

export const Section = styled.div`
  padding: 24px 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid #D8E2ED
  }  
  
  &:first-child {
    padding-top: 0
  }
  
  &:last-child {
    padding-bottom: 0
  }
`;
