import styled from 'styled-components';

export const Wrapper = styled.section``;

export const Title = styled.h3`
  margin: 0 0 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  color: var(--text-color);
`;

export const Content = styled.div``;
