import {useMemo, useState} from 'react';
import {ChevronIcon} from '../../icons/ChevronIcon';
import {IGroupStatusItem, IStatusItem} from './type';

import {
  Options,
  Wrapper,
  Option,
  OptionsContainer,
  Label,
  Group,
  TitleGroup,
  StatusColor,
} from './Status.styles';
import {Id} from '../../../types/auxiliary.types';
import {useDropdownRefs} from '../../../hooks/htmlElement.hooks';

export interface Props {
  id: Id | null;
  options: (IStatusItem | IGroupStatusItem)[];
  onChange(id: Id): void;
  isStatus?: boolean;
}

export default function Status(props: Props) {
  const {isOpen, setIsOpen, elementRef, dropdownRef} = useDropdownRefs<
    HTMLDivElement,
    HTMLDivElement
  >();

  function handleSelectOption(selectedId: Id) {
    props.onChange(selectedId);
    setIsOpen(false);
  }

  const currentOption = (
    props.options as (IGroupStatusItem | IStatusItem)[]
  ).reduce(
    (acc: IStatusItem | undefined, curr: IGroupStatusItem | IStatusItem) =>
      acc != null
        ? acc
        : 'statuses' in curr
        ? curr.statuses.find((o: IStatusItem) => o.id === props.id)
        : curr.id === props.id
        ? curr
        : undefined,
    undefined
  );

  const styles = {
    border: '1px solid var(--primary-border-color)',
    color: 'var(--primary-text-color)',
    borderRadius: '8px',
    fontSize: '14px',
    outline: 'none',
    marginTop: '1rem',
    width: '95%',
    height: '30px',
  };

  const [value, setValue] = useState('');

  const filteredArray: any = useMemo(() => {
    const statusesFind = props.options.flatMap(
      ({statuses, id, name, color}: any) => {
        return {
          id,
          name,
          color,
          statuses: statuses?.filter(({name}: any) =>
            name.toLowerCase().includes(value.toLowerCase())
          ),
        };
      }
    );

    const subStatusesFind: any = props.options.filter(({name}) =>
      name.toLowerCase().includes(value.toLowerCase())
    );

    return props.isStatus ? statusesFind : subStatusesFind;
  }, [props.isStatus, props.options, value]);

  return (
    <Wrapper>
      <Label
        ref={elementRef}
        role="button"
        bColor={currentOption?.color || 'white'}>
        {props.id == null
          ? 'Нет значений'
          : currentOption?.name || 'Нет значений'}
        <ChevronIcon />
      </Label>
      {props.options != null && (
        <OptionsContainer open={isOpen} ref={dropdownRef}>
          <Options tabIndex={-1} role="list">
            <input
              style={styles}
              placeholder={'Введите...'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {filteredArray.length !== 0 ? (
              filteredArray?.map((o: any) =>
                o['statuses'] ? (
                  <Group key={o.id}>
                    <TitleGroup>{o.name}</TitleGroup>
                    <Options>
                      {o.statuses?.map((o1: any) => (
                        <Option
                          key={o1.id}
                          bColor={o1.color}
                          onClick={() => handleSelectOption(o1.id)}>
                          {o1.name} <StatusColor bColor={o1.color} />
                        </Option>
                      ))}
                    </Options>
                  </Group>
                ) : (
                  <Option
                    key={o.id}
                    bColor={o.color}
                    onClick={() => handleSelectOption(o.id)}>
                    {o.name} <StatusColor bColor={o.color} />
                  </Option>
                )
              )
            ) : (
              <Option bColor="transparent">Список пуст!</Option>
            )}
          </Options>
        </OptionsContainer>
      )}
    </Wrapper>
  );
}
