import {useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {TEAM_PHOTOS_QUERY} from '../../../../../gql';
import {
  TeamPhotos,
  TeamPhotosVariables,
} from '../../../../../gql/queries/team/__generated__/TeamPhotos';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading, fetchMore} = useQuery<TeamPhotos, TeamPhotosVariables>(
    TEAM_PHOTOS_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip: params.teamId == null || params.teamId === '',
    }
  );

  const fetch = useCallback(
    () =>
      fetchMore({
        variables: {
          id: (params.teamId || '').toString(),
        },
      }),
    [fetchMore, params.teamId]
  );

  return {
    list: data?.team?.images == null ? [] : data.team.images.map((m) => ({
      name: m?.name || '',
      url: m?.url || '',
    })) || [],
    loading,
    fetch
  };
}
