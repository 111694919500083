import {ControllerRenderProps, FieldValues, Path} from 'react-hook-form';
import {Id} from '../../../types/auxiliary.types';

import {GroupOptionT, Option as OptionT} from './Select.types';
import {
  Options,
  SelectLabel,
  Wrapper,
  Option,
  Select as SelectStyled,
  OptionsContainer,
  FakeCheck,
  UnCheck,
  GroupWrapper,
  GroupTitle,
  OptionsDiv,
  FilterBlock,
  InputName,
} from './Select.styles';
import {useDropdownRefs} from '../../../hooks/htmlElement.hooks';
import {ChevronIcon} from '../../icons/ChevronIcon';
import {CheckMarkIcon} from '../../icons/CheckMarkIcon';
import {SearchIcon} from '../../icons/SearchIcon';
import {ReactElement, useState} from 'react';

export interface Props<T extends FieldValues> {
  id?: string;
  name: Path<T>;
  options: (OptionT | GroupOptionT)[];
  field: ControllerRenderProps;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  enumeration?: boolean;
  shouldNotBeEmpty?: boolean;
  hasFilter?: boolean;
  option?(arg: OptionT): ReactElement;
  onCallbackChange?(newValue: Id | Id[] | null): void;
}

Select.defaultProps = {
  multiple: false,
  disabled: false,
} as Partial<Props<FieldValues>>;

export default function Select<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const [valueFilter, setValueFilter] = useState('');

  const {isOpen, setIsOpen, elementRef, dropdownRef} = useDropdownRefs<
    HTMLDivElement,
    HTMLDivElement
  >();

  function handleSelectOption(selectedValue: OptionT['value']) {
    if (props.disabled) return;

    if (!Array.isArray(props.field.value)) {
      const newValue =
        props.field.value === selectedValue && !props.shouldNotBeEmpty
          ? ''
          : selectedValue;

      props.field.onChange(newValue);
      props.onCallbackChange?.(newValue);
      setIsOpen(false);

      return;
    }

    let resultValue;

    const index = (props.field.value as OptionT['value'][]).findIndex(
      (value) => value === selectedValue
    );

    if (index >= 0) {
      if (
        props.shouldNotBeEmpty &&
        (props.field.value as OptionT['value'][]).length === 1
      ) {
        return;
      }

      resultValue = (props.field.value as OptionT['value'][]).filter(
        (value) => value !== selectedValue
      );
    } else {
      resultValue = [...props.field.value, selectedValue];
    }

    props.field.onChange(resultValue);
    props.onCallbackChange?.(resultValue);
  }

  const triggerId = `${props.id || props.name}__trigger`;

  function renderValue() {
    if (
      Array.isArray(props.field.value)
        ? Object.keys(props.field.value)?.length === 0
        : props.field.value === '' || props.field.value == null
    )
      return props.placeholder;

    if (Array.isArray(props.field.value) && props.enumeration) {
      return props.field.value
        .map((value) => {
          let label: Id = '';

          props.options.some((o) => {
            if ('id' in o) {
              return o.values.some((o1) => {
                if (o1.value === value) {
                  label = `${o.label} - ${o1.label}`;
                  return true;
                }

                return false;
              });
            }

            if (o.value === value) {
              label = o.label;
              return true;
            }

            return false;
          });

          return label;
        })
        .join(', ');
    }

    if (Array.isArray(props.field.value)) {
      return `Выбрано ${props.field.value?.length}`;
    }

    let label: Id = '';

    props.options.some((o) => {
      if ('id' in o) {
        return o.values.some((o1) => {
          if (o1.value == props.field.value) {
            label = `${o.label} - ${o1.label}`;
            return true;
          }

          return false;
        });
      }

      if (o.value == props.field.value) {
        label = o.label;
        return true;
      }

      return false;
    });

    return label;
  }

  function renderOption(option: OptionT, shouldFilter = true) {
    const selected = Array.isArray(props.field.value)
      ? props.field.value.some(
          (value: OptionT['value']) => value == option.value
        )
      : props.field.value == option.value;

    const disabled =
      option.disabled ||
      (props.shouldNotBeEmpty &&
        ((Array.isArray(props.field.value) &&
          props.field.value.length === 1 &&
          selected) ||
          (!Array.isArray(props.field.value) && selected)));

    if (
      shouldFilter &&
      props.hasFilter &&
      !String(option.label)
        .toLowerCase()
        .includes(valueFilter.toString().toLowerCase())
    )
      return null;

    return (
      <Option
        key={option.value}
        selected={selected}
        title={option.label.toString()}
        role="option"
        aria-disabled={disabled}
        tabIndex={0}
        onKeyDown={(event) =>
          option.disabled || event.code !== 'Space'
            ? undefined
            : handleSelectOption(option.value)
        }
        onClick={
          option.disabled ? undefined : () => handleSelectOption(option.value)
        }>
        {props.multiple ? (
          selected ? (
            <FakeCheck>
              <CheckMarkIcon />
            </FakeCheck>
          ) : (
            <UnCheck />
          )
        ) : null}
        {props.option != null ? props.option(option) : option.label}
      </Option>
    );
  }

  function renderGroupOption(option: GroupOptionT) {
    const findInGroup =
      props.hasFilter &&
      !String(option.label)
        .toLowerCase()
        .includes(valueFilter.toString().toLowerCase());

    return (
      <GroupWrapper key={option.id}>
        <GroupTitle>{option.label}</GroupTitle>
        <OptionsDiv>
          {option.values.length > 0 ? (
            option.values.map((v) => renderOption(v, findInGroup))
          ) : (
            <Option isEmpty>"Список пуст!"</Option>
          )}
        </OptionsDiv>
      </GroupWrapper>
    );
  }

  return (
    <Wrapper
      onKeyDown={(event) =>
        event.code !== 'Space' ? undefined : setIsOpen(!isOpen)
      }>
      <SelectStyled
        id={triggerId}
        aria-disabled={props.disabled}
        ref={props.disabled ? undefined : elementRef}
        role="button"
        tabIndex={0}>
        <SelectLabel
          empty={
            Array.isArray(props.field.value)
              ? Object.keys(props.field.value)?.length === 0
              : props.field.value === '' || props.field.value == null
          }>
          {renderValue()}
        </SelectLabel>
        <ChevronIcon />
      </SelectStyled>
      {props.options != null && (
        <OptionsContainer tabIndex={-1} open={isOpen} ref={dropdownRef}>
          <Options tabIndex={-1} role="listbox">
            {props.hasFilter && (
              <Option>
                <FilterBlock>
                  <SearchIcon />
                  <InputName
                    tabIndex={0}
                    value={valueFilter}
                    onChange={(e) => setValueFilter(e.target.value)}
                    placeholder="Начните вводить имя"
                  />
                </FilterBlock>
              </Option>
            )}

            {props.options.length !== 0 ? (
              props.options.map((o) =>
                'values' in o ? renderGroupOption(o) : renderOption(o)
              )
            ) : (
              <Option isEmpty>"Список пуст!"</Option>
            )}
          </Options>
        </OptionsContainer>
      )}
    </Wrapper>
  );
}
