import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import {Switch} from '../SwitchButton/Switch';
import {Field, Wrapper, Label} from './SwitchField.styles';

interface Props<T extends FieldValues> {
  disabled?: boolean;
  name: Path<T>;
  control: Control<T>;
  label?: string | null;
  rules?: UseControllerProps['rules'];
  onCallbackChange?(): void;
}

export default function SwitchField<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const {fieldState} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper>
      <Field>
        <Switch
          control={props.control}
          name={props.name}
          disabled={props.disabled}
          onCallbackChange={props.onCallbackChange}
        />
        {props.label != null && props.label !== '' && (
          <Label htmlFor={props.name} aria-disabled={props.disabled}>
            {props.label}
          </Label>
        )}
      </Field>
      {fieldState.error != null && (
        <ErrorMessage message={fieldState.error.message} />
      )}
    </Wrapper>
  );
}
