import {Option} from '../components/elements/Select/Select.types';

export function createDict(
  dict:
    | ({
        name?: string | null;
        id?: string | number | null;
        key?: string | number | null;
      } | null)[]
    | null
): Option[] {
  return dict == null
    ? []
    : dict.reduce((acc: Option[], curr) => {
        if (curr == null) return acc;

        return [
          ...acc,
          {
            label: curr?.name || '',
            value: curr?.key || curr?.id || '',
          },
        ];
      }, []);
}
