import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../elements/Button/Button';
import {ModalWrapper} from '../../elements/ModalWrapper/ModalWrapper';
import {TextField} from '../../elements/TextField/TextField';
import {
  Buttons,
  Description,
  Form,
  Img,
  Tooltip,
  Wrapper,
} from './Photo.styles';
import {FormFields} from './Photo.types';

interface Props {
  defaultValues?: FormFields;

  title: string;
  file: File;
  requestSending: boolean;
  submitLabel: string;
  onSubmit(data: FormFields): void;
  onModalClose(): void;
}

export function Photo(props: Props) {
  const {onSubmit} = props;

  const [src, setSrc] = useState<string | ArrayBuffer | null | undefined>('');
  const {handleSubmit, register, formState, watch, setValue} =
    useForm<FormFields>({
      defaultValues: {
        file: props.file,
        description: '',
      },
      mode: 'onSubmit',
    });

  const file = watch('file');

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (function () {
      return function (e: any) {
        setSrc(e.target?.result || '');
      };
    })();

    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    if (props.defaultValues != null) {
      setValue('file', props.defaultValues.file);
      setValue('description', props.defaultValues.description);
    }
  }, [props.defaultValues, setValue]);

  return (
    <ModalWrapper title={props.title} onModalClose={props.onModalClose}>
      <Form onSubmit={handleSubmit(onSubmit)} onReset={props.onModalClose}>
        <Wrapper>
          <Img alt="Изображение" src={src == null ? '' : src.toString()} />

          <Description>
            <TextField
              label="Добавить описание фото"
              register={register('description', {
                maxLength: {
                  value: 300,
                  message: 'Превышено количество символов',
                },
              })}
              error={formState.errors.description}
              placeholder="Введите ваше сообщение"
              isTextarea
              rows={5}
            />
            <Tooltip>Не более 300 символов</Tooltip>
          </Description>
        </Wrapper>
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={props.requestSending}
          />
          <Button
            title={props.submitLabel}
            type="submit"
            fullWidth
            disabled={props.requestSending}
          />
        </Buttons>
      </Form>
    </ModalWrapper>
  );
}
