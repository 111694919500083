import {useServerErrors} from '../../../../hooks/useErrors.hooks';
import {FormFields} from '../components/form/types';

export function useServerValidate() {

  const {addServerError} = useServerErrors<FormFields>({
    'input.isTraining': 'isEducational',
    'input.regionId': 'region',
    'input.sourceId': 'source',
    'input.typeId': 'type',
    'input.description': 'description',
    'input.contact': 'lastName',
    'input.phone': 'phone',
    'input.email': 'email',
    'input.isUrgent': 'isUrgently',
    'input.dateTime': 'dateTime',
    'input.isEmailSent': 'isEmailSend',
    'input.issueId': 'applicationId',
    'input.regions': 'additionalRegions',
    'input.calls': 'calls'
  });

  return {addServerError}
}
