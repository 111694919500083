import styled from 'styled-components';

type ColorType = 'orange' | 'pink';

const config: Record<ColorType, string> = {
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IIconWrapper {
  color: ColorType;
}

export const IconWrapper = styled.button<IIconWrapper>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;

  border: none;
  cursor: pointer;
  background-color: ${(p) => config[p.color]};

  svg {
    width: 12px;
    height: 12px;

    fill: #fff;
  }

  &:not(&:disabled):hover {
    // not found in design
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Form = styled.form``;

export const Buttons = styled.div`
  margin-top: 24px;
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
