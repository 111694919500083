import {onError} from '@apollo/client/link/error';

const errorLink = onError(({graphQLErrors, networkError}) => {
  const unauthenticated = graphQLErrors?.find(
    (error) => error.message === 'Unauthenticated.'
  );

  if (unauthenticated?.message) {
    window.location.href = '/sign-in';
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export default errorLink;
