import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;

  background: #ffffff;
  box-sizing: border-box;

  width: 100%;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 22px 0 0;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 8px;
  }
`;

export const FieldsWrapper = styled.div`
  margin-top: 25px;
`;

export const ResponsibleBlock = styled.div`
  margin-bottom: 5rem;
  border-top: solid #d8e2ed;
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 1.36;

  color: #7d7d7d;
  text-decoration: none;
  display: block;

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;
