import React, {Fragment, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ChangeButtons} from '../../../../Call/view/components/description/modal/changeButtons';
import {useActions, useGetCallings} from '../../../../Call/view/ringing.hook';
import {DateField} from '../../shared/date/component';
import {HeadingInfo} from '../../shared/headingInfo/component';
import * as S from '../../../../Call/view/components/description/styles';
import * as SC from './MissingSection.styles';
import CallingComponent from './CallingComponent';
import {EditCalling} from '../../../../Call/view/components/description/EditCalling';
import {Id} from '../../../../../../types/auxiliary.types';
import {DeleteModal} from '../../modals/Delete/component';
import User from '../../../../../elements/user/component';

export default function RingingList() {
  const {callings, refetch, loading, fetch} = useGetCallings();

  const [modal, setModal] = useState<
    | {
        callingId: Id;
        content: string;
      }
    | {}
    | null
  >(null);

  const [show, setShow] = useState<{id: Id} | null>(null);

  const {applicationId} = useParams();

  const {handleDeleteCalling, loading: callingLoading} = useActions(refetch);

  const filteredCallings = applicationId
    ? callings.filter((item) => item.issue === Number(applicationId))
    : callings;

  const deleteCalling = (id: Id) => {
    handleDeleteCalling(id);
    setShow(null);
  };

  return (
    <Fragment>
      {modal != null &&
        ('callingId' in modal ? (
          <EditCalling
            content={'content' in modal ? modal.content : ''}
            callingId={modal.callingId}
            onEditSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ) : null)}
      <CallingComponent onChangeSuccess={refetch} />
      {show != null &&
        ('id' in show ? (
          <DeleteModal
            confirm={() => deleteCalling(show.id)}
            close={() => setShow(null)}
          />
        ) : null)}
      <S.List>
        {filteredCallings.map((item) => (
          <S.Card key={item.id}>
            <S.Header>
              <S.Number>{item.recipient}</S.Number>
              <ChangeButtons
                edit={{
                  disabled: loading,
                  onClick: () =>
                    setModal({
                      callingId: item.id,
                      content: item.description,
                    }),
                }}
                delete={{
                  disabled: callingLoading,
                  onClick: () => setShow({id: item.id}),
                }}
              />
              <DateField
                displayShown="small"
                label="Добавлено"
                date={item.createdAt}
              />
            </S.Header>
            <SC.FlexBox>
              <HeadingInfo
                dates={[
                  {
                    label: 'Добавлено',
                    date: item.createdAt,
                  },
                ]}
              />
              <SC.MarginBlock>
                <User fullName={item.fullname} />
              </SC.MarginBlock>
            </SC.FlexBox>
            <S.Description>
              {item.description.split('\n').map((item, key) => {
                return (
                  <S.Description key={key}>
                    {item} <br />{' '}
                  </S.Description>
                );
              })}
            </S.Description>
          </S.Card>
        ))}
      </S.List>
    </Fragment>
  );
}
