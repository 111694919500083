import React from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {getFullName, getShortNames} from '../../../../utils/String.utils';
import {Button} from '../../../elements/Button/Button';
import FormFooter from '../../../elements/FormFooter/component';
import Preloader from '../../../elements/Preloader/Preloader';
import {TextField} from '../../../elements/TextField/TextField';
import {MissingCard} from '../../Application/ApplicationView/components/MissingCard/MissingCard';
import {useStatuses} from '../../Application/ApplicationView/components/MissingSection/MissingSection.hooks';
import {useProfile} from '../../Profile/edit/hooks';
import CallRequestCard from '../item/components/card/component';
import Sidebar from './components/sidebar/component';
import {useActions, useInstitution} from './hooks';

import {
  Wrapper,
  Title,
  Main,
  List,
  Circumstances,
  CircumstancesText,
  Result,
  ResultBlock,
  ResultTitle,
  Form,
} from './styles';

export default function InstitutionScreen() {
  const {statuses} = useStatuses();
  const {data: me} = useProfile();

  const params = useParams<{callRequestId: string; institutionId: string}>();
  const {data, loading, refetch} = useInstitution();
  const {handleCreateResult, loading: createResultLoading} =
    useActions(refetch);
  const {register, formState, handleSubmit} = useForm({
    defaultValues: {
      result: data.institution?.result || '',
    },
  });

  return (
    <Form onSubmit={handleSubmit(handleCreateResult)}>
      <Title hasMargin>Прозвон #{params.institutionId}</Title>
      {loading && <Preloader />}
      <Wrapper>
        <Main>
          <List>
            {data.institution != null && (
              <CallRequestCard
                id={data.institution.id}
                number={data.institution.number}
                name={data.institution.name}
                type={data.institution.type}
                region={data.institution.region}
                address={data.institution.address}
                contacts={{
                  phone: data.institution.phone,
                  email: data.institution.email,
                  website: data.institution.webSite,
                }}
                caller="hide"
                note={data.institution.note}
                result={data.institution.result}
              />
            )}
            {data.issue.missing.map((m) => (
              <MissingCard
                key={m.id}
                id={m.id}
                gender={m.gender}
                fullName={getFullName(m.fullName)}
                age={m.age}
                labels={m.labels}
                statuses={m.statuses}
                fields={m.fields}
                statusesDict={statuses}
                onSuccessChangeStatus={refetch}
              />
            ))}
          </List>
          {data.circumstances != null && data.circumstances !== '' && (
            <Circumstances>
              <Title>Обстоятельства пропажи</Title>
              <CircumstancesText>{data.circumstances}</CircumstancesText>
            </Circumstances>
          )}
          {!loading &&
            data.institution != null &&
            me.fullName == data.institution.caller?.fullName && (
              <Result>
                <ResultTitle>Результаты прозвона</ResultTitle>
                {me.fullName === data.institution.caller?.fullName ? (
                  <ResultBlock>
                    <TextField
                      register={register('result')}
                      error={formState.errors.result?.message}
                      placeholder="Введите результат прозвона"
                      isTextarea
                      rows={12}
                      disabled={createResultLoading}
                    />
                  </ResultBlock>
                ) : null}
              </Result>
            )}
        </Main>
        <Sidebar
          number={data.id}
          urgent={data.urgency}
          missing={getShortNames(data.issue.missing.map((m) => m.fullName))}
          createDate={data.createDate}
          lossDate={data.issue.lossDate}
          applicationId={data.issue.id}
          regionFields={data.issue.regionFields}
        />
      </Wrapper>
      {data.institution != null &&
        me.fullName == data.institution.caller?.fullName && (
          <FormFooter hasErrors={Object.keys(formState.errors).length > 0}>
            <Button
              disabled={loading || createResultLoading}
              title="Сохранить"
              type="submit"
            />
          </FormFooter>
        )}
    </Form>
  );
}
