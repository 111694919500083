import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Pagination} from '../../../elements/Pagination/Pagination';
import Preloader from '../../../elements/Preloader/Preloader';
import {Filter} from './components/filter/component';
import Card from '../../../cards/call/component';
import useCallList, {usePagination, useTakeToWork} from './hooks';
import {List, PaginationWrapper, Wrapper} from './styles';
import {Title} from '../../ApplicationsScreen/ApplicationsScreen.styles';

export default function CallListScreen() {
  useDocumentTitle('Звонки');
  const {data, pagination, loading, refetch} = useCallList();
  const {page, handleSwitchPage} = usePagination();
  const {loading: takeWork, handleTakeToWork} = useTakeToWork(refetch);

  return (
    <Wrapper>
      <Title>Звонки</Title>
      <Filter callsCount={pagination?.total || 0} />
      {loading ? (
        <Preloader />
      ) : (
        <List>
          {data.map((call) => {
            return (
              <Card
                key={call.callId}
                number={Number(call.callId)}
                isUrgent={call.isUrgent}
                isTraining={call.isTraining}
                description={call.description}
                status={call.status}
                statusColor={call.statusColor}
                fullNameResponsible={call.fullNameResponsible}
                phoneResponsible={call.phoneResponsible}
                region={call.region}
                type={call.type}
                dateTime={call.dateTime}
                ringingCount={call.ringingCount}
                taskCount={call.taskCount}
                commentCount={call.commentCount}
                takeToWork={() => handleTakeToWork(call.callId)}
                takeWork={takeWork}
                responsible={call.responsible}
                contact={call.contact}
              />
            );
          })}
        </List>
      )}
      <PaginationWrapper>
        <Pagination
          page={page}
          total={pagination.lasPage}
          onChange={handleSwitchPage}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
