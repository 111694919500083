import React from 'react';
import {Wrapper} from './List.styles';
import {Card, Props as CardProps} from '../Card/Card';
import {Id} from '../../../../../types/auxiliary.types';

interface Props {
  items: Omit<CardProps, 'onClick'>[];
  onCardClick?(id: Id): void;
}

export function List(props: Props) {
  return (
    <Wrapper>
      {props.items.map((card) => (
        <Card
          key={card.number}
          isTraining={card.isTraining}
          parentId={card.parentId}
          number={card.number}
          isUrgently={card.isUrgently}
          location={card.location}
          area={card.area}
          missing={card.missing}
          lossDate={card.lossDate}
          creationDate={card.creationDate}
          ringingNumber={card.ringingNumber}
          tasksNumber={card.tasksNumber}
          forestInTouch={card.forestInTouch}
          institutionCallsNumber={card.institutionCallsNumber}
          callsNumber={card.callsNumber}
          lettersServicesNumber={card.lettersServicesNumber}
          commentNumber={card.commentNumber}
          onClick={() => props.onCardClick?.(card.number)}
        />
      ))}
    </Wrapper>
  );
}
