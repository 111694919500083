import React from 'react';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {BlockWrapper} from '../BlockWrapper/BlockWrapper';
import {Wrapper} from './SocialNetworkInfo.styles';
import {TextField} from '../../../../elements/TextField/TextField';
import {Control, UseFormRegister, useFormState} from 'react-hook-form';
import {FormFields} from '../../RegistrationFrom.types';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
}

export const SocialNetworkInfo = (props: Props) => {
  const {errors} = useFormState({
    control: props.control,
  });

  // const initialState = 'https://';
  //
  // const [value, setValue] = useState(initialState);
  //
  // const handleChange = (event: any) => {
  //   const {value: inputValue} = event.target;
  //   if (inputValue.length >= value.length) {
  //     setValue(value + inputValue.slice(-1));
  //   } else if (value.length > initialState.length) {
  //     setValue(value.slice(0, -1));
  //   }
  // };

  return (
    <BlockWrapper title="Соц.сети и мессенджеры">
      <Wrapper>
        <TextField
          register={props.register('socialNetworkInfo.vk')}
          error={errors.socialNetworkInfo?.vk}
          label="Ссылка на аккаунт в VK"
          placeholder="https://"
        />
        <TextField
          register={props.register('socialNetworkInfo.facebook', {
            pattern: {
              value: RegExpConstants.link,
              message: 'Некорректный формат',
            },
          })}
          error={errors.socialNetworkInfo?.facebook}
          label="Ссылка на аккаунт в Facebook"
          placeholder="https://"
        />
        <TextField
          register={props.register('socialNetworkInfo.twitter', {
            pattern: {
              value: RegExpConstants.link,
              message: 'Некорректный формат',
            },
          })}
          error={errors.socialNetworkInfo?.twitter}
          label="Ссылка на аккаунт в Twitter"
          placeholder="https://"
        />
        <TextField
          register={props.register('socialNetworkInfo.telegram')}
          error={errors.socialNetworkInfo?.telegram}
          label="Имя в Telegram"
          placeholder="@"
        />
      </Wrapper>
    </BlockWrapper>
  );
};
