import styled from 'styled-components';

interface IWrapper {
  size: 'small' | 'big';
}

export const Wrapper = styled.div<IWrapper>`
  width: ${(p) => (p.size === 'small' ? 40 : 136)}px;
  height: ${(p) => (p.size === 'small' ? 40 : 136)}px;

  font-size: ${(p) => (p.size === 'small' ? 16 : 46)}px;

  font-size: ${(p) => (p.size === 'small' ? 16 : 46)}px;
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const FakeAvatar = styled.p`
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;

  background-color: var(--primary-color);
  color: var(--primary-color-contrast);

  display: flex;
  align-items: center;
  justify-content: center;
`;
