import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {GROUP_FILE_QUERY} from '../../../../../gql';
import {
  GroupFiles,
  GroupFilesVariables,
} from '../../../../../gql/queries/group/__generated__/GroupFiles';

export function useGroup() {
  const params = useParams<{groupId?: string}>();

  const {data, loading, fetchMore} = useQuery<GroupFiles, GroupFilesVariables>(
    GROUP_FILE_QUERY,
    {
      variables: {
        id: (params.groupId || '').toString(),
      },
      skip: params.groupId == null || params.groupId === '',
    }
  );

  const fetch = useCallback(() => {
    fetchMore({
      variables: {
        id: (params.groupId || '').toString(),
      },
    });
  }, [fetchMore, params.groupId]);

  return {
    list:
      data?.group.files == null
        ? []
        : data.group.files.map((c) => ({
            name: c?.name || '',
            url: c?.url || '',
            mime: c?.mime || '',
            size: c?.size || 0,
          })) || [],
    loading,
    fetch,
  };
}
