import React from 'react';
import {MapMarkerIcon} from '../../../../../../icons/MapMarkerIcon';

import {Wrapper, Title, Value} from './Coords.styles';

interface Props {
  coords: string;
}

export function Coords(props: Props) {
  return (
    <Wrapper>
      <Title>Координаты штаба или точки входа:</Title>
      <Value>
        <MapMarkerIcon />
        {props.coords}
      </Value>
    </Wrapper>
  );
}
