import {DELETE_EQUIPMENT_ITEM} from './../../../../../../gql/index';
import {
  DeleteEquipment,
  DeleteEquipmentVariables,
} from './../../../../../../gql/profile/mutations/__generated__/DeleteEquipment';
import {useMutation} from '@apollo/client';
import {Id} from '../../../../../../types/auxiliary.types';
import {toast} from 'react-toastify';

export function useActions(onDeleteSuccess?: () => void) {
  // const [deleteEquipment, {loading}] = useMutation<
  //   DeleteEquipment,
  //   DeleteEquipmentVariables
  // >(DELETE_EQUIPMENT_ITEM);

  const [deleteEquipmentItem, {loading}] = useMutation<
    DeleteEquipment,
    DeleteEquipmentVariables
  >(DELETE_EQUIPMENT_ITEM);

  function handleDelete(id: Id) {
    deleteEquipmentItem({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        onDeleteSuccess?.();
        toast('Оборудование успешно удалено');
      })
      .catch(() => toast('Ошибка удаления оборудования', {type: 'error'}));
  }
  return {
    loading,
    handleDelete,
  };
}
