import React, {ReactNode} from 'react';
import {Id} from '../../../../../types/auxiliary.types';
import {Pagination} from '../../../../elements/Pagination/Pagination';
import Preloader from '../../../../elements/Preloader/Preloader';
import {ShortCard} from '../../../../elements/ShortCard/ShortCard';
import {Tabs} from '../../../../elements/Tabs/Tabs';
import {
  FilterWrapper,
  List,
  PaginationWrapper,
  Title,
  Wrapper,
} from './Form.styles';

export interface Props {
  activeTab: Id;
  items: {
    id: Id;
    title: string;
    labels: {
      id: Id;
      name: string;
      count: number;
    }[];
  }[];
  tabs: {
    id: Id;
    label: string;
  }[];
  pagination: {
    page: number;
    total: number;
    onChange(n: number): void;
  };
  loading?: boolean;
  renderFilterComponent?: () => ReactNode;
  onCardClick?(id: Id): void;
  onSwitchTab(newTab: Id): void;
}

export function Form(props: Props) {
  return (
    <Wrapper>
      <Title>Отряды и ведомства</Title>
      <Tabs
        activeTab={props.activeTab}
        items={props.tabs}
        onSwitchTab={props.onSwitchTab}
      />
      {props.renderFilterComponent != null && (
        <FilterWrapper>{props.renderFilterComponent?.()}</FilterWrapper>
      )}
      {props.loading ? (
        <Preloader />
      ) : (
        <List>
          {props.items.length === 0 ? (
            <div>Данные отсутствуют</div>
          ) : (
            props.items.map((item) => (
              <ShortCard
                key={item.id}
                title={item.title}
                labels={item.labels}
                onClick={
                  props.onCardClick != null
                    ? () => props.onCardClick?.(item.id)
                    : undefined
                }
              />
            ))
          )}
        </List>
      )}
      <PaginationWrapper>
        <Pagination
          page={props.pagination.page}
          total={props.pagination.total}
          onChange={props.pagination.onChange}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
