import styled from 'styled-components';

interface ITextarea {
  isInvalid?: boolean;
}

export const TextareaStyled = styled.textarea<ITextarea>`
  border: 1px solid
    ${(p) =>
      p.isInvalid ? 'var(--warning-color);' : 'var(--primary-border-color);'};
  color: ${(props) =>
    props.isInvalid ? 'var(--warning-color);' : 'var(--primary-text-color);'};
  border-radius: 8px;
  font-size: 14px;
  padding: 14px 16px;
  line-height: 1.3571428571;
  resize: vertical;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;
