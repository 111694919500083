import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {USER_LIST_DICT_QUERY} from '../../../../../../gql';
import {UserListDict} from '../../../../../../gql/users/queries/__generated__/UserListDict';
import {Id} from '../../../../../../types/auxiliary.types';
import {createDict} from '../../../../../../utils/Dict.utils';

import {createUrlSearch} from '../../../../../../utils/url.utils';
import {Option} from '../../../../../elements/Select/Select.types';

import {FormFields} from './types';

export function useDict() {
  const {data, loading} = useQuery<UserListDict>(USER_LIST_DICT_QUERY);

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.allRegions),
    teams:
      data?.allTeams?.reduce(
        (acc: Record<Id, Option & {groups: Record<Id, Option>}>, team) => ({
          ...acc,
          [team?.id || '']: {
            value: team?.id || '',
            label: team?.name || '',
            groups:
              team?.groups?.reduce(
                (acc1: Record<Id, Option>, group) => ({
                  ...acc1,
                  [group?.id || '']: {
                    value: group?.id || '',
                    label: group?.full_name || '',
                  },
                }),
                {}
              ) || {},
          },
        }),
        {}
      ) || {},
  };
}

export function useAction() {
  const [, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {
      setSearchParams({
        page: '1',
        ...createUrlSearch(data),
      });
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams({page: '1'}),
    [setSearchParams]
  );

  return {submit: handleSubmit, handleReset};
}
