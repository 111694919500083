import {ReactNode} from 'react';
import {Description} from './components/description/component';
import {Photos} from './components/photos/component';
import Tasks from './components/tasks/component';

export const TabConfig: {
  id: string,
  label: string,
  renderComponent?: () => ReactNode
}[] = [{
  id: 'description',
  label: "Описание",
  renderComponent: () => <Description />
}, {
  id: 'tasks',
  label: 'Задачи',
  renderComponent: () => <Tasks />
}, {
  id: 'photo',
  label: 'Фото',
  renderComponent: () => <Photos />
}]
