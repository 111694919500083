import {
  CallCreateComment,
  CallCreateCommentVariables,
} from './../../../../gql/call/mutations/__generated__/CallCreateComment';
import {
  CallChat,
  CallChatVariables,
} from './../../../../gql/call/queries/__generated__/CallChat';
import {
  CALL_CHAT_QUERY,
  CALL_ADD_COMMENT_MUTATION,
} from './../../../../gql/index';
import {useQuery, useMutation} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

interface FormFields {
  message: string;
}

export function useChat() {
  const {callId} = useParams<{callId: string}>();

  const {data, loading, refetch} = useQuery<CallChat, CallChatVariables>(
    CALL_CHAT_QUERY,
    {
      variables: {
        id: Number(callId),
      },
      skip: false,
    }
  );

  const [addMessageMutation, {loading: sendMessage}] = useMutation<
    CallCreateComment,
    CallCreateCommentVariables
  >(CALL_ADD_COMMENT_MUTATION);

  async function handleAddMessage(data: FormFields) {
    await addMessageMutation({
      variables: {
        input: {
          description: data.message,
          callId: Number(callId),
        },
      },
    })
      .then(() => {
        toast('Ваш комментарий успешно добавлен!', {type: 'success'});
      })
      .catch((e) => toast(e.message));
    return await refetch();
  }
  return {
    comments:
      data?.call?.comments == null
        ? []
        : data.call.comments.map((item) => {
            return {
              id: item?.id || '',
              fullName: item?.user.profile.full_name || '',
              description: item?.description || '',
              date: item?.created_at,
            };
          }),

    loading,
    handleAddMessage,
    sendMessage,
  };
}
