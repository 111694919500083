import {useQuery} from '@apollo/client';
import {CALL_CHANGE_FORM_DICT_QUERY} from '../../../../../gql';
import {ChangeFormDict} from '../../../../../gql/call/queries/__generated__/ChangeFormDict';
import {createDict} from '../../../../../utils/Dict.utils';

export function useDict() {
  const {data, loading} = useQuery<ChangeFormDict>(CALL_CHANGE_FORM_DICT_QUERY);

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.allRegions),
    types: data == null ? [] : createDict(data.allCallTypes),
    sources: data == null ? [] : createDict(data.allCallSources),
    applications:
      data?.issues == null
        ? []
        : data.issues.data.map((i) => ({value: i.id, label: i.id})),
    calls:
      data?.calls == null
        ? []
        : data.calls.data.map((c) => ({value: c.id, label: c.id})),
  };
}
