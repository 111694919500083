import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
`;

export const List = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
`;
