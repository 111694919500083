import {ApolloClient, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import errorLink from './hooks/useAnAuthUser';

const httpLinkOptions = {
  uri: process.env.REACT_APP_GQL_URL,
  credentials: 'include',
};

const httpLink = createUploadLink(httpLinkOptions);

export const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
});
