import React, {useEffect, useState} from 'react';
import {useForm, useFormState} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Id} from '../../../../types/auxiliary.types';
import {getFullName} from '../../../../utils/String.utils';
import {Button} from '../../../elements/Button/Button';
import Preloader from '../../../elements/Preloader/Preloader';
import SwitchField from '../../../elements/SwitchField/SwitchField';
import {TextField} from '../../../elements/TextField/TextField';
import {BlockWrapper} from '../../../elements/BlockWrapper/BlockWrapper';
import {GeneralInfo} from '../../../forms/ApplicationFrom/components/GeneralInfo/GeneralInfo';
import {useEnterForm} from '../../../forms/ApplicationFrom/form.hooks';
import {EditApplicant} from '../../../forms/ApplicationFrom/pages/edit/modals/EditApplicant/component';
import {NewApplicant} from '../../../forms/ApplicationFrom/pages/edit/modals/NewApplicant/NewApplicant';
import {NewMissing} from '../../../forms/ApplicationFrom/pages/edit/modals/NewMissing/NewMissing';
import {PencilIcon} from '../../../icons/PencilIcon';
import {PlusIcon} from '../../../icons/PlusIcon';
import {TrashIcon} from '../../../icons/TrashIcon';
import {Applicant, FormFields} from './Edit.types';
import {
  Action,
  ActionTitle,
  ButtonsWrapper,
  Form,
  ContentWrapper,
  GeneralInfoWrapper,
  IconWrapper,
  Title,
  Wrapper,
  Fields,
  PrimaryColumn,
  SecondaryColumn,
  ApplicantsWrapper,
  MissingWrapper,
  Missing,
  MissingName,
  Row,
  Footer,
  ActionTitleAdd,
} from './Edit.styles';
import {useHandler, useInit} from './Edit.hooks';
import {EditMissing} from '../../../forms/ApplicationFrom/pages/edit/modals/EditMissing/component';

export function ApplicationEditScreen() {
  const navigate = useNavigate();

  const [modal, setModal] = useState<{
    type: 'applicant' | 'missing';
    mode: 'new' | 'edit';
    id?: Id;
  } | null>(null);

  const params = useParams<{applicationId: string}>();

  useDocumentTitle('Редактировать заявку');

  const {data, applicants, loading, fetch} = useInit();

  const {
    control,
    register,
    formState,
    handleSubmit,
    setValue,
    reset,
    setError,
  } = useForm<FormFields>({
    defaultValues: data as FormFields,
    reValidateMode: 'onChange',
    mode: 'onBlur',
  });

  const {errors} = useFormState({
    control: control,
  });

  useEffect(() => {
    if (!loading && data != null) {
      reset(data, {keepDefaultValues: false});
    }
  }, [data, loading, reset]);

  const {
    handleSubmitBaseInfo,
    handleApplicantDelete,
    handleMissingDelete,
    actionLoading,
  } = useHandler(setError, () =>
    navigate(`/applications/${params.applicationId}`)
  );

  useEnterForm(handleSubmit(handleSubmitBaseInfo));

  function renderModal() {
    if (modal == null) return null;

    switch (modal.type) {
      case 'missing': {
        switch (modal.mode) {
          case 'new': {
            if (modal.id == null) return null;

            return (
              <NewMissing
                applicantId={modal.id}
                onClose={() => setModal(null)}
                onSuccess={fetch}
              />
            );
          }
          case 'edit': {
            let currentMissing: Applicant['missing'][0] | undefined;

            applicants.some((a) =>
              a.missing.some((m) => {
                if (m.id === modal.id) {
                  currentMissing = m;
                  return true;
                }

                return false;
              })
            );

            if (currentMissing == null || modal.id == null) return null;

            return (
              <EditMissing
                editValues={{
                  firstName: currentMissing.firstName,
                  lastName: currentMissing.lastName,
                  middleName: currentMissing.middleName,
                  gender: currentMissing.gender,
                  birthdayString: currentMissing.birthdayString,
                  hasPhone: currentMissing.hasPhone,
                  availablePhone: currentMissing.availablePhone,
                  phone: currentMissing.phone,
                  additionalPhones: currentMissing.additionalPhones,
                  health: currentMissing.health,
                  diseases: currentMissing.diseases,
                  appearance: currentMissing.appearance,
                  cloth: currentMissing.cloth,
                  withSelf: currentMissing.withSelf,
                  additionalInformation: currentMissing.additionalInformation,
                }}
                missingId={modal.id}
                onClose={() => setModal(null)}
                onSuccess={fetch}
              />
            );
          }
          default:
            return null;
        }
      }
      case 'applicant': {
        switch (modal.mode) {
          case 'new': {
            return (
              <NewApplicant onClose={() => setModal(null)} onSuccess={fetch} />
            );
          }
          case 'edit': {
            const currentApplicant = applicants.find((a) => a.id === modal.id);

            if (currentApplicant == null || modal.id == null) return null;

            return (
              <EditApplicant
                id={modal.id}
                editValues={{
                  firstName: '',
                  is112: false,
                  lastName: currentApplicant.fullName,
                  middleName: '',
                  relationDegree: currentApplicant.relationDegree,
                  phone: currentApplicant.phone,
                  additionalContact: currentApplicant.additionalContact,
                  police: currentApplicant.police,
                  MES: currentApplicant.MES,
                }}
                onClose={() => setModal(null)}
                onSuccess={fetch}
              />
            );
          }
          default:
            return null;
        }
      }
      default:
        return null;
    }
  }

  function renderButtons(id: Id, type: 'applicant' | 'missing') {
    return (
      <ButtonsWrapper>
        <Action
          type="button"
          color="orange"
          disabled={actionLoading}
          onClick={(e) => {
            e.stopPropagation();

            setModal({
              id: id,
              mode: 'edit',
              type: type,
            });
          }}>
          <IconWrapper data-icon>
            <PencilIcon />
          </IconWrapper>
          <ActionTitle data-name>Редактировать</ActionTitle>
        </Action>

        <Action
          type="button"
          color="pink"
          disabled={actionLoading}
          onClick={(e) => {
            e.stopPropagation();

            switch (type) {
              case 'missing': {
                return handleMissingDelete(id).then(() => fetch());
              }
              case 'applicant': {
                return handleApplicantDelete(id).then(() => fetch());
              }
            }
          }}>
          <IconWrapper data-icon>
            <TrashIcon />
          </IconWrapper>
          <ActionTitle data-name>Удалить</ActionTitle>
        </Action>
      </ButtonsWrapper>
    );
  }
  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {renderModal()}
          <Title>{`Заявка #${params.applicationId}`}</Title>
          <ContentWrapper>
            <Form
              onSubmit={handleSubmit(handleSubmitBaseInfo)}
              onReset={() => {
                if (data != null) {
                  reset(data as FormFields, {keepDefaultValues: true});
                }
                navigate(`/applications/${params.applicationId}`);
              }}>
              <Fields>
                <GeneralInfo
                  register={register as any}
                  control={control as any}
                  setValue={setValue as any}>
                  <GeneralInfoWrapper>
                    <PrimaryColumn>
                      <TextField
                        register={register('coordinates')}
                        label="Координаты штаба или точки входа"
                        placeholder="Введите"
                        error={errors.coordinates}
                      />
                      <TextField
                        register={register('forumTopic')}
                        label="Тема на форуме"
                        placeholder="Введите"
                        error={errors.forumTopic}
                      />
                      <TextField
                        register={register('socialMediaTopic')}
                        label="Темы в соцсетях (URL через пробел)"
                        placeholder="Введите"
                        isTextarea
                        error={errors.socialMediaTopic}
                        rows={2}
                      />
                      <TextField
                        register={register('mediaPublications')}
                        label="Публикации в СМИ (URL через пробел)"
                        placeholder="Введите"
                        isTextarea
                        rows={2}
                        error={errors.mediaPublications}
                      />
                    </PrimaryColumn>
                    <SecondaryColumn>
                      <SwitchField
                        name="isMailSend"
                        control={control}
                        label="Почту отправили"
                      />
                      <SwitchField
                        name="isSMSSent"
                        control={control}
                        label="СМС отправили"
                      />
                    </SecondaryColumn>
                  </GeneralInfoWrapper>
                </GeneralInfo>
              </Fields>

              {applicants.length > 0 && (
                <ApplicantsWrapper>
                  {applicants.map((a) => (
                    <BlockWrapper
                      key={a.id}
                      title={`Заявитель - ${a.fullName}`}
                      shouldOpen
                      renderHeader={() => renderButtons(a.id, 'applicant')}>
                      <MissingWrapper>
                        {a.missing.map((m) => (
                          <Missing key={m.id}>
                            <MissingName>
                              {`${m.gender === 'm' ? 'Пропавший' : 'Пропавшая'}
                               - ${getFullName({
                                 firstName: m.firstName,
                                 lastName: m.lastName,
                                 secondName: m.middleName,
                               })}`}
                            </MissingName>
                            {renderButtons(m.id, 'missing')}
                          </Missing>
                        ))}
                        <Row>
                          <Action
                            type="button"
                            color="orange"
                            disabled={actionLoading}
                            onClick={() =>
                              setModal({
                                mode: 'new',
                                type: 'missing',
                                id: a.id,
                              })
                            }>
                            <IconWrapper data-icon>
                              <PlusIcon />
                            </IconWrapper>
                            <ActionTitleAdd data-name>
                              Добавить пропавшего
                            </ActionTitleAdd>
                          </Action>
                        </Row>
                      </MissingWrapper>
                    </BlockWrapper>
                  ))}
                  <Row>
                    <Action
                      type="button"
                      color="orange"
                      disabled={actionLoading}
                      onClick={() =>
                        setModal({
                          mode: 'new',
                          type: 'applicant',
                        })
                      }>
                      <IconWrapper data-icon>
                        <PlusIcon />
                      </IconWrapper>
                      <ActionTitleAdd data-name>
                        Добавить заявителя
                      </ActionTitleAdd>
                    </Action>
                  </Row>
                </ApplicantsWrapper>
              )}

              <Footer>
                <Button
                  title="Отменить"
                  type="reset"
                  variant="outlined"
                  disabled={formState.isSubmitting || actionLoading}
                />
                <Button
                  title="Сохранить"
                  type="submit"
                  disabled={formState.isSubmitting || actionLoading}
                />
              </Footer>
            </Form>
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
}
