import React from 'react';
import AlgorithmIcon from '../../../../icons/helpfulInfo/AlgorithmIcon';
import InstitutionsIcon from '../../../../icons/helpfulInfo/InstitutionsIcon';
import QuestionnairesIcon from '../../../../icons/helpfulInfo/QuestionnairesIcon';
import RegionalAgreeIcon from '../../../../icons/helpfulInfo/RegionalAgreeIcon';
import ResourcesIcon from '../../../../icons/helpfulInfo/ResourcesIcon';
import TypographyIcon from '../../../../icons/helpfulInfo/TypographyIcon';
import {
  AlgorithmIconWrapper,
  ContentItem,
  Description,
  InstitutionsIconWrapper,
  ItemWrapper,
  QuestionnairesIconWrapper,
  RegionalAgreeIconWrapper,
  ResourcesIconWrapper,
  TypographyIconWrapper,
  BlockWithLink as StyledNavLink,
} from './Main.styles';

export default function Main() {
  return (
    <>
      <ItemWrapper>

        <StyledNavLink to={'/usefulResources'}>
          <ContentItem>
            <ResourcesIconWrapper>
              <ResourcesIcon />
            </ResourcesIconWrapper>
            <Description>
              <p>Полезные ресурсы</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

        <StyledNavLink to={'/typography'}>
          <ContentItem>
            <TypographyIconWrapper>
              <TypographyIcon />
            </TypographyIconWrapper>
            <Description>
              <p>Типографии</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

        <StyledNavLink to={'/institutions'}>
          <ContentItem>
            <InstitutionsIconWrapper>
              <InstitutionsIcon />
            </InstitutionsIconWrapper>
            <Description>
              <p>Учреждения</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

      </ItemWrapper>

      <ItemWrapper>

        <StyledNavLink to={'/algorithms'}>
          <ContentItem>
            <AlgorithmIconWrapper>
              <AlgorithmIcon />
            </AlgorithmIconWrapper>
            <Description>
              <p>Алгоритмы</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

        <StyledNavLink to={'/questionnaires'}>
          <ContentItem>
            <QuestionnairesIconWrapper>
              <QuestionnairesIcon />
            </QuestionnairesIconWrapper>
            <Description>
              <p>Опросники</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

        <StyledNavLink to={'/regionalAgree'}>
          <ContentItem>
            <RegionalAgreeIconWrapper>
              <RegionalAgreeIcon />
            </RegionalAgreeIconWrapper>
            <Description>
              <p>Региональные соглашения</p>
            </Description>
          </ContentItem>
        </StyledNavLink>

      </ItemWrapper>
    </>
  );
}
