import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {TypeWrapper} from '../../../../../cards/call/styles';
import {StatusWrapper} from '../../../../../cards/task/styles';
import {Badge} from '../../../../../elements/Badge/Badge';
import {Button} from '../../../../../elements/Button/Button';
import Status from '../../../../../elements/ChangedStatus/Status';
import Preloader from '../../../../../elements/Preloader/Preloader';
import Fields from '../../../../../elements/sidebarInfo/generalInfo/component';
import Header from '../../../../../elements/sidebarInfo/header/component';
import {useTakeToWork} from '../../../list/hooks';
import {useCall, useStatuses} from './hooks';
import {
  Wrapper,
  LabelsWrapper,
  FieldsWrapper,
  ResponsibleBlock,
  Link,
} from './styles';
import {Text} from '../../../../../elements/Text/Text';
import {Title} from '../description/styles';

interface Props {
  onSuccess?(): void;
  onChangeStatus(id: Id): void;
}

export function GeneralInfo(props: Props) {
  const {loading, data, refetch} = useCall();

  const {loading: takeWork, handleTakeToWork} = useTakeToWork(refetch);

  const handleStopPropogation = (event: any) => {
    event?.stopPropagation();
    handleTakeToWork(Number(data?.id));
  };

  const {data: Statuses} = useStatuses();

  return (
    <Wrapper>
      {loading || data == null ? (
        <Preloader />
      ) : (
        <>
          <Header
            number={data.id}
            isUrgently={data.isUrgently}
            isEmailSend={data.isEmailSend || undefined}
          />
          {!loading && (
            <StatusWrapper>
              <Status
                id={data.status.id}
                options={Statuses}
                onChange={props.onChangeStatus}
              />
            </StatusWrapper>
          )}
          {(data.status.name !== '' || data.isEducational) && (
            <LabelsWrapper>
              {data.isEducational && <Badge title="Учебный" color="#DB00FF" />}
            </LabelsWrapper>
          )}
          <FieldsWrapper>
            <Fields
              list={[
                {
                  type: 'datetime',
                  label: 'Время звонка',
                  value: data.datetime,
                },
                {
                  type: 'default',
                  label: 'Тип звонка',
                  value: data.type,
                },
                {
                  type: 'default',
                  label: 'Источник',
                  value: data.source,
                },
                {
                  type: 'links',
                  label: 'Заявка',
                  values: [
                    {
                      href: `/applications/${data.applicationId}`,
                      text: data.applicationId || 'Нет привязанной заявки',
                    },
                  ],
                },
                {
                  type: 'links',
                  label: 'Звонки',
                  values: data.callsId.map((call) => ({
                    href: `/calls/${call}`,
                    text: call,
                  })),
                },
                {
                  type: 'largeText',
                  label: 'Регионы',
                  value: data.regions.join(', '),
                },
                data.responsibleFullName !== undefined
                  ? {
                      type: 'default',
                      label: 'Ответственный',
                      value: data.responsibleFullName,
                    }
                  : {
                      type: 'default',
                      label: 'Ответственный',
                      value: 'Ответственного нет',
                    },
              ]}
            />
          </FieldsWrapper>
          <TypeWrapper onClick={(e) => handleStopPropogation(e)}>
            {data.responsibleFullName === undefined ? (
              <Button
                onClick={handleStopPropogation}
                disabled={!!data.responsibleId}
                color="primary"
                title={takeWork ? 'Идет загрузка...' : 'Взять в работу'}
              />
            ) : null}
          </TypeWrapper>
        </>
      )}
      <ResponsibleBlock>
        <Title>Звонивший</Title>
        <Text variant="tips">{data?.contact}</Text>
        <Link href={`mailto:${data?.email}`}>{data?.email}</Link>
        {data?.is112 ? (
          <Link>Звонок по системе: 112</Link>
        ) : (
          <Link href={`tel:${data?.is112}`}>{data?.is112}</Link>
        )}
      </ResponsibleBlock>
    </Wrapper>
  );
}
