import React from 'react';
import {SvgStyled} from './Icons.styles';

export const TriangleIcon = () => {
  return (
    <SvgStyled
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      data-at="triangle-icon"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 -3.49691e-07L8 5L0 5L4 -3.49691e-07Z" fill="#376092" />
    </SvgStyled>
  );
};
