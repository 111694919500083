import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  margin-bottom: 40px;

  display: flex;

  @media screen and (max-width: 1150px) {
    display: block;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const PrimaryColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 330px);

  grid-column-gap: 24px;
  grid-row-gap: 16px;

  margin-right: 34px;

  @media screen and (max-width: 1400px) {
    flex: auto;
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: 1150px) {
    margin-right: 0;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Fields = styled.div`
  display: flex;

  > * {
    width: calc((100% - 16px) / 2);
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const SecondaryField = styled.div`
  max-width: 300px;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 35px;
  }
`;
