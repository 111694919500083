import {Url} from '../../../types/auxiliary.types';
import {FakeAvatar, Wrapper} from './Avatar.styles';

interface Props {
  firstName?: string;
  lastName?: string;
  src?: Url;
  size?: 'small' | 'big';
  fullName?: string;
}

export function Avatar(props: Props) {
  let shortName = '';

  if (props.fullName != null) {
    const array = props.fullName.split(' ');

    switch (array.length) {
      case 0: {
        shortName = '';
        break;
      }
      case 1: {
        shortName = array[0][0];
        break;
      }
      default: {
        shortName = array[0][0] + array[1][0];
        break;
      }
    }
  } else if (props.firstName != null && props.lastName != null) {
    shortName = props.firstName[0] + props.lastName[0];
  }

  return (
    <Wrapper data-at="avatar-element" size={props.size || 'small'}>
      <FakeAvatar>{(shortName || '').toUpperCase()}</FakeAvatar>
    </Wrapper>
  );
}
