import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px 25px 24px 24px;
  box-sizing: border-box;

  background-color: #f4f9ff;
  display: flex;
  justify-content: space-between;

  flex: auto;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;
    overflow-x: hidden;
    & * {
      text-overflow: ellipsis;
    }

    > *:not(:last-child) {
      margin-right: 0;
    }

    > *:not(:first-child) {
      margin-bottom: 24px;
    }
  }
`;

export const MainContent = styled.div`
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: auto;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-size: 1.875rem;
  font-weight: 700;
  color: #000;

  @media screen and (max-width: 950px) {
    margin-bottom: 8px;
  }
`;

export const ListWrapper = styled.div`
  flex: auto;
`;

interface ISidebar {
  isAdaptive?: boolean;
}

export const Sidebar = styled.div<ISidebar>`
  display: ${(p) => (p.isAdaptive ? 'none' : 'block')};
  margin-left: 24px;

  flex: auto;
  overflow-y: auto;

  flex-shrink: 0;

  width: 312px;

  @media screen and (max-width: 950px) {
    display: ${(p) => (!p.isAdaptive ? 'none' : 'block')};

    width: 100%;
    margin-bottom: 16px;
  }
`;
