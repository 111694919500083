import React from 'react';
import {Outlet} from 'react-router-dom';
import {Copyright} from '../../elements/Copyright/Copyright';
import {
  Container,
  Content,
  CopyrightWrapper,
  LogoStyled,
} from './SignUpLayout.style';

export function SignUpLayout() {
  return (
    <Container>
      <LogoStyled variant={'medium'} />
      <Content>
        <Outlet />
      </Content>
      <CopyrightWrapper>
        <Copyright />
      </CopyrightWrapper>
    </Container>
  );
}
