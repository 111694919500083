import React from 'react';
import {SvgStyled} from './Icons.styles';

export const FilterIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.30202 14.9243L1.1566 13.3077C0.77882 12.8628 0.845487 12.0526 1.65935 12.0526H2.1454V2.18382C2.1454 1.75929 2.22326 1.448 2.76033 1.448H3.95926C4.49633 1.448 4.5742 1.75929 4.5742 2.18382V12.0526H5.06024C5.87411 12.0526 5.94184 12.8628 5.56406 13.3077L4.41758 14.9243C3.78913 15.8112 2.9198 15.7954 2.30202 14.9243Z"
        fill="#FF6600"
      />
      <path
        d="M7.78438 13.1714C7.08536 13.2084 6.8754 13.6795 6.60109 14.1429L6.3122 14.6313C6.03932 15.0946 6.165 15.6027 7.07718 15.6027H14.3179C14.7412 15.5804 15.0829 15.1691 15.0829 14.6313V14.1429C15.0829 13.6052 14.7412 13.1938 14.3179 13.1714H7.78438Z"
        fill="#FF6600"
      />
      <path
        d="M6.9493 1.448C6.52601 1.47004 6.18433 1.88178 6.18433 2.4192V2.90773C6.18433 3.44551 6.52601 3.85689 6.9493 3.87929H9.21223C9.63551 3.85706 9.9772 3.44551 9.9772 2.90773V2.4192C9.9772 1.88178 9.63551 1.47004 9.21223 1.448H6.9493Z"
        fill="#FF6600"
      />
      <path
        d="M6.9493 9.26367C6.52601 9.28589 6.18433 9.69745 6.18433 10.235V10.7234C6.18433 11.2612 6.52601 11.6726 6.9493 11.695H12.0567C12.48 11.6726 12.8216 11.2612 12.8216 10.7234V10.235C12.8216 9.69745 12.48 9.28589 12.0567 9.26367H6.9493Z"
        fill="#FF6600"
      />
      <path
        d="M6.94931 5.35571C6.52602 5.37794 6.18433 5.78967 6.18433 6.32727V6.8158C6.18433 7.35322 6.52602 7.76496 6.94931 7.787H10.99C11.4133 7.76496 11.755 7.35322 11.755 6.8158V6.32727C11.755 5.78985 11.4133 5.37811 10.99 5.35571H6.94931Z"
        fill="#FF6600"
      />
    </SvgStyled>
  );
};
