import Preloader from '../../../../../elements/Preloader/Preloader';
import {Content, Wrapper} from './CircumstancesOfLoss.styles';
import {useCircumstancesOfLoss} from './hooks';

export function CircumstancesOfLoss() {
  const {loading, text} = useCircumstancesOfLoss();

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <Content>
          {text.split('\n').map((item, key) => {
            return (
              <div key={key}>
                {item} <br />
              </div>
            );
          })}{' '}
        </Content>
      )}
    </Wrapper>
  );
}
