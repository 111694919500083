import React from 'react';
import {Form, Props as FormProps} from './componets/Form/Form';
import {MainContent, Wrapper} from './StructureList.styles';

// props: FormProps & ResponsibleFormProps when there will be blocks of responsible
export function StructureList(props: FormProps) {
  return (
    <Wrapper>
      <MainContent>
        <Form
          activeTab={props.activeTab}
          items={props.items}
          tabs={props.tabs}
          pagination={props.pagination}
          loading={props.loading}
          renderFilterComponent={props.renderFilterComponent}
          onCardClick={props.onCardClick}
          onSwitchTab={props.onSwitchTab}
        />
      </MainContent>
      {/*<ResponsibleWrapper>*/}
      {/*  <ResponsibleForm responsible={props.responsible} />*/}
      {/*</ResponsibleWrapper>*/}
    </Wrapper>
  );
}
