import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import { Gallery } from '../../../../../elements/Gallery/Gallery';
import Preloader from '../../../../../elements/Preloader/Preloader';
import { AddPhoto } from '../../modals/addPhoto/component';
import {usePhoto} from './hooks';

import {List, Photo, PhotoGallery, PhotoGalleryWrapper, Title, Wrapper} from './styles';

export function Photos() {
  const [numberSlide, setNumberSlide] = useState<number | null>(null);
  const {loading, list, fetch} = usePhoto();

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  function renderModal() {
    if (file == null) return null;

    return (
      <AddPhoto
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  function renderCard(photo: {name: string; url: string}, i: number) {
    return (
      <Photo
        key={photo.url}
        height={312}
        width={312}
        alt="Фото"
        src={photo.url}
        onClick={() => setNumberSlide(i)}
      />
    );
  }

  function renderGalleryCard(photo: {name: string; url: string}) {
    return (
      <PhotoGalleryWrapper>
        <PhotoGallery alt="Фото" src={photo.url} />
        <Title>{photo.name}</Title>
      </PhotoGalleryWrapper>
    );
  }

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      {renderModal()}
      {numberSlide != null && (
        <Gallery
          list={list}
          renderCard={renderGalleryCard}
          firstSlide={numberSlide}
          onModalClose={() => setNumberSlide(null)}
        />
      )}
      <AddButton
        buttonLabel="Добавить фото"
        typeButton="fileInput"
        formRegister={formRegisterResult}
        accept=".jpg,.jpeg,.png"
      />
      <List>
        {list.map(renderCard)}
      </List>
    </Wrapper>
  );
}
