import React from 'react';
import {Column, Label, Line} from '../styles';
import {DateField as DateFieldCustom} from '../../../../../cards/elements/dateField/component';

interface Props {
  displayShown?: 'small' | 'big';
  label: string;
  date: Date | null;
}

export function DateField(props: Props) {
  if (props.date == null) return null;

  if (props.displayShown === 'big') {
    return (
      <Column displayShown="big">
        <DateFieldCustom label={props.label} date={props.date} />
      </Column>
    );
  }

  return (
    <Line displayShown="small">
      <Label data-element="label">{props.label} </Label>
      <DateFieldCustom date={props.date} />
    </Line>
  );
}
