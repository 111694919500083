import React from 'react';
import {SvgStyled} from './Icons.styles';

export const SearchIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6211 14.1849L11.9426 10.5062C12.5676 9.51114 12.9303 8.33508 12.9303 7.07294C12.9303 3.5022 10.0356 0.60791 6.46503 0.60791C2.89442 0.60791 0 3.5022 0 7.07294C0 10.6438 2.89429 13.5378 6.46503 13.5378C7.83856 13.5378 9.1108 13.1084 10.1577 12.3788L13.7924 16.0138C14.045 16.266 14.3761 16.3917 14.7067 16.3917C15.0378 16.3917 15.3685 16.266 15.6215 16.0138C16.1262 15.5085 16.1262 14.6901 15.6211 14.1849ZM6.46503 11.4433C4.05162 11.4433 2.09498 9.48676 2.09498 7.07321C2.09498 4.65967 4.05162 2.70302 6.46503 2.70302C8.87858 2.70302 10.8351 4.65967 10.8351 7.07321C10.8351 9.48676 8.87858 11.4433 6.46503 11.4433Z" />
    </SvgStyled>
  );
};
