import React from 'react';

import Template from '../template/component';
import {Card} from './components/card/component';
import {useIssue} from './hooks';

export default function Issue() {
  const {data, modalMeta, loading} = useIssue();

  return (
    <Template
      loading={loading}
      modalMeta={modalMeta}
      list={data}
      renderCard={Card}
    />
  )
}
