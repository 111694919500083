import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useRegionalAgree} from './RegionalAgree.hooks';
import {
  ItemWrapper,
  RegionalAgreeItem,
  RegionalName,
  Wrapper,
  Title,
} from './RegionalAgree.styles';

export default function RegionalAgree() {
  useDocumentTitle('Региональные соглашения');

  const {regionalAgree, loading} = useRegionalAgree();

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Региональные соглашения</Title>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {regionalAgree.map((item) => {
            return (
              <ItemWrapper key={item.id}>
                <RegionalAgreeItem
                  onClick={() => navigate(`/regionalAgree/${item.id}`)}>
                  <RegionalName>{item.name}</RegionalName>
                </RegionalAgreeItem>
              </ItemWrapper>
            );
          })}
        </>
      )}
    </Wrapper>
  );
}
