import {useMutation} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {CREATE_CALL_PHOTO_MUTATION} from '../../../../../../gql';
import {CreateCallPhoto, CreateCallPhotoVariables} from '../../../../../../gql/call/mutations/__generated__/CreateCallPhoto';

export function useAddPhotosHandler(onAddSuccess: () => void) {
  const params = useParams<{callId?: string}>();

  const [addPhotoMutation, {loading}] = useMutation<CreateCallPhoto, CreateCallPhotoVariables>(
    CREATE_CALL_PHOTO_MUTATION
  );

  function handleAddPhoto(
    data: {
      file: File,
      description: string
    }) {
      addPhotoMutation({
        variables: {
          input: {
            callId: Number(params.callId),
            file: data.file,
            name: data.description
          },
        }
      }).then(() => onAddSuccess())
    }

  return {handleAddPhoto, requestSending: loading}
}
