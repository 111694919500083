import {useQuery} from '@apollo/client';
import {TASK_STATUSES_QUERY} from '../../../../../../gql';
import { TaskStatuses } from '../../../../../../gql/task/queries/__generated__/TaskStatuses';

export function useStatuses() {
  const {data, loading} = useQuery<
    TaskStatuses
    >(TASK_STATUSES_QUERY);

  return {
    data: data?.allTaskStatuses == null ? [] : data.allTaskStatuses.map((status) => ({
        id: status?.id || "",
        name: status?.name || "",
        color: status?.color || "",
      }
    )),
    loading,
  };
}
