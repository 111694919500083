import React from 'react';

import Template from '../template/component';
import Card from './components/card/component';
import {useCalls} from './hooks';

export default function Calls() {
  const {data, modalMeta, loading} = useCalls();

  return (
    <Template
      loading={loading}
      modalMeta={modalMeta}
      list={data}
      renderCard={Card}
    />
  )
}
