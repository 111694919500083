import {useMutation} from '@apollo/client';
import {APPLICATION_EDIT_STATUS_MUTATION} from '../../../../../../gql';
import {
  ChangeStatus,
  ChangeStatusVariables,
} from '../../../../../../gql/application/mutations/__generated__/ChangeStatus';
import {Id} from '../../../../../../types/auxiliary.types';
import {
  IGroupStatus,
  IStatus,
} from '../../../../../elements/ChangedStatus/type';

export function useStatusAction(
  missingId: Id,
  statuses: IGroupStatus[],
  curStatusId?: Id,
  curSubStatusId?: Id,
  onSuccessChange?: () => void
) {
  const [changeStatusMutation] = useMutation<
    ChangeStatus,
    ChangeStatusVariables
  >(APPLICATION_EDIT_STATUS_MUTATION);

  const currentStatus =
    curStatusId != null
      ? statuses.reduce((acc: IStatus | undefined, curr) => {
          if (acc != null) return acc;

          return curr.statuses.find(
            (s) => s.id.toString() === curStatusId.toString()
          );
        }, undefined)
      : undefined;

  const currentSubStatus =
    curSubStatusId != null && currentStatus != null
      ? currentStatus.subStatuses.find((s) => s.id === curSubStatusId)
      : null;

  function handleChangeSubStatus(id: Id) {
    if (currentStatus == null) return;

    changeStatusMutation({
      variables: {
        input: {
          missingId: Number(missingId),
          statusId: Number(currentStatus.id),
          subStatusId: Number(id),
        },
      },
    }).then(() => onSuccessChange?.());
  }

  function handleChangeStatus(id: Id) {
    changeStatusMutation({
      variables: {
        input: {
          missingId: Number(missingId),
          statusId: Number(id),
        },
      },
    }).then(() => onSuccessChange?.());
  }

  return {
    currentStatus: currentStatus ?? {id: null},
    currentSubStatus: currentSubStatus ?? {id: null},
    handleChangeSubStatus,
    handleChangeStatus,
  };
}
