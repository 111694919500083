import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Url} from '../../../../../types/auxiliary.types';
import Preloader from '../../../../elements/Preloader/Preloader';
import {AddPhoto} from '../../modals/AddPhoto/AddPhoto';
import {Gallery} from '../../../../elements/Gallery/Gallery';
import {useTeam} from './Photos.hooks';
import {MediaList} from '../MediaList/MediaList';

import {Photo, PhotoGallery, PhotoGalleryWrapper, Title} from './Photos.styles';

export function Photos() {
  const [numberSlide, setNumberSlide] = useState<number | null>(null);
  const {loading, list, fetch} = useTeam();

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  function renderModal() {
    if (file == null) return null;

    return (
      <AddPhoto
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  function renderCard(photoSrc: Url, i: number) {
    return (
      <Photo
        key={photoSrc}
        height={312}
        width={312}
        alt="Фото"
        src={photoSrc}
        onClick={() => setNumberSlide(i)}
      />
    );
  }

  function renderGalleryCard(photo: {name: string; url: string}) {
    return (
      <PhotoGalleryWrapper>
        <PhotoGallery alt="Фото" src={photo.url} />
        <Title>{photo.name}</Title>
      </PhotoGalleryWrapper>
    );
  }

  if (loading) return <Preloader />;

  return (
    <>
      {numberSlide != null && (
        <Gallery
          list={list}
          renderCard={renderGalleryCard}
          firstSlide={numberSlide}
          onModalClose={() => setNumberSlide(null)}
        />
      )}
      <MediaList<Url>
        list={list.map((i) => i.url)}
        typeButton="fileInput"
        buttonLabel="Добавить фото"
        renderCard={renderCard}
        renderModal={renderModal}
        accept=".jpg,.jpeg,.png"
        formRegister={formRegisterResult}
      />
    </>
  );
}
