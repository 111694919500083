import styled from 'styled-components';
import {Button} from '../../../Button/Button';

export const Wrapper = styled.article`
  margin-bottom: 32px;
  padding-right: 76px;

  @media (max-width: 1440px) {
    padding-right: 30px;
  }
  @media (max-width: 768px) {
    padding-right: 20px;
  }
  @media (max-width: 425px) {
    padding-right: 0;
  }
  padding-bottom: 1rem;
  border-bottom: solid 1px #f3f3f3;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const AvatarContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;

  > img {
    height: 100%;
    width: 120%;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UserName = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: var(--additional-color);
  padding-bottom: 3px;
  margin: 0;
`;

export const Time = styled.time`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-text-color);
`;

export const Content = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
`;

export const DeleteButton = styled(Button)``;
