import React, {useState} from 'react';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {ErrorMessage} from '../../elements/ErrorMessage/ErrorMessage';
import {Tabs} from '../../elements/Tabs/Tabs';
import Issue from './components/issue/component';
import Tasks from './components/tasks/component';
import Calls from './components/calls/component';
import DutyList from '../Profile/duty/list/component';
import {DutyModal} from './modals/form/component';
import {Container, Primary, Secondary, Title, Wrapper} from './styles';
import {useActions, useTab} from './hooks';
import {Button} from '../../elements/Button/Button';
import {useEquipment} from '../Profile/equipmentsList/Equipments.hooks';

export default function IndexPage() {
  const [showAddModal, setShowAddModal] = useState(false);
  const {handleSwitchTab, activeTab} = useTab();
  const {handleEndDuty, commonError, loading} = useActions();

  const tabs = [
    {
      id: 'applications',
      label: 'Мои заявки',
      renderSubpage: <Issue />,
    },
    {
      id: 'duty',
      label: 'Мои дежурства',
      renderSubpage: <DutyList />,
    },
    {
      id: 'calls',
      label: 'Мои звонки',
      renderSubpage: <Calls />,
    },
    {
      id: 'tasks',
      label: 'Мои задачи',
      renderSubpage: <Tasks />,
    },
  ];

  const activeItem = tabs.find((t) => t.id === activeTab);

  useDocumentTitle(activeItem?.label || 'Главная');

  const {isShiftActive} = useEquipment();

  return (
    <Wrapper>
      {showAddModal && (
        <DutyModal
          onClose={() => setShowAddModal(false)}
          onAddSuccess={() => setShowAddModal(false)}
        />
      )}
      <Primary>
        {activeItem?.label != null && <Title>{activeItem.label}</Title>}
        <Tabs
          activeTab={activeTab || ''}
          items={tabs}
          onSwitchTab={handleSwitchTab}
        />
        <Container>
          {activeItem?.renderSubpage == null
            ? 'Произошла ошибка навигации'
            : activeItem.renderSubpage}
        </Container>
      </Primary>
      <Secondary>
        <Button
          fullWidth
          title="Начать смену"
          disabled={loading}
          onClick={() => setShowAddModal(true)}
        />
        {isShiftActive ? (
          <Button
            fullWidth
            disabled={loading}
            onClick={handleEndDuty}
            title="Закончить смену"
            variant={'outlined'}
          />
        ) : null}
        {commonError !== '' && <ErrorMessage message={commonError} />}
      </Secondary>
    </Wrapper>
  );
}
