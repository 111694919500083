import {SvgStyled} from './Icons.styles';

export const CheckMarkIcon = () => {
  return (
    <SvgStyled
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.988403 4.60707L3.27819 6.89682L9.2668 0.908203"
        stroke="white"
        strokeWidth="2"
      />
    </SvgStyled>
  );
};
