import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000;

  padding-bottom: 27px;
  border-bottom: 2px solid #ffffff;
  margin: 28px 24px 32px;
`;

export const ContentWrapper = styled.div`
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const Fields = styled.div`
  flex: auto;
  margin: 0 24px 22px;

  > *:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 2px solid #ffffff;
  }

  > *:not(:first-child) {
    margin-top: 32px;
  }

  > *:last-child {
    margin-top: 16px;
  }
`;

export const ApplicantsWrapper = styled.div`
  padding: 10px 24px;

  > * {
    padding-top: 32px;
    border-top: 2px solid #fff;
  }
`;

export const MissingWrapper = styled.div`
  > * {
    border-top: 1px solid #a1b2c8;
  }
`;

export const Missing = styled.div`
  padding: 16px 0 16px 26px;

  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  padding: 32px 0;
`;

export const MissingName = styled.p`
  margin: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #376092;

  flex: auto;
`;

export const Footer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px 16px 25px;

  display: flex;
  align-items: center;
  justify-content: end;

  background-color: #fff;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const GeneralInfoWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;

  @media screen and (max-width: 680px) {
    display: block;
  }
`;

export const PrimaryColumn = styled.div`
  flex: auto;
  margin-right: 92px;
  max-width: 648px;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1110px) {
    flex: none;
  }

  @media screen and (max-width: 680px) {
    margin-right: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 32px;
  }
`;

type ColorType = 'orange' | 'pink';

const config: Record<ColorType, string> = {
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IAction {
  color: ColorType;
}

export const Action = styled.button<IAction>`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  [data-icon='true'] {
    background-color: ${(p) => config[p.color]};
  }
  [data-name='true'] {
    color: ${(p) => config[p.color]};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;
  margin-right: 10px;

  svg {
    fill: #fff;
  }
`;

export const ActionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 1110px) {
    display: none;
  }
`;

export const ActionTitleAdd = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

export const SecondaryColumn = styled.div`
  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 680px) {
    margin-top: 32px;
  }
`;
