import React, {useState} from 'react';
import {Id} from '../../../types/auxiliary.types';
import {ChevronIcon} from '../../icons/ChevronIcon';
import CommentCard from './components/comment/component';
import {SendingComment} from './components/form/component';
import {FormFields} from './components/form/types';
import {
  ChatWrapper,
  ChatHeader,
  CommentWrapper,
  ViewAllButton,
  ChevronWrapper,
  CommentName,
  CommentCount,
  Content,
  Container,
  ContentWrapper,
  BackgroundContainer,
} from './styles';

interface Props {
  loadingComments?: boolean;
  comments: {
    id: Id;
    fullName: string;
    description: string;
    date?: any;
  }[];
  sendingMessage: boolean;
  onMessageAdd: (data: FormFields) => Promise<any>;
  deleteComment?: any;
}

export function Chat(props: Props) {
  const [isShowFullChat, setIsShowFullChat] = useState(false);

  return (
    <Container>
      {isShowFullChat && <BackgroundContainer />}
      <ChatWrapper>
        <ChatHeader>
          <CommentWrapper>
            <CommentName>Комментарии</CommentName>
            <CommentCount>{props.comments.length}</CommentCount>
          </CommentWrapper>
          <ViewAllButton
            type="button"
            onClick={() => {
              setIsShowFullChat((p) => !p);
            }}>
            {isShowFullChat ? 'Скрыть' : 'Показать'}
            <ChevronWrapper topdown={isShowFullChat}>
              <ChevronIcon />
            </ChevronWrapper>
          </ViewAllButton>
        </ChatHeader>
        <ContentWrapper>
          <Content isOpen={isShowFullChat}>
            {props.loadingComments ? (
              <p>Идет загрузка...</p>
            ) : (
              props.comments.map((item) => (
                <CommentCard
                  key={item.id}
                  fullName={item.fullName}
                  description={item.description}
                  date={item.date}
                  deleteComment={props.deleteComment}
                  id={item.id}
                />
              ))
            )}
          </Content>
          {isShowFullChat && (
            <SendingComment
              onAddMessage={props.onMessageAdd}
              isLoading={props.sendingMessage || Boolean(props.loadingComments)}
            />
          )}
        </ContentWrapper>
      </ChatWrapper>
    </Container>
  );
}
