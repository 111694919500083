import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {BlockSidebarWrapper} from '../../../../../elements/BlockSidebarWrapper/BlockSidebarWrapper';
import Status from '../../../../../elements/ChangedStatus/Status';
import Fields from '../../../../../elements/sidebarInfo/generalInfo/component';
import Header from '../../../../../elements/sidebarInfo/header/component';
import User from '../../../../../elements/user/component';
import {useStatuses} from './hooks';
import {
  Wrapper,
  FieldsWrapper,
  StatusWrapper,
  ResponsibleWrapper,
  Title,
  UserWrapper,
  Responsible,
  Field,
  Label,
  Badge,
  ControlGroupWrapper,
  MainInfo,
} from './styles';

interface Props {
  id: Id;
  statusId: Id | null;
  createDate: Date | null;
  applications: Id[];
  calls: Id[];
  isUrgent: boolean;
  region: string;
  author: {
    avatar?: string;
    fullName: string | null;
  };
  responsible: {
    team: string | null;
    group: string | null;
    avatar?: string;
    fullName: string | null;
  };
  controlGroup: string | null;
  onSuccess?(): void;
  onChangeStatus(id: Id): void;
}

export default function GeneralInfo(props: Props) {
  const {data, loading} = useStatuses();

  return (
    <BlockSidebarWrapper title={'Подробная информация'} shouldHideTitleBigSize>
      <Wrapper>
        <MainInfo>
          <Header number={props.id} isUrgently={props.isUrgent} />
          {!loading && (
            <StatusWrapper>
              <Status
                id={props.statusId}
                onChange={props.onChangeStatus}
                options={data}
              />
            </StatusWrapper>
          )}
          <FieldsWrapper>
            <Fields
              list={[
                {
                  type: 'datetime',
                  label: 'Задача создана',
                  value: props.createDate,
                },
                {
                  type: 'links',
                  label: 'Заявка',
                  values: props.applications.map((id) => ({
                    href: `/applications/${id}`,
                    text: id || 'Нет привязанного звонка',
                  })),
                },
                {
                  type: 'links',
                  label: 'Звонок',
                  values: props.calls.map((id) => ({
                    href: `/calls/${id}`,
                    text: id,
                  })),
                },
                {
                  type: 'largeText',
                  label: 'Регионы',
                  value: props.region,
                },
              ]}
            />
          </FieldsWrapper>
        </MainInfo>
        {props.author.fullName != null && props.author.fullName !== '' && (
          <User avatar={props.author.avatar} fullName={props.author.fullName} />
        )}

        <ResponsibleWrapper>
          <Title>Ответственные</Title>
          {props.responsible.fullName != null &&
            props.responsible.fullName !== '' && (
              <UserWrapper>
                <User
                  avatar={props.responsible.avatar}
                  fullName={props.responsible.fullName}
                />
              </UserWrapper>
            )}
          <Responsible>
            {props.responsible.team != null && (
              <Field>
                <Label>Отряд</Label>
                <Badge>{props.responsible.team}</Badge>
              </Field>
            )}
            {props.responsible.group != null && (
              <Field>
                <Label>Группа</Label>
                <Badge>{props.responsible.group}</Badge>
              </Field>
            )}
          </Responsible>
        </ResponsibleWrapper>
        {props.controlGroup != null && props.controlGroup !== '' && (
          <ControlGroupWrapper>
            <Title>Группа контроля</Title>
            <Field>
              <Badge>{props.controlGroup}</Badge>
            </Field>
          </ControlGroupWrapper>
        )}
      </Wrapper>
    </BlockSidebarWrapper>
  );
}
