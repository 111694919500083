import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 300px
`

export const Main = styled.div`
width: 100%;
display: flex;
align-items: center;
background: #fff;
padding: 16px;
box-sizing: border-box;

@media screen and (max-width: 800px) {
  flex-direction: column;
  align-items: start;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  
  @media screen and (max-width: 1200px) {
    display: block;
  }
`

interface IColumn {
  main?: boolean;
  margin?: number;
}

export const Column = styled.div<IColumn>`
  flex: ${p => p.main ? "auto" : "initial"};
  margin: 0 24px 24px 0;
  > *:not(:last-child) {
    margin-bottom: ${p => p.margin || 0}px;
  }
`

export const NumberCard = styled.p`
  margin: 0 24px 24px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #000;
  
  width: 26px;
  
`

export const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`

export const Label = styled.span`
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--scecondary-additional-color);

  font-style: normal;
  font-size: 12px;
  line-height: 16px;

  color: #333333;
`

export const Region = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);
`

export const Address = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`

export const SmallTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
  
  &::after {
    content: ":";
  }
`

export const Phone = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
  text-decoration: none;
  display: block;
`

export const Email = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  
  color: var(--additional-color);
  text-decoration: none;
  display: block;
`

export const Website = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: var(--additional-color);
  text-decoration: none;
  display: block;
`

export const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`

export const Caller = styled.div`
  width: 150px;
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`

export const LabelWeight = styled.b`
  font-weight: 700;
  display: block;

  &::after {
    content: ":";
    margin-right: 4px;
  }
`;

export const Secondary = styled.p`
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;
