import {useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {TEAM_VIDEOS_QUERY} from '../../../../../gql';
import {
  TeamVideos,
  TeamVideosVariables,
} from '../../../../../gql/queries/team/__generated__/TeamVideos';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading, fetchMore} = useQuery<TeamVideos, TeamVideosVariables>(
    TEAM_VIDEOS_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip: params.teamId == null || params.teamId === '',
    }
  );

  const fetch = useCallback(
    () =>
      fetchMore({
        variables: {
          id: (params.teamId || '').toString(),
        },
      }),
    [fetchMore, params.teamId]
  );

  return {
    list: data?.team?.videos == null ? [] : data.team.videos.map((v) => ({
      name: v?.name || '',
      url: v?.url || '',
    })) || [],
    loading,
    fetch
  };
}
