import React, {ReactNode, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Chat} from '../../../elements/chat/component';
import {Tabs} from '../../../elements/Tabs/Tabs';
import {TabConfig} from './ApplicationView.config';
import {useActions, useChat, useTab} from './ApplicationView.hooks';
import {
  Wrapper,
  ContentWrapper,
  Primary,
  Title,
  Secondary,
} from './ApplicationView.styles';
import {ActionBlock} from './components/ActionBlock/ActionBlock';
import {GeneralInfo} from './components/GeneralInfo/GeneralInfo';

export function ApplicationViewScreen() {
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState<ReactNode>(null);
  const {activeTab, handleSwitchTab} = useTab();

  const params = useParams();

  useDocumentTitle(`Заявка #${params.applicationId}`);

  const {comments, loading, handleAddMessage, sendingMessage, refetch} =
    useChat();

  const {handleDeleteComment} = useActions(refetch);

  const currentSettings = TabConfig.find((t) => t.id === activeTab);

  function renderForm() {
    const renderComponent = currentSettings?.renderComponent;

    if (renderComponent == null) return <i>Раздел находится в разработке</i>;

    let key;

    if (searchParams.has('files')) {
      key = 'files';
    } else if (searchParams.has('photos')) {
      key = 'photos';
    }
    if (searchParams.has('videos')) {
      key = 'videos';
    }

    return renderComponent(key, setTitle);
  }

  return (
    <Wrapper>
      <Tabs
        activeTab={activeTab || ''}
        items={TabConfig.map((t) => ({
          id: t.id,
          label: t.label,
        }))}
        onSwitchTab={handleSwitchTab}
      />
      <Secondary shouldHideBigSize>
        <ActionBlock />
        <GeneralInfo />
      </Secondary>
      <Title>{title || currentSettings?.label || 'Вкладка не найдена'}</Title>
      <ContentWrapper>
        <Primary>{renderForm()}</Primary>
        <Secondary>
          <ActionBlock />
          <GeneralInfo />
        </Secondary>
      </ContentWrapper>
      <Chat
        comments={comments}
        loadingComments={loading}
        onMessageAdd={handleAddMessage}
        sendingMessage={sendingMessage}
        deleteComment={handleDeleteComment}
      />
    </Wrapper>
  );
}
