import React from 'react';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useTeam} from './ParticipantList.hooks';

import {ParticipantList as ParticipantListComponent} from '../../../../elements/ParticipantList/ParticipantList';

export function ParticipantList() {
  const {loading, data} = useTeam();

  if (loading) return <Preloader />;

  return (
    <ParticipantListComponent
      users={
        data?.team.users?.map((u) => ({
          id: u?.id || '',
          name: u?.profile.full_name || '',
          nickname: u?.profile.nickname || '',
          address: u?.profile.address || '',
          phones: [
            u?.profile.phone_number || '',
            ...(u?.additionalPhones?.map((p) => p?.number || '') || []),
          ],
          socialNetworkInfo: {
            vk: u?.profile.account_vkontakte || '',
            facebook: u?.profile.account_facebook || '',
            twitter: u?.profile.account_twitter || '',
            telegram: u?.profile.account_telegram || '',
          },
        })) || []
      }
    />
  );
}
