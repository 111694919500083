import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import Preloader from '../../../elements/Preloader/Preloader';
import {RegistrationForm} from '../../../forms/RegistrationFrom/RegistrationForm';

import {useActions, useProfile} from './hooks';
import {Title, RegistrationFormWrapper, Wrapper} from './styles';

export function ProfileEdit() {
  const {loading, data} = useProfile();
  const {loading: editLoading, handleEditProfile} = useActions();

  useDocumentTitle('Редактирование профиля');

  return (
    <Wrapper>
      <Title>Редактирование профиля</Title>
      {loading ? (
        <Preloader />
      ) : (
        <RegistrationFormWrapper>
          <RegistrationForm
            defaultValues={data}
            loading={editLoading}
            disabledPrimaryFields
            hideRegisterFields
            labelSubmit="Сохранить"
            onSubmit={handleEditProfile}
          />
        </RegistrationFormWrapper>
      )}
    </Wrapper>
  );
}
