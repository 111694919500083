import {useQuery} from '@apollo/client';
import {PRIVATE_INFO_QUERY} from '../../../../gql';
import {
  PrivateInfo,
  PrivateInfo_me,
} from '../../../../gql/profile/queries/__generated__/PrivateInfo';

export function useTechnics() {
  const {data, loading, refetch} = useQuery<PrivateInfo, PrivateInfo_me>(
    PRIVATE_INFO_QUERY
  );

  return {
    loading,
    refetch,
    technics:
      data?.me.technics == null
        ? []
        : data.me.technics.map((item) => ({
            id: item?.id || '',
            name: item?.name || '',
            note: item?.note || '',
          })),
  };
}
