import styled from 'styled-components';

export const WrapperLeaders = styled.div`
  background: #fff;
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 19.75rem;
  box-sizing: border-box;
`;

export const TitleLeaders = styled.div`
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.875rem;
  pointer-events: none;
  box-sizing: border-box;
`;

export const LeaderBody = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const AvatarBlock = styled.div`
  margin-right: 1rem;
`;

export const InfoBlock = styled.a`
  color: #376092;
  word-break: break-word;
  margin-bottom: 8px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 1rem;
  &&:hover {
    color: #2c4d75;
    transition: 0.36s;
  }
`;

export const LoginBlock = styled.div`
  box-sizing: border-box;
  color: #7d7d7d;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 6px;
  display: flex;
`;
