import jwt_decode, {JwtPayload} from 'jwt-decode';

export const AuthService = {
  isAuthorized(): boolean {
    return !!localStorage.getItem('accessToken');
  },
  authorize(token: any) {},
  getTokens(): [string, string] {
    return [
      localStorage.getItem('accessToken') || '',
      localStorage.getItem('refreshToken') || '',
    ];
  },
  isAccessTokenExpired(): boolean {
    const token = localStorage.getItem('accessToken') || '';
    const {exp = 0} = jwt_decode<JwtPayload>(token);
    return new Date() < new Date(exp * 1000);
  },
  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
};
