import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {APPLICATION_CREATE_MISSING_FILE_MUTATION} from '../../../../../../gql';
import {
  CreateMissingFile,
  CreateMissingFileVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateMissingFile';

export const useAddFilesHandler = (fetch: () => void) => {
  const [searchParams] = useSearchParams();
  const [requestSending, setRequestSending] = useState(false);

  const [addFileMutation] = useMutation<
    CreateMissingFile,
    CreateMissingFileVariables
  >(APPLICATION_CREATE_MISSING_FILE_MUTATION);

  const handleAddFile = useCallback(
    async (data: {file: File; name: string}) => {
      if (!searchParams.has('files')) return null;
      setRequestSending(true);

      await addFileMutation({
        variables: {
          input: {
            file: data.file,
            name: data.name,
            missingId: Number(searchParams.get('files')),
          },
        },
      })
        .then(() => fetch())
        .finally(() => setRequestSending(false));
    },
    [addFileMutation, fetch, searchParams]
  );
  return {handleAddFile, requestSending};
};
