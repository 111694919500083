import styled from 'styled-components';

export const Wrapper = styled.div``;

export const AvatarBlock = styled.div`
  margin-right: 1rem;
`;

export const UserInfo = styled.div`
  display: flex;
`;

export const Duty = styled.p`
  font-size: 12px;
  color: #7d7d7d;
`;

export const Badge = styled.div`
  background: #a1b2c8;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
`;

export const InfoBlock = styled.a`
  color: #376092;
  word-break: break-word;
  margin-bottom: 8px;
  box-sizing: border-box;
  display: inline;
  text-decoration: none;
  font-size: 1rem;
  &&:hover {
    color: #2c4d75;
    transition: 0.36s;
  }
`;
