import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  color: #333333;
`;

export const DescriptionText = styled.p`
  margin: 4px 0 0;
  font-size: 16px;
  line-height: 22px;

  color: #333333;
`;

export const Result = styled.article`
  margin: 30px 0 0;
  background: #FFFFFF;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px 36px 22px 24px;
`;

export const ResultTitle = styled.h2`
  margin: 0 0 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 22px;

  color: #333333;
`;
