import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {APPLICATION_FILTERS_DICTIONARIES_QUERY} from '../../../../../gql';
import {ApplicationFiltersDictionaries} from '../../../../../gql/application/queries/__generated__/ApplicationFiltersDictionaries';
import {Id} from '../../../../../types/auxiliary.types';

import {createDict} from '../../../../../utils/Dict.utils';
import {Option} from '../../../../elements/Select/Select.types';
import {FormFields} from './Filter.types';

export function useDict() {
  const {data} = useQuery<ApplicationFiltersDictionaries>(
    APPLICATION_FILTERS_DICTIONARIES_QUERY
  );

  const areaDict =
    data == null || data.allRegions == null
      ? {}
      : data.allRegions.reduce((acc: Record<string, Option[]>, region) => {
          if (region == null) return acc;

          return {
            ...acc,
            [region.id]:
              region.districts == null
                ? []
                : region.districts.map((district) => ({
                    label: district?.name || '',
                    value: district?.id || '',
                  })),
          };
        }, {});

  return {
    regions: data == null ? [] : createDict(data.allRegions),
    areas: areaDict,
    locations: data == null ? [] : createDict(data.searchLocations),
    statuses:
      data == null
        ? []
        : data.allGroupSearchStatuses?.map((s) => ({
            id: s?.id || '',
            label: s?.name || '',
            values:
              s?.statuses?.map((s1) => ({
                label: s1?.name || '',
                value: s1?.id != null ? `${s?.id}.${s1?.id}` : '',
              })) || [],
          })) || [],
    subStatuses:
      data?.allGroupSearchStatuses?.reduce(
        (
          acc: Record<Id, {value: string | number; label: string | number}[]>,
          curr
        ) => {
          return {
            ...acc,
            ...(curr?.statuses?.reduce((acc1, curr1) => {
              const currentGroup = curr?.id;
              const currentStatus = curr1?.id;

              const currentKey = `${currentGroup}.${currentStatus}`;
              const currentItem = acc[currentKey] || [];

              return {
                ...acc1,
                ...currentItem,
                [currentKey]: [
                  ...currentItem,
                  ...(curr1?.subStatuses?.map((s) => ({
                    label: s?.name || '',
                    value: s?.id != null ? `${currentKey}.${s.id}` : '',
                  })) || []),
                ],
              };
            }, {}) || {}),
          };
        },
        {}
      ) ||
      ({} as Record<Id, {value: string | number; label: string | number}[]>),
  };
}

export function useAction() {
  const [, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {
      function changeSearch(
        name: keyof FormFields,
        mapping?: (data: any) => string
      ) {
        return (
          data[name] !== '' &&
          data[name] != null &&
          data[name] !== false && {
            [name]: mapping == null ? String(data[name]) : mapping(data[name]),
          }
        );
      }

      const newSearch = {
        ...changeSearch('search'),
        ...changeSearch('region'),
        ...changeSearch('area'),
        ...changeSearch('location'),
        ...changeSearch('dateFrom', (d) => d.toISOString().split('T')[0]),
        ...changeSearch('dateTo', (d) => d.toISOString().split('T')[0]),
        ...changeSearch('statuses', (d) => d),
        ...changeSearch('subStatuses', (d) => d),
        ...changeSearch('missingName'),
        ...changeSearch('gender'),
        ...changeSearch('ageFrom'),
        ...changeSearch('ageTo'),
        ...changeSearch('missingNumber'),
        ...changeSearch('applicantNumber'),
        ...changeSearch('searchParticipantName'),
        ...changeSearch('isUnworked', () => '1'),
        ...changeSearch('isUrgent', () => '1'),
        ...changeSearch('isEducational', () => '1'),
        ...changeSearch('isForestInTouch', () => '1'),
        ...changeSearch('isReverseLookup', () => '1'),
      };

      setSearchParams({
        page: '1',
        ...newSearch,
      });
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams({page: '1'}),
    [setSearchParams]
  );

  return {submit: handleSubmit, handleReset};
}
