import React, {useState} from 'react';
import { Id } from '../../../types/auxiliary.types';
import {AddButton} from '../../elements/AddButton/AddButton';
import Preloader from '../../elements/Preloader/Preloader';
import { TrashIcon } from '../../icons/TrashIcon';
import AddMapModal from '../../modals/AddMap/component';
import {Card, Title, Info, Url, Container, Wrapper, DeleteButton} from './styles';

interface Map {
  id: Id;
  name: string;
  url: string;
}

interface Props {
  loading: boolean;
  actionLoading?: boolean;
  list: Map[];
  onAdd(data: Map): Promise<void>;
  onDelete?(id: Id): Promise<void>;
  fetch(): Promise<void>;
}

export function MapList(props: Props) {
  const [shownModal, setShownModal] = useState(false);

  function renderModal() {
    if (!shownModal) return null;

    return (
      <AddMapModal
        onAdd={props.onAdd}
        onAddSuccess={() => {
          props.fetch()
          setShownModal(false);
        }}
        onModalClose={() => setShownModal(false)}
      />
    );
  }

  function renderCard(card: Map) {
    return (
      <Card key={card.id}>
        <Info>
          <Title>{card.name}</Title>
          <Url href={card.url} target="_blank">
            {card.url}
          </Url>
        </Info>
        {props.onDelete != null && (
          <DeleteButton
            type="button"
            disabled={props.loading || props.actionLoading}
            onClick={() => props.onDelete?.(card.id).then(() => props.fetch())}
            title="Удалить"
          >
            <TrashIcon />
          </DeleteButton>
        )}
      </Card>
    );
  }

  if (props.loading) return <Preloader />;

  return (
    <Wrapper>
      {renderModal()}
      <AddButton
        typeButton="button"
        buttonLabel="Добавить карту"
        disabled={props.actionLoading}
        addItem={() => setShownModal(true)}
      />
      <Container>
        {props.list.length === 0
          ? 'Данные отсутствуют'
          : props.list.map(renderCard)}
      </Container>
    </Wrapper>
  )
}
