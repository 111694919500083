import styled from 'styled-components';

export const CopyrightBox = styled.div`
  display: flex;
  align-items: center;
`;
export const CopyrightText = styled.p`
  margin-right: 10px;
  font-size: 0.875rem;
  color: var(--secondary-text-color);
`;
