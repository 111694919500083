import {useQuery} from '@apollo/client';
import {useState} from 'react';
import {PROFILE_CALLS_QUERY} from '../../../../../gql';
import { ProfileCall } from '../../../../../gql/profile/queries/__generated__/ProfileCall';
import {Id} from '../../../../../types/auxiliary.types';

export function useCalls() {
  const [sendId, setSendId] = useState<Id | null>(null)

  const {data, loading, refetch} = useQuery<ProfileCall>(PROFILE_CALLS_QUERY, {
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    modalMeta: sendId == null ? undefined : {
      id: sendId,
      type: "call" as "call",
      onSendSuccess: refetch,
      onResetMeta: () => setSendId(null)
    },
    data: data?.me.calls == null ? [] : data.me.calls.map((c) => ({
      number: c?.id || 0,
      isUrgent: Boolean(c?.is_urgent),
      status: c?.status?.name || "",
      statusColor: c?.status?.color || "",
      fullNameResponsible: c?.contact || "",
      phoneResponsible: c?.phone || "",
      region: c?.region?.name || "",
      type: c?.type?.name || "",
      dateTime: c?.date_time == null ? null : new Date(c.date_time),
      ringingCount: c?.callingCount ?? undefined,
      taskCount: undefined, //FIXME:
      commentNumber: c?.commentCount ?? undefined,
      onSendData: () => setSendId(c?.id || 0)
    }))
  }
}
