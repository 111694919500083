import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Logo} from '../../../../elements/Logo/Logo';
import {ArrowIcon} from '../../../../icons/ArrowIcon';
import {MenuConfig} from '../../Menu.types';
import {LogoWrapper, MenuContainer, MenuItem, Wrapper} from './FullMenu.styles';

interface Props extends MenuConfig {
  activeTab: string;
  onClose?: () => void;
}

export function FullMenu(props: Props) {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const {pathname} = useLocation();

  useEffect(() => {
    if (pathname.match(/\/\w+\/\w+/)) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [pathname, visible]);

  const mainPathname: RegExpMatchArray | null = pathname.match(/\/\w+/);

  const $pathname: string | undefined = mainPathname?.[0];

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate($pathname ? $pathname : '/');
    } else {
      navigate('/', {replace: true});
    }
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo variant="medium" />
      </LogoWrapper>
      <MenuContainer>
        {visible && (
          <MenuItem title={'Back'} active={false}>
            <div onClick={handleBack}>
              <ArrowIcon /> Назад
            </div>
          </MenuItem>
        )}
        {props.items.map((item) => {
          const Icon = item.icon;

          return (
            <MenuItem
              onClick={props.onClose}
              key={item.id}
              title={item.name}
              active={item.id === props.activeTab}>
              <Link to={item.linkTo}>
                <Icon /> {item.name}
              </Link>
            </MenuItem>
          );
        })}
      </MenuContainer>
    </Wrapper>
  );
}
