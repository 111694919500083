import React from 'react';
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {useCurrSearch} from '../../../../../hooks/useUrl.hooks';
import MainInfo from '../../../../cards/elements/mainInfo/component';
import {ListFilter} from '../../../../elements/ListFilter/ListFilter';
import Preloader from '../../../../elements/Preloader/Preloader';
import SelectField from '../../../../elements/SelectField/SelectField';
import User from '../../../../elements/user/component';
import {useEquipments} from './hooks';
import {
  Card,
  Category,
  Description,
  Status,
  Title,
  List,
  Wrapper,
  Column,
  UserWrapper,
  StatusColumn,
  Form,
  Filters,
} from './styles';
import { FormFields } from './types';

export default function Equipments() {
  const [searchParams, setSearchParams] = useSearchParams();
  const prevState = useCurrSearch();

  const {loading, list, statusDict, categoryDict} = useEquipments();

  const {register, formState, control, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      search: searchParams.get("search") || "",
      categoryIds: searchParams.getAll("categoryIds") || [],
      statusIds: searchParams.getAll("statusIds") || [],
    }
  })

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit((data) => {
        setSearchParams({
          ...prevState,
          ...data
        })
      })}>
        <ListFilter
          register={register("search")}
          showReset
          submitLabelButton="Применить"
          placeholder="Искать по номеру, названию или ФИО хранителя"
        >
          <Filters>
            <SelectField
              name="categoryIds"
              placeholder="Выберите"
              label="Категория"
              disabled={formState.isSubmitting}
              options={categoryDict}
              control={control}
              multiple
            />
            <SelectField
              name="statusIds"
              placeholder="Выберите"
              label="Статус"
              disabled={formState.isSubmitting}
              options={statusDict}
              control={control}
              multiple
            />
          </Filters>
        </ListFilter>
      </Form>

      {loading ? (
        <Preloader />
      ) : (
        <List>
          {list.map(l => (
            <Card key={l.id}>
              <MainInfo number={Number(l.id)} />
              <Column>
                <Title>{l.name}</Title>
                {l.category != null && (<Category>{l.category}</Category>)}
                {l.description != null && (<Description>{l.description}</Description>)}
              </Column>
              <UserWrapper>
                {l.user.fullName !== undefined && l.user.fullName !== "" && (
                  <User
                    label="Хранитель"
                    hasLabelPostfix
                    avatar={l.user.avatar}
                    fullName={l.user.fullName}
                  />
                )}
              </UserWrapper>
              <StatusColumn>
                <Status color={l.status.color}>{l.status.name}</Status>
              </StatusColumn>
            </Card>
          ))}
        </List>
      )}
    </Wrapper>
  );
}
