import styled from 'styled-components';

export const Wrapper = styled.form` 
  display: flex;
  flex-direction: column;
`;

export const FieldsWrapper = styled.div`
  flex: auto;
  
  min-height: 150px;
`;

export const Tooltip = styled.p`
  margin: 0 0 24px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;

  color: #000;
`;

export const Buttons = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
