import React from 'react';
import {SvgStyled} from './Icons.styles';

export const PhotoIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99964 12.3542C3.93235 12.3542 2.98299 11.6708 2.63825 10.6535L2.61494 10.5768C2.53364 10.3074 2.49958 10.0808 2.49958 9.85412V5.30859L0.882228 10.7074C0.674215 11.5015 1.14823 12.3248 1.94353 12.5441L12.2525 15.3049C12.3812 15.3383 12.5098 15.3543 12.6365 15.3543C13.3005 15.3543 13.9072 14.9136 14.0773 14.2641L14.6779 12.3542H4.99964Z"
        fill="#E5F1FF"
      />
      <path
        d="M6.83311 5.35431C7.56847 5.35431 8.16639 4.75628 8.16639 4.02091C8.16639 3.28554 7.56847 2.6875 6.83311 2.6875C6.09774 2.6875 5.4997 3.28554 5.4997 4.02091C5.4997 4.75628 6.09774 5.35431 6.83311 5.35431Z"
        fill="#E5F1FF"
      />
      <path
        d="M15.1666 0.6875H5.16632C4.24771 0.6875 3.49965 1.43557 3.49965 2.35429V9.68772C3.49965 10.6064 4.24771 11.3545 5.16632 11.3545H15.1666C16.0853 11.3545 16.8334 10.6064 16.8334 9.68772V2.35429C16.8334 1.43557 16.0853 0.6875 15.1666 0.6875ZM5.16632 2.02091H15.1666C15.3506 2.02091 15.4999 2.1702 15.4999 2.35429V7.08707L13.3939 4.62961C13.1705 4.36764 12.8472 4.22763 12.4999 4.21969C12.1545 4.22164 11.8305 4.37497 11.6092 4.64036L9.13309 7.61235L8.32643 6.80765C7.87048 6.3517 7.1284 6.3517 6.67307 6.80765L4.83306 8.64705V2.35429C4.83306 2.1702 4.98235 2.02091 5.16632 2.02091Z"
        fill="#E5F1FF"
      />
    </SvgStyled>
  );
};
