import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-left: 76px;
  }

  > :not(:last-child) {
    margin-right: 32px;
  }
`;
