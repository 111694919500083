import {
  callRequestsListVariables,
  callRequestsList,
} from './../../../../../../gql/application/queries/__generated__/callRequestsList';
import {APPLICATION_CALL_REQUEST_QUERY} from './../../../../../../gql/index';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

export function useCallRequesList() {
  const params = useParams<{applicationId?: string}>();

  const {data, loading, refetch} = useQuery<
    callRequestsList,
    callRequestsListVariables
  >(APPLICATION_CALL_REQUEST_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
  });
  return {
    loading,
    refetch,
    list:
      data?.issue.callRequests == null
        ? []
        : data?.issue.callRequests.map((item, i) => ({
            id: item?.id || '',
            urgency: item?.urgency || 2,
            createdAt: new Date(item?.created_at || ''),
            profile: item?.createdBy?.profile.full_name,
          })),
  };
}
