import React from 'react';
import {Button} from '../../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../../elements/ModalWrapper/ModalWrapper';
import {
  ButtonWrapper,
  Wrapper,
} from '../../../../../layouts/Header/components/User/User.styles';

interface Props {
  confirm: () => void;
  close: () => void;
}

export function DeleteModal(props: Props) {
  return (
    <ModalWrapper title={'Подтвердите удаление'} onModalClose={props.close}>
      <Wrapper>
        <ButtonWrapper>
          <Button
            title="Да, удалить"
            variant={'contained'}
            color={'primary'}
            onClick={props.confirm}
          />
        </ButtonWrapper>
        <Button
          title="Отмена"
          variant={'outlined'}
          color={'primary'}
          onClick={props.close}
        />
      </Wrapper>
    </ModalWrapper>
  );
}
