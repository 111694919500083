import React from 'react';
import styled, {css} from 'styled-components';
import {TextProps} from './Text';

const TextComponent = (props: TextProps) => {
  const {tag = 'p', children, className} = props;
  const CustomTag = tag;
  return <CustomTag className={className}>{children}</CustomTag>;
};

export const StyledText = styled(TextComponent)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  ${(props) =>
    props.variant === 'h1' &&
    css`
      font-size: 30px;
      line-height: 42px;
    `}

  ${(props) =>
    props.variant === 'h2' &&
    css`
      font-size: 24px;
      line-height: 34px;
    `}

  ${(props) =>
    props.variant === 'h3' &&
    css`
      font-size: 18px;
      line-height: 24px;
    `}

  ${(props) =>
    props.variant === 'h4' &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}
  
  ${(props) =>
    props.variant === 'h4' &&
    css`
      font-size: 14px;
      line-height: 19px;
    `}
  
  ${(props) =>
    props.variant === 'p1' &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}

  ${(props) =>
    props.variant === 'p2' &&
    css`
      font-size: 14px;
      line-height: 19px;
    `}

  ${(props) =>
    props.variant === 'tips' &&
    css`
      font-size: 12px;
      line-height: 16px;
    `}
`;
