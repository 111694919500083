import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {Badge} from '../Badge/Badge';

import {
  Wrapper,
  FlexBox,
  HeaderWrapper,
  Subtitle,
  AddressBlock,
  TitleWrapper,
  Title,
  ContactBlock,
  Description,
  ContactBlockTitle,
  ContactBlockItem,
  SubtitleItem,
} from './InstitutionItem.styles';

interface Props {
  id: Id;
  region: any;
  district: any;
  institutionType: any;
  name: any;
  address: string;
  phoneNumber: string;
  email: string;
  note: string;
  websiteLink: string;
}

export default function InstitutionItem(props: Props) {
  return (
    <Wrapper>
      <HeaderWrapper>
        <FlexBox>
          <TitleWrapper>
            <Title>{props.name}</Title>
            {props.institutionType != null && (
              <Badge title={props.institutionType} color="optional" />
            )}
          </TitleWrapper>
          <Subtitle>
            <SubtitleItem>
              {props.region}, {props.district}
            </SubtitleItem>
          </Subtitle>
          <AddressBlock>{props.address}</AddressBlock>
        </FlexBox>
        <FlexBox>
          <ContactBlock>
            <ContactBlockTitle>Контакты</ContactBlockTitle>
            <ContactBlockItem href={`tel:${props.phoneNumber}`}>
              {props.phoneNumber}
            </ContactBlockItem>
            {props.email != null && (
              <ContactBlockItem href={`mailto:${props.email}`}>
                {props.email}
              </ContactBlockItem>
            )}
            {props.websiteLink != null && (
              <ContactBlockItem href={props?.websiteLink}>
                {props.websiteLink}
              </ContactBlockItem>
            )}
          </ContactBlock>
        </FlexBox>
      </HeaderWrapper>
      <Description>{props.note}</Description>
    </Wrapper>
  );
}
