import React from 'react';
import {Verification} from '../../../modals/Verification/component';
import {useVerificationHandler} from './hooks';

interface Props {
  onAddSuccess(): void;
  onModalClose(): void;
}

export function AddVerification(props: Props) {
  const {submit, loading} = useVerificationHandler(props.onAddSuccess);

  return (
    <Verification
      title="Добавить верификацию"
      loading={loading}
      labelSubmitButton="Добавить"
      submit={submit}
      onModalClose={props.onModalClose}
    />
  );
}
