import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useAlgorithm} from './Algorithms.hooks';
import {
  AlgorithmItem,
  AlgorithmName,
  ItemWrapper,
  Wrapper,
  Title,
} from './Algorithms.styles';

export default function Algorithms() {
  useDocumentTitle('Алгоритмы');

  const {algorithm, loading} = useAlgorithm();

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Алгоритмы</Title>
      {loading ? (
        <Preloader />
      ) : (
        <ItemWrapper>
          {algorithm.map((item) => {
            return (
              <ItemWrapper key={item.id}>
                <AlgorithmItem
                  onClick={() => navigate(`/algorithms/${item.id}`)}>
                  <AlgorithmName>{item.name}</AlgorithmName>
                </AlgorithmItem>
              </ItemWrapper>
            );
          })}
        </ItemWrapper>
      )}
    </Wrapper>
  );
}
