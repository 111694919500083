import styled from 'styled-components';

export const CalendarIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;

  path {
    fill: var(--additional-color);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .date_time_picker {
    position: relative;

    .react-datetime-picker__wrapper {
      border: none;
    }

    .react-calendar {
      padding: 16px 16px 8px;
      background: #ffffff;
      box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: none;
    }

    .react-datetime-picker__inputGroup,
    .react-datetime-picker,
    .react-datetime-picker--closed,
    .react-datetime-picker--enabled input {
      position: relative;
      padding: 14px 21px 15px 42px;

      border: 1px solid #e7e7e7ff !important;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 14px;
      line-height: 19px;
      color: #333333ff;

      &::placeholder {
        font-size: 14px;
        line-height: 19px;
        color: #7d7d7dff;
      }
    }

    .react-datepicker__navigation {
      top: 10px;
      &:active,
      &:focus {
        outline: none;
      }
    }

    .react-datetime-picker__inputGroup__month,
    .react-datetime-picker__inputGroup__day,
    .react-datetime-picker__inputGroup__year {
      background-color: inherit;
      position: relative;
    }

    .react-calendar__tile--now {
      color: #333333ff;
      border: 1px solid rgba(51, 51, 51, 0.8);
      background-color: transparent;
    }

    .react-calendar__tile--active {
      color: #fff;
      border: 1px solid #ff6600ff;
      background-color: #ff6600ff;
    }

    .react-calendar__decade-view__years__year,
    .react-calendar__century-view__decades__decade {
      width: 64px;
      height: 64px;
      box-sizing: border-box;
      margin: 0;
      flex: none !important;
      font-family: Nunito Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 50%;

      color: #333333ff;
      transition: color, background-color, border 500ms linear;
    }

    .react-calendar__year-view__months__month {
      width: 64px;
      height: 64px;
      box-sizing: border-box;
      margin: 0;
      flex: none !important;

      font-family: Nunito Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 50%;

      color: #333333ff;
      transition: color, background-color, border 500ms linear;
    }

    .react-calendar__month-view__days__day,
    .react-calendar__month-view__days__day--weekend {
      font-family: Nunito Sans, sans-serif;
      font-size: 13px;
      border: 1px solid transparent;
      border-radius: 50%;

      transition: color, background-color, border 500ms linear;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      text-decoration: none;
    }
  }
`;

export const Input = styled.input`
  position: relative;
  padding: 14px 21px 15px 42px;

  border: 1px solid var(--primary-border-color);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  color: var(--black-color);

  &::placeholder {
    font-size: 14px;
    line-height: 19px;
    color: var(--secondary-text-color);
  }
`;
