import styled from 'styled-components';

export const Wrapper = styled.article`
  background: #fff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const PrimaryInfo = styled.div`
  padding: 24px 24px 25px;
  box-sizing: border-box;
`;

export const MissingMainInfo = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 850px) {
    margin-bottom: 8px;
  }
`;

interface IGenderWrapper {
  gender: 'm' | 'w';
}

const fillGender = {
  m: '--secondary-color',
  w: '--warning-color',
};

export const GenderWrapper = styled.div<IGenderWrapper>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: var(${(p) => fillGender[p.gender]});
  }
`;

export const Age = styled.p`
  margin: 0 8px 0 0;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

interface IName {
  fullShow?: boolean;
}

export const Name = styled.p<IName>`
  display: ${(p) => (p.fullShow ? 'block' : 'none')};
  margin: 0 15px 0 0;
  flex: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #333;

  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 850px) {
    display: ${(p) => (p.fullShow ? 'none' : 'block')};
    margin: 0 0 16px;
  }
`;

export const Statuses = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 18px;

  > * {
    margin-bottom: 8px;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 850px) {
    margin-bottom: 8px;
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const Label = styled.div`
  padding: 8px 22px 8px 16px;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--scecondary-additional-color);
  border-radius: 4px;

  @media screen and (max-width: 850px) {
    width: 88px;
    padding: 4px 2px;
  }
`;

export const LabelIcon = styled.div`
  svg path {
    fill: var(--additional-color);
  }
`;

interface ILabelText {
  acceptable?: boolean;
}

export const LabelText = styled.p<ILabelText>`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: var(
    ${(p) =>
      p.acceptable == null
        ? '--additional-color'
        : p.acceptable
        ? '--secondary-color'
        : '--warning-color'}
  );
`;

interface IAdditional {
  showFull?: boolean;
  scrollHeight?: number | null;
}

export const AdditionalInfo = styled.div<IAdditional>`
  position: relative;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 850px) {
    max-height: ${(p) =>
      p.showFull
        ? p.scrollHeight != null
          ? `${p.scrollHeight}px`
          : '100%'
        : '320px'};

    transition: max-height 500ms linear;
    overflow-y: hidden;
  }
`;

export const Field = styled.div`
  display: flex;

  @media screen and (max-width: 850px) {
    display: block;
  }
`;

export const FieldLabel = styled.p`
  width: 142px;
  margin: 0 22px 0 0;
  flex-shrink: 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);

  @media screen and (max-width: 850px) {
    width: 100%;
    margin: 0 0 8px;
  }
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 850px) {
    display: block;
  }
`;

export const FieldValueMain = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 16px;

    margin-right: 10px;

    path {
      fill: #333;
    }
  }
`;

export const FieldValue = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const FieldValueLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  > *:not(:first-child) {
    margin-left: 16px;
  }

  @media screen and (max-width: 850px) {
    display: block;
    margin-left: 0;

    > *:not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const FieldValueLabel = styled.p`
  margin: 0;
  width: fit-content;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #333;

  background: #e5f1ff;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;

  @media screen and (max-width: 850px) {
    margin-top: 8px;
  }
`;

export const ApplicantCard = styled.div`
  width: 264px;
  padding: 16px 30px 16px 16px;
  box-sizing: border-box;

  background: var(--scecondary-additional-color);
  border-radius: 4px;

  display: flex;

  @media screen and (max-width: 850px) {
    max-width: 264px;
    width: 100%;
  }
`;

export const ApplicantPerson = styled.div`
  margin-left: 16px;
`;

export const ApplicantTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const PhonesWrapper = styled.div`
  margin: 24px 0;
`;

export const PhoneWrapper = styled.div`
  margin: 10px 0 0;

  display: flex;
`;

export const MissingPhone = styled.p`
  margin: 0 16px 0 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #333;
`;

export const CoordinatesWrapper = styled.div``;

export const Coordinates = styled.div`
  margin: 8px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--additional-color);

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;

    path {
      fill: var(--additional-color);
    }
  }
`;

interface IOpacityBlock {
  showFull?: boolean;
}

export const OpacityBlock = styled.div<IOpacityBlock>`
  display: none;

  width: 100%;
  height: 190px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.921875) 68.75%,
    #ffffff 100%
  );

  position: absolute;
  bottom: 0;

  @media screen and (max-width: 850px) {
    display: flex;
    align-items: end;
  }
`;

export const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: #2c507c;

  transition: color 500ms linear;

  cursor: pointer;

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const SecondaryInfo = styled.div`
  display: flex;

  background-color: var(--additional-color);
  border-radius: 0 0 8px 8px;
  overflow: hidden;

  > :not(:last-child) {
    border-right: 1px solid #a1b2c8;
  }
`;

export const Attach = styled.p`
  width: 100%;

  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--additional-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--scecondary-additional-color);

  cursor: pointer;

  svg {
    margin-right: 8px;

    path {
      fill: var(--scecondary-additional-color);
    }
  }
`;

export const AttachName = styled.span`
  @media screen and (max-width: 850px) {
    display: none;
  }
`;
