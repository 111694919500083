import styled from 'styled-components';

interface IColumn {
  displayShown?: 'big' | 'small';
}

export const Column = styled.div<IColumn>`
  display: ${(p) =>
    p.displayShown == null || p.displayShown === 'big' ? 'block' : 'none'};

  @media screen and (max-width: 800px) {
    display: ${(p) =>
      p.displayShown == null || p.displayShown === 'small' ? 'block' : 'none'};
  }
`;

interface ILine {
  displayShown?: 'big' | 'small';
}

export const Line = styled.div<ILine>`
  display: ${(p) =>
    p.displayShown == null || p.displayShown === 'big' ? 'flex' : 'none'};
  align-items: center;

  @media screen and (max-width: 800px) {
    display: ${(p) =>
      p.displayShown == null || p.displayShown === 'small' ? 'flex' : 'none'};
  }

  > *[data-element='label'] {
    margin: 0 4px 0 0;
  }
`;

interface ILabel {
  displayShown?: 'big' | 'small';
}

export const Label = styled.p<ILabel>`
  display: ${(p) =>
    p.displayShown == null || p.displayShown === 'big' ? 'flex' : 'none'};

  margin: 0 0 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);

  @media screen and (max-width: 800px) {
    display: ${(p) =>
      p.displayShown == null || p.displayShown === 'small' ? 'flex' : 'none'};
  }
`;
