import React from 'react';
import Fields, {Props as FieldsProps} from '../generalInfo/component';

import {Wrapper, Title} from './FieldsBlock.styles';

interface Props {
  title: string;
  fields:
    | {
        title: string;
        value: string;
      }[]
    | FieldsProps['list'];
}

export function FieldsBlock(props: Props) {
  return (
    <Wrapper>
      <Title>{props.title}:</Title>
      <Fields
        list={
          props.fields.some((f) => 'type' in f)
            ? (props.fields as FieldsProps['list'])
            : (
                props.fields as {
                  title: string;
                  value: string;
                }[]
              ).map((field) => ({
                type: 'largeText',
                label: field.title,
                value: field.value,
              }))
        }
      />
    </Wrapper>
  );
}
