import styled from 'styled-components';

export const Section = styled.section`
  padding: 24px 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(216, 226, 237, 0.5);
  }

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;
