import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {UseFormSetError} from 'react-hook-form';
import {TASK_CREATE_MUTATION} from '../../../../gql';
import {
  CreateTask,
  CreateTaskVariables,
} from '../../../../gql/task/mutations/__generated__/CreateTask';
import {FormFields} from '../components/form/types';
import {toast} from 'react-toastify';

export function useTask() {

  const navigate = useNavigate();

  const [createTaskRequest, {loading}] = useMutation<
    CreateTask,
    CreateTaskVariables
  >(TASK_CREATE_MUTATION);

  function createTask(
    data: FormFields,
    setServerError: UseFormSetError<FormFields>
  ) {
    createTaskRequest({
      variables: {
        input: {
          isUrgent: data.isUrgently,
          regionId: Number(data.regionId),
          teamId: Number(data.teamId),
          ...(data.groupId !== '' &&
            data.groupId != null && {
              groupId: Number(data.groupId),
            }),
          ...(data.userId !== '' &&
            data.userId != null && {
              responsibleId: Number(data.userId),
            }),
          controlGroupId: Number(data.controlGroupId),
          ...(data.applicationId !== '' &&
            data.applicationId != null && {
              issueId: Number(data.applicationId),
            }),
          ...(data.callId !== '' &&
            data.callId != null && {
              callId: Number(data.callId),
            }),
          description: data.description,
        },
      },
    })
      .then(() => {
        toast('Задача успешно создана!');
        navigate('/tasks');
      })
      .then(() => {
        window.alert('Задача успешно создана!');
        navigate('/tasks');
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      });
  }

  return {createTask, loading};
}
