import React from 'react';
import {Id} from '../../../../../../../types/auxiliary.types';
import {Missing} from '../Missing/Missing';
import {FormFields} from '../Missing/Missing.types';
import {useHandler} from './hooks';

interface Props {
  editValues: FormFields;
  missingId: Id;
  onClose: () => void;
  onSuccess?: () => void;
}

export function EditMissing(props: Props) {
  const {handleSubmit} = useHandler(props.missingId);

  return (
    <Missing
      title="Редактирование пропавшего"
      defaultValues={props.editValues}
      onClose={props.onClose}
      onSave={(d, error) =>
        handleSubmit(d, error, () => {
          props.onSuccess?.();
          props.onClose();
        })
      }
    />
  );
}
