import styled from 'styled-components';

export const Form = styled.form``;

export const Wrapper = styled.div`
  padding-bottom: 24px;

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Tooltip = styled.p`
  margin: 0 0 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #000;
`;

export const DateWrapper = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }

  *[data-element='time-field'] {
    margin-top: 24px;
    max-width: 145px;
  }

  *[data-element='date-field'] {
    flex: auto;
  }
`;

export const Buttons = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
