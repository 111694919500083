import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {toast} from 'react-toastify';
import {APPLICATION_EDIT_MISSING_MUTATION} from '../../../../../../../gql';
import {
  EditMissing,
  EditMissingVariables,
} from '../../../../../../../gql/application/mutations/__generated__/EditMissing';
import {Id} from '../../../../../../../types/auxiliary.types';
import {createDate_DTO} from '../../../../../../../utils/Date.utils';
import {FormFields} from '../Missing/Missing.types';

export function useHandler(missingId: Id) {
  const [editMissing] = useMutation<EditMissing, EditMissingVariables>(
    APPLICATION_EDIT_MISSING_MUTATION
  );

  // const {addServerError} = useServerErrors<FormFields>({
  //   'input.lastName': 'firstName',
  //   'input.fullName': 'lastName',
  //   'input.secondName': 'middleName',
  //   'input.sex': 'gender',
  //   'input.birthday': 'birthday',
  //   'input.havingPhoneStatus': 'hasPhone',
  //   'input.availabilityPhoneStatus': 'availablePhone',
  //   'input.phoneNumber': 'phone',
  //   'input.additionalPhones': 'additionalPhones',
  //   'input.appearance': 'appearance',
  //   'input.health': 'health',
  //   'input.diseases': 'diseases',
  //   'input.clothes': 'cloth',
  //   'input.things': 'withSelf',
  //   'input.additionalInformation': 'additionalInformation',
  // });

  const handleSubmit = useCallback(
    async (
      data: FormFields,
      setServerError: UseFormSetError<FormFields>,
      callback?: () => void
    ) => {
      await editMissing({
        variables: {
          input: {
            missingId: Number(missingId),
            // batteryCharge: String
            // bonfire: String
            // detectionCoordinates: String
            // mentalCondition: Int
            // missingId: Int!
            // mobileConnectionQuality: Int
            // pilotInformation: String
            // water: String

            lastName: data.lastName,
            firstName: data.firstName,
            ...(data.middleName != null &&
              data.middleName !== '' && {
                secondName: data.middleName,
              }),
            sex: data.gender === 'm' ? 'male' : 'female',
            birthdayString:
              data.birthdayString == null ? '' : data.birthdayString,
            havingPhoneStatus: data.hasPhone.toString(),
            availabilityPhoneStatus: data.availablePhone.toString(),
            ...(data.phone != null &&
              data.phone !== '' && {
                phoneNumber: data.phone,
              }),
            additionalPhones: data.additionalPhones,
            health: data.health,
            diseases: data.diseases.map((d) => Number(d)),
            ...(data.appearance != null && {
              appearance: data.appearance,
            }),
            ...(data.cloth != null && {
              clothes: data.cloth,
            }),
            ...(data.withSelf != null && {
              things: data.withSelf,
            }),
            ...(data.additionalInformation != null && {
              additionalInformation: data.additionalInformation,
            }),
          },
        },
      })
        .then(() => callback?.())
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [editMissing, missingId]
  );

  return {handleSubmit};
}
