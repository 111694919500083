import styled from 'styled-components';

export const Form = styled.form`
  flex: auto;

  display: flex;
  flex-direction: column;
  
  max-height: calc(100vh - 16%);

  overflow: auto;
`;

export const Fields = styled.div`  
  flex: auto;
  margin-bottom: 24px;
  
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding-top: 24px;

  display: flex;
  align-items: center;
  justify-content: end;

  background-color: #fff;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;

