import {Url} from '../../../../../types/auxiliary.types';
import {
  ContactsWrapper,
  Data,
  IconsWrapper,
  LinkIcon,
  PhoneLink,
  Name,
  PersonInfo,
  Wrapper,
} from './Card.styles';
import {Avatar} from '../../../../elements/Avatar/Avatar';
import {VKIcon} from '../../../../icons/VKIcon';
import {FacebookIcon} from '../../../../icons/FacebookIcon';
import {TwitterIcon} from '../../../../icons/TwitterIcon';

interface Props {
  avatar?: Url;
  fullName: string;
  nickName: string;
  telegram?: string;
  phones?: string[];
  email?: string;
  vk?: Url;
  facebook?: Url;
  twitter?: Url;
}

export function Card(props: Props) {
  return (
    <Wrapper>
      <PersonInfo>
        <Avatar fullName={props.fullName} src={props.avatar} size="small" />
        <Data>
          <Name>{props.fullName}</Name>
          <Name thin>{props.nickName}</Name>
        </Data>
      </PersonInfo>

      <ContactsWrapper>
        {props.telegram != null && (
          <PhoneLink href={`tg://resolve?domain=${props.telegram}`}>
            {props.telegram}
          </PhoneLink>
        )}

        <PhoneLink href={`tel:${props.phones}`}>{props.phones}</PhoneLink>

        {props.email != null && (
          <PhoneLink href={`mailto:${props.email}`}>{props.email}</PhoneLink>
        )}
      </ContactsWrapper>

      <IconsWrapper>
        {props.vk != null && (
          <LinkIcon href={props.vk}>
            <VKIcon />
          </LinkIcon>
        )}
        {props.facebook != null && (
          <LinkIcon href={props.facebook}>
            <FacebookIcon />
          </LinkIcon>
        )}
        {props.twitter != null && (
          <LinkIcon href={props.twitter}>
            <TwitterIcon />
          </LinkIcon>
        )}
      </IconsWrapper>
    </Wrapper>
  );
}
