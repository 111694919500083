import {useSearchParams} from 'react-router-dom';
import {useUrlParamInitialization} from '../../../../hooks/useUrl.hooks';

export function useTab() {
  useUrlParamInitialization({
    tab: 'description',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {
    handleSwitchTab,
    activeTab,
  };
}
