import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: block;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  *[data-element='text-field'] {
    max-width: 648px;
  }
`;

export const GridFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 312px);

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  @media screen and (max-width: 1110px) {
    grid-template-columns: repeat(2, 312px);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, auto);
  }
`;
