

import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {CREATE_TEAM_MAP_MUTATION, TEAM_MAPS_QUERY} from '../../../../../gql';
import {CreateTeamMap, CreateTeamMapVariables} from '../../../../../gql/mutations/__generated__/CreateTeamMap';
import {
  TeamMaps,
  TeamMapsVariables,
} from '../../../../../gql/queries/team/__generated__/TeamMaps';
import {FormFields} from './types';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading, fetchMore} = useQuery<TeamMaps, TeamMapsVariables>(
    TEAM_MAPS_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip: params.teamId == null || params.teamId === '',
    }
  );

  const fetch = useCallback(
    () =>
      fetchMore({
        variables: {
          id: (params.teamId || '').toString(),
        },
      }),
    [fetchMore, params.teamId]
  );

  return {
    list: data?.team?.maps == null ? [] : data.team.maps.map((m) => ({
      id: m?.name || '',
      name: m?.name || '',
      url: m?.url || '',
    })) || [],
    loading,
    fetch
  };
}

export function useActions() {
  const [createTeamMapMutation] = useMutation<
    CreateTeamMap,
    CreateTeamMapVariables
    >(CREATE_TEAM_MAP_MUTATION);

  function handleAddMap(data: FormFields) {
    return createTeamMapMutation({
      variables: {
        input: data,
      },
    })
  }

  return {handleAddMap}
}
