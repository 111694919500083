import {ApolloError, useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {SIGN_UP_MUTATION} from '../../../gql';

import {
  SignUp,
  SignUpVariables,
} from '../../../gql/mutations/__generated__/SignUp';
import {useServerErrors} from '../../../hooks/useErrors.hooks';
import {AuthService} from '../../../services/authService';
import {createDate_DTO} from '../../../utils/Date.utils';
import {FormFields} from '../../forms/RegistrationFrom/RegistrationFrom.types';

export const useSignUpHandler = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const navigate = useNavigate();

  const {addServerError} = useServerErrors<FormFields>({
    'input.fullName': 'personalInfo.firstName',
    'input.nickname': 'personalInfo.nick',
    'input.birthday': 'personalInfo.birthday',
    'input.driverLicenseCategories': 'personalInfo.category',
    'input.email': 'contactInfo.primaryEmail',
    'input.additionalEmails': 'contactInfo.secondaryEmails',
    'input.phoneNumber': 'contactInfo.primaryPhone',
    'input.additionalPhones': 'contactInfo.secondaryPhones',
    'input.address': 'contactInfo.address',
    'input.teamId': 'squadInfo.squad',
    'input.groups': 'squadInfo.roles',
    'input.regions': 'squadInfo.regions',
    'input.accountVkontakte': 'socialNetworkInfo.vk',
    'input.accountFacebook': 'socialNetworkInfo.facebook',
    'input.accountTwitter': 'socialNetworkInfo.twitter',
    'input.accountTelegram': 'socialNetworkInfo.telegram',
    'input.qualification': 'additionalInfo.qualification',
  });

  const [successRequest, setSuccessRequest] = useState(false);

  const [signUpMutation] = useMutation<SignUp, SignUpVariables>(
    SIGN_UP_MUTATION
  );

  const handleSubmit = useCallback(
    async (data: FormFields, setError?: UseFormSetError<FormFields>) => {
      if (data.personalInfo.birthday == null) return null;

      await signUpMutation({
        variables: {
          input: {
            ...(token != null &&
              token !== '' && {
                token: token,
              }),
            email: data.contactInfo.primaryEmail,
            fullName: [
              data.personalInfo.lastName,
              data.personalInfo.firstName,
              data.personalInfo.middleName,
            ]
              .filter(Boolean)
              .join(' '),
            phoneNumber: data.contactInfo.primaryPhone,
            birthday: createDate_DTO(data.personalInfo.birthday),
            address: data.contactInfo.address,
            teamId: data.squadInfo.squad as number,
            groups: data.squadInfo.roles.map((s) => Number(s)),
            qualification: data.additionalInfo.qualification,
            nickname: data.personalInfo.nick,
            accountVkontakte: data.socialNetworkInfo.vk,
            accountFacebook: data.socialNetworkInfo.facebook,
            accountTelegram: data.socialNetworkInfo.telegram,
            accountTwitter: data.socialNetworkInfo.twitter,
            regions: data.squadInfo.regions.map((s) => Number(s)),
            additionalEmails: data.contactInfo.secondaryEmails,
            additionalPhones: data.contactInfo.secondaryPhones,
            driverLicenseCategories:
              data.personalInfo.category?.map((s) => s.toString()) || [],
          },
        },
      })
        .then((r) => {
          if (r.data?.signUp?.token != null) {
            AuthService.authorize(r.data?.signUp?.token);
            navigate('/');
          } else {
            setSuccessRequest(true);
          }
        })
        .catch((e) =>
          addServerError(new ApolloError(e).graphQLErrors, setError)
        );
    },
    [addServerError, navigate, signUpMutation, token]
  );

  return {handleSubmit, successRequest};
};
