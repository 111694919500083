import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {DateComponent} from '../DateComponent/DateComponent';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import {Label} from '../Label/Label';
import {Wrapper} from './DateField.styles';

interface Props<T extends FieldValues> {
  maxDate?: Date;
  minDate?: Date;
  startDate?: Date;
  disabled?: boolean;
  name: Path<T>;
  control: Control<T>;
  label?: string | null;
  rules?: UseControllerProps['rules'];
  format?: string;
}

export default function DateField<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const {fieldState} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper data-element="date-field">
      {props.label != null && props.label !== '' && (
        <Label label={props.label} isInvalid={fieldState.invalid} />
      )}
      <DateComponent
        ariaInvalid={Boolean(fieldState.error)}
        maxDate={props.maxDate}
        minDate={props.minDate}
        startDate={props.startDate}
        disabled={props.disabled}
        name={props.name}
        control={props.control}
        rules={props.rules}
        format={props.format}
      />
      {fieldState.error != null && (
        <ErrorMessage message={fieldState.error.message} />
      )}
    </Wrapper>
  );
}
