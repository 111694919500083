import {useQuery} from '@apollo/client';

import {useNavigate, useParams} from 'react-router-dom';
import {TEAM_GROUPS_QUERY} from '../../../../../gql';
import {
  TeamGroups,
  TeamGroupsVariables,
} from '../../../../../gql/queries/team/__generated__/TeamGroups';
import {Id} from '../../../../../types/auxiliary.types';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading} = useQuery<
    TeamGroups,
    TeamGroupsVariables
  >(TEAM_GROUPS_QUERY, {
    variables: {
      id: (params.teamId || '').toString(),
    },
    skip: params.teamId == null || params.teamId === '',
  });

  return {
    list: data?.team.groups?.map((g) => ({
      id: g?.id || '',
      title: g?.full_name || '',
      labels: [
        {
          id: 'userCount',
          name: 'Участники',
          count: g?.userCount || 0,
        },
      ],
    })) || [],
    loading,
  };
}

export function useActions() {
  const navigate = useNavigate();

  function handleCardClick(groupId: Id) {
      navigate(`/groups/${groupId}`);
  }

  return {handleCardClick};
}
