import React, {useState} from 'react';
import {Url} from '../../../../../types/auxiliary.types';
import {VideoCard} from '../../../../cards/Video/component';
import Preloader from '../../../../elements/Preloader/Preloader';
import {AddVideo} from '../../modals/AddVideo/AddVideo';
import {useTeam} from './Videos.hooks';
import {MediaList} from '../MediaList/MediaList';

interface IVideo {
  name: string;
  url: Url;
}

export function Videos() {
  const {loading, list, fetch} = useTeam();

  const [shownModal, setShownModal] = useState(false);

  function renderModal() {
    if (!shownModal) return null;

    return (
      <AddVideo
        onAddSuccess={() => {
          fetch().then(() => setShownModal(false));
        }}
        onModalClose={() => setShownModal(false)}
      />
    );
  }

  function renderCard(card: IVideo, i: number) {
    return (
      <VideoCard
        key={i}
        name={card.name}
        url={card.url}
      />
    );
  }

  if (loading) return <Preloader />;

  return (
    <MediaList
      list={list}
      typeButton="button"
      countInRow={[1, 2]}
      buttonLabel="Добавить видео"
      renderCard={renderCard}
      renderModal={renderModal}
      addItem={() => setShownModal(true)}
    />
  );
}
