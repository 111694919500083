import {useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {TaskList} from '../../../../gql/task/queries/__generated__/TaskList';
import {TASK_LIST_QUERY} from '../../../../gql';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../hooks/useUrl.hooks';
import {createDate_DTO} from '../../../../utils/Date.utils';
import {FormFields} from './components/filter/types';

export function useTaskList() {
  const currSearchParams = useCurrSearch<
    FormFields & {page: string | null}
  >() as FormFields & {page: string | null};

  const {data, loading} = useQuery<TaskList>(TASK_LIST_QUERY, {
    variables: {
      ...(currSearchParams.search != null && {
        text: currSearchParams.search,
      }),
      ...(currSearchParams.isUrgently != null && {
        isUrgent: true,
      }),
      ...(currSearchParams.statusId != null && {
        statusId: Number(currSearchParams.statusId),
      }),
      ...(currSearchParams.regionId != null && {
        regionId: Number(currSearchParams.regionId),
      }),
      ...(currSearchParams.createDate != null && {
        createDate: createDate_DTO(new Date(currSearchParams.createDate)),
      }),
      page: Number(currSearchParams.page),
    },
    fetchPolicy: 'no-cache',
    skip: currSearchParams.page == null,
  });

  return {
    data:
      data?.tasks?.data == null
        ? []
        : data?.tasks?.data.map((task) => ({
            id: task.id || '',
            isUrgent: task.is_urgent || false,
            issue: task.issue?.id,
            call: task.call?.id,
            description: task.description || '',
            region: task.region?.name || '',
            createDate:
              task.created_at == null ? null : new Date(task.created_at),
            avatarResponsible: undefined,
            responsible: task.responsible?.profile.full_name || '',
            status: task.status?.name || '',
            statusColor: task.status?.color || 'transparent',
            result: task.result || undefined,
          })),
    pagination: {
      lasPage: data?.tasks?.paginatorInfo.lastPage || 0,
      total: data?.tasks?.paginatorInfo?.total || 0,
    },
    loading,
  };
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    handleSwitchPage,
    page: Number(searchParams.get('page')),
  };
}

export function useStatistic() {
  return {
    statistic: [],
    loading: false,
  };
}
