import {SvgStyled} from './Icons.styles';

export const SuccessIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8"
        cy="8"
        r="7"
        fill="#79C077"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M5 8.33333L6.76471 10L11 6" stroke="white" strokeWidth="2" />
    </SvgStyled>
  );
};
