import React from 'react';
import {useForm} from 'react-hook-form';
import {Id} from '../../../../../../types/auxiliary.types';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {ApplicantInfo} from '../../../components/ApplicantInfo/ApplicantInfo';
import {useEnterForm} from '../../../form.hooks';
import {useHandler} from './Step2.hooks';

import {ContentWrapper, Title, Fields, Footer, Form} from './Step2.styles';

import {Step2FormFields as FormFields} from '../../../types';
import {Button} from '../../../../../elements/Button/Button';
import {useInit} from '../hooks';

interface Props {
  applicationId: Id;
  defaultValues?: FormFields;
  onSuccess?(applicantId?: Id): void;
}

export function Step2(props: Props) {
  const {loading, data, refetch} = useInit(props.applicationId);

  const {control, register, formState, handleSubmit, setError} =
    useForm<FormFields>({
      defaultValues: props.defaultValues || {
        firstName: '',
        lastName: '',
        middleName: '',
        relationDegree: '',
        phone: '',
        additionalContact: '',
        police: '',
        MES: '',
        is112: false,
      },
      reValidateMode: 'onBlur',
      mode: 'onBlur',
    });

  const {handleSubmit2Step} = useHandler(
    props.applicationId,
    setError,
    (applicantId?: Id) => {
      refetch();
      props.onSuccess?.(applicantId);
    }
  );

  useEnterForm(handleSubmit(handleSubmit2Step));

  if (loading) {
    return <Preloader />;
  }

  if (!loading && data == null) {
    return <p>Ошибка</p>;
  }

  return (
    <>
      <Title>Заявка #{data?.issue.id}</Title>
      <ContentWrapper>
        <Form onSubmit={handleSubmit(handleSubmit2Step)}>
          <Fields>
            <ApplicantInfo register={register} control={control} />
          </Fields>

          <Footer>
            <Button
              title="Далее"
              type="submit"
              disabled={formState.isSubmitting}
            />
          </Footer>
        </Form>
      </ContentWrapper>
    </>
  );
}
