import React, {ReactNode, useState} from 'react';
import {TriangleIcon} from '../../icons/TriangleIcon';
import {useSizes} from './BlockWrapper.hooks';
import {
  Container,
  Content,
  Header,
  Title,
  Wrapper,
} from './BlockWrapper.styles';

interface Props {
  title: string;
  shouldOpen?: boolean;
  children: ReactNode;
  renderHeader?: () => ReactNode;
}

export const BlockWrapper = (props: Props) => {
  const [show, setShow] = useState(props.shouldOpen || false);
  const {ref, height} = useSizes();

  return (
    <Wrapper>
      <Header onClick={() => setShow((p) => !p)}>
        <TriangleIcon />
        <Title>{props.title}</Title>
        {props.renderHeader?.()}
      </Header>
      <Content ref={ref} show={show} height={Math.ceil(height)}>
        <Container>{props.children}</Container>
      </Content>
    </Wrapper>
  );
};
