import React, {useCallback, useMemo, useState} from 'react';
import {Controller, useForm, UseFormSetError} from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {Id} from '../../../../../types/auxiliary.types';
import {BlockWrapper} from '../../../../elements/BlockWrapper/BlockWrapper';
import {Button} from '../../../../elements/Button/Button';
import DateField from '../../../../elements/DateField/DateField';
import FormFooter from '../../../../elements/FormFooter/component';
import {Label} from '../../../../elements/Label/Label';
import SelectField from '../../../../elements/SelectField/SelectField';
import SwitchField from '../../../../elements/SwitchField/SwitchField';
import {TextField} from '../../../../elements/TextField/TextField';
import Checkbox from '../../../../forms/RegistrationFrom/components/Checkbox/Checkbox';
import {
  useCallOptions,
  useIssueOptions,
} from '../../../Application/ApplicationView/modals/AddDouble/hooks';
import {useDict} from './hooks';
import {
  BigGridFields,
  GridFields,
  PrimaryColumn,
  SecondaryColumn,
  Form as FormWrapper,
  Footer,
  Content,
  GeneralInfo,
} from './styles';
import {FormFields} from './types';

interface Props {
  defaultValues?: FormFields;
  fetching?: boolean;
  submitLabel: string;
  onSubmit(d: FormFields, setError: UseFormSetError<FormFields>): void;
  id?: Id;
}

export function Form(props: Props) {
  const params = useParams<{applicationId?: string}>();

  useCallback(() => {
    window.location.reload();
  }, []);

  const {loading: loadingDict, regions, types, sources} = useDict();

  const currentDate = useMemo(() => new Date(), []);

  const navigate = useNavigate();

  const initState = props.defaultValues || {
    region: '',
    additionalRegions: [],
    type: '',
    source: '',
    description: '',
    applicationId: '',
    calls: [],
    dateTime: currentDate,
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    is112: false,
    email: '',

    isEducational: false,
    isUrgently: false,
    isEmailSend: false,
  };

  const {control, register, formState, handleSubmit, setError, watch, reset} =
    useForm<FormFields>({
      defaultValues: initState,
      reValidateMode: 'onBlur',
      mode: 'all',
    });

  const fields = watch();
  const hasErrors = Object.keys(formState.errors).length > 0;

  const [issue, setIssue] = useState<any>(undefined);

  const [call, setCall] = useState<any>(undefined);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const applicationId = searchParams.get('applicationId');
  const callId = searchParams.get('callId');

  return (
    <FormWrapper
      onReset={() => {
        reset(initState, {keepDefaultValues: true});
      }}
      onSubmit={handleSubmit((d) => {
        props.onSubmit(
          {...d, applicationId: issue || '', calls: call || ''},
          setError
        );
      })}>
      <Content>
        <BlockWrapper title="Общая информация" shouldOpen>
          <GeneralInfo>
            <PrimaryColumn>
              <GridFields>
                <Label label={'Выберите регион'}>
                  <Controller
                    name={'region'}
                    control={control}
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          placeholder={'Выберите регион'}
                          noOptionsMessage={() => 'Список пуст!'}
                          loadingMessage={() => 'Идет загрузка...'}
                          options={regions}
                          value={regions.find((c) => c.value === value)}
                          isLoading={
                            formState.isSubmitting ||
                            loadingDict ||
                            props.fetching
                          }
                          isClearable
                          onChange={(option) => onChange(option?.value)}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />
                </Label>

                <Label label={'Дополнительные регионы'}>
                  <Controller
                    name={'additionalRegions'}
                    control={control}
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          placeholder="Выберите регионы"
                          isMulti
                          noOptionsMessage={() => 'Список пуст!'}
                          loadingMessage={() => 'Идет загрузка...'}
                          options={regions}
                          value={regions.find((c) => c.value === value)}
                          isLoading={
                            formState.isSubmitting ||
                            loadingDict ||
                            props.fetching
                          }
                          isClearable
                          onChange={(options) =>
                            onChange(options?.map((option) => option.value))
                          }
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />
                </Label>

                <Label label={'Тип звонка*'}>
                  <Controller
                    name={'type'}
                    control={control}
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Select
                          placeholder="Выберите"
                          options={types}
                          isLoading={
                            formState.isSubmitting ||
                            loadingDict ||
                            props.fetching
                          }
                          onChange={(option) => onChange(option?.value)}
                          onBlur={onBlur}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          noOptionsMessage={() => 'Список пуст!'}
                          loadingMessage={() => 'Идет загрузка...'}
                          value={types.find((c) => c.value === value)}
                        />
                      );
                    }}
                  />
                </Label>

                <Label label={'Источник*'}>
                  <Controller
                    name={'source'}
                    control={control}
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          placeholder="Выберите"
                          isMulti
                          noOptionsMessage={() => 'Список пуст!'}
                          loadingMessage={() => 'Идет загрузка...'}
                          options={sources}
                          isClearable
                          onChange={(options) =>
                            onChange(options?.map((option) => option.value))
                          }
                          onBlur={onBlur}
                          value={sources.find((c) => c.value === value)}
                        />
                      );
                    }}
                  />
                </Label>
              </GridFields>
              <TextField
                register={register('description', {
                  required: 'Поле обязательно для заполнения!',
                })}
                label="Описание звонка*"
                placeholder="Введите"
                isTextarea
                rows={2}
                error={formState.errors.description}
                disabled={formState.isSubmitting || props.fetching}
              />
              <GridFields>
                <Label label={'Сопутствующая заявка'}>
                  <Controller
                    name={'issueId'}
                    control={control}
                    render={({field: {onBlur}}) => {
                      return (
                        <AsyncSelect
                          value={issue}
                          onBlur={onBlur}
                          onChange={(value) => setIssue(value)}
                          placeholder="Выберите"
                          noOptionsMessage={() => 'Введите значение...'}
                          loadingMessage={() => 'Идет загрузка...'}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          defaultValue={
                            applicationId === null
                              ? null
                              : {
                                  id: applicationId,
                                  value: applicationId,
                                  label: `Заявка #${applicationId}`,
                                }
                          }
                          loadOptions={(inputValue, callback) =>
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useIssueOptions(inputValue, callback, applicationId)
                          }
                          cacheOptions
                        />
                      );
                    }}
                  />
                </Label>
                <Label label={'Сопутствующий звонок'}>
                  <AsyncSelect
                    value={call}
                    onChange={(cal) => setCall(cal)}
                    name="calls"
                    placeholder="Выберите"
                    noOptionsMessage={() => 'Введите значение...'}
                    loadingMessage={() => 'Идет загрузка...'}
                    menuPortalTarget={document.body}
                    styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    cacheOptions
                    isDisabled={loadingDict}
                    defaultValue={
                      callId === null
                        ? null
                        : {
                            id: callId,
                            value: callId,
                            label: `Заявка #${callId}`,
                          }
                    }
                    loadOptions={(inputValue, callback) =>
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useCallOptions(inputValue, callback, applicationId)
                    }
                  />
                </Label>
                <DateField
                  label="Время приема звонка"
                  name="dateTime"
                  format="dd.MM.yyyy HH:mm"
                  maxDate={currentDate}
                  control={control}
                  disabled={formState.isSubmitting || props.fetching}
                />
              </GridFields>
            </PrimaryColumn>
            <SecondaryColumn>
              <SwitchField
                name="isEducational"
                control={control}
                label="Учебный"
                disabled={formState.isSubmitting || props.fetching}
              />
              <SwitchField
                name="isUrgently"
                control={control}
                label="Срочный звонок"
                disabled={formState.isSubmitting || props.fetching}
              />
              <SwitchField
                name="isEmailSend"
                control={control}
                label="Почту отправили"
                disabled={formState.isSubmitting || props.fetching}
              />
            </SecondaryColumn>
          </GeneralInfo>
        </BlockWrapper>
        <BlockWrapper title="Звонивший" shouldOpen>
          <BigGridFields>
            <TextField
              register={register('lastName', {
                required: 'Поле обязательно для заполнения.',
              })}
              label="ФИО*"
              placeholder="Введите"
              error={formState.errors.lastName}
              disabled={formState.isSubmitting || props.fetching}
            />

            <TextField
              register={register('phone', {
                required: fields.is112
                  ? ''
                  : 'Поле обязательно для заполнения!',
                pattern: {
                  value: RegExpConstants.phone,
                  message: fields.is112 ? '' : 'Некорректный формат',
                },
              })}
              mask="+7(999) 999 99 99"
              error={formState.errors.phone}
              label="Номер телефона"
              placeholder="Введите"
              disabled={formState.isSubmitting || props.fetching}
            />

            <Checkbox name={'is112'} control={control} label={'112'} />

            <TextField
              register={register('email', {
                pattern: {
                  value: RegExpConstants.email,
                  message: 'Некорректный формат',
                },
              })}
              label="E-mail (если есть)"
              placeholder="Введите"
              error={formState.errors.email}
              disabled={formState.isSubmitting || props.fetching}
            />
          </BigGridFields>
        </BlockWrapper>
      </Content>
      <FormFooter
        hasErrors={
          hasErrors ||
          (formState.submitCount > 0 ? formState.isValid : undefined)
        }>
        <Footer>
          <Button
            onClick={() => navigate(`/calls`)}
            title="Отменить"
            type="reset"
            variant="outlined"
            disabled={formState.isSubmitting || props.fetching}
          />
          <Button
            title={props.submitLabel}
            type="submit"
            disabled={formState.isSubmitting || props.fetching}
          />
        </Footer>
      </FormFooter>
    </FormWrapper>
  );
}
