const RegExpConstants = {
  email:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
  phone: /^\+?[\s()0-9-]{5,}$/,
  integerNumber: /^\d+$/,
  name: /^[а-яА-ЯёË]{2,}$/,
  fio: /^[А-ЯЁа-яё\s-']+$/,
  link: /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/,
};

export default RegExpConstants;
