import React, {useState} from 'react';
import {DateField} from '../../../../../cards/elements/dateField/component';
import {ParticipantList} from '../../../../../elements/ParticipantList/ParticipantList';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {PlusIcon} from '../../../../../icons/PlusIcon';
import {AddSearchMember} from '../../modals/AddSearchMember/AddSearchMember';
import {DeleteModal} from '../../modals/Delete/component';
import {useDeleteSearchMember, useSearchMembers} from './SearchMembers.hooks';
import {
  Wrapper,
  Button,
  PlusIconWrapper,
  TimeWrapper,
  Group,
  GroupName,
} from './SearchMembers.styles';

export function SearchMembers() {
  const [shownModal, setShownModal] = useState(false);

  const [show, setShow] = useState(null);

  const {loading, data, fetch} = useSearchMembers();

  const {handleDeleteMember} = useDeleteSearchMember(fetch);

  const deleteMember = () => {
    handleDeleteMember(Number(show));
    setShow(null);
  };

  if (loading) return <Preloader />;

  function renderDate(startDate: Date | null) {
    return (
      <TimeWrapper>
        <DateField date={startDate} label="Начало смены" />
      </TimeWrapper>
    );
  }

  return (
    <Wrapper>
      {shownModal && (
        <AddSearchMember
          fetchSearchMember={() => {
            fetch().then(() => setShownModal(false));
          }}
          onModalClose={() => setShownModal(false)}
        />
      )}
      {show != null &&
        (show ? (
          <DeleteModal confirm={deleteMember} close={() => setShow(null)} />
        ) : null)}
      <Button type="button" onClick={() => setShownModal(true)}>
        <PlusIconWrapper>
          <PlusIcon />
        </PlusIconWrapper>
        Добавить участника
      </Button>
      {Object.entries(data).map(([id, group]) => (
        <Group key={id}>
          <GroupName>{group.name}</GroupName>
          <ParticipantList
            users={group.list.map(({startDate, ...l}) => ({
              ...l,
              renderAdditionalFirstColumn: () => renderDate(startDate),
            }))}
            onDelete={setShow}
          />
        </Group>
      ))}
    </Wrapper>
  );
}
