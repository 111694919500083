import React, {useState} from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {ChangeButtons} from './modal/changeButtons';
import {DateField} from '../../../../Application/ApplicationView/shared/date/component';
import {HeadingInfo} from '../../../../Application/ApplicationView/shared/headingInfo/component';
import {useActions, useGetCallings} from '../../ringing.hook';
import {useCall} from './hooks';
import AddRinging from './modal/AddRinging';
import {DescriptionWrapper, Title, Wrapper, DescriptionText} from './styles';
import * as S from '../../../../Application/ApplicationView/components/Reports/Reports.styles';
import {useParams} from 'react-router-dom';
import {EditCalling} from './EditCalling';
import {DeleteModal} from '../../../../Application/ApplicationView/modals/Delete/component';

export function Description() {
  const [modal, setModal] = useState<
    | {
        callingId: Id;
        content: string;
      }
    | {}
    | null
  >(null);

  const {loading, data} = useCall();

  const {callId} = useParams();

  const {loading: callingLoading, callings, refetch, fetch} = useGetCallings();

  const {loading: deleteCalling, handleDeleteCalling} = useActions(refetch);

  const [show, setShow] = useState(false);

  const filteredCallings = callId
    ? callings.filter((item) => item.call === Number(callId))
    : callings;

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      {modal != null &&
        ('callingId' in modal ? (
          <EditCalling
            content={'content' in modal ? modal.content : ''}
            callingId={modal.callingId}
            onEditSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ) : (
          <AddRinging
            onAddSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ))}
      <DescriptionWrapper>
        <Title>Описание</Title>
        <DescriptionText>
          {data?.description.split('\n').map((item, key) => {
            return (
              <DescriptionText key={key}>
                {item} <br />
              </DescriptionText>
            );
          })}
        </DescriptionText>
        <AddButton
          buttonLabel="Добавить прозвон"
          typeButton="button"
          addItem={() => setModal({})}
        />
        <S.List>
          {filteredCallings.map((item) => (
            <S.Card key={item.id}>
              <S.Header>
                <S.Number>#{item.number}</S.Number>
                <ChangeButtons
                  edit={{
                    disabled: callingLoading,
                    onClick: () =>
                      setModal({
                        callingId: item.id,
                        content: item.description,
                      }),
                  }}
                  delete={{
                    disabled: deleteCalling,
                    onClick: () => setShow(!show),
                  }}
                />
                {show && (
                  <DeleteModal
                    confirm={() => handleDeleteCalling(item.id)}
                    close={() => setShow(false)}
                  />
                )}
                <DateField
                  displayShown="small"
                  label="Добавлено"
                  date={item.createdAt}
                />
              </S.Header>
              <HeadingInfo
                dates={[
                  {
                    label: 'Добавлено',
                    date: item.createdAt,
                  },
                ]}
              />
              <S.Description>
                {item.description.split('\n').map((item, key) => {
                  return (
                    <S.Description key={key}>
                      {' '}
                      {item} <br />{' '}
                    </S.Description>
                  );
                })}
              </S.Description>
            </S.Card>
          ))}
        </S.List>
      </DescriptionWrapper>
    </Wrapper>
  );
}
