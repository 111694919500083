import React from 'react';
import {useForm} from 'react-hook-form';
import {TextField} from '../../../../elements/TextField/TextField';
import {useFeedback} from './hooks';
import {FormFields} from './types';
import {Wrapper, Tooltip, Buttons} from './styles';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import {Button} from '../../../../elements/Button/Button';

interface Props {
  onClose: () => void;
}

export function FeedbackModal(props: Props) {
  const {register, formState, handleSubmit, setError} = useForm<FormFields>({
    defaultValues: {
      message: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const {handleSend, loading} = useFeedback(props.onClose, setError);

  return (
    <ModalWrapper title="Обратная связь" onModalClose={props.onClose}>
      <Wrapper onSubmit={handleSubmit(handleSend)}>
        <Tooltip>
          Оставьте ваш отзыв, пожелания или общие впечатления об ипользовании
          нашей системы. Это поможет нам развивать и дополнять продукт
        </Tooltip>
        <TextField
          register={register('message', {
            required: 'Поле обязательно для заполнения.',
          })}
          error={formState.errors.message}
          isTextarea
          rows={2}
          label="Заполните поле"
          placeholder="Введите ваше сообщение"
        />
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={loading}
          />
          <Button
            title="Отправить"
            type="submit"
            fullWidth
            disabled={loading}
          />
        </Buttons>
      </Wrapper>
    </ModalWrapper>
  );
}
