import styled from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`

  > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Card = styled.article`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  padding: 16px 26px 16px 16px;
  box-sizing: border-box;
`;

export const Info = styled.div``;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #000;
`;

export const Url = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const DeleteButton = styled.button`
  margin-left: 10px;
  width: 16px;
  height: 16px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--warning-color);
  border-radius: 4px;
  background-color: var(--warning-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    background-color: #fff;

    svg path {
      fill: var(--warning-color);
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;
