import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Id} from '../../../types/auxiliary.types';
import {MessageIcon} from '../../icons/MessageIcon';
import {SMSIcon} from '../../icons/SMSIcon';
import {
  useCreateLetter,
  useLetterTemplates,
} from '../../screens/Application/ApplicationView/components/ActionBlock/letter.hook';
import {Button} from '../Button/Button';
import {Option} from '../Select/Select.types';
import SelectField from '../SelectField/SelectField';
import {Text} from '../Text/Text';
import * as S from './SendLetter.styles';

interface FormFields {
  subject: string;
  text: string;
  recipients: string;
  type: string;
  select: Option;
}

interface Props {
  title: string;
  loading?: boolean;
  labelSubmit: string;
  defaultValues?: FormFields;
}

export default function LetterModal(props: Props) {
  const [open, setOpen] = useState(false);

  const {templates, loading, fields} = useLetterTemplates();

  const [chooseField, setChooseField] = useState<Id | Id[] | null>(0);

  const currentTheme = useMemo(
    () => fields.find((item) => item.id === chooseField),
    [chooseField]
  );

  const {control} = useForm({
    defaultValues: props.defaultValues || {
      subject: currentTheme && currentTheme.subject,
      text: currentTheme && currentTheme.text,
      recipients: currentTheme && currentTheme.recipients,
      type: '',
    },
    mode: 'all',
  });

  const styles = {
    border: '1px solid var(--primary-border-color)',
    color: 'var(--primary-text-color)',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '14px 16px',
    lineHeight: '1.3571428571',
    outline: 'none',
    marginTop: '1rem',
    width: '400px',
  };

  const [data, setData] = useState<any>({
    subject: '',
    text: '',
    recipients: '',
  });

  useEffect(() => {
    if (currentTheme) {
      const {subject, text, recipients} = currentTheme;
      setData({
        subject,
        text,
        recipients,
      });
    }
  }, [currentTheme]);

  const handleChange = (event: any) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  const {submit} = useCreateLetter(() => setOpen(false));

  return (
    <S.Wrapper>
      <S.Action type="button" color="blue" onClick={() => setOpen(true)}>
        <S.IconWrapper data-icon>
          <MessageIcon />
        </S.IconWrapper>
        <S.Title data-name>Отправить письмо</S.Title>
      </S.Action>
      {open && (
        <S.ModalWraper
          title={'Отправка письма'}
          onModalClose={() => setOpen(false)}>
          <Fragment>
            <S.SampleBlock>
              <Text variant={'p1'}>
                Выберите шаблон письма и отредактируйте его при необходимости
              </Text>
              <SelectField
                name="select"
                options={loading ? [] : templates}
                placeholder="Выберите"
                label="Название шаблона"
                control={control}
                onCallbackChange={(value) => {
                  setChooseField(value);
                }}
                rules={{
                  required: 'Поле обязательно для заполнения',
                }}
              />
            </S.SampleBlock>
            <S.ThemeBlock>
              <input
                placeholder={'Введите тему письма'}
                style={styles}
                maxLength={1000}
                defaultValue={currentTheme && currentTheme.subject}
                onChange={handleChange}
                name={'subject'}
              />
            </S.ThemeBlock>
            <S.ContentBlock>
              <textarea
                placeholder={'Введите содержимое письма'}
                autoFocus={false}
                style={{...styles, resize: 'vertical'}}
                defaultValue={currentTheme && currentTheme.text}
                onChange={handleChange}
                maxLength={10000}
                name={'text'}
              />
            </S.ContentBlock>
            <S.AdresseeBlock>
              <input
                placeholder={'Укажите почту получателя'}
                style={styles}
                defaultValue={currentTheme && currentTheme.recipients}
                onChange={handleChange}
                maxLength={1000}
                name={'recipients'}
              />
            </S.AdresseeBlock>
            <S.ButtonsWrapper>
              <Button
                title="Отмена"
                variant="outlined"
                type="reset"
                onClick={() => setOpen(false)}
              />
              <Button onClick={() => submit(data)} title="Отправить" />
            </S.ButtonsWrapper>
          </Fragment>
        </S.ModalWraper>
      )}
    </S.Wrapper>
  );
}
