import {useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import {useCallback} from 'react';
import {EDIT_DUTY_MUTATION} from '../../../../../../../gql';
import {
  EditDuty,
  EditDutyVariables,
} from '../../../../../../../gql/profile/mutations/__generated__/EditDuty';
import {Id} from '../../../../../../../types/auxiliary.types';
import {createDateTime_DTO} from '../../../../../../../utils/Date.utils';
import {FormFields} from '../form/types';

export function useEditDuty(id: Id, onEditSuccess: () => void) {
  const [editDuty, {loading}] = useMutation<EditDuty, EditDutyVariables>(
    EDIT_DUTY_MUTATION
  );

  const handleSubmit = useCallback(
    (data: FormFields, setError?: (error: string) => void) => {
      if (data.startAt == null || data.endAt == null) return;

      editDuty({
        variables: {
          input: {
            shiftId: Number(id),
            dateStart: createDateTime_DTO(data.startAt),
            dateEnd: createDateTime_DTO(data.endAt),
          },
        },
      })
        .then(() => {
          onEditSuccess();
          toast('Дежурство успешно изменено!');
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
        });
    },
    [editDuty, id, onEditSuccess]
  );

  return {handleSubmit, loading};
}
