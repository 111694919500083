import {useForm, useWatch} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import { ListFilter } from '../../../../../elements/ListFilter/ListFilter';
import {useAction} from './hooks';
import {FormFields} from './types';
import {Form} from './styles';


const InitState = {
  search: "",
}

interface Props {
  callRequestsCount: number;
}

export function Filter(props: Props) {
  const [search] = useSearchParams();

  const {submit, handleReset} = useAction();

  const {control, register, reset, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      search: search.get("search") || InitState.search,
    },
    reValidateMode: "onSubmit",
    mode: 'onSubmit'
  });

  const fields = useWatch<FormFields>({
    control: control
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset()
      }}
    >
      <ListFilter
        register={register("search")}
        showReset={Object.values(fields).some(f => f !== '' && f != null)}
        submitLabelButton="Показать запросы"
        count={{
          amount: props.callRequestsCount,
          words: ['запроса', 'запроса', 'запросов']
        }}
      >
      </ListFilter>
    </Form>
  );
}
