import React from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../elements/Preloader/Preloader';
import {TextField} from '../../../../elements/TextField/TextField';
import {Buttons, Form} from './Report.style';
import {FormFields} from './Report.types';

interface Props {
  title: string;
  defaultValues?: FormFields;
  loading?: boolean;
  labelSubmitButton: string;
  submit(data: FormFields): void;
  onModalClose(): void;
}

export function Report(props: Props) {
  const {handleSubmit, register, formState} = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      description: '',
    },
    mode: 'onSubmit',
  });

  return (
    <ModalWrapper title={props.title} onModalClose={props.onModalClose}>
      {props.loading ? (
        <Preloader />
      ) : (
        <Form
          onSubmit={handleSubmit(props.submit)}
          onReset={props.onModalClose}>
          <TextField
            register={register('description', {
              maxLength: {
                value: 10000,
                message: 'Превышено допустимое количество символов',
              },
            })}
            error={formState.errors.description}
            label="Содержание"
            placeholder="Введите"
            isTextarea
            rows={2}
          />
          <Buttons>
            <Button
              title="Отмена"
              type="reset"
              variant={'outlined'}
              color={'primary'}
              fullWidth
              disabled={formState.isSubmitting}
            />
            <Button
              title={props.labelSubmitButton}
              type="submit"
              fullWidth
              disabled={formState.isSubmitting}
            />
          </Buttons>
        </Form>
      )}
    </ModalWrapper>
  );
}
