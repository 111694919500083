import React from 'react';
import pagePreloaderGif from './Preloader.gif';
import {Container, Title, Image, Subtitle} from './Preloader.styles';

export const Preloader = () => {
  return (
    <Container>
      <Image alt="" src={pagePreloaderGif} />
      <Title>Идет загрузка...</Title>
      <Subtitle>Пожалуйста подождите</Subtitle>
    </Container>
  );
};

export default Preloader;
