import {createDate_DTO} from './Date.utils';

export function createUrlSearch(data: any) {
  return Object.entries(data).reduce((acc, [key, curr]) => {
    if (Array.isArray(curr)) {
      if (curr.length === 0) {
        return acc;
      }

      return {
        ...acc,
        [key]: curr,
      };
    }

    if (typeof curr === 'boolean') {
      if (curr)
        return {
          ...acc,
          [key]: 1,
        };

      return acc;
    }

    if (curr instanceof Date) {
      return {
        ...acc,
        [key]: createDate_DTO(curr),
      };
    }

    if (curr == null || curr === '') {
      return acc;
    }

    return {
      ...acc,
      [key]: curr,
    };
  }, {});
}
