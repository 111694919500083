import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 19.75rem;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.875rem;
  pointer-events: none;
  box-sizing: border-box;
`;

export const Content = styled.a`
  color: #376092;
  word-break: break-word;
  margin-bottom: 8px;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  &&:hover {
    color: #2c4d75;
    transition: 0.36s;
  }
`;
