import React, {useState} from 'react';
import Select from 'react-select';
import RegExpConstants from '../../../../../constants/regExp.constants';
import {Label} from '../../../../elements/Label/Label';
import {TextArrayFields} from '../../../../elements/TextArrayFields/TextArrayFields';
import {TextField} from '../../../../elements/TextField/TextField';
import {BlockWrapper} from '../../../../elements/BlockWrapper/BlockWrapper';
import {
  Control,
  Controller,
  UseFormRegister,
  useFormState,
} from 'react-hook-form';
import {Step3FormFields as FormFields} from '../../types';
import {useDict} from './MissingInfo.hooks';
import {
  BigGridFields,
  GridFields,
  Wrapper,
  DatePickerWrapper,
} from './MissingInfo.styles';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
}

export const MissingInfo = (props: Props) => {
  const gender = [
    {
      label: 'Мужской',
      value: 'm',
    },
    {
      label: 'Женский',
      value: 'w',
    },
  ];
  const insert = (source: string, substring: string, index: number) =>
    source.slice(0, index) + substring + source.slice(index);

  const [accessCode, setAccessCode] = useState('');

  const handleAccessCodeChange = (newAccessCode: string) => {
    const dividerIndexes = [2, 5];

    const dividerChar = '.';

    let formattedAccessCode = newAccessCode;

    for (const dividerIndex of dividerIndexes) {
      const hasDividerChar = newAccessCode[dividerIndex] === dividerChar;
      const isRemoving = newAccessCode.length < accessCode.length;
      if (
        newAccessCode.length >= dividerIndex &&
        !hasDividerChar &&
        !isRemoving
      )
        formattedAccessCode = insert(
          formattedAccessCode,
          dividerChar,
          dividerIndex
        );
    }

    setAccessCode(formattedAccessCode);
  };

  const {phoneStatuses, hasPhone, diseases} = useDict();

  const {errors} = useFormState({
    control: props.control,
  });

  return (
    <BlockWrapper title="Пропавший" shouldOpen>
      <Wrapper>
        <BigGridFields>
          <TextField
            register={props.register('lastName', {
              required: 'Поле обязательно для заполнения.',
            })}
            error={errors.lastName}
            label="Фамилия*"
            placeholder="Введите"
          />
          <TextField
            register={props.register('firstName', {
              required: 'Поле обязательно для заполнения.',
            })}
            error={errors.firstName}
            label="Имя*"
            placeholder="Введите"
          />
          <TextField
            register={props.register('middleName')}
            error={errors.middleName}
            label="Отчество"
            placeholder="Введите"
          />
        </BigGridFields>
        <GridFields>
          <Label label={'Пол*'}>
            <Controller
              name={'gender'}
              rules={{required: true}}
              control={props.control}
              render={({field: {onChange, value, onBlur}}) => {
                return (
                  <Select
                    options={gender}
                    onChange={(option) => onChange(option?.value)}
                    onBlur={onBlur}
                    isClearable
                    value={gender.find((c) => c.value === value)}
                    placeholder={'Выберите'}
                  />
                );
              }}
            />
          </Label>

          <DatePickerWrapper>
            <TextField
              register={props.register('birthdayString', {
                required: 'Поле обязательно для заполнения.',
                onChange: (event) => handleAccessCodeChange(event.target.value),
                onBlur: (event) => handleAccessCodeChange(event.target.value),
              })}
              label={'Введите дату рождения'}
              placeholder={'Введите'}
            />
          </DatePickerWrapper>

          <Label label={'Наличие телефона'}>
            <Controller
              name={'hasPhone'}
              control={props.control}
              render={({field: {onChange, value, onBlur}}) => {
                return (
                  <Select
                    isClearable
                    placeholder={'Выберите'}
                    onChange={(option) => onChange(option?.value)}
                    onBlur={onBlur}
                    options={hasPhone}
                    value={hasPhone.find((c) => c.value === value)}
                  />
                );
              }}
            />
          </Label>

          <Label label={'Доступность телефона'}>
            <Controller
              name={'availablePhone'}
              control={props.control}
              render={({field: {onChange, value, onBlur}}) => {
                return (
                  <Select
                    isClearable
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        width: '300px',
                      }),
                      option: (baseStyles) => ({
                        ...baseStyles,
                        width: '300px',
                      }),
                    }}
                    placeholder={'Выберите'}
                    options={phoneStatuses}
                    value={phoneStatuses?.find((c) => c.value === value)}
                    onChange={(option) => onChange(option?.value)}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </Label>

          <TextField
            register={props.register('phone', {
              pattern: {
                value: RegExpConstants.phone,
                message: 'Некорректный формат',
              },
            })}
            mask="+7(999) 999 99 99"
            error={errors.phone}
            label="Номер телефона (основной)"
            placeholder="+ _ ___ ___ __ __"
          />
          <TextArrayFields
            label="Дополнительные номера телефонов"
            name="additionalPhones"
            mask="+7(999) 999 99 99"
            placeholder="+ _ ___ ___ __ __"
            control={props.control}
          />
        </GridFields>
        <TextField
          register={props.register('health')}
          label="Здоровье"
          placeholder="Введите"
          error={errors.health}
        />
        <Label label={'Заболевания'}>
          <Controller
            name={'diseases'}
            control={props.control}
            render={({field: {onChange, value, onBlur}}) => {
              return (
                <Select
                  placeholder={'Выберите'}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: '300px',
                    }),
                  }}
                  options={diseases}
                  isMulti
                  noOptionsMessage={() => 'Список пуст'}
                  loadingMessage={() => 'Идет загрузка...'}
                  onChange={(options) =>
                    onChange(options?.map((option) => option.value))
                  }
                  onBlur={onBlur}
                  value={diseases?.filter((c) => value.includes(c.value))}
                  isClearable
                />
              );
            }}
          />
        </Label>

        <TextField
          register={props.register('appearance')}
          label="Внешность, особые приметы"
          placeholder="Введите"
          error={errors.appearance}
        />
        <TextField
          register={props.register('cloth')}
          label="Одежда"
          placeholder="Введите"
          error={errors.cloth}
        />
        <TextField
          register={props.register('withSelf')}
          label="С собой"
          placeholder="Введите"
          error={errors.withSelf}
        />
        <TextField
          register={props.register('additionalInformation')}
          label="Дополнительная информация"
          placeholder="Введите"
          error={errors.additionalInformation}
          isTextarea
          rows={2}
        />
      </Wrapper>
    </BlockWrapper>
  );
};
