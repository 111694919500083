import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {TEAM_EVENTS_QUERY} from '../../../../../gql';
import {
  TeamEvents,
  TeamEventsVariables,
} from '../../../../../gql/queries/team/__generated__/TeamEvents';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading} = useQuery<
    TeamEvents,
    TeamEventsVariables
  >(TEAM_EVENTS_QUERY, {
    variables: {
      id: (params.teamId || '').toString(),
    },
    skip: params.teamId == null || params.teamId === '',
  });

  return {
    list: data?.team.events == null ? [] : data?.team.events.map((e) => ({
      id: e?.id || '',
      name: e?.name || '',
      type: e?.type?.name,
      colorType: e?.type?.color,
      contactPerson: e?.contact_person || '',
      contactPhone: e?.contact_phone || '',
      link: e?.link || undefined,
      dateFrom: e?.date_from || '',
      dateTo: e?.date_to || undefined,
      comment: e?.comment || undefined,
      region: e?.region?.name || '',
    })),
    loading,
  };
}
