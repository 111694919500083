import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Select = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  padding: 14px 20px 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;

  svg {
    flex-shrink: 0;
  }

  svg path {
    fill: var(--primary-color);
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

interface ISelectLabel {
  empty: boolean;
}

export const SelectLabel = styled.span<ISelectLabel>`
  flex-grow: 1;
  font-size: 14px;
  line-height: 19px;
  color: var(${(p) => (p.empty ? '--secondary-text-color' : '--text-color')});

  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IOptionContainer {
  open: boolean;
}

export const OptionsContainer = styled.div<IOptionContainer>`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  padding: 0;
  width: 100%;
  display: ${(p) => (p.open ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  z-index: 3;

  filter: ${(p) =>
    p.open ? 'drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.06))' : 'none'};
  border-radius: 8px;
`;

export const Options = styled.ul`
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  list-style: none;
  max-height: 334px;

  &::-webkit-scrollbar {
    width: 0;
  }

  > *:not(:last-child) {
    border-bottom: 1px solid var(--primary-border-color);
  }
`;

interface IOption {
  selected?: boolean;
  isEmpty?: boolean;
}

export const Option = styled.li<IOption>`
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.isEmpty ? 'default' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(p) => (p.selected ? 'var(--primary-color)' : '#000')};

  &:not([aria-disabled='true']):hover {
    //not found in design
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

export const FakeCheck = styled.p`
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary-color);
  border-radius: 4px;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const UnCheck = styled.p`
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;
  box-sizing: border-box;

  border: 1px solid var(--additional-color);
  border-radius: 4px;
`;

export const GroupWrapper = styled.div``;

export const OptionsDiv = styled.div`
  overflow-y: scroll;
  max-height: 334px;

  &::-webkit-scrollbar {
    width: 0;
  }

  > *:not(:last-child) {
    border-bottom: 1px solid var(--primary-border-color);
  }
`;

export const GroupTitle = styled.p`
  margin: 0;
  padding: 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #000000;
`;

export const InputName = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  margin-left: 8px;
`;

export const FilterBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 14px 8px 15px 16px;
  border: 1px solid #e7e7e7;
  border-radius: 8px;

  svg path {
    width: 16px;
    fill: #376092;
  }
`;
