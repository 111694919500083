import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {ActionBlock} from '../../../elements/ActionBlock/ActionBlock';
import {Button} from '../../../elements/Button/Button';
import {Chat} from '../../../elements/chat/component';
import {ModalWrapper} from '../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../elements/Preloader/Preloader';
import {
  ButtonWrapper,
  Wrapper as ButtonsWrapper,
} from '../../../layouts/Header/components/User/User.styles';
import {Description} from './components/description/component';
import GeneralInfo from './components/generalInfo/component';
import {useActions, useChat, useTask} from './hooks';

import {Wrapper, MainColumn, SecondaryColumn} from './styles';

export default function ViewTaskScreen() {
  useDocumentTitle('Просмотреть задачу');

  const [modalName, setModalName] = useState<'deleteTask' | null>(null);

  const params = useParams<{taskId?: string}>();

  const navigate = useNavigate();

  const {loading, data, refetch} = useTask();

  const {handleDeleteTask, handleChangeStatus} = useActions(refetch);

  const {
    comments,
    loading: CommentLoading,
    handleAddMessage,
    sendMessage,
  } = useChat();

  function renderModal() {
    switch (modalName) {
      case 'deleteTask': {
        return (
          <ModalWrapper
            title={'Подтвердите удаление'}
            onModalClose={() => setModalName(null)}>
            Вы уверены что хотите удалить задачу?
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  title="Да"
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => handleDeleteTask(Number(params.taskId))}
                />
              </ButtonWrapper>
              <Button
                title="Отмена"
                variant={'outlined'}
                color={'primary'}
                onClick={() => setModalName(null)}
              />
            </ButtonsWrapper>
          </ModalWrapper>
        );
      }
    }
  }

  return (
    <Wrapper>
      <MainColumn>
        {loading || data == null ? (
          <Preloader />
        ) : (
          <Description description={data.description} result={data.result} />
        )}
      </MainColumn>
      <SecondaryColumn>
        <ActionBlock
          title="Действие с задачей"
          // sendData={{
          //   label: "Переоткрыть задачу",
          // }}
          onEdit={() => navigate(`/tasks/edit/${params.taskId}`)}
          onDelete={{
            label: 'Удалить задачу',
            onClick: () => setModalName('deleteTask'),
          }}
        />
        {renderModal()}
        {data != null && (
          <GeneralInfo
            id={data.id}
            isUrgent={data.isUrgent}
            statusId={data.statusId}
            createDate={data.createDate}
            applications={data.applications}
            calls={data.calls}
            region={data.region}
            author={data.author}
            responsible={data.responsible}
            controlGroup={data.controlGroup}
            onChangeStatus={handleChangeStatus}
            onSuccess={refetch}
          />
        )}
      </SecondaryColumn>
      <Chat
        comments={comments}
        sendingMessage={sendMessage}
        onMessageAdd={handleAddMessage}
        loadingComments={CommentLoading}
      />
    </Wrapper>
  );
}
