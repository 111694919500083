import {useQuery} from '@apollo/client';
import {
  usefulResource_links_data,
  usefulResource,
} from '../../../../../gql/queries/__generated__/usefulResource';
import {USEFUL_RESOURCES_QUERY} from '../../../../../gql';

export const useUsefulResources = () => {
  const {data, loading} = useQuery<usefulResource, usefulResource_links_data>(
    USEFUL_RESOURCES_QUERY
  );

  return {
    loading,
    resources:
      data?.links == null
        ? []
        : data.links.data.map((i) => ({
            name: i.name || '',
            id: i.id || '',
            link: i.link || '',
            comment: i.comment || '',
          })),
  };
};
