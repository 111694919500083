import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {InputStyled} from './Input.styles';
import InputMask from 'react-input-mask';

type InputProps = {
  name?: string;
  register?: UseFormRegisterReturn;
  placeholder?: string;
  type?: 'password' | 'text';
  isInvalid?: boolean;
  disabled?: boolean;
  mask?: string | Array<string | RegExp>;
  defaultValue?: string;
};

export function Input({defaultValue, register, mask, ...rest}: InputProps) {
  if (mask != null) {
    return <InputStyled as={InputMask} mask={mask} {...rest} {...register} />;
  }

  return <InputStyled defaultValue={defaultValue} {...register} {...rest} />;
}
