import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {Tab, Wrapper} from './Tabs.styles';

interface Props {
  activeTab: Id;
  items: {
    id: Id;
    label: string | number;
  }[];
  onSwitchTab(newTab: Id): void;
}

export function Tabs(props: Props) {
  return (
    <Wrapper>
      {props.items.map((tab) => (
        <Tab
          key={tab.id}
          type="button"
          active={tab.id === props.activeTab}
          onClick={() => props.onSwitchTab(tab.id)}>
          {tab.label}
        </Tab>
      ))}
    </Wrapper>
  );
}
