import styled from 'styled-components';

export const Wrapper = styled.article`
  background: #fff;
  border-radius: 8px;

  width: 100%;
  box-sizing: border-box;

  cursor: pointer;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 48px 16px 18px;
`;

export const ShortInfo = styled.div`
  width: 77px;
  margin-right: 20px;

  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

export const Number = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333;

  margin: 0 0 6px;

  &::before {
    content: '#';
  }

  @media screen and (max-width: 900px) {
    display: flex;
  }
`;

export const IconsWrapper = styled.div`
  > *:not(:last-child) {
    margin-right: 6px;
  }

  @media screen and (min-width: 900px) {
    & > div {
      display: flex;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 900px) {
    display: block;
  }

  @media screen and (max-width: 600px) {
    margin-right: 1rem;
  }
`;

export const IconWrapper = styled.div`
  display: flex;

  svg path {
    fill: var(--warning-color);
  }
`;

export const ForestInTouchIconWrapper = styled.div`
  margin-top: -3px;
  width: 20px;
  height: 19px;

  svg path {
    fill: var(--secondary-color);
  }

  svg path[data-at='phone'] {
    fill: var(--additional-color);
  }
`;

export const DateBlocks = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 40px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px 0;
  box-sizing: border-box;

  background: var(--additional-color);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 8px 8px;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 8px;
  }
`;

export const Comment = styled.p`
  margin: 0 0 8px 8px;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;
