import React from 'react';
import MIMEConstants from '../../../constants/mime.constants';

import {Url} from '../../../types/auxiliary.types';
import { ExelIcon } from '../../icons/ExelIcon';
import { PDFIcon } from '../../icons/PDFIcon';
import { WordIcon } from '../../icons/WordIcon';
import {
  Card, ExelWrapper,
  Info,
  Link, PDFWrapper,
  Size,
  WordWrapper,
  WrapperInfo,
} from './FileCard.styles';

interface Props {
  name: string,
  url: Url,
  mime: string,
  size: number
}

export function FileCard(props: Props) {

  function renderMimeIcon(mime: string) {
    switch (MIMEConstants[mime]) {
      case 'word':
        return (
          <WordWrapper>
            <WordIcon />
          </WordWrapper>
        );
      case 'exel':
        return (
          <ExelWrapper>
            <ExelIcon />
          </ExelWrapper>
        );
      case 'pdf':
        return (
          <PDFWrapper>
            <PDFIcon />
          </PDFWrapper>
        );
      default:
        return null;
    }
  }

  return (
    <Card key={props.url}>
      <WrapperInfo>
        {renderMimeIcon(props.mime)}
        <Info>
          <Link href={props.url} download>
            {props.name}
          </Link>
          <Size>{(props.size / 1024).toFixed(2)} KB</Size>
        </Info>
      </WrapperInfo>
      {/*<DeleteButton*/}
      {/*  type="button"*/}
      {/*  onClick={() => null}>*/}
      {/*  <TrashIcon />*/}
      {/*</DeleteButton>*/}
    </Card>
  );
}
