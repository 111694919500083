import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {GROUP_VIDEO_QUERY} from '../../../../../gql';
import {
  Video,
  VideoVariables,
} from '../../../../../gql/queries/group/__generated__/Video';
import {VideoTypes} from './types';

export function useVideos() {
  const [searchParams] = useSearchParams();
  const params = useParams<{groupId?: string}>();
  const {data, loading, fetchMore} = useQuery<Video, VideoVariables>(
    GROUP_VIDEO_QUERY,
    {
      variables: {
        id: (params.groupId || '').toString(),
      },
      skip: params.groupId == null || params.groupId === '',
    }
  );

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.groupId || '').toString(),
      },
    });
  }, [fetchMore, params.groupId]);

  return {
    groupId: params.groupId,
    groupName: data?.group.full_name,
    list:
      data?.group.videos?.map((v) => ({
        name: v?.name || '',
        url: v?.url || '',
      })) ?? ([] as VideoTypes[]),
    loading,
    fetch,
  };
}
