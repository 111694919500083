import React, {CSSProperties} from 'react';
import {NavLink} from 'react-router-dom';
import {LocationType} from '../../../../constants/location.constants';
import {Badge} from '../../../elements/Badge/Badge';
import {LocationIcon} from '../../../elements/LocationIcon/LocationIcon';
import {DoubleUrgentlyIcon} from '../../../icons/DoubleUrgentlyIcon';
import {ForestInTouchIcon} from '../../../icons/ForestInTouchIcon';
import {UrgentlyIcon} from '../../../icons/UrgentlyIcon';
import {StyledNavLink} from '../../../layouts/Header/components/User/User.styles';
import Status from '../status/component';
import {
  Wrapper,
  NumberField,
  UrgentlyIconWrapper,
  Column,
  ForestInTouchIconWrapper,
  Labels,
  Link,
} from './styles';

interface Props {
  number: number | string;
  isUrgent?: boolean;
  isTraining?: boolean;
  urgent?: 1 | 2 | 3 | 4;
  status?: string;
  location?: LocationType;
  forestInTouch?: boolean;
  statusColor?: CSSProperties['backgroundColor'];
  issue?: number;
  call?: number;
}

export default function MainInfo(props: Props) {
  return (
    <Wrapper>
      <Column>
        <NumberField>{props.number}</NumberField>
        {props.isUrgent && (
          <UrgentlyIconWrapper title="Срочно">
            <UrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        <div style={{marginTop: '1rem'}}>
          {props.isTraining && <Badge title="Учебная" color="#DB00FF" />}
        </div>
        {props.urgent != null && [2, 3].includes(props.urgent) && (
          <UrgentlyIconWrapper
            important={props.urgent === 2}
            title={props.urgent === 2 ? 'Важно' : 'Срочно'}>
            <UrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        {props.urgent === 4 && (
          <UrgentlyIconWrapper title="Очень срочно">
            <DoubleUrgentlyIcon />
          </UrgentlyIconWrapper>
        )}
        <LocationIcon location={props.location} />
        {props.forestInTouch && (
          <ForestInTouchIconWrapper title="Лес на связи">
            <ForestInTouchIcon />
          </ForestInTouchIconWrapper>
        )}
        {props.issue && (
          <Labels onClick={(e) => e.preventDefault()}>
            Заявка:
            <Link to={`/applications/${props.issue}`}>{props.issue}</Link>
          </Labels>
        )}
        {props.call && (
          <Labels onClick={(e) => e.preventDefault()}>
            Звонок: <Link to={`/calls/${props.call}`}>{props.call}</Link>
          </Labels>
        )}
      </Column>

      {props.status != null && (
        <Status color={props.statusColor} value={props.status} hideFullSize />
      )}
    </Wrapper>
  );
}
