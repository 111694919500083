import React from 'react';
import {SvgStyled} from './Icons.styles';

export const SMSIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4 0H1.60001C0.715987 0 0.00798749 0.715987 0.00798749 1.60001L0 16L3.19999 12.8H14.4C15.284 12.8 16 12.084 16 11.2V1.60001C16 0.715987 15.284 0 14.4 0ZM5.59998 7.19999H3.99997V5.59998H5.59998V7.19999ZM8.80001 7.19999H7.19999V5.59998H8.80001V7.19999ZM12 7.19999H10.4V5.59998H12V7.19999Z"
        fill="#A1B2C8"
      />
    </SvgStyled>
  );
};
