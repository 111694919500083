import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  APPLICATION_DELETE_SEARCH_MEMBER_QUERY,
  APPLICATION_SEARCH_MEMBERS_QUERY,
} from '../../../../../../gql';
import {
  DeleteSearchMember,
  DeleteSearchMemberVariables,
} from '../../../../../../gql/application/mutations/__generated__/DeleteSearchMember';
import {SearchMemberListVariables} from '../../../../../../gql/application/queries/__generated__/SearchMemberList';
import {SearchMemberList} from '../../../../../../gql/application/queries/__generated__/SearchMemberList';
import {Id} from '../../../../../../types/auxiliary.types';
import {Card} from './SearchMembers.types';

export function useSearchMembers() {
  const params = useParams<{applicationId?: string}>();

  const {data, loading, refetch} = useQuery<
    SearchMemberList,
    SearchMemberListVariables
  >(APPLICATION_SEARCH_MEMBERS_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null || params.applicationId === '',
    fetchPolicy: 'no-cache',
    refetchWritePolicy: 'merge',
  });

  const fetch = useCallback(() => {
    return refetch({
      id: (params.applicationId || '').toString(),
    });
  }, [refetch, params.applicationId]);

  return {
    data:
      data?.issue?.participants == null
        ? {}
        : data?.issue?.participants.reduce(
            (acc: Record<Id, {name: string; list: Card[]}>, p) => {
              if (p?.group?.id == null) return acc;

              const newItem = {
                id: p.user?.id || '',
                idParticipant: p.id || '',
                name: p.user?.profile.full_name || '',
                nickname: p.user?.profile.nickname || '',
                address: p.user?.profile.address || '',
                phones: [
                  p.user?.profile.phone_number || '',
                  ...(p.user?.additionalPhones?.map((ph) => ph?.number || '') ||
                    []),
                ],
                socialNetworkInfo: {
                  vk: p.user?.profile.account_vkontakte || undefined,
                  facebook: p.user?.profile.account_facebook || undefined,
                  twitter: p.user?.profile.account_twitter || undefined,
                  telegram: p.user?.profile.account_telegram || undefined,
                },
                startDate:
                  p.shift_start == null ? null : new Date(p?.shift_start),
              };

              return {
                ...acc,
                [p.group.id]: {
                  name: p.group.full_name,
                  idPart: p.id,
                  list:
                    acc[p.group.id] == null
                      ? [newItem]
                      : [...acc[p.group.id].list, newItem],
                },
              };
            },
            {}
          ),
    loading,
    fetch,
  };
}

export const useDeleteSearchMember = (
  onChangeSuccess?: () => void,
  onModalClose?: () => void
) => {
  const [deleteSearchMember] = useMutation<
    DeleteSearchMember,
    DeleteSearchMemberVariables
  >(APPLICATION_DELETE_SEARCH_MEMBER_QUERY);

  function handleDeleteMember(id: Id) {
    deleteSearchMember({
      variables: {
        id: Number(id),
      },
    }).then(() => {
      if (onChangeSuccess != null) {
        toast('Участник поиска успешно удален', {type: 'success'});
        onChangeSuccess();
        onModalClose?.();
      }
    });
  }
  return {handleDeleteMember};
};
