import styled from 'styled-components';

export const Wrapper = styled.form`
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Buttons = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
