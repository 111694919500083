import React from 'react';
import { Url } from '../../../types/auxiliary.types';

import {Card, Title, Video} from './styles';

interface Props {
  name: string;
  url: Url;
}

export function VideoCard(props: Props) {
  return (
      <Card>
        <Video
          src={props.url
            .replace('https://youtu.be/', 'https://www.youtube.com/embed/')
            .replace(
              'https://rutube.ru/live/video/',
              'https://rutube.ru/play/embed/'
            )
            .replace(
              'https://rutube.ru/video/',
              'https://rutube.ru/play/embed/'
            )}
          frameBorder="0"
          title={props.name}
        />
        <Title>{props.name}</Title>
      </Card>
    );
  }
