import React from 'react';
import {Content} from './Link.styles';

interface Props {
  links: string | undefined;
}

const Links = (props: Required<Props>) => {
  return (
    <>
      <Content href={props.links}>{props.links}</Content>
    </>
  );
};

export default Links;
