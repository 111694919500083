import styled from 'styled-components';

export const Wrapper = styled.div``;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 22px 0 18px;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 8px;
  }
`;
