import {
  Status,
  Wrapper,
  MainInfo,
  Field,
  Title,
  Footer,
  Value,
  Phone,
  FooterField,
  FooterLabel,
  FooterValue,
} from './ApplicantCard.styles';

interface Props {
  fullName: string;
  label?: string;
  phone: string;
  additionalContact?: string;
  MES?: string;
  police?: string;
  is112?: boolean | null;
}

export function ApplicantCard(props: Props) {
  return (
    <Wrapper>
      <MainInfo>
        <Field>
          <Title>Заявитель:</Title>
          <Value>{props.fullName}</Value>
        </Field>
        <Field full>
          <Phone href={`tel:${props.phone}`}>
            {props.is112 ? '112' : props.phone}
          </Phone>
          {props.additionalContact != null && (
            <Value>{props.additionalContact}</Value>
          )}
        </Field>
        <Status>{props.label}</Status>
      </MainInfo>
      {(props.MES != null || props.police != null) && (
        <Footer>
          {props.MES != null && (
            <FooterField>
              <FooterLabel>МЧС,ПСО, 112: </FooterLabel>
              <FooterValue>{props.MES}</FooterValue>
            </FooterField>
          )}
          {props.police != null && (
            <FooterField>
              <FooterLabel>Заявление в полицию: </FooterLabel>
              <FooterValue>{props.police}</FooterValue>
            </FooterField>
          )}
        </Footer>
      )}
    </Wrapper>
  );
}
