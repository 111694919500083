import styled from 'styled-components';

export const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Title = styled.header`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: #333;
`;
