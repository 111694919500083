import {toast} from 'react-toastify';
import {ChangeCallStatusVariables} from './../../../../../../gql/call/mutations/__generated__/ChangeCallStatus';
import {CallStatus} from './../../../../../../gql/call/queries/__generated__/CallStatus';
import {
  CALL_CHANGE_STATUS_MUTATION,
  CALL_STATUSES_QUERY,
} from './../../../../../../gql/index';
import {useQuery, useMutation} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {CALL_DETAIL_QUERY} from '../../../../../../gql';
import {ChangeCallStatus} from '../../../../../../gql/call/mutations/__generated__/ChangeCallStatus';
import {
  Detail,
  DetailVariables,
} from '../../../../../../gql/call/queries/__generated__/Detail';
import {Id} from '../../../../../../types/auxiliary.types';
import {
  IGroupStatus,
  IStatus,
} from '../../../../../elements/ChangedStatus/type';

export function useCall() {
  const params = useParams<{callId?: string}>();
  const {data, loading, refetch} = useQuery<Detail, DetailVariables>(
    CALL_DETAIL_QUERY,
    {
      variables: {
        id: Number(params.callId),
      },
      skip: params.callId == null || params.callId === '',
    }
  );

  return {
    refetch,
    data:
      data?.call == null
        ? null
        : {
            id: data.call.id || '',
            responsibleId: data.call.responsible?.id,
            responsibleFullName: data.call.responsible?.profile.full_name,
            responsibleEmail: data.call.responsible?.email,
            responsiblePhone: data.call.responsible?.profile.phone_number,
            isUrgently: data.call.is_urgent || false,
            isEmailSend: data.call.is_email_sent, //== null ? null : data.call.is_email_sent,
            contact: data.call.contact,
            email: data.call.email,
            is112: data.call.is_112,
            phone: data.call.phone,
            status: {
              id: data.call.status?.id || '',
              name: data.call.status?.name || '',
              color: data.call.status?.color || '',
            },
            isEducational: data.call.is_training || false,
            datetime:
              data.call.date_time == null
                ? null
                : new Date(data.call.date_time),
            type: data.call.type?.name || '',
            source: data.call.source?.name || '',
            applicationId: data.call.issue?.id || null,
            callsId: data.call.calls?.map((c) => c?.id || '') || [],
            regions: [
              data.call.region?.name,
              ...(data.call.regions?.map((r) => r?.name || '') || []),
            ].filter(Boolean),
          },
    loading,
  };
}

export function useStatuses() {
  const {data, loading} = useQuery<CallStatus>(CALL_STATUSES_QUERY);

  return {
    loading,
    data:
      data?.allCallStatuses == null
        ? []
        : data.allCallStatuses.map((status) => ({
            id: status?.id || '',
            name: status?.name || '',
            color: status?.color || '',
          })),
  };
}

export function useChangeStatus(
  onSuccess?: () => void,
  curStatusId?: Id,
  statuses?: IGroupStatus[]
) {
  const params = useParams<{callId: string}>();

  const [changeStatus] = useMutation<
    ChangeCallStatus,
    ChangeCallStatusVariables
  >(CALL_CHANGE_STATUS_MUTATION);

  const currentStatus =
    curStatusId != null
      ? statuses?.reduce((acc: IStatus | undefined, curr) => {
          if (acc != null) return acc;
          return curr.statuses.find(
            (s) => s.id.toString() === curStatusId.toString()
          );
        }, undefined)
      : undefined;

  function handleChangeStatus(id: Id) {
    changeStatus({
      variables: {
        input: {
          callId: Number(params.callId),
          statusId: Number(id),
        },
      },
    }).then(() => {
      toast('Статус успешно изменен');
      onSuccess?.();
    });
  }
  return {
    handleChangeStatus,
    currentStatus,
  };
}
