import React, {useContext} from 'react';
import {UserContext} from '../../../context/UserContext';
import {useWidgetHooks} from '../../../hooks/useWidget.hooks';
import Leaders from '../../elements/Leaders/Leaders';
import Links from '../../elements/Link/Links';
import Preloader from '../../elements/Preloader/Preloader';
import {Tabs} from '../../elements/Tabs/Tabs';
import Equipments from './components/equipment/component';
import Technics from './components/technic/component';
import {Events} from './components/Events/Events';
import {Groups} from './components/Groups/Groups';
import {Main} from './components/Main/Main';
import {ParticipantList} from './components/ParticipantList/ParticipantList';
import {Maps} from './components/Maps/component';
import {Photos} from './components/Photos/Photos';
import {Videos} from './components/Videos/Videos';

import {useActiveDuty, useDetachment} from './DetachmentScreen.hooks';
import {
  Container,
  Primary,
  Title,
  Widget,
  WidgetWrapper,
  Wrapper,
} from './DetachmentScreen.styles';
import {Files} from './components/Files/Files';
import {
  TitleLeaders,
  WrapperLeaders,
} from '../../elements/Leaders/Leaders.styles';
import DutyBlock from '../../elements/DutyBlock/DutyBlock';

export function DetachmentScreen() {
  const user = useContext(UserContext);

  const {loading, data, activeTab, handleSwitchTab} = useDetachment();

  const {data: widget} = useWidgetHooks();

  const hasFullAccess = user != null && user.teamId === data?.team.id;

  const {duty} = useActiveDuty();

  function renderForm() {
    if (!data?.team) return null;

    if (!hasFullAccess) {
      switch (activeTab) {
        case 'about': {
          return <Main />;
        }
        case 'equipment': {
          return <Equipments />;
        }
        case 'technique': {
          return <Technics />;
        }
        default:
          return null;
      }
    }

    switch (activeTab) {
      case 'about': {
        return <Main />;
      }
      case 'group': {
        return <Groups />;
      }
      case 'users': {
        return <ParticipantList />;
      }
      case 'events': {
        return <Events />;
      }
      case 'equipment': {
        return <Equipments />;
      }
      case 'technique': {
        return <Technics />;
      }
      case 'maps': {
        return <Maps />;
      }
      case 'photos': {
        return <Photos />;
      }
      case 'videos': {
        return <Videos />;
      }
      case 'files': {
        return <Files />;
      }
      default:
        return <i>Раздел находится в разработке</i>;
    }
  }

  if (loading) {
    return <Preloader />;
  }

  if (!data?.team) {
    return <div>'Not found'</div>;
  }

  return (
    <Wrapper>
      <Title>{data.team.name}</Title>
      <Tabs
        activeTab={activeTab || ''}
        items={[
          {
            id: 'about',
            label: 'Об отряде',
          },
          {
            id: 'equipment',
            label: 'Оборудование',
          },
          {
            id: 'technique',
            label: 'Техника',
          },
        ].concat(
          !hasFullAccess
            ? []
            : [
                {
                  id: 'group',
                  label: 'Группы',
                },
                {
                  id: 'users',
                  label: 'Участники',
                },

                {
                  id: 'maps',
                  label: 'Карты',
                },
                {
                  id: 'events',
                  label: 'Мероприятия',
                },
                {
                  id: 'photos',
                  label: 'Фото',
                },
                {
                  id: 'videos',
                  label: 'Видео',
                },
                {
                  id: 'files',
                  label: 'Файлы',
                },
              ]
        )}
        onSwitchTab={handleSwitchTab}
      />
      <Container>
        <Primary>{renderForm()}</Primary>
        <WidgetWrapper>
          <WrapperLeaders>
            <TitleLeaders>Руководители</TitleLeaders>
            {widget?.team.leaders?.map((leader: any) => {
              return (
                <Leaders
                  key={leader?.id}
                  id={leader?.id}
                  email={leader?.email}
                  fullName={leader?.profile.full_name}
                />
              );
            })}
          </WrapperLeaders>
          <Widget>
            <WrapperLeaders>
              <TitleLeaders>Дежурные</TitleLeaders>
              {duty.map((item) => {
                return (
                  <DutyBlock
                    id={item.id}
                    dateStart={item.dateStart}
                    dateEnd={item.dateEnd}
                    group={item.group}
                    user={item.user}
                  />
                );
              })}
            </WrapperLeaders>
          </Widget>
          <Widget>
            <WrapperLeaders>
              <TitleLeaders>Ссылки</TitleLeaders>
              {widget?.team.links?.map((links: any) => {
                return <Links key={links?.id} links={links?.website_link} />;
              })}
            </WrapperLeaders>
          </Widget>
        </WidgetWrapper>
      </Container>
    </Wrapper>
  );
}
