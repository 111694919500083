import {gql} from '@apollo/client';
import {client} from '../gql-client';
import {WebSocketAuthToken} from '../gql/mutations/__generated__/WebSocketAuthToken';

const query = gql`
  mutation WebSocketAuthToken {
    websocketAuth {
      token
      mainSub {
        token
        channel
      }
      callRequestsSub {
        token
        channel
      }
    }
  }
`;

export const fetchWebSocketToken = async () => {
  try {
    const {data} = await client.mutate<WebSocketAuthToken>({mutation: query});
    return {
      data: {
        token: data?.websocketAuth?.token,
        mainSub: {
          token: data?.websocketAuth?.mainSub.token,
          channel: data?.websocketAuth?.mainSub.channel,
        },
        callRequestsSub: {
          token: data?.websocketAuth?.callRequestsSub.token,
          channel: data?.websocketAuth?.callRequestsSub.channel,
        },
      },
    };
  } catch (error) {
    return null;
  }
};
