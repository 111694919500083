import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

interface ITab {
  active: boolean;
}

export const Tab = styled.button<ITab>`
  padding: 8px 16px;
  box-sizing: border-box;
  margin-bottom: 8px;

  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);

  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  color: ${(p) => (p.active ? '#fff' : 'var(--black-color)')};
  background-color: ${(p) => (p.active ? '#376092' : 'transparent')};
  border: 1px solid ${(p) => (p.active ? '#376092' : '#A1B2C8')};
  transition: color, background-color, border 500ms linear;

  cursor: pointer;

  &:hover {
    //not found in design
  }

  &:disabled {
    //not found in design
    cursor: not-allowed;
  }
`;
