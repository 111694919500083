import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {CREATE_TEAM_VIDEO_MUTATION} from '../../../../../gql';
import {CreateTeamVideo, CreateTeamVideoVariables} from '../../../../../gql/mutations/__generated__/CreateTeamVideo';
import {FormFields} from './AddVideo.types';
import {toast} from 'react-toastify';

export function useAddVideoHandler(onAddSuccess: () => void) {
  const [requestSending, setRequestSending] = useState(false)
  const [addVideoMutation] = useMutation<CreateTeamVideo, CreateTeamVideoVariables>(
    CREATE_TEAM_VIDEO_MUTATION
  );

  const handleAddVideo = useCallback(
    async (data: FormFields) => {
      setRequestSending(true)

      await addVideoMutation({
        variables: {
          input: data,
        },
      })
        .then(() => {
          toast('Видео успешно добавлено');
          onAddSuccess();
        })
        .catch((e) => toast(e.message, {type: 'error'}))
        .finally(() => setRequestSending(false));
    },
    [addVideoMutation, onAddSuccess]
  );

  return {handleAddVideo, requestSending}
}
