import {useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams, useSearchParams} from 'react-router-dom';
import {APPLICATION_VIDEOS_QUERY} from '../../../../../../gql';
import {
  Videos,
  VideosVariables,
} from '../../../../../../gql/application/queries/__generated__/Videos';
import {getFullName_dto} from '../../../../../../utils/String.utils';

import {Video} from './types';

export function useVideos() {
  const [searchParams] = useSearchParams();
  const params = useParams<{applicationId?: string}>();
  const {data, loading, fetchMore} = useQuery<Videos, VideosVariables>(
    APPLICATION_VIDEOS_QUERY,
    {
      variables: {
        id: (params.applicationId || '').toString(),
      },
      skip: params.applicationId == null || params.applicationId === '',
    }
  );

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.applicationId || '').toString(),
      },
    });
  }, [fetchMore, params.applicationId]);

  const missing = data?.issue?.missing?.find(
    (m) => m?.id === Number(searchParams.get('videos'))
  );

  return {
    missingId: Number(searchParams.get('videos')),
    missingName:
      missing == null
        ? ''
        : getFullName_dto({
            first_name: missing.first_name,
            last_name: missing.last_name,
            second_name: missing.second_name,
          }),
    list:
      missing?.videos?.map((p) => ({
        name: p?.name || '',
        url: p?.url || '',
      })) ?? ([] as Video[]),
    loading,
    fetch,
  };
}
