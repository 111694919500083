import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  position: relative;
`;

export const AddButton = styled.button`
  position: absolute;
  top: 40px;
  right: 16px;

  width: 16px;
  height: 16px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--primary-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;

    path {
      stroke: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    background-color: #fff;

    svg path {
      stroke: var(--primary-color);
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;

  > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const Item = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  display: flex;
  align-items: center;

  color: var(--text-color);
`;

export const DeleteButton = styled.button`
  margin-left: 10px;
  width: 16px;
  height: 16px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--warning-color);
  border-radius: 4px;
  background-color: var(--warning-color);

  transition: background-color 500ms linear;

  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: #fff;

      transition: stroke 500ms linear;
    }
  }

  &:not(:disabled):hover {
    background-color: #fff;

    svg path {
      fill: var(--warning-color);
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;
