import React from 'react';
import {useForm} from 'react-hook-form';
import MIMEConstants from '../../../../../constants/mime.constants';
import {FileCard} from '../../../../cards/FileCard/FileCard';
import Preloader from '../../../../elements/Preloader/Preloader';

import {Url} from '../../../../../types/auxiliary.types';

import {useTeam} from './Files.hooks';
import {MediaList} from '../MediaList/MediaList';

import {AddFile} from '../../modals/AddFile/AddFile';

interface IFile {
  name: string;
  url: Url;
  size: number;
  mime: string;
}

export function Files() {
  const {loading, list, fetch} = useTeam();

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  function renderModal() {
    if (file == null) return null;

    return (
      <AddFile
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }

  function renderCard(file: IFile) {
    return (
      <FileCard
        key={file.url}
        name={file.name}
        mime={file.mime}
        size={file.size}
        url={file.url}
      />
    );
  }

  if (loading) return <Preloader />;

  const accept = Object.entries(MIMEConstants).reduce(
    (acc: string[], [k, v]) =>
      ['word', 'exel', 'pdf'].includes(v) ? [...acc, k] : acc,
    []
  );

  return (
    <MediaList<IFile>
      list={list}
      typeButton="fileInput"
      buttonLabel="Добавить файл"
      renderCard={renderCard}
      renderModal={renderModal}
      formRegister={formRegisterResult}
      accept={accept.join(',')}
      countInRow={1}
    />
  );
}
