import styled from 'styled-components';

type ColorType = 'orange' | 'pink';

const config: Record<ColorType, string> = {
  orange: 'var(--primary-color)',
  pink: 'var(--warning-color)',
};

interface IIconWrapper {
  color: ColorType;
}

export const IconWrapper = styled.button<IIconWrapper>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  width: 16px;
  height: 16px;

  border-radius: 4px;

  border: none;
  cursor: pointer;
  background-color: ${(p) => config[p.color]};

  svg {
    width: 12px;
    height: 12px;

    fill: #fff;
  }

  &:not(&:disabled):hover {
    // not found in design
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
