import React, {CSSProperties} from 'react';
import {Id} from '../../../../types/auxiliary.types';
import {getAgeFromDate} from '../../../../utils/Date.utils';
import {declOfNum} from '../../../../utils/String.utils';
import {MenIcon} from '../../../icons/MenIcon';
import {WomenIcon} from '../../../icons/WomenIcon';
import {
  Area,
  Missing,
  GenderWrapper,
  Name,
  Age,
  Status,
  Wrapper,
} from './styles';

export interface Props {
  area?: string;
  missing: {
    id: Id;
    status?: {
      name: string;
      color: CSSProperties['backgroundColor'];
    };
    fullName: string;
    gender: 'w' | 'm';
    birthday?: string;
  }[];
}

export default function MissingColumn(props: Props) {
  return (
    <Wrapper>
      {props.missing.map((missing) => {
        let age: any =
          missing.birthday == null
            ? null
            : getAgeFromDate(new Date(missing.birthday));

        if (isNaN(Number(age))) {
          age = missing.birthday;
        }

        return (
          <Missing key={missing.id}>
            <Status color={missing.status?.color}>
              {missing.status?.name || 'Статус не задан'}
            </Status>
            <GenderWrapper gender={missing.gender}>
              {missing.gender === 'w' && <WomenIcon />}
              {missing.gender === 'm' && <MenIcon />}
            </GenderWrapper>
            <Age>
              {missing.birthday == null
                ? '-'
                : declOfNum(age as number, ['год', 'года', 'лет'])}
            </Age>
            <Name>{missing.fullName}</Name>
          </Missing>
        );
      })}
      {props.area != null && props.area !== '' && <Area>{props.area}</Area>}
    </Wrapper>
  );
}
