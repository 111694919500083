import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {APPLICATION_CREATE_VERIFICATION_MUTATION} from '../../../../../../gql';
import {
  CreateVerification,
  CreateVerificationVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateVerification';
import {FormFields} from '../../../modals/Verification/types';
import {toast} from 'react-toastify';

export const useVerificationHandler = (onAddSuccess: () => void) => {
  const [loading, setLoading] = useState(false);
  const params = useParams<{applicationId?: string}>();

  const [createVerificationMutation] = useMutation<
    CreateVerification,
    CreateVerificationVariables
  >(APPLICATION_CREATE_VERIFICATION_MUTATION);

  const submit = useCallback(
    async (data: FormFields, setServerError: UseFormSetError<FormFields>) => {
      await createVerificationMutation({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            comment: data.description,
          },
        },
      })
        .then(() => {
          toast('Верификация успешно добавлена!');
          setLoading(true);
          onAddSuccess();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [createVerificationMutation, onAddSuccess, params.applicationId]
  );

  return {submit, loading};
};
