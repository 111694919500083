import styled from 'styled-components';

export const Wrapper = styled.div`
 
`;

export const ListWrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
