import {useMutation} from '@apollo/client';
import {RESET_PASSWORD_MUTATION} from '../../../../gql';
import {
  ResetPassword,
  ResetPasswordVariables,
} from '../../../../gql/mutations/__generated__/ResetPassword';

export const useResetPasswordHook = () => {
  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD_MUTATION
  );

  return async (data: ResetPasswordVariables) => {
    await resetPassword({variables: data});
  };
};
