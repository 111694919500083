import styled from 'styled-components';

export const Form = styled.form``;

export const Buttons = styled.div`
  margin-top: 24px;
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
