import React from 'react';

import Transfer from '../../../../../modals/transfer/component';
import {useActions} from './hooks';

interface Props {
  onChangeSuccess?: () => void
  onClose: () => void
}

export default function TransferCall(props: Props) {
  const {handleTransferCall} = useActions()

  return (
    <Transfer
      onTransfer={handleTransferCall as any}
      onChangeSuccess={props.onChangeSuccess}
      onClose={props.onClose}
    />
  )
}
