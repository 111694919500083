import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  flex: auto;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;

  flex: auto;

  padding: 25px 24px;
`;

export const ResponsibleWrapper = styled.div``;
