import {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

export function useUrlParamInitialization(
  params: Record<string, string | number>,
  shouldWait = false
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const prevState = useCurrSearch();

  useEffect(() => {
    if (shouldWait && Object.keys(prevState).length === 0) return;
    const setParams = Object.entries(params).filter(
      ([key, _]) => !searchParams.has(key)
    );

    if (setParams.length !== 0) {
      setSearchParams(
        {
          ...prevState,
          ...setParams.reduce((acc: Record<string, string>, [key, value]) => {
            return {
              ...acc,
              [key]: value.toString(),
            };
          }, {}),
        },
        {
          replace: true,
        }
      );
    }
  }, [params, prevState, searchParams, setSearchParams, shouldWait]);
}

export function useCurrSearch<T = Record<string, string>>(): T | {} {
  const [search, setSearch] = useState<T | {}>({});

  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const search = location.search.substring(1);

    setSearch(
      search === ''
        ? {}
        : JSON.parse(
            '{"' +
              decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}'
          )
    );
  }, [searchParams, location.search]);

  return search;
}
