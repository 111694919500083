import {useQuery} from "@apollo/client";
import {useSearchParams} from 'react-router-dom';
import {UserList} from "../../../../gql/users/queries/__generated__/UserList";
import {USER_LIST_QUERY} from '../../../../gql';
import {useCurrSearch, useUrlParamInitialization} from '../../../../hooks/useUrl.hooks';
import {FormFields} from './components/filter/types';

export function useUserList() {
  const currSearchParams = useCurrSearch<FormFields & {page: string | null}>() as FormFields & {page: string | null};

  const {data, loading} = useQuery<UserList>(USER_LIST_QUERY, {
    variables: {
      ...(currSearchParams.groupId != null && currSearchParams.groupId !== "" && {
        groupId: Number(currSearchParams.groupId),
      }),
      ...(currSearchParams.regionId != null && currSearchParams.regionId !== "" && {
        regionId: Number(currSearchParams.regionId),
      }),
      ...(currSearchParams.teamId != null && currSearchParams.teamId !== "" && {
        teamId: Number(currSearchParams.teamId),
      }),
      ...(currSearchParams.search != null && currSearchParams.search !== "" && {
        search: currSearchParams.search,
      }),
      page: Number(currSearchParams.page)
    },
    fetchPolicy: "no-cache",
    skip: currSearchParams.page == null,
  });

  return ({
    data: data?.users?.data == null ? [] : data?.users?.data.map((user) => ({
      id: user.id || "",
      avatar: undefined,
      fullName: user.profile.full_name || "",
      nickname: user.profile.nickname || "",
      address: user.profile.address || "",
      regions: user.regions?.map(r => r.name || "") || [],
      team: user.team?.name || "",
      phones: [user.profile.phone_number || "", ...(user.additionalPhones || [])?.map(a => a?.number || "")].filter(Boolean),
      emails: [user.email || "", ...(user.additionalEmails || [])?.map(a => a?.name || "")].filter(Boolean),
      socialNetworkInfo: {
        vk: user?.profile.account_vkontakte || undefined,
        facebook: user?.profile.account_facebook || undefined,
        twitter: user?.profile.account_twitter || undefined,
        telegram: user?.profile.account_telegram || undefined,
      },
    })),
    pagination: {
      lasPage: data?.users?.paginatorInfo.lastPage || 0,
      total: data?.users?.paginatorInfo?.total || 0
    },
    loading
  })
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    handleSwitchPage,
    page: Number(searchParams.get("page")),
  };
}

export function useStatistic() {

  return ({
    statistic: [],
    loading: false
  })
}

