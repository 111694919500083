import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  
  > *:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    > *:not(:last-child) {
      margin-right: 0;
    }
    
    > *:not(:first-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  
`;

export const List = styled.div`
width: 100%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Secondary = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  margin: 0 0 18px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: var(--text-color);
`;
