import React from 'react';
import {Form} from '../form/form';
import {useAddDuty} from './hooks';

interface Props {
  onAddSuccess: () => void;
  onClose: () => void;
}

export function AddForm(props: Props) {
  const {handleSubmit, loading} = useAddDuty(props.onAddSuccess);

  return (
    <Form
      title="Добавить дежурство"
      submitLabel="Добавить"
      submitLoading={loading}
      onClose={props.onClose}
      onSubmit={handleSubmit}
    />
  );
}
