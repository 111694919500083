import React, {useState} from 'react';
import {Button} from '../../../elements/Button/Button';
import Preloader from '../../../elements/Preloader/Preloader';
import ProfileItem from '../../../elements/ProfileItem/ProfileItem';

import {useEquipment} from './Equipments.hooks';

import * as S from './Equipments.styles';
import AddEquipmentForm from './modals/add/component';
import {useActions} from './modals/delete/hook';

export default function Equipments() {
  const {equipments: equipment, loading, refetch} = useEquipment();

  const {loading: actionLoading, handleDelete} = useActions(refetch);

  const [showModal, setShowModal] = useState(false);

  if (loading) return <Preloader />;

  return (
    <S.Wrapper>
      {showModal ? (
        <AddEquipmentForm
          onAddSuccess={() => {
            refetch().then(() => setShowModal(false));
          }}
          onClose={() => setShowModal(false)}
        />
      ) : null}
      <Button fullWidth variant="outlined" onClick={() => setShowModal(true)}>
        Добавить оборудование
      </Button>

      <S.ContentWrapper>
        {equipment.map((equipment) => {
          return (
            <ProfileItem
              loading={actionLoading}
              id={equipment.id}
              handleDelete={() => handleDelete(equipment.id)}
              key={equipment.id}
              name={equipment.name}
              note={equipment.note}
            />
          );
        })}
      </S.ContentWrapper>
    </S.Wrapper>
  );
}
