import {useQuery} from '@apollo/client';
import {USE_INSTITUTIONS_DICT_QUERY} from '../../../../../../gql';
import {useSearchParams} from 'react-router-dom';
import {
  InstitutionsDict,
  InstitutionsDict_allInstitutionTypes,
} from '../../../../../../gql/helpfulInfo/queries/__generated__/InstitutionsDict';
import {createDict} from '../../../../../../utils/Dict.utils';
import {createUrlSearch} from '../../../../../../utils/url.utils';
import {Option} from '../../../../../elements/Select/Select.types';
import {FormFields} from './types';
import {useCallback} from 'react';

export function useDict() {
  const {data, loading} = useQuery<InstitutionsDict>(
    USE_INSTITUTIONS_DICT_QUERY
  );

  const areaDict =
    data == null || data.allRegions == null
      ? {}
      : data.allRegions.reduce((acc: Record<string, Option[]>, region) => {
          if (region == null) return acc;

          return {
            ...acc,
            [region.id]:
              region.districts == null
                ? []
                : region.districts.map((district) => ({
                    label: district?.name || '',
                    value: district?.id || '',
                  })),
          };
        }, {});

  return {
    loading,
    region: data == null ? [] : createDict(data.allRegions),
    type: data == null ? [] : createDict(data.allInstitutionTypes),
    district: areaDict,
  };
}

export function useAction() {
  const [, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {
      setSearchParams({
        page: '1',
        ...createUrlSearch(data),
      });
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams({page: '1'}),
    [setSearchParams]
  );

  return {submit: handleSubmit, handleReset};
}
