import {useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {CALL_PHOTOS_QUERY} from '../../../../../../gql';
import {
  CallPhotos,
  CallPhotosVariables,
} from '../../../../../../gql/call/queries/__generated__/CallPhotos';

export function usePhoto() {
  const params = useParams<{callId?: string}>();

  const {data, loading, fetchMore} = useQuery<CallPhotos, CallPhotosVariables>(
    CALL_PHOTOS_QUERY,
    {
      variables: {
        id: Number(params.callId),
      },
      skip: params.callId == null || params.callId === '',
    }
  );

  const fetch = useCallback(
    () =>
      fetchMore({
        variables: {
          id: Number(params.callId),
        },
      }),
    [fetchMore, params.callId]
  );

  return {
    list: data?.call?.images == null ? [] : data.call.images.map((m) => ({
      name: m?.name || '',
      url: m?.url || '',
    })) || [],
    loading,
    fetch
  };
}
