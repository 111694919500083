import React from 'react';
import {SvgStyled} from '../Icons.styles';
const TypographyIcon = () => {
  return (
    <SvgStyled
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M47.2 16H43.2V10.4C43.2 9.9582 42.8418 9.6 42.4 9.6H40V0.8C40 0.3582 39.6418 0 39.2 0H8.8C8.3582 0 8 0.3582 8 0.8V9.6H5.6C5.1582 9.6 4.8 9.9582 4.8 10.4V16H0.8C0.3582 16 0 16.3582 0 16.8V39.2C0 39.6418 0.3582 40 0.8 40H8V47.2C8 47.6418 8.3582 48 8.8 48H39.2C39.6418 48 40 47.6418 40 47.2V40H47.2C47.6418 40 48 39.6418 48 39.2V16.8C48 16.3582 47.6418 16 47.2 16ZM40 11.2H41.6V16H40V11.2ZM9.6 1.6H38.4V16H9.6V1.6ZM6.4 11.2H8V16H6.4V11.2ZM8 35.2H7.2V32.8H8V35.2ZM38.4 46.4H9.6V32.8H38.4V46.4ZM46.4 38.4H40V36.8H41.6C42.0418 36.8 42.4 36.4418 42.4 36V32C42.4 31.5582 42.0418 31.2 41.6 31.2H6.4C5.9582 31.2 5.6 31.5582 5.6 32V36C5.6 36.4418 5.9582 36.8 6.4 36.8H8V38.4H1.6V17.6H46.4V38.4ZM40 35.2V32.8H40.8V35.2H40Z" />
    </SvgStyled>
  );
};

export default TypographyIcon;
