import React from 'react';
import {SvgStyled} from './Icons.styles';

export const HomeIcon = () => {
  return (
    <SvgStyled
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M22.125 8.67466V19.8752C22.1244 20.4717 21.8872 21.0437 21.4653 21.4655C21.0435 21.8873 20.4716 22.1246 19.875 22.1252H16.125C15.7272 22.1252 15.3456 21.9671 15.0643 21.6858C14.783 21.4045 14.625 21.023 14.625 20.6252V16.5002C14.625 16.3012 14.546 16.1105 14.4053 15.9698C14.2647 15.8292 14.0739 15.7502 13.875 15.7502H10.125C9.92609 15.7502 9.73532 15.8292 9.59467 15.9698C9.45402 16.1105 9.375 16.3012 9.375 16.5002V20.6252C9.375 21.023 9.21696 21.4045 8.93566 21.6858C8.65436 21.9671 8.27282 22.1252 7.875 22.1252H4.125C3.52845 22.1246 2.9565 21.8873 2.53467 21.4655C2.11284 21.0437 1.8756 20.4717 1.875 19.8752V8.67466C1.87556 8.41564 1.94287 8.16113 2.07043 7.9357C2.19799 7.71027 2.3815 7.52152 2.60325 7.38766L11.2282 2.21266C11.4614 2.07271 11.7282 1.99878 12.0002 1.99878C12.2721 1.99878 12.539 2.07271 12.7721 2.21266L21.3971 7.38766C21.6188 7.52156 21.8022 7.71033 21.9297 7.93576C22.0572 8.16119 22.1245 8.41567 22.125 8.67466V8.67466Z" />
    </SvgStyled>
  );
};
