import {useMutation, useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';

import {
  ANNOUNCEMENT_LIST_QUERY,
  TOGGLE_READ_ANNOUNCEMENT_MUTATION,
} from '../../../../gql';
import {
  AnnouncementList,
  AnnouncementListVariables,
} from '../../../../gql/profile/queries/__generated__/AnnouncementList';
import {
  ToggleReadAnnouncement,
  ToggleReadAnnouncementVariables,
} from '../../../../gql/profile/mutations/__generated__/ToggleReadAnnouncement';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../hooks/useUrl.hooks';
import {Id} from '../../../../types/auxiliary.types';

export function useAnnouncement() {
  const prevState = useCurrSearch();

  useUrlParamInitialization(
    {
      page: 1,
    },
    true
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page');

  const {data, loading, refetch} = useQuery<
    AnnouncementList,
    AnnouncementListVariables
  >(ANNOUNCEMENT_LIST_QUERY, {
    variables: {
      page: Number(page),
    },
    skip: page == null || page === '',
  });

  const [toggleReadAnnouncement, {loading: actionLoading}] = useMutation<
    ToggleReadAnnouncement,
    ToggleReadAnnouncementVariables
  >(TOGGLE_READ_ANNOUNCEMENT_MUTATION);

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  function handleToggleRead(id: Id) {
    toggleReadAnnouncement({
      variables: {
        id: Number(id),
      },
    }).then(() => {
      refetch?.();
    });
  }

  return {
    loading,
    actionLoading,
    paginatorInfo: {
      currentPage:
        data?.announcements?.paginatorInfo == null
          ? page
          : data.announcements.paginatorInfo.currentPage,
      lastPage:
        data?.announcements?.paginatorInfo == null
          ? 0
          : data.announcements.paginatorInfo.lastPage,
    },
    list:
      data?.announcements?.data == null
        ? []
        : data.announcements.data.map((a) => ({
            id: a.id,
            author: {
              avatar: undefined,
              fullName: a.createdBy?.profile.full_name || '',
            },
            createDate: a.created_at == null ? null : new Date(a.created_at),
            isRead: a.is_read || false,
            text: a.text || '',
          })),
    handleToggleRead,
    handleSwitchPage,
  };
}
