import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 32px;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const LinkWrapper = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: var(--text-color);
`;

export const LinkS = styled(Link)`
  text-decoration: none;
  color: var(--primary-color);
`;
