import React from 'react';
import {
  FieldValues,
  Control,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {Id} from '../../../../../types/auxiliary.types';
import {ErrorMessage} from '../../../../elements/ErrorMessage/ErrorMessage';
import {
  Input,
  InputLabel,
  Item,
  Items,
  Label,
  Wrapper,
} from './LabelCheckbox.styles';

interface Props<T extends FieldValues> {
  label?: string;
  control: Control<T>;
  name: Path<T>;
  options: {
    id: Id;
    label: string | number;
  }[];
  rules?: UseControllerProps['rules'];
}

export default function LabelCheckbox<FormField extends FieldValues>(
  props: Props<FormField>
) {
  const {field, fieldState} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper>
      {props.label != null && props.label !== '' && (
        <Label>{props.label}</Label>
      )}
      <Items>
        {props.options.map((option, index) => {
          return (
            <Item key={option.id}>
              <Input
                type="checkbox"
                value={option.id}
                checked={field.value.includes(option.id)}
                id={option.id.toString()}
                name={`${props.name}.${index}`}
                onChange={() => {
                  field.onChange(
                    field.value.includes(option.id)
                      ? field.value.filter((f: Id) => f !== option.id)
                      : [...field.value, option.id]
                  );
                }}
              />
              <InputLabel htmlFor={option.id.toString()}>
                {option.label}
              </InputLabel>
            </Item>
          );
        })}
      </Items>
      {fieldState.error != null && (
        <ErrorMessage message={fieldState.error.message} />
      )}
    </Wrapper>
  );
}
