import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {LocationType} from '../../../../../../constants/location.constants';
import {APPLICATION_GENERAL_QUERY} from '../../../../../../gql';

import {
  ApplicationGeneral,
  ApplicationGeneralVariables,
} from '../../../../../../gql/application/queries/__generated__/ApplicationGeneral';
import {getShortNames_dto} from '../../../../../../utils/String.utils';
import {Props as FieldsProps} from '../../../../../elements/sidebarInfo/generalInfo/component';

export function useGeneralInfo() {
  const params = useParams<{applicationId?: string}>();

  const {data, loading} = useQuery<
    ApplicationGeneral,
    ApplicationGeneralVariables
  >(APPLICATION_GENERAL_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null,
  });

  const normalize = (s: string) =>
    'https://' + s.replace(/https:\/\/|http:\/\//gi, '');

  return {
    data:
      data?.issue == null
        ? null
        : {
            number: data.issue.id,
            parentId: data.issue?.parentIssue?.id,
            isUrgently: Boolean(data.issue.is_emergency),
            forestInTouch: Boolean(data.issue.is_forest_online),
            location: (data.issue.searchLocation?.key ?? undefined) as
              | LocationType
              | undefined,
            smsStatus: Boolean(data.issue.is_sms_submitted),
            // smsStatus: false,
            emailStatus: Boolean(data.issue.is_email_submitted),
            // emailStatus: false,
            isReverseLookup: Boolean(data.issue.is_reverse_lookup),
            isEducational: Boolean(data.issue.is_training),
            newCallCount: undefined,
            // newCallCount: 2
            generalFields: [
              {
                type: 'default',
                label: 'Пропавшие',
                value: getShortNames_dto(data.issue.missing),
              },
              {
                type: 'datetime',
                label: 'Заявка создана',
                value:
                  data.issue.created_at == null
                    ? null
                    : new Date(data.issue.created_at),
              },
              {
                type: 'datetime',
                label: 'Время пропажи',
                value:
                  data.issue.date_of_loss == null
                    ? null
                    : new Date(data.issue.date_of_loss),
              },
              {
                type: 'default',
                label: 'Источник',
                value: data.issue.source?.name,
              },
            ].filter(
              (f) => f.value != null && f.value !== ''
            ) as FieldsProps['list'],
            accepted:
              Boolean(1) === true
                ? undefined
                : {
                    id: 1,
                    avatar: undefined,
                    fullName: '',
                  },
            regionFields: [
              {
                title: 'Регионы',
                value: data.issue.region?.name || '',
              },
              {
                title: 'Районы',
                value:
                  data.issue.districts == null
                    ? ''
                    : data.issue.districts
                        .map((d) => d?.name)
                        .filter(Boolean)
                        .join(', '),
              },
              {
                title: 'Город и адрес',
                value: data.issue.last_seen_location || '',
              },
            ].filter((f) => f.value != null && f.value !== ''),
            coords: undefined,
            socials: [
              {
                title: 'Тема на форуме',
                links: data.issue.forum_theme?.split(' ') || [],
              },
              {
                title: 'Темы в соцсетях',
                links: data.issue.social_network_theme?.split(' ') || [],
              },
              {
                title: 'Публикации в СМИ',
                links: data.issue.media_publications?.split(' ') || [],
              },
            ].filter((f) => f.links.length !== 0),
          },
    loading,
  };
}
