import styled from 'styled-components';
import {line_clamp} from '../../../styles/functions';

export const Wrapper = styled.div`
  flex: 2;

  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const NameWrapper = styled.div`
  margin-left: 16px;
`;

export const Name = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 22px;

  color: var(--additional-color);

  max-width: 200px;
  ${line_clamp(2)}
`;

interface ILabel {
  hasLabelPostfix?: boolean;
}

export const Label = styled.p<ILabel>`
  margin: 0;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);

  &::after {
    content: ':';
    display: ${(p) => (p.hasLabelPostfix ? 'inline' : 'none')};
  }
`;
