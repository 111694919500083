import {RefObject, useCallback, useEffect, useRef, useState} from 'react';

export function useOutside<T extends HTMLElement>(
  onClose: () => void
): {contentRef: RefObject<T>} {
  const contentRef = useRef<T>(null);

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (
        contentRef.current != null &&
        !contentRef.current!.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onClose]);

  return {contentRef};
}

export function useDropdownRefs<T extends HTMLElement, D extends HTMLElement>(
  initOpen?: boolean
): {
  elementRef: RefObject<T>;
  dropdownRef: RefObject<D>;
  isOpen: boolean;
  setIsOpen(p: boolean): void;
} {
  const elementRef = useRef<T>(null);
  const dropdownRef = useRef<D>(null);
  const [isOpen, setIsOpen] = useState<boolean>(initOpen || false);

  const handleClick = useCallback((event: MouseEvent) => {
    if (
      elementRef.current != null &&
      elementRef.current.contains(event.target as Node)
    ) {
      setIsOpen((prevState) => !prevState);
    } else if (
      dropdownRef.current != null &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return {isOpen, setIsOpen, elementRef, dropdownRef};
}
