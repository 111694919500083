import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: block;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  > *[data-element='text-field'],
  > *[data-element='select-field'] {
    max-width: 648px;
  }
`;

export const BigGridFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 312px);

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 312px);
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const GridFields = styled.div`
  display: grid;
  grid-template-columns: 312px auto;

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  *[data-element='text-array-fields__input-field'],
  *[data-element='text-field'],
  *[data-element='date-field'],
  *[data-element='select-field'] {
    max-width: 312px;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, auto);

    *[data-element='text-array-fields__input-field'],
    *[data-element='text-field'],
    *[data-element='date-field'],
    *[data-element='select-field'] {
      max-width: inherit;
    }
  }
`;

export const DatePickerWrapper = styled.div`
  margin-top: 2px;
`;
