import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {
  APPLICATION_MISSING_LIST_QUERY,
  APPLICATION_STATUSES_QUERY,
} from '../../../../../../gql';
import {
  ApplicationMissingList,
  ApplicationMissingListVariables,
} from '../../../../../../gql/application/queries/__generated__/ApplicationMissingList';
import {Statuses} from '../../../../../../gql/application/queries/__generated__/Statuses';

import {getAgeFromDate} from '../../../../../../utils/Date.utils';
import {getFullName_dto} from '../../../../../../utils/String.utils';
import {Props as MissingCardProps} from '../MissingCard/MissingCard';

export const useMissing = () => {
  const params = useParams<{applicationId?: string}>();

  const {data, loading, refetch} = useQuery<
    ApplicationMissingList,
    ApplicationMissingListVariables
  >(APPLICATION_MISSING_LIST_QUERY, {
    variables: {
      id: params.applicationId as string,
    },
    skip: params.applicationId == null || params.applicationId === '',
  });

  return {
    list:
      data?.issue.bearers == null
        ? []
        : data?.issue.bearers.map((a) => ({
            id: a?.id || '',
            name: a?.full_name || '',
            label: a?.kinship,
            is112: a?.is_112,
            phone: a?.contacts || '',
            additionalContact: a?.additional_contacts || undefined,
            MES: a?.emergency_services || undefined,
            police: a?.police_statement || undefined,
            missing:
              a?.missing == null
                ? []
                : a?.missing.map((m) => ({
                    id: m?.id || '',
                    gender: m?.sex === 'male' ? 'm' : ('w' as 'm' | 'w'),
                    fullName: getFullName_dto({
                      first_name: m?.first_name,
                      last_name: m?.last_name,
                      second_name: m?.second_name,
                    }),
                    age:
                      m?.birthday_string == null
                        ? 0
                        : getAgeFromDate(new Date(m.birthday_string)),
                    // labels: {
                    //   fire: true,
                    //   water: false,
                    //   phoneCharging: 12,
                    //   wifiNetwork: 4,
                    //   generalState: 8,
                    // },
                    labels: undefined,
                    statuses: {
                      status: m?.status?.id,
                      subStatus: m?.subStatus?.id,
                    },
                    fields: [
                      {
                        id: 'phone',
                        name: 'Телефоны',
                        value: m?.phone_number,
                        labels: [
                          {
                            id: m?.havingPhoneStatus?.key,
                            value: m?.havingPhoneStatus?.name,
                          },
                          {
                            id: m?.availabilityPhoneStatus?.key,
                            value: m?.availabilityPhoneStatus?.name,
                          },
                        ].filter((l) => l.value != null),
                      },
                      {
                        id: 'disease',
                        name: 'Заболевания',
                        value:
                          m?.diseases == null
                            ? null
                            : m?.diseases.map((item) => item?.name).join(', '),
                      },
                      {
                        //   id: "coordinates",
                        //   name: "Координаты обнаружения",
                        //   value: "45.03083 с.ш. 37.52243 в.д.",
                        //   preIcon: <MapMarkerIcon />
                        // }, {
                        //   id: "health",
                        //   name: "Здоровье",
                        //   value: "Хронических заболеваний нет"
                        // }, {

                        id: 'appearance',
                        name: 'Приметы, внешность',
                        value: m?.appearance,
                      },
                      {
                        id: 'clothes',
                        name: 'Одежда',
                        value: m?.clothes,
                      },
                      {
                        id: 'withSelf',
                        name: 'С собой',
                        value: m?.things,
                      },
                      {
                        id: 'additionally',
                        name: 'Дополнительно',
                        value: m?.additional_information,
                        // }, {
                        //   id: "informationForPilot",
                        //   name: "Информация для пилота",
                        //   value: "Оставьте ваш отзыв, пожелания или общие впечатления об ипользовании нашей систымы. Это поможет нам развивать и дополнять продукт"
                      },
                    ].filter(
                      (f) => f.value != null
                    ) as MissingCardProps['fields'],
                    filesCount: m?.files?.length,
                    photosCount: m?.images?.length,
                    videosCount: m?.videos?.length,
                    // videosCount: 12
                  })),
          })),
    loading,
    refetch,
  };
};

export const useStatuses = () => {
  const {data, loading} = useQuery<Statuses>(APPLICATION_STATUSES_QUERY);

  return {
    statuses:
      data?.allGroupSearchStatuses == null
        ? []
        : data?.allGroupSearchStatuses.map((g) => ({
            id: g?.id || '',
            name: g?.name || '',
            statuses:
              g?.statuses?.map((s) => ({
                id: s?.id || '',
                name: s?.name || '',
                color: s?.color || 'transparent',
                subStatuses:
                  s?.subStatuses?.map((ss) => ({
                    id: ss?.id || '',
                    name: ss?.name || '',
                    color: s?.color || 'transparent',
                  })) || [],
              })) || [],
          })),
    loading,
  };
};
