import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px;
  
  flex: auto;
  
  display: flex;

   > :not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    > :not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const Primary = styled.div`
  flex: auto;
`;

export const Secondary = styled.div`
  width: 310px;
  
  & > * {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 950px) {
    width: 310px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 24px;
`;
