import React from 'react';
import {SvgStyled} from './Icons.styles';

export const FireIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.62429 12.8531C4.47479 12.5517 4.36466 12.2309 4.29988 11.9027C4.1846 11.3184 4.20844 10.7288 4.37076 10.1502L4.75366 8.78519L5.19732 7.20369L6.21344 8.26472L7.20585 6.68275L8.00004 5.41678L8.79419 6.68272L9.7866 8.26469L10.8027 7.20366L11.2464 8.78516L11.6293 10.1501C11.7916 10.7287 11.8154 11.3183 11.7002 11.9027C11.6354 12.2309 11.5253 12.5517 11.3758 12.8531C13.0392 11.5408 13.8963 9.28841 13.267 7.04506L12.5273 4.40834L11.1519 5.84459L8.00004 0.820312L4.84816 5.84459L3.47269 4.40834L2.73301 7.04506C2.10369 9.28844 2.96085 11.5408 4.62429 12.8531Z"
        fill="#376092"
      />
      <path
        d="M10.7267 10.4031L10.3438 9.03813L9.63176 9.78163L8.00007 7.18066L6.36842 9.78163L5.65639 9.03813L5.27348 10.4031C4.77423 12.1828 6.08186 13.9738 7.92995 14.0069C7.97673 14.0077 8.02348 14.0077 8.07026 14.0069C9.91832 13.9738 11.2259 12.1828 10.7267 10.4031Z"
        fill="#376092"
      />
      <path
        d="M2.56131 13.6172C1.91687 13.8648 1.59509 14.5879 1.84265 15.2323L1.89843 15.3776C2.14597 16.022 2.86906 16.3438 3.51353 16.0962L6.79109 14.8373C5.59409 14.6149 4.49481 14.0544 3.60884 13.2148L2.56131 13.6172Z"
        fill="#376092"
      />
      <path
        d="M13.4387 13.6172L12.3912 13.2148C11.5052 14.0544 10.406 14.6149 9.20897 14.8373L12.4865 16.0962C13.131 16.3438 13.8541 16.022 14.1016 15.3776L14.1574 15.2323C14.405 14.5878 14.0832 13.8648 13.4387 13.6172Z"
        fill="#376092"
      />
      <path
        d="M14.75 11.4756H13.6956C13.5334 11.791 13.3456 12.0934 13.1341 12.3776C13.0937 12.432 13.0523 12.4854 13.0105 12.5381L13.775 12.742C14.3204 12.9515 14.7516 13.3609 14.9892 13.8947C15.0179 13.9593 15.0432 14.0248 15.0655 14.091C15.6031 13.9512 16 13.4626 16 12.8812V12.7256C16 12.0352 15.4404 11.4756 14.75 11.4756Z"
        fill="#376092"
      />
      <path
        d="M3.02281 12.5791C2.96953 12.513 2.91697 12.4462 2.866 12.3776C2.6545 12.0934 2.46669 11.791 2.30444 11.4756H1.25C0.559656 11.4756 0 12.0352 0 12.7256V12.8812C0 13.4626 0.396937 13.9512 0.934594 14.091C0.956813 14.0248 0.982156 13.9593 1.01087 13.8947C1.24841 13.3609 1.67963 12.9515 2.22509 12.742L3.02281 12.5791Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
