import {useQuery} from '@apollo/client';

import {APPLICATION_QUERY} from '../../../../../gql';
import {
  Application,
  ApplicationVariables,
} from '../../../../../gql/application/queries/__generated__/Application';
import {Id} from '../../../../../types/auxiliary.types';

export const useInit = (applicationId: Id) => {
  const {data, loading, refetch} = useQuery<Application, ApplicationVariables>(
    APPLICATION_QUERY,
    {
      variables: {
        id: applicationId as string,
      },
    }
  );

  return {data, loading, refetch};
};
