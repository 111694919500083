import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {TextareaStyled} from './Textarea.styles';

type InputProps = {
  name?: string;
  register?: UseFormRegisterReturn;
  placeholder?: string;
  isInvalid?: boolean;
  rows?: number;
  disabled?: boolean;
};

export function Textarea({register, ...rest}: InputProps) {
  return <TextareaStyled {...register} {...rest} />;
}
