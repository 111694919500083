import React from 'react';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import Main from './components/Main/Main';
import {Wrapper, Title, ContentWrapper} from './HelpfulInfo.styles';

export default function HelpfulInfo() {
  useDocumentTitle('Полезная информация | ЦППЛ');
  return (
    <Wrapper>
      <Title>Полезная информация</Title>
      <ContentWrapper>
        <Main />
      </ContentWrapper>
    </Wrapper>
  );
}
