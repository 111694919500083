import React from 'react';
import {SvgStyled} from './Icons.styles';

export const DoubleIcon = () => {
  return (
    <SvgStyled
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.0820312H0V6.08203H2.04414V2.09398H6V0.0820312Z"
        fill="white"
      />
      <rect x="3" y="3.08203" width="5" height="5" fill="white" />
    </SvgStyled>
  );
};
