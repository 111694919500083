import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import Preloader from '../../../elements/Preloader/Preloader';

import {Title, Wrapper} from './styles';
import {Form} from '../components/form/component';
import {useTaskQuery, useAction} from './hook';

function EditTaskScreen() {
  useDocumentTitle('Редактировать задачу');

  const {fetch, loading: fetchLoading, id, data} = useTaskQuery();

  const {handleEditTask} = useAction(fetch);

  if (fetchLoading) return <Preloader />;

  return (
    <Wrapper>
      <Title>Редактировать задачу #{id}</Title>
      <Form
        defaultValues={data}
        submitLabel={'Редактировать задачу'}
        onSubmit={handleEditTask}
        fetching={fetchLoading}
      />
    </Wrapper>
  );
}

export default EditTaskScreen;
