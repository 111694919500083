import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useEffect, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {
  APPLICATION_DETAILS_QUERY,
  APPLICATION_EDIT_MUTATION,
  APPLICATION_DELETE_APPLICANT_MUTATION,
  APPLICATION_DELETE_MISSING_MUTATION,
} from '../../../../gql';
import {
  Edit,
  EditVariables,
} from '../../../../gql/application/mutations/__generated__/Edit';
import {
  Details,
  DetailsVariables,
} from '../../../../gql/application/queries/__generated__/Details';
import {
  DeleteMissing,
  DeleteMissingVariables,
} from '../../../../gql/application/mutations/__generated__/DeleteMissing';
import {
  DeleteApplicant,
  DeleteApplicantVariables,
} from '../../../../gql/application/mutations/__generated__/DeleteApplicant';
import {useServerErrors} from '../../../../hooks/useErrors.hooks';
import {Id} from '../../../../types/auxiliary.types';
import {createDateTime_DTO} from '../../../../utils/Date.utils';
import {Applicant, FormFields} from './Edit.types';
import {toast} from 'react-toastify';

export function useInit() {
  const [data, setData] = useState<{
    application: FormFields;
    applicants: Applicant[];
  } | null>(null);
  const params = useParams<{applicationId: string}>();

  const {loading, refetch} = useQuery<Details, DetailsVariables>(
    APPLICATION_DETAILS_QUERY,
    {
      skip: true,
    }
  );

  const fetch = useCallback(() => {
    refetch({
      id: params.applicationId as string,
    }).then(({data}) => {
      const date =
        data?.issue.date_of_loss == null
          ? null
          : new Date(data?.issue.date_of_loss);

      setData({
        application: {
          region: data.issue.region?.id || '',
          additionalRegions: [],
          area: data.issue.districts?.map((d) => d?.id || '') || [],
          location: data.issue.searchLocation?.key || '',
          source: data.issue.source?.id || '',
          date: date,
          lastSeen: data.issue?.last_seen_location || '',
          circumstances: data.issue.reason_of_loss || '',
          educational: Boolean(data.issue.is_training),
          forestInTouch: Boolean(data.issue.is_forest_online),
          urgently: Boolean(data.issue.is_emergency),
          reverseLookup: Boolean(data.issue.is_reverse_lookup),

          coordinates: data.issue.office_coordinates || '',
          forumTopic: data.issue.forum_theme || '',
          socialMediaTopic: data.issue.social_network_theme || '',
          mediaPublications: data.issue.media_publications || '',
          isMailSend: data.issue.is_email_submitted || false,
          isSMSSent: data.issue.is_sms_submitted || false,
        },
        applicants:
          data?.issue?.bearers == null
            ? []
            : data.issue.bearers.map((b) => ({
                id: b?.id || '',
                fullName: b?.full_name || '',
                relationDegree: b?.kinship || '',
                phone: b?.contacts || '',
                additionalContact: b?.additional_contacts || '',
                police: b?.police_statement || '',
                MES: b?.emergency_services || '',
                missing:
                  b?.missing?.map((m) => ({
                    id: m?.id || '',
                    gender: m?.sex === 'male' ? 'm' : ('w' as 'm' | 'w'),
                    firstName: m?.first_name || '',
                    lastName: m?.last_name || '',
                    middleName: m?.second_name || '',
                    birthdayString:
                      m?.birthday_string == null ? '' : m.birthday_string,
                    hasPhone: m?.havingPhoneStatus?.key || '',
                    availablePhone: m?.availabilityPhoneStatus?.key || '',
                    phone: m?.phone_number || '',
                    additionalPhones:
                      m?.additionalPhones?.map((a) => a?.number || '') || [],
                    health: m?.health || '',
                    diseases: m?.diseases?.map((d) => d?.id || '') || [],
                    appearance: m?.appearance || '',
                    cloth: m?.clothes || '',
                    withSelf: m?.things || '',
                    additionalInformation: m?.additional_information || '',
                  })) || [],
              })),
      });
    });
  }, [params.applicationId, refetch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    data: data?.application || null,
    applicants: data?.applicants || [],
    loading,
    fetch,
  };
}

export function useHandler(
  setServerError: UseFormSetError<FormFields>,
  onSuccess?: () => void
) {
  const params = useParams<{applicationId?: string}>();
  const [editApplication, {loading: editLoading}] = useMutation<
    Edit,
    EditVariables
  >(APPLICATION_EDIT_MUTATION);
  const [deleteApplicant, {loading: deleteApplicantLoading}] = useMutation<
    DeleteApplicant,
    DeleteApplicantVariables
  >(APPLICATION_DELETE_APPLICANT_MUTATION);
  const [deleteMissing, {loading: deleteMissingLoading}] = useMutation<
    DeleteMissing,
    DeleteMissingVariables
  >(APPLICATION_DELETE_MISSING_MUTATION);

  const {addServerError} = useServerErrors(
    {
      'input.regionId': 'region',
      // 'input.': 'additionalRegions',
      'input.districts': 'area',
      'input.searchLocation': 'location',
      'input.sourceId': 'source',
      'input.dateOfLoss': 'date',
      'input.reasonOfLoss': 'circumstances',
      'input.lastSeenLocation': 'lastSeen',
      'input.isTraining': 'educational',
      'input.isForestOnline': 'forestInTouch',
      'input.isEmergency': 'urgently',
      'input.isReverseLookup': 'reverseLookup',
    },
    setServerError
  );

  const handleSubmitBaseInfo = useCallback(
    async (data: FormFields) => {
      await editApplication({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            lastSeenLocation: data.lastSeen,
            dateOfLoss:
              data.date == null ? undefined : createDateTime_DTO(data.date),
            reasonOfLoss: data.circumstances,
            isEmergency: data.urgently,
            isTraining: data.educational,
            isReverseLookup: data.reverseLookup,
            isForestOnline: data.forestInTouch,
            isSmsSubmitted: data.isSMSSent,
            isEmailSubmitted: data.isMailSend,
            sourceId: Number(data.source),
            regionId: Number(data.region),
            searchLocation: data.location.toString(),
            districts: data.area.map((a) => Number(a)),
            officeCoordinates: data.coordinates,
            forumTheme: data.forumTopic,
            socialNetworkTheme: data.socialMediaTopic,
            mediaPublications: data.mediaPublications,
          },
        },
      })
        .then(() => {
          onSuccess?.();
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
        });
    },
    [editApplication, onSuccess, params.applicationId]
  );

  function handleApplicantDelete(id: Id) {
    return deleteApplicant({
      variables: {
        input: Number(id),
      },
    });
  }

  function handleMissingDelete(id: Id) {
    return deleteMissing({
      variables: {
        input: Number(id),
      },
    });
  }

  return {
    actionLoading:
      deleteApplicantLoading || deleteMissingLoading || editLoading,
    handleSubmitBaseInfo,
    handleApplicantDelete,
    handleMissingDelete,
  };
}
