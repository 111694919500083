import React from 'react';
import {Outlet, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ArrowIcon} from '../../icons/ArrowIcon';
import {Header} from '../Header/Header';
import {
  Container,
  ContentContainer,
  BackButtonWrapper,
  Button,
  OutletWrapper,
} from './DetailLayout.styles';

export const DetailLayout = () => {
  const navigate = useNavigate();

  const {pathname} = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams()

  let config = {
    title: '',
    navigateTo: '/',
  };

  if (pathname === '/teams') {
    config = {
      title: 'Регионы',
      navigateTo: '/regions',
    };
  } else if (pathname === '/applications/new') {
    config = {
      title: 'Заявки',
      navigateTo: '/applications',
    };
  } else if (pathname.match(/applications\/edit/)) {
    config = {
      title: `Заявкa #${params.applicationId || ""}`,
      navigateTo: `/applications/${params.applicationId || ""}`,
    };
  } else if (pathname.match(/calls\/edit/)) {
    config = {
      title: `Звонок #${params.callId || ""}`,
      navigateTo: `/calls/${params.callId || ""}`,
    };
  } else if (pathname.includes('/calls/')) {
    if (searchParams.has("applicationId")) {
      config = {
        title: `Заявка #${searchParams.get("applicationId")}`,
        navigateTo: `/applications/${searchParams.get("applicationId")}?tab=calls`,
      };
    } else {
      config = {
        title: `Звонки`,
        navigateTo: "/calls",
      };
    }
  } else if (pathname.match(/tasks\/edit/)) {
    config = {
      title: `Задача #${params.taskId || ""}`,
      navigateTo: `/tasks/${params.taskId || ""}`,
    };
  } else if (pathname.includes('/tasks/')) {
    if (searchParams.has("applicationId")) {
      config = {
        title: `Заявка #${searchParams.get("applicationId")}`,
        navigateTo: `/applications/${searchParams.get("applicationId")}?tab=tasks`,
      };
    } if (searchParams.has("callId")) {
      config = {
        title: `Звонок #${searchParams.get("callId")}`,
        navigateTo: `/calls/${searchParams.get("callId")}?tab=tasks`,
      };
    } else {
      config = {
        title: `Задачи`,
        navigateTo: "/tasks",
      };
    }
  } else if (pathname.includes("/callRequests/") && params.institutionId != null) {
    config = {
      title: `Запрос на прозвон`,
      navigateTo: `/callRequests/${params.callRequestId || ""}`,
    };
  } else if (pathname.includes('/callRequests')) {
    config = {
      title: `Запросы на прозвон`,
      navigateTo: "/callRequests",
    };
  } else {
    config = {
      title: 'Ошибка',
      navigateTo: '/404',
    };
  }

  return (
    <Container>
      <Header />
      <ContentContainer>
        <BackButtonWrapper>
          <Button
            title={config.title}
            onClick={() => navigate(config.navigateTo)}>
            <ArrowIcon />
          </Button>
        </BackButtonWrapper>
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </ContentContainer>
    </Container>
  );
};
