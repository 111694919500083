import React from 'react';
import {LocationType} from '../../../../../../../constants/location.constants';
import {Badge} from '../../../../../../elements/Badge/Badge';
import Fields, {
  Props as FieldsProps,
} from '../../../../../../elements/sidebarInfo/generalInfo/component';
import Header from '../../../../../../elements/sidebarInfo/header/component';

import {LabelsWrapper, Wrapper} from './MainField.styles';

interface Props {
  number: number | string;
  isUrgently: boolean;
  forestInTouch: boolean;
  location?: LocationType;
  smsStatus?: boolean;
  emailStatus?: boolean;
  isReverseLookup: boolean;
  isEducational: boolean;
  newCallCount?: number;
  generalFields: FieldsProps['list'];
  parentId: number | undefined;
}

export function MainField(props: Props) {
  return (
    <Wrapper>
      <Header
        number={props.number}
        isUrgently={props.isUrgently}
        isForestInTouch={props.forestInTouch}
        location={props.location}
        isSmsStatus={props.smsStatus}
        isEmailSend={props.emailStatus}
      />

      {(!props.isReverseLookup ||
        !props.isEducational ||
        !props.parentId ||
        (props.newCallCount != null && props.newCallCount !== 0)) && (
        <LabelsWrapper>
          {props.isReverseLookup && (
            <Badge title="Обратный поиск" color="#A1B2C8" />
          )}
          {props.isEducational && <Badge title="Учебная" color="#DB00FF" />}
          {props.parentId && <Badge title="Дубль" color="#EE8C8C" />}
          {props.newCallCount != null && props.newCallCount !== 0 && (
            <Badge
              title="Есть новые звонки"
              color="var(--additional-color)"
              counter={props.newCallCount}
            />
          )}
        </LabelsWrapper>
      )}
      <Fields list={props.generalFields} />
    </Wrapper>
  );
}
