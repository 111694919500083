import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {TEAM_WIDGET_QUERY} from '../gql';
import {TeamWidget, TeamWidgetVariables} from '../gql/queries/__generated__/TeamWidget';

export const useWidgetHooks = () => {
  const params = useParams<{teamId?: string}>();

  const {data} = useQuery<TeamWidget, TeamWidgetVariables>(
    TEAM_WIDGET_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
    }
  );
  return {data};
};
