import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Pagination} from '../../../elements/Pagination/Pagination';
import Preloader from '../../../elements/Preloader/Preloader';
import Card from './components/card/component';
import {Filter} from './components/filter/component';
import useCallRequestList, {usePagination} from './hooks';
import {List, PaginationWrapper, Wrapper, Title} from './styles';

export default function CallRequestsListScreen() {
  useDocumentTitle('Запросы на прозвоны');
  const {page, handleSwitchPage} = usePagination();
  const {data, pagination, loading} = useCallRequestList();

  return (
    <Wrapper>
      <Title>Запросы на прозвоны</Title>
      {loading ? (
        <Preloader />
      ) : (
        <List>
          <Filter callRequestsCount={pagination?.total || 0} />
          {data.map((callRequest) => {
            return (
              <Card
                key={callRequest.id}
                id={callRequest.id}
                number={Number(callRequest.applicationId)}
                urgent={callRequest.urgent as 1 | 2 | 3 | 4 | undefined}
                region={callRequest.region}
                author={callRequest.author}
                missing={callRequest.missing}
                area={callRequest.region}
              />
            );
          })}
        </List>
      )}
       <PaginationWrapper>
        <Pagination
          page={page}
          total={pagination.lastPage}
          onChange={handleSwitchPage}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
