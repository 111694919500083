import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Select, {Props as SelectProps} from '../Select/Select';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import {Label} from '../Label/Label';
import {Wrapper} from './SelectField.styles';

interface Props<T extends FieldValues>
  extends Pick<
    SelectProps<T>,
    | 'id'
    | 'name'
    | 'enumeration'
    | 'options'
    | 'multiple'
    | 'disabled'
    | 'placeholder'
    | 'option'
    | 'shouldNotBeEmpty'
    | 'onCallbackChange'
    | 'hasFilter'
  > {
  label?: string | null;
  control: Control<T>;
  rules?: UseControllerProps['rules'];
}

export default function SelectField<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const {field, fieldState} = useController({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Wrapper data-element="select-field">
      {props.label != null && props.label !== '' && (
        <Label label={props.label} isInvalid={fieldState.invalid} />
      )}
      <Select
        id={props.id}
        name={field.name}
        field={field as any}
        options={props.options}
        multiple={props.multiple}
        placeholder={props.placeholder}
        enumeration={props.enumeration}
        disabled={props.disabled}
        shouldNotBeEmpty={props.shouldNotBeEmpty}
        hasFilter={props.hasFilter}
        onCallbackChange={props.onCallbackChange}
      />
      {fieldState.error != null && (
        <ErrorMessage message={fieldState.error.message} />
      )}
    </Wrapper>
  );
}
