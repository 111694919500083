import styled from 'styled-components';

export const LogoImg = styled.img<{variant: string}>`
  display: block;
  width: 100%;
  ${(props) =>
    props.variant === 'big' &&
    `
    max-width: 312px;
    max-height: 132px;
  `}
  ${(props) =>
    props.variant === 'medium' &&
    `
    max-width: 128px;
    max-height: 54px;
  `}
  ${(props) =>
    props.variant === 'small' &&
    `
    width: 40px;
    height: 40px;
  `}
`;
