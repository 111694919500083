import React from 'react';
import {SvgStyled} from './Icons.styles';

export const EmotionStateIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41661 7.16666C5.58717 7.16666 5.75776 7.10219 5.88795 6.972C6.14773 6.71222 6.14773 6.28907 5.88795 6.02929C5.62755 5.76888 5.20567 5.76888 4.94523 6.02929C4.68545 6.28907 4.68545 6.71222 4.94523 6.972C5.07548 7.10219 5.24605 7.16666 5.41661 7.16666Z"
        fill="#376092"
      />
      <path
        d="M11.2787 4.6953C11.0189 4.95508 11.0189 5.37824 11.2787 5.63802C11.4089 5.76824 11.5795 5.83267 11.75 5.83267C11.9206 5.83267 12.0912 5.7682 12.2214 5.63802C12.4812 5.37824 12.4812 4.95508 12.2214 4.6953C11.961 4.4349 11.5391 4.4349 11.2787 4.6953Z"
        fill="#376092"
      />
      <path
        d="M8.75 0.5C4.33853 0.5 0.75 4.08853 0.75 8.5C0.75 12.9115 4.33853 16.5 8.75 16.5C13.1615 16.5 16.75 12.9115 16.75 8.5C16.75 4.08853 13.1615 0.5 8.75 0.5ZM6.08334 3.83334C7.18622 3.83334 8.08334 4.73047 8.08334 5.83334C8.08334 6.93622 7.18622 7.83334 6.08334 7.83334C4.98047 7.83334 4.08334 6.93622 4.08334 5.83334C4.08334 4.73047 4.98047 3.83334 6.08334 3.83334ZM13.4076 10.9102C13.1988 11.7902 12.7172 12.5574 12.0761 13.1621C11.146 12.4452 9.85362 12.2862 8.75978 12.8418C7.66225 12.2831 6.36244 12.4473 5.43297 13.1721C4.78653 12.5659 4.30134 11.7952 4.09247 10.9102C4.06903 10.8106 4.09181 10.7064 4.15563 10.6263C4.21875 10.5469 4.31509 10.5 4.41666 10.5H13.0833C13.1855 10.5 13.2819 10.5469 13.345 10.627C13.4082 10.7064 13.431 10.8112 13.4076 10.9102ZM11.4167 7.83334C10.3138 7.83334 9.41666 6.93622 9.41666 5.83334C9.41666 4.73047 10.3138 3.83334 11.4167 3.83334C12.5195 3.83334 13.4167 4.73047 13.4167 5.83334C13.4167 6.93619 12.5195 7.83334 11.4167 7.83334Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
