import React from 'react';
import {PencilIcon} from '../../../../../icons/PencilIcon';
import {TrashIcon} from '../../../../../icons/TrashIcon';

import {IconWrapper} from './styles';

interface Props {
  delete: {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
  };
  edit: {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
  };
}

export function ChangeButtons(props: Props) {
  return (
    <>
      <IconWrapper
        type="button"
        color="pink"
        title="Редактировать"
        disabled={props.edit.disabled}
        onClick={(e) => props.edit.onClick?.(e)}>
        <PencilIcon />
      </IconWrapper>
      <IconWrapper
        type="button"
        color="orange"
        title="Удалить"
        disabled={props.delete.disabled}
        onClick={(e) => props.delete.onClick?.(e)}>
        <TrashIcon />
      </IconWrapper>
    </>
  );
}
