import styled from 'styled-components';
import {Button} from '../../../../../elements/Button/Button';

export const MyWrapper = styled.div`
  display: flex;
  justify-items: start;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10000;
  height: 300px;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ActionsSection = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 10px;
`;
