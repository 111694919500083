import styled from 'styled-components';
import {Button} from '../../elements/Button/Button';
import {AuthFrom} from '../../forms/AuthFrom/AuthFrom';

export const AuthFormStyled = styled(AuthFrom)`
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
`;
