import React from 'react';
import {SvgStyled} from './Icons.styles';

export const DoubleUrgentlyIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        data-at="urgently"
        d="M5 8.5H7L7.5 4.5C7.5 4.22388 7.27612 4 7 4H5C4.72388 4 4.5 4.22388 4.5 4.5L5 8.5Z"
        fill="white"
      />
      <path
        data-at="urgently"
        d="M9 8.5H11L11.5 4.5C11.5 4.22388 11.2761 4 11 4H9C8.72388 4 8.5 4.22388 8.5 4.5L9 8.5Z"
        fill="white"
      />
      <path
        data-at="urgently"
        d="M5 11C5 11.5525 5.44769 12 6 12C6.55231 12 7 11.5525 7 11C7 10.4478 6.55231 10 6 10C5.44769 10 5 10.4478 5 11Z"
        fill="white"
      />
      <path
        data-at="urgently"
        d="M9 11C9 11.5525 9.44769 12 10 12C10.5523 12 11 11.5525 11 11C11 10.4478 10.5523 10 10 10C9.44769 10 9 10.4478 9 11Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58166 0 0 3.58178 0 8C0 12.4185 3.58166 16 8 16C12.4183 16 16 12.4185 16 8C16 3.58178 12.4183 0 8 0ZM5 8.5H7L7.5 4.5C7.5 4.22388 7.27612 4 7 4H5C4.72388 4 4.5 4.22388 4.5 4.5L5 8.5ZM9 8.5H11L11.5 4.5C11.5 4.22388 11.2761 4 11 4H9C8.72388 4 8.5 4.22388 8.5 4.5L9 8.5ZM5 11C5 11.5525 5.44769 12 6 12C6.55231 12 7 11.5525 7 11C7 10.4478 6.55231 10 6 10C5.44769 10 5 10.4478 5 11ZM9 11C9 11.5525 9.44769 12 10 12C10.5523 12 11 11.5525 11 11C11 10.4478 10.5523 10 10 10C9.44769 10 9 10.4478 9 11Z"
        fill="#EE8C8C"
      />
    </SvgStyled>
  );
};
