import React from 'react';

import Template from '../template/component';
import Card from './components/card/component';
import {useTasks} from './hooks';

export default function Tasks() {
  const {data, modalMeta, loading} = useTasks();

  return (
    <Template
      loading={loading}
      modalMeta={modalMeta}
      list={data}
      renderCard={Card}
    />
  )
}
