import React, {useState} from 'react';
import {Avatar} from '../../../elements/Avatar/Avatar';
import {BlockSidebarWrapper} from '../../../elements/BlockSidebarWrapper/BlockSidebarWrapper';
import {Button} from '../../../elements/Button/Button';
import Preloader from '../../../elements/Preloader/Preloader';
import {SocialNetworks} from '../../../elements/SocialNetworks/component';
import {Label as LabelComponent} from '../../../elements/Label/Label';
import {PencilIcon} from '../../../icons/PencilIcon';
import {FeedbackModal} from './feedbackModal/component';

import {useProfile} from './hooks';
import {
  Wrapper,
  Action,
  IconWrapper,
  ProfileWrapper,
  Name,
  SmallText,
  Region,
  Link,
  PhoneLink,
  SocialNetworksWrapper,
  Label,
  Groups,
  GroupLabels,
  Qualification,
} from './styles';
import {useNavigate} from 'react-router-dom';

export default function Sidebar() {
  const [showModal, setShowModal] = useState(false);

  const {data, loading} = useProfile();

  const navigate = useNavigate();

  return (
    <BlockSidebarWrapper title="Профиль" shouldHideTitleBigSize>
      {loading ? (
        <Preloader />
      ) : (
        <Wrapper>
          {showModal && <FeedbackModal onClose={() => setShowModal(false)} />}
          <Action type="button" onClick={() => navigate('/edit/profile')}>
            <IconWrapper data-icon>
              <PencilIcon />
            </IconWrapper>
            Редактирование профиля
          </Action>
          <div>
            <Button
              title="Обратная связь"
              type="button"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => setShowModal(true)}
            />
          </div>
          <ProfileWrapper>
            <Avatar size="big" src={data.avatar} fullName={data.fullName} />
            <Name>{data.fullName}</Name>
            <SmallText
              showSeparator={data.nickname != null && data.nickname !== ''}>
              {data.nickname}
              {data.birthday != null && (
                <time dateTime={data.birthday.toLocaleDateString()}>
                  {data.birthday.toLocaleDateString('ru', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  })}
                </time>
              )}
            </SmallText>
            {data.driverLicense != null && (
              <SmallText>
                Водительское удостоверение: <b>{data.driverLicense}</b>
              </SmallText>
            )}
            {data.region != null && <Region>{data.region.toString()}</Region>}
            {data.region != null && <SmallText>{data.address}</SmallText>}

            {data.phones.map((phone) => (
              <PhoneLink data-element="phone" key={phone} href={`tel:${phone}`}>
                {phone}
              </PhoneLink>
            ))}
            {data.socialNetworkInfo.telegram != null && (
              <Link
                href={`tg://resolve?domain=${data.socialNetworkInfo.telegram}`}>
                {data.socialNetworkInfo.telegram}
              </Link>
            )}
            {data.emails.map((e) => (
              <Link key={e} href={`mailto:${e}`}>
                {e}
              </Link>
            ))}
            {(data.socialNetworkInfo.vk != null ||
              data.socialNetworkInfo.twitter != null ||
              data.socialNetworkInfo.facebook != null) && (
              <SocialNetworksWrapper>
                <SocialNetworks
                  vk={data.socialNetworkInfo.vk}
                  twitter={data.socialNetworkInfo.twitter}
                  facebook={data.socialNetworkInfo.facebook}
                />
              </SocialNetworksWrapper>
            )}
          </ProfileWrapper>
          <Groups>
            <Label>{data.team}</Label>
            <GroupLabels>
              {data.groups.map((g) => (
                <Label key={g} isGray>
                  {g}
                </Label>
              ))}
            </GroupLabels>
            {data.qualification != null && data.qualification !== '' && (
              <>
                <LabelComponent label="Квалификация и навыки" />
                <Qualification>{data.qualification}</Qualification>
              </>
            )}
          </Groups>
        </Wrapper>
      )}
    </BlockSidebarWrapper>
  );
}
