import { useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

import {createUrlSearch} from '../../../../../../utils/url.utils';

import {FormFields} from './types';

export function useAction() {
  const [, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {

      setSearchParams({
        page: "1",
        ...createUrlSearch(data)
      })
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams({page: "1"}),
    [setSearchParams]
  );

  return {submit: handleSubmit, handleReset}
}
