import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {useCallback} from 'react';
import {GROUP_IMAGE_QUERY} from '../../../../../gql';
import {
  PhotoVariables,
  Photo,
} from '../../../../../gql/queries/group/__generated__/Photo';
import {PhotoType} from './types';

export function usePhotos() {
  const params = useParams<{groupId?: string}>();

  const {data, loading, fetchMore} = useQuery<Photo, PhotoVariables>(
    GROUP_IMAGE_QUERY,
    {
      variables: {
        id: (params.groupId || '').toString(),
      },
      skip: params.groupId == null || params.groupId === '',
    }
  );
  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.groupId || '').toString(),
      },
    });
  }, [fetchMore, params.groupId]);
  return {
    groupName: data?.group.full_name,
    list:
      data?.group.images?.map((z) => ({
        name: z?.name || '',
        url: z?.url || '',
      })) ?? ([] as PhotoType[]),
    fetch,
    loading,
  };
}
