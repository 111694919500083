import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 12;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    margin-right: 12px;
  }
`;

export const Name = styled.p`
  position: relative;
  width: 110px;
  margin: 0 0 0 12px;

  font-weight: bold;
  font-size: 14px;
  line-height: 1.36;

  color: #000;

  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: '';
    position: absolute;
    right: 0;

    height: 100%;
    width: 80%;

    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const IconsWrapper = styled.div`
  margin-left: 12px;

  > *:not(:last-child) {
    margin-right: 26px;
  }
`;

interface IIconWrapper {
  showBadge?: boolean;
  light?: boolean;
}

export const IconWrapper = styled.button<IIconWrapper>`
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: 20px;

  path {
    fill: var(${(p) => (p.light ? '--secondary-text-color' : '--text-color')});
  }

  ${(p) =>
    p.showBadge &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -9px;

        display: block;
        width: 8px;
        height: 8px;

        border-radius: 50%;
        background-color: var(--primary-color);
      }
    `}
`;

export const ButtonWrapper = styled.div`
  margin-right: 1rem;
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
