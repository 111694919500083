import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {SearchIcon} from '../../icons/SearchIcon';
import {
  SearchFieldContainer,
  SearchIconStyled,
  SearchInputStyled,
} from './SearchField.styles';

type TextFieldProps = {
  register?: UseFormRegisterReturn;
  placeholder?: string;
  type?: 'password' | 'text';
};

export function SearchField(props: TextFieldProps) {
  const {...rest} = props;
  return (
    <SearchFieldContainer>
      <SearchInputStyled placeholder={'Поиск'} {...rest} />
      <SearchIconStyled>
        <SearchIcon />
      </SearchIconStyled>
    </SearchFieldContainer>
  );
}
