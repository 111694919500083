import React from 'react';
import {SvgStyled} from './Icons.styles';

export const MenIcon = () => {
  return (
    <SvgStyled
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6776 1.44866C18.6692 1.3837 18.6546 1.32112 18.6305 1.26241C18.6297 1.26094 18.6297 1.25891 18.6294 1.25707C18.6294 1.2567 18.629 1.25634 18.6286 1.25597C18.6021 1.19413 18.5658 1.138 18.5249 1.08591C18.5148 1.07358 18.5049 1.06143 18.4942 1.04984C18.4505 1.00125 18.403 0.956344 18.3479 0.919719C18.3464 0.918615 18.3445 0.918247 18.343 0.917142C18.2897 0.882542 18.2309 0.856408 18.1692 0.835795C18.1538 0.830457 18.1389 0.825856 18.1232 0.821807C18.0585 0.804507 17.992 0.791992 17.9219 0.791992H12.5625C12.1399 0.791992 11.7969 1.12971 11.7969 1.54584C11.7969 1.96196 12.1399 2.29968 12.5625 2.29968H16.0732L11.5036 6.79902C10.2851 5.83941 8.78484 5.31507 7.20312 5.31507C3.40379 5.31507 0.3125 8.3588 0.3125 12.0997C0.3125 15.8406 3.40379 18.8843 7.20312 18.8843C11.0025 18.8843 14.0938 15.8406 14.0938 12.0997C14.0938 10.543 13.5616 9.06626 12.5862 7.86537L17.1562 3.36567V6.82276C17.1562 7.23889 17.4992 7.57661 17.9219 7.57661C18.3445 7.57661 18.6875 7.23889 18.6875 6.82276V1.54584C18.6875 1.53001 18.6838 1.51492 18.6828 1.49946C18.6817 1.48216 18.6798 1.46559 18.6776 1.44866ZM7.20312 17.3766C4.24773 17.3766 1.84375 15.0096 1.84375 12.0997C1.84375 9.18976 4.24773 6.82276 7.20312 6.82276C8.634 6.82276 9.98113 7.37121 10.9941 8.36505C12.0055 9.36442 12.5625 10.6908 12.5625 12.0997C12.5625 15.0096 10.1585 17.3766 7.20312 17.3766Z"
        fill="#79C077"
      />
    </SvgStyled>
  );
};
