import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Header} from '../Header/Header';
import {Menu} from '../Menu/Menu';
import {
  Container,
  ContentContainer,
  MenuWrapper,
  OutletWrapper,
} from './AppLayout.styles';

export const AppLayout = () => {
  const [type, setType] = useState<'short' | 'full'>('short');
  const onCloseHandler = () => setType('short');

  return (
    <Container>
      <Header onClickLogo={() => setType('full')} />
      <ContentContainer>
        <MenuWrapper>
          <Menu
            activeMenuTab="main"
            menuType={type}
            onCloseFullMenu={onCloseHandler}
          />
        </MenuWrapper>
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </ContentContainer>
    </Container>
  );
};
