import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {TEAM_DESCRIPTION_QUERY} from '../../../../../gql';
import {
  TeamDescription,
  TeamDescriptionVariables,
} from '../../../../../gql/queries/team/__generated__/TeamDescription';

export function useTeam() {
  const params = useParams<{teamId?: string}>();

  const {data, loading} = useQuery<TeamDescription, TeamDescriptionVariables>(
    TEAM_DESCRIPTION_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip: params.teamId == null || params.teamId === '',
    }
  );

  return {
    data,
    loading,
  };
}
