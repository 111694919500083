import styled from 'styled-components';

export const Wrapper = styled.div`
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Form = styled.form`
 
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  > :not(:last-child) {
    margin-right: 24px;
  }
  
  > * {
    width: 310px;
  }
`;

export const Card = styled.article`
  background: #FFFFFF;
  border-radius: 8px;

  padding: 16px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  > :first-child {
    flex-shrink: 0;
    width: 100px;
  }

  > :not(:last-child) {
    margin-right: 6px;
  }
  
  @media screen and (max-width: 1250px) {
    display: block;
    
    > :first-child {
      flex-shrink: 0;
      width: 100%;
    }
    
    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const List = styled.div`
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Column = styled.div`
  flex: auto;
  
  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
`;

export const Category = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 19px;

  color: #7D7D7D;
`;

export const Description = styled.p`
  margin: 0;
  
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333333;
`;

export const UserWrapper = styled.div`
  flex-shrink: 0;
  width: 265px;


  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
