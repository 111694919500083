import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useParams, useNavigate} from 'react-router-dom';
import {CALL_EDIT_MUTATION, CALL_QUERY} from '../../../../gql';
import {
  EditCall,
  EditCallVariables,
} from '../../../../gql/call/mutations/__generated__/EditCall';
import {
  Call,
  CallVariables,
} from '../../../../gql/call/queries/__generated__/Call';
import {createDateTime_DTO} from '../../../../utils/Date.utils';
import {FormFields} from '../components/form/types';
import {useServerValidate} from '../hooks/form.hooks';
import {toast} from 'react-toastify';

export function useCall() {
  const params = useParams<{callId?: string}>();

  const {data, loading, refetch} = useQuery<Call, CallVariables>(CALL_QUERY, {
    variables: {
      id: Number(params.callId),
    },
    skip: params.callId == null || params.callId === '',
  });

  const fetch = useCallback(() => refetch(), [refetch]);

  return {
    id: params.callId,
    data:
      data?.call == null
        ? null
        : {
            region: data.call.region?.id || '',
            additionalRegions: data.call.regions?.map((r) => r?.id || '') || [],
            type: data.call.type?.id || '',
            source: data.call.source?.id || '',
            description: data.call.description || '',
            applicationId: data.call.issue?.id || '',
            calls: data.call.calls?.map((c) => c?.id || '') || [],
            dateTime: new Date(data.call.date_time) || null,
            firstName: '',
            lastName: data.call.contact || '',
            middleName: '',
            phone: data.call.phone || '',
            email: data.call.email || '',
            is112: data.call.is_112 || false,
            isEducational: data.call.is_training || false,
            isUrgently: data.call.is_urgent || false,
            isEmailSend: data.call.is_email_sent || false,
          },
    loading,
    fetch,
    refetch,
  };
}

export function useActions(onSuccessChange?: () => void) {
  const params = useParams<{callId?: string}>();
  const [loading, setLoading] = useState(false);

  const {addServerError} = useServerValidate();
  const navigate = useNavigate();

  const [editCallRequest] = useMutation<EditCall, EditCallVariables>(
    CALL_EDIT_MUTATION
  );

  function editCall(
    data: FormFields,
    setServerError: UseFormSetError<FormFields>
  ) {
    if (params.callId == null || params.callId === '') return null;

    setLoading(true);

    editCallRequest({
      variables: {
        input: {
          callId: Number(params.callId),
          isTraining: data.isEducational,
          regionId: Number(data.region),
          sourceId: Number(data.source),
          typeId: Number(data.type),
          description: data.description,
          contact: [data.lastName, data.firstName, data.middleName]
            .filter(Boolean)
            .join(' '),
          phone: data.phone,
          email: data.email,
          isUrgent: data.isUrgently,
          dateTime:
            data.dateTime == null
              ? null
              : `${createDateTime_DTO(data.dateTime)}`,
          isEmailSent: data.isEmailSend,
          ...(data.applicationId !== '' &&
            data.applicationId != null && {
              issueId: data.applicationId.value,
            }),
          regions: data.additionalRegions.map((r) => Number(r)),
          ...(data.calls !== '' &&
            data.calls != null && {
              calls: data.calls.value,
            }),
        },
      },
    })
      .then(() => {
        toast('Звонок успешно изменён');
        onSuccessChange?.();
        navigate(`/calls/${params.callId}`);
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {editCall, loading};
}
