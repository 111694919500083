import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import {DateField} from '../elements/dateField/component';
import MainInfoCard from '../elements/mainInfo/component';
import Status from '../elements/status/component';
import User from '../../elements/user/component';
import WrapperWithComment from '../elements/wrapperWithComment/component';
import {
  DateWrapper,
  ResponsibleWrapper,
  Comment,
  SecondaryInfo,
  Name,
  Region,
  StatusWrapper,
  CommentTitle,
} from './styles';

interface Props {
  number: number;
  isUrgent: boolean;
  description: string;
  region: string;
  createAt: Date | null;
  avatarUrl?: string;
  fullNameResponsible: string;
  status: string;
  statusColor: CSSProperties['backgroundColor'];
  result?: string;
  issue?: number;
  call?: number;
}

export default function Card(props: Props) {
  const navigate = useNavigate();

  const showResult = props.result != null && props.result !== '';

  return (
    <WrapperWithComment onClickCard={() => navigate(`/tasks/${props.number}`)}>
      <>
        <MainInfoCard
          call={props.call}
          issue={props.issue}
          number={props.number}
          isUrgent={props.isUrgent}
          status={props.status}
          statusColor={props.statusColor}
        />

        <SecondaryInfo>
          <Name>
            {props.description.substring(0, 250)}{' '}
            {props.description.length > 250 ? '...' : ''}
          </Name>
          <Region>{props.region}</Region>
        </SecondaryInfo>

        <DateWrapper>
          <DateField label="Добавлено" date={props.createAt} />
        </DateWrapper>
        <ResponsibleWrapper>
          {props.fullNameResponsible !== '' ? (
            <User
              avatar={props.avatarUrl}
              fullName={props.fullNameResponsible}
              label="Ответственный"
              hasLabelPostfix
            />
          ) : null}
        </ResponsibleWrapper>
        <StatusWrapper>
          <Status
            color={props.statusColor}
            value={props.status}
            hideSmallSize
          />
        </StatusWrapper>
      </>
      {showResult && (
        <Comment>
          <CommentTitle>Результат</CommentTitle>
          {props.result}
        </Comment>
      )}
    </WrapperWithComment>
  );
}
