import React from 'react';
import {SvgStyled} from './Icons.styles';

export const PencilIcon = () => {
  return (
    <SvgStyled
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6.36867V8.03556H1.6669L6.58535 3.11709L4.91845 1.4502L0 6.36867Z"
        fill="white"
      />
      <path
        d="M7.86995 1.20309L6.83202 0.165174C6.65866 -0.00818307 6.37639 -0.00818307 6.20303 0.165174L5.38959 0.97862L7.05648 2.64552L7.86993 1.83207C8.04331 1.65871 8.04331 1.37645 7.86995 1.20309Z"
        fill="white"
      />
    </SvgStyled>
  );
};
