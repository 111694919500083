import React, {CSSProperties} from 'react';
import {LocationType} from '../../../../../constants/location.constants';
import {Id} from '../../../../../types/auxiliary.types';
import {DateField} from '../../../../cards/elements/dateField/component';
import MissingColumn from '../../../../cards/elements/missingColumn/component';
import {LocationIcon} from '../../../../elements/LocationIcon/LocationIcon';
import {ForestInTouchIcon} from '../../../../icons/ForestInTouchIcon';
import {
  Number,
  MainInfo,
  ShortInfo,
  Wrapper,
  IconsWrapper,
  IconWrapper,
  SecondaryInfo,
  Footer,
  Comment,
  ForestInTouchIconWrapper,
  DateBlocks,
} from './Card.styles';
import {UrgentlyIcon} from '../../../../icons/UrgentlyIcon';
import {Gender} from './Card.types';
import {Badge} from '../../../../elements/Badge/Badge';

export interface Props {
  number: number;
  isUrgently?: boolean;
  forestInTouch?: boolean;
  location?: LocationType;
  area: string;
  isTraining?: boolean;
  parentId?: number;
  missing: {
    id: Id;
    status?: {
      name: string;
      color: CSSProperties['backgroundColor'];
    };
    fullName: string;
    gender: Gender;
    birthday?: string;
  }[];
  lossDate: Date | null;
  creationDate: Date | null;

  ringingNumber?: {
    count: number;
    new?: number;
  };
  tasksNumber?: {
    count: number;
    new?: number;
  };
  institutionCallsNumber?: {
    count: number | string;
    new?: number;
  };
  callsNumber?: {
    count: number;
    new?: number;
  };
  lettersServicesNumber?: {
    count: number;
    new?: number;
  };
  commentNumber?: number;
  onClick?(): void;
}

export function Card(props: Props) {
  const hasSecondaryInfo =
    (props.ringingNumber != null && props.ringingNumber.count !== 0) ||
    (props.tasksNumber != null && props.tasksNumber.count !== 0) ||
    (props.institutionCallsNumber != null &&
      props.institutionCallsNumber.count !== 0) ||
    (props.callsNumber != null && props.callsNumber.count !== 0) ||
    (props.lettersServicesNumber != null &&
      props.lettersServicesNumber.count !== 0) ||
    (props.commentNumber != null && props.commentNumber !== 0);

  return (
    <Wrapper onClick={props.onClick}>
      <MainInfo>
        <ShortInfo>
          <Number>{props.number}</Number>
          <IconsWrapper>
            <div>
              {props.isUrgently && (
                <IconWrapper title="Срочная">
                  <UrgentlyIcon />
                </IconWrapper>
              )}
              <LocationIcon location={props.location} />
              {props.forestInTouch && (
                <ForestInTouchIconWrapper title="Лес на связи">
                  <ForestInTouchIcon />
                </ForestInTouchIconWrapper>
              )}
            </div>
            <div>
              {props.isTraining && <Badge title="Учебная" color="#DB00FF" />}
            </div>
            <div>
              {props.parentId && <Badge title="Дубль" color="#EE8C8C" />}
            </div>
          </IconsWrapper>
        </ShortInfo>
        <MissingColumn missing={props.missing} area={props.area} />
        <DateBlocks>
          <DateField
            date={props.lossDate}
            label="Время пропажи"
            hasLabelPostfix
          />
          <DateField
            date={props.creationDate}
            label="Время создания"
            hasLabelPostfix
          />
        </DateBlocks>
      </MainInfo>
      {hasSecondaryInfo && (
        <Footer>
          <SecondaryInfo>
            {props.ringingNumber != null && props.ringingNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.ringingNumber.new}
                title={`Прозвоны: ${props.ringingNumber.count}`}
              />
            )}
            {props.tasksNumber != null && props.tasksNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.tasksNumber.new}
                title={`Задачи: ${props.tasksNumber.count}`}
              />
            )}
            {props.institutionCallsNumber != null && (
              <Badge
                color="dark_blue"
                counter={props.institutionCallsNumber.new}
                title={`Прозвоны учреждений: ${props.institutionCallsNumber.count}`}
              />
            )}
            {props.callsNumber != null && props.callsNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.callsNumber.new}
                title={`Звонки: ${props.callsNumber.count}`}
              />
            )}
            {props.lettersServicesNumber != null &&
              props.lettersServicesNumber.count !== 0 && (
                <Badge
                  color="dark_blue"
                  counter={props.lettersServicesNumber.new}
                  title={`Письма в службы: ${props.lettersServicesNumber.count}`}
                />
              )}
          </SecondaryInfo>

          {props.commentNumber != null && props.commentNumber !== 0 && (
            <Comment>{`Комментарии: ${props.commentNumber}`}</Comment>
          )}
        </Footer>
      )}
    </Wrapper>
  );
}
