import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Id} from '../../../../../../types/auxiliary.types';
import WrapperWithComment from '../../../../../cards/elements/wrapperWithComment/component';
import {Button} from '../../../../../elements/Button/Button';
import User from '../../../../../elements/user/component';
import {connectCentrifuge} from '../../../../../layouts/Header/webSocket.hooks';
import {useSetCaller} from '../../hooks';
import {
  Address,
  Column,
  Email,
  Header,
  Label,
  Main,
  NumberCard,
  Phone,
  Region,
  SmallTitle,
  Website,
  Title,
  Description,
  Row,
  Caller,
  LabelWeight,
  Secondary,
} from './styles';
import {connectCallRequestsCentrifuge} from './webSocket.hooks';

interface Props {
  id: Id;
  number: number;
  name: string;
  type: string;
  region: string;
  address: string;
  contacts: {
    phone: string;
    email: string;
    website: string;
  };
  caller?:
    | {
        avatar?: string;
        fullName?: string;
      }
    | 'hide';
  note: string;
  result?: string;
  authorFullName?: string;
  onAddSuccess?: () => void;
}

interface Data {
  channel: string;
  data: {
    id: number;
    called_by: {
      id: number;
      name: string;
    };
  };
}

export default function Card(props: Props) {
  const navigate = useNavigate();

  const [webSocketData, setWebsocketData] = useState<Data>();

  const params = useParams<{callRequestId: string}>();

  const handleCallRequestPublication = useCallback((ctx: any) => {
    setWebsocketData(ctx);
  }, []);

  const {handleSetCaller, loading} = useSetCaller(props.onAddSuccess);

  useEffect(() => {
    connectCallRequestsCentrifuge(handleCallRequestPublication).catch((err) => {
      console.error('Ошибка при инициализации подключения:', err);
    });
  }, [handleCallRequestPublication]);

  const handleCalling = (e: any) => {
    e.stopPropagation();
    handleSetCaller(Number(props.id));
  };

  return (
    <div
      onClick={() =>
        navigate(`/callRequests/${params.callRequestId}/${props.id}`)
      }>
      <WrapperWithComment>
        <Main>
          <Column>
            <NumberCard>{props.number}</NumberCard>
          </Column>
          <Column main margin={24}>
            <Row>
              <Column main margin={9}>
                <Header>
                  <Title>{props.name}</Title>
                  <Label>{props.type}</Label>
                </Header>
                <Region>{props.region}</Region>
                <Address>{props.address}</Address>
              </Column>
              <Column>
                <SmallTitle>Контакты</SmallTitle>
                <Phone href={`tel:${props.contacts.phone}`}>
                  {props.contacts.phone}
                </Phone>
                <Email href={`mailto:${props.contacts.email}`}>
                  {props.contacts.email}
                </Email>
                <Website href={props.contacts.website}>
                  {props.contacts.website}
                </Website>
              </Column>
            </Row>
            <Description>{props.note}</Description>
          </Column>
          {props.caller !== 'hide' && (
            <Column>
              <Caller>
                {!props.caller?.fullName ? (
                  <Button disabled={loading} onClick={(e) => handleCalling(e)}>
                    {loading ? 'Загрузка' : 'Звоню'}
                  </Button>
                ) : (
                  <User
                    avatar={props.caller?.avatar}
                    fullName={
                      webSocketData?.data.called_by.name ||
                      props.caller?.fullName
                    }
                    label="Звонил"
                    hasLabelPostfix
                  />
                )}
              </Caller>
            </Column>
          )}
        </Main>
        {props.result != null && props.result !== '' && (
          <Secondary>
            <LabelWeight>Результат</LabelWeight>
            {props.result.split('\n').map((item, key) => {
              return (
                <div key={key}>
                  {item} <br />
                </div>
              );
            })}
          </Secondary>
        )}
      </WrapperWithComment>
    </div>
  );
}
