import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-between;
  width: 61rem;
  padding: 50px 0 50px 25px;
  @media screen and (max-width: 800px) {
    width: 400px;
  }
`;

export const AuthSide = styled.div`
  align-items: center;
  width: 19.5rem;
`;

export const LinkComeIn = styled.a`
  width: 100%;
  margin: 0 10px 0 10px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-color);
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 3.125rem;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 3.438rem;
`;

export const QuestionWrapper = styled.div`
  margin-bottom: 1.563rem;
`;

export const SloganWrapper = styled.div`
  width: 26.875rem;
  margin-top: 3.75rem;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
