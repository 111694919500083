import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';

import {Report} from '../../../modals/Report/Report';
import {useReportsHandler} from './EditReport.hooks';

interface Props {
  description: string;
  reportId: Id;
  onEditSuccess?(): void;
  onModalClose(): void;
}

export function EditReport(props: Props) {
  const {submit, loading} = useReportsHandler(
    props.reportId,
    props.onEditSuccess
  );

  return (
    <Report
      title="Редактировать отчет"
      defaultValues={{
        description: props.description,
      }}
      loading={loading}
      labelSubmitButton="Редактировать"
      submit={submit}
      onModalClose={props.onModalClose}
    />
  );
}
