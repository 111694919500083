import {useQuery} from '@apollo/client';
import {DRIVER_LICENSE_CATEGORIES_QUERY} from '../../../../../gql';
import {DriverLicenseCategories} from '../../../../../gql/queries/__generated__/DriverLicenseCategories';
import {Id} from '../../../../../types/auxiliary.types';

export function useDict() {
  const {data: driverCategories} = useQuery<DriverLicenseCategories>(
    DRIVER_LICENSE_CATEGORIES_QUERY
  );

  const driverCategoriesDict =
    driverCategories == null || driverCategories.driverLicenseCategories == null
      ? []
      : (driverCategories.driverLicenseCategories
          .map((category) => {
            if (category == null) return null;

            return {
              label: category.name,
              id: category.key,
            };
          })
          .filter(Boolean) as {
          id: Id;
          label: string | number;
        }[]);

  return {
    driverCategoriesDict,
  };
}
