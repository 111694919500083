import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: var(--text-color);
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-bottom: -8px;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  display: none;

  &:checked ~ label {
    background: #376092;
    border: 1px solid #2c507c;
    color: #ffffff;
  }
`;

export const InputLabel = styled.label`
  min-width: 24px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #2c507c;
  box-sizing: border-box;
  box-shadow: inset 2px 4px 14px rgba(0, 0, 0, 0.09);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2c507c;
  cursor: pointer;
`;
