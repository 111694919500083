import {useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {TEAMS_PAGINATION_QUERY} from '../../../gql';
import {
  TeamsPagination,
  TeamsPaginationVariables,
} from '../../../gql/queries/__generated__/TeamsPagination';
import {useCurrSearch, useUrlParamInitialization} from '../../../hooks/useUrl.hooks';

export function useTeams() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    tab: 'squads',
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');
  const page = searchParams.get('page');
  const isInstitution = activeTab === 'departments';

  const {data, loading} = useQuery<TeamsPagination, TeamsPaginationVariables>(
    TEAMS_PAGINATION_QUERY,
    {
      variables: {
        ...(searchParams.get('search') !== null && searchParams.get('search') !== "" && {
          name: searchParams.get('search')
        }),
        first: 15,
        page: Number(page),
        isInstitution: isInstitution,
        ...(searchParams.getAll('regions').length !== 0 && {
          regions: searchParams.getAll('regions').map((r) => Number(r)),
        }),
      },
      skip:
        activeTab == null || activeTab === '' || page == null || page === '',
    }
  );

  function handleSwitchTab(value: string) {
    setSearchParams({
      ...prevState,
      tab: value,
      page: '1',
    });
  }

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    data,
    loading,
    handleSwitchTab,
    handleSwitchPage,
    activeTab,
  };
}
