import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {useEditCalling} from '../../ringing.hook';
import {Calling} from './modal/Calling';

interface Props {
  content: string;
  callingId: Id;
  onEditSuccess?(): void;
  onModalClose(): void;
}

export function EditCalling(props: Props) {
  const {submit, loading} = useEditCalling(
    props.callingId,
    props.onEditSuccess
  );
  return (
    <Calling
      title={'Редактировать звонок'}
      defaultValues={{
        content: props.content,
      }}
      labelSubmitButton={'Редактировать'}
      submit={submit}
      loading={loading}
      onModalClose={props.onModalClose}
    />
  );
}
