import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {Button} from '../../elements/Button/Button';
import {ModalWrapper} from '../../elements/ModalWrapper/ModalWrapper';
import {TextField} from '../../elements/TextField/TextField';
import {useResetPasswordHook} from '../../screens/PasswordRecoveryScreen/hooks/useResetPassword.hook';
import {LinkComeIn} from '../../screens/PasswordRecoveryScreen/Password.styles';
import {Container, Row} from '../AuthFrom/AuthFrom.styles';
import {Wrapper} from './ResetPassword.styles';

export type ResetPasswordFormData = {
  email: string;
};

type ResetPasswordFormProps = {
  style?: React.CSSProperties;
};

const validations = {
  email: {
    minLength: {
      value: 2,
      message: 'Логин слишком короткий.',
    },
    required: 'Логин обязателен для заполнение.',
  },
};

const ResetPassword = ({style}: ResetPasswordFormProps) => {
  const {
    handleSubmit,
    setError,
    register,
    resetField,
    formState: {errors, isSubmitting},
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [, setShown] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = useResetPasswordHook();

  const submitForm: SubmitHandler<ResetPasswordFormData> = async (
    data: ResetPasswordFormData
  ) => {
    try {
      await onSubmit({email: data.email});
      setSuccess(true);
    } catch (error) {
      resetField('email');
      setError('email', {
        type: 'server',
        message: 'Неверные данные',
      });
    } finally {
    }
  };

  return (
    <form style={style} onSubmit={handleSubmit(submitForm)}>
      {success && (
        <Wrapper>
          <ModalWrapper
            onModalClose={() => setShown(false)}
            title="Восстановление пароля.">
            <p>Инструкция по сбросу пароля отправлена на Вашу почту</p>
            <LinkComeIn>
              <Link to="/sign-in"> Вернуться на страницу авторизации.</Link>
            </LinkComeIn>
          </ModalWrapper>
        </Wrapper>
      )}
      <Container>
        <TextField
          register={register('email', {...validations.email})}
          type="text"
          label="Логин"
          placeholder="Введите ваш e-mail"
          error={errors.email}
        />
        <Row>
          <Button
            disabled={isSubmitting}
            title="Восстановить пароль"
            type="submit"
          />
        </Row>
      </Container>
    </form>
  );
};

export default ResetPassword;
