import React from 'react';

import {Report} from '../../../modals/Report/Report';
import {useReportsHandler} from './AddReport.hooks';

interface Props {
  onAddSuccess?(): void;
  onModalClose(): void;
}

export function AddReport(props: Props) {
  const {submit, loading} = useReportsHandler(props.onAddSuccess);

  return (
    <Report
      title="Добавить отчет"
      loading={loading}
      labelSubmitButton="Добавить"
      submit={submit}
      onModalClose={props.onModalClose}
    />
  );
}
