import {CSSProperties} from 'react';
import styled from 'styled-components';

const changeSize = '700px';

export const Wrapper = styled.div`
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Main = styled.div`
  padding: 25px 24px 16px;
  box-sizing: border-box;

  @media screen and (max-width: ${changeSize}) {
    padding: 15px 16px 20px;
  }
`;

export const MainInfo = styled.div`
  box-sizing: border-box;
  display: flex;

  @media screen and (max-width: ${changeSize}) {
    flex-direction: column;
  }
`;

export const MainColumn = styled.div`
  width: 58%;
  margin-right: 7%;

  @media screen and (max-width: ${changeSize}) {
    width: 100%;
    margin-right: 0;
  }
`;

export const Title = styled.h3`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${changeSize}) {
    align-items: start;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

interface IStatus {
  color: CSSProperties['backgroundColor'];
}

export const Status = styled.p<IStatus>`
  margin: 0 22px 0 0;

  padding: 4px 8px;
  background-color: ${(p) => p.color};
  border-radius: 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #333;
`;

export const Region = styled.p`
  margin: 0 22px 0 0;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const DateS = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const SecondaryColumn = styled.div`
  display: flex;

  flex: auto;

  @media screen and (max-width: ${changeSize}) {
    margin-top: 16px;
  }
`;

export const Phone = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const SecondaryInfo = styled.p`
  margin: 32px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;

  @media screen and (max-width: ${changeSize}) {
    display: none;
  }
`;

export const Url = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #376092;
`;

export const Secondary = styled.p`
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const LabelWeight = styled.b`
  font-weight: 700;


  &::after {
    content: ":";
    margin-right: 4px;
  }
`;
