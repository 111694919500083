import React from 'react';
import {SvgStyled} from '../Icons.styles';

export const WaterIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        data-element="water"
        d="M13.785 3.48868C13.243 4.14592 12.3638 4.14592 11.8176 3.48868C11.2329 2.78449 10.4519 2.40039 9.60257 2.40039C8.75754 2.40039 7.97226 2.78449 7.38758 3.48868C6.84556 4.14592 5.96213 4.14592 5.42012 3.48868C4.83543 2.78449 4.05442 2.40039 3.20513 2.40039C2.3601 2.40039 1.57482 2.78449 0.990135 3.48868C0.716996 3.81303 0.354232 3.97521 0.00427246 3.97521V5.60124C0.849298 5.60124 1.63457 5.20861 2.21926 4.50442C2.76127 3.84718 3.64471 3.84718 4.18672 4.50442C4.77141 5.20861 5.55242 5.60124 6.40171 5.60124C7.24674 5.60124 8.03201 5.20861 8.6167 4.50442C9.15871 3.84718 10.0421 3.84718 10.5842 4.50442C11.1688 5.20861 11.9541 5.60124 12.7992 5.60124C13.6442 5.60124 14.4295 5.20861 15.0141 4.50442C15.2873 4.1758 15.6458 4.00935 16 4.00935V2.40039C15.1507 2.40039 14.3697 2.78449 13.785 3.48868Z"
      />
      <path
        data-element="water"
        d="M13.785 11.4867C13.243 12.144 12.3638 12.144 11.8176 11.4867C11.2329 10.7825 10.4519 10.3984 9.60257 10.3984C8.75754 10.3984 7.97226 10.7825 7.38758 11.4867C6.84556 12.144 5.96213 12.144 5.42012 11.4867C4.83543 10.7825 4.05442 10.3984 3.20513 10.3984C2.3601 10.3984 1.57482 10.7825 0.990135 11.4867C0.716996 11.8111 0.354232 11.9733 0.00427246 11.9733V13.5993C0.849298 13.5993 1.63457 13.2067 2.21926 12.5025C2.76127 11.8452 3.64471 11.8452 4.18672 12.5025C4.77141 13.2067 5.55242 13.5993 6.40171 13.5993C7.24674 13.5993 8.03201 13.2067 8.6167 12.5025C9.15871 11.8452 10.0421 11.8452 10.5842 12.5025C11.1688 13.2067 11.9541 13.5993 12.7992 13.5993C13.6442 13.5993 14.4295 13.2067 15.0141 12.5025C15.2873 12.1738 15.6458 12.0074 16 12.0074V10.3984C15.1507 10.3984 14.3697 10.7825 13.785 11.4867Z"
      />
      <path
        data-element="water"
        d="M15.8421 6.39648C14.9971 6.39648 14.2118 6.78059 13.6271 7.48478C13.0851 8.14202 12.2059 8.14202 11.6596 7.48478C11.075 6.78059 10.2939 6.39648 9.44465 6.39648C8.59963 6.39648 7.81435 6.78059 7.22966 7.48478C6.68765 8.14202 5.80421 8.14202 5.2622 7.48478C4.67751 6.78059 3.89224 6.39648 3.04721 6.39648C2.20219 6.39648 1.41691 6.78059 0.832222 7.48478C0.640171 7.71951 0.170712 7.98411 0 7.95851V9.59308C0.0554815 9.59308 0.106695 9.59308 0.153641 9.58455C0.887703 9.49919 1.55348 9.11936 2.06562 8.50479C2.60763 7.84754 3.49106 7.84754 4.03308 8.50479C4.61776 9.20898 5.40304 9.60162 6.24807 9.60162C7.09309 9.60162 7.87837 9.20898 8.46306 8.50479C9.00507 7.84754 9.8885 7.84754 10.4305 8.50479C11.0152 9.20898 11.8005 9.60162 12.6455 9.60162C13.4905 9.60162 14.2758 9.20898 14.8605 8.50479C15.1336 8.17617 15.4921 8.00972 15.8464 8.00972C15.9018 8.00972 15.9531 8.00972 16 8.02679V6.40929C15.9488 6.40502 15.9018 6.40502 15.8421 6.39648Z"
      />
    </SvgStyled>
  );
};
