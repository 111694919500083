import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 10%;
  height: 100%;
  align-items: start;
  margin-right: 1rem;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    flex: auto;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex-direction: row;
    align-items: center;

    flex: auto;
  }
`;

export const Link = styled(NavLink)`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: var(--additional-color);
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &::before {
    content: '#';
  }
`;

export const NumberField = styled.p`
  margin: 0 6px 0 0;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;

  color: #333;

  ::before {
    content: '#';
  }
`;

interface IUrgentlyIconWrapper {
  important?: boolean;
}

export const UrgentlyIconWrapper = styled.div<IUrgentlyIconWrapper>`
  width: 16px;
  height: 16px;

  svg path {
    fill: var(
      ${(p) => (p.important ? '--yellow-color-accent' : '--warning-color')}
    );

    &[data-at='urgently'] {
      fill: #fff;
    }
  }
`;

export const ForestInTouchIconWrapper = styled.div`
  width: 16px;
  height: 16px;

  svg path {
    fill: var(--secondary-color);

    &[data-at='phone'] {
      fill: var(--additional-color);
    }
  }
`;

export const Labels = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 16px;
  color: #333333;
`;
