import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const NumberField = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  color: #333;

  &::before {
    content: '#';
  }
`;

interface IIconsWrapper {
  isMain?: boolean;
}

export const IconsWrapper = styled.div<IIconsWrapper>`
  display: flex;
  align-items: center;

  flex: ${(p) => (p.isMain ? 'auto' : 'none')};

  > *:not(:last-child) {
    margin-right: ${(p) => (p.isMain ? 12 : 16)}px;
  }
`;

interface IUrgentlyIconWrapper {
  important?: boolean;
}

export const UrgentlyIconWrapper = styled.div<IUrgentlyIconWrapper>`
  width: 16px;
  height: 16px;

  svg path {
    fill: var(
      ${(p) => (p.important ? '--yellow-color-accent' : '--warning-color')}
    );

    &[data-at='urgently'] {
      fill: #fff;
    }
  }
`;

export const ForestInTouchIconWrapper = styled.div`
  margin-top: -3px;
  width: 20px;
  height: 19px;

  svg path {
    fill: var(--secondary-color);
  }

  svg path[data-at='phone'] {
    fill: var(--additional-color);
  }
`;

export const MessageIconWrapper = styled.div`
  position: relative;

  width: 16px;
  height: 16px;

  svg path {
    fill: #a1b2c8;
  }
`;

export const SuccessIconWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;

  width: 12px;
  height: 12px;

  svg circle {
    fill: var(--secondary-color);
  }
`;

export const RejectIconWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;

  width: 12px;
  height: 12px;

  svg circle {
    fill: var(--secondary-text-color);
  }
`;
