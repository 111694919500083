import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Pagination} from '../../../elements/Pagination/Pagination';
import {ParticipantList} from '../../../elements/ParticipantList/ParticipantList';
import Preloader from "../../../elements/Preloader/Preloader";
import Filter from './components/filter/component';
import {useUserList, usePagination} from './hooks';
import { PaginationWrapper, Wrapper, Title, Main } from "./styles";

export default function UserListScreen() {
  useDocumentTitle('Пользователи');

  const {data, pagination, loading} = useUserList();
  const {page, handleSwitchPage} = usePagination();

  return (
    <Wrapper>
      <Main>
        <Title>Пользователи</Title>
        <Filter usersCount={pagination?.total || 0}/>
        {loading
          ? <Preloader />
          : <ParticipantList
            users={data.map(u => ({
              id: u.id,
              avatar: u.avatar,
              name: u.fullName,
              nickname: u.nickname,
              address: u.address,
              region: u.regions.join(", "),
              team: u.team,
              phones: u.phones,
              emails: u.emails,
              socialNetworkInfo: u.socialNetworkInfo
            }))}
          />
        }
      </Main>
      <PaginationWrapper>
        <Pagination
          page={page}
          total={pagination.lasPage}
          onChange={handleSwitchPage}
        />
      </PaginationWrapper>
    </Wrapper>
  )
}
