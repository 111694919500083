import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {SHORT_REGIONS_QUERY} from '../../../../../gql';
import {ShortRegions} from '../../../../../gql/queries/__generated__/ShortRegions';
import { useCurrSearch } from '../../../../../hooks/useUrl.hooks';
import {FormFields} from './Filter.types';

export interface Item {
  label: string | number;
  value: string | number;
}

export function useDict() {
  const {data: regions} = useQuery<ShortRegions>(SHORT_REGIONS_QUERY);

  const regionsDict =
    regions == null || regions.allRegions == null
      ? []
      : (regions.allRegions
          .map((team) => {
            if (team == null) return null;

            return {
              label: team.name,
              value: team.id,
            };
          })
          .filter(Boolean) as Item[]);

  return {regions: regionsDict as {label: string; value: string}[]};
}

export function useAction() {
  const prevState = useCurrSearch<FormFields>() as FormFields;

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {
      function changeSearch(
        name: keyof FormFields,
        mapping?: (data: any) => string | string[]
      ) {
        return data[name] !== "" && data[name].length !== 0 && ({
          [name]: mapping == null ? String(data[name]) : mapping(data[name])
        })
      }

      const newSearch = {
        ...changeSearch('search'),
        ...changeSearch('regions', d => d),
      }

      const prevSearch = Object.entries(prevState).reduce((acc: Record<string, string>, [key, value]) => {
        const keyT = key as keyof FormFields;

        if (data.hasOwnProperty(key) && (data[keyT] === "" || data[keyT].length === 0)) return acc;

        return {
          ...acc,
          [key]: value
        }
      }, {})

      setSearchParams({
        ...prevSearch,
        ...newSearch,
        page: "1"
      })
    },
    [prevState, setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams(Object.entries(prevState).reduce((acc: Record<string, string>, [key, value]) => {
      if (searchParams.hasOwnProperty(key)) return acc;

      return {
        ...acc,
        [key]: value
      }
    }, {})),
    [prevState, searchParams, setSearchParams]
  );

  return {submit: handleSubmit, handleReset}
}
