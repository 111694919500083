import styled from 'styled-components';

export const Form = styled.form`
  min-height: 500px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding-bottom: 24px;

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Fields = styled.div`
  flex: auto;
`;

export const Tooltip = styled.p`
  margin: 0 0 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #000;
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 0 30px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Row = styled.li`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #333;

  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  span:first-child:after {
    content: '.';
  }
`;

interface ITriangleButton {
  rotate?: boolean;
}

export const TriangleButton = styled.button<ITriangleButton>`
  width: 16px;
  height: 16px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);

  transition: background-color 300ms linear;

  cursor: pointer;

  transform: rotate(${(p) => (p.rotate ? 0 : 180)}deg);

  svg {
    flex-shrink: 0;
    width: 8px;
    height: 4px;

    path {
      fill: #fff;

      transition: fill 500ms linear;
    }
  }

  &:not(:disabled):hover {
    //not found in design

    svg path {
      //empty
    }
  }

  &:disabled {
    //not found in design

    cursor: not-allowed;
  }
`;

export const TrashButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 4px;

  border: none;
  cursor: pointer;
  background-color: var(--primary-color);

  svg {
    width: 12px;
    height: 12px;

    fill: #fff;
  }

  &:not(&:disabled):hover {
    // not found in design
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 30px;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
