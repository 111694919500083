import React from 'react';
import {useAddFilesHandler} from './AddFile.hooks';
import {AddFile as SharedAddFile} from '../../../../../modals/AddFile/AddFile';

interface Props {
  file: File;
  fetch(): void;
  onModalClose(): void;
}

export function AddFile(props: Props) {
  const {handleAddFile, requestSending} = useAddFilesHandler(() => {
    props.fetch();
    props.onModalClose();
  });

  return (
    <SharedAddFile
      file={props.file}
      onSubmit={handleAddFile}
      requestSending={requestSending}
      onModalClose={props.onModalClose}
    />
  );
}
