import {SvgStyled} from './Icons.styles';
import React from 'react';

export const ExelIcon = () => {
  return (
    <SvgStyled
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        data-at="wrapper"
        d="M0 2.9082C0 1.80363 0.895431 0.908203 2 0.908203H25C28.866 0.908203 32 4.04221 32 7.9082V38.9082C32 40.0128 31.1046 40.9082 30 40.9082H2C0.895431 40.9082 0 40.0128 0 38.9082V2.9082Z"
      />
      <path
        data-at="text"
        d="M8.15598 35.9082L10.592 32.8842L8.31198 30.0522H9.72798L11.3 32.0562L12.872 30.0522H14.288L11.996 32.8962L14.444 35.9082H13.004L11.288 33.7482L9.58398 35.9082H8.15598ZM17.2541 36.0162C16.6141 36.0162 16.1341 35.8362 15.8141 35.4762C15.5021 35.1162 15.3461 34.5882 15.3461 33.8922V27.4482H16.5581V33.8202C16.5581 34.6122 16.8821 35.0082 17.5301 35.0082C17.6261 35.0082 17.7141 35.0042 17.7941 34.9962C17.8821 34.9882 17.9701 34.9722 18.0581 34.9482L18.0341 35.9202C17.7621 35.9842 17.5021 36.0162 17.2541 36.0162ZM21.0075 36.0162C20.5275 36.0162 20.0795 35.9562 19.6635 35.8362C19.2475 35.7082 18.8995 35.5322 18.6195 35.3082L18.9675 34.4922C19.2635 34.7002 19.5875 34.8602 19.9395 34.9722C20.2995 35.0842 20.6595 35.1402 21.0195 35.1402C21.4435 35.1402 21.7635 35.0642 21.9795 34.9122C22.1955 34.7602 22.3035 34.5562 22.3035 34.3002C22.3035 34.0922 22.2315 33.9322 22.0875 33.8202C21.9435 33.7002 21.7275 33.6082 21.4395 33.5442L20.2995 33.3162C19.2915 33.1082 18.7875 32.5882 18.7875 31.7562C18.7875 31.2042 19.0075 30.7642 19.4475 30.4362C19.8875 30.1082 20.4635 29.9442 21.1755 29.9442C21.5835 29.9442 21.9715 30.0042 22.3395 30.1242C22.7155 30.2442 23.0275 30.4242 23.2755 30.6642L22.9275 31.4802C22.6875 31.2722 22.4115 31.1122 22.0995 31.0002C21.7875 30.8882 21.4795 30.8322 21.1755 30.8322C20.7595 30.8322 20.4435 30.9122 20.2275 31.0722C20.0115 31.2242 19.9035 31.4322 19.9035 31.6962C19.9035 32.0962 20.1675 32.3522 20.6955 32.4642L21.8355 32.6922C22.3555 32.7962 22.7475 32.9722 23.0115 33.2202C23.2835 33.4682 23.4195 33.8042 23.4195 34.2282C23.4195 34.7882 23.1995 35.2282 22.7595 35.5482C22.3195 35.8602 21.7355 36.0162 21.0075 36.0162Z"
      />
      <rect y="28.9082" width="2" height="9" />
    </SvgStyled>
  );
};
