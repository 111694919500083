import {toast} from 'react-toastify';
import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useState} from 'react';

import {useParams} from 'react-router-dom';
import {
  APPLICATION_DELETE_REPORT_MUTATION,
  APPLICATION_REPORTS_QUERY,
} from '../../../../../../gql';
import {
  Reports,
  ReportsVariables,
} from '../../../../../../gql/application/queries/__generated__/Reports';
import {
  DeleteReport,
  DeleteReportVariables,
} from '../../../../../../gql/application/mutations/__generated__/DeleteReport';
import {Id} from '../../../../../../types/auxiliary.types';

import {Card} from './Reports.types';

export function useReports() {
  const params = useParams<{applicationId?: string}>();
  const {data, loading, fetchMore} = useQuery<Reports, ReportsVariables>(
    APPLICATION_REPORTS_QUERY,
    {
      variables: {
        id: (params.applicationId || '').toString(),
      },
      skip: params.applicationId == null || params.applicationId === '',
    }
  );

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.applicationId || '').toString(),
      },
    });
  }, [fetchMore, params.applicationId]);

  return {
    data:
      data?.issue?.reports == null
        ? []
        : (data?.issue?.reports.map((report, i) => ({
            id: report?.id,
            number: i + 1,
            author: {
              avatar: undefined,
              fullName: report?.user.profile.full_name,
            },
            createAt: new Date(report?.created_at || ''),
            // editAt: new Date(),
            description: report?.description || '',
          })) as Card[]),
    loading,
    fetch,
  };
}

export function useActions(
  onChangeSuccess?: () => void,
  onModalClose?: () => void
) {
  const [loading, setLoading] = useState(false);

  const [deleteReport] = useMutation<DeleteReport, DeleteReportVariables>(
    APPLICATION_DELETE_REPORT_MUTATION
  );

  function handleDeleteReport(id: Id) {
    deleteReport({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        if (onChangeSuccess != null) {
          new Promise(onChangeSuccess).then(() => setLoading(false));
          toast('Отчет успешно удален');
          onModalClose?.();
        }
      })
      .finally(() => {
        if (onChangeSuccess == null) {
          setLoading(false);
        }
      });
  }

  return {
    loading,
    handleDeleteReport,
  };
}
