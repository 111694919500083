import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Form} from '../components/form/component';
import {useTask} from './hooks';
import {Wrapper, Title} from './styles';

export function NewTaskScreen() {
  useDocumentTitle('Создать задачу');

  const {createTask, loading} = useTask();

  return (
    <Wrapper>
      <Title>Новая задача</Title>
      <Form
        submitLabel="Создать задачу"
        fetching={loading}
        onSubmit={createTask}
      />
    </Wrapper>
  )
}
