import React, {useEffect} from 'react';
import FormFooter from '../../elements/FormFooter/component';
import {useInvite} from './RegistrationFrom.hooks';
import {Fields, Form} from './RegistrationFrom.styles';
import {useForm, UseFormSetError, useFormState} from 'react-hook-form';
import {FormFields} from './RegistrationFrom.types';
import {PersonalInfo} from './components/PersonalInfo/PersonalInfo';
import {ContactInfo} from './components/ContactInfo/ContactInfo';
import {SquadInfo} from './components/SquadInfo/SquadInfo';
import {SocialNetworkInfo} from './components/SocialNetworkInfo/SocialNetworkInfo';
import {AdditionalInfo} from './components/AdditionalInfo/AdditionalInfo';
import {Button} from '../../elements/Button/Button';

interface Props {
  defaultValues?: FormFields;
  labelSubmit?: string;
  errors?: Record<string, string> | null;
  disabledPrimaryFields?: boolean;
  hideRegisterFields?: boolean;
  loading?: boolean;
  onSubmit?(value: FormFields, setError?: UseFormSetError<FormFields>): void;
}

export const RegistrationForm = (props: Props) => {
  const {data} = useInvite();

  const {register, control, formState, handleSubmit, setValue, setError} =
    useForm<FormFields>({
      defaultValues: props.defaultValues || {
        personalInfo: {
          firstName: '',
          lastName: '',
          middleName: '',
          nick: '',
          birthday: null,
          category: [],
        },
        contactInfo: {
          primaryEmail: '',
          secondaryEmails: [],
          primaryPhone: '',
          secondaryPhones: [],
          address: '',
        },
        squadInfo: {
          squad: '',
          roles: [],
          regions: [],
        },
        socialNetworkInfo: {
          vk: '',
          facebook: '',
          twitter: '',
          telegram: '',
        },
        additionalInfo: {
          qualification: '',
          message: '',
          agreeWork: false,
          agreePersonalData: false,
          sendNotificationEmail: false,
          playNotificationSound: false,
        },
      },
      reValidateMode: 'onBlur',
      mode: 'all',
    });

  useEffect(() => {
    if (data == null) return;

    if (data.invite?.email != null) {
      setValue('contactInfo.primaryEmail', data.invite?.email);
    }

    if (data.invite?.team != null) {
      setValue('squadInfo.squad', data.invite?.team.id);
    }

    if (data.invite?.group != null) {
      setValue('squadInfo.roles', [data.invite?.group.id]);
    }
  }, [data, setValue]);

  return (
    <Form onSubmit={handleSubmit((data) => props.onSubmit?.(data, setError))}>
      <Fields>
        <PersonalInfo
          register={register}
          control={control}
          setValue={setValue}
        />

        <ContactInfo
          disabledPrimaryFields={props.disabledPrimaryFields}
          register={register}
          control={control}
        />
        <SquadInfo
          disabledPrimaryFields={props.disabledPrimaryFields}
          register={register}
          control={control}
          setField={setValue}
        />
        <SocialNetworkInfo register={register} control={control} />
        <AdditionalInfo
          register={register}
          control={control}
          hideRegisterFields={props.hideRegisterFields}
        />
      </Fields>

      <FormFooter
        isValid={formState.isValid}
        hasErrors={Object.keys(formState.errors).length > 0}>
        <Button
          title={props.labelSubmit}
          type="submit"
          disabled={formState.isSubmitting || props.loading}
        />
      </FormFooter>
    </Form>
  );
};
