import React, {ReactNode} from 'react';
import {Content, Title, Wrapper} from './BlockWrapper.styles';

interface Props {
  title: string;
  children: ReactNode;
}

export const BlockWrapper = (props: Props) => {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <Content>{props.children}</Content>
    </Wrapper>
  );
};
