import {useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams, useSearchParams} from 'react-router-dom';
import {APPLICATION_PHOTOS_QUERY} from '../../../../../../gql';
import {
  Photos,
  PhotosVariables,
} from '../../../../../../gql/application/queries/__generated__/Photos';
import {getFullName_dto} from '../../../../../../utils/String.utils';

import {Photo} from './Photos.types';

export function usePhotos() {
  const [searchParams] = useSearchParams();
  const params = useParams<{applicationId?: string}>();
  const {data, loading, fetchMore} = useQuery<Photos, PhotosVariables>(
    APPLICATION_PHOTOS_QUERY,
    {
      variables: {
        id: (params.applicationId || '').toString(),
      },
      skip: params.applicationId == null || params.applicationId === '',
    }
  );

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.applicationId || '').toString(),
      },
    });
  }, [fetchMore, params.applicationId]);

  const missing = data?.issue?.missing?.find(
    (m) => m?.id === Number(searchParams.get('photos'))
  );

  return {
    missingName:
      missing == null
        ? ''
        : getFullName_dto({
            first_name: missing.first_name,
            last_name: missing.last_name,
            second_name: missing.second_name,
          }),
    list:
      missing?.images?.map((p) => ({
        name: p?.name || '',
        url: p?.url || '',
      })) ?? ([] as Photo[]),
    loading,
    fetch,
  };
}
