import React, {Fragment, useState} from 'react';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import RingingDropDown from '../../../../../elements/CreateRinging/RingingDropDown';
import {useCreateRingingApplication} from '../../../../Call/view/ringing.hook';

interface Props {
  onChangeSuccess?: () => void;
}

function CallingComponent(props: Props) {
  const [modal, setModal] = useState(false);

  const handleModalClose = () => setModal(false);

  const {submit} = useCreateRingingApplication(
    props.onChangeSuccess,
    handleModalClose
  );

  return (
    <Fragment>
      <AddButton
        buttonLabel="Добавить прозвон"
        typeButton="button"
        addItem={() => setModal(true)}
      />
      {modal && (
        <RingingDropDown
          title={'Кому звонили'}
          labelSubmit={'Выберите'}
          onAddSuccess={props.onChangeSuccess}
          submit={submit}
          onModalClose={() => setModal(false)}
        />
      )}
    </Fragment>
  );
}

export default CallingComponent;
