import React, {useState} from 'react';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import User from '../../../../../elements/user/component';
import {AddCallRequest} from '../../modals/AddCallRequest/component';
import {useCallRequesList} from './hooks';
import {Card, List, Wrapper, SettingsBlock, StyledNavLink} from './styles';
import Header from '../../../../../elements/sidebarInfo/header/component';

export function CallRequests() {
  const [shownModal, setShownModal] = useState(false);

  const {list, loading, refetch} = useCallRequesList();

  if (loading) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      {shownModal && (
        <AddCallRequest
          onModalClose={() => setShownModal(false)}
          onAddSuccess={refetch}
        />
      )}
      <AddButton
        typeButton="button"
        buttonLabel="Добавление запроса"
        addItem={() => setShownModal(true)}
      />
      <List>
        {list?.map((card) => (
          <StyledNavLink to={`/callRequests/${card.id}`}>
            <Card key={card.id}>
              <Header number={card.id} urgent={card.urgency} />
              <SettingsBlock>
                <User fullName={card.profile} />
              </SettingsBlock>
            </Card>
          </StyledNavLink>
        ))}
      </List>
    </Wrapper>
  );
}
