import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  Wrapper,
  Container,
  FakeCheck,
  UnCheck,
  CheckboxWrapper,
} from './Checkbox.styles';
import {CheckMarkIcon} from '../../../../icons/CheckMarkIcon';
import {ReactElement} from 'react';
import {ErrorMessage} from '../../../../elements/ErrorMessage/ErrorMessage';

export interface Props<T extends FieldValues> {
  id?: string;
  name: Path<T>;
  control: Control<T>;
  rules?: UseControllerProps['rules'];
  disabled?: boolean;
  label?: string;
  LabelComponent?: ReactElement;
}

export default function Checkbox<FormFields extends FieldValues>(
  props: Props<FormFields>
) {
  const {field, fieldState} = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  });

  return (
    <Wrapper>
      <Container>
        <CheckboxWrapper
          disabled={props.disabled}
          onClick={() => field.onChange(!field.value)}>
          {field.value ? (
            <FakeCheck>
              <CheckMarkIcon />
            </FakeCheck>
          ) : (
            <UnCheck />
          )}
        </CheckboxWrapper>

        {props.LabelComponent || props.label || ''}
      </Container>
      {fieldState.error != null && (
        <ErrorMessage message={fieldState.error?.message} />
      )}
    </Wrapper>
  );
}
