import React, {useContext, useState} from 'react';
import {Controller, useForm, UseFormSetError} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {UserContext} from '../../../../../context/UserContext';
import {BlockWrapper} from '../../../../elements/BlockWrapper/BlockWrapper';
import {Button} from '../../../../elements/Button/Button';
import FormFooter from '../../../../elements/FormFooter/component';
import {Label} from '../../../../elements/Label/Label';
import Preloader from '../../../../elements/Preloader/Preloader';
import {Option} from '../../../../elements/Select/Select.types';
import SwitchField from '../../../../elements/SwitchField/SwitchField';
import {TextField} from '../../../../elements/TextField/TextField';
import {
  useCallOptions,
  useIssueOptions,
} from '../../../Application/ApplicationView/modals/AddDouble/hooks';
import {useDict} from './hooks';
import {
  GridFields,
  PrimaryColumn,
  SecondaryColumn,
  Form as FormWrapper,
  Footer,
  Content,
  GeneralInfo,
  GeneralInfoRow,
} from './styles';
import {FormFields} from './types';

interface Props {
  defaultValues?: FormFields | null;
  fetching?: boolean;
  submitLabel: string;
  onSubmit(d: FormFields, setError: UseFormSetError<FormFields>): void;
}

export function Form(props: Props) {
  const [searchParams] = useSearchParams();

  const user = useContext(UserContext);

  const {loading: loadingDict, regions, teams, calls, applications} = useDict();

  const navigate = useNavigate();

  const initState = props.defaultValues || {
    description: '',
    applicationId: searchParams.get('applicationId') || '',
    callId: searchParams.get('callId') || '',
    isUrgently: false,
  };

  const {control, register, formState, handleSubmit, setError, watch, reset} =
    useForm<FormFields>({
      defaultValues: initState,
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

  const fields = watch();

  const baseDisabled = formState.isSubmitting || props.fetching;

  const dictDisabled = baseDisabled || loadingDict;

  const groupDisabled =
    dictDisabled || fields.teamId === '' || fields.teamId == null;

  const userDisabled =
    groupDisabled || fields.groupId === '' || fields.groupId == null;

  const groupsId = user?.groups?.map((g) => g.id) || [];

  const applicationId = searchParams.get('applicationId');

  const callId = searchParams.get('callId');

  const [issue, setIssue] = useState<any>(undefined);
  const [call, setCall] = useState<any>(undefined);

  if (loadingDict) return <Preloader />;

  // @ts-ignore
  return (
    <FormWrapper
      onReset={() => {
        reset(initState, {keepDefaultValues: true});
      }}
      onSubmit={handleSubmit((d) => props.onSubmit(d, setError))}>
      <Content>
        <BlockWrapper title="Общая информация" shouldOpen>
          <GeneralInfo>
            <PrimaryColumn>
              <TextField
                register={register('description', {
                  required: 'Поле обязательно для заполнения.',
                  maxLength: {
                    value: 1000,
                    message:
                      'Максимальная длина описания не должна превышать 1000 символов',
                  },
                })}
                label="Описание задачи *"
                placeholder="Введите"
                error={formState.errors.description}
                isTextarea
                rows={2}
                disabled={baseDisabled}
              />
              <GridFields>
                <Label label="Сопутствующая заявка">
                  <AsyncSelect
                    value={issue}
                    name="applicationId"
                    onChange={(value) => setIssue(value)}
                    placeholder="Выберите"
                    noOptionsMessage={() => 'Введите значение...'}
                    loadingMessage={() => 'Идет загрузка...'}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({...base, zIndex: 9999}),
                    }}
                    defaultValue={
                      applicationId === null
                        ? null
                        : {
                            id: applicationId,
                            value: applicationId,
                            label: `Заявка #${applicationId}`,
                          }
                    }
                    loadOptions={(inputValue, callback) =>
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useIssueOptions(inputValue, callback, applicationId)
                    }
                    cacheOptions
                  />
                </Label>

                <Label label={'Сопутствующий звонок'}>
                  <AsyncSelect
                    value={call}
                    onChange={(cal) => setCall(cal)}
                    name="callId"
                    placeholder="Выберите"
                    noOptionsMessage={() => 'Введите значение...'}
                    loadingMessage={() => 'Идет загрузка...'}
                    menuPortalTarget={document.body}
                    styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    cacheOptions
                    isDisabled={loadingDict}
                    defaultValue={
                      callId === null
                        ? null
                        : {
                            id: callId,
                            value: callId,
                            label: `Заявка #${callId}`,
                          }
                    }
                    loadOptions={(inputValue, callback) =>
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useCallOptions(inputValue, callback, applicationId)
                    }
                  />
                </Label>

                <Label label={'Выберите регион*'}>
                  <Controller
                    name={'regionId'}
                    control={control}
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                          }}
                          placeholder={'Выберите регион'}
                          noOptionsMessage={() => 'Список пуст!'}
                          loadingMessage={() => 'Идет загрузка...'}
                          options={regions}
                          value={regions.find((c) => c.value === value)}
                          isClearable
                          isDisabled={dictDisabled}
                          onChange={(option) => onChange(option?.value)}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />
                </Label>
              </GridFields>
            </PrimaryColumn>
            <SecondaryColumn>
              <SwitchField
                name="isUrgently"
                control={control}
                label="Срочная задача"
                disabled={baseDisabled}
              />
            </SecondaryColumn>
          </GeneralInfo>
          <GeneralInfoRow>
            <Label label={'Ответственный отряд *'}>
              <Controller
                name={'teamId'}
                control={control}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                      placeholder={'Выберите'}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={Object.values(teams).map(({value, label}) => ({
                        value,
                        label,
                      }))}
                      value={Object.values(teams)
                        .map(({value, label}) => ({
                          value,
                          label,
                        }))
                        .find((c) => c.value === value)}
                      isDisabled={dictDisabled}
                      isClearable
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </Label>
            <Label label={'Ответственная группа'}>
              <Controller
                name={'groupId'}
                control={control}
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                      placeholder={'Выберите'}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={
                        fields.teamId === '' || fields.teamId == null
                          ? []
                          : Object.values(
                              teams[fields.teamId]?.groups || []
                            ).map(({value, label}) => ({value, label}))
                      }
                      value={
                        fields.teamId === '' || fields.teamId == null
                          ? []
                          : Object.values(teams[fields.teamId]?.groups || [])
                              .map(({value, label}) => ({value, label}))
                              .find((c) => c.value === value)
                      }
                      isDisabled={groupDisabled}
                      isClearable
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </Label>
            <Label label={'Ответственный исполнитель'}>
              <Controller
                name={'userId'}
                control={control}
                render={({field: {onChange, value, onBlur}}) => {
                  const currentTeam = teams[fields?.teamId || ''] || null;
                  const currentGroup =
                    currentTeam && fields.groupId
                      ? currentTeam.groups[fields.groupId] || null
                      : null;
                  const options = currentGroup ? currentGroup.users : [];
                  const selectedOption = options.find(
                    (option) => option.value === value
                  );

                  return (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                      placeholder={'Выберите'}
                      noOptionsMessage={() => 'Список пуст!'}
                      loadingMessage={() => 'Идет загрузка...'}
                      options={options}
                      value={selectedOption}
                      isDisabled={userDisabled}
                      isClearable
                      onChange={(option) => onChange(option?.value)}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </Label>
          </GeneralInfoRow>
        </BlockWrapper>
        <BlockWrapper title="Группа контроля" shouldOpen>
          <GridFields>
            <GridFields>
              <Label label={'Роль или группа *'}>
                <Controller
                  name={'controlGroupId'}
                  control={control}
                  render={({field: {onChange, value, onBlur}}) => {
                    const options =
                      user?.teamId == null || !groupsId.length
                        ? []
                        : Object.values(
                            teams[user?.teamId]?.groups || {}
                          ).reduce((acc: Option[], curr) => {
                            if (!groupsId.includes(curr.value)) return acc;

                            return [
                              ...acc,
                              {
                                value: curr.value,
                                label: curr.label,
                              },
                            ];
                          }, []);

                    // Найти объект option, соответствующий текущему значению
                    const selectedOption = options.find(
                      (option) => option.value === parseInt(value)
                    );

                    return (
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({...base, zIndex: 9999}),
                        }}
                        placeholder={'Выберите'}
                        noOptionsMessage={() => 'Список пуст!'}
                        loadingMessage={() => 'Идет загрузка...'}
                        options={options}
                        value={selectedOption}
                        isClearable
                        onChange={(option) => onChange(option?.value)}
                        onBlur={onBlur}
                      />
                    );
                  }}
                />
              </Label>
            </GridFields>
          </GridFields>
        </BlockWrapper>
      </Content>
      <FormFooter>
        <Footer>
          <Button
            onClick={() => navigate('/tasks')}
            title="Отменить"
            type="reset"
            variant="outlined"
            disabled={baseDisabled}
          />
          <Button
            title={props.submitLabel}
            type="submit"
            disabled={baseDisabled || !formState.isValid}
          />
        </Footer>
      </FormFooter>
    </FormWrapper>
  );
}
