import styled from "styled-components";
import {CSSProperties} from 'react';

interface IWrapper {
  hideSmallSize?: boolean;
  hideFullSize?: boolean;
  color: CSSProperties["backgroundColor"]
}

export const Wrapper = styled.p<IWrapper>`
  height: min-content;
  display: ${p => p.hideFullSize ? "none" : "block"};
  margin: 0;
  background-color: ${p => p.color};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333;
  border-radius: 4px;
  padding: 4px 8px;

  @media screen and (max-width: 800px) {
    display: ${p => p.hideSmallSize ? "none" : "block"};
  }
`

