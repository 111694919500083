import styled from 'styled-components';

export const Wrapper = styled.section`
  flex: auto;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
