import {useMutation} from '@apollo/client';
import {gql} from 'graphql.macro';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {toast} from 'react-toastify';
import {Button} from '../../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../../elements/ModalWrapper/ModalWrapper';
import {useIssueOptions} from './hooks';
import {ActionsSection, ButtonStyled, InputWrapper, MyWrapper} from './styled';

type Props = {
  close: () => void;
  onSubmit: () => void;
};

const MARK_AS_DOUBLE = gql`
  mutation EditParenIssue($input: EditParentIssueInput) {
    editParentIssue(input: $input) {
      success
    }
  }
`;

const ERROR_MESSAGE = 'Ошибка добавления родительской заявки!';

export function AddDoubleModal(props: Props) {
  const {applicationId} = useParams();
  const [parentIssue, setParentIssue] = useState<any>(undefined);

  const [markUsDoubleMutation, {loading}] = useMutation(MARK_AS_DOUBLE, {
    variables: {
      input: {
        issueId: Number(applicationId),
        parentIssueId: parentIssue?.id,
      },
    },
  });

  const submit = async () => {
    try {
      await markUsDoubleMutation({});
      props.onSubmit();
    } catch (e) {
      toast(ERROR_MESSAGE, {type: 'error'});
    }
  };

  // @ts-ignore
  return (
    <ModalWrapper
      title={'Присвоение родительской заявки'}
      onModalClose={props.close}>
      <MyWrapper>
        <InputWrapper>
          <AsyncSelect
            value={parentIssue}
            onChange={(issue) => setParentIssue(issue)}
            cacheOptions
            loadingMessage={() => 'Загрузка'}
            noOptionsMessage={() => 'Нет варантов'}
            placeholder={'Введите ФИО пропавшего'}
            loadOptions={useIssueOptions}
          />
        </InputWrapper>
        <ActionsSection>
          <Button
            title="Отменить"
            type="reset"
            disabled={loading}
            onClick={props.close}
            variant="outlined"
          />
          <ButtonStyled
            disabled={loading || !parentIssue}
            onClick={() => submit()}
            title="Сохранить"
            type="submit"
          />
        </ActionsSection>
      </MyWrapper>
    </ModalWrapper>
  );
}
