import styled, {css} from 'styled-components';

function section(pl: number, pr: number) {
  return css`
    padding: 16px ${pr}px 16px ${pl}px;
    border-right: 1px solid #d8e2ed;
  `;
}

export const Wrapper = styled.header`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  ${section(18, 21)};
  cursor: pointer;

  @media screen and (max-width: 950px) {
    ${section(12, 12)};
  }
`;

export const MainSection = styled.div`
  ${section(24, 24)};

  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 950px) {
    ${section(12, 12)};
  }
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: -8px;

  > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @media screen and (max-width: 650px) {
    button {
      padding: 9px;

      > svg {
        margin-right: 0;
      }
    }

    span {
      display: none;
    }
  }
`;

export const UserWrapper = styled.div`
  ${section(24, 24)};
  border: none;

  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    ${section(12, 12)};
  }
`;
