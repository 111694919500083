import React from 'react';
import {useParams} from 'react-router-dom';
import Preloader from '../../../../elements/Preloader/Preloader';
import useQuestionnaire from './Questionnaires.hooks';
import {
  Wrapper,
  QuestionnaireDescription,
  QuestionnaireName,
} from './Questionnaires.styles';

export default function Questionnaire() {
  const {questionnaires, loading} = useQuestionnaire();

  const params = useParams<{questionnaireId: string}>();

  const data = questionnaires.find((p) => p.id == params.questionnaireId);

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <QuestionnaireName>{data?.name}</QuestionnaireName>
          {data?.text != null && (
            <QuestionnaireDescription
              dangerouslySetInnerHTML={{__html: data.text}}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}
