import React, {Fragment} from 'react';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {ApplicantCard} from '../ApplicantCard/ApplicantCard';
import {MissingCard} from '../MissingCard/MissingCard';
import RingingList from './component';
import {useMissing, useStatuses} from './MissingSection.hooks';

import {Missing, Wrapper} from './MissingSection.styles';

export function MissingSection() {
  const {list, loading, refetch} = useMissing();
  const {statuses} = useStatuses();

  if (loading) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      <Missing>
        {list.map((applicant) => (
          <Fragment key={applicant.id}>
            <ApplicantCard
              is112={applicant.is112}
              fullName={applicant.name}
              label={applicant.label}
              phone={applicant.phone}
              additionalContact={applicant.additionalContact}
              MES={applicant.MES}
              police={applicant.police}
            />
            {applicant.missing.map((m, i) => (
              <MissingCard
                key={`${applicant.id}_${i}`}
                id={m.id}
                gender={m.gender}
                fullName={m.fullName}
                age={m.age}
                labels={m.labels}
                statuses={m.statuses}
                fields={m.fields}
                filesCount={m.filesCount}
                photosCount={m.photosCount}
                videosCount={m.videosCount}
                statusesDict={statuses}
                onSuccessChangeStatus={refetch}
              />
            ))}
          </Fragment>
        ))}
      </Missing>
      <RingingList />
    </Wrapper>
  );
}
