import {useState} from 'react';
import {
  TakeToWork,
  TakeToWorkVariables,
} from './../../../../gql/call/mutations/__generated__/TakeToWork';
import {CALL_TAKE_TO_WORK} from './../../../../gql/index';
import {useMutation, useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {List} from '../../../../gql/call/queries/__generated__/List';
import {CALLS_QUERY} from '../../../../gql';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../hooks/useUrl.hooks';
import {FormFields} from './components/filter/types';
import {toast} from 'react-toastify';

export default function useCallList() {
  const currSearchParams = useCurrSearch<
    FormFields & {page: string | null}
  >() as FormFields & {page: string | null};

  const {data, loading, refetch} = useQuery<List>(CALLS_QUERY, {
    variables: {
      ...(currSearchParams.search != null && {
        text: currSearchParams.search,
        responsible: currSearchParams.responsible,
      }),
      ...((currSearchParams.dateFrom != null || currSearchParams.dateTo) && {
        dateTime: {
          ...(currSearchParams.dateFrom != null && {
            from: currSearchParams.dateFrom,
          }),
          ...(currSearchParams.dateTo != null && {
            to: currSearchParams.dateTo,
          }),
        },
      }),
      ...(currSearchParams.isUrgently != null && {
        isUrgent: true,
      }),
      ...(currSearchParams.isTraining != null && {
        isTraining: true,
      }),
      ...(currSearchParams.application != null && {
        application: Number(currSearchParams.application),
      }),
      ...(currSearchParams.region != null && {
        regionId: Number(currSearchParams.region),
      }),
      ...(currSearchParams.status != null && {
        status: Number(currSearchParams.status),
      }),
      ...(currSearchParams.type != null && {
        type: Number(currSearchParams.type),
      }),
      ...(currSearchParams.type != null && {
        type: Number(currSearchParams.type),
      }),
      page: Number(currSearchParams.page),
    },
    fetchPolicy: 'no-cache',
    skip: currSearchParams.page == null,
  });

  return {
    data:
      data?.calls?.data == null
        ? []
        : data?.calls?.data.map((call) => ({
            callId: call.id,
            description: call.description || '',
            isUrgent: call.is_urgent || false,
            isTraining: call.is_training || false,
            status: call.status?.name || '',
            statusColor: call.status?.color || '',
            fullNameResponsible: call.responsible?.profile.full_name || '',
            phoneResponsible: call.phone || '',
            region: call.region?.name || '',
            type: call.type?.name || '',
            dateTime: call.date_time == null ? null : new Date(call.date_time),
            ringingCount: undefined, //FIXME:
            taskCount: undefined, //FIXME:
            commentCount: undefined, //FIXME:
            responsible: call.responsible?.id,
            contact: call.contact,
          })),
    pagination: {
      lasPage: data?.calls?.paginatorInfo.lastPage || 0,
      total: data?.calls?.paginatorInfo?.total || 0,
    },
    loading,
    refetch,
  };
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    handleSwitchPage,
    page: Number(searchParams.get('page')),
  };
}

export function useTakeToWork(onAddSuccess?: () => void) {
  const [loading, setLoading] = useState(false);
  const [takeToWork] = useMutation<TakeToWork, TakeToWorkVariables>(
    CALL_TAKE_TO_WORK
  );
  function handleTakeToWork(id: number) {
    setLoading(true);
    takeToWork({
      variables: {
        id: id,
      },
    })
      .then(() => {
        toast('Звонок успешно взят в работу');
        onAddSuccess?.();
      })
      .catch((e) => toast(e.message, {type: 'error'}))
      .finally(() => setLoading(false));
  }

  return {loading, handleTakeToWork};
}
