import {useQuery} from '@apollo/client';
import {CALL_RECIPIENT_QUERY} from '../../../gql';
import {
  CallRecipient,
  CallRecipient_allCallRecipients,
} from '../../../gql/queries/__generated__/CallRecipient';
import {createDict} from '../../../utils/Dict.utils';

export function useCallRecipient() {
  const {data, loading} = useQuery<
    CallRecipient,
    CallRecipient_allCallRecipients
  >(CALL_RECIPIENT_QUERY);

  return {
    loading,
    recipient: data == null ? [] : createDict(data.allCallRecipients),
  };
}
