import {useMutation} from '@apollo/client';

import {useParams, useNavigate} from 'react-router-dom';
import {APPLICATION_TRANSFER_MUTATION} from '../../../../../../gql';
import {
  TransferApplication,
  TransferApplicationVariables,
} from '../../../../../../gql/application/mutations/__generated__/TransferApplication';

import {FormFields} from './types';
import {toast} from 'react-toastify';

export function useActions() {
  const params = useParams<{applicationId: string}>();

  const [transfer] = useMutation<
    TransferApplication,
    TransferApplicationVariables
  >(APPLICATION_TRANSFER_MUTATION);

  const navigate = useNavigate();

  function handleTransfer(data: FormFields) {
    return transfer({
      variables: {
        input: {
          issueId: Number(params.applicationId),
          teamId: Number(data.groupId),
          description: data.description,
        },
      },
    })
      .then(() => {
        toast('Заявка успешно передана');
        navigate('/applications');
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
        navigate('/applications');
      });
  }

  return {
    handleTransfer,
  };
}
