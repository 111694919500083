import {allTechnicCategories} from './../../../../../../gql/profile/queries/__generated__/allTechnicCategories';
import {Profile} from './../../../../../../gql/profile/queries/__generated__/Profile';
import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {
  CreateTechnique,
  CreateTechniqueVariables,
} from './../../../../../../gql/profile/mutations/__generated__/CreateTechnique';
import {
  PROFILE_CREATE_TECHNIQUE,
  PROFILE_QUERY,
  ALL_TECHNIC_CATEGORY,
} from './../../../../../../gql/index';
import {useMutation} from '@apollo/client';
import {FormFields} from './types';
import {createDict} from '../../../../../../utils/Dict.utils';
import {toast} from 'react-toastify';

export function useAddTechnique(onAddSuccess: () => void) {
  const [addTechnique, {loading}] = useMutation<
    CreateTechnique,
    CreateTechniqueVariables
  >(PROFILE_CREATE_TECHNIQUE);

  const handleSubmit = useCallback(
    (data: FormFields, setError?: (error: string) => void) => {
      addTechnique({
        variables: {
          input: {
            regionId: Number(data.regionId),
            categoryId: Number(data.categoryId),
            name: data.name,
            number: data.number,
            note: data.note,
          },
        },
      })
        .then(() => {
          toast('Техника успешно добавлена');
          onAddSuccess();
        })
        .catch(() => {
          toast('Ошибка добавления техники!', {type: 'error'});
        });
    },
    [addTechnique, onAddSuccess]
  );
  return {handleSubmit, loading};
}

export function useDict() {
  const {data, loading} = useQuery<Profile>(PROFILE_QUERY);
  const {data: category} = useQuery<allTechnicCategories>(ALL_TECHNIC_CATEGORY);

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.me.regions),
    category: category == null ? [] : createDict(category.allTechnicCategories),
  };
}
