import React, {ReactNode, useState} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {CSSTransition} from 'react-transition-group';
import {declOfNum} from '../../../utils/String.utils';
import {FilterIcon} from '../../icons/FilterIcon';
import {TriangleIcon} from '../../icons/TriangleIcon';
import {SearchField} from '../SearchField/SearchField';
import {
  ButtonsWrapper,
  Container,
  CustomButton,
  Footer,
  ListFilterButton,
  ListFilterContainer,
  ListFilterContent,
  ListFilterHeader,
  Count,
  CountWrapper,
  Span,
  IconText,
} from './ListFilter.styles';
import {Button} from '../Button/Button';
import {TrashIcon} from '../../icons/TrashIcon';

interface Props {
  register?: UseFormRegisterReturn;
  showReset?: boolean;
  submitLabelButton?: string;
  children: ReactNode;
  count?: {
    amount: number;
    words: [string, string, string];
  };
  placeholder?: string;
}

export function ListFilter(props: Props) {
  const [isFiltersShown, setIsFilterShown] = useState(false);
  const clickHandler = () => setIsFilterShown(!isFiltersShown);

  return (
    <ListFilterContainer>
      <ListFilterHeader>
        {props.register != null && (
          <SearchField
            placeholder={props.placeholder}
            register={props.register}
          />
        )}
        <ListFilterButton onClick={clickHandler} variant={'outlined'}>
          <FilterIcon /> <Span>Фильтр</Span>
        </ListFilterButton>
      </ListFilterHeader>
      <CSSTransition
        in={isFiltersShown}
        classNames="shown"
        unmountOnExit
        timeout={500}>
        <ListFilterContent isShow={isFiltersShown}>
          <Container>{props.children}</Container>
          <Footer>
            <CustomButton type="button" onClick={clickHandler}>
              <TriangleIcon />
              Скрыть фильтр
            </CustomButton>
            {props.count != null && (
              <CountWrapper>
                Найдено{' '}
                <Count>
                  {declOfNum(props.count.amount, props.count.words)}
                </Count>
              </CountWrapper>
            )}
            <ButtonsWrapper>
              {props.showReset && (
                <Button contrast type="reset" variant={'outlined'}>
                  <IconText>
                    <TrashIcon />
                  </IconText>
                  <Span>Сбросить фильтр</Span>
                </Button>
              )}
              {props.submitLabelButton != null &&
                props.submitLabelButton !== '' && (
                  <Button type="submit">
                    <IconText>
                      <FilterIcon />
                    </IconText>
                    <Span>Применить фильтр</Span>
                  </Button>
                )}
            </ButtonsWrapper>
          </Footer>
        </ListFilterContent>
      </CSSTransition>
    </ListFilterContainer>
  );
}
