import {SvgStyled} from './Icons.styles';

export const ExitIcon = () => {
  return (
    <SvgStyled
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="#7D7D7D"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M3.18082 0.757025C1.92469 0.666361 0.75415 1.67144 0.75415 2.99883V21.033C0.75415 22.2938 1.80933 23.3242 3.07225 23.2837C3.0742 23.2837 3.07616 23.2837 3.07812 23.2837C3.293 23.2751 3.5104 23.2379 3.72073 23.1677L8.22341 21.6639C9.13888 21.3585 9.76539 20.4994 9.76539 19.5292V17.2727H9.01714C6.96903 17.273 5.25656 15.5605 5.25684 13.5124V10.5136C5.25656 8.46544 6.96903 6.75885 9.01714 6.75914H9.76539V4.50853C9.76539 3.53841 9.13993 2.67373 8.22341 2.36796L3.72073 0.864127C3.54093 0.804133 3.36026 0.769977 3.18082 0.757025ZM15.2012 5.27438C14.7955 5.25149 14.3894 5.34215 14.0289 5.52233C13.308 5.88281 12.7642 6.60162 12.7642 7.50738V8.2615H9.01714C7.78124 8.2615 6.7592 9.27765 6.7592 10.5136V13.5124C6.7592 14.7483 7.78124 15.7704 9.01714 15.7704H12.7642V16.5186C12.7642 18.3301 14.9257 19.4131 16.3749 18.3262L22.3858 13.8176C22.9701 13.3794 23.3046 12.6841 23.2837 11.9543C23.2642 11.27 22.9356 10.6266 22.3858 10.2143L16.3749 5.70572C16.0126 5.43399 15.6069 5.29728 15.2012 5.27438Z" />
    </SvgStyled>
  );
};
