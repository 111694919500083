import React from 'react';
import {StyledText} from './TextComponent';

export type TextProps = {
  className?: string;
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p1' | 'p2' | 'tips';
  tag?: 'p' | 'h1' | 'h2' | 'h3';
  children?: React.ReactNode;
};

export const Text = (props: TextProps) => <StyledText {...props} />;
