import React, {ReactNode} from 'react';
import {Label, Name, NameWrapper, Wrapper} from './styles';
import {Avatar} from '../Avatar/Avatar';

interface Props {
  avatar?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  label?: string;
  hasLabelPostfix?: boolean;
  children?: ReactNode;
}

export default function User(props: Props) {
  return (
    <Wrapper>
      <Avatar
        src={props.avatar}
        fullName={props.fullName}
        lastName={props.lastName}
        firstName={props.firstName}
      />
      <NameWrapper>
        {props.label != null && props.label !== '' && (
          <Label hasLabelPostfix={props.hasLabelPostfix}>{props.label}</Label>
        )}
        <Name>{props.fullName || `${props.firstName} ${props.lastName}`}</Name>
        {props.children}
      </NameWrapper>
    </Wrapper>
  );
}
