import React from 'react';
import {Link} from 'react-router-dom';
import User from '../../../../../elements/user/component';
import {BlockSidebarWrapper} from '../../../../../elements/BlockSidebarWrapper/BlockSidebarWrapper';
import {Coords} from './Coords/Coords';
import {FieldsBlock} from '../../../../../elements/sidebarInfo/FieldsBlock/FieldsBlock';
import {useGeneralInfo} from './GeneralInfo.hooks';
import {Section} from './GeneralInfo.styles';
import {MainField} from './MainField/MainField';
import {Socials} from './Socials/Socials';

export function GeneralInfo() {
  const {data, loading} = useGeneralInfo();

  if (loading) {
    return null;
  }

  if (data == null) {
    return <p>Ошибка загрузки данных</p>;
  }

  return (
    <BlockSidebarWrapper title={'Подробная информация'} shouldHideTitleBigSize>
      <Section>
        <MainField
          number={data.number}
          isUrgently={data.isUrgently}
          forestInTouch={data.forestInTouch}
          location={data.location}
          smsStatus={data.smsStatus}
          emailStatus={data.emailStatus}
          isReverseLookup={data.isReverseLookup}
          isEducational={data.isEducational}
          newCallCount={data.newCallCount}
          generalFields={data.generalFields}
          parentId={data.parentId}
        />
      </Section>
      {data.parentId && (
        <Section>
          Ссылка на основную заявку: <br />
          <a href={`/applications/${data.parentId}`} target="_blank">
            Заявка #{data.parentId}
          </a>
        </Section>
      )}
      {data.accepted != null && (
        <Section>
          <User
            avatar={data.accepted.avatar}
            fullName={data.accepted.fullName}
            label="Заявку принял"
            hasLabelPostfix
          />
        </Section>
      )}
      {data.regionFields.length !== 0 && (
        <Section>
          <FieldsBlock
            title="Регионы и адрес пропажи"
            fields={data.regionFields}
          />
        </Section>
      )}
      {data.coords != null && (
        <Section>
          <Coords coords={data.coords} />
        </Section>
      )}
      {data.socials.length !== 0 && (
        <Section>
          <Socials links={data.socials} />
        </Section>
      )}
    </BlockSidebarWrapper>
  );
}
