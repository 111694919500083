import React from 'react';
import Preloader from '../../../../elements/Preloader/Preloader';
import {useGroup} from './Main.hooks';

import {Description, Wrapper} from './Main.styles';

export function Main() {
  const {loading, data} = useGroup();

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : data?.group.description != null ? (
        <Description
          dangerouslySetInnerHTML={{__html: data?.group.description}}
        />
      ) : (
        <Description>Описания нет</Description>
      )}
    </Wrapper>
  );
}
