import React from 'react';
import {
  AddButton,
  Wrapper,
  Item,
  DeleteButton,
  Items,
  Container,
} from './TextArrayFields.styles';
import {TextField} from '../TextField/TextField';
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
  useForm,
} from 'react-hook-form';
import {PlusIcon} from '../../icons/PlusIcon';
import {TrashIcon} from '../../icons/TrashIcon';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';

interface Props<T extends FieldValues> {
  label: string;
  placeholder?: string;
  name: Path<T>;
  control: Control<T>;
  rules?: UseControllerProps['rules'];
  mask?: string | Array<string | RegExp>;
}

export function TextArrayFields<T extends FieldValues>(props: Props<T>) {
  const {field, formState} = useController({
    control: props.control,
    name: props.name,
  });

  const {
    register,
    watch,
    formState: formS,
    setValue,
  } = useForm<{newValue: string}>({
    defaultValues: {
      newValue: '',
    },
    mode: 'onChange',
  });
  const newValue = watch('newValue');

  function addItem() {
    if (formS.errors.newValue == null) {
      field.onChange([...field.value, newValue]);
      setValue('newValue', '');
    }
  }

  function deleteItem(index: number) {
    field.onChange(field.value.filter((_: string, i: number) => i !== index));
  }

  return (
    <Container>
      <Wrapper data-element="text-array-fields__input-field">
        <TextField
          register={register('newValue', props.rules)}
          label={props.label}
          placeholder={props.placeholder}
          mask={props.mask}
        />
        <AddButton
          type="button"
          disabled={
            newValue === '' ||
            newValue == null ||
            formState.isSubmitting ||
            formS.errors.newValue != null
          }
          onClick={addItem}>
          <PlusIcon />
        </AddButton>
      </Wrapper>
      {field.value.length !== 0 && (
        <Items>
          {field.value.map((value: string, i: number) => (
            <Item key={`${value}_${i}`}>
              {value}
              <DeleteButton
                type="button"
                disabled={formState.isSubmitting}
                onClick={() => deleteItem(i)}>
                <TrashIcon />
              </DeleteButton>
            </Item>
          ))}
        </Items>
      )}
      {formS.errors.newValue?.message && (
        <ErrorMessage message={formS.errors.newValue?.message} />
      )}
    </Container>
  );
}
