import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {Logo} from '../../elements/Logo/Logo';
import {RegistrationForm} from '../../forms/RegistrationFrom/RegistrationForm';
import {ArrowIcon} from '../../icons/ArrowIcon';
import {useSignUpHandler} from './useSignUpHandler';
import {
  Button,
  FakePageWrapper,
  Header,
  Message,
  MessageWrapper,
  RegistrationFormWrapper,
  Title,
} from './SignUpScreen.styles';

export const SignUpScreen = () => {
  useDocumentTitle('Регистрация');

  const {handleSubmit, successRequest} = useSignUpHandler();
  const navigate = useNavigate();

  if (successRequest) {
    return (
      <FakePageWrapper>
        <MessageWrapper>
          <Logo variant="big" />
          <Message>
            Вы успешно зарегистрировались в системе!
            Ожидайте подтверждение о регистрации со стороны администратора
          </Message>
        </MessageWrapper>
      </FakePageWrapper>
    )
  }

  return (
    <>
      <Header>
        <Button title="Форма авторизации" onClick={() => navigate('/sign-in')}>
          <ArrowIcon />
        </Button>
        <Title>Регистрация</Title>
      </Header>
      <RegistrationFormWrapper>
        <RegistrationForm labelSubmit="Зарегистрироваться" onSubmit={handleSubmit} />
      </RegistrationFormWrapper>
    </>
  );
};
