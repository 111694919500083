import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {useAction, useDict} from './hooks';
import {useForm, useWatch} from 'react-hook-form';
import {FormFields} from './types';
import {Form, PrimaryColumn, Wrapper} from './styles';
import {ListFilter} from '../../../../../elements/ListFilter/ListFilter';
import SelectField from '../../../../../elements/SelectField/SelectField';

const InitState = {
  search: '',
  region: '',
  type: '',
  district: '',
};

interface Props {
  institutionsCount: number;
}

function Filter(props: Props) {
  const [search] = useSearchParams();

  const {loading, region, district, type} = useDict();

  const {submit, handleReset} = useAction();

  const {control, register, reset, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      search: search.get('search') || InitState.search,
      region: search.get('region') || InitState.region,
      district: search.get('district') || InitState.district,
      type: search.get('type') || InitState.type,
    },
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });

  const fields = useWatch<FormFields>({
    control: control,
  });
  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset={Object.values(fields).some((f) => f !== '' && f != null)}
        submitLabelButton="Показать учреждения"
        count={{
          amount: props.institutionsCount,
          words: ['учреждения', 'учреждение', 'учреждений'],
        }}>
        <Wrapper>
          <PrimaryColumn>
            <SelectField
              name="region"
              placeholder="Выберите регион"
              options={region}
              label="Регион"
              control={control}
              disabled={loading}
            />
            <SelectField
              name="district"
              placeholder="Выберите"
              options={
                fields.region === '' ||
                fields.region == null ||
                district[fields.region] == null
                  ? []
                  : district[fields.region]
              }
              label="Район"
              control={control}
              disabled={loading}
            />
            <SelectField
              name="institutionType"
              placeholder="Выберите"
              options={type}
              label="Тип"
              control={control}
              disabled={loading}
            />
          </PrimaryColumn>
        </Wrapper>
      </ListFilter>
    </Form>
  );
}

export default Filter;
