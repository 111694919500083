
import {useState} from 'react';
import {FormFields} from './types';
import {toast} from 'react-toastify';

interface UseMap {
  onAddSuccess?: () => void;
  onAdd: (data: FormFields) => Promise<void>;
}

export function useMapHandler(params: UseMap) {
  const [loading, setLoading] = useState(false)

  function handleAddMap (data: FormFields) {
    setLoading(true)

    params.onAdd(data)
      .then(() => {
        toast('Карта успешно создана!');
        params.onAddSuccess?.();
      })
      .catch(() => toast('Ошибка!', {type: 'error'}))
      .finally(() => setLoading(false));
    }

  return {
    loading,
    handleAddMap
  }
}
