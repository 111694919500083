import {useQuery} from '@apollo/client';
import {TASK_CHANGE_FORM_DICT_QUERY} from '../../../../../gql';
import {ChangeFormDict_task} from '../../../../../gql/task/queries/__generated__/ChangeFormDict_task';
import {Id} from '../../../../../types/auxiliary.types';
import {createDict} from '../../../../../utils/Dict.utils';
import {Option} from '../../../../elements/Select/Select.types';

export function useDict() {
  const {data, loading} = useQuery<ChangeFormDict_task>(
    TASK_CHANGE_FORM_DICT_QUERY
  );

  return {
    loading: loading,
    applications:
      data?.issues == null
        ? []
        : data.issues.data.map((i) => ({value: i.id, label: i.id})),
    calls:
      data?.calls == null
        ? []
        : data.calls.data.map((c) => ({value: c.id, label: c.id})),
    regions: data == null ? [] : createDict(data.allRegions),
    teams:
      data?.allTeams?.reduce(
        (
          acc: Record<
            Id,
            Option & {groups: Record<Id, Option & {users: Option[]}>}
          >,
          team
        ) => ({
          ...acc,
          [team?.id || '']: {
            value: team?.id || '',
            label: team?.name || '',
            groups:
              team?.groups?.reduce(
                (acc1: Record<Id, Option & {users: Option[]}>, group) => ({
                  ...acc1,
                  [group?.id || '']: {
                    value: group?.id || '',
                    label: group?.full_name || '',
                    users:
                      group?.users?.map((user) => ({
                        value: user?.id || '',
                        label: user?.profile.full_name || '',
                      })) || [],
                  },
                }),
                {}
              ) || {},
          },
        }),
        {}
      ) || {},
  };
}
