import {PrivateInfo} from './../../../../../gql/profile/queries/__generated__/PrivateInfo';
import {useQuery} from '@apollo/client';
import {useMutation} from '@apollo/client';
import {useState} from 'react';
import {ADD_DUTY_MUTATION, PRIVATE_INFO_QUERY} from '../../../../../gql';
import {
  AddDuty,
  AddDutyVariables,
} from '../../../../../gql/profile/mutations/__generated__/AddDuty';
import {FormFields} from './types';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

export function useAddDuty(onAddSuccess: () => void) {
  const [commonError] = useState('');
  const navigate = useNavigate();
  const [addDuty, {loading}] = useMutation<AddDuty, AddDutyVariables>(
    ADD_DUTY_MUTATION
  );

  function handleAddDuty(data: FormFields) {
    addDuty({
      variables: {
        input: {
          groupId: Number(data.groupId),
        },
      },
    })
      .then(() => {
        onAddSuccess();
        toast('Текущая смена началась');
        navigate('/?tab=duty');
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      });
  }

  return {handleAddDuty, commonError, loading};
}

export function useVigialGroups() {
  const {data, loading} = useQuery<PrivateInfo>(PRIVATE_INFO_QUERY);
  return {
    loading,
    groups: data == null ? [] : data.me.vigilGroups,
  };
}
