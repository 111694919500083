import {toast} from 'react-toastify';
import {useMutation, useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';
import {DELETE_DUTY_MUTATION, DUTY_LIST_QUERY} from '../../../../../gql';

import {
  DutyList,
  DutyListVariables,
} from '../../../../../gql/profile/queries/__generated__/DutyList';
import {
  DeleteDuty,
  DeleteDutyVariables,
} from '../../../../../gql/profile/mutations/__generated__/DeleteDuty';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../../hooks/useUrl.hooks';
import {Id} from '../../../../../types/auxiliary.types';
import {createDate_DTO} from '../../../../../utils/Date.utils';

function getFirstDay(d: Date): Date {
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(d.setDate(diff));
}

function getLastDay(d: Date): Date {
  const first = getFirstDay(d).getDate();
  const last = first + 6;

  return new Date(d.setDate(last));
}

export function useDuty() {
  const [, setSearchParams] = useSearchParams();

  const currSearchParams = useCurrSearch<{date: string}>() as {date: string};

  const firstDate =
    currSearchParams.date == null
      ? null
      : createDate_DTO(getFirstDay(new Date(currSearchParams.date)));
  const lastDate =
    currSearchParams.date == null
      ? null
      : createDate_DTO(getLastDay(new Date(currSearchParams.date)));

  const {data, loading, refetch} = useQuery<DutyList, DutyListVariables>(
    DUTY_LIST_QUERY,
    {
      variables: {
        dateStart: firstDate as string,
        dateEnd: lastDate as string,
      },
      skip:
        firstDate == null ||
        firstDate === '' ||
        lastDate == null ||
        lastDate === '',
    }
  );

  useUrlParamInitialization(
    {
      date: createDate_DTO(new Date()),
    },
    true
  );

  function handleTodayClick() {
    setSearchParams({
      ...currSearchParams,
      date: createDate_DTO(new Date()),
    });
  }

  function handlePrevClick() {
    const d = new Date(currSearchParams.date);
    const first = getFirstDay(d).getDate();
    const last = first - 7;

    setSearchParams({
      ...currSearchParams,
      date: createDate_DTO(new Date(d.setDate(last))),
    });
  }

  function handleNextClick() {
    const d = new Date(currSearchParams.date);
    const first = getFirstDay(d).getDate();
    const last = first + 7;

    setSearchParams({
      ...currSearchParams,
      date: createDate_DTO(new Date(d.setDate(last))),
    });
  }

  return {
    loading,
    firstDate,
    lastDate,
    list: [...Array(7).keys()].map((i) => {
      const d = new Date(firstDate as string);
      const first = d.getDate();
      const last = first + i;

      const date = createDate_DTO(new Date(d.setDate(last)));

      const list =
        data?.me.shiftsBetween == null
          ? []
          : data.me.shiftsBetween.filter(
              (sh) => createDate_DTO(new Date(sh.date_start as string)) === date
            );
      return {
        date: date,
        list: list.map((l) => ({
          id: l.id,
          dateStart: new Date(l.date_start as string),
          dateEnd: l.date_end == null ? null : new Date(l.date_end as string),
          group: {
            id: l.group.id,
            name: l.group.full_name,
          },
        })),
      };
    }),
    refetch,
    handlePrevClick,
    handleNextClick,
    handleTodayClick,
  };
}

export function useActions(onDeleteSuccess?: () => void) {
  const [deleteDuty] = useMutation<DeleteDuty, DeleteDutyVariables>(
    DELETE_DUTY_MUTATION
  );

  function handleDelete(id: Id) {
    deleteDuty({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        toast('Дежурство успешно удалено!');
        onDeleteSuccess?.();
      })
      .catch((e) => toast(e.message, {type: 'error'}));
  }

  return {
    handleDelete,
  };
}
