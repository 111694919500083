import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28px 24px;
  
  flex: auto;
`;

export const Title = styled.h1`
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 24px;
`;

export const Primary = styled.div`
width: 100%;
`;
