import React from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {Verification} from '../../../modals/Verification/component';
import {useVerificationHandler} from './hooks';

interface Props {
  description: string;
  verificationId: Id;
  onEditSuccess(): void;
  onModalClose(): void;
}

export function EditVerification(props: Props) {
  const {submit, loading} = useVerificationHandler(
    props.verificationId,
    props.onEditSuccess
  );

  return (
    <Verification
      title="Редактировать верификацию"
      defaultValues={{
        description: props.description,
      }}
      loading={loading}
      labelSubmitButton="Редактировать"
      submit={submit}
      onModalClose={props.onModalClose}
    />
  );
}
