import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useOutside} from '../../../hooks/htmlElement.hooks';
import {ChevronIcon} from '../../icons/ChevronIcon';
import {PlusIcon} from '../../icons/PlusIcon';
import {
  Wrapper,
  Container,
  CloseButton,
  Content,
  ChevronButton,
} from './Gallery.styles';

interface Props<T> {
  list: T[];
  renderCard: (data: T) => React.ReactNode;
  onModalClose(): void;
  firstSlide?: number;
}

export function Gallery<T>(props: Props<T>) {
  const {onModalClose} = props;
  const [numberSlide, setNumberSlide] = useState(props.firstSlide || 0);
  const {contentRef} = useOutside<HTMLDivElement>(onModalClose);

  const disabledNext = useMemo(
    () => numberSlide === props.list.length - 1,
    [numberSlide, props.list.length]
  );
  const disabledPrev = useMemo(() => numberSlide === 0, [numberSlide]);

  const onNext = useCallback(
    () => !disabledNext && setNumberSlide((p) => p + 1),
    [disabledNext]
  );
  const onPrev = useCallback(
    () => !disabledPrev && setNumberSlide((p) => p - 1),
    [disabledPrev]
  );

  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      switch (event.key) {
        case 'Escape': {
          onModalClose();
          break;
        }
        case 'ArrowLeft': {
          onPrev();
          break;
        }
        case 'ArrowRight': {
          onNext();
          break;
        }
      }
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [onModalClose, onNext, onPrev]);

  return (
    <Wrapper>
      <Container ref={contentRef}>
        <CloseButton title="Закрыть" type="button" onClick={onModalClose}>
          <PlusIcon />
        </CloseButton>
        <ChevronButton type="button" onClick={onPrev} disabled={disabledPrev}>
          <ChevronIcon />
        </ChevronButton>
        <Content>{props.renderCard(props.list[numberSlide] as T)}</Content>
        <ChevronButton
          type="button"
          rotate
          onClick={onNext}
          disabled={disabledNext}>
          <ChevronIcon />
        </ChevronButton>
      </Container>
    </Wrapper>
  );
}
