import React, {useState} from 'react';
import {Id} from '../../../../../../types/auxiliary.types';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {EditVerification} from '../../modals/EditVerification/component';
import {ChangeButtons} from '../../shared/changeButtons/component';
import {AddVerification} from '../../modals/AddVerification/component';
import {DateField} from '../../shared/date/component';
import {HeadingInfo} from '../../shared/headingInfo/component';
import {useVerifications, useActions} from './hooks';
import {Wrapper, Title, Card, List, Header, Description} from './styles';

export function Verifications() {
  const [modal, setModal] = useState<
    | {
        id: Id;
        description: string;
      }
    | {}
    | null
  >(null);

  const {loading, data, fetch} = useVerifications();
  const {loading: actionLoading, handleDeleteVerification} = useActions(fetch);

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      {modal != null &&
        ('id' in modal ? (
          <EditVerification
            description={'description' in modal ? modal.description : ''}
            verificationId={modal.id}
            onEditSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ) : (
          <AddVerification
            onAddSuccess={() => {
              fetch().then(() => setModal(null));
            }}
            onModalClose={() => setModal(null)}
          />
        ))}
      <AddButton
        disabled={actionLoading || loading}
        buttonLabel="Добавить верификацию"
        typeButton="button"
        addItem={() => setModal({})}
      />
      <List>
        {data.map((card) => (
          <Card key={card.id}>
            <Header>
              <Title>{card.team}</Title>
              <ChangeButtons
                edit={{
                  disabled: actionLoading || loading,
                  onClick: () =>
                    setModal({id: card.id, description: card.description}),
                }}
                delete={{
                  disabled: actionLoading || loading,
                  onClick: () => handleDeleteVerification(card.id),
                }}
              />
              <DateField
                displayShown="small"
                label="Добавлено"
                date={card.createAt}
              />
            </Header>
            <HeadingInfo
              author={card.author}
              dates={[
                {
                  label: 'Добавлено',
                  date: card.createAt,
                },
                {
                  label: 'Отредактировано',
                  date: card.editAt,
                },
              ]}
            />
            <Description>
              {card.description.split('\n').map((item, key) => {
                return (
                  <Description key={key}>
                    {item} <br />
                  </Description>
                );
              })}
            </Description>
          </Card>
        ))}
      </List>
    </Wrapper>
  );
}
