import React from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../elements/Button/Button';
import {ErrorMessage} from '../../elements/ErrorMessage/ErrorMessage';
import {ModalWrapper} from '../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../elements/Preloader/Preloader';
import SelectField from '../../elements/SelectField/SelectField';
import {TextField} from '../../elements/TextField/TextField';
import {useActions, useDict} from './hooks';
import {Form, Fields, Footer} from './styles';

import {FormFields} from './types';

interface Props {
  onTransfer: (data: FormFields) => Promise<void>;
  onChangeSuccess?: () => void;
  onClose: () => void;
}

export default function Transfer(props: Props) {
  const {groups, loading: dictLoading} = useDict();
  const {
    handleTransfer,
    commonMessage,
    loading: actionLoading,
  } = useActions({
    onTransfer: props.onTransfer,
    onChangeSuccess: props.onChangeSuccess,
  });

  const {control, register, formState, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      groupId: '',
      description: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return (
    <ModalWrapper title="Передача в другой отряд" onModalClose={props.onClose}>
      {dictLoading ? (
        <Preloader />
      ) : (
        <Form onSubmit={handleSubmit(handleTransfer)} onReset={props.onClose}>
          <Fields>
            <SelectField
              name="groupId"
              placeholder="Выберите"
              options={groups}
              hasFilter
              label="Отряд"
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
              disabled={dictLoading || actionLoading}
            />
            <TextField
              register={register('description', {
                required: 'Поле обязательно для заполнения.',
              })}
              isTextarea
              rows={2}
              error={formState.errors.description}
              label="Комментарий"
              placeholder="Введите ваше сообщение"
            />
            {commonMessage !== '' && <ErrorMessage message={commonMessage} />}
          </Fields>

          <Footer>
            <Button
              title="Отменить"
              type="reset"
              variant="outlined"
              disabled={formState.isSubmitting || actionLoading}
            />
            <Button
              title="Сохранить"
              type="submit"
              disabled={formState.isSubmitting || actionLoading}
            />
          </Footer>
        </Form>
      )}
    </ModalWrapper>
  );
}
