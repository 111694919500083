import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000;

  margin: 0 24px 80px;
`;

export const RegistrationFormWrapper = styled.div`
  padding-left: 24px;
`;
