import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import Select from 'react-select';
import {Label} from '../../../../elements/Label/Label';
import {ListFilter} from '../../../../elements/ListFilter/ListFilter';
import SelectField from '../../../../elements/SelectField/SelectField';
import {useAction, useDict} from './Filter.hooks';
import {Form} from './Filter.styles';
import {FormFields} from './Filter.types';

export function Filter() {
  const [searchParams] = useSearchParams();
  const {regions} = useDict();
  const {submit, handleReset} = useAction();

  const {register, control, reset, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      search: searchParams.get('search') || '',
      regions: searchParams.getAll('regions') || [],
    },
    mode: 'onBlur',
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(
          {
            search: '',
            regions: [],
          },
          {keepDefaultValues: true}
        );
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset
        submitLabelButton="Применить фильтр">
        <SelectField
          name="regions"
          placeholder="Выберите"
          hasFilter
          options={regions}
          label="Район"
          control={control}
        />
      </ListFilter>
    </Form>
  );
}
