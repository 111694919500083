import {ReactNode, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useCurrSearch} from '../../../../../../hooks/useUrl.hooks';
import {Id} from '../../../../../../types/auxiliary.types';
import {declOfNum} from '../../../../../../utils/String.utils';
import {ChargingIcon} from '../../../../../icons/ChargingIcon';
import {DropIcon} from '../../../../../icons/DropIcon';
import {EmotionStateIcon} from '../../../../../icons/EmotionStateIcon';
import {FileIcon} from '../../../../../icons/FileIcon';
import {FireIcon} from '../../../../../icons/FireIcon';
import {MenIcon} from '../../../../../icons/MenIcon';
import {PhotoIcon} from '../../../../../icons/PhotoIcon';
import {VideoIcon} from '../../../../../icons/VideoIcon';
import {WiFiIcon} from '../../../../../icons/WiFiIcon';
import {WomenIcon} from '../../../../../icons/WomenIcon';
import Status from '../../../../../elements/ChangedStatus/Status';
import {useStatusAction} from './MissingCard.hooks';
import {
  MissingMainInfo,
  PrimaryInfo,
  SecondaryInfo,
  Wrapper,
  GenderWrapper,
  Age,
  Name,
  LabelsWrapper,
  Label,
  LabelText,
  LabelIcon,
  AdditionalInfo,
  Field,
  FieldLabel,
  FieldValue,
  Statuses,
  Button,
  FieldValueWrapper,
  FieldValueLabel,
  Attach,
  FieldValueMain,
  FieldValueLabelWrapper,
  OpacityBlock,
  AttachName,
} from './MissingCard.styles';
import {IGroupStatus} from '../../../../../elements/ChangedStatus/type';

export interface Props {
  id: Id;
  gender: 'm' | 'w';
  fullName: string;
  age: number;
  labels?: {
    fire?: boolean;
    phoneCharging?: number;
    wifiNetwork?: number;
    generalState?: number;
    water?: boolean;
  };
  statuses?: {
    status: Id | undefined;
    subStatus: Id | undefined;
  };
  fields: {
    id: Id;
    name: string;
    value: string;
    preIcon?: ReactNode;
    labels?: {
      id: Id;
      value: string;
    }[];
  }[];
  filesCount?: number;
  photosCount?: number;
  videosCount?: number;
  statusesDict: IGroupStatus[];
  onSuccessChangeStatus?(): void;
}

export function MissingCard(props: Props) {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [showFull, setShowFull] = useState(false);
  const prevSearchParams = useCurrSearch();
  const [, setSearchParams] = useSearchParams();

  const showPhotos = props.photosCount != null && props.photosCount !== 0;
  const showFiles = props.filesCount != null && props.filesCount !== 0;
  const showVideos = props.videosCount != null && props.videosCount !== 0;

  const showFooter =
    'photosCount' in props || 'filesCount' in props || 'videosCount' in props;

  const {
    currentStatus,
    currentSubStatus,
    handleChangeSubStatus,
    handleChangeStatus,
  } = useStatusAction(
    props.id,
    props.statusesDict,
    props.statuses?.status,
    props.statuses?.subStatus,
    props.onSuccessChangeStatus
  );

  return (
    <Wrapper>
      <PrimaryInfo>
        <MissingMainInfo>
          <GenderWrapper gender={props.gender}>
            {props.gender === 'w' && <WomenIcon />}
            {props.gender === 'm' && <MenIcon />}
          </GenderWrapper>
          <Age>{declOfNum(props.age, ['год', 'года', 'лет'])}</Age>
          <Name fullShow>{props.fullName}</Name>
          {props.statuses != null && (
            <Statuses>
              {currentStatus.id != null && (
                <Status
                  id={currentSubStatus.id}
                  options={currentStatus?.subStatuses || []}
                  onChange={handleChangeSubStatus}
                />
              )}
              <Status
                isStatus
                id={currentStatus.id}
                options={props.statusesDict.map((s) => ({
                  id: s.id,
                  name: s.name,
                  statuses: s.statuses.map((ss) => ({
                    id: ss.id,
                    name: ss.name,
                    color: ss.color,
                  })),
                }))}
                onChange={handleChangeStatus}
              />
            </Statuses>
          )}
        </MissingMainInfo>
        <Name>{props.fullName}</Name>
        {props.labels != null && (
          <LabelsWrapper>
            {props.labels.fire != null && (
              <Label title="Костёр">
                <LabelIcon>
                  <FireIcon />
                </LabelIcon>
                <LabelText>{props.labels.fire ? 'Есть!' : 'Нет!'}</LabelText>
              </Label>
            )}
            {props.labels.water != null && (
              <Label title="Вода">
                <LabelIcon>
                  <DropIcon />
                </LabelIcon>
                <LabelText>{props.labels.water ? 'Есть!' : 'Мало'}</LabelText>
              </Label>
            )}
            {props.labels.phoneCharging != null && (
              <Label title="Остаток батареи">
                <LabelIcon>
                  <ChargingIcon />
                </LabelIcon>
                <LabelText>{props.labels.phoneCharging}%</LabelText>
              </Label>
            )}
            {props.labels.wifiNetwork != null && (
              <Label title="Качество связи по шкале от 1 до 10">
                <LabelIcon>
                  <WiFiIcon />
                </LabelIcon>
                <LabelText acceptable={props.labels.wifiNetwork > 5}>
                  {props.labels.wifiNetwork}/10
                </LabelText>
              </Label>
            )}
            {props.labels.generalState != null && (
              <Label title="Вменяемость потерявшегося, оценка оператора по шкале от 1 до 10">
                <LabelIcon>
                  <EmotionStateIcon />
                </LabelIcon>
                <LabelText acceptable={props.labels.generalState > 5}>
                  {props.labels.generalState}/10
                </LabelText>
              </Label>
            )}
          </LabelsWrapper>
        )}
        <AdditionalInfo
          ref={accordionRef}
          scrollHeight={accordionRef?.current?.scrollHeight}
          showFull={showFull}>
          {props.fields.map((f) => (
            <Field key={f.id}>
              <FieldLabel>{f.name}:</FieldLabel>
              <FieldValueWrapper>
                <FieldValueMain>
                  {f.preIcon}
                  <FieldValue>{f.value}</FieldValue>
                </FieldValueMain>
                <FieldValueLabelWrapper>
                  {f.labels?.map((l, i) => (
                    <FieldValueLabel key={`${l.id}_${i}`}>
                      {l.value}
                    </FieldValueLabel>
                  ))}
                </FieldValueLabelWrapper>
              </FieldValueWrapper>
            </Field>
          ))}
          {!showFull && (
            <OpacityBlock showFull={showFull}>
              <Button type="button" onClick={() => setShowFull(true)}>
                Подробнее...
              </Button>
            </OpacityBlock>
          )}
        </AdditionalInfo>
      </PrimaryInfo>
      {showFooter && (
        <SecondaryInfo>
          <Attach
            title="Фото"
            onClick={() =>
              setSearchParams({
                ...prevSearchParams,
                photos: props.id.toString(),
              })
            }>
            <PhotoIcon />
            {showPhotos && (
              <>
                <AttachName>Фото: </AttachName>
                {props.photosCount}
              </>
            )}
          </Attach>
          <Attach
            title="Видео"
            onClick={() =>
              setSearchParams({
                ...prevSearchParams,
                videos: props.id.toString(),
              })
            }>
            <VideoIcon />
            {showVideos && (
              <>
                <AttachName>Видео: </AttachName>
                {props.videosCount}
              </>
            )}
          </Attach>
          <Attach
            title="Файлы"
            onClick={() =>
              setSearchParams({
                ...prevSearchParams,
                files: props.id.toString(),
              })
            }>
            <FileIcon />
            {showFiles && (
              <>
                <AttachName>Файлы: </AttachName>
                {props.filesCount}
              </>
            )}
          </Attach>
        </SecondaryInfo>
      )}
    </Wrapper>
  );
}
