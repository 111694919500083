import styled, {css} from 'styled-components';

export const Wrapper = styled.article`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;

  background: #ffffff;
  border-radius: 8px;

  ${(props) =>
    props.onClick != null &&
    css`
      cursor: pointer;

      &:not([aria-disabled='true']):hover {
        //not found in design
      }

      &[aria-disabled='true'] {
        cursor: not-allowed;

        //not found in design
      }
    `}
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  color: var(--text-color);
`;

export const LabelsWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Label = styled.p`
  margin: 8px 0 0;
  padding: 4px 8px;
  box-sizing: border-box;

  background: var(--scecondary-additional-color);
  border-radius: 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--additional-color);
`;
