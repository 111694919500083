import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {Avatar} from '../Avatar/Avatar';
import * as S from './DutyBlock.styles';

interface Props {
  id: Id;
  dateStart: string;
  dateEnd: string;
  group: string;
  user: string;
}

export default function DutyBlock(props: Props) {
  return (
    <S.Wrapper>
      <S.UserInfo style={{display: 'flex'}}>
        <S.AvatarBlock>
          <Avatar src={undefined} fullName={props.user} />
        </S.AvatarBlock>
        <S.InfoBlock>{props.user}</S.InfoBlock>
      </S.UserInfo>
      <S.Duty>
        {props.dateStart} - {props.dateEnd}
      </S.Duty>
      <S.Badge>{props.group}</S.Badge>
    </S.Wrapper>
  );
}
