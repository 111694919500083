import {ReactNode} from 'react';
import {CallRequests} from './components/CallRequests/component';
import Calls from './components/calls/component';
import {CircumstancesOfLoss} from './components/CircumstancesOfLoss/CircumstancesOfLoss';
import {MissingSection} from './components/MissingSection/MissingSection';
import {SearchMembers} from './components/SearchMembers/SearchMembers';
import {Reports} from './components/Reports/Reports';
import {Files} from './components/Files/Files';
import {Photos} from './components/Photos/Photos';
import Tasks from './components/tasks/component';
import {Verifications} from './components/Verifications/component';
import {Videos} from './components/Videos/component';
import {Maps} from './components/Maps/component';

type TabConfigType = {
  id: string;
  label: string;
  renderComponent?: (
    page?: 'photos' | 'files' | 'videos' | string,
    setTitle?: (value: ReactNode) => void
  ) => ReactNode;
};

export const TabConfig: TabConfigType[] = [
  {
    id: 'missing',
    label: 'Пропавшие',
    renderComponent: (
      page?: 'files' | 'photos' | 'videos' | string,
      setTitle?: (value: ReactNode) => void
    ) => {
      switch (page) {
        case 'files':
          return <Files setTitle={setTitle} />;
        case 'photos':
          return <Photos setTitle={setTitle} />;
        case 'videos':
          return <Videos setTitle={setTitle} />;
        default:
          return <MissingSection />;
      }
    },
  },
  {
    id: 'circumstances',
    label: 'Обстоятельства',
    renderComponent: () => <CircumstancesOfLoss />,
  },
  {
    id: 'tasks',
    label: 'Задачи',
    renderComponent: () => <Tasks />,
  },
  {
    id: 'calls',
    label: 'Звонки',
    renderComponent: () => <Calls />,
  },
  {
    id: 'reports',
    label: 'Отчеты',
    renderComponent: () => <Reports />,
  },
  {
    id: 'maps',
    label: 'Карты',
    renderComponent: () => <Maps />,
  },
  {
    id: 'searchMembers',
    label: 'Участники поиска',
    renderComponent: () => <SearchMembers />,
  },
  // {
  //   id: 'letters',
  //   label: 'Письма',
  // },
  {
    id: 'callRequests',
    label: 'Запросы на прозвоны',
    renderComponent: () => <CallRequests />,
  },
  {
    id: 'verification',
    label: 'Верификация',
    renderComponent: () => <Verifications />,
  },
];
