import React from 'react';
import {SvgStyled} from './Icons.styles';

export const TelegramIcon = () => {
  return (
    <SvgStyled
      width="9"
      height="8"
      viewBox="0 0 9 8"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.60425 0.418698C8.52069 0.359477 8.41096 0.351484 8.32013 0.399078L0.145457 4.66807C0.0488143 4.71857 -0.00822678 4.82176 -0.000233765 4.93039C0.00812257 5.03938 0.0804231 5.13239 0.183242 5.16763L2.4558 5.94441L7.29557 1.80621L3.55048 6.31826L7.35916 7.62003C7.38749 7.62948 7.41729 7.63457 7.44708 7.63457C7.49649 7.63457 7.54554 7.62112 7.58877 7.59497C7.6578 7.55282 7.70467 7.48197 7.71666 7.40241L8.71579 0.681014C8.73068 0.579284 8.68781 0.478282 8.60425 0.418698V0.418698Z" />
    </SvgStyled>
  );
};
