import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import {Button} from '../../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../../elements/Preloader/Preloader';
import SelectField from '../../../../../elements/SelectField/SelectField';
import {TrashIcon} from '../../../../../icons/TrashIcon';
import {TriangleIcon} from '../../../../../icons/TriangleIcon';
import {useInstitutionsOptions} from '../AddDouble/hooks';
import {useCallRequestHandler, useDictionary} from './hooks';
import {
  Buttons,
  Fields,
  Form,
  List,
  Row,
  Tooltip,
  TrashButton,
  TriangleButton,
  Wrapper,
} from './style';
import {FormFields} from './types';

interface Props {
  onAddSuccess?(): void;
  onModalClose(): void;
}

export function AddCallRequest(props: Props) {
  const submit = useCallRequestHandler(props.onAddSuccess, props.onModalClose);
  const {loading: loadingDict, institutions} = useDictionary();

  const {handleSubmit, control, formState, watch, setValue} =
    useForm<FormFields>({
      defaultValues: {
        institutions: [],
        urgency: null,
      },
      mode: 'onSubmit',
    });

  const fields = watch('institutions');

  return (
    <ModalWrapper
      title="Новый запрос на прозвон"
      onModalClose={props.onModalClose}>
      {loadingDict ? (
        <Preloader />
      ) : (
        <Form onSubmit={handleSubmit(submit)} onReset={props.onModalClose}>
          <Tooltip>Выберите учреждения для прозвона</Tooltip>
          <Fields>
            <Wrapper>
              <SelectField
                name="institutions"
                placeholder="Выберите"
                disabled={formState.isSubmitting}
                options={institutions}
                control={control}
                multiple
                hasFilter
                rules={{
                  required: 'Поле обязательно для заполнения.',
                }}
              />
            </Wrapper>
            {fields.length !== 0 && (
              <List>
                {fields.map((f, i) => (
                  <Row key={f}>
                    <span>{i + 1}</span>
                    {i > 0 && (
                      <TriangleButton
                        type="button"
                        title="Переместить вверх"
                        rotate
                        onClick={() => {
                          const current = fields[i];
                          const prev = fields[i - 1];

                          const newValue = [...fields];
                          newValue.splice(i - 1, 2, current, prev);

                          setValue('institutions', newValue);
                        }}>
                        <TriangleIcon />
                      </TriangleButton>
                    )}
                    {i < fields.length - 1 && (
                      <TriangleButton
                        type="button"
                        title="Переместить вниз"
                        onClick={() => {
                          const current = fields[i];
                          const next = fields[i + 1];

                          const newValue = [...fields];

                          newValue.splice(i, 2, next, current);
                          setValue('institutions', newValue);
                        }}>
                        <TriangleIcon />
                      </TriangleButton>
                    )}
                    <span>
                      {(function () {
                        let group: string | number = '';
                        let name: string | number = '';

                        institutions.some((i) => {
                          const n = i.values.find((v) => {
                            if (v.value === f) {
                              name = v.label;
                              return true;
                            }

                            return false;
                          });

                          if (n != null) {
                            group = i.label;
                            return true;
                          }

                          return false;
                        });

                        return `${group} - ${name}`;
                      })()}
                    </span>
                    <TrashButton
                      type="button"
                      title="Удалить"
                      onClick={() => {
                        const newValue = [...fields];
                        newValue.splice(i, 1);

                        setValue('institutions', newValue);
                      }}>
                      <TrashIcon />
                    </TrashButton>
                  </Row>
                ))}
              </List>
            )}
            <SelectField
              name="urgency"
              placeholder="Выберите индикатор срочности"
              disabled={formState.isSubmitting}
              options={[...Array(4).keys()].map((i) => ({
                value: i + 1,
                label: i + 1,
              }))}
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
            />
          </Fields>
          <Buttons>
            <Button
              title="Отмена"
              type="reset"
              variant={'outlined'}
              color={'primary'}
              fullWidth
              disabled={formState.isSubmitting}
            />
            <Button
              title="Добавить"
              type="submit"
              fullWidth
              disabled={formState.isSubmitting}
            />
          </Buttons>
        </Form>
      )}
    </ModalWrapper>
  );
}
