import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {SIGN_IN_MUTATION} from '../../../../gql';
import {
  SignIn,
  SignInVariables,
} from '../../../../gql/mutations/__generated__/SignIn';
import {AuthService} from '../../../../services/authService';
import {AuthFormData} from '../../../forms/AuthFrom/AuthFrom';

export const useSignInHandler = () => {
  const navigate = useNavigate();
  const [signInMutation] = useMutation<SignIn, SignInVariables>(
    SIGN_IN_MUTATION
  );

  const onSubmit = async (data: AuthFormData) => {
    const result = await signInMutation({variables: {input: data}});
    if (result?.data?.signIn?.success) {
      AuthService.authorize(result.data.signIn.success);
      navigate('/main');
    } else {
      throw Error('Token not found');
    }
  };

  return useCallback(onSubmit, [signInMutation, navigate]);
};
