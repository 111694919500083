import React from 'react';
import {Url} from '../../../../../../types/auxiliary.types';
import User from '../../../../../elements/user/component';
import {DateField} from '../date/component';
import {Wrapper, DateWrapper} from './styles';

interface Props {
  author?: {
    fullName: string;
    avatar?: Url;
  };
  dates: {
    label: string;
    date: Date | null;
  }[];
}

export function HeadingInfo(props: Props) {
  return (
    <Wrapper>
      {props.author != null && (
        <User
          avatar={props.author.avatar}
          fullName={props.author.fullName}
          label="Автор"
        />
      )}
      <DateWrapper>
        {props.dates.map((date) => (
          <DateField
            displayShown="big"
            key={date.label}
            label={date.label}
            date={date.date}
          />
        ))}
      </DateWrapper>
    </Wrapper>
  );
}
