import React from 'react';
import {SvgStyled} from './Icons.styles';

export const VideoIcon = () => {
  return (
    <SvgStyled
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7725 0.5805C14.7026 0.535136 14.6225 0.507944 14.5395 0.501395C14.4565 0.494847 14.3731 0.509148 14.297 0.543L10.5 2.2305V1.171C10.4996 0.860553 10.3761 0.562936 10.1566 0.343416C9.93705 0.123897 9.63943 0.000396907 9.32899 0H1.17098C0.860538 0.000396907 0.56292 0.123897 0.343401 0.343416C0.123882 0.562936 0.000381648 0.860553 -1.52588e-05 1.171V8.829C0.000381648 9.13945 0.123882 9.43707 0.343401 9.65658C0.56292 9.8761 0.860538 9.9996 1.17098 10H9.32899C9.63943 9.9996 9.93705 9.8761 10.1566 9.65658C10.3761 9.43707 10.4996 9.13945 10.5 8.829V7.7695L14.297 9.457C14.3609 9.48546 14.43 9.50011 14.5 9.5C14.6326 9.5 14.7598 9.44732 14.8535 9.35355C14.9473 9.25979 15 9.13261 15 9V1C15 0.916656 14.9792 0.834621 14.9395 0.761357C14.8998 0.688093 14.8424 0.625923 14.7725 0.5805Z"
        fill="#E5F1FF"
      />
    </SvgStyled>
  );
};
