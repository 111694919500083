import React from 'react';
import {SvgStyled} from './Icons.styles';

export const ReverseArrowIcon = () => {
  return (
    <SvgStyled
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8L-2.32688e-07 2.70095L3.38745 2.70095L3.38745 0.5L7 3.14514L3.38745 6L3.38745 3.58933L1.04229 3.58933L1.04229 7.11162L5.73261 7.11162L5.73261 8L0 8Z"
        fill="white"
      />
    </SvgStyled>
  );
};
