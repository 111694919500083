import React from 'react';

import {
  Title,
  Wrapper,
  DescriptionText,
  Text,
  ResultTitle,
  Result,
} from './styles';

interface Props {
  description: string;
  result?: string;
}

export function Description(props: Props) {
  return (
    <Wrapper>
      <Title>Описание задачи</Title>
      <DescriptionText>
        {props.description.split('\n').map((item, key) => {
          return (
            <DescriptionText key={key}>
              {' '}
              {item} <br />{' '}
            </DescriptionText>
          );
        })}
      </DescriptionText>
      {props.result != null && props.result !== '' && (
        <Result>
          <ResultTitle>Результат</ResultTitle>
          <Text>{props.result}</Text>
        </Result>
      )}
    </Wrapper>
  );
}
