import React from 'react';
import {SvgStyled} from './Icons.styles';

export const DropIcon = () => {
  return (
    <SvgStyled
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5521 5.76439C5.5521 5.5714 5.5521 4.98406 4.54596 3.39611C4.07342 2.65027 3.60801 2.04277 3.58842 2.01727L3.21669 1.5332L2.84495 2.01727C2.82536 2.04277 2.35998 2.6503 1.88741 3.39611C0.881302 4.98406 0.881302 5.5714 0.881302 5.76439C0.881302 7.05214 1.92894 8.09977 3.21669 8.09977C4.50443 8.09977 5.5521 7.05214 5.5521 5.76439Z"
        fill="#376092"
      />
      <path
        d="M13.9669 1.99639C13.6201 1.44911 13.2782 1.00282 13.2638 0.984069L12.8921 0.5L12.5203 0.984069C12.506 1.00282 12.164 1.44911 11.8173 1.99639C11.4946 2.50565 11.0656 3.24536 11.0656 3.78733C11.0656 4.79444 11.885 5.61374 12.8921 5.61374C13.8991 5.61374 14.7185 4.79441 14.7185 3.78733C14.7185 3.24533 14.2895 2.50565 13.9669 1.99639Z"
        fill="#376092"
      />
      <path
        d="M8.92011 5.23309L8.54838 4.74902L8.17665 5.23309C8.13853 5.28275 7.23242 6.46553 6.31097 7.91986C5.04075 9.92463 4.39669 11.4146 4.39669 12.3484C4.39669 14.6377 6.25916 16.5001 8.54841 16.5001C10.8377 16.5001 12.7001 14.6377 12.7001 12.3484C12.7001 11.4146 12.056 9.92463 10.7858 7.91986C9.86434 6.46553 8.95826 5.28275 8.92011 5.23309Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
