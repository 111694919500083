import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 312px auto;

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  margin-bottom: 46px;

  @media screen and (max-width: 690px) {
    grid-template-columns: repeat(1, 1fr);
  }

  *[data-element='text-array-fields__input-field'],
  *[data-element='text-field'] {
    max-width: 312px;
  }

  & ~ [data-element='text-field'] {
    max-width: 650px;
  }
`;
