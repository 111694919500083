import {
  EditCalling,
  EditCallingVariables,
} from './../../../../gql/call/mutations/__generated__/EditCalling';
import {Id} from './../../../../types/auxiliary.types';
import {
  DeleteCalling,
  DeleteCallingVariables,
} from './../../../../gql/call/mutations/__generated__/DeleteCalling';
import {
  GET_CALLINGS_QUERY,
  CREATE_RINGING_MUTATION,
  CALL_DELETE_CALLING,
  CALL_EDIT_CALLING,
} from './../../../../gql/index';

import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  CreateRinging,
  CreateRingingVariables,
} from '../../../../gql/mutations/__generated__/CreateRinging';
import {
  Calling,
  Calling_callings,
} from '../../../../gql/queries/__generated__/Calling';

import {toast} from 'react-toastify';

export interface FormFields {
  content: string;
  recipientId?: Id;
  callId?: Id;
  issueId?: Id;
}

export function useCreateRinging(
  onAddSuccess?: () => void,
  onModalClose?: () => void
) {
  const [loading, setLoading] = useState(false);

  const [create] = useMutation<CreateRinging, CreateRingingVariables>(
    CREATE_RINGING_MUTATION
  );

  const params = useParams<{callId?: string}>();

  const submit = useCallback(
    async (data: FormFields) =>
      await create({
        variables: {
          input: {
            content: data.content,
            callId: Number(params.callId),
          },
        },
      })
        .then(() => {
          toast('Прозвон успешно добавлен');
          onAddSuccess?.();
          onModalClose?.();
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
        })
        .finally(() => setLoading(false)),

    [create, onAddSuccess, onModalClose, params.callId]
  );

  return {submit, loading};
}

export function useGetCallings() {
  const params = useParams<{callId?: string}>();

  const {data, loading, fetchMore, refetch} = useQuery<
    Calling,
    Calling_callings
  >(GET_CALLINGS_QUERY);

  const fetch = useCallback(() => {
    return fetchMore({
      variables: {
        id: (params.callId || '').toString(),
      },
    });
  }, [fetchMore, params.callId]);

  return {
    loading,
    fetch,
    refetch,
    callings:
      data?.callings?.data == null
        ? []
        : data.callings.data.map((item, i) => ({
            id: item.id || '',
            number: i + 1,
            createdAt: item.created_at || '',
            description: item.content || '',
            issue: item.issue?.id,
            call: item.call?.id,
            recipient: item.recipient?.name || '',
            fullname: item.user?.profile.full_name || '',
          })),
  };
}

export function useCreateRingingApplication(
  onAddSuccess?: () => void,
  onModalClose?: () => void
) {
  const [loading, setLoading] = useState(false);

  const [create] = useMutation<CreateRinging, CreateRingingVariables>(
    CREATE_RINGING_MUTATION
  );

  const params = useParams();

  const submit = useCallback(
    async (data: FormFields) =>
      await create({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            content: data.content,
            recipientId: Number(data.recipientId),
          },
        },
      })
        .then(() => {
          toast('Прозвон успешно добавлен');
          onAddSuccess?.();
          onModalClose?.();
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
        })
        .finally(() => setLoading(false)),

    [create, onAddSuccess, onModalClose, params.applicationId]
  );
  return {submit, loading};
}

export function useActions(onChangeSuccess?: () => void) {
  const [loading, setLoading] = useState(false);

  const [deleteCalling] = useMutation<DeleteCalling, DeleteCallingVariables>(
    CALL_DELETE_CALLING
  );

  function handleDeleteCalling(id: Id) {
    setLoading(true);
    deleteCalling({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        if (onChangeSuccess != null) {
          new Promise(onChangeSuccess).then(() => setLoading(false));
          toast('Прозвон успешно удален');
          setLoading(false);
        }
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      })
      .finally(() => {
        if (onChangeSuccess == null) {
          setLoading(false);
        }
      });
  }
  return {handleDeleteCalling, loading};
}

export function useEditCalling(id: Id, onEditSuccess?: () => void) {
  const [loading, setLoading] = useState(false);

  const [editCallingMutation] = useMutation<EditCalling, EditCallingVariables>(
    CALL_EDIT_CALLING
  );

  const submit = useCallback(
    async (data: FormFields) => {
      await editCallingMutation({
        variables: {
          input: {
            callingId: Number(id),
            content: data.content,
          },
        },
      })
        .then(() => {
          toast('Прозвон успешно изменен');
          setLoading(true);
          onEditSuccess?.();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [editCallingMutation, id, onEditSuccess]
  );
  return {submit, loading};
}
