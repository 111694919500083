import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;

  > *:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    > *:not(:last-child) {
      margin-right: 0;
    }

    > *:not(:first-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Main = styled.div`
  flex: auto;
`;

export const Secondary = styled.div`
  width: 100%;
  max-width: 312px;
  > * {
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1250px) {
    width: auto;
  }
`;

export const List = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000;

  margin: 0 0 18px;
`;
