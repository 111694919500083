import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000;

  padding-bottom: 27px;
  border-bottom: 2px solid #ffffff;
  margin: 28px 24px 32px;
`;

export const ContentWrapper = styled.div`
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  flex: auto;

  display: flex;
  flex-direction: column;
`;

export const Applicant = styled.p`
  margin: 0 24px 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #333;
`;

export const Fields = styled.div`
  flex: auto;
  margin: 0 24px 22px;

  > *:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 2px solid #ffffff;
  }

  > *:not(:first-child) {
    margin-top: 32px;
  }

  > *:last-child {
    margin-top: 16px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px 16px 25px;

  display: flex;
  align-items: center;
  justify-content: end;

  background-color: #fff;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;
