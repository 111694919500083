import React from 'react';
import useInstitutionList, {usePagination} from './Institutions.hooks';
import InstitutionItem from '../../../../elements/InstitutionItem/InstitutionItem';
import Preloader from '../../../../elements/Preloader/Preloader';
import {Title} from '../../../ApplicationsScreen/ApplicationsScreen.styles';
import {Wrapper} from '../UsefulResources/UsefulResources.styles';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import {Pagination} from '../../../../elements/Pagination/Pagination';
import {PaginationWrapper} from '../../../Call/list/styles';
import Filter from './filter/component';

export default function Institutions() {
  useDocumentTitle('Учреждения');

  const {data, loading, pagination} = useInstitutionList();

  const {page, handleSwitchPage} = usePagination();

  return (
    <Wrapper>
      <Title>Учреждения</Title>
      <Filter institutionsCount={pagination.total || 0} />
      {loading ? (
        <Preloader />
      ) : (
        <>
          {data.map((item) => {
            return (
              <InstitutionItem
                key={item.id}
                id={item.id}
                region={item.region}
                district={item.district}
                institutionType={item.type}
                name={item.name}
                address={item.address}
                phoneNumber={item.phoneNumber}
                email={item.email}
                note={item.note}
                websiteLink={item.webSiteLink}
              />
            );
          })}
        </>
      )}
      <PaginationWrapper>
        <Pagination
          page={page}
          total={pagination.lastPage}
          onChange={handleSwitchPage}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
