import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 1rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const TextNote = styled.p`
  margin: 0;
  color: #7d7d7d;
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
export const DeleteButton = styled.button`
  margin-right: 1rem;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  border-radius: 4px;
  margin-right: 10px;

  background-color: var(--primary-color);

  svg {
    fill: #fff;
  }
`;
