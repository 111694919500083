import styled from 'styled-components';
import authBgSvg from '../../../assets/svg/auth-bg.svg';

export const SloganContainer = styled.div`
  min-height: 400px;
  max-width: 500px;
  background: url(${authBgSvg}) no-repeat;
`;

export const SloganText = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 150px 0 0 120px;
`;

export const SloganTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
  display: block;
  padding-bottom: 10px;
`;
