import {useQuery} from '@apollo/client';
import {CALL_REQUEST_LIST_QUERY} from '../../../../gql';
import {
  callRequestList,
  callRequestListVariables,
} from '../../../../gql/callRequest/queries/__generated__/CallRequestList';
import {useSearchParams} from 'react-router-dom';
import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../hooks/useUrl.hooks';
import {FormFields} from './components/filter/types';

export default function useCallRequestList() {
  const currSearchParams = useCurrSearch<
    FormFields & {page: string | null}
  >() as FormFields & {page: string | null};
  const {data, loading} = useQuery<callRequestList, callRequestListVariables>(
    CALL_REQUEST_LIST_QUERY,
    {
      variables: {
        ...(currSearchParams.search != null && {
          issueIdMissingName: String(currSearchParams.search),
        }),
        page: Number(currSearchParams.page),
      },
      fetchPolicy: 'no-cache',
      skip: currSearchParams.page == null,
    }
  );

  return {
    data:
      data?.callRequests?.data == null
        ? []
        : data.callRequests.data.map((ringing) => ({
            id: ringing?.id || '',
            applicationId: ringing?.issue?.id || '',
            urgent: ringing?.urgency,
            region: ringing?.issue?.region?.name || '',
            author: {
              avatar: undefined,
              fullName: ringing?.createdBy?.profile.full_name || '',
            },
            missing:
              ringing?.issue?.missing == null
                ? []
                : ringing?.issue?.missing.map((m) => ({
                    id: m?.id || '',
                    status:
                      m?.status?.name == null
                        ? undefined
                        : {
                            name: m?.status?.name || '',
                            color: m?.status?.color || 'transparent',
                          },
                    fullName: [m?.last_name, m?.first_name, m?.second_name]
                      .filter(Boolean)
                      .join(' '),
                    gender: m?.sex === 'male' ? 'm' : ('w' as 'm' | 'w'),
                    birthday:
                      m?.birthday_string == null
                        ? undefined
                        : m?.birthday_string,
                  })),
          })),
    pagination: {
      lastPage: data?.callRequests?.paginatorInfo.lastPage || 0,
      total: data?.callRequests?.paginatorInfo?.total || 0,
    },
    loading,
  };
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }

  return {
    handleSwitchPage,
    page: Number(searchParams.get('page')),
  };
}
