import {useQuery} from '@apollo/client';
import {USEFUL_REGIONAL_AGREE_QUERY} from '../../../../../gql';
import {
  RegionalAgree,
  RegionalAgree_regionalAgreements_data,
} from '../../../../../gql/helpfulInfo/queries/__generated__/RegionalAgree';

export function useRegionalAgree() {
  const {data, loading} = useQuery<
    RegionalAgree,
    RegionalAgree_regionalAgreements_data
  >(USEFUL_REGIONAL_AGREE_QUERY);
  return {
    loading,
    regionalAgree:
      data?.regionalAgreements?.data == null
        ? []
        : data.regionalAgreements.data.map((item) => ({
            id: item.id || '',
            name: item.name || '',
            text: item.text || '',
          })),
  };
}
