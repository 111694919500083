import React from 'react';
import {useParams} from 'react-router-dom';

import {Wrapper, Home, BreadCrumb} from './BreadCrumbs.styles';

interface Props {
  subtitle: string;
  missingName: string;
}

export function BreadCrumbs(props: Props) {
  const params = useParams<{applicationId?: string}>();

  return (
    <Wrapper>
      <Home
        to={{
          pathname: `/applications/${params.applicationId}`,
          search: 'tab=missing',
        }}>
        Пропавшие
      </Home>
      <BreadCrumb>
        {' > '}
        {props.subtitle} - {props.missingName}
      </BreadCrumb>
    </Wrapper>
  );
}
