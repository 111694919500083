import React from 'react';
import Card from '../../../../../cards/task/component';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {useActions, useTasks} from './hooks';
import {Wrapper, List} from './styles';

export default function Tasks() {
  const {loading, data} = useTasks();
  const {handleAdd} = useActions();

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      <AddButton
        disabled={loading}
        buttonLabel="Добавить задачу"
        typeButton="button"
        addItem={handleAdd}
      />
      <List>
        {data.map((card) => (
          <Card
            key={card.number}
            number={card.number as number}
            isUrgent={card.isUrgent}
            description={card.description}
            region={card.region}
            createAt={card.createAt}
            avatarUrl={card.avatarUrl}
            fullNameResponsible={card.fullNameResponsible}
            status={card.status}
            statusColor={card.statusColor}
            result={card.result}
          />
        ))}
      </List>
    </Wrapper>
  );
}
