import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {Label, LabelsWrapper, Title, Wrapper} from './ShortCard.styles';

interface Props {
  title: string;
  labels: {
    id: Id;
    name: string;
    count: number;
  }[];
  disabled?: boolean;
  onClick?(): void;
}

export function ShortCard(props: Props) {
  return (
    <Wrapper onClick={props.onClick} aria-disabled={props.disabled}>
      <Title>{props.title}</Title>
      <LabelsWrapper>
        {props.labels.map((label) =>
          label.count === 0 ? null : (
            <Label key={label.id}>
              {label.name}: {label.count}
            </Label>
          )
        )}
      </LabelsWrapper>
    </Wrapper>
  );
}
