import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {
  APPLICATION_ADD_SEARCH_MEMBER_QUERY,
  APPLICATION_ADD_SEARCH_MEMBERS_DICT_QUERY,
} from '../../../../../../gql';
import {
  AddSearchMember,
  AddSearchMemberVariables,
} from '../../../../../../gql/application/mutations/__generated__/AddSearchMember';
import {
  AddSearchMembersDict,
  AddSearchMembersDictVariables,
} from '../../../../../../gql/application/queries/__generated__/AddSearchMembersDict';
import {Id} from '../../../../../../types/auxiliary.types';
import {createDateTime_DTO} from '../../../../../../utils/Date.utils';
import {createDict} from '../../../../../../utils/Dict.utils';
import {Option} from '../../../../../elements/Select/Select.types';
import {FormFields} from './AddSearchMember.types';
import {toast} from 'react-toastify';

export const useSearchMemberHandler = (fetchSearchMembers: () => void) => {
  const params = useParams<{applicationId?: string}>();

  const [createSearchMemberMutation] = useMutation<
    AddSearchMember,
    AddSearchMemberVariables
  >(APPLICATION_ADD_SEARCH_MEMBER_QUERY);

  return useCallback(
    async (data: FormFields) => {
      await createSearchMemberMutation({
        variables: {
          input: {
            userId: Number(data.user),
            groupId: Number(data.group),
            issueId: Number(params.applicationId),
            shiftStart: `${
              data.starWorkDate != null && createDateTime_DTO(data.starWorkDate)
            } `,
          },
        },
      })
        .then(() => {
          toast('Участник поиска успешно добавлен!');
          fetchSearchMembers();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [createSearchMemberMutation, fetchSearchMembers, params.applicationId]
  );
};

export function useDictionary() {
  const params = useParams<{applicationId?: string}>();

  const {data, loading} = useQuery<
    AddSearchMembersDict,
    AddSearchMembersDictVariables
  >(APPLICATION_ADD_SEARCH_MEMBERS_DICT_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null,
  });

  const usersDict =
    data?.issue?.teams == null
      ? {}
      : data.issue.teams.reduce(
          (acc: Record<string, Option[]>, team) =>
            team?.groups == null
              ? acc
              : {
                  ...acc,
                  ...team?.groups.reduce((acc1, group) => {
                    if (group == null) return acc1;

                    return {
                      ...acc1,
                      [group.id]:
                        group.users == null
                          ? []
                          : group.users.map((user) => ({
                              label: user?.profile?.full_name || '',
                              value: user?.id || '',
                            })),
                    };
                  }, {}),
                },
          {}
        );

  return {
    loading,
    groups:
      data?.issue.teams == null
        ? []
        : createDict(
            data.issue.teams.reduce(
              (acc: ({id: Id; name: string} | null)[], curr) =>
                curr?.groups == null
                  ? acc
                  : [
                      ...acc,
                      ...curr.groups.map((group) =>
                        group == null
                          ? null
                          : {
                              id: group.id,
                              name: group?.full_name,
                            }
                      ),
                    ],
              []
            )
          ),
    users: data == null ? {} : usersDict,
  };
}
