import React from 'react';
import {SloganContainer, SloganText, SloganTitle} from './Slogan.styles';

const SLOGAN_TITLE = 'Единая IT-система по управлению поисками';
const SLOGAN_TEXT = 'Решение для оперативного реагирования на поиск без вести пропавших,  управления поисково-спасательными работами и синхронизации действий всех участников ПСР.';

export const Slogan = () => {
  return (
    <SloganContainer>
      <SloganText><SloganTitle>{SLOGAN_TITLE}</SloganTitle>{SLOGAN_TEXT}</SloganText>
    </SloganContainer>
  );
};
