import React from 'react';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import Preloader from '../../../../elements/Preloader/Preloader';
import TypographyItem from '../../../../elements/TypographyItem/TypographyItem';
import {Title} from '../../../ApplicationsScreen/ApplicationsScreen.styles';
import {Wrapper} from '../../../DetachmentScreen/DetachmentScreen.styles';
import {useTypography} from './Typography.hooks';

const Typography = () => {
  useDocumentTitle('Типографии');
  const {typography, loading} = useTypography();
  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <Wrapper>
          <Title>Типографии</Title>
          {typography.map((item) => {
            return (
              <TypographyItem
                key={item.id}
                id={item.id}
                region={item.region?.name}
                name={item.name}
                address={item.address}
                startTime={item.startTime}
                endTime={item.endTime}
                maxInColor={item.maxInColor}
                printMaps={item.printMaps}
                note={item.note}
              />
            );
          })}
        </Wrapper>
      )}
    </div>
  );
};

export default Typography;
