import styled from 'styled-components';

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;
`;

export const Img = styled.img`
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 4px;
  object-fit: cover;
`;

export const Description = styled.div`
  width: 100%;
  margin-left: 24px;
`;

export const Tooltip = styled.p`
  margin: 5px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const Buttons = styled.div`
  margin: 24px 0 0;
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
