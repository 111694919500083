import {useQuery} from '@apollo/client';

import {useNavigate, useParams} from 'react-router-dom';
import {
   CALL_TASKS_QUERY,
} from '../../../../../../gql';
import {
  CallTasks,
  CallTasksVariables,
} from '../../../../../../gql/call/queries/__generated__/CallTasks';

export function useTasks() {
  const params = useParams<{callId?: string}>();
  const {data, loading} = useQuery<
    CallTasks,
    CallTasksVariables
  >(CALL_TASKS_QUERY, {
    variables: {
      id: Number(params.callId || ''),
    },
    skip: params.callId == null || params.callId === '',
  });

  return {
    data: data?.call?.tasks == null
      ? []
      : data.call.tasks.map((t) => ({
        number: t?.id || "",
        isUrgent: Boolean(t?.is_urgent),
        description: t?.description || "",
        region: t?.region?.name || "",
        createAt: t?.created_at == null ? null : new Date(t.created_at),
        avatarUrl: undefined,
        fullNameResponsible: t?.responsible?.profile.full_name || "",
        status: t?.status?.name || "",
        statusColor: t?.status?.color || "transparent",
        result: t?.result || undefined,
        })),
    loading
  };
}

export function useActions() {
  const params = useParams<{callId: string}>();

  const navigate = useNavigate();

  function handleAdd() {
    navigate(`/tasks/new?callId=${params.callId}`)
  }

  return {
    handleAdd
  };
}
