import styled from 'styled-components';
import {Logo} from '../../elements/Logo/Logo';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 50%;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 312px;
  width: 100%;
  flex-direction: column;
`;

export const LogoStyled = styled(Logo)`
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const Image = styled.img`
  width: 150px;
`;
