import {TEAM_DUTY_QUERY} from './../../../gql/index';
import {useQuery} from '@apollo/client';

import {useParams, useSearchParams} from 'react-router-dom';
import {TEAM_NAME_QUERY} from '../../../gql';
import {
  ActiveDutyGroup,
  ActiveDutyGroupVariables,
} from '../../../gql/queries/team/__generated__/ActiveDutyGroup';
import {
  TeamName,
  TeamNameVariables,
} from '../../../gql/queries/team/__generated__/TeamName';

import {useUrlParamInitialization} from '../../../hooks/useUrl.hooks';

export function useDetachment() {
  const params = useParams<{teamId?: string}>();

  useUrlParamInitialization({
    tab: 'about',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  const {data, loading} = useQuery<TeamName, TeamNameVariables>(
    TEAM_NAME_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
      skip:
        activeTab == null ||
        activeTab === '' ||
        params.teamId == null ||
        params.teamId === '',
    }
  );

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {
    data,
    loading,
    handleSwitchTab,
    activeTab,
  };
}

export function useActiveDuty() {
  const params = useParams<{teamId?: string}>();

  const {data} = useQuery<ActiveDutyGroup, ActiveDutyGroupVariables>(
    TEAM_DUTY_QUERY,
    {
      variables: {
        id: (params.teamId || '').toString(),
      },
    }
  );
  return {
    duty:
      data?.team.activeShifts == null
        ? []
        : data.team.activeShifts.map((item) => ({
            id: item.id || '',
            dateStart: item.date_start || '',
            dateEnd: item.date_end || '',
            group: item.group.full_name || '',
            user: item.user.profile.full_name || '',
          })),
  };
}
