import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Button} from '../../elements/Button/Button';
import {TextField} from '../../elements/TextField/TextField';
import {Container, ForgotPasswordLink, Row} from './AuthFrom.styles';

export type AuthFormData = {
  email: string;
  password: string;
};

type AuthFromProps = {
  onSubmit: (data: AuthFormData) => Promise<void>;
  style?: React.CSSProperties;
};

const validations = {
  email: {
    minLength: {
      value: 2,
      message: 'Логин слишком короткий.',
    },
    required: 'Логин обязателен для заполнения.',
  },
  password: {
    required: 'Пароль обязателен для заполнения.',
  },
};

export const AuthFrom = ({onSubmit, style}: AuthFromProps) => {
  const {
    register,
    handleSubmit,
    setError,
    resetField,
    formState: {errors},
  } = useForm<AuthFormData>();

  const submitForm: SubmitHandler<AuthFormData> = async (
    data: AuthFormData
  ) => {
    try {
      await onSubmit(data);
    } catch (error) {
      resetField('password');
      setError('email', {
        type: 'server',
      });
      setError('password', {
        type: 'server',
        message: 'Неверный логин или пароль',
      });
    }
  };

  return (
    <form style={style} onSubmit={handleSubmit(submitForm)}>
      <Container>
        <TextField
          register={register('email', {...validations.email})}
          error={errors.email}
          label="Логин"
          placeholder="Введите ваш е-mail"
        />
        <TextField
          register={register('password', {...validations.password})}
          error={errors.password}
          type="password"
          label="Пароль"
          placeholder="Введите ваш пароль"
        />
        <Row>
          <Button type="submit" title="Войти" />
          <ForgotPasswordLink href={'/password-recovery'}>
            Забыли пароль?
          </ForgotPasswordLink>
        </Row>
      </Container>
    </form>
  );
};
