import styled from 'styled-components';

interface IWrapper {
  open: boolean;
}

export const DefaultWrapper = styled.section<IWrapper>`
  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  width: 100%;
  max-width: 336px;
  padding: 24px;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    border: 1px solid #a1b2c8;
    background: transparent;
    max-width: 100%;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
    padding: 10px 16px;
  }
`;

export const FullWrapper = styled.section<IWrapper>`
  padding: 20px 25px;
  width: 100%;
  box-sizing: border-box;

  background-color: var(--scecondary-additional-color);

  h3 {
    color: var(--additional-color);
  }

  @media screen and (max-width: 950px) {
    background-color: ${(p) =>
      p.open ? 'fff' : 'var(--scecondary-additional-color)'};

    padding: 16px;
  }
`;

interface ITitle {
  shouldHideTitleBigSize?: boolean;
}

export const Title = styled.h3<ITitle>`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: var(--text-color);

  display: ${(p) => (p.shouldHideTitleBigSize ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 950px) {
    display: flex;
    cursor: pointer;
  }
`;

export const ChevronButton = styled.div`
  width: 20px;
  height: 20px;
  padding: 0;

  display: none;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 5px;

    path {
      stroke: #000;

      transition: stroke 500ms linear;
    }
  }

  @media screen and (max-width: 950px) {
    display: flex;
  }
`;

interface IContent {
  open: boolean;
  shouldHideTitleBigSize?: boolean;
}

export const Content = styled.div<IContent>`
  margin-top: ${(p) => (p.shouldHideTitleBigSize ? 0 : '15px')};
  display: block;

  @media screen and (max-width: 950px) {
    margin-top: ${(p) => (!p.open ? 0 : '15px')};
    display: ${(p) => (p.open ? 'block' : 'none')};
  }
`;
