import React from 'react';
import {SvgStyled} from './Icons.styles';

export const PlusCircleIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 8.5C12.5 8.76522 12.3946 9.01957 12.2071 9.20711C12.0196 9.39464 11.7652 9.5 11.5 9.5H9V12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13C7.73478 13 7.48043 12.8946 7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12V9.5H4.5C4.23478 9.5 3.98043 9.39464 3.79289 9.20711C3.60536 9.01957 3.5 8.76522 3.5 8.5C3.5 8.23478 3.60536 7.98043 3.79289 7.79289C3.98043 7.60536 4.23478 7.5 4.5 7.5H7V5C7 4.73478 7.10536 4.48043 7.29289 4.29289C7.48043 4.10536 7.73478 4 8 4C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5V7.5H11.5C11.7652 7.5 12.0196 7.60536 12.2071 7.79289C12.3946 7.98043 12.5 8.23478 12.5 8.5ZM16 8.5C16 12.9112 12.4112 16.5 8 16.5C3.58878 16.5 0 12.9112 0 8.5C0 4.08878 3.58878 0.5 8 0.5C12.4112 0.5 16 4.08878 16 8.5ZM14 8.5C14 5.19159 11.3084 2.5 8 2.5C4.69159 2.5 2 5.19159 2 8.5C2 11.8084 4.69159 14.5 8 14.5C11.3084 14.5 14 11.8084 14 8.5Z" />
    </SvgStyled>
  );
};
