import {SvgStyled} from './Icons.styles';

export const NotificationsIcon = () => {
  return (
    <SvgStyled
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.15498 21.1356C7.15493 21.1419 7.15451 21.1481 7.15451 21.1544C7.15451 22.7259 8.42851 23.9999 10.0001 23.9999C11.5716 23.9999 12.8456 22.7259 12.8456 21.1544C12.8456 21.1481 12.8452 21.1419 12.8452 21.1356H7.15498Z" />
      <path d="M19.0853 17.5787L16.5243 13.8167C16.5243 12.665 16.5243 9.85103 16.5243 9.12309C16.5243 5.95776 14.27 3.31926 11.2794 2.72466V1.27933C11.2793 0.572812 10.7066 0 10 0C9.29345 0 8.72069 0.572812 8.72069 1.27933V2.72471C5.73008 3.31936 3.47582 5.95786 3.47582 9.12314C3.47582 10.3623 3.47582 13.0985 3.47582 13.8167L0.91481 17.5788C0.645045 17.975 0.616517 18.488 0.840668 18.9117C1.06482 19.3355 1.50486 19.6005 1.98425 19.6005H18.0158C18.4952 19.6005 18.9352 19.3354 19.1594 18.9117C19.3836 18.4879 19.355 17.975 19.0853 17.5787Z" />
    </SvgStyled>
  );
};
