import React from 'react';
import {MapList} from '../../../../../lists/maps/component';
import {useActions, useApplication} from './hooks';

export function Maps() {
  const {loading, list, fetch} = useApplication();
  const {handleAddMap, handleDeleteMap, loading: actionLoading} = useActions();

  return (
    <MapList
      loading={loading}
      list={list}
      // complex type with Promise and Apollo
      onAdd={handleAddMap as any}
      onDelete={handleDeleteMap as any}
      actionLoading={actionLoading}
      // complex type with Promise and Apollo
      fetch={fetch as any}
    />
  );
}
