import styled from 'styled-components';

export const Form = styled.form``;

export const Fields = styled.div`
  margin-bottom: 32px;

  > *:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 2px solid #ffffff;
  }

  > *:not(:first-child) {
    margin-top: 32px;
  }

  > *:last-child {
    margin-top: 16px;
  }
`;
