import styled from 'styled-components';

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;

  > * {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  margin: 24px 0 0;
  display: flex;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;
