import React from 'react';
import {useSearchParams} from 'react-router-dom';
import Select from 'react-select';
import {Label} from '../../../../elements/Label/Label';
import {BlockWrapper} from '../BlockWrapper/BlockWrapper';
import {Wrapper} from './SquadInfo.styles';
import {
  Control,
  Controller,
  useController,
  UseFormRegister,
} from 'react-hook-form';
import {FormFields} from '../../RegistrationFrom.types';
import {useDict} from './SquadInfo.hooks';

interface Props {
  disabledPrimaryFields?: boolean;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  setField?: any;
}

export const SquadInfo = (props: Props) => {
  const [searchParams] = useSearchParams();
  const hasToken = searchParams.has('token');

  const {squads, roles, regions} = useDict();

  const {field: squadField} = useController({
    name: 'squadInfo.squad',
    control: props.control,
  });

  const {field: rolesField} = useController({
    name: 'squadInfo.roles',
    control: props.control,
  });

  return (
    <BlockWrapper title="Отряд и роль">
      <Wrapper>
        <Label label={'Отряд'}>
          <Controller
            name={'squadInfo.squad'}
            control={props.control}
            render={({field: {onBlur, value, onChange}}) => {
              return (
                <Select
                  placeholder={'Выберите отряд*'}
                  options={squads}
                  noOptionsMessage={() => 'Список пуст!'}
                  loadingMessage={() => 'Идет загрузка...'}
                  value={squads.find((c) => c.value === value)}
                  isDisabled={hasToken || props.disabledPrimaryFields}
                  onBlur={onBlur}
                  onChange={(option) => {
                    props.setField('squadInfo.roles', []);
                    onChange(option?.value);
                  }}
                />
              );
            }}
          />
        </Label>
        <Label label={'Роль или группа'}>
          <Controller
            name={'squadInfo.roles'}
            control={props.control}
            render={({field: {onBlur, value, onChange}}) => {
              return (
                <Select
                  isMulti
                  placeholder={'Выберите группу*'}
                  name={'squadInfo.roles'}
                  noOptionsMessage={() => 'Список пуст!'}
                  value={(roles[squadField.value] || []).filter((f) =>
                    value.includes(f.value)
                  )}
                  loadingMessage={() => 'Идет загрузка...'}
                  onBlur={onBlur}
                  onChange={(options) =>
                    onChange(options?.map((option) => option.value))
                  }
                  options={
                    squadField.value === '' ? [] : roles[squadField.value]
                  }
                />
              );
            }}
          />
        </Label>

        <Label label={'Регион(ы) осуществления деятельности'}>
          <Controller
            control={props.control}
            name={'squadInfo.regions'}
            render={({field: {onBlur, value, onChange}}) => {
              return (
                <Select
                  name={'squadInfo.regions'}
                  placeholder={'Выберите регионы'}
                  loadingMessage={() => 'Идет загрузка...'}
                  noOptionsMessage={() => 'Список пуст!'}
                  isMulti
                  options={regions}
                  value={regions.find((c) => c.value === value)}
                  isDisabled={props.disabledPrimaryFields}
                  onBlur={onBlur}
                  onChange={(options) =>
                    onChange(options?.map((option) => option.value))
                  }
                />
              );
            }}
          />
        </Label>
      </Wrapper>
    </BlockWrapper>
  );
};
