import React from 'react';

import {Wrapper, Label, DateValue} from './styles';

export interface Props {
  label?: string;
  hasLabelPostfix?: boolean;
  date: string | Date | null;
}

export function DateField(props: Props) {
  if (props.date == null) return null;

  const date =
    typeof props.date === 'string' ? new Date(props.date) : props.date;

  return (
    <Wrapper>
      {props.label != null && props.label !== '' && (
        <Label hasLabelPostfix={props.hasLabelPostfix}>{props.label}</Label>
      )}
      <DateValue>
        {date.toLocaleDateString('ru', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })}{' '}
        в{' '}
        {date.toLocaleTimeString('ru', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </DateValue>
    </Wrapper>
  );
}
