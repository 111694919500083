import React from 'react';
import {Container, Content,  Message, Title,} from './ErrorLayout.style';

interface Props {
  title: string;
  message: string;
}

export function ErrorLayout(props: Props) {
  return (
    <Container>
      <Content>
        <Title>{props.title}</Title>
        <Message>{props.message}</Message>
      </Content>
    </Container>
  );
}
