import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface ICheckboxWrapper {
  disabled?: boolean;
}

export const CheckboxWrapper = styled.p<ICheckboxWrapper>`
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color);

  opacity: ${(p) => (p.disabled ? '0.5' : '1')};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

export const FakeCheck = styled.span`
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;

  svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    border-color: var(--additional-color);
  }
`;

export const UnCheck = styled.span`
  display: block;
  margin: 0 15px 0 0;

  width: 16px;
  height: 16px;
  box-sizing: border-box;

  border: 1px solid var(--secondary-text-color);
  border-radius: 4px;

  &:hover {
    border-color: var(--additional-color);
  }
`;
