import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDocumentTitle} from '../../../../../hooks/generateTitle.hooks';
import Preloader from '../../../../elements/Preloader/Preloader';
import useQuestionnaire from './Questionnaires.hooks';
import {
  ItemWrapper,
  QuestionnaireItem,
  Wrapper,
  Title,
  QuestionnaireName,
} from './Questionnaires.styles';

export default function Questionnaires() {
  useDocumentTitle('Опросники');

  const {questionnaires, loading} = useQuestionnaire();

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Опросники</Title>
      {loading ? (
        <Preloader />
      ) : (
        <ItemWrapper>
          {questionnaires.map((item) => {
            return (
              <QuestionnaireItem key={item.id}>
                <QuestionnaireName
                  onClick={() => navigate(`/questionnaires/${item.id}`)}>
                  {item.name}
                </QuestionnaireName>
              </QuestionnaireItem>
            );
          })}
        </ItemWrapper>
      )}
    </Wrapper>
  );
}
