import styled from 'styled-components';

export const Wrapper = styled.div``;

const width = 28;
const height = 16;
const border = 2;

export const CheckBoxLabelStyled = styled.label`
  position: relative;

  display: flex;
  align-items: center;

  width: ${width}px;
  padding: ${border}px;
  box-sizing: border-box;
  border-radius: 150px;

  background-color: var(--primary-border-color);
  transition: background-color 150ms linear;
  cursor: pointer;

  &::after {
    content: '';

    display: block;

    width: ${height - 2 * border}px;
    height: ${height - 2 * border}px;
    border-radius: 50%;
    background-color: var(--primary-color);

    transform: translateX(0);
    transition: transform 150ms linear, background-color 150ms linear;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked ~ label {
    background-color: var(--primary-color);

    &::after {
      transform: translateX(${width - height}px);
      background-color: #fff;
    }
  }

  &:not(:disabled):hover ~ label {
    //not found in design
  }

  &:disabled ~ label {
    cursor: not-allowed;
  }
`;
