import React from 'react';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';
import {Copyright} from '../../elements/Copyright/Copyright';
import {Logo} from '../../elements/Logo/Logo';
import {Slogan} from '../../elements/Slogan/Slogan';
import ResetPassword from '../../forms/ResetPasswordForm/ResetPassword';
import {
  Container,
  AuthSide,
  LinkComeIn,
  Wrapper,
  Footer,
  LogoWrapper,
  QuestionWrapper,
  SloganWrapper,
} from './Password.styles';

const PasswordRecoveryScreen = () => {
  useDocumentTitle('Восстановление пароля');

  return (
    <Wrapper>
      <AuthSide>
        <Container>
          <LogoWrapper>
            <Logo variant="big" />
          </LogoWrapper>
          <ResetPassword />
          <QuestionWrapper>
            Есть логин и пароль?
            <LinkComeIn href={'/sign-in'}>Войти</LinkComeIn>
          </QuestionWrapper>
        </Container>
      </AuthSide>
      <SloganWrapper>
        <Slogan />
      </SloganWrapper>
      <Footer>
        <Copyright />
      </Footer>
    </Wrapper>
  );
};

export default PasswordRecoveryScreen;
