import React from 'react';
import {Id} from '../../../../../../../types/auxiliary.types';
import {Applicant} from '../Applicant/Applicant';
import {FormFields} from '../Applicant/Applicant.types';
import {useHandler} from './hooks';

interface Props {
  id: Id;
  editValues: FormFields;
  onClose: () => void;
  onSuccess?: () => void;
}

export function EditApplicant(props: Props) {
  const {handleSubmit} = useHandler(props.id);

  return (
    <Applicant
      title="Редактирование заявителя"
      defaultValues={props.editValues}
      onClose={props.onClose}
      onSave={(d, error) =>
        handleSubmit(d, error, () => {
          props.onSuccess?.();
          props.onClose();
        })
      }
    />
  );
}
