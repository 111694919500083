import React from 'react';
import {useForm} from 'react-hook-form';
import {ModalWrapper} from '../../../../elements/ModalWrapper/ModalWrapper';
import {Button} from '../../../../elements/Button/Button';
import SelectField from '../../../../elements/SelectField/SelectField';
import {useAddDuty, useVigialGroups} from './hooks';
import {Buttons, FieldsWrapper, Tooltip, Wrapper} from './styles';
import {FormFields} from './types';

interface Props {
  onAddSuccess: () => void;
  onClose: () => void;
}

export function DutyModal(props: Props) {

  const {control, formState, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      groupId: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const {groups: vigialGroups, loading: vigialLoading} = useVigialGroups()

  const vigGroup = vigialGroups == null ? [] : vigialGroups.map(($) => ({
    label: $.full_name,
    value: $.id
  }))

  const {handleAddDuty, commonError, loading} = useAddDuty(props.onAddSuccess);

  return (
    <ModalWrapper title="Начать смену" onModalClose={props.onClose}>
      <Wrapper onSubmit={handleSubmit(handleAddDuty)} onReset={props.onClose}>
        <FieldsWrapper>
          <Tooltip>Выберите группу </Tooltip>
          <SelectField
            name="groupId"
            placeholder="Выберите"
            disabled={formState.isSubmitting || vigialLoading}
            options={vigGroup}
            label="Роль или группа"
            control={control}
            rules={{
              required: 'Поле обязательно для заполнения.',
            }}
          />
        </FieldsWrapper>
        {commonError !== '' && <p>{commonError}</p>}
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            fullWidth
            disabled={loading}
          />
          <Button title="Добавить" type="submit" fullWidth disabled={loading} />
        </Buttons>
      </Wrapper>
    </ModalWrapper>
  );
}
