import React from 'react';
import {Url} from '../../../../../../../types/auxiliary.types';

import {Wrapper, Title, Link, Field, TitleLink} from './Socials.styles';

interface Props {
  links: {
    title: string;
    links: Url[];
  }[];
}

export function Socials(props: Props) {
  return (
    <Wrapper>
      <Title>СМИ и соцсети</Title>
      {props.links.map((link) => (
        <Field key={link.title}>
          <TitleLink>{link.title}:</TitleLink>
          {link.links.map((l) => (
            <Link key={l} href={l} target={'_blank'}>
              {l
                .replace('http://', '')
                .replace('https://', '')
                .substring(0, 25)}
              {l.length > 25 ? '...' : ''} <br />
            </Link>
          ))}
        </Field>
      ))}
    </Wrapper>
  );
}
