import styled from "styled-components";

export const Wrapper = styled.article`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  
  cursor: pointer;
  
  display: flex;
  align-items: center;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`
