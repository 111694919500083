import React from 'react';
import Card from '../../../../../cards/call/component';
import {AddButton} from '../../../../../elements/AddButton/AddButton';
import Preloader from '../../../../../elements/Preloader/Preloader';
import {useActions, useCalls} from './hooks';
import {Wrapper, List} from './styles';

export default function Calls() {
  const {loading, data} = useCalls();
  const {handleAdd} = useActions();

  if (loading) return <Preloader />;

  return (
    <Wrapper>
      <AddButton
        disabled={loading}
        buttonLabel="Добавить звонок"
        typeButton="button"
        addItem={handleAdd}
      />
      <List>
        {data.map((card) => (
          <Card
            key={card.number}
            number={card.number as number}
            isUrgent={card.isUrgent}
            description={card.description}
            status={card.status}
            statusColor={card.statusColor}
            avatarResponsible={card.avatarResponsible}
            fullNameResponsible={card.fullNameResponsible}
            phoneResponsible={card.phoneResponsible}
            region={card.region}
            type={card.type}
            dateTime={card.dateTime}
            ringingCount={card.ringingCount}
            taskCount={card.taskCount}
            commentCount={card.commentCount}
            isTraining
          />
        ))}
      </List>
    </Wrapper>
  );
}
