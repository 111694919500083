import {useQuery} from '@apollo/client';
import {PRIVATE_INFO_QUERY} from '../../../../gql';
import {
  PrivateInfo,
  PrivateInfo_me,
} from '../../../../gql/profile/queries/__generated__/PrivateInfo';

export function useEquipment() {
  const {data, loading, refetch} = useQuery<PrivateInfo, PrivateInfo_me>(
    PRIVATE_INFO_QUERY
  );

  return {
    refetch,
    loading,
    isShiftActive: data?.me.isShiftActive,
    equipments:
      data?.me.equipments == null
        ? []
        : data.me.equipments.map((item) => ({
            id: item?.id || '',
            name: item?.name || '',
            note: item?.note || '',
          })),
  };
}
