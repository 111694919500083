import {
  useCurrSearch,
  useUrlParamInitialization,
} from '../../../../../hooks/useUrl.hooks';
import {FormFields} from './types';
import {useQuery} from '@apollo/client';
import {USEFUL_INSTITUTIONS_QUERY} from '../../../../../gql';
import {
  InstitutionList,
  InstitutionListVariables,
} from '../../../../../gql/helpfulInfo/queries/__generated__/InstitutionList';
import {useSearchParams} from 'react-router-dom';

export default function useInstitutionList() {
  const currSearchParams = useCurrSearch<
    FormFields & {page: string | null}
  >() as FormFields & {page: string | null};

  const {data, loading, refetch} = useQuery<
    InstitutionList,
    InstitutionListVariables
  >(USEFUL_INSTITUTIONS_QUERY, {
    variables: {
      ...(currSearchParams.search != null && {
        nameAddress: currSearchParams.search,
      }),
      ...(currSearchParams.region != null && {
        region: Number(currSearchParams.region),
      }),
      ...(currSearchParams.district != null && {
        district: Number(currSearchParams.district),
      }),
      ...(currSearchParams.institutionType != null && {
        type: Number(currSearchParams.institutionType),
      }),
    },
    fetchPolicy: 'no-cache',
    skip: currSearchParams.page == null || currSearchParams.page === '',
  });

  return {
    data:
      data?.institutions?.data == null
        ? []
        : data.institutions.data.map((item) => ({
            id: item.id,
            region: item.region?.name || '',
            address: item.address || '',
            district: item.district?.name || '',
            name: item.name || '',
            type: item.institution_type?.name || '',
            email: item.email || '',
            phoneNumber: item.phone_number || '',
            webSiteLink: item.website_link || '',
            note: item.note || '',
          })),
    pagination: {
      lastPage:
        data?.institutions?.paginatorInfo.lastPage == undefined
          ? 0
          : data?.institutions.paginatorInfo.lastPage,
      total: data?.institutions?.paginatorInfo.total || 0,
    },
    loading,
    refetch,
  };
}

export function usePagination() {
  const prevState = useCurrSearch();

  useUrlParamInitialization({
    page: 1,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  function handleSwitchPage(value: number) {
    setSearchParams({
      ...prevState,
      page: value.toString(),
    });
  }
  return {
    handleSwitchPage,
    page: Number(searchParams.get('page')),
  };
}
