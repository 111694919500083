import {TASK_QUERY} from './../../../../gql/index';
import {
  Task,
  TaskVariables,
} from './../../../../gql/task/queries/__generated__/Task';
import {useMutation, useQuery} from '@apollo/client';

import {useNavigate, useParams} from 'react-router-dom';
import {TASK_EDIT_MUTATION} from '../../../../gql';
import {
  EditTask,
  EditTaskVariables,
} from '../../../../gql/task/mutations/__generated__/EditTask';
import {FormFields} from '../components/form/types';

import {useCallback} from 'react';
import {toast} from 'react-toastify';

export function useTaskQuery() {
  const params = useParams<{taskId?: string}>();

  const {data, loading, refetch} = useQuery<Task, TaskVariables>(TASK_QUERY, {
    variables: {
      id: Number(params.taskId),
    },
    skip: params.taskId == null || params.taskId === '',
  });

  const fetch = useCallback(() => refetch(), [refetch]);

  return {
    id: params.taskId,
    data:
      data?.task == null
        ? null
        : {
            description: data.task.description || '',
            callId: data.task.call?.id,
            applicationId: data.task.issue?.id,
            regionId: data.task.region?.id,
            teamId: data.task.team?.id,
            groupId: data.task.group?.id,
            userId: data.task.user?.id,
            isUrgently: !!data.task.is_urgent,
            controlGroupId: data.task.controlGroup?.id,
            responsibleId: data.task.user?.id,
          },
    loading,
    fetch,
  };
}

export function useAction(onSuccessChange?: () => void) {
  const [editTask, {loading: editLoading}] = useMutation<
    EditTask,
    EditTaskVariables
  >(TASK_EDIT_MUTATION);

  const params = useParams<{taskId?: string}>();

  const navigate = useNavigate();

  function handleEditTask(data: FormFields) {
    if (params.taskId == null || params.taskId === '') return null;

    editTask({
      variables: {
        input: {
          taskId: Number(params.taskId),
          description: data.description,
          teamId: data.teamId ? Number(data.teamId) : undefined,
          issueId: data.applicationId ? Number(data.applicationId) : undefined,
          callId: data.callId ? Number(data.callId) : undefined,
          regionId: data.regionId ? Number(data.regionId) : undefined,
          groupId: data.groupId ? Number(data.groupId) : undefined,
          isUrgent: data.isUrgently,
          responsibleId: data.userId ? Number(data.userId) : undefined,
          controlGroupId: data.controlGroupId
            ? Number(data.controlGroupId)
            : undefined,
        },
      },
    })
      .then(() => {
        toast('Задача успешно изменена!');
        onSuccessChange?.();
        navigate(`/tasks/${params.taskId}`);
      })
      .catch((e) => {
       toast(e.message, {type: 'error'});
      });
  }

  return {handleEditTask, editLoading};
}
