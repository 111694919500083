import {useQuery} from '@apollo/client';
import {USEFUL_QUESTIONNAIRE_QUERY} from '../../../../../gql';
import {
  Questionnaire,
  Questionnaire_questionnaires_data,
} from '../../../../../gql/helpfulInfo/queries/__generated__/Questionnaire';

export default function useQuestionnaire() {
  const {data, loading} = useQuery<
    Questionnaire,
    Questionnaire_questionnaires_data
  >(USEFUL_QUESTIONNAIRE_QUERY);
  return {
    loading,
    questionnaires:
      data?.questionnaires?.data == null
        ? []
        : data.questionnaires.data.map((item) => ({
            id: item.id || '',
            name: item.name || '',
            text: item.text || '',
          })),
  };
}
