import React, {useMemo} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import DateField from '../../../../../elements/DateField/DateField';
import {ListFilter} from '../../../../../elements/ListFilter/ListFilter';
import SelectField from '../../../../../elements/SelectField/SelectField';
import SwitchField from '../../../../../elements/SwitchField/SwitchField';
import {useAction, useDict} from './hooks';
import {DateWrapper, Form, Wrapper} from './styles';
import {FormFields} from './types';

const InitState = {
  search: '',
  regionId: '',
  statusId: '',
  isUrgently: false,
  createDate: null,
};

interface Props {
  tasksCount: number;
}

export function Filter(props: Props) {
  const currentDate = useMemo(() => new Date(), []);
  const [search] = useSearchParams();

  const {loading, regions, statuses} = useDict();

  const {submit, handleReset} = useAction();

  const {control, register, reset, handleSubmit} = useForm<FormFields>({
    defaultValues: {
      search: search.get('search') || InitState.search,
      regionId: search.get('regionId') || InitState.regionId,
      statusId: search.get('statusId') || InitState.statusId,
      isUrgently: (search.get('isUrgently') || InitState.isUrgently) as boolean,
      createDate: (search.get('createDate') == null
        ? InitState.createDate
        : new Date(search.get('createDate') as string)) as Date | null,
    },
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });

  const fields = useWatch<FormFields>({
    control: control,
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      onReset={() => {
        reset(InitState, {keepDefaultValues: true});
        handleReset();
      }}>
      <ListFilter
        register={register('search')}
        showReset={Object.values(fields).some(
          (f) => f !== '' && f != null && f !== false
        )}
        submitLabelButton="Показать задачи"
        count={{
          amount: props.tasksCount,
          words: ['задача', 'задачи', 'задач'],
        }}
        placeholder="Поиск по номеру или ответственному (отряд, группа, исполнитель)">
        <Wrapper>
          <SelectField
            name="regionId"
            placeholder="Выберите"
            hasFilter
            options={regions}
            label="Регион"
            control={control}
            disabled={loading}
          />
          <SelectField
            name="statusId"
            placeholder="Выберите"
            options={statuses}
            hasFilter
            label="Статус"
            control={control}
            disabled={loading}
          />
          <SwitchField name="isUrgently" control={control} label="Срочная" />
        </Wrapper>
        <DateWrapper>
          <DateField
            label="Дата создания"
            name="createDate"
            maxDate={currentDate}
            control={control}
            format={'dd.MM.yyyy'}
          />
        </DateWrapper>
      </ListFilter>
    </Form>
  );
}
