import React from 'react';
import * as S from './ProfileItem.styles';
import {Text} from '../Text/Text';
import {Id} from '../../../types/auxiliary.types';
import {IconWrapper} from '../../screens/Application/ApplicationView/shared/changeButtons/styles';
import {TrashIcon} from '../../icons/TrashIcon';

interface Props {
  id: Id;
  name: string;
  note?: string;
  loading: boolean;
  handleDelete: (id: Id) => void;
}

export default function ProfileItem(props: Props) {
  return (
    <S.Wrapper>
      <div>
        <Text variant="p1">{props.name}</Text>
        {props.note != null && <S.TextNote>{props.note}</S.TextNote>}
      </div>
      <S.ButtonsWrapper>
        <IconWrapper
          type="button"
          color="orange"
          title="Удалить"
          disabled={props.loading}
          onClick={() => props.handleDelete(props.id)}>
          <TrashIcon />
        </IconWrapper>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
}
