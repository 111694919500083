import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {APPLICATION_CREATE_APPLICANT_MUTATION} from '../../../../../../../gql';
import {
  CreateApplicant,
  CreateApplicantVariables,
} from '../../../../../../../gql/application/mutations/__generated__/CreateApplicant';
import {useServerErrors} from '../../../../../../../hooks/useErrors.hooks';
import {FormFields} from '../Applicant/Applicant.types';

export function useHandler() {
  const params = useParams<{applicationId?: string}>();

  const [createApplicant] = useMutation<
    CreateApplicant,
    CreateApplicantVariables
  >(APPLICATION_CREATE_APPLICANT_MUTATION);

  const {addServerError} = useServerErrors<FormFields>({
    'input.fullName': 'lastName',
    'input.kinship': 'relationDegree',
    'input.contacts': 'phone',
    'input.additionalContacts': 'additionalContact',
    'input.policeStatement': 'police',
    'input.emergencyServices': 'MES',
  });

  const handleSubmit = useCallback(
    async (
      data: FormFields,
      setServerError: UseFormSetError<FormFields>,
      callback?: () => void
    ) => {
      await createApplicant({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            fullName: [data.lastName, data.firstName, data.middleName]
              .filter(Boolean)
              .join(' '),
            kinship: data.relationDegree,
            contacts: data.phone,
            ...(data.MES != null &&
              data.MES !== '' && {
                emergencyServices: data.MES,
              }),
            ...(data.police != null &&
              data.police !== '' && {
                policeStatement: data.police,
              }),
            ...(data.additionalContact != null &&
              data.additionalContact !== '' && {
                additionalContacts: data.additionalContact,
              }),
          },
        },
      })
        .then(() => callback?.())
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [createApplicant, params.applicationId]
  );

  return {handleSubmit};
}
