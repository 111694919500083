import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {
  ADD_CALL_REQUEST_DICT_QUERY,
  ADD_CALL_REQUEST_MUTATION,
} from '../../../../../../gql';
import {CallRequestDict} from '../../../../../../gql/callRequest/queries/__generated__/CallRequestDict';
import {
  CallRequestCreate,
  CallRequestCreateVariables,
} from '../../../../../../gql/callRequest/mutation/__generated__/CallRequestCreate';

import {GroupOptionT} from '../../../../../elements/Select/Select.types';
import {FormFields} from './types';
import {toast} from 'react-toastify';

export const useCallRequestHandler = (
  onAddSuccess?: () => void,
  onModalClose?: () => void
) => {
  const params = useParams<{applicationId?: string}>();

  const [create] = useMutation<CallRequestCreate, CallRequestCreateVariables>(
    ADD_CALL_REQUEST_MUTATION
  );

  return useCallback(
    async (data: FormFields) => {
      if (data.urgency == null) return;

      await create({
        variables: {
          input: {
            issueId: Number(params.applicationId),
            urgency: data.urgency,
            institutions: {
              create: data.institutions.map((d, i) => ({
                call_order: i + 1,
                institution_id: Number(d),
              })),
            },
          },
        },
      })
        .then(() => {
          toast('Прозвон успешно добавлен!');
          onAddSuccess?.();
          onModalClose?.();
        })
        .catch((e) => {
          toast(e.message, {type: 'error'});
          onModalClose?.();
        });
    },
    [create, onAddSuccess, onModalClose, params.applicationId]
  );
};

export function useDictionary() {
  const {data, loading} = useQuery<CallRequestDict>(
    ADD_CALL_REQUEST_DICT_QUERY
  );

  return {
    loading,
    institutions:
      data?.institutions?.data == null
        ? []
        : data.institutions.data.reduce((acc: GroupOptionT[], curr) => {
            const findIndex = acc.findIndex(
              (a) => a.id === curr.institution_type?.id
            );

            if (findIndex === -1) {
              return [
                ...acc,
                {
                  id: curr.institution_type?.id || '',
                  label: curr.institution_type?.name || '',
                  values: [
                    {
                      label: curr?.name || '',
                      value: curr?.id || '',
                    },
                  ],
                },
              ];
            }

            const element = acc[findIndex];

            acc.splice(findIndex, 1, {
              id: element.id,
              label: element.label,
              values: [
                ...element.values,
                {
                  label: curr?.name || '',
                  value: curr?.id || '',
                },
              ],
            });

            return acc;
          }, []),
  };
}
