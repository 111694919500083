import React, {useState} from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import {Id} from '../../../../types/auxiliary.types';
import {Step1} from '../../../forms/ApplicationFrom/pages/new/Step1/Step1';
import {Step2} from '../../../forms/ApplicationFrom/pages/new/Step2/Step2';
import {Step3} from '../../../forms/ApplicationFrom/pages/new/Step3/Step3';
import {Wrapper} from './ApplicationCreate.styles';

export function ApplicationCreateScreen() {
  useDocumentTitle('Создать заявку');

  const [setting, setSetting] = useState<{
    step: number;
    applicationId?: Id;
    applicantId?: Id;
  }>({
    step: 1,
    applicationId: '',
    applicantId: '',
  });

  function renderForm(step: number) {
    switch (step) {
      case 1:
        return (
          <Step1
            onSuccess={(id) =>
              setSetting({
                step: 2,
                applicationId: id,
              })
            }
          />
        );
      case 2:
        return (
          <Step2
            applicationId={setting.applicationId || ''}
            onSuccess={(applicantId) =>
              setSetting((p) => ({
                ...p,
                applicantId,
                step: 3,
              }))
            }
          />
        );
      case 3:
        return (
          <Step3
            applicationId={setting.applicationId || ''}
            applicantId={setting.applicantId || ''}
          />
        );
    }
  }

  return <Wrapper>{renderForm(setting.step)}</Wrapper>;
}
