import {useQuery} from '@apollo/client';

import {useParams} from 'react-router-dom';
import {GROUP_PARTICIPANTS_QUERY} from '../../../../../gql';
import {
  GroupParticipants,
  GroupParticipantsVariables,
} from '../../../../../gql/queries/group/__generated__/GroupParticipants';

export function useGroup() {
  const params = useParams<{groupId?: string}>();

  const {data, loading} = useQuery<
    GroupParticipants,
    GroupParticipantsVariables
  >(GROUP_PARTICIPANTS_QUERY, {
    variables: {
      id: (params.groupId || '').toString(),
    },
    skip: params.groupId == null || params.groupId === '',
  });

  return {
    data,
    loading,
  };
}
