import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  
  display: flex;
  flex-direction: column;
  flex: auto;
`;

export const Main = styled.div`
  flex: auto;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;

  color: #000;

  margin: 0 0 18px;
`;
