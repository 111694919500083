import {Centrifuge, Subscription} from 'centrifuge';
import {fetchWebSocketToken} from '../../../../../../hooks/getToken.hook';

export async function connectCallRequestsCentrifuge(
  handleCallRequestPublication: any
) {
  try {
    const tokenResponse = await fetchWebSocketToken();
    if (!tokenResponse) {
      return new Error('Ошибка получения токена для Centrifugo');
    }
    const token = tokenResponse.data.token;

    const centrifuge = new Centrifuge(
      'wss://cppl.msharks.ru/centrifugo/connection/websocket',
      {
        token: token,
      }
    );

    const subCallRequests: Subscription = centrifuge.newSubscription(
      tokenResponse.data.callRequestsSub.channel as string,
      {
        token: tokenResponse.data.callRequestsSub.token,
      }
    );
    subCallRequests.on('publication', handleCallRequestPublication).subscribe();

    centrifuge.connect();
  } catch (e) {
    console.error('Ошибка подключения к Centrifuge:', e);
    throw e;
  }
}
