import {useMutation, useQuery} from '@apollo/client';
import {useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {
  APPLICATION_CREATE_MAP_MUTATION,
  APPLICATION_DELETE_MAP_MUTATION,
  APPLICATION_MAPS_QUERY,
} from '../../../../../../gql';
import {
  ApplicationMaps,
  ApplicationMapsVariables,
} from '../../../../../../gql/application/queries/__generated__/ApplicationMaps';
import {
  ApplicationCreateMap,
  ApplicationCreateMapVariables,
} from '../../../../../../gql/application/mutations/__generated__/ApplicationCreateMap';
import {
  ApplicationDeleteMap,
  ApplicationDeleteMapVariables,
} from '../../../../../../gql/application/mutations/__generated__/ApplicationDeleteMap';

import {FormFields} from './types';
import {Id} from '../../../../../../types/auxiliary.types';

export function useApplication() {
  const params = useParams<{applicationId: string}>();

  const {data, loading, fetchMore} = useQuery<
    ApplicationMaps,
    ApplicationMapsVariables
  >(APPLICATION_MAPS_QUERY, {
    variables: {
      id: (params.applicationId || '').toString(),
    },
    skip: params.applicationId == null || params.applicationId === '',
  });

  const fetch = useCallback(
    () =>
      fetchMore({
        variables: {
          id: (params.applicationId || '').toString(),
        },
      }),
    [fetchMore, params.applicationId]
  );

  return {
    list:
      data?.issue?.maps == null
        ? []
        : data.issue.maps.map((m) => ({
            id: m?.id || '',
            name: m?.name || '',
            url: m?.url || '',
          })) || [],
    loading,
    fetch,
  };
}

export function useActions() {
  const params = useParams<{applicationId: string}>();

  const [createMapMutation, {loading: createLoading}] = useMutation<
    ApplicationCreateMap,
    ApplicationCreateMapVariables
  >(APPLICATION_CREATE_MAP_MUTATION);

  const [deleteMapMutation, {loading: deleteLoading}] = useMutation<
    ApplicationDeleteMap,
    ApplicationDeleteMapVariables
  >(APPLICATION_DELETE_MAP_MUTATION);

  function handleAddMap(data: FormFields) {
    return createMapMutation({
      variables: {
        input: {
          issueId: Number(params.applicationId),
          ...data,
        },
      },
    });
  }

  function handleDeleteMap(id: Id) {
    return deleteMapMutation({
      variables: {
        id: Number(id),
      },
    });
  }

  return {
    handleAddMap,
    handleDeleteMap,
    loading: createLoading || deleteLoading,
  };
}
