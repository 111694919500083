import {Centrifuge, Subscription} from 'centrifuge';
import {fetchWebSocketToken} from '../../../hooks/getToken.hook';

export async function connectCentrifuge(handlePublication: any) {
  try {
    const tokenResponse = await fetchWebSocketToken();
    if (!tokenResponse) {
      return new Error('Ошибка получения токена для Centrifugo');
    }

    const token = tokenResponse.data.token;

    const centrifuge = new Centrifuge(
      'wss://cppl.msharks.ru/centrifugo/connection/websocket',
      {
        token: token,
      }
    );

    const sub: Subscription = centrifuge.newSubscription(
      tokenResponse.data.mainSub.channel as string,
      {
        token: tokenResponse.data.mainSub.token,
      }
    );

    sub.on('publication', handlePublication).subscribe();

    centrifuge.connect();

    return {centrifuge, tokenResponse};
  } catch (err) {
    console.error('Ошибка подключения к Centrifugo:', err);
    throw err;
  }
}
