import {SvgStyled} from './Icons.styles';

export const InCorrectMark = () => {
  return (
    <SvgStyled
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2.50586 10L10.9937 2" stroke="#EE8C8C" strokeWidth="2" />
      <path d="M2.75 1.75586L10.75 10.2437" stroke="#EE8C8C" strokeWidth="2" />
    </SvgStyled>
  );
};
