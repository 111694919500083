import React from 'react';
import {useDocumentTitle} from '../../../../hooks/generateTitle.hooks';
import Preloader from '../../../elements/Preloader/Preloader';
import {Form} from '../components/form/component';
import {useActions, useCall} from './hooks';
import {Wrapper, Title} from './styles';

export function EditCallScreen() {
  useDocumentTitle('Редактировать звонок');

  const {fetch, loading: fetchLoading, data, id} = useCall();
  const {editCall, loading} = useActions(fetch);

  return (
    <Wrapper>
      <Title>Редактировать звонок #{id}</Title>
      {fetchLoading ? (
        <Preloader />
      ) : (
        <Form
          id={id}
          submitLabel="Редактировать звонок"
          defaultValues={data ?? undefined}
          fetching={fetchLoading || loading}
          onSubmit={editCall}
        />
      )}
    </Wrapper>
  );
}
