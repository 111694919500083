import React from 'react';
import {useForm} from 'react-hook-form';
import { Id } from '../../../../../../types/auxiliary.types';
import { ErrorMessage } from '../../../../../elements/ErrorMessage/ErrorMessage';
import { ModalWrapper } from '../../../../../elements/ModalWrapper/ModalWrapper';
import { Button } from '../../../../../elements/Button/Button';
import Preloader from '../../../../../elements/Preloader/Preloader';
import SelectField from '../../../../../elements/SelectField/SelectField';
import { TextField } from '../../../../../elements/TextField/TextField';

import {useActions, useDict} from './hooks';
import { Form, Fields, Footer, Tooltip } from './styles';

import {FormFields} from './types';

interface Props {
  meta: {
    id: Id,
    type: "issue" | "call" | "task"
  }
  onSendSuccess?: () => Promise<any>
  onClose: () => void
}

export default function SendDataModal(props: Props) {
  const {groups, loading: dictLoading} = useDict()
  const {handleSend, commonMessage, loading: actionLoading} = useActions({
    meta: props.meta,
    onSendSuccess: () =>
      props.onSendSuccess?.().then(() => {
        props.onClose()
      })
  })

  const {
    control,
    register,
    formState,
    handleSubmit,
    setValue,
    watch
  } = useForm<FormFields>({
    defaultValues: {
      groupId: '',
      userId: '',
      description: '',
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur'
  })

  const fields = watch()

  return (
    <ModalWrapper title="Передать участнику" onModalClose={props.onClose}>
      {dictLoading ? (
        <Preloader />
      ) : (
        <Form onSubmit={handleSubmit(handleSend)} onReset={props.onClose}>
          <Fields>
            <Tooltip>Выберите группу и пользователя для передачи</Tooltip>
            <SelectField
              name="groupId"
              placeholder="Выберите"
              options={Object.values(groups).map(({value, label}) => ({
                value,
                label,
              }))}
              label="Роль или группа"
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
              onCallbackChange={() => {
                setValue('userId', '');
              }}
              disabled={dictLoading}
            />
            <SelectField
              name="userId"
              placeholder="Выберите"
              options={
                fields.groupId === '' ||
                fields.groupId == null ||
                groups[fields.groupId] == null
                  ? []
                  : groups[fields.groupId].users
              }
              label="Пользователь"
              control={control}
              rules={{
                required: 'Поле обязательно для заполнения.',
              }}
              disabled={
                dictLoading || fields.groupId === '' || fields.groupId == null
              }
            />
            <TextField
              register={register('description', {
                required: 'Поле обязательно для заполнения.',
              })}
              isTextarea
              rows={2}
              error={formState.errors.description}
              label="Комментарий"
              placeholder="Введите ваше сообщение"
            />
            {commonMessage !== '' && <ErrorMessage message={commonMessage} />}
          </Fields>

          <Footer>
            <Button
              title="Отменить"
              type="reset"
              variant="outlined"
              disabled={formState.isSubmitting || actionLoading}
            />
            <Button
              title="Сохранить"
              type="submit"
              disabled={formState.isSubmitting || actionLoading}
            />
          </Footer>
        </Form>
      )}
    </ModalWrapper>
  );
}
