import {useQuery} from '@apollo/client';

import {PROFILE_STATISTICS_QUERY} from '../../../../gql';
import {ProfileStatistics} from '../../../../gql/profile/queries/__generated__/ProfileStatistics';

export function useStatistics() {
  const {data, loading} = useQuery<ProfileStatistics>(PROFILE_STATISTICS_QUERY);

  return {
    loading,
    data: {
      issues:
        data?.userStatistics?.issues == null
          ? []
          : data.userStatistics.issues.map((i) => ({
              count: i?.count || 0,
              name: i?.name || '',
              id: i?.name || '',
            })),
      tasks:
        data?.userStatistics?.tasks == null
          ? []
          : data.userStatistics.tasks.map((t) => ({
              count: t?.count || 0,
              name: t?.name || '',
              id: t?.name || '',
            })),
      duty:
        data?.userStatistics?.shifts == null
          ? []
          : data.userStatistics.shifts.map((i) => ({
              count: i?.count || 0,
              name: i?.name || '',
              id: i?.name || '',
            })),
    },
  };
}
