import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import {LocationType} from '../../../../../../../constants/location.constants';
import {Id} from '../../../../../../../types/auxiliary.types';
import {DateField} from '../../../../../../cards/elements/dateField/component';
import MainInfo from '../../../../../../cards/elements/mainInfo/component';
import MissingColumn from '../../../../../../cards/elements/missingColumn/component';
import {Badge} from '../../../../../../elements/Badge/Badge';
import {Button} from '../../../../../../elements/Button/Button';
import {
  PrimaryInfo,
  ShortInfo,
  Wrapper,
  SecondaryInfo,
  Footer,
  Comment,
  ButtonWrapper,
} from './styles';

export interface Props {
  number: number;
  isUrgently?: boolean;
  forestInTouch?: boolean;
  location?: LocationType;
  area: string;
  missing: {
    id: Id;
    status?: {
      name: string;
      color: CSSProperties['backgroundColor'];
    };
    fullName: string;
    gender: 'm' | 'w';
    birthday?: string;
  }[];
  lossDate: Date | null;

  ringingNumber?: {
    count: number;
    new?: number;
  };
  tasksNumber?: {
    count: number;
    new?: number;
  };
  institutionCallsNumber?: {
    count: number | string;
    new?: number;
  };
  callsNumber?: {
    count: number;
    new?: number;
  };
  lettersServicesNumber?: {
    count: number;
    new?: number;
  };
  commentNumber?: number;

  loading?: boolean;
  onSendData: () => void;
}

export function Card(props: Props) {
  const navigate = useNavigate();

  const hasSecondaryInfo =
    (props.ringingNumber != null && props.ringingNumber.count !== 0) ||
    (props.tasksNumber != null && props.tasksNumber.count !== 0) ||
    (props.institutionCallsNumber != null &&
      props.institutionCallsNumber.count !== 0) ||
    (props.callsNumber != null && props.callsNumber.count !== 0) ||
    (props.lettersServicesNumber != null &&
      props.lettersServicesNumber.count !== 0) ||
    (props.commentNumber != null && props.commentNumber !== 0);

  return (
    <Wrapper onClick={() => navigate(`/applications/${props.number}`)}>
      <PrimaryInfo>
        <ShortInfo>
          <MainInfo
            number={props.number}
            isUrgent={props.isUrgently}
            location={props.location}
            forestInTouch={props.forestInTouch}
          />
        </ShortInfo>
        <MissingColumn missing={props.missing} area={props.area} />
        <DateField
          date={props.lossDate}
          label="Время пропажи"
          hasLabelPostfix
        />
        <ButtonWrapper>
          <Button
            disabled={props.loading}
            type="button"
            title="Передать"
            onClick={(e) => {
              e.stopPropagation();

              props.onSendData();
            }}
          />
        </ButtonWrapper>
      </PrimaryInfo>
      {hasSecondaryInfo && (
        <Footer>
          <SecondaryInfo>
            {props.ringingNumber != null && props.ringingNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.ringingNumber.new}
                title={`Прозвоны: ${props.ringingNumber.count}`}
              />
            )}
            {props.tasksNumber != null && props.tasksNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.tasksNumber.new}
                title={`Задачи: ${props.tasksNumber.count}`}
              />
            )}
            {props.institutionCallsNumber != null && (
              <Badge
                color="dark_blue"
                counter={props.institutionCallsNumber.new}
                title={`Прозвоны учреждений: ${props.institutionCallsNumber.count}`}
              />
            )}
            {props.callsNumber != null && props.callsNumber.count !== 0 && (
              <Badge
                color="dark_blue"
                counter={props.callsNumber.new}
                title={`Звонки: ${props.callsNumber.count}`}
              />
            )}
            {props.lettersServicesNumber != null &&
              props.lettersServicesNumber.count !== 0 && (
                <Badge
                  color="dark_blue"
                  counter={props.lettersServicesNumber.new}
                  title={`Письма в службы: ${props.lettersServicesNumber.count}`}
                />
              )}
          </SecondaryInfo>

          {props.commentNumber != null && props.commentNumber !== 0 && (
            <Comment>{`Комментарии: ${props.commentNumber}`}</Comment>
          )}
        </Footer>
      )}
    </Wrapper>
  );
}
