import React, {useEffect} from 'react';
import {useOutside} from '../../../hooks/htmlElement.hooks';
import {PlusIcon} from '../../icons/PlusIcon';
import {
  Wrapper,
  Container,
  CloseButton,
  Heading,
  Header,
  Content,
} from './ModalWrapper.styles';

interface Props {
  title: string;
  onModalClose(): void;
  size?: 'default' | 'big';
  children: React.ReactNode;
}

export function ModalWrapper(props: Props) {
  const {onModalClose} = props;
  const {contentRef} = useOutside<HTMLDivElement>(onModalClose);

  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onModalClose();
      }
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [onModalClose]);

  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        props.onModalClose();
      }
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [props, props.onModalClose]);

  return (
    <Wrapper>
      <Container ref={contentRef} size={props.size}>
        <Header>
          <Heading>{props.title}</Heading>
          <CloseButton title="Закрыть" type="button" onClick={onModalClose}>
            <PlusIcon />
          </CloseButton>
        </Header>
        <Content>{props.children}</Content>
      </Container>
    </Wrapper>
  );
}
