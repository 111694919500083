import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const InputWrapper = styled.div``;

interface IContainer {
  items: number | [number, number] | [number, number, number];
}

export const Container = styled.div<IContainer>`
  display: grid;
  grid-template-columns: repeat(
    ${(p) =>
      typeof p.items === 'number'
        ? p.items
        : p.items[2] || p.items[1] || p.items[0]},
    1fr
  );

  grid-gap: 24px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(
      ${(p) =>
        typeof p.items === 'number' ? p.items : p.items[1] || p.items[0]},
      1fr
    );
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(
      ${(p) => (typeof p.items === 'number' ? p.items : p.items[0])},
      1fr
    );
  }
`;
