import React from 'react';
import {SvgStyled} from './Icons.styles';

export const ApplicationIcon = () => {
  return (
    <SvgStyled
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7367 5.83844C19.5455 5.62567 19.2747 5.5043 18.9906 5.5043H1.00938C0.725447 5.5043 0.454534 5.62567 0.263259 5.83844C0.0719844 6.0512 -0.0219875 6.33592 0.00435696 6.62257L0.963759 17.0715C1.01211 17.5977 1.44755 18 1.96878 18H18.0312C18.5525 18 18.9879 17.5976 19.0362 17.0715L19.9956 6.62257C20.022 6.33592 19.928 6.0512 19.7367 5.83844ZM13.3666 15.8569C13.3659 15.8568 13.3653 15.8568 13.3646 15.8569H6.63548C6.07801 15.8569 5.62612 15.3986 5.62612 14.8335C5.62612 13.4437 6.74126 12.313 8.11198 12.313H8.8364C8.09139 11.8957 7.5855 11.0911 7.5855 10.1688C7.5855 8.81891 8.66865 7.72059 10.0001 7.72059C11.3316 7.72059 12.4147 8.81881 12.4147 10.1688C12.4147 11.0911 11.9088 11.8957 11.1638 12.313H11.8882C13.231 12.313 14.3285 13.3981 14.3727 14.7489C14.3749 14.7768 14.3761 14.805 14.3761 14.8335C14.376 15.3987 13.9241 15.8569 13.3666 15.8569ZM18.0582 3.59902C18.0582 4.02302 17.7192 4.36657 17.3011 4.36657H2.69896C2.28088 4.36657 1.94193 4.02302 1.94193 3.59902C1.94193 3.17502 2.28088 2.83146 2.69896 2.83146H17.3011C17.7192 2.83146 18.0582 3.17502 18.0582 3.59902ZM16.0596 0.767556C16.0596 1.19155 15.7207 1.53511 15.3026 1.53511H4.6975C4.27942 1.53511 3.94048 1.19155 3.94048 0.767556C3.94048 0.343558 4.27942 0 4.6975 0H15.3025C15.7207 0 16.0596 0.343558 16.0596 0.767556Z" />
    </SvgStyled>
  );
};
