import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;
  width: 100%;

  display: flex;
  
  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    > *:not(:last-child) {
      margin-right: 0;
    }

    > *:not(:first-child) {
      margin-bottom: 24px;
    }
  }
`;

export const MainColumn = styled.div`
  padding: 24px;
  flex: auto;
  box-sizing: border-box;
`;

export const SecondaryColumn = styled.div`
  width: 336px;

  @media screen and (max-width: 950px) {
   width: 100%;
  }
`;
