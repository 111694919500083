import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Card = styled.article`
  background: #fff;

  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  padding: 24px;
`;
