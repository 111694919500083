import {SvgStyled} from './Icons.styles';

export const EmailIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0001 6.0955L15.5679 1.65559C15.3501 1.33324 15.0571 1.06869 14.7143 0.884732C14.3715 0.700774 13.989 0.602923 13.6 0.59961H2.40022C2.00917 0.599439 1.624 0.694826 1.27823 0.877473C0.932456 1.06012 0.636551 1.32449 0.41626 1.64759L8.0001 6.0955Z"
        fill="#A1B2C8"
      />
      <path
        d="M8.80007 7.47159C8.557 7.61259 8.28108 7.68708 8.00008 7.68758C7.7198 7.68952 7.44394 7.61779 7.2001 7.47959L0.000244141 3.26367V10.9995C0.000244141 11.636 0.253095 12.2465 0.703174 12.6965C1.15325 13.1466 1.76369 13.3995 2.4002 13.3995H13.6C14.2365 13.3995 14.8469 13.1466 15.297 12.6965C15.7471 12.2465 15.9999 11.636 15.9999 10.9995V3.26367L8.80007 7.47159Z"
        fill="#A1B2C8"
      />
    </SvgStyled>
  );
};
