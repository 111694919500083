import styled from 'styled-components';

export const Wrapper = styled.article`
  background: #fff;

  width: 100%;
  box-sizing: border-box;
`;

export const MainInfo = styled.div`
  padding: 15px 16px 16px;
  box-sizing: border-box;

  background: #ffffff;
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    padding: 24px 16px;
    display: block;

    > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

interface IField {
  full?: boolean;
}

export const Field = styled.div<IField>`
  margin-right: 16px;

  flex: ${(p) => (p.full ? 'auto' : 'none')};
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);
`;

export const Value = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--additional-color);
`;

export const Phone = styled.a`
  margin: 0;
  text-decoration: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const PhonesWrapper = styled.div`
  margin: 24px 0;
`;

export const PhoneWrapper = styled.div`
  margin: 10px 0 0;

  display: flex;
`;

export const MissingPhone = styled.p`
  margin: 0 16px 0 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #333;
`;

export const CoordinatesWrapper = styled.div``;

export const Coordinates = styled.div`
  margin: 8px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--additional-color);

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;

    path {
      fill: var(--additional-color);
    }
  }
`;

export const Status = styled.p`
  margin: 0;
  width: fit-content;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: var(--secondary-color);
  color: #fff;
`;

export const Footer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 15px 16px 16px;
  border-radius: 0 0 8px 8px;

  background-color: var(--scecondary-additional-color);

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const FooterField = styled.div`
  display: flex;
`;

export const FooterLabel = styled.p`
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const FooterValue = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;
