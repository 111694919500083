import React from 'react';
import {Avatar} from '../Avatar/Avatar';
import {LeaderBody, AvatarBlock, InfoBlock, LoginBlock} from './Leaders.styles';

interface Props {
  id?: number;
  email?: string;
  fullName?: string;
}

const Leaders = (props: Props) => {
  return (
    <>
      <LeaderBody key={props.id}>
        <AvatarBlock>
          <Avatar src={undefined} fullName={props.fullName} />
        </AvatarBlock>
        <div>
          <InfoBlock>{props.fullName}</InfoBlock>
          <LoginBlock>{props.email}</LoginBlock>
        </div>
      </LeaderBody>
    </>
  );
};

export default Leaders;
