import {CSSProperties} from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-right: 38px;

  flex: 5;
  
  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Missing = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    margin-top: 20px;
  }
`;

interface IStatus {
  color?: CSSProperties["backgroundColor"];
}

export const Status = styled.p<IStatus>`
  margin: 0 10px 0 0;

  height: fit-content;

  background-color: ${(p) => p.color || "white"};
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333;
`;

interface IGenderWrapper {
  gender: 'm' | 'w';
}

const fillGender = {
  m: '--secondary-color',
  w: '--warning-color',
};

export const GenderWrapper = styled.div<IGenderWrapper>`
  margin-right: 10px;

  svg path {
    fill: var(${(p) => fillGender[p.gender]});
  }
`;

export const Age = styled.p`
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const Name = styled.p`
  margin: 0 8px 0 0;
  max-width: 370px;

  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #333;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Area = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);

  @media screen and (max-width: 900px) {
    margin: 8px 0 16px;
  }
`;
