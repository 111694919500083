import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 16px;
`;

export const Photo = styled.img`
  display: block;
  width: 312px;
  height: 312px;
  border-radius: 4px;
  object-fit: cover;
  
  cursor: pointer;
`;

export const PhotoGalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhotoGallery = styled.img`
  width: 100%;
  display: block;
  height: fit-content;
  object-fit: cover;

  border-radius: 4px 4px 0 0;

  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  margin: 0;
  padding: 22px 22px 24px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background: #333;
  border-radius: 0 0 4px 4px;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
 
  color: #FFF;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 24px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
