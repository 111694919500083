import React from 'react';
import {SvgStyled} from './Icons.styles';

export const CalendarIcon = () => {
  return (
    <SvgStyled
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5025 11.802C15.8477 11.802 16.1275 11.5222 16.1275 11.177V4.552C16.1275 3.1735 15.006 2.052 13.6275 2.052H12.815V1.427C12.815 1.08181 12.5352 0.802002 12.19 0.802002C11.8448 0.802002 11.565 1.08181 11.565 1.427V2.052H8.72128V1.427C8.72128 1.08181 8.44147 0.802002 8.09628 0.802002C7.7511 0.802002 7.47128 1.08181 7.47128 1.427V2.052H4.65878V1.427C4.65878 1.08181 4.37897 0.802002 4.03378 0.802002C3.6886 0.802002 3.40878 1.08181 3.40878 1.427V2.052H2.62753C1.24903 2.052 0.127533 3.1735 0.127533 4.552V14.302C0.127533 15.6805 1.24903 16.802 2.62753 16.802H13.6275C15.006 16.802 16.1275 15.6805 16.1275 14.302C16.1275 13.9568 15.8477 13.677 15.5025 13.677C15.1573 13.677 14.8775 13.9568 14.8775 14.302C14.8775 14.9913 14.3168 15.552 13.6275 15.552H2.62753C1.93828 15.552 1.37753 14.9913 1.37753 14.302V4.552C1.37753 3.86275 1.93828 3.302 2.62753 3.302H3.40878V3.927C3.40878 4.27219 3.6886 4.552 4.03378 4.552C4.37897 4.552 4.65878 4.27219 4.65878 3.927V3.302H7.47128V3.927C7.47128 4.27219 7.7511 4.552 8.09628 4.552C8.44147 4.552 8.72128 4.27219 8.72128 3.927V3.302H11.565V3.927C11.565 4.27219 11.8448 4.552 12.19 4.552C12.5352 4.552 12.815 4.27219 12.815 3.927V3.302H13.6275C14.3168 3.302 14.8775 3.86275 14.8775 4.552V11.177C14.8775 11.5222 15.1573 11.802 15.5025 11.802Z"
        fill="#376092"
      />
      <path
        d="M4.03378 7.9895C4.37896 7.9895 4.65878 7.70968 4.65878 7.3645C4.65878 7.01932 4.37896 6.7395 4.03378 6.7395C3.6886 6.7395 3.40878 7.01932 3.40878 7.3645C3.40878 7.70968 3.6886 7.9895 4.03378 7.9895Z"
        fill="#376092"
      />
      <path
        d="M9.47128 7.9895C9.81646 7.9895 10.0963 7.70968 10.0963 7.3645C10.0963 7.01932 9.81646 6.7395 9.47128 6.7395C9.1261 6.7395 8.84628 7.01932 8.84628 7.3645C8.84628 7.70968 9.1261 7.9895 9.47128 7.9895Z"
        fill="#376092"
      />
      <path
        d="M12.19 7.9895C12.5352 7.9895 12.815 7.70968 12.815 7.3645C12.815 7.01932 12.5352 6.7395 12.19 6.7395C11.8449 6.7395 11.565 7.01932 11.565 7.3645C11.565 7.70968 11.8449 7.9895 12.19 7.9895Z"
        fill="#376092"
      />
      <path
        d="M4.03378 10.7083C4.37896 10.7083 4.65878 10.4284 4.65878 10.0833C4.65878 9.73807 4.37896 9.45825 4.03378 9.45825C3.6886 9.45825 3.40878 9.73807 3.40878 10.0833C3.40878 10.4284 3.6886 10.7083 4.03378 10.7083Z"
        fill="#376092"
      />
      <path
        d="M4.03378 13.427C4.37896 13.427 4.65878 13.1472 4.65878 12.802C4.65878 12.4568 4.37896 12.177 4.03378 12.177C3.6886 12.177 3.40878 12.4568 3.40878 12.802C3.40878 13.1472 3.6886 13.427 4.03378 13.427Z"
        fill="#376092"
      />
      <path
        d="M6.75253 7.9895C7.09771 7.9895 7.37753 7.70968 7.37753 7.3645C7.37753 7.01932 7.09771 6.7395 6.75253 6.7395C6.40735 6.7395 6.12753 7.01932 6.12753 7.3645C6.12753 7.70968 6.40735 7.9895 6.75253 7.9895Z"
        fill="#376092"
      />
      <path
        d="M6.75253 10.7083C7.09771 10.7083 7.37753 10.4284 7.37753 10.0833C7.37753 9.73807 7.09771 9.45825 6.75253 9.45825C6.40735 9.45825 6.12753 9.73807 6.12753 10.0833C6.12753 10.4284 6.40735 10.7083 6.75253 10.7083Z"
        fill="#376092"
      />
      <path
        d="M6.75253 13.427C7.09771 13.427 7.37753 13.1472 7.37753 12.802C7.37753 12.4568 7.09771 12.177 6.75253 12.177C6.40735 12.177 6.12753 12.4568 6.12753 12.802C6.12753 13.1472 6.40735 13.427 6.75253 13.427Z"
        fill="#376092"
      />
      <path
        d="M9.47128 10.7083C9.81646 10.7083 10.0963 10.4284 10.0963 10.0833C10.0963 9.73807 9.81646 9.45825 9.47128 9.45825C9.1261 9.45825 8.84628 9.73807 8.84628 10.0833C8.84628 10.4284 9.1261 10.7083 9.47128 10.7083Z"
        fill="#376092"
      />
      <path
        d="M12.19 10.7083C12.5352 10.7083 12.815 10.4284 12.815 10.0833C12.815 9.73807 12.5352 9.45825 12.19 9.45825C11.8449 9.45825 11.565 9.73807 11.565 10.0833C11.565 10.4284 11.8449 10.7083 12.19 10.7083Z"
        fill="#376092"
      />
      <path
        d="M9.47128 13.427C9.81646 13.427 10.0963 13.1472 10.0963 12.802C10.0963 12.4568 9.81646 12.177 9.47128 12.177C9.1261 12.177 8.84628 12.4568 8.84628 12.802C8.84628 13.1472 9.1261 13.427 9.47128 13.427Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};
