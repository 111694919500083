import {useMutation, useQuery} from '@apollo/client';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  APPLICATION_ADD_COMMENT_MUTATION,
  APPLICATION_CHAT_QUERY,
  APPLICATION_DELETE_COMMENT_MUTATION,
} from '../../../../gql';
import {
  ApplicationCreateComment,
  ApplicationCreateCommentVariables,
} from '../../../../gql/application/mutations/__generated__/ApplicationCreateComment';
import {
  ApplicationChat,
  ApplicationChatVariables,
} from '../../../../gql/application/queries/__generated__/ApplicationChat';
import {useUrlParamInitialization} from '../../../../hooks/useUrl.hooks';
import {FormFields} from '../../../elements/chat/components/form/types';
import {toast} from 'react-toastify';
import {
  DeleteComment,
  DeleteCommentVariables,
} from '../../../../gql/application/mutations/__generated__/DeleteComment';
import {Id} from '../../../../types/auxiliary.types';

export const useTab = () => {
  useUrlParamInitialization({
    tab: 'missing',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {
    handleSwitchTab,
    activeTab,
  };
};

export function useChat() {
  const {applicationId} = useParams<{applicationId: string}>();
  const {data, loading, refetch} = useQuery<
    ApplicationChat,
    ApplicationChatVariables
  >(APPLICATION_CHAT_QUERY, {
    variables: {
      id: applicationId as string,
    },
    skip: false,
  });

  const [addMessageMutation, {loading: sendingMessage}] = useMutation<
    ApplicationCreateComment,
    ApplicationCreateCommentVariables
  >(APPLICATION_ADD_COMMENT_MUTATION);

  function handleAddMessage(data: FormFields) {
    return addMessageMutation({
      variables: {
        input: {
          description: data.message,
          issueId: Number(applicationId),
        },
      },
    }).then(() => {
      refetch();
      toast('Ваш комментарий добавлен', {type: 'success'});
    });
  }

  return {
    comments:
      data?.issue.comments == null
        ? []
        : data.issue.comments.map((item) => {
            return {
              id: item?.id || '',
              fullName: item?.user.profile.full_name || '',
              description: item?.description || '',
              date: item?.created_at,
            };
          }),
    loading,
    handleAddMessage,
    refetch,
    sendingMessage,
  };
}

export const useActions = (onChangeSuccess?: () => void) => {
  const [deleteComment] = useMutation<DeleteComment, DeleteCommentVariables>(
    APPLICATION_DELETE_COMMENT_MUTATION
  );

  function handleDeleteComment(id: Id) {
    deleteComment({
      variables: {
        id: Number(id),
      },
    }).then(() => {
      toast('Комментарий успешно удален');
      onChangeSuccess?.();
    });
  }
  return {handleDeleteComment};
};
