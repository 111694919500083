import React, {useState} from 'react';
import {Button} from '../../../elements/Button/Button';
import Preloader from '../../../elements/Preloader/Preloader';
import {useTechnics} from './Technics.hooks';
import * as S from './Technics.styles';
import ProfileItem from '../../../elements/ProfileItem/ProfileItem';
import AddTechniqueForm from './modals/add/component';
import {useActions} from './modals/delete/hook';

export default function Equipments() {
  const {technics, loading, refetch} = useTechnics();

  const {loading: actionLoading, handleDelete} = useActions(refetch);

  const [showModal, setShowModal] = useState(false);

  if (loading) return <Preloader />;

  return (
    <S.Wrapper>
      {showModal ? (
        <AddTechniqueForm
          onAddSuccess={() => {
            refetch().then(() => setShowModal(false));
          }}
          onClose={() => setShowModal(false)}
        />
      ) : null}
      <Button fullWidth variant="outlined" onClick={() => setShowModal(true)}>
        Добавить технику
      </Button>
      <S.ContentWrapper>
        {technics.map((technics) => {
          return (
            <ProfileItem
              loading={actionLoading}
              id={technics.id}
              handleDelete={() => handleDelete(technics.id)}
              key={technics.id}
              name={technics.name}
              note={technics.note}
            />
          );
        })}
      </S.ContentWrapper>
    </S.Wrapper>
  );
}
