import React from 'react';
import {SvgStyled} from './Icons.styles';

export const PlusIcon = () => {
  return (
    <SvgStyled
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.5H10" stroke="white" strokeWidth="2" />
      <path d="M5 0.5L5 10.5" stroke="white" strokeWidth="2" />
    </SvgStyled>
  );
};
