import {ApplicationIcon} from '../../icons/ApplicationsIcon';
import {HomeIcon} from '../../icons/HomeIcon';
import {CallIcon} from '../../icons/CallIcon';
import {InfoIcon} from '../../icons/InfoIcon';
import {RingingIcon} from '../../icons/RingingIcon';
import {TaskIcon} from '../../icons/TaskIcon';
import {UserIcon} from '../../icons/UserIcon';
import {UsersIcon} from '../../icons/UsersIcon';
import {MenuConfig as MenuConfigType} from './Menu.types';

export const MenuConfig: MenuConfigType['items'] = [
  {
    id: 'main',
    name: 'Главная',
    linkTo: '/main',
    icon: HomeIcon,
  },
  {
    id: 'applications',
    name: 'Заявки',
    linkTo: '/applications',
    icon: ApplicationIcon,
  },
  {
    id: 'tasks',
    name: 'Задачи',
    linkTo: '/tasks',
    icon: TaskIcon,
  },
  {
    id: 'calls',
    name: 'Звонки',
    linkTo: '/calls',
    icon: CallIcon,
  },
  {
    id: 'callRequests',
    name: 'Запросы на прозвоны',
    linkTo: '/callRequests',
    icon: RingingIcon,
  },
  {
    id: 'users',
    name: 'Пользователи',
    linkTo: '/users',
    icon: UserIcon,
  },
  {
    id: 'teams',
    name: 'Отряды',
    linkTo: '/teams',
    icon: UsersIcon,
  },
  {
    id: 'information',
    name: 'Полезная информация',
    linkTo: '/helpfulInfo',
    icon: InfoIcon,
  },
];
