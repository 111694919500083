import {Id} from './../../../../../../types/auxiliary.types';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {DELETE_ISSUE_MUTATION} from '../../../../../../gql';
import {toast} from 'react-toastify';
import {
  DeleteIssue,
  DeleteIssueVariables,
} from '../../../../../../gql/application/mutations/__generated__/DeleteIssue';
import {useState} from 'react';

export function useDeleteIssue(onChangeSuccess?: () => void) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [deleteIssue] = useMutation<DeleteIssue, DeleteIssueVariables>(
    DELETE_ISSUE_MUTATION
  );
  function handleDeleteIssue(id: Id) {
    setLoading(true);

    deleteIssue({
      variables: {
        id: Number(id),
      },
    })
      .then(() => {
        toast('Заявка успешно удалена.');
        if (onChangeSuccess != null) {
          new Promise(onChangeSuccess).then(() => setLoading(false));
        }
        navigate('/applications');
      })
      .finally(() => {
        if (onChangeSuccess == null) {
          setLoading(false);
        }
      });
  }
  return {
    loading,
    handleDeleteIssue,
  };
}
