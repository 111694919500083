import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Arrow = styled.div`
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: white;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
`;

export const NotificationsDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  box-sizing: border-box;
`;

export const EmptyNotification = styled.div`
  text-align: center;
  color: #777;
`;

export const NotificationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const NotificationItem = styled.li`
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }

  &:last-child::after {
    display: none;
  }
`;
