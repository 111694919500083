import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';
import {DateField} from '../../../../../../cards/elements/dateField/component';
import MainInfoCard from '../../../../../../cards/elements/mainInfo/component';
import { Badge } from '../../../../../../elements/Badge/Badge';
import {Button} from '../../../../../../elements/Button/Button';
import {
  Status,
  Wrapper,
  MainInfo,
  PersonInfo,
  ResponseName,
  SecondaryInfo,
  TypeWrapper,
  ValueText,
  DateWrapper,
  SecondaryInfoBlock,
  Badges, Comment, ButtonWrapper,
} from './styles';

interface Props {
  number: number;
  isUrgent: boolean;
  status: string;
  statusColor: CSSProperties["backgroundColor"];
  fullNameResponsible: string
  phoneResponsible: string
  region: string
  type: string
  dateTime: Date | null;
  ringingCount?: number;
  taskCount?: number;
  commentCount?: number;
  loading?: boolean;
  onSendData: () => void
}

export default function Card(props: Props) {
  const navigate = useNavigate()

  const showRingingCount = props.ringingCount != null && props.ringingCount !== 0
  const showTaskCount = props.taskCount != null && props.taskCount !== 0
  const showCommentCount = props.commentCount != null && props.commentCount !== 0

  return (
    <Wrapper onClick={() => navigate(`/calls/${props.number}`)}>
      <MainInfo>
        <MainInfoCard
          number={props.number}
          isUrgent={props.isUrgent}
          status={props.status}
          statusColor={props.statusColor}
        />

        <PersonInfo>
          <ResponseName>{props.fullNameResponsible}</ResponseName>
          <SecondaryInfo>{props.phoneResponsible}</SecondaryInfo>
          <SecondaryInfo>{props.region}</SecondaryInfo>
        </PersonInfo>

        <TypeWrapper>
          {props.type !== "" && (
            <>
              <SecondaryInfo>Тип:</SecondaryInfo>
              <ValueText>{props.type}</ValueText>
            </>
          )}
        </TypeWrapper>
        <DateWrapper>
          <DateField
            date={props.dateTime}
            label="Дата создания"
            hasLabelPostfix
          />
        </DateWrapper>

        {props.status !== "" && (
          <Status hideSmallSize color={props.statusColor}>
            {props.status}
          </Status>
        )}
        <ButtonWrapper>
          <Button
            disabled={props.loading}
            type="button"
            title="Передать"
            onClick={(e) => {
              e.stopPropagation();

              props.onSendData()
            }}
          />
        </ButtonWrapper>
      </MainInfo>

      {(showRingingCount || showTaskCount || showCommentCount) && (
        <SecondaryInfoBlock>
          <Badges>
            {showRingingCount && (
              <Badge
                color="dark_blue"
                title={`Прозвоны: ${props.ringingCount}`}
              />
            )}
            {showTaskCount && (
              <Badge
                color="dark_blue"
                title={`Задачи: ${props.taskCount}`}
              />
            )}
          </Badges>
          {showCommentCount && (
            <Comment>Комментарии: {props.commentCount}</Comment>
          )}
        </SecondaryInfoBlock>
      )}
    </Wrapper>
  )
}
