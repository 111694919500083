import React, {ReactNode, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {VideoCard} from '../../../../cards/Video/component';
import {AddButton} from '../../../../elements/AddButton/AddButton';
import Preloader from '../../../../elements/Preloader/Preloader';
import {BreadCrumbs} from '../../../Application/ApplicationView/components/BreadCrumbs/BreadCrumbs';
import {Video as VideoType} from '../../../Application/ApplicationView/components/Videos/types';
import {useVideos} from './hooks';
import {Container} from './styles';
import {AddVideo} from './addVideo/modal';
interface Props {
  setTitle?: (value: ReactNode) => void;
}

const Videos = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const {setTitle} = props;
  const params = useParams<{groupId?: string}>();
  const {list, loading, fetch, groupId, groupName} = useVideos();

  useEffect(() => {
    if (setTitle != null && groupName != null && groupName !== '') {
      setTitle(<BreadCrumbs subtitle="Видео" missingName={groupName} />);
    }

    return () => {
      if (setTitle != null) {
        setTitle(null);
      }
    };
  }, [groupName, params.groupId, setTitle]);

  if (loading) return <Preloader />;

  function renderCard(card: VideoType, i: number) {
    return <VideoCard key={i} name={card.name} url={card.url} />;
  }

  return (
    <div>
      {showModal && (
        <AddVideo
          groupId={Number(groupId)}
          onAddSuccess={fetch}
          onModalClose={() => setShowModal(false)}
        />
      )}
      <AddButton
        typeButton="button"
        buttonLabel="Добавить видео"
        addItem={() => setShowModal(true)}
      />
      <Container>
        {list.length === 0 ? 'Данные отсутствуют' : list.map(renderCard)}
      </Container>
    </div>
  );
};

export default Videos;
