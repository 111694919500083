import {ReactNode, useState} from 'react';
import {ChevronIcon} from '../../icons/ChevronIcon';
import {
  Title,
  Content,
  ChevronButton,
  FullWrapper,
  DefaultWrapper,
} from './BlockSidebarWrapper.styles';

export interface Props {
  title: string | ReactNode;
  shouldHideTitleBigSize?: boolean;
  type?: 'full' | 'default';
  children: ReactNode;
}

export function BlockSidebarWrapper(props: Props) {
  const [open, setOpen] = useState(false);

  const Wrapper = props.type === 'full' ? FullWrapper : DefaultWrapper;

  return (
    <Wrapper open={open}>
      <Title
        shouldHideTitleBigSize={props.shouldHideTitleBigSize}
        onClick={() => setOpen((p) => !p)}>
        {props.title}
        <ChevronButton>
          <ChevronIcon />
        </ChevronButton>
      </Title>
      <Content
        shouldHideTitleBigSize={props.shouldHideTitleBigSize}
        open={open}>
        {props.children}
      </Content>
    </Wrapper>
  );
}
