import {SvgStyled} from './Icons.styles';

import React from 'react';

const PaperPlaneIcon = () => {
  return (
    <SvgStyled
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.6846 0.139089C23.4546 -0.0239114 23.1526 -0.0459114 22.9026 0.0850886L0.402618 11.8351C0.136617
        11.9741 -0.0203828 12.2581 0.00161723 12.5571C0.0246173 12.8571 0.223618 13.1131 0.506618 13.2101L6.76163
        15.3481L20.0826 3.95809L9.77463 16.3771L20.2576 19.9601C20.3356 19.9861 20.4176 20.0001 20.4996 20.0001C20.
        6356 20.0001 20.7706 19.9631 20.8896 19.8911C21.0796 19.7751 21.2086 19.5801 21.2416 19.3611L23.9916 0.861089C24.
        0326 0.581089 23.9146 0.303089 23.6846 0.139089Z"
        fill="#376092"
      />
    </SvgStyled>
  );
};

export default PaperPlaneIcon;
