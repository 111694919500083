import {useState} from 'react';
import {useOutside} from '../../../../../hooks/htmlElement.hooks';
import {Url} from '../../../../../types/auxiliary.types';
import {LabelStyled, Wrapper, CardWrapper} from './Label.styles';
import {Card} from '../Card/Card';

interface Props {
  name?: string;
  active: any;
  person: {
    avatar?: Url;
    fullName: string;
    nickName: string;
    telegram?: string;
    phones?: any;
    email?: any;
    vk?: Url;
    facebook?: Url;
    twitter?: Url;
  };
}

export function Label(props: Props) {
  const [active, setActive] = useState(false);

  const handleActive = () => {
    setActive((active) => !active);
  };

  const {contentRef} = useOutside<HTMLDivElement>(handleActive);

  return (
    <Wrapper>
      <LabelStyled onClick={handleActive} active={props.active}>
        {props.name}
      </LabelStyled>
      {active ? (
        <CardWrapper ref={contentRef}>
          <Card
            avatar={props.person.avatar}
            fullName={props.person.fullName}
            nickName={props.person.nickName}
            telegram={props.person.telegram}
            phones={props.person.phones}
            email={props.person.email}
            vk={props.person.vk}
            facebook={props.person.facebook}
            twitter={props.person.twitter}
          />
        </CardWrapper>
      ) : null}
    </Wrapper>
  );
}
