import styled from 'styled-components';

export const Wrapper = styled.article`
  background: #fff;
  border-radius: 8px;

  width: 100%;
  box-sizing: border-box;
  
  cursor: pointer;
`;

export const PrimaryInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 48px 16px 18px;
`;

export const ShortInfo = styled.div`
  width: 77px;
  margin-right: 20px;

  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: 40px;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px 0;
  box-sizing: border-box;

  background: var(--additional-color);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 8px 8px;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 8px;
  }
`;

export const Comment = styled.p`
  margin: 0 0 8px 8px;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;
