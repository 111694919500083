import React from 'react';
import {
  Wrapper,
  Field,
  Label,
  Value,
  Link,
  DateValue,
  LargeValue,
} from './styles';

export interface Props {
  list: (
    | {
        type: 'datetime';
        label: string;
        value: string | Date | null;
      }
    | {
        type: 'default';
        label: string;
        value: string;
      }
    | {
        type: 'links';
        label: string;
        values: {
          href: string;
          text: string | number;
        }[];
      }
    | {
        type: 'largeText';
        label: string;
        value: string;
      }
  )[];
}

export default function Fields(props: Props) {
  function renderField(field: Props['list'][0], i: number) {
    switch (field.type) {
      case 'datetime': {
        if (field.value == null) return null;

        const date =
          typeof field.value === 'string' ? new Date(field.value) : field.value;

        return (
          <Field key={i}>
            <Label>{field.label}</Label>
            <DateValue dateTime={date.toLocaleDateString()}>
              {date.toLocaleDateString('ru', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}{' '}
              в{' '}
              {date.toLocaleTimeString('ru', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </DateValue>
          </Field>
        );
      }
      case 'links': {
        if (field.values.length === 0) return null;

        return (
          <Field key={i}>
            <Label>{field.label}</Label>
            {field.values.map((value, i) => (
              <Link key={i} href={value.href}>
                {value.text}
              </Link>
            ))}
          </Field>
        );
      }
      case 'largeText': {
        return (
          <Field key={i}>
            <Label>{field.label}</Label>
            <LargeValue>{field.value}</LargeValue>
          </Field>
        );
      }
      default: {
        if (field.value == null || field.value === '') return;

        return (
          <Field key={i}>
            <Label>{field.label}</Label>
            <Value>{field.value}</Value>
          </Field>
        );
      }
    }
  }

  return <Wrapper>{props.list.map(renderField)}</Wrapper>;
}
