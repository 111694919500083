import styled from 'styled-components';

export const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Field = styled.p`
  margin: 0;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: var(--secondary-text-color);

  margin-right: 8px;
`;

export const DateValue = styled.time`
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const Value = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #333;
`;

export const Link = styled.a`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: var(--additional-color);
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &::before {
    content: '#';
  }
`;

export const LargeValue = styled.span`
  display: block;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;
