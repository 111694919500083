import React from 'react';
import {BlockWrapper} from '../BlockWrapper/BlockWrapper';
import {
  LinkWrapper,
  Wrapper,
  LinkS,
  CheckboxWrapper,
} from './AdditionalInfo.styles';
import {TextField} from '../../../../elements/TextField/TextField';
import {Control, UseFormRegister, useFormState} from 'react-hook-form';
import {FormFields} from '../../RegistrationFrom.types';
import Checkbox from '../Checkbox/Checkbox';

interface Props {
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  hideRegisterFields?: boolean;
}

export const AdditionalInfo = (props: Props) => {
  const {errors} = useFormState({
    control: props.control,
  });

  return (
    <BlockWrapper title="Дополнительно">
      <Wrapper>
        <TextField
          register={props.register('additionalInfo.qualification')}
          error={errors.additionalInfo?.qualification}
          label="Квалификация и навыки"
          placeholder="Введите"
          isTextarea
          rows={2}
        />
        {!props.hideRegisterFields && (
          <TextField
            register={props.register('additionalInfo.message')}
            error={errors.additionalInfo?.message}
            label="Сообщение Руководителю"
            placeholder="Введите"
            isTextarea
            rows={2}
          />
        )}
      </Wrapper>
      {!props.hideRegisterFields && (
        <CheckboxWrapper>
          <Checkbox
            name="additionalInfo.agreeWork"
            control={props.control}
            rules={{
              required: 'Поле обязательно для заполнения.',
            }}
            LabelComponent={
              <LinkWrapper>
                Соглашаюсь с{' '}
                <LinkS to="/terms" target="_blank">
                  правилами работы в системе *
                </LinkS>
              </LinkWrapper>
            }
          />
          <Checkbox
            name="additionalInfo.agreePersonalData"
            control={props.control}
            rules={{
              required: 'Поле обязательно для заполнения.',
            }}
            LabelComponent={
              <LinkWrapper>
                Соглашаюсь на{' '}
                <LinkS to="/policy" target="_blank">
                  обработку персональных данных *
                </LinkS>
              </LinkWrapper>
            }
          />
        </CheckboxWrapper>
      )}
    </BlockWrapper>
  );
};
