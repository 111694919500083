import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import { Button } from '../../elements/Button/Button';
import { ModalWrapper } from '../../elements/ModalWrapper/ModalWrapper';
import { TextField } from '../../elements/TextField/TextField';
import {
  Buttons,
  Form,
  Tooltip,
  Url,
  Wrapper,
} from './styles';
import {FormFields} from './types';

interface Props {
  defaultValues?: FormFields;

  title: string;
  requestSending: boolean;
  submitLabel: string;
  onSubmit(data: FormFields): void;
  onModalClose(): void;
}

export function Video(props: Props) {
 const {onSubmit} = props;

  const {handleSubmit, register, formState, setValue} = useForm<FormFields>({
    defaultValues: props.defaultValues || {
      url: '',
      name: '',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (props.defaultValues != null) {
      setValue("url", props.defaultValues.url);
      setValue("name", props.defaultValues.name);
    }
  }, [props.defaultValues, setValue])

  return (
    <ModalWrapper title={props.title} onModalClose={props.onModalClose}>
      <Form onSubmit={handleSubmit(onSubmit)} onReset={props.onModalClose}>
        <Wrapper>
          <Url>
            <TextField
              register={register('url', {
                required: 'Поле обязательно для заполнения.',
                maxLength: {
                  value: 20000,
                  message: 'Превышено количество символов',
                },
              })}
              error={formState.errors.url}
              label="Ссылка на видео"
              placeholder="http://"
              disabled={props.requestSending}
            />
            <Tooltip>
              Вы можете указать ссылку на страницу видеозаписи на таких сайтах,
              как Youtube, Rutube, Vimeo и др.
            </Tooltip>
          </Url>

          <TextField
            register={register('name', {
              required: 'Поле обязательно для заполнения.',
              maxLength: {
                value: 300,
                message: 'Превышено количество символов',
              },
            })}
            error={formState.errors.name}
            label="Название видео"
            placeholder="Введите ваше сообщение"
            disabled={props.requestSending}
          />
        </Wrapper>
        <Buttons>
          <Button
            title="Отмена"
            type="reset"
            variant={'outlined'}
            color={'primary'}
            disabled={formState.isSubmitting || props.requestSending}
            fullWidth
          />
          <Button
            title={props.submitLabel}
            type="submit"
            disabled={formState.isSubmitting || props.requestSending}
            fullWidth
          />
        </Buttons>
      </Form>
    </ModalWrapper>
  );
}
