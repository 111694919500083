import {useUrlParamInitialization} from '../../../../hooks/useUrl.hooks';

import {useSearchParams} from 'react-router-dom';

export function useProfileInfo() {
  const [searchParams, setSearchParams] = useSearchParams();

  useUrlParamInitialization({
    tab: 'equipments',
  });

  const activeTab = searchParams.get('tab');

  function handleSwitchTab(value: string) {
    setSearchParams({
      tab: value,
    });
  }

  return {handleSwitchTab, activeTab};
}
