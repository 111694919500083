import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {CALL_NEW_MUTATION} from '../../../../gql';
import {
  CreateCall,
  CreateCallVariables,
} from '../../../../gql/call/mutations/__generated__/CreateCall';
import {createDateTime_DTO} from '../../../../utils/Date.utils';
import {FormFields} from '../components/form/types';

import {toast} from 'react-toastify';

export function useCall() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [createCallRequest] = useMutation<CreateCall, CreateCallVariables>(
    CALL_NEW_MUTATION
  );

  function createCall(
    data: FormFields,
    setServerError: UseFormSetError<FormFields>
  ) {
    setLoading(true);
    createCallRequest({
      variables: {
        input: {
          isTraining: data.isEducational,
          is112: data.is112,
          regionId: Number(data.region),
          sourceId: Number(data.source),
          typeId: Number(data.type),
          description: data.description,
          contact: [data.lastName, data.firstName, data.middleName]
            .filter(Boolean)
            .join(' '),
          phone: data.phone,
          email: data.email,
          isUrgent: data.isUrgently,
          dateTime:
            data.dateTime == null ? '' : createDateTime_DTO(data.dateTime),
          isEmailSent: data.isEmailSend,
          ...{
            issueId: data.applicationId.id,
          },
          ...{calls: data.calls?.id},
          regions: data.additionalRegions.map((r) => Number(r)),
        },
      },
    })
      .then(() => {
        toast('Звонок успешно создан');
        navigate('/calls');
      })
      .catch((e) => {
        toast(e.message, {type: 'error'});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {createCall, loading};
}
