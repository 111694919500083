import styled from 'styled-components';
import {Logo} from '../../elements/Logo/Logo';

export const Container = styled.div`
  min-height: 100vh;
  max-width: 1448px;
  padding: 60px 272px 58px 108px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  margin: auto;
  align-items: center;
  width: 100%;

  position: relative;

  @media screen and (max-width: 1200px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media screen and (max-width: 850px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media screen and (max-width: 800px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LogoStyled = styled(Logo)`
  position: absolute;
  top: 70px;
  right: 64px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const CopyrightWrapper = styled.div`
  margin-top: 58px;
  width: 100%;
  display: flex;
`;
