import {useQuery} from '@apollo/client';

import {PROFILE_QUERY} from '../../../../gql';
import {Profile} from '../../../../gql/profile/queries/__generated__/Profile';

export function useProfile() {
  const {data, loading} = useQuery<Profile>(PROFILE_QUERY);

  return {
    loading,
    data: {
      avatar: undefined,
      fullName: data?.me.profile.full_name || '',
      nickname: data?.me.profile.nickname || '',
      birthday:
        data?.me.profile.birthday == null || data?.me.profile.birthday === ''
          ? null
          : new Date(data.me.profile.birthday),
      driverLicense:
        data?.me.profile.driver_license_categories == null
          ? []
          : data?.me.profile.driver_license_categories
              ?.filter((item) => !!item?.value)
              .map((item) => item?.key)
              .join(', ') || '',
      region:
        data?.me.regions == null
          ? null
          : data?.me.regions.map((item) => item.name).join(', '),
      address: data?.me.profile.address || '',
      phones: [
        data?.me.profile.phone_number,
        ...(data?.me.additionalPhones?.map((p) => p?.number) || []),
      ].filter(Boolean) as string[],
      emails: [
        data?.me.email,
        ...(data?.me.additionalEmails?.map((e) => e?.name) || []),
      ].filter(Boolean) as string[],
      socialNetworkInfo: {
        telegram: data?.me.profile.account_telegram || undefined,
        vk: data?.me.profile.account_vkontakte || undefined,
        facebook: data?.me.profile.account_facebook || undefined,
        twitter: data?.me.profile.account_twitter || undefined,
      },
      team: data?.me.team?.name || '',
      groups: data?.me.groups?.map((g) => g.full_name) || [],
      qualification: data?.me.profile.qualification || null,
    },
  };
}
