import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const MainColumn = styled.div`
  padding: 24px;
  flex: auto;
  box-sizing: border-box;
  width: 100%;

`;

export const SecondaryColumn = styled.div`
  max-width: 336px;
  width: 100%;

  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-left: 0px;

    margin-bottom: 20px;
    margin-top: 1rem;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  
`;
