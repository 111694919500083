import {Button, Page, PagesWrapper, Wrapper} from './Pagination.styles';
import {ArrowIcon} from '../../icons/ArrowIcon';
import {useCallback, useEffect, useState} from 'react';

interface Props {
  page: number;
  total: number;
  onChange(n: number): void;
}

export function Pagination(props: Props) {
  const [centerPage, setCenterPage] = useState<number | null>(null);

  const changeCenterPage = useCallback(
    (page: number) =>
      setCenterPage(() => {
        if (props.total > 5) {
          // 3 4 5 6 ... n - 2
          if (page > 2 && page < props.total - 1) return page;

          // 1 2
          if (page <= 2) return 3;

          // n - 1 n
          return props.total - 2;
        }

        return null;
      }),
    [props.total]
  );

  useEffect(() => {
    changeCenterPage(props.page);
  }, [props.page, changeCenterPage]);

  if (props.total < 2) return null;

  function renderButton(page: number | null) {
    return (
      <Page
        type="button"
        title={page == null ? undefined : `Перейти на страницу ${page}`}
        active={page === props.page}
        disabled={page == null}
        onClick={() => page != null && props.onChange(page)}>
        {page == null ? '...' : page}
      </Page>
    );
  }

  return (
    <Wrapper>
      <Button
        type="button"
        title="Назад"
        isRight={false}
        disabled={centerPage == null || centerPage <= 3}
        onClick={() => setCenterPage((p) => (p as number) - 1)}>
        <ArrowIcon />
      </Button>

      <PagesWrapper>
        {renderButton(1)}

        {props.total > 1 &&
          renderButton(centerPage == null || centerPage === 3 ? 2 : null)}

        {props.total > 2 && renderButton(centerPage == null ? 3 : centerPage)}

        {props.total > 3 &&
          renderButton(
            centerPage == null
              ? 4
              : centerPage === props.total - 2
              ? props.total - 1
              : null
          )}

        {props.total > 4 && renderButton(props.total)}
      </PagesWrapper>

      <Button
        type="button"
        title="Вперед"
        isRight={true}
        disabled={centerPage == null || centerPage >= props.total - 2}
        onClick={() => setCenterPage((p) => (p as number) + 1)}>
        <ArrowIcon />
      </Button>
    </Wrapper>
  );
}
