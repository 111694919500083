import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
 
  color: #000;

  padding-bottom: 27px;
  border-bottom: 2px solid #ffffff;
  margin: 28px 24px 0
`;
