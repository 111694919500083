import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

export const LinkIcon = styled.a`
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #010002;
    }
  }

  &:hover path {
    fill: var(--additional-color-accent);
  }
`;
