import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {APPLICATION_EDIT_REPORT_MUTATION} from '../../../../../../gql';
import {
  EditReport,
  EditReportVariables,
} from '../../../../../../gql/application/mutations/__generated__/EditReport';
import {Id} from '../../../../../../types/auxiliary.types';
import {toast} from 'react-toastify';
import {FormFields} from '../../../modals/Report/Report.types';

export const useReportsHandler = (reportId: Id, onEditSuccess?: () => void) => {
  const [loading, setLoading] = useState(false);

  const [editReportMutation] = useMutation<EditReport, EditReportVariables>(
    APPLICATION_EDIT_REPORT_MUTATION
  );

  const submit = useCallback(
    async (data: FormFields) => {
      await editReportMutation({
        variables: {
          input: {
            id: Number(reportId),
            description: data.description,
          },
        },
      })
        .then(() => {
          toast('Отчет успешно изменен!');
          setLoading(true);
          onEditSuccess?.();
        })
        .catch((e) => toast(e.message, {type: 'error'}));
    },
    [editReportMutation, onEditSuccess, reportId]
  );

  return {submit, loading};
};
