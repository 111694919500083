import styled from 'styled-components';

export const InputStyled = styled.input<{isInvalid?: boolean}>`
  border: 1px solid
    ${(props) =>
      props.isInvalid
        ? 'var(--warning-color);'
        : 'var(--primary-border-color);'};
  color: ${(props) =>
    props.isInvalid ? 'var(--warning-color);' : 'var(--primary-text-color);'};
  border-radius: 8px;
  font-size: 14px;
  padding: 14px 16px;
  line-height: 1.3571428571;

  &:disabled {
    cursor: not-allowed;
  }
`;
