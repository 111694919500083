import {SvgStyled} from './Icons.styles';

export const SettingsIcon = () => {
  return (
    <SvgStyled
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7326 13.7451L20.2885 13.4846C19.7599 13.1751 19.4441 12.6197 19.4441 12C19.4441 11.3803 19.7599 10.8249 20.2885 10.5154L20.7326 10.2549C21.9452 9.54343 22.3597 7.97314 21.6598 6.74229L20.8154 5.25771C20.1171 4.02943 18.5643 3.60771 17.3552 4.31657L16.911 4.57629C16.3824 4.88657 15.75 4.88657 15.2223 4.57629C14.6937 4.266 14.3779 3.71143 14.3779 3.09171V2.57143C14.3779 1.15371 13.2414 0 11.8448 0H10.1561C8.75946 0 7.62294 1.15371 7.62294 2.57143V3.09257C7.62294 3.71229 7.30714 4.26686 6.77857 4.57714C6.24999 4.88657 5.6184 4.88743 5.08982 4.57714L4.64568 4.31657C3.43654 3.60771 1.88374 4.02943 1.18461 5.25771L0.340234 6.74229C-0.35975 7.97314 0.0548365 9.54429 1.26735 10.2549L1.71234 10.5154C2.24091 10.8249 2.55671 11.3803 2.55671 12C2.55671 12.6197 2.24091 13.1751 1.71234 13.4846L1.2682 13.7451C0.0556808 14.4557 -0.358906 16.0269 0.341078 17.2577L1.18545 18.7423C1.88459 19.9706 3.43739 20.3923 4.64568 19.6834L5.08982 19.4237C5.6184 19.1126 6.24999 19.1143 6.77857 19.4237C7.30714 19.734 7.62294 20.2886 7.62294 20.9083V21.4286C7.62294 22.8463 8.75946 24 10.1561 24H11.8448C13.2414 24 14.3779 22.8463 14.3779 21.4286V20.9074C14.3779 20.2877 14.6937 19.7331 15.2223 19.4229C15.75 19.1134 16.3824 19.1126 16.911 19.4229L17.3552 19.6834C18.5643 20.3914 20.1171 19.9697 20.8154 18.7423L21.6598 17.2577C22.3597 16.0269 21.9452 14.4557 20.7326 13.7451ZM11.0004 16.2857C8.67249 16.2857 6.77857 14.3631 6.77857 12C6.77857 9.63686 8.67249 7.71429 11.0004 7.71429C13.3284 7.71429 15.2223 9.63686 15.2223 12C15.2223 14.3631 13.3284 16.2857 11.0004 16.2857Z" />
    </SvgStyled>
  );
};
