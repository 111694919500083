import {useQuery} from '@apollo/client';
import {ME_QUERY} from '../gql';
import {Me} from '../gql/queries/__generated__/Me';

export const useAuthenticatedUser = () => {
  const {data, loading} = useQuery<Me>(ME_QUERY);

  return {
    user:
      data == null
        ? null
        : {
            fullName: data.me.profile.full_name || '',
            email: data.me.email || '',
            teamId: data.me.team?.id || '',
            link: data.me.profile.link,
            notificationSound: data.me.profile.play_notification_sound,
            groups:
              data.me.groups?.map((g) => ({
                id: g.id,
                name: g.full_name,
              })) || [],
          },
    loading,
  };
};

export const getAuthToken = (): string | null => {
  const name = 'token='; // Имя куки
  const decodedCookie = decodeURIComponent(document.cookie);

  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
};
