import {SvgStyled} from './Icons.styles';

export const RejectIcon = () => {
  return (
    <SvgStyled
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8"
        cy="8"
        r="7"
        fill="#7D7D7D"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M6.00006 10L10.2353 6" stroke="white" strokeWidth="2" />
      <path
        d="M6.11768 5.88287L10.1177 10.1182"
        stroke="white"
        strokeWidth="2"
      />
    </SvgStyled>
  );
};
