import React from 'react';
import {Tabs} from '../../elements/Tabs/Tabs';
import {Main} from './components/Main/Main';
import {useDocumentTitle} from '../../../hooks/generateTitle.hooks';

import {useDetachment} from './DepartmentScreen.hooks';
import {Container, Primary, Title, Wrapper} from './DepartmentScreen.styles';
import Preloader from '../../elements/Preloader/Preloader';

export function DepartmentScreen() {
  useDocumentTitle('Ведомства');

  const {loading, data, activeTab, handleSwitchTab} = useDetachment();

  function renderForm() {
    if (!data?.team) return null;

    switch (activeTab) {
      case 'about': {
        return <Main description={data.team.description} />;
      }
      default:
        return <i>Раздел находится в разработке</i>;
    }
  }

  if (loading) {
    return <Preloader />;
  }

  if (!data?.team) {
    return <div>'Not found'</div>;
  }

  return (
    <Wrapper>
      <Title>{data.team.name}</Title>
      <Tabs
        activeTab={activeTab || ''}
        items={[
          {
            id: 'about',
            label: 'О ведомстве',
          },
          {
            id: 'applications',
            label: 'Заявки',
          },
          {
            id: 'photos',
            label: 'Фото',
          },
          {
            id: 'videos',
            label: 'Видео',
          },
          {
            id: 'files',
            label: 'Файлы',
          },
        ]}
        onSwitchTab={handleSwitchTab}
      />
      <Container>
        <Primary>{renderForm()}</Primary>
      </Container>
    </Wrapper>
  );
}
