import React, {Fragment, ReactNode} from 'react';
import { Id } from '../../../../../types/auxiliary.types';
import Preloader from '../../../../elements/Preloader/Preloader';
import SendDataModal from './modals/component';
import {Wrapper, ListWrapper} from './styles';

interface Props<List> {
  loading: boolean;
  renderCard: (card: List) => ReactNode;
  list: List[];
  modalMeta?: {
    id: Id;
    type: "call" | "issue" | "task";
    onSendSuccess?: () => Promise<any>;
    onResetMeta(): void;
  }
}

export default function Template<List>(props: Props<List>) {

  return (
    <Wrapper>
      {props.modalMeta != null && (
        <SendDataModal
           meta={{
             id: props.modalMeta.id,
             type: props.modalMeta.type,
           }}
           onSendSuccess={props.modalMeta.onSendSuccess}
           onClose={props.modalMeta.onResetMeta}
        />
      )}
      {props.loading ? (
        <Preloader />
      ) : (
        <ListWrapper>
          {props.list.map((l, i) => <Fragment key={i}>{props.renderCard(l)}</Fragment>)}
        </ListWrapper>
      )}
    </Wrapper>
  )
}
