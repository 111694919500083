import styled from "styled-components";

export const SecondaryInfo = styled.div`
  flex: auto;
`;

export const Name = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;

export const Region = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: var(--secondary-text-color);
`;

export const DateWrapper = styled.div`
  width: 140px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const ResponsibleWrapper = styled.div`
  width: 28%;
  
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const StatusWrapper = styled.div`
  width: 120px;
  
  > * {
    width: fit-content;
  }
  
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const Comment = styled.p`
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333;
`;

export const CommentTitle = styled.b`
  font-weight: 700;
  
  &::after {
    content: ":";
    margin-right: 4px;
  }
`;
