import React from 'react';
import {Form} from './form';
import {useAddTechnique} from './hooks';

interface Props {
  onAddSuccess: () => void;
  onClose: () => void;
}

function AddTechniqueForm(props: Props) {
  const {handleSubmit, loading} = useAddTechnique(props.onAddSuccess);

  return (
    <Form
      title={'Добавить технику'}
      submitLabel={'Добавить'}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      submitLoading={loading}
    />
  );
}

export default AddTechniqueForm;
