import React from 'react';
import {SvgStyled} from './Icons.styles';

export const MessageIcon = () => {
  return (
    <SvgStyled
      width="10"
      height="8"
      viewBox="0 0 10 8"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.99967 3.43493L9.72957 0.659987C9.59341 0.458519 9.4103 0.293175 9.19604 0.178202C8.98177 0.0632281 8.74276 0.00207123 8.4996 1.43175e-07H1.49974C1.25533 -0.000106632 1.01461 0.0595106 0.798497 0.173665C0.582388 0.287819 0.397447 0.453052 0.259766 0.654987L4.99967 3.43493Z" />
      <path d="M5.49989 4.29499C5.34797 4.38311 5.17552 4.42967 4.9999 4.42998C4.82473 4.43119 4.65231 4.38636 4.49991 4.29999L0 1.66504V6.49994C0 6.89776 0.158032 7.27928 0.439331 7.56058C0.72063 7.84188 1.10215 7.99991 1.49997 7.99991H8.49983C8.89764 7.99991 9.27917 7.84188 9.56047 7.56058C9.84176 7.27928 9.9998 6.89776 9.9998 6.49994V1.66504L5.49989 4.29499Z" />
    </SvgStyled>
  );
};
