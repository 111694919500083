import {useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {TASK_LIST_DICT_QUERY} from '../../../../../../gql';
import {TaskListDict} from '../../../../../../gql/task/queries/__generated__/TaskListDict';
import {createDict} from '../../../../../../utils/Dict.utils';

import {createUrlSearch} from '../../../../../../utils/url.utils';

import {FormFields} from './types';

export function useDict() {
  const {data, loading} = useQuery<TaskListDict>(TASK_LIST_DICT_QUERY);

  return {
    loading: loading,
    regions: data == null ? [] : createDict(data.allRegions),
    statuses: data == null ? [] : createDict(data.allTaskStatuses),
  };
}

export function useAction() {
  const [, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (data: FormFields) => {
      setSearchParams({
        page: '1',
        ...createUrlSearch(data),
      });
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    () => setSearchParams({page: '1'}),
    [setSearchParams]
  );

  return {submit: handleSubmit, handleReset};
}
