import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {APPLICATION_CREATE_MISSING_VIDEO_MUTATION} from '../../../../../../gql';
import {
  CreateMissingVideo,
  CreateMissingVideoVariables,
} from '../../../../../../gql/application/mutations/__generated__/CreateMissingVideo';
import {Id} from '../../../../../../types/auxiliary.types';
import {FormFields} from './types';
import {toast} from 'react-toastify';

export function useAddVideo(
  missingId: Id,
  onAddSuccess: () => void,
  onModalClose?: () => void
) {
  const [requestSending, setRequestSending] = useState(false);
  const [addVideoMutation] = useMutation<
    CreateMissingVideo,
    CreateMissingVideoVariables
  >(APPLICATION_CREATE_MISSING_VIDEO_MUTATION);

  const handleAddVideo = useCallback(
    async (data: FormFields) => {
      setRequestSending(true);

      await addVideoMutation({
        variables: {
          input: {
            missingId: Number(missingId),
            url: data.url,
            name: data.name,
          },
        },
      })
        .then(() => {
          toast('Видео успешно создано!');
          onModalClose?.();
          onAddSuccess();
        })
        .catch((e) => toast(e.message, {type: 'error'}))
        .finally(() => setRequestSending(false));
    },
    [addVideoMutation, missingId, onAddSuccess, onModalClose]
  );

  return {handleAddVideo, requestSending};
}
