import React from 'react';
import {HtmlStringified} from '../../../../../types/auxiliary.types';

import {Description, Wrapper} from './Main.styles';

interface Props {
  description: HtmlStringified | null;
}

export function Main(props: Props) {
  return (
    <Wrapper>
      {props.description != null ? (
        <Description dangerouslySetInnerHTML={{__html: props.description}} />
      ) : (
        <Description>Описания нет</Description>
      )}
    </Wrapper>
  );
}
