import {SvgStyled} from './Icons.styles';
import React from 'react';

export const FacebookIcon = () => {
  return (
    <SvgStyled
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="#010002"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.23908 16V8.70218H8.68767L9.05503 5.85725H6.23908V4.04118C6.23908 3.21776 6.4668 2.65661 7.64891 2.65661L9.15414 2.65599V0.111384C8.89383 0.0775563 8.00029 0 6.96029 0C4.78861 0 3.30184 1.32557 3.30184 3.75942V5.85725H0.845825V8.70218H3.30184V16H6.23908Z" />
    </SvgStyled>
  );
};
