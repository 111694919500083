import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 680px) {
    display: block;
  }
`;

export const PrimaryColumn = styled.div`
  flex: auto;
  margin-right: 92px;
  max-width: 648px;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1110px) {
    flex: none;
  }

  @media screen and (max-width: 680px) {
    margin-right: 0;
  }
`;

export const GridFields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 24px;
  grid-row-gap: 32px;

  *[data-element='time-field'] {
    max-width: 145px;
  }

  @media screen and (max-width: 1110px) {
    grid-template-columns: repeat(1, 312px);
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const SecondaryColumn = styled.div`
  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 680px) {
    margin-top: 32px;
  }
`;

export const ChildrenWrapper = styled.div`
  flex: auto;
`;
