import React from 'react';
import Preloader from '../../../../elements/Preloader/Preloader';
import {ShortCard} from '../../../../elements/ShortCard/ShortCard';
import {useActions, useTeam} from './Groups.hooks';

import {Wrapper} from './Groups.styles';

export function Groups() {
  const {loading, list} = useTeam();
  const {handleCardClick} = useActions();

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : list.length === 0 ? (
        <div>Данные отсутствуют</div>
      ) : (
        list.map((g) => (
          <ShortCard
            key={g.id}
            title={g.title}
            labels={g.labels}
            onClick={() => handleCardClick(g.id)}
          />
        ))
      )}
    </Wrapper>
  );
}
