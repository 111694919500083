import {FullMenuWrapper, ShortMenuWrapper, Wrapper} from './Menu.styles';
import {ShortMenu} from './components/ShortMenu/ShortMenu';
import {FullMenu} from './components/FullMenu/FullMenu';
import {MenuConfig} from './Menu.constants';
import {useOutside} from '../../../hooks/htmlElement.hooks';

interface Props {
  activeMenuTab: string;
  menuType: 'short' | 'full';
  onCloseFullMenu: () => void;
}

export function Menu(props: Props) {
  const {contentRef} = useOutside<HTMLDivElement>(props.onCloseFullMenu);

  return (
    <>
      <FullMenuWrapper ref={contentRef} visible={props.menuType === 'full'}>
        <FullMenu
          onClose={props.onCloseFullMenu}
          activeTab={props.activeMenuTab}
          items={MenuConfig}
        />
      </FullMenuWrapper>
      <Wrapper>
        <ShortMenuWrapper visible={props.menuType === 'short'}>
          <ShortMenu activeTab={props.activeMenuTab} items={MenuConfig} />
        </ShortMenuWrapper>
      </Wrapper>
    </>
  );
}
