import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Home = styled(Link)`
  margin-right: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  color: var(--additional-color);
`;

export const BreadCrumb = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  color: #333;
`;
