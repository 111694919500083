import React from 'react';
import {Id} from '../../../types/auxiliary.types';
import {BlockSidebarWrapper} from '../BlockSidebarWrapper/BlockSidebarWrapper';
import {
  Wrapper,
  Text,
  Label,
  Count,
  TitleCount,
  Title,
} from './StatisticSimple.styles';

export interface Props {
  title: string;
  showCount?: boolean;
  items: {
    id: Id;
    name: string;
    count: number;
  }[];
}

export function StatisticSimple(props: Props) {
  if (props.items.length === 0) return null;

  const title = (
    <Title>
      {props.title}
      {props.showCount && (
        <TitleCount>
          {props.items.reduce((acc, curr) => acc + curr.count, 0)}
        </TitleCount>
      )}
    </Title>
  );

  return (
    <BlockSidebarWrapper title={title}>
      <Wrapper>
        {props.items.map((item) => (
          <Label key={item.id}>
            <Text>{item.name}</Text>
            <Count>{item.count}</Count>
          </Label>
        ))}
      </Wrapper>
    </BlockSidebarWrapper>
  );
}
