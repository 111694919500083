import React from 'react';
import {ModalWrapper} from '../ModalWrapper/ModalWrapper';
import {useForm} from 'react-hook-form';
import {Text} from '../Text/Text';
import {TextField} from '../TextField/TextField';
import Preloader from '../Preloader/Preloader';
import {Form, ButtonsWrapper} from './CreateRinging.styles';
import {Button} from '../Button/Button';

interface FormFields {
  content: string;
}

interface Props {
  title: string;
  loading?: boolean;
  labelSubmit: string;
  submit(data: FormFields): void;
  onModalClose(): void;
  onAddSuccess?(): void;
}

export default function CreateRinging(props: Props) {
  const {handleSubmit, register, formState} = useForm<FormFields>({
    defaultValues: {
      content: '',
    },
    mode: 'onSubmit',
  });

  return (
    <ModalWrapper title={props.title} onModalClose={props.onModalClose}>
      {props.loading ? (
        <Preloader />
      ) : (
        <Form
          onReset={props.onModalClose}
          onSubmit={handleSubmit(props.submit)}>
          <Text variant="p2">Содержание</Text>
          <TextField
            register={register('content', {
              maxLength: {
                value: 3000,
                message: 'Количество символов в поле не может превышать 3000',
              },
            })}
            isTextarea
            placeholder={'Введите ваше сообщение'}
            error={formState.errors.content}
          />
          <ButtonsWrapper>
            <Button
              variant="outlined"
              type="reset"
              onClick={() => props.onModalClose()}>
              Отменить
            </Button>
            <Button type="submit">Отправить</Button>
          </ButtonsWrapper>
        </Form>
      )}
    </ModalWrapper>
  );
}
