import React from 'react';
import {useForm, UseFormSetError} from 'react-hook-form';
import {Button} from '../../../../../../elements/Button/Button';
import {ModalWrapper} from '../../../../../../elements/ModalWrapper/ModalWrapper';
import Preloader from '../../../../../../elements/Preloader/Preloader';
import {ApplicantInfo} from '../../../../components/ApplicantInfo/ApplicantInfo';
import {useEnterForm} from '../../../../form.hooks';
import {Form, Fields, Footer} from './Applicant.styles';

import {FormFields} from './Applicant.types';

interface Props {
  title: string;
  loading?: boolean;
  defaultValues?: FormFields;
  onSave: (
    value: FormFields,
    setServerError: UseFormSetError<FormFields>
  ) => void;
  onClose: () => void;
}

export function Applicant(props: Props) {
  const {control, register, formState, handleSubmit, setError} =
    useForm<FormFields>({
      defaultValues: props.defaultValues || {
        firstName: '',
        lastName: '',
        middleName: '',
        relationDegree: '',
        phone: '',
        additionalContact: '',
        police: '',
        MES: '',
        is112: false,
      },
      reValidateMode: 'onBlur',
      mode: 'onBlur',
    });

  useEnterForm(handleSubmit((d) => props.onSave(d, setError)));

  return (
    <ModalWrapper title={props.title} size="big" onModalClose={props.onClose}>
      {props.loading ? (
        <Preloader />
      ) : (
        <Form onSubmit={handleSubmit((d) => props.onSave(d, setError))}>
          <Fields>
            <ApplicantInfo register={register} control={control} />
          </Fields>

          <Footer>
            <Button
              title="Отменить"
              onClick={props.onClose}
              disabled={formState.isSubmitting}
            />
            <Button
              title="Сохранить"
              type="submit"
              disabled={formState.isSubmitting}
            />
          </Footer>
        </Form>
      )}
    </ModalWrapper>
  );
}
