import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  margin-bottom: 40px;
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 24px;
  align-items: center;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
