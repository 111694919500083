import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #333;
`;
