import React from 'react';
import {DateField} from '../../../cards/elements/dateField/component';
import {Pagination} from '../../../elements/Pagination/Pagination';
import Preloader from '../../../elements/Preloader/Preloader';
import User from '../../../elements/user/component';
import {useAnnouncement} from './hooks';
import {List, Wrapper, Card, Header, Button, Text} from './styles';

export default function AnnouncementList() {
  const {
    loading,
    list,
    paginatorInfo,
    actionLoading,
    handleSwitchPage,
    handleToggleRead,
  } = useAnnouncement();

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <List>
            {list.map((a) => (
              <Card key={a.id}>
                <Header>
                  <User
                    avatar={a.author.avatar}
                    fullName={a.author.fullName}
                    label="Автор"
                  />
                  <DateField date={a.createDate} label="Создано" />
                  {!a.isRead && (
                    <Button
                      type="button"
                      disabled={actionLoading}
                      onClick={() => handleToggleRead(a.id)}>
                      Отметить как прочитанное
                    </Button>
                  )}
                </Header>
                <Text isBold={!a.isRead}>{a.text}</Text>
              </Card>
            ))}
          </List>
          <Pagination
            page={(paginatorInfo.currentPage as number) || 0}
            total={paginatorInfo.lastPage}
            onChange={handleSwitchPage}
          />
        </>
      )}
    </Wrapper>
  );
}
