export type StringPipe = (e: string) => string;

export const trim: StringPipe = (e: string): string => e.trim();
export const lowercase: StringPipe = (e: string): string => e.toLowerCase();
export const capitalized: StringPipe = (e: string): string =>
  e.charAt(0).toUpperCase() + e.slice(1);

export const stringPipe =
  (...fns: StringPipe[]) =>
  (e: string) =>
    fns.reduce((s, fn) => fn(s), e);
