import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form/dist/types/form';
import {AddButton} from '../../../../elements/AddButton/AddButton';

import {
  Wrapper,
  InputWrapper,
  Container,
} from './MediaList.styles';

type Props<T> = {
  list: T[];
  renderCard: (data: T, i: number, array: T[]) => void;
  renderModal: () => void;
  buttonLabel: string;
  countInRow?: number | [number, number] | [number, number, number];
} & (
  | {
      typeButton: 'button';
      addItem(): void;
    }
  | {
      typeButton: 'fileInput';
      accept?: string;
      formRegister: UseFormRegisterReturn;
    }
);

export function MediaList<T>(props: Props<T>) {

  return (
    <Wrapper>
      {props.renderModal()}
      <InputWrapper>
        <AddButton
          {...props}
        />
      </InputWrapper>
      <Container items={props.countInRow || [1, 2, 3]}>
        {props.list.length === 0
          ? 'Данные отсутствуют'
          : props.list.map(props.renderCard)}
      </Container>
    </Wrapper>
  );
}
