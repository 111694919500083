import React from 'react';
import {SvgStyled} from './Icons.styles';

export const TaskIcon = () => {
  return (
    <SvgStyled
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3999 2.40002H14.3779C13.8858 1.008 12.5658 0 10.9998 0C9.43382 0 8.1138 1.008 7.62184 2.40002H2.59985C1.27387 2.40002 0.199829 3.474 0.199829 4.80003V21.6C0.199829 22.926 1.27381 24 2.59985 24H19.3999C20.7258 24 21.7999 22.926 21.7999 21.6V4.79998C21.7998 3.474 20.7258 2.40002 19.3999 2.40002ZM10.9998 2.40002C11.6598 2.40002 12.1998 2.934 12.1998 3.6C12.1998 4.266 11.6598 4.79998 10.9998 4.79998C10.3398 4.79998 9.79984 4.266 9.79984 3.6C9.79984 2.934 10.3398 2.40002 10.9998 2.40002ZM8.59986 19.2L3.79983 14.4L5.49784 12.702L8.59986 15.804L16.5019 7.90199L18.1999 9.60001L8.59986 19.2Z" />
    </SvgStyled>
  );
};
