import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Url} from '../../../../../types/auxiliary.types';
import {Gallery} from '../../../../elements/Gallery/Gallery';
import Preloader from '../../../../elements/Preloader/Preloader';
import {MediaList} from '../../../DetachmentScreen/components/MediaList/MediaList';
import {Title} from '../../../DetachmentScreen/components/Photos/Photos.styles';
import {usePhotos} from './hooks';
import * as S from './styles';
import {AddPhoto} from './modal/component';
const Photo = () => {
  const [numberSlide, setNumberSlide] = useState<number | null>(null);
  const {list, loading, fetch} = usePhotos();

  const {register, watch, reset} = useForm<{
    file: FileList | null;
  }>({
    defaultValues: {
      file: null,
    },
  });

  const formRegisterResult = register('file');

  const file = watch('file');

  function renderModal() {
    if (file == null) return null;

    return (
      <AddPhoto
        file={file.item(0) as File}
        fetch={fetch}
        onModalClose={() => reset({file: null}, {keepDefaultValues: true})}
      />
    );
  }
  function renderCard(photoSrc: Url, i: number) {
    return (
      <S.Photo
        key={photoSrc}
        height={312}
        width={312}
        alt="Фото"
        src={photoSrc}
        onClick={() => setNumberSlide(i)}
      />
    );
  }
  function renderGalleryCard(photo: {name: string; url: string}) {
    return (
      <S.PhotoGalleryWrapper>
        <S.PhotoGallery alt="Фото" src={photo.url} />
        <Title>{photo.name}</Title>
      </S.PhotoGalleryWrapper>
    );
  }

  if (loading) return <Preloader />;

  return (
    <>
      {numberSlide != null && (
        <Gallery
          list={list}
          renderCard={renderGalleryCard}
          firstSlide={numberSlide}
          onModalClose={() => setNumberSlide(null)}
        />
      )}
      <MediaList<Url>
        list={list.map((i) => i.url)}
        typeButton="fileInput"
        buttonLabel="Добавить фото"
        renderCard={renderCard}
        renderModal={renderModal}
        accept=".jpg,.jpeg,.png"
        formRegister={formRegisterResult}
      />
    </>
  );
};

export default Photo;
